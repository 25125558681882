import React, { useEffect } from "react";
import { useGetMrcDocuments, useGetPolicy } from "/src/dal/dal";
import { useParams } from "/src/routing/routing";
import { Routes } from "/src/routing/routes";
import { MrcPage, MrcSplitPane } from "./page";
import { Loader } from "/src/design_system/Loader";
import { useHistory } from "react-router";
import { throwIfAppError } from "/src/utils/app_error";

export const MrcIndexView = (): JSX.Element => {
  const { data } = useParams(Routes.mrcUpload);
  const productId = data.capture.productId;
  const policyId = data.capture.policyId;
  const policy = useGetPolicy(productId, policyId);
  throwIfAppError(policy);

  const docState = useGetMrcDocuments(productId, policyId);
  throwIfAppError(docState);
  const history = useHistory();

  useEffect(() => {
    if (docState.value.length > 0) {
      const documentId = Math.max(...docState.value.map((s) => s.documentId));
      history.push(
        Routes.mrcDocumentView.generatePath({
          capture: { productId: productId, policyId: policyId, documentId: documentId },
        }),
      );
    } else {
      history.push(
        Routes.mrcUpload.generatePath({ capture: { productId: productId, policyId: policyId } }),
      );
    }
  }, [docState]);

  return (
    <MrcPage policy={policy.value} isUpdating={false}>
      <MrcSplitPane
        onCancel={async () => {
          return;
        }}
        onImport={null}
        leftPanel={
          <div className="w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        }
        rightPanel={<></>}
      />
    </MrcPage>
  );
};
