import clsx from "clsx";
import React from "react";

export const Badge = (props: BadgeProps): JSX.Element => {
  return (
    <div
      data-testid={props.testid}
      className={clsx([
        "inline-flex",
        "items-center",
        props.small === true ? "px-1.5" : "px-2",
        "py-0.5",
        "text-xs",
        "font-medium",
        "rounded-full",
        getBadgeColors(props.color),
        props.border ? "border" : "",
        getBorderColor(props.color),
      ])}
    >
      {props.important && (
        <svg
          className={clsx(["-ml-0.5", "mr-1.5", "h-2", "w-2", getImportantDotColor(props.color)])}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx="4" cy="4" r="3" />
        </svg>
      )}
      <span>{props.label} </span>
      {props.secondaryLabel !== undefined && (
        <span className={`border-l ml-1 pl-1 ${getBorderColor(props.color)}`}>
          {props.secondaryLabel}{" "}
        </span>
      )}
    </div>
  );
};

export type BadgeProps = {
  label: string;
  secondaryLabel?: string;
  color: "green" | "orange" | "red" | "blue" | "gray" | "white";
  important?: boolean; // tw: it's the dot that can be found on the designs near "needs review" badge for example
  testid?: string;
  border?: boolean;
  small?: boolean;
};

function getBadgeColors(color: BadgeProps["color"]): string[] {
  switch (color) {
    case "green":
      return ["text-green-800", "bg-green-100"];
    case "orange":
      return ["text-yellow-800", "bg-yellow-400", "bg-opacity-25"];
    case "red":
      return ["text-red-800", "bg-red-100"];
    case "blue":
      return ["text-blue-800", "bg-blue-100"];
    case "gray":
      return ["text-black", "bg-gray-100"];
    case "white":
      return ["text-gray-500", "bg-white"];
  }
}

function getBorderColor(color: BadgeProps["color"]): string {
  switch (color) {
    case "green":
      return "border-green-200";
    case "orange":
      return "border-orange-200";
    case "red":
      return "border-red-200";
    case "blue":
      return "border-blue-200";
    case "gray":
      return "border-gray-200";
    case "white":
      return "border-gray-200";
  }
}
function getImportantDotColor(color: BadgeProps["color"]): string[] {
  switch (color) {
    case "green":
      return ["text-green-400"];
    case "orange":
      return ["text-orange-400"];
    case "red":
      return ["text-red-400"];
    case "blue":
      return ["text-blue-800"];
    case "gray":
      return ["text-gray-400"];
    case "white":
      return ["text-gray-400"];
  }
}
