export const METAS = {
  SHOW_IN_QUICKVIEW: "show_in_quickview",
  PREFIX: "ui_prefix",
  SUGGESTIONS: "suggestions",
  AS_PREMIUM: "as_premium",
  CUSTOM_YES_PROMPT: "custom_yes_prompt",
  CUSTOM_NO_PROMPT: "custom_no_prompt",
  DESCRIPTION: "description",
};

export const SPECIAL_DATAPOINTS = {
  REFER: "refer",
  DECLINE: "decline",
  MULTI_DOC_FOOTER: "documents.quote_footer",
  MULTI_DOC_BODY: "documents.quote_body",
  MULTI_DOC_HEADER: "documents.quote_header",
};

export const CAPTIONS = {
  PREMIUM_HIDDEN: "Unavailable whilst referred",
};

export const DEFAULT_PAGE_NUMBER = 1;
