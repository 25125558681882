import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  storeMrcExtractionCheckedState,
  useGetAnyMrcImported,
  useGetMrcResolvedDatapoints,
  useGetQueryPolicyKeysInfo,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import { checkNotUndefined, Either, Left, Right } from "/src/utils";
import { mrcDocumentSingleDatapointEdit } from "/src/routing/routes";
import {
  KeyInfo,
  MrcCandidateId,
  MrcCheckedState,
  MrcDocumentId,
  MrcExtractionId,
  MrcResolvedExtraction,
  MrcResolvedStatus,
  PolicyId,
  ProductId,
  Scalar,
} from "/src/internal_types";
import { Button } from "/src/design_system/Button";
import * as Popover from "@radix-ui/react-popover";
import * as Tooltip from "@radix-ui/react-tooltip";
import { CheckIcon, MenuAlt1Icon, PencilAltIcon, SearchIcon, XIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { assertNever } from "/src/utils";
import { match } from "ts-pattern";

const StatusDot = (props: { status: MrcResolvedStatus }): JSX.Element => {
  const colour = (): string => {
    switch (props.status) {
      case "StatusEditedByMe":
        return "#6B7280";
      case "StatusError":
        return "#EF4444";
      case "StatusWarning":
        return "#F59E0B";
      case "StatusAutoMatch":
        return "#10B981";
    }
  };
  return <div className="w-2 h-2 rounded mt-2 mr-3.5" style={{ backgroundColor: colour() }}></div>;
};

const StatusDotWithTooltip = (props: { extraction: MrcResolvedExtraction }): JSX.Element => {
  if (props.extraction.resolvedValue.tag === "ResolvedValueExtracted") {
    const value = props.extraction.resolvedValue.contents;
    return (
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger className="-mt-2 -ml-2 pl-2">
          <StatusDot status={props.extraction.status} />
        </Tooltip.Trigger>
        <Tooltip.Content
          side="left"
          sideOffset={12}
          align="start"
          className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm flex flex-col gap-3"
        >
          {value.matchConfidence !== undefined && (
            <p>
              Match confidence:
              <span
                className={
                  "font-semibold pl-1 text-" +
                  (value.matchConfidence.meetsThreshold ? "green-500" : "red-500")
                }
              >
                {Math.round(value.matchConfidence.value)}%
              </span>
            </p>
          )}
          <p>
            Text confidence:
            <span
              className={
                "font-semibold pl-1 text-" +
                (value.textConfidence.meetsThreshold ? "green-500" : "red-500")
              }
            >
              {Math.round(value.textConfidence.value)}%
            </span>
          </p>
        </Tooltip.Content>
      </Tooltip.Root>
    );
  } else {
    return <StatusDot status={props.extraction.status} />;
  }
};

const SearchBox = (props: { onChange: (v: string) => void }): JSX.Element => {
  return (
    <div className="relative rounded-md shadow-sm grow">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        className="block w-full pl-10 border border-gray-300 rounded-md text-sm"
        placeholder="Search"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};

const FilterDropdown = (props: {
  active: null | MrcResolvedStatus;
  onChange: (v: null | MrcResolvedStatus) => void;
}): JSX.Element => {
  const active = props.active;
  const onChange = props.onChange;

  const Item = (props: {
    value: null | MrcResolvedStatus;
    children?: React.ReactNode;
  }): JSX.Element => {
    return (
      <a
        onClick={(_) => onChange(props.value)}
        className={
          "flex flex-row select-none cursor-pointer py-2 px-4 hover:bg-gray-200" +
          (active === props.value ? " bg-gray-100" : "")
        }
      >
        {props.value !== null && <StatusDot status={props.value} />}
        {props.children}
      </a>
    );
  };

  return (
    <Popover.Root>
      <Popover.Trigger className="flex gap-2 items-center h-min-content rounded-md border border-gray-300 shadow-sm px-2 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
        <MenuAlt1Icon className="h-4 w-4" />
        Filters
      </Popover.Trigger>
      <Popover.Content
        onOpenAutoFocus={(e) => e.preventDefault()}
        align="end"
        className="mt-2 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 outline-none flex flex-col"
      >
        <Item value={null}>All</Item>
        <Item value={"StatusError"}>Errors</Item>
        <Item value={"StatusWarning"}>Warnings</Item>
        <Item value={"StatusEditedByMe"}>Edited by me</Item>
        <Item value={"StatusAutoMatch"}>Auto matches</Item>
      </Popover.Content>
    </Popover.Root>
  );
};

export const scalarString = (value: Scalar): string => {
  const tag = value.tag;
  switch (tag) {
    case "Bool":
      return value.contents ? "Yes" : "No";
    case "CaselessText":
      return value.contents;
    case "Date":
      return value.contents;
    case "DateDuration":
      return `${value.contents.months} months ${value.contents.days} days`;
    case "File":
      return value.contents;
    case "Int":
      return value.contents.toString();
    case "Number":
      return value.contents.toString();
    case "Percent":
      return (value.contents * 100).toString() + "%";
    case "Text":
      return value.contents;
    case "Uuid":
      return value.contents;
    case "Umr":
      return "B" + value.contents.brokerId + value.contents.extraData;
    case "Time":
      return value.contents;
    default:
      assertNever(tag);
  }
};

export const resolvedValueString = (extraction: MrcResolvedExtraction): string => {
  switch (extraction.resolvedValue.tag) {
    case "ResolvedValueExtracted":
      return scalarString(extraction.resolvedValue.contents.value);
    case "ResolvedValueOverridden":
      return scalarString(extraction.resolvedValue.contents.value);
    case "ResolvedValueMissing":
      return "";
  }
};

const MarkCorrectButtons = (props: {
  productId: ProductId;
  policyId: PolicyId;
  documentId: MrcDocumentId;
  extractionId: MrcExtractionId;
  checkedState: MrcCheckedState | undefined;
  hasImported: boolean;
}): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const [localState, setState] = useState<MrcCheckedState | undefined>(props.checkedState);
  useEffect(() => {
    const go = async () => {
      setLoading(true);
      await storeMrcExtractionCheckedState(
        props.productId,
        props.policyId,
        props.documentId,
        props.extractionId,
        localState,
      );
      setLoading(false);
    };
    void go();
  }, [localState]);
  return (
    <>
      <Button
        disabled={props.hasImported || isLoading}
        className={clsx([
          "shadow-none h-8 rounded-none !border-gray-200 border-0 px-2",
          props.hasImported && "bg-gray-200",
          localState == "MrcCheckedStateIgnore" &&
            "!text-white bg-red-500 hover:bg-red-600 active:bg-red-700",
        ])}
        variant="secondary"
        onClick={async (_) => {
          if (localState === "MrcCheckedStateIgnore") {
            setState(undefined);
          } else {
            setState("MrcCheckedStateIgnore");
          }
        }}
      >
        <XIcon className="w-4 h-4" />
      </Button>
      <Button
        disabled={props.hasImported || isLoading}
        className={clsx([
          "h-8 rounded-none !border-gray-200 border-0 px-2",
          props.hasImported && "bg-gray-200",
          localState == "MrcCheckedStateCorrect" &&
            "!text-white bg-green-500 hover:bg-green-600 active:bg-green-700",
        ])}
        variant="secondary"
        onClick={async (_) => {
          if (localState === "MrcCheckedStateCorrect") {
            setState(undefined);
          } else {
            setState("MrcCheckedStateCorrect");
          }
        }}
      >
        <CheckIcon className="w-4 h-4" />
      </Button>
    </>
  );
};

export const AllDatapointsView = (props: {
  productId: ProductId;
  policyId: PolicyId;
  documentId: MrcDocumentId;
  jumpToBoundingBox: (candidateId: MrcCandidateId) => void;
}): JSX.Element => {
  const extractions = useGetMrcResolvedDatapoints(
    props.productId,
    props.policyId,
    props.documentId,
  );
  throwIfAppError(extractions);

  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState<null | MrcResolvedStatus>(null);
  const anyImported = useGetAnyMrcImported(props.productId, props.policyId);
  throwIfAppError(anyImported);

  // this query is a record of all the datapoints that we need
  const queryString = `{${extractions.value.map((e) => e.extractionKey).join(",")}}`;
  const keyInfosResult = useGetQueryPolicyKeysInfo(
    props.productId,
    props.policyId,
    undefined,
    queryString,
  );

  const keyInfosMap = match(keyInfosResult)
    .with({ status: "success" }, (kir) => {
      const result: Record<string, KeyInfo> = {};
      kir.value.forEach((ki) => {
        result[ki.name] = ki;
      });
      return result;
    })
    .with({ status: "error" }, (kir) => {
      console.log(
        `Query ${queryString} could not be evaluated: ${JSON.stringify(kir.value.message)}`,
      );
      return {} as Record<string, KeyInfo>;
    })
    .exhaustive();

  const orderedExtractions = extractions.value.sort((a, b) => {
    const aKeyInfo = keyInfosMap[a.extractionKey];
    const bKeyInfo = keyInfosMap[b.extractionKey];
    if (aKeyInfo === undefined || bKeyInfo === undefined) {
      return 0;
    }
    return aKeyInfo.sourceOrder - bKeyInfo.sourceOrder;
  });

  return (
    <>
      <div className="bg-gray-50 px-6 py-4 border-b border-gray-200">
        <h2 className="text-base leading-6 font-medium text-gray-900">Extraction results</h2>
      </div>
      <div className="flex flex-row m-6 gap-2">
        <SearchBox onChange={(v) => setQuery(v.trim())} />
        <FilterDropdown active={filter} onChange={setFilter} />
      </div>
      <ul className="overflow-y-auto">
        {orderedExtractions.map((extraction) => {
          const queried =
            query === "" || extraction.displayName.toLowerCase().includes(query.toLowerCase());
          const filtered = filter !== null && extraction.status !== filter;
          if (!queried || filtered) {
            return <li key={extraction.id}></li>;
          }
          const displayNameFromKeyInfos = checkNotUndefined(keyInfosMap[extraction.extractionKey])
            .andThen((keyInfo): Either<string, string> => {
              if (keyInfo.datapoints.length !== 1) {
                return new Left("Datapoints size is not equal to 1");
              } else {
                if (keyInfo.datapoints[0].prompt.tag === "HasValue") {
                  return new Right(keyInfo.datapoints[0].prompt.contents);
                }
                return new Left("Prompt is not defined");
              }
            })
            .getRight();

          const displayName = displayNameFromKeyInfos ?? extraction.displayName;

          return (
            <li key={extraction.id} className="border-b border-gray-200 px-6 py-4">
              <div className="flex flex-row items-start text-gray-700">
                <div className="grow">
                  <h3 className="text-l font-bold flex flex-row">
                    <StatusDotWithTooltip extraction={extraction} />
                    {displayName}
                  </h3>
                  <p className="mt-1">{resolvedValueString(extraction)}</p>
                </div>
                <div className="border border-gray-200 rounded flex flex-row shrink-0 overflow-hidden divide-x">
                  {extraction.resolvedValue.tag === "ResolvedValueExtracted" && (
                    <Button
                      className="h-8 border-0 border-gray-200 rounded-none px-2"
                      variant="secondary"
                      onClick={(_) => {
                        if (extraction.resolvedValue.tag === "ResolvedValueExtracted") {
                          props.jumpToBoundingBox(extraction.resolvedValue.contents.candidateId);
                        }
                      }}
                    >
                      <SearchIcon className="w-4 h-4" />
                    </Button>
                  )}
                  <Link
                    className="shrink-0"
                    to={mrcDocumentSingleDatapointEdit.generatePath({
                      capture: {
                        productId: props.productId,
                        policyId: props.policyId,
                        documentId: props.documentId,
                        extractionId: extraction.id,
                      },
                    })}
                  >
                    <Button className="h-8 border-0 rounded-none px-2" variant="secondary">
                      <PencilAltIcon className="w-4 h-4" />
                    </Button>
                  </Link>
                  <MarkCorrectButtons
                    productId={props.productId}
                    policyId={props.policyId}
                    documentId={props.documentId}
                    extractionId={extraction.id}
                    checkedState={extraction.checkedState}
                    hasImported={anyImported.value}
                  />
                </div>
              </div>
              {extraction.errors.length > 0 && (
                <ul className="mt-2 px-3 py-2 bg-red-50 text-red-700 rounded text-xs leading-4 font-normal list-inside list-disc">
                  {extraction.errors.map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              )}
              {extraction.warnings.length > 0 && (
                <ul
                  style={{ color: "#0369A1" }}
                  className="mt-2 px-3 py-2 bg-blue-50 rounded text-xs leading-4 font-normal list-inside list-disc"
                >
                  {extraction.warnings.map((warning, i) => (
                    <li key={i}>{warning}</li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
