import { XIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React from "react";

/**
 * Clear button atom
 */
export const ClearButton = (props: { onClick: () => void; size: "lg" | "sm" }): JSX.Element => {
  return (
    <button
      onClick={() => {
        props.onClick();
      }}
      className={clsx(
        "inline-flex justify-center items-center w-auto rounded shadow-sm bg-blue-50 text-blue-600 hover:bg-blue-100  focus:outline-none",
        props.size === "lg" ? "px-4 py-2 text-sm font-medium ml-3" : "px-2 py text-xs font-medium",
      )}
    >
      <XIcon className="h-3 -ml-1 mr-1 text-blue-500" />
      Clear
    </button>
  );
};
