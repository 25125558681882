import React from "react";

/**
 * A dropdown item
 */
export const DropdownItem = ({
  label,
  onClick,
  disabled,
  className,
  testid,
}: {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  testid: string;
}) => (
  <div
    data-testid={testid}
    onClick={() => onClick()}
    className={`hover:bg-blue-700 hover:text-white ${
      disabled ? "text-gray-400" : "text-gray-900"
    } cursor-pointer flex items-center p-2 w-full ${className ?? ""}`}
  >
    <div className={`ml-2 block text-sm`}>{label}</div>
  </div>
);
