import React from "react";
import { Autocomplete } from "@mui/lab";
import { TextField } from "./text_component";
import { generalEditorOrSummaryView, SelectableNodeT_, SingleNodeT_ } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";

export interface PercentSelectNodeT_ extends SingleNodeT_<number>, SelectableNodeT_<number> {
  type: "percent_select";
}

export const PercentSelectNode = (props: { node: PercentSelectNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(
    props.node,
    PercentSelectNodeEditorView,
    PercentSelectNodeSummaryView,
  );
};

export const PercentSelectNodeEditorView = (props: { node: PercentSelectNodeT_ }): JSX.Element => {
  const { node } = props;

  // Autocomplete component allows options with structure, not only strings.  We
  // store the original value of the node along with the string, to avoid having
  // to re-parse the label.
  type Option = { label: string; value: number };

  const onChange = (newOption: Option | null) => {
    if (newOption === null) {
      node.commit(null);
    } else {
      node.commit(newOption.value);
    }
  };

  const nodeValueToOption: (v: number) => Option = (v) => ({
    label: v.toLocaleString() + "%",
    value: v,
  });

  return (
    <LeafNode node={node}>
      <Autocomplete
        value={node.value === null ? null : nodeValueToOption(node.value)}
        options={node.options.map(nodeValueToOption)}
        multiple={false}
        onChange={(_evt, value) => onChange(value)}
        disabled={node.readOnly || node.isCommitting}
        getOptionLabel={(o) => o.label}
        // By default, Autocomplete compares the selected option with the available
        // options using strict equality. But that doesn't work
        // well when using objects as the options. Instead, we
        // compare the original values for the node.
        isOptionEqualToValue={(o, v) => {
          return o.value === v.value;
        }}
        renderInput={(params) => <TextField {...params} node={node} />}
      />
    </LeafNode>
  );
};

const PercentSelectNodeSummaryView = (props: { node: PercentSelectNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[props.node.value !== null ? `${props.node.value}%` : ""]}
    />
  );
};
