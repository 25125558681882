import React from "react";
import { Loader } from "/src/design_system/Loader";
import { Skeleton } from "@mui/lab";
import { classNames } from "/src/utils";

type ItemLoaderProps = {
  type?: "skeleton" | "loader";
  size?: "sm" | "lg";
  children?: React.ReactNode;
  className?: string;
};

export const ItemLoader = (props: ItemLoaderProps) => {
  const { type = "loader", size = "sm", children, className } = props;
  return (
    <>
      {type === "skeleton" && (
        <Skeleton height={"100%"} width={"100%"} {...classNames(className ?? "")}>
          {children}
        </Skeleton>
      )}
      {type === "loader" && (
        <div {...classNames(className ?? "", "flex items-center justify-center")}>
          <Loader size={size} />
        </div>
      )}
    </>
  );
};
