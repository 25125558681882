import { Autocomplete } from "@mui/lab";
import React from "react";
import {
  generalEditorOrSummaryView,
  SelectableNodeT_,
  SingleNodeT_,
  useSingleNodeState,
} from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";

export interface TextSuggestNodeT_ extends SingleNodeT_<string>, SelectableNodeT_<string> {
  type: "text_suggest";
}

export const TextSuggestNode = (props: { node: TextSuggestNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(
    props.node,
    TextSuggestNodeEditorView,
    TextSuggestNodeSummaryView,
  );
};

export const TextSuggestNodeEditorView = (props: { node: TextSuggestNodeT_ }) => {
  const { node } = props;

  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = async () => {
    await node.commit(localValue);
  };

  return (
    <LeafNode node={node}>
      <Autocomplete
        value={localValue}
        options={node.options}
        multiple={false}
        onBlur={handleOnBlur}
        disabled={node.readOnly}
        onInputChange={(_evt, value) => {
          setLocalValue(value);
        }}
        freeSolo={true}
        renderInput={(params) => {
          return (
            <div ref={params.InputProps.ref}>
              <TextField {...params} node={node} />
            </div>
          );
        }}
      />
    </LeafNode>
  );
};

const TextSuggestNodeSummaryView = (props: { node: TextSuggestNodeT_ }): JSX.Element => {
  return <SummaryLeafStringNode label={props.node.label} contents={[props.node.value ?? ""]} />;
};
