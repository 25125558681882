import { Table, TableHeader, TableBody, SortOrder } from "/src/design_system/Table";
import { Page, PageHeader, PageTitle, usePageTitle } from "/src/layout";
import {
  useGetProductAnalytics,
  useGetProducts,
  useGetAggregations,
  useGetPrimitiveAggregations,
  useGetCurrentUser,
} from "/src/dal/dal";
import { ClearButton } from "/src/components/ClearButton";
import { ChoroplethMap, RiskLocation } from "/src/components/ChoroplethMap";
import { ProductDropdown } from "/src/components/ProductDropdown";
import React from "react";
import { ResponsiveFunnel } from "@nivo/funnel";
import { Routes } from "/src/routing/routes";
import { useParams } from "/src/routing/routing";
import {
  Aggregations,
  Triggers,
  toConvertedAggregation,
  ConvertedAggregation,
} from "/src/pages/portfolio/aggregations";
import {
  partitionMap,
  Left,
  Right,
  zip,
  capitalize,
  downloadFile,
  scalarToText,
  formatNumber,
  assertNever,
} from "/src/utils";
import { BarChart, Value as BarChartValue } from "/src/components/BarChart";
import { TabPanel } from "/src/design_system/TabPanel";
import { Tabs } from "/src/design_system/Tabs";
import { CanSeeAggregations } from "../policy/permissions";
import { Redirect } from "react-router";
import { DownloadIcon } from "@heroicons/react/solid";
import { throwIfAppError } from "/src/utils/app_error";
import { Scalar } from "/src/internal_types";
import CodeMirror from "/src/components/CodeMirror";

type Widget =
  | {
      type: "Counter";
      title: string;
      value: string;
    }
  | {
      type: "Bar";
      title: string;
      values: BarChartValue[];
    }
  | {
      type: "Choropleth";
      title: string;
      riskLocations: RiskLocation[];
    }
  | {
      type: "Table";
      title: string;
      columnNames: string[];
      rows: Scalar[][];
    }
  | {
      type: "Raw";
      title: string;
      value: string;
    };

type Counter = Extract<Widget, { type: "Counter" }>;

type CompositeWidget = Extract<Widget, { type: "Bar" | "Choropleth" | "Table" | "Raw" }>;

export function Portfolio() {
  return <Redirect to={Routes.portfolioTabs.generatePath({ capture: { tab: "dashboard" } })} />;
}

export const PortfolioTabs: React.FC = () => {
  const {
    data: {
      query: { productId, page },
      capture: { tab },
    },
    setQuery,
    setCapture,
  } = useParams(Routes.portfolioTabs);
  const productsResp = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(productsResp);
  const products = productsResp.value.products;
  usePageTitle("Portfolio");
  // Add more here as needed.
  const hasFilters = productId !== undefined;
  const user = useGetCurrentUser();
  throwIfAppError(user);
  const canSeeAggregations = CanSeeAggregations({ currentRole: user.value.role }).check(undefined);
  const whichTab = capitalize(tab);
  return (
    <Page>
      <PageHeader>
        <div className="flex flex-row justify-between w-full">
          <div>
            <PageTitle>Portfolio</PageTitle>
          </div>
          <div className="flex flex-row items-center space-x-2">
            {hasFilters && (
              <ClearButton
                onClick={() => {
                  setQuery({ productId: undefined });
                }}
                size="lg"
              />
            )}
            <ProductDropdown
              label="Product"
              products={products}
              productId={productId}
              onSelectProduct={(pid) => setQuery({ productId: pid })}
            />
          </div>
        </div>
      </PageHeader>
      <div className="overflow-x-auto flex-1">
        <div className="align-middle bg-white flex w-full h-full mx-auto">
          <div className="flex flex-col flex-1 px-8 w-full">
            <Tabs
              tabs={["Dashboard", "Aggregates", "Triggers"]}
              selected={whichTab}
              onSelect={(tab) => setCapture({ tab: tab.toLowerCase() as any })}
            />
            <TabPanel index={"Dashboard"} value={whichTab}>
              <Dashboard productId={productId} />
            </TabPanel>
            {canSeeAggregations && (
              <>
                <TabPanel index={"Aggregates"} value={whichTab}>
                  <Aggregations
                    productId={productId}
                    products={products}
                    page={page}
                    setPage={(page) => setQuery({ page })}
                  />
                </TabPanel>
                <TabPanel index={"Triggers"} value={whichTab}>
                  <Triggers productId={productId} products={products} />
                </TabPanel>
              </>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

function Dashboard(props: { productId?: number }) {
  const { productId } = props;
  /**
   * Note: we're dealing with a funnel! These stats are
   * *not* the number of submissions in a given status.
   * They are the number of submissions that went through
   * a given status!
   */
  const pipelineData = useGetProductAnalytics(productId);
  throwIfAppError(pipelineData);

  // Pull down aggregations for display in the dashboard
  const aggregationsResp = useGetAggregations({ productId, page: undefined });
  throwIfAppError(aggregationsResp);
  const aggregations = aggregationsResp.value.aggregations
    .filter((x) => productId === x.productId && x.widgetType !== undefined)
    .map(toConvertedAggregation);
  const primitiveAggregationsResp = useGetPrimitiveAggregations();
  throwIfAppError(primitiveAggregationsResp);
  const primitiveAggregations = primitiveAggregationsResp.value.outputs
    .filter(
      ({ aggregation }) =>
        productId === aggregation.productId && aggregation.widgetType !== undefined,
    )
    .map(toConvertedAggregation);
  const allAggregations = aggregations.concat(primitiveAggregations);

  const widgets = getWidgets(allAggregations);
  const [counters, compositeWidgets]: [Counter[], CompositeWidget[]] = partitionMap(widgets, (x) =>
    x.type === "Counter" ? new Left(x) : new Right(x),
  );

  return (
    <div className="mt-4 ml-2">
      <div className="space-y-4 flex-1">
        <Counters counters={counters} />
        <CompositeWidgets widgets={compositeWidgets} {...pipelineData.value} />
      </div>
    </div>
  );
}

function Counters(props: { counters: Counter[] }) {
  return (
    <>
      {props.counters.length > 0 && (
        <div className="space-y-3">
          <div className="bg-white divide-x grid grid-flow-col gap-x-10 border rounded-lg">
            {props.counters.map(({ title, value }) => (
              <Counter title={title} value={value} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function Counter(props: { title: string; value: string }) {
  return (
    <div className="px-6 py-6">
      <div className="font-medium text-gray-700">{props.title}</div>
      <div className="flex items-center">
        <div className="text-gray-900 pr-2 text-3xl font-semibold">{props.value}</div>
      </div>
    </div>
  );
}

function WidgetContainer(
  props: React.PropsWithChildren<{
    title: string;
    buttons?: React.ReactNode;
  }>,
) {
  return (
    <div className="flex space-y-3 flex-col">
      <div className="text-base font-medium leading-6 flex flex-row">
        <div className="flex-grow">{props.title}</div>
        {props.buttons !== undefined && <div>{props.buttons}</div>}
      </div>
      <div className="rounded-lg border overflow-hidden">{props.children}</div>
    </div>
  );
}

function CompositeWidgets(props: {
  widgets: CompositeWidget[];
  boundSubmissions: number;
  quotedSubmissions: number;
  declinedSubmissions: number;
  totalSubmissions: number;
}) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <SubmissionPipeline {...props} />
      {props.widgets.map((widget) => {
        switch (widget.type) {
          case "Bar":
            return (
              <WidgetContainer title={widget.title}>
                <BarChart values={widget.values} />
              </WidgetContainer>
            );
          case "Choropleth":
            return (
              <WidgetContainer title={widget.title}>
                <ChoroplethMap states={widget.riskLocations} />
              </WidgetContainer>
            );
          case "Table":
            return (
              <TableWidget
                columnNames={widget.columnNames}
                rows={widget.rows}
                title={widget.title}
              />
            );
          case "Raw":
            return <RawWidget title={widget.title} value={widget.value} />;
          default:
            assertNever(widget);
        }
      })}
    </div>
  );
}

function SubmissionPipeline(props: {
  quotedSubmissions: number;
  boundSubmissions: number;
  totalSubmissions: number;
}) {
  const data = [
    {
      id: "total_submissions",
      value: props.totalSubmissions,
      label: "Submitted",
    },
    {
      id: "quoted_submissions",
      value: props.quotedSubmissions,
      label: "Quoted",
    },
    {
      id: "bound_submissions",
      value: props.boundSubmissions,
      label: "Bound",
    },
  ];

  const quotedToSubmittedRatio =
    props.totalSubmissions === 0 ? 0 : props.quotedSubmissions / props.totalSubmissions;
  const boundToQuotedRatio =
    props.quotedSubmissions === 0 ? 0 : props.boundSubmissions / props.quotedSubmissions;
  return (
    <WidgetContainer title="Submission pipeline">
      <div className="grid items-start">
        <div className="row-start-1 col-start-1" style={{ height: 340 }}>
          <ResponsiveFunnel
            data={data}
            direction="horizontal"
            colors={"#76A9FA"}
            borderWidth={20}
            beforeSeparatorLength={120}
            afterSeparatorLength={60}
            enableLabel={false}
            animate={true}
            isInteractive={true}
          />
        </div>
        <div className="row-start-1 col-start-1 grid auto-cols-fr grid-flow-col h-28 items-center">
          <div className="text-center font-medium text-gray-700">
            Submitted
            <div className="text-gray-900 font-semibold text-3xl">{props.totalSubmissions}</div>
          </div>
          <div className="text-center font-medium text-gray-700">
            Quoted
            <div className="text-gray-900 font-semibold text-3xl">{props.quotedSubmissions}</div>
          </div>
          <div className="text-center font-medium text-gray-700">
            Bound
            <div className="text-gray-900 font-semibold text-3xl">{props.boundSubmissions}</div>
          </div>
        </div>
        <div className="row-start-1 col-start-1 grid auto-cols-fr grid-flow-col h-28 items-center relative pointer-events-none text-base">
          <div className="row-start-1 col-start-1 col-end-3 text-center font-medium text-gray-700 pointer-events-none">
            <span className="bg-white rounded-full px-4 py-4 ">
              {formatRatio(quotedToSubmittedRatio)} →
            </span>
          </div>
          <div className="row-start-1 col-start-2 col-end-4 text-center font-medium text-gray-700 pointer-events-none">
            <span className="bg-white rounded-full px-4 py-4">
              {formatRatio(boundToQuotedRatio)} →
            </span>
          </div>
        </div>
      </div>
    </WidgetContainer>
  );
}

type Pair<T> = [T, T];

const sortRows = (sortColIdx: number, sortOrder: SortOrder) => (row1: Scalar[], row2: Scalar[]) => {
  const firstScalar = row1[sortColIdx];
  const secondScalar = row2[sortColIdx];

  const [first, second] = ((): Pair<string> | Pair<number> => {
    try {
      return [scalarToNumber(firstScalar), scalarToNumber(secondScalar)];
    } catch {
      // Use lexicographic order for everything else
      return [showScalar(firstScalar), showScalar(secondScalar)];
    }
  })();

  let res = 0;
  if (first < second) {
    res = -1;
  }
  if (first > second) {
    res = 1;
  }
  if (sortOrder === "desc") {
    res = res * -1;
  }
  return res;
};

function quote(x: string) {
  const x_ = x.replace(/"/g, '\\"');
  return `"${x_}"`;
}

function maybeQuote(x: string) {
  const badChars = ['"', " ", ",", "\t", "\n"];
  if (badChars.some((char) => x.indexOf(char) !== -1)) {
    return quote(x);
  } else {
    return x;
  }
}

function downloadCSV(widgetTitle: string, columnNames: string[], rows: Scalar[][]) {
  const header = columnNames.map(maybeQuote).join(",");
  const rows_ = rows
    .map((row) => row.map((value) => maybeQuote(showScalar(value))).join(","))
    .join("\n");
  const res = new Blob([`${header}\n${rows_}`]);
  downloadFile(res, `${widgetTitle}.csv`);
}

function TableWidget(props: { title: string; columnNames: string[]; rows: Scalar[][] }) {
  const { title, columnNames, rows } = props;

  const [{ sortBy, sortOrder }, setSortState] = React.useState({
    sortBy: undefined as undefined | string,
    sortOrder: "desc" as SortOrder,
  });

  const headers = columnNames.map((label) => ({
    label,
    sortBy: label,
  }));

  const sortColIdx = columnNames.findIndex((x) => x === sortBy);
  const sortedRows = sortColIdx < 0 ? rows : rows.sort(sortRows(sortColIdx, sortOrder));
  const tableRows = sortedRows.map((row, idx) => ({
    rowId: idx,
    columns: row.map((value) => ({
      content: prettyScalar(value),
    })),
  }));

  const downloadButton = (
    <DownloadIcon
      className="w-5 h-5 cursor-pointer text-gray-500"
      onClick={() => downloadCSV(title, columnNames, sortedRows)}
    />
  );

  return (
    <WidgetContainer title={title} buttons={downloadButton}>
      <Table>
        <TableHeader
          headers={headers}
          setSortBy={(sortBy, sortOrder) => setSortState({ sortBy, sortOrder })}
          currentSortBy={sortBy}
          currentSortOrder={sortOrder}
        />
        <TableBody rows={tableRows} />
      </Table>
    </WidgetContainer>
  );
}

function RawWidget(props: { title: string; value: string }) {
  const { title, value } = props;
  return (
    <WidgetContainer title={title}>
      <CodeMirror initialText={value} readOnly={true} />
    </WidgetContainer>
  );
}

function formatRatio(ratio: number): string {
  return `${Math.trunc(ratio * 100)}%`;
}

function getAggregationDescription(aggregation: ConvertedAggregation): string {
  return aggregation.description.length > 0 ? aggregation.description : aggregation.name;
}

function getWidgets(aggregations: ConvertedAggregation[]): Widget[] {
  const widgets = [];

  for (const aggregation of aggregations) {
    if (aggregation.widgetType === undefined) {
      continue;
    }

    if (aggregation.type === "Custom") {
      const title = getAggregationDescription(aggregation);
      let value = aggregation.finalValue ?? "No value";
      const num = parseFloat(value);
      if (!isNaN(num)) {
        value = formatNumber(num);
      }
      if (aggregation.widgetType === "Raw") {
        widgets.push({
          type: "Raw" as const,
          title,
          value,
        });
      } else if (aggregation.widgetType === "Counter" && aggregation.finalTyp?.tag === "Scalar") {
        widgets.push({
          type: "Counter" as const,
          title,
          value,
        });
      }
      continue;
    }

    switch (aggregation.widgetType) {
      case "Counter": {
        let value = "";
        if (aggregation.values.length > 0) {
          const firstRow = Object.values(aggregation.values[0]);
          if (firstRow.length > 0) {
            value = prettyScalar(firstRow[0]);
          }
        }
        widgets.push({
          type: "Counter" as const,
          title: getAggregationDescription(aggregation),
          value,
        });
        continue;
      }

      case "Bar": {
        const groupBy = aggregation.projections.find(({ operation }) => operation === "Group By");
        const otherOp = aggregation.projections.find(({ operation }) => operation !== "Group By");
        if (groupBy === undefined || otherOp === undefined) {
          continue;
        }
        const labels = aggregation.values.map((row) => showScalar(row[groupBy.index]));
        const nums = aggregation.values.map((row) => scalarToNumber(row[otherOp.index]));
        const values = zip(labels, nums).map(([label, value]) => ({ label, value }));
        widgets.push({
          type: "Bar" as const,
          title: getAggregationDescription(aggregation),
          values,
        });
        continue;
      }

      case "Choropleth": {
        const riskLocations = [];
        const groupBy = aggregation.projections.find(({ operation }) => operation === "Group By");
        const otherOp = aggregation.projections.find(({ operation }) => operation !== "Group By");
        if (groupBy === undefined || otherOp === undefined) {
          continue;
        }
        const isoCodes = aggregation.values.map((row) => scalarToNumber(row[groupBy.index]));
        const values = aggregation.values.map((row) => scalarToNumber(row[otherOp.index]));
        const max = Math.max(...values);
        for (const [isoCode, value] of zip(isoCodes, values)) {
          const rate = value / max;
          riskLocations.push({ code: isoCode, rate, value });
        }
        widgets.push({
          type: "Choropleth" as const,
          title: getAggregationDescription(aggregation),
          riskLocations,
        });
        continue;
      }

      case "Table": {
        const columnNames = aggregation.projections.map(
          ({ projectAs }, idx) => projectAs ?? idx.toString(),
        );
        const projectionCount = aggregation.projections.length;
        const rows = aggregation.values.map((rowObject) => {
          const values = [];
          for (let i = 0; i < projectionCount; ++i) {
            values.push(rowObject[i]);
          }
          return values;
        });
        widgets.push({
          type: "Table" as const,
          title: getAggregationDescription(aggregation),
          columnNames,
          rows,
        });
        continue;
      }

      default:
        throw new Error(`Widget type ${aggregation.widgetType} not implemented yet`);
    }
  }

  return widgets;
}

function scalarToNumber(s: Scalar): number {
  switch (s.tag) {
    case "Number":
      return s.contents;
    case "Int":
      return s.contents;
    case "Percent":
      return s.contents;
  }
  throw new Error("Expected Number, Int or Percent, got " + s.tag);
}

function showScalar(s: Scalar): string {
  return scalarToText(s).getOrThrow();
}

function prettyScalar(s: Scalar): string {
  try {
    return formatNumber(scalarToNumber(s));
  } catch {
    return showScalar(s);
  }
}

export default Portfolio;
