import React from "react";

export const NodeProblems = (props: { problems: string[] }) => {
  const { problems } = props;

  if (problems.length > 0) {
    return (
      <div className="text-red-600 font-medium text-sm mt-1" data-testid="node-problems">
        <ul className="m-0 space-y-1">
          {problems.map((problem, i) => (
            <li key={i}>{problem}</li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};
