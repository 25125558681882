import React, { useState } from "react";
import { Button } from "/src/design_system/Button";
import { ArrowNarrowLeftIcon, DotsVerticalIcon, UploadIcon } from "@heroicons/react/outline";
import { Policy } from "/src/internal_types";
import { useParams } from "/src/routing/routing";
import { Routes } from "/src/routing/routes";
import { PolicyContext } from "../context";
import { Page } from "/src/layout";
import { Link } from "react-router-dom";
import { PolicyStatusBadge } from "../policies";
import { PolicySaveStatus } from "../modified_date";
import { ConfirmationModal } from "/src/components/ConfirmationModal";
import { ExclamationIcon } from "@heroicons/react/solid";
import * as Popover from "@radix-ui/react-popover";

export const MrcSplitPane = (props: {
  onImport: null | (() => Promise<null | string>);
  onCancel: () => Promise<void>;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
}): JSX.Element => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importModalIsAwaiting, setImportModalIsAwaiting] = useState(false);
  const [errorState, setErrorState] = useState<null | string>(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelModalIsAwaiting, setCancelModalIsAwaiting] = useState(false);

  return (
    <div className="flex flex-col w-full h-full gap-4">
      <div className="w-full grow-0 flex flex-row gap-4 justify-end">
        <Button
          type="button"
          variant="primary"
          disabled={props.onImport === null}
          onClick={(_) => {
            if (props.onImport !== null) {
              setImportModalOpen(true);
              setErrorState(null);
              setImportModalIsAwaiting(false);
            }
          }}
        >
          <UploadIcon className="w-4 h-4 -ml-1 mr-2" />
          Import MRC
        </Button>
        <ConfirmationModal
          isOpen={importModalOpen}
          onClose={() => {
            setImportModalOpen(false);
          }}
          isAwaiting={importModalIsAwaiting}
          onConfirm={async () => {
            if (props.onImport !== null) {
              setImportModalIsAwaiting(true);
              const error = await props.onImport();
              setImportModalIsAwaiting(false);
              setErrorState(error);
              if (error === null) {
                setImportModalOpen(false);
              }
            }
          }}
          title="Are you sure?"
          confirmButtonLabel="Import MRC Data"
        >
          <p className="text-gray-700">
            Are you sure you've finished your work and want to import this MRC?
            <br />
            Your MRC can't be edited past this point.
          </p>
          {errorState !== null && (
            <p className="text-red-700">
              <ExclamationIcon className="w-4 h-4 inline mr-2" />
              {errorState}
            </p>
          )}
        </ConfirmationModal>
        <Popover.Root>
          <Popover.Trigger className="flex select-none font-medium shadow-sm text-sm focus:outline-none focus:shadow-outline flex items-center justify-center text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 active:bg-gray-100 px-4 py-2 rounded-md text-sm">
            <DotsVerticalIcon className="w-4 h-4 -mx-1" />
          </Popover.Trigger>
          <Popover.Content
            align="end"
            className="bg-white rounded-lg shadow-md border border-gray-100 mt-1 text-gray-700 overflow-hidden"
          >
            <p
              className="cursor-pointer py-2 px-4 hover:bg-gray-100"
              onClick={(_) => {
                setCancelModalOpen(true);
                setCancelModalIsAwaiting(false);
              }}
            >
              Cancel import
            </p>
          </Popover.Content>
        </Popover.Root>
        <ConfirmationModal
          isOpen={cancelModalOpen}
          onClose={() => {
            setCancelModalOpen(false);
          }}
          isAwaiting={cancelModalIsAwaiting}
          onConfirm={async () => {
            setCancelModalIsAwaiting(true);
            await props.onCancel();
            setCancelModalIsAwaiting(false);
            setCancelModalOpen(false);
          }}
          title="Are you sure?"
          confirmButtonLabel="Cancel MRC Import"
        >
          <p className="text-gray-700">
            Are you sure you want to cancel importing this MRC?
            <br />
            You will not be able to upload another MRC for this policy.
          </p>
        </ConfirmationModal>
      </div>
      <div className="w-full flex-1 rounded-lg border border-gray-200 flex flex-row overflow-hidden">
        <div className="shrink-0 grow-0 bg-gray-50 w-1/2 border-r border-gray-200">
          {props.leftPanel}
        </div>
        <div className="bg-white grow flex flex-col min-w-0 overflow-auto">{props.rightPanel}</div>
      </div>
    </div>
  );
};

export const MrcPage = (props: {
  policy: Policy;
  children?: React.ReactNode;
  isUpdating: boolean; // TODO
}): JSX.Element => {
  const { data } = useParams(Routes.mrcUpload);
  return (
    <PolicyContext.Provider value={{ policy: props.policy }}>
      <Page>
        <div className="flex-1 flex overflow-hidden">
          <div className="flex-1 flex flex-col min-w-0">
            <div className="bg-white border-b border-gray-200 z-10">
              <div className=" mx-auto h-16 py-3 font-semibold flex items-center justify-between px-6">
                <div className=" h-20 font-semibold flex flex-row items-center justify-between">
                  <Link
                    to={Routes.policies.generatePath({ query: { ...data.query } })}
                    className="group py-2 pr-6"
                  >
                    <ArrowNarrowLeftIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                  </Link>
                  <span className="pr-6 text-lg inline-block">Policy #{props.policy.id}</span>
                  <PolicyStatusBadge policy={props.policy} />
                </div>
                <div className="flex-1"></div>
                <div>
                  <PolicySaveStatus
                    lastUpdatedAt={props.policy.modified ?? props.policy.created.happenedAt}
                    isUpdating={props.isUpdating}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 overflow-y-auto">
              <div className="flex flex-row p-6 mx-auto w-full h-full">{props.children}</div>
            </div>
          </div>
        </div>
      </Page>
    </PolicyContext.Provider>
  );
};
