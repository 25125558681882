import React from "react";

interface TabPanelProps<A> {
  children?: React.ReactNode;
  index: A;
  value: A;
  className?: string;
}

export function TabPanel<A>(props: TabPanelProps<A>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
