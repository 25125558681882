import { Routes } from "../routing/routes";
import { useHistory } from "react-router";
import React from "react";
import { CreateProductsP, Permission } from "../pages/policy/permissions";
import { Button } from "/src/design_system/Button";

/**
 * Button to go to the Create product page.
 */
const CreateProductButton = (props: {
  canCreateProducts: Permission<unknown, unknown, CreateProductsP>;
}): JSX.Element => {
  const [isLoading, _setIsLoading] = React.useState(false);
  const history = useHistory();
  return (
    <>
      {props.canCreateProducts.check(undefined) && (
        <Button
          data-testid="create-new-product"
          type="button"
          variant="primary"
          onClick={async () => {
            history.push(Routes.createProduct.generatePath({}));
          }}
          disabled={isLoading}
          className="space-x-2 mr-2"
        >
          <span>New product</span>
        </Button>
      )}
    </>
  );
};

export default CreateProductButton;
