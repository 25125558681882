import { Button as RkButton, ButtonProps as RkButtonProps } from "reakit/Button";
import clsx, { ClassValue } from "clsx";

import React from "react";

export type ButtonVariant = "primary" | "secondary" | "danger" | "danger-secondary";

export type ButtonSize = "xs" | "sm" | "md";

export interface ButtonProps extends RkButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  className?: string;
  isPending?: boolean;
}

export const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const {
    variant = "primary",
    size = "md",
    fullWidth,
    isPending,
    disabled,
    className,
    ...restProps
  } = props;

  return (
    <RkButton
      ref={ref}
      {...restProps}
      disabled={disabled || isPending}
      className={clsx([
        "select-none",
        "font-medium",
        "shadow-sm",
        "text-sm",
        "focus:outline-none",
        "focus:shadow-outline",
        "flex",
        "items-center",
        "justify-center",
        fullWidth && "w-full",
        (disabled || isPending) && "opacity-50",
        getClsByVariant(variant),
        getClsBySize(size),
        className,
      ])}
    >
      {isPending && getPendingIndicator()}
      {props.children}
    </RkButton>
  );
});

function getClsByVariant(variant: ButtonVariant): ClassValue[] {
  switch (variant) {
    case "primary":
      return ["text-white", "bg-blue-600", "hover:bg-blue-700"];
    case "secondary":
      return [
        "text-gray-700",
        "border",
        "border-gray-300",
        "bg-white",
        "hover:bg-gray-50",
        "active:bg-gray-100",
      ];
    case "danger":
      return ["text-white", "bg-red-600", "hover:bg-red-700"];
    case "danger-secondary":
      return [
        "border",
        "border-gray-300",
        "text-red-600",
        "bg-white",
        "hover:bg-gray-50",
        "active:bg-gray-100",
      ];
  }
}

function getClsBySize(size: ButtonSize): ClassValue[] {
  switch (size) {
    case "xs":
      return ["px-2.5", "py-1.5", "rounded", "text-xs"];
    case "sm":
      return ["px-3", "py-2", "leading-4", "rounded-md", "text-sm"];
    case "md":
      return ["px-4", "py-2", "rounded-md", "text-sm"];
  }
}

function getPendingIndicator(): JSX.Element {
  return (
    <svg viewBox="0 0 10 10" className="h-5 mr-1">
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1">
        <g>
          <path d="M5,2 A3 3 0 0 1 5 8">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              dur="0.7s"
              from="0 5 5"
              repeatCount="indefinite"
              to="360 5 5"
              type="rotate"
            />
          </path>
        </g>
      </g>
    </svg>
  );
}
