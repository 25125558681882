import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Banner from "../../banner";
import Input from "../../input";
import { Button } from "../../design_system/Button";
import { PublicRoutes } from "../../routing/routes";
import Layout from "./Layout";
import { queryClient } from "/src/dal/dal";

export interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const { push } = useHistory();
  const [formValues, setFormValues] = useState<LoginFormValues>({
    email: "",
    password: "",
  });

  const [formError, setFormError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const isValid = formValues.email.trim().length > 0 && formValues.password.length > 0;
  const canSubmit = !isLoading && isValid;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setFormError(null);

    try {
      const user = await aws_amplify.Auth.signIn(formValues.email.trim(), formValues.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return push(PublicRoutes.setupPassword.generatePath({}, formValues));
      }

      // TODO: quick fix, need to do this differently
      // The problem this is attempting to solve is that
      // the auth status (referenced in App.tsx) could still be
      // signed_out, which would redirect back to this login form.
      queryClient.setQueryData("authStatus", "signed_in");

      return push("/");
    } catch (error: any) {
      if (
        typeof error === "object" &&
        error !== null &&
        "message" in error &&
        error["message"] !== undefined
      ) {
        setFormError(error.message);
        setIsLoading(false);
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

  return (
    <Layout>
      {formError != null && <Banner className="mb-8 w-full" variant="error" label={formError} />}
      <h3 className="text-3xl leading-8 font-bold mb-6">Sign in</h3>
      <form onSubmit={onSubmit}>
        <Input
          className="mb-2"
          label="Email address"
          name="email"
          onChange={onChange}
          type="email"
          value={formValues.email}
        />
        <Input
          className="mb-2"
          label="Password"
          name="password"
          onChange={onChange}
          type="password"
          value={formValues.password}
        />

        <Link
          to={PublicRoutes.forgotPassword.generatePath({})}
          className="text-blue-600 hover:text-blue-500 text-sm font-medium block mb-6 text-right"
        >
          Forgot your password?
        </Link>
        <Button fullWidth type="submit" disabled={!canSubmit}>
          Sign in
        </Button>
      </form>
    </Layout>
  );
};

export default Login;
