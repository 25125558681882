import { ClockIcon, ExclamationIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { match, P } from "ts-pattern";
import React from "react";
import { UnifiedTaskId } from "../internal_types";
import { Button } from "../design_system/Button";
import { filterMap } from "/src/utils";
import { encodeTaskId, PromiseViewer } from "/src/components/TaskProvider";
import {
  startAsyncForProductPolicyQuote,
  startAsyncForProductPolicy,
  cancelAsyncForProductPolicyQuote,
  cancelAsyncForProductPolicy,
  useGetCurrentUser,
} from "/src/dal/dal";
import { PromiseNodeT_ } from "../data_editor/nodes/promise_node";
import clsx from "clsx";
import { useHistory } from "react-router";
import { Routes } from "../routing/routes";
import { throwIfAppError } from "../utils/app_error";
import {
  CanCancelSequelHubTask,
  CanStartSequelHubTask,
  CanViewTasks,
} from "../pages/policy/permissions";

export type PromiseProps = {
  node: PromiseNodeT_;
  ident: string; // INVARIANT: Must not contain parentheses, this is the ident specifically.
};

type PromiseStatus =
  | { type: "ready" }
  | { type: "is_running"; unifiedTaskId: UnifiedTaskId }
  | { type: "completed"; unifiedTaskId: UnifiedTaskId }
  | { type: "failed"; unifiedTaskId: UnifiedTaskId }
  | { type: "not_ready" }
  | { type: "broken" };

/**
 * Promise component.
 */
export const Promise = (props: PromiseProps): JSX.Element => {
  const user = useGetCurrentUser();
  throwIfAppError(user);
  const history = useHistory();

  const promiseStatus: PromiseStatus = match(props.node.asyncStatus)
    .with({ tag: "HasValue" }, (asyncStatus) => {
      return match<typeof asyncStatus.contents, PromiseStatus>(asyncStatus.contents)
        .with({ tag: "AsyncHasEvalError" }, () => ({ type: "not_ready" }))
        .with(
          {
            tag: P.union("AsyncIsReady", "AsyncRunning", "AsyncCompleted", "AsyncFailed"),
          },
          () => {
            // TODO: all of the state of the async we are interested in is
            // present in asyncStatus. We don't need taskState but invalidating
            // the policy cache and refreshing the UI Promise component correctly
            // is difficult.
            return match<typeof props.node.taskState, PromiseStatus>(props.node.taskState)
              .with({ tag: "NotRunning" }, () => ({ type: "ready" }))
              .with({ tag: "Running" }, (taskState) => ({
                type: "is_running",
                unifiedTaskId: taskState.unifiedTaskId,
              }))
              .with({ tag: "Completed" }, (taskState) => ({
                type: "completed",
                unifiedTaskId: taskState.unifiedTaskId,
              }))
              .with({ tag: "Failed" }, (taskState) => ({
                type: "failed",
                unifiedTaskId: taskState.unifiedTaskId,
              }))
              .exhaustive();
          },
        )
        .exhaustive();
    })
    .otherwise(() => ({ type: "broken" }));

  const actionable = props.node.summaryView === false && props.node.readOnly === false;
  const mTaskId = "unifiedTaskId" in promiseStatus ? promiseStatus.unifiedTaskId : undefined;

  const globalContext = { currentRole: user.value.role };
  const tasksContext = props.node.taskState;
  const canViewTasks = CanViewTasks(globalContext).check(undefined);
  const canStartTask = CanStartSequelHubTask(globalContext).check(tasksContext);
  const canCancelTask = CanCancelSequelHubTask(globalContext).check(tasksContext);

  const actions: TaskViewProps["actions"] = filterMap(
    [
      canViewTasks && mTaskId !== undefined
        ? {
            label: "See task",
            variant: "secondary" as const,
            onClick: () => {
              const [taskTag, taskId] = encodeTaskId(mTaskId);
              history.push(Routes.taskRuns.generatePath({ capture: { taskTag, taskId } }));
            },
          }
        : undefined,
      actionable && canStartTask
        ? {
            label: match(promiseStatus)
              .with({ type: "ready" }, () => "Start now")
              .with({ type: "failed" }, () => "Retry")
              .otherwise(() => "Start now"),
            onClick: async () => {
              if (props.node.mquoteId !== undefined) {
                await startAsyncForProductPolicyQuote(
                  props.node.productId,
                  props.node.policyId,
                  props.node.mquoteId,
                  props.ident,
                );
              } else {
                await startAsyncForProductPolicy(
                  props.node.productId,
                  props.node.policyId,
                  props.ident,
                );
              }
            },
          }
        : undefined,
      actionable && canCancelTask
        ? {
            label: "Cancel",
            onClick: () => {
              if (props.node.mquoteId !== undefined) {
                return cancelAsyncForProductPolicyQuote(
                  props.node.productId,
                  props.node.policyId,
                  props.node.mquoteId,
                  props.ident,
                );
              } else {
                return cancelAsyncForProductPolicy(
                  props.node.productId,
                  props.node.policyId,
                  props.ident,
                );
              }
            },
          }
        : undefined,
    ],
    (action) => action,
  );

  const taskViewPropsWithoutActions = match<PromiseStatus, Omit<TaskViewProps, "actions">>(
    promiseStatus,
  )
    .with({ type: "broken" }, () => ({
      caption: "In a bad state. Please report this as a bug.",
    }))
    .with({ type: "not_ready" }, () => ({
      className: "text-slate-500",
      caption: actionable
        ? "Please fill out all the necessary fields."
        : "Missing required fields.",
    }))
    .with({ type: "ready" }, () => ({
      caption: actionable ? "Ready to run." : "Not started yet.",
    }))
    .with({ type: "is_running" }, () => ({
      caption: "Running...",
      icon: ClockIcon,
    }))
    .with({ type: "failed" }, () => ({
      caption: "Failed.",
      icon: ExclamationIcon,
    }))
    .with({ type: "completed" }, () => ({
      caption: "Completed successfully.",
      icon: CheckCircleIcon,
    }))
    .exhaustive();

  const taskViewProps: TaskViewProps = { ...taskViewPropsWithoutActions, actions };

  return (
    <div className="bg-gray-100 border p-5 rounded flex flex-col">
      <div className="flex flex-row">
        <div className="p-2 font-bold text-slate-800">
          Task{mTaskId === undefined ? "" : ` #${encodeTaskId(mTaskId)[1]}`}
        </div>
        <TaskView {...taskViewProps} />
      </div>

      {props.node.promiseId !== undefined && (
        <PromiseViewer
          productId={props.node.productId}
          policyId={props.node.policyId}
          promiseId={props.node.promiseId}
        />
      )}
    </div>
  );
};

type TaskViewProps = {
  icon?: (props: React.ComponentProps<"svg">) => JSX.Element;
  className?: string;
  caption: string;
  actions: {
    label: string;
    onClick: () => void;
    variant?: "primary" | "secondary";
  }[];
  unifiedTaskId?: UnifiedTaskId;
};

const TaskView = (props: TaskViewProps) => {
  const Icon = props.icon === undefined ? () => <></> : props.icon;
  return (
    <>
      {props.icon !== undefined && (
        <div className="py-2 pl-2">
          <Icon className="w-5 h-5" />
        </div>
      )}
      <div className={clsx("p-2", props.className)}>{props.caption}</div>
      {props.unifiedTaskId !== undefined && <SeeTaskButton unifiedTaskId={props.unifiedTaskId} />}
      {props.actions.map((action) => (
        <Button key={action.label} onClick={action.onClick} variant={action.variant}>
          {action.label}
        </Button>
      ))}
    </>
  );
};

const SeeTaskButton = (props: { unifiedTaskId: UnifiedTaskId }) => {
  const history = useHistory();
  const [taskTag, taskId] = encodeTaskId(props.unifiedTaskId);
  return (
    <Button
      variant="secondary"
      onClick={() => {
        history.push(Routes.taskRuns.generatePath({ capture: { taskTag, taskId } }));
      }}
    >
      See task
    </Button>
  );
};
