import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Path } from "/src/routing/routing";

export const BreadcrumbBackArrow = (): JSX.Element => {
  return <ArrowLeftIcon className="h-4 w-4 inline text-gray-400 mr-1" />;
};

export const BreadcrumbWrapper = (props: { children?: React.ReactNode }): JSX.Element => {
  return (
    <div className="text-sm leading-4 font-medium flex gap-1 items-center">{props.children}</div>
  );
};

export const BreadcrumbLink = (props: {
  to: Path;
  truncate?: boolean;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <Link
      className={
        "whitespace-nowrap cursor-pointer hover:underline focus:underline " +
        (props.truncate ? "min-w-0 truncate shrink" : "flex-none")
      }
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

export const BreadcrumbText = (props: {
  grow?: boolean;
  truncate?: boolean;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <span
      className={
        "whitespace-nowrap text-gray-400 " +
        (props.grow ? "grow " : "") +
        (props.truncate ? "min-w-0 truncate shrink" : "flex-none")
      }
    >
      {props.children}
    </span>
  );
};

export const BreadcrumbSep = (): JSX.Element => {
  return <span className="flex-none text-gray-300"> / </span>;
};
