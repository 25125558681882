import React from "react";
import { useParams } from "/src/routing/routing";
import { Routes } from "/src/routing/routes";
import { Page, usePageTitle } from "/src/layout";
import { AggregationForm, toConvertedAggregation, NewAggregation } from "./aggregations";
import {
  apiPut,
  apiPatch,
  invalidatePath,
  useGetProducts,
  useGetAggregations,
  useGetPrimitiveAggregations,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import NotFound from "/src/pages/404";

export function EditAggregation() {
  const params = useParams(Routes.editAggregation);
  const aggregationType = params.data.capture.aggregationType;
  const aggregationId = params.data.capture.aggregationId;

  usePageTitle(`Aggregation #${aggregationId}`);

  const products = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(products);
  const aggregationsResp = useGetAggregations({});
  const primitiveAggregationsResp = useGetPrimitiveAggregations();
  throwIfAppError(aggregationsResp);
  throwIfAppError(primitiveAggregationsResp);
  const aggregations =
    aggregationType === "custom"
      ? aggregationsResp.value.aggregations.map(toConvertedAggregation)
      : primitiveAggregationsResp.value.outputs.map(toConvertedAggregation);
  const aggregation = aggregations.find(({ id }) => id === aggregationId);

  if (aggregation === undefined) {
    return <NotFound />;
  }

  const productEditable = aggregation.triggers.length === 0;
  const initialError = aggregation.type === "Grouped" ? undefined : aggregation.error;

  const onSubmit = async (newAggregation: NewAggregation) => {
    let error: string | undefined;

    if (newAggregation.type === "Primitive") {
      await apiPut("/api/stats/primitive-aggregation/{primitiveAggregationId}", {
        params: { primitiveAggregationId: aggregationId },
        query: {},
        body: newAggregation,
      });
      await invalidatePath("/api/stats/primitive-aggregation");
    } else if (newAggregation.type === "Brossa") {
      const res = await apiPatch("/api/stats/aggregation/{id}", {
        params: { id: aggregationId },
        query: {},
        body: newAggregation,
      });
      if (res.status === "error") {
        error = res.value.message;
      }
      await invalidatePath("/api/stats/aggregation");
    }

    return error;
  };

  return (
    <Page>
      <AggregationForm
        title="Edit aggregation"
        onSubmit={onSubmit}
        products={products.value.products}
        initialFields={aggregation}
        productEditable={productEditable}
        functionEditable={false}
        initialError={initialError}
        existingAggregation={aggregation}
      />
    </Page>
  );
}
