import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "/src/design_system/Button";
import { Scalar, SovScalarTyp, SovSpecInfo } from "/src/internal_types";
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import clsx from "clsx";
import * as AgGridReact from "ag-grid-react";
import * as AgGrid from "ag-grid-community";
import "ag-grid-enterprise";
import { assertNever } from "/src/utils";
import * as Popover from "@radix-ui/react-popover";

export type FormatCellsPageState = {
  initialTableData: SovTable;
};

const ROW_COLUMN_KEY = "brossa_row_index";

type ParseResult = { tag: "failed" } | { tag: "success"; content: null | Scalar };

const parseDatapoint = (typ: SovScalarTyp, value: any): ParseResult => {
  if (value === null || value === "") {
    return { tag: "success", content: null };
  }
  // TODO create date and uuid parsers
  if (typ === "Text" || typ === "CaselessText" || typ === "Date" || typ === "Uuid") {
    const str = String(value);
    return { tag: "success", content: { tag: typ, contents: str } };
  } else if (typ === "Int") {
    const number = Number.parseInt(value);
    if (isNaN(number)) {
      return { tag: "failed" };
    } else {
      return { tag: "success", content: { tag: typ, contents: number } };
    }
    // TODO percent parser?
  } else if (typ === "Number" || typ === "Percent") {
    const number = Number.parseFloat(value);
    if (isNaN(number)) {
      return { tag: "failed" };
    } else {
      return { tag: "success", content: { tag: typ, contents: number } };
    }
  } else if (typ === "Bool") {
    // Strings with values are truthy in JS, so we parse some common values
    const v = String(value).toLowerCase().trim();
    if (v === "yes" || v === "true" || v === "1") {
      return { tag: "success", content: { tag: typ, contents: true } };
    } else if (v === "no" || v === "false" || v === "0") {
      return { tag: "success", content: { tag: typ, contents: false } };
    } else {
      return { tag: "failed" };
    }
  } else {
    assertNever(typ);
  }
};

export type ExtractedColumn = {
  datapointId: string;
  values: any[];
};

export type ExtractedTable = ExtractedColumn[];

// Take data from the table and convert it into something the API can consume
const extractTableData = (
  specInfo: SovSpecInfo,
  grid: AgGridReact.AgGridReact<any>,
  hiddenRows: number[],
  hiddenCols: string[],
): null | ExtractedTable => {
  const columnsWithData: { [key: string]: (null | Scalar)[] } = {};
  grid.api.forEachNode((rowNode: AgGrid.RowNode<any>) => {
    const rowHidden = hiddenRows.includes(rowNode.data[ROW_COLUMN_KEY]);
    if (!rowHidden) {
      Object.keys(rowNode.data).forEach((key: string) => {
        const colHidden = hiddenCols.includes(key);
        if (key !== ROW_COLUMN_KEY && !colHidden) {
          const datapointInfo = specInfo[key];
          if (datapointInfo !== undefined) {
            const parsed = parseDatapoint(datapointInfo.typ, rowNode.data[key]);
            if (parsed.tag === "failed") {
              return null;
            }
            if (key in columnsWithData) {
              columnsWithData[key].push(parsed.content);
            } else {
              columnsWithData[key] = [parsed.content];
            }
          }
        }
      });
    }
  });
  const result: ExtractedColumn[] = [];
  Object.keys(columnsWithData).forEach((datapointId: string) => {
    result.push({
      datapointId,
      values: columnsWithData[datapointId],
    });
  });
  return result;
};

export type ImportToPolicy = (initialTableData: ExtractedTable) => Promise<null | string>;

export const FormatCellsPage = ({
  specInfo,
  pageState,
  importToPolicy,
  moveToNextPage,
}: {
  specInfo: SovSpecInfo;
  pageState: FormatCellsPageState;
  importToPolicy: ImportToPolicy;
  moveToNextPage: () => void;
}): JSX.Element => {
  const [isImporting, setImporting] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact.AgGridReact<any>>(null);
  const [hiddenRows, setHiddenRows] = useState<number[]>([]);
  const [hiddenCols, setHiddenCols] = useState<string[]>([]);

  return (
    <>
      <div className="flex flex-row items-center gap-6">
        <h1 className="text-gray-900 text-xl leading-7 font-bold grow">Format cell data</h1>
        <Button
          isPending={isImporting}
          onClick={async () => {
            if (gridRef.current === null) {
              return;
            }
            setImporting(true);
            const extractedData = extractTableData(
              specInfo,
              gridRef.current,
              hiddenRows,
              hiddenCols,
            );
            if (extractedData === null) {
              console.log("Cannot import: fix values");
              setImporting(false);
              return;
            }
            const importResult = await importToPolicy(extractedData);
            if (importResult !== null) {
              console.log("Failed import: %s", importResult);
              setImporting(false);
            } else {
              console.log("Import completed successfully!");
              setImporting(false);
              moveToNextPage();
            }
          }}
        >
          Import to policy
        </Button>
      </div>
      <AgGridTable
        specInfo={specInfo}
        gridRef={gridRef}
        initTable={pageState.initialTableData}
        hiddenRows={hiddenRows}
        setHiddenRows={setHiddenRows}
        hiddenCols={hiddenCols}
        setHiddenCols={setHiddenCols}
        isImporting={isImporting}
      />
    </>
  );
};

export type SovColumn = {
  datapointId: string;
  prettyName: string;
  targetType: SovScalarTyp;
  values: any[];
};
export type SovTable = SovColumn[];

export const AgGridTable = (props: {
  specInfo: SovSpecInfo;
  gridRef: RefObject<AgGridReact.AgGridReact<any>>;
  initTable: SovTable;
  hiddenRows: number[];
  setHiddenRows: (rows: number[]) => void;
  hiddenCols: string[];
  setHiddenCols: (cols: string[]) => void;
  isImporting: boolean;
}): JSX.Element => {
  const numColumns = props.initTable.length;
  const numRows = props.initTable[0].values.length;

  const rowData: { [key: string]: any }[] = useMemo(() => {
    const rowData = [];
    for (let i = 0; i < numRows; i++) {
      const row: { [key: string]: any } = {};
      row[ROW_COLUMN_KEY] = i + 1;
      for (let j = 0; j < numColumns; j++) {
        const column = props.initTable[j];
        row[column.datapointId] = column.values[i];
      }
      rowData.push(row);
    }
    return rowData;
  }, [props.initTable]);

  const onGridReady = useCallback((event: AgGrid.GridReadyEvent) => {
    // Not defining a min width causes the columns to be too narrow and cramped
    // when there are many.
    event.api.sizeColumnsToFit({ defaultMinWidth: 150 });
  }, []);

  // Force a redraw so the rows get the correct style.
  useEffect(() => {
    if (props.gridRef.current !== null) {
      if (props.gridRef.current.api !== undefined) {
        props.gridRef.current.api.redrawRows({});
      }
    }
  }, [props.hiddenRows]);

  const onClickHeader = useCallback(
    (props: AgGrid.IHeaderParams, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const direction = props.column.isSortAscending() ? "desc" : "asc";
      if (props.enableSorting) {
        props.setSort(direction, event.shiftKey);
      }
    },
    [],
  );

  const indexHeaderComponent = useCallback(
    (props: AgGrid.IHeaderParams): JSX.Element => {
      return (
        <div className="-mx-px min-w-20 h-full" onClick={(event) => onClickHeader(props, event)} />
      );
    },
    [onClickHeader],
  );

  const headerComponent = useCallback(
    (params: AgGrid.IHeaderParams): JSX.Element => {
      const colId = params.column.getColId();
      const isHidden = props.hiddenCols.includes(colId);
      let invalidCount = 0;
      params.api.forEachNode((rowNode: AgGrid.RowNode<any>, _index: number) => {
        const datapointInfo = props.specInfo[colId];
        if (datapointInfo !== undefined) {
          const data = rowNode.data[colId];
          const parsed = parseDatapoint(datapointInfo.typ, data);
          if (parsed.tag === "failed") {
            invalidCount += 1;
          }
        }
      });
      return (
        <div
          className={clsx([
            "-mx-px min-w-20 h-full flex flex-row gap-1 items-center overflow-hidden px-3 text-xs leading-4 font-medium",
            invalidCount > 0 && "bg-red-100 border-x border-red-300",
            props.hiddenCols.includes(colId) && "opacity-30",
          ])}
          onClick={(event) => onClickHeader(params, event)}
        >
          {/* TODO replace with correct type */}
          {/*<DocumentTextIcon className={clsx([
          "h-4 shrink-0 mr-1",
          invalidCount > 0 ? "text-red-900" : "text-gray-400"
        ])} />*/}
          <p
            className={clsx(["truncate grow", invalidCount > 0 ? "text-red-900" : "text-gray-500"])}
          >
            {params.displayName}
          </p>
          {invalidCount > 0 && (
            <>
              <ExclamationCircleIcon className="h-5 shrink-0 text-red-500" />
              <span className="text-sm leading-5 font-medium text-red-500">{invalidCount}</span>
            </>
          )}
          <Popover.Root>
            <Popover.Trigger
              onClick={(e) => e.stopPropagation()}
              className={clsx([
                "w-5 h-5 shrink-0 flex items-center justify-center rounded-sm",
                invalidCount > 0
                  ? "bg-red-200 hover:bg-red-300/50"
                  : "bg-transparent hover:bg-gray-200",
              ])}
            >
              <ChevronDownIcon
                className={clsx(["h-5", invalidCount > 0 ? "text-red-500" : "text-gray-500"])}
              />
            </Popover.Trigger>
            <Popover.Content
              onClick={(e) => e.stopPropagation()}
              align="start"
              className="bg-white flex flex-col overflow-hidden rounded-md ring-1 ring-black ring-opacity-5 shadow-lg divide-y divide-gray-200"
            >
              {invalidCount > 0 && (
                <div className="px-4 py-2 flex items-center gap-2">
                  <ExclamationCircleIcon className="h-4 shrink-0 text-red-500" />
                  <div className="grow">Column has invalid data</div>
                </div>
              )}
              {isHidden ? (
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100 flex items-center gap-2"
                  onClick={(_) => {
                    const cols = [...props.hiddenCols];
                    const i = cols.indexOf(colId);
                    delete cols[i];
                    props.setHiddenCols(cols);
                  }}
                >
                  <EyeIcon className="h-4 shrink-0 text-gray-400" />
                  <div className="grow">Import column</div>
                </div>
              ) : (
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100 flex items-center gap-2"
                  onClick={(_) => {
                    const cols = [...props.hiddenCols];
                    cols.push(colId);
                    props.setHiddenCols(cols);
                  }}
                >
                  <EyeOffIcon className="h-4 shrink-0 text-gray-400" />
                  <div className="grow">Ignore column</div>
                </div>
              )}
            </Popover.Content>
          </Popover.Root>
        </div>
      );
    },
    [rowData, onClickHeader, props.hiddenCols, props.setHiddenCols],
  );

  const indexCellComponent = useCallback((props: AgGrid.ICellRendererParams): JSX.Element => {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-xs leading-none font-normal text-gray-400">{props.value}</p>
      </div>
    );
  }, []);

  const cellComponent = useCallback(
    (params: AgGrid.ICellRendererParams): JSX.Element => {
      // Default to text
      let target_type: SovScalarTyp = "Text";
      if (params.column !== undefined) {
        const colId = params.column.getColId();
        const datapointInfo = props.specInfo[colId];
        if (datapointInfo !== undefined) {
          target_type = datapointInfo.typ;
        }
      }
      const parsed = parseDatapoint(target_type, params.value);
      return (
        <div
          className={clsx([
            "w-full h-full flex flex-row items-center overflow-hidden px-3 py-2",
            parsed.tag === "failed" && "bg-red-50 hovered-red-cell",
          ])}
        >
          {/* Handy for debugging
        <div className="absolute right-0 top-0" style={{ fontSize: 5, lineHeight: 1 }}>
          [{target_type}] {parsed}
        </div>
        */}
          <p className="truncate grow text-gray-900">{params.value}</p>
          {parsed.tag === "failed" && (
            <ExclamationCircleIcon className="h-5 shrink-0 text-red-500" />
          )}
        </div>
      );
    },
    [props.specInfo, props.hiddenCols],
  );

  const onCellValueChanged = useCallback((event: AgGrid.NewValueParams<any>) => {
    event.api.refreshHeader();
  }, []);

  const columnDefs = useMemo(() => {
    const indexColumn: AgGridReact.AgGridColumnProps = {
      field: ROW_COLUMN_KEY,
      headerName: "",
      headerComponent: indexHeaderComponent,
      cellRenderer: indexCellComponent,
      sortable: true,
      width: 54,
    };
    const dataColumns: AgGridReact.AgGridColumnProps[] = props.initTable.map((column) => ({
      field: column.datapointId,
      headerName: column.prettyName,
      headerComponent: headerComponent,
      cellRenderer: cellComponent,
      cellClassRules: {
        "opacity-30 bg-gray-300": (_: AgGrid.CellClassParams) => {
          return props.hiddenCols.includes(column.datapointId);
        },
      },
      onCellValueChanged: onCellValueChanged,
      resizable: true,
      editable: !props.isImporting,
      sortable: true,
    }));
    return [indexColumn].concat(dataColumns);
  }, [props.initTable, props.hiddenCols, props.isImporting]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const getContextMenuItems = useCallback(
    (params: AgGrid.GetContextMenuItemsParams) => {
      const rowIndex: number | null | undefined = params.node?.data[ROW_COLUMN_KEY];
      const results = [];
      results.push("copy");
      results.push("copyWithHeaders");
      results.push("paste");
      if (params.column?.getColId() === ROW_COLUMN_KEY) {
        results.push("separator");
        if (rowIndex !== undefined && rowIndex !== null) {
          const isHidden = props.hiddenRows.includes(rowIndex);
          results.push({
            name: isHidden ? "Import row" : "Ignore row",
            icon: isHidden
              ? '<span class="ag-icon ag-icon-eye"></span>'
              : '<span class="ag-icon ag-icon-eye-slash"></span>',
            action: () => {
              if (isHidden) {
                const rows = [...props.hiddenRows];
                const i = rows.indexOf(rowIndex);
                delete rows[i];
                props.setHiddenRows(rows);
              } else {
                props.setHiddenRows([rowIndex, ...props.hiddenRows]);
              }
            },
          });
        }
      }
      return results;
    },
    [props.hiddenRows],
  );

  return (
    <div className="-mx-8 ag-theme-alpine grow">
      <AgGridReact.AgGridReact
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={30}
        enableRangeSelection={true}
        statusBar={statusBar}
        getContextMenuItems={getContextMenuItems}
        getRowId={(params) => params.data[ROW_COLUMN_KEY]}
        rowClassRules={{
          "opacity-30 bg-gray-300": (params) => {
            return props.hiddenRows.includes(params.data[ROW_COLUMN_KEY]);
          },
        }}
        ref={props.gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        suppressFieldDotNotation={true}
      />
    </div>
  );
};
