import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routing/routes";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Link } from "react-router-dom";
import { useParams } from "../routing/routing";
import { ProductEditor, Meta, OnFinish, File } from "../components/ProductEditor";
import { useGetPendingProductEditable } from "../dal/dal";
import { setProductPending } from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";

////////////////////////////////////////////////////////////////////////////////
// Main entry point

const CreateProduct: React.FC = () => {
  const history = useHistory();
  const {
    data: {
      capture: { pendingProductId },
    },
  } = useParams(Routes.editPendingProduct);
  const product = useGetPendingProductEditable(pendingProductId);
  throwIfAppError(product);
  const files = product.value.sourceTextFiles.map((file) => ({
    name: file.fileName,
    contents: file.fileContents,
  }));
  return (
    <Page>
      <PageHeader>
        <EditProductHeader productId={pendingProductId} />
      </PageHeader>
      <PageContent>
        <ProductEditor
          actionButtonTitle="Save"
          name={product.value.name}
          files={files}
          readOnly={false}
          onSubmit={(files, meta, onFinish, onError) =>
            editTheProduct(pendingProductId, files, meta, onFinish, onError)
          }
          onFinish={(id) =>
            history.push(Routes.pendingProduct.generatePath({ capture: { pendingProductId: id } }))
          }
        />
      </PageContent>
    </Page>
  );
};

const EditProductHeader = (props: { productId: number }) => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <Link
          to={Routes.pendingProduct.generatePath({
            capture: { pendingProductId: props.productId },
          })}
          className="group py-2 pr-6 flex flex-row"
        >
          ← Back to pending product
        </Link>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div>
          <PageTitle>Edit pending product</PageTitle>
        </div>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Functions

const editTheProduct = async (
  pendingProductId: number,
  files: File[],
  meta: Meta,
  onFinish: OnFinish,
  onError: (error: string) => void,
) => {
  await setProductPending(pendingProductId, meta.name, files).then((r) => {
    if (r.status === "success") {
      onFinish(pendingProductId);
    } else {
      onError(`Couldn't edit product. The error was ${r.value.message}`);
    }
  });
};

export default CreateProduct;
