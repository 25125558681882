import React, { useCallback } from "react";
import clsx from "clsx";
import { ChevronRight } from "@mui/icons-material";
import {
  ColumnMappingPageState,
  ColumnMappingPage,
  preselectMostLikelyCandidates,
  GetExtractedTable,
} from "./column_mapping";
import {
  GetTabs,
  SelectTabsAndGetCandidates,
  UploadAndGetTabs,
  UploadPage,
  UploadPageState,
} from "./upload_page";
import {
  ExtractedColumn,
  ExtractedTable,
  FormatCellsPage,
  FormatCellsPageState,
  ImportToPolicy,
} from "./format_cells";
import { PolicyContext } from "../context";
import {
  createAdditionFact,
  createKeyWithArgs,
  createScalarUuid,
  createTransactionPart,
} from "/src/utils";
import {
  Transaction,
  SovExtractedTable,
  TransactionPart,
  SovAvailableTabs,
  SovSelectedTabs,
  SovColumnMatches,
  SovColumnMapping,
  SovColumnMappings,
  Scalar,
  SovExtractionId,
} from "/src/internal_types";
import { v4 } from "uuid";
import {
  runMapperSov,
  getSovExecutionStatus,
  getSovMappedOutput,
  invalidatePolicy,
  invalidateTimeline,
  selectSovTabs,
  uploadSovDocument,
  useGetSovProductSpecInfo,
  transactPolicySov,
  getSovTabs,
} from "/src/dal/dal";

export type SovImportWizardState =
  | { tag: "UploadSpreadsheet"; content: UploadPageState }
  | { tag: "MapColumns"; content: ColumnMappingPageState }
  | { tag: "FormatCells"; content: FormatCellsPageState };

type SovImportWizardStateTag = SovImportWizardState["tag"];

export type SovImportWizardProps = {
  extractionId: SovExtractionId;
  tableDatapointName: string;
  wizardState: SovImportWizardState;
  setWizardState: (wizardState: SovImportWizardState) => void;
  onImportComplete: () => void;
};

export const SovImportWizard = ({
  extractionId,
  tableDatapointName,
  wizardState,
  setWizardState,
  onImportComplete,
}: SovImportWizardProps): JSX.Element => {
  const { policy } = React.useContext(PolicyContext);

  const getTabs: GetTabs = useCallback(
    async (abortController: AbortController): Promise<SovAvailableTabs | null> => {
      const getTabsResult = await getSovTabs(
        policy.productId,
        policy.id,
        extractionId,
        abortController,
      );
      if (getTabsResult.status === 404) return null;
      return getTabsResult.result;
    },
    [policy, extractionId],
  );

  const uploadAndGetTabs: UploadAndGetTabs = useCallback(
    async (file: File, abortController: AbortController): Promise<SovAvailableTabs | null> => {
      const initialUploadResult = await uploadSovDocument(
        policy.productId,
        policy.id,
        extractionId,
        file,
        abortController,
      );
      if (initialUploadResult.status !== 200) return null;
      const getTabsResult = await getSovTabs(
        policy.productId,
        policy.id,
        extractionId,
        abortController,
      );
      if (getTabsResult.status !== 200) return null;
      return getTabsResult.result;
    },
    [policy, extractionId],
  );

  const selectTabsAndGetCandidates: SelectTabsAndGetCandidates = useCallback(
    async (
      targetTableDatapoint: string,
      selectedTabs: SovSelectedTabs,
      abortController: AbortController,
    ): Promise<SovColumnMatches[] | null> => {
      await selectSovTabs(policy.productId, policy.id, extractionId, selectedTabs, abortController);
      let completed = false;
      for (let retries = 0; retries < 60; retries++) {
        console.log("Checking if candidates are ready...");
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const retryResult = await getSovExecutionStatus(
          policy.productId,
          policy.id,
          extractionId,
          abortController,
        );
        if (retryResult.status !== 200) return null;
        if (retryResult.result.tag === "Other") return null;
        if (retryResult.result.tag === "Completed") {
          completed = true;
          break;
        }
      }
      if (completed === false) return null;
      const candidatesResult = await runMapperSov(
        policy.productId,
        policy.id,
        extractionId,
        targetTableDatapoint,
        selectedTabs,
        abortController,
      );
      if (candidatesResult.status !== 200) return null;
      console.log("Candidates ready! %o", candidatesResult.result);
      return candidatesResult.result;
    },
    [policy, extractionId],
  );

  const getExtractedTable: GetExtractedTable = useCallback(
    async (
      { mapped }: ColumnMappingPageState,
      abortController: AbortController,
    ): Promise<SovExtractedTable | null> => {
      const columnMappings: SovColumnMapping[] = [...mapped.entries()].flatMap(
        ([targetColumnId, mappedColumnState]) => {
          if (mappedColumnState.tag === "Mapped" && mappedColumnState.checked) {
            return [{ target: targetColumnId, source: [mappedColumnState.sourceColumn] }];
          }
          return [];
        },
      );
      const sovColumnMappings: SovColumnMappings = {
        columnMappings,
      };
      const mappedOutputResult = await getSovMappedOutput(
        policy.productId,
        policy.id,
        extractionId,
        sovColumnMappings,
        abortController,
      );
      if (mappedOutputResult.status !== 200) return null;
      return mappedOutputResult.result;
    },
    [policy, extractionId],
  );

  const importToPolicy: ImportToPolicy = useCallback(
    async (tableData) => {
      const transaction = buildTableTransaction(tableData, tableDatapointName);
      console.log("Transaction data:", transaction);
      if (typeof transaction === "string") return transaction;
      console.log("Transaction object: %o", transaction);
      const qinfo = await transactPolicySov(policy.productId, policy.id, extractionId, transaction);
      if (qinfo.status == "success") {
        await invalidatePolicy(policy.productId, policy.id);
        await invalidateTimeline(policy.productId, policy.id);
        return null;
      } else {
        return "Transaction failed.";
      }
    },
    [policy, extractionId],
  );

  const chevron = <ChevronRight className="text-gray-300" />;
  const step = (stepName: string, stepState: SovImportWizardStateTag) => (
    <p
      className={clsx([
        "text-sm",
        "leading-4",
        "font-medium",
        wizardState.tag === stepState ? "text-blue-600" : "text-gray-400",
      ])}
    >
      {stepName}
    </p>
  );

  const specInfoResult = useGetSovProductSpecInfo(
    policy.productId,
    policy.productVersion,
    tableDatapointName,
  );
  const specInfo = specInfoResult.status === "success" ? specInfoResult.value : {};
  return (
    <div className="flex flex-col gap-4 mt-5 grow overflow-y-auto">
      <div className="flex items-center gap-3">
        {step("Import", "UploadSpreadsheet")}
        {chevron}
        {step("Map column headers", "MapColumns")}
        {chevron}
        {step("Format cell data", "FormatCells")}
      </div>
      {wizardState.tag === "UploadSpreadsheet" && (
        <UploadPage
          getTabs={getTabs}
          uploadAndGetTabs={uploadAndGetTabs}
          selectTabsAndGetCandidates={selectTabsAndGetCandidates}
          pageState={wizardState.content}
          setPageState={(pageState) =>
            setWizardState({ tag: "UploadSpreadsheet", content: pageState })
          }
          moveToNextPage={(tab, candidates) =>
            setWizardState({
              tag: "MapColumns",
              content: {
                tab,
                candidates,
                mapped: preselectMostLikelyCandidates(candidates),
                isExtractingTable: false,
              },
            })
          }
        />
      )}
      {wizardState.tag === "MapColumns" && (
        <ColumnMappingPage
          getExtractedTable={getExtractedTable}
          specInfo={specInfo}
          pageState={wizardState.content}
          setPageState={(s) => setWizardState({ tag: "MapColumns", content: s })}
          moveToNextPage={(tableData) =>
            setWizardState({
              tag: "FormatCells",
              content: {
                initialTableData: tableData,
              },
            })
          }
        />
      )}
      {wizardState.tag === "FormatCells" && (
        <FormatCellsPage
          specInfo={specInfo}
          pageState={wizardState.content}
          importToPolicy={importToPolicy}
          moveToNextPage={() => onImportComplete()}
        />
      )}
    </div>
  );
};

type ExtractedTableDataError = string;

const buildTableTransaction = (
  extractedColumns: ExtractedTable,
  // This is the datapoint with render=table
  rowsKeyName: string,
): Transaction | ExtractedTableDataError => {
  let result: Transaction = [];
  const lengths: number[] = extractedColumns.map(({ values }) => values.length);
  const expectedLength = lengths[0];
  if (!lengths.every((l) => l === expectedLength)) return "Columns don't have same number of rows.";

  console.log("Extracted columns %o", extractedColumns);
  console.log("Expected length %i", expectedLength);

  const rowIds: string[] = [];
  for (let i = 0; i < expectedLength; i++) {
    rowIds.push(v4());
  }

  const rowsKey = createKeyWithArgs(rowsKeyName, []);
  const addRowsTransactionParts: TransactionPart[] = rowIds.map((rowId) => {
    const fact = createAdditionFact(createScalarUuid(rowId));
    return createTransactionPart(rowsKey, fact);
  });
  console.log("rows transactions parts: %o", addRowsTransactionParts);
  result = result.concat(addRowsTransactionParts);

  for (const extractedColumn of extractedColumns) {
    const columnTransaction = buildColumnTransaction(rowIds, extractedColumn);
    if (typeof columnTransaction === "string") return columnTransaction;
    result = result.concat(columnTransaction);
  }

  return result;
};

type ColumnDataError = string;

/**
 * Builds a {@link Transaction} corresponding to the values of a single column.
 *
 * @param rowIds The Brossa table row ids, which are assumed to be equal in
 * number to the values that we want to insert.
 */
const buildColumnTransaction = (
  rowIds: string[],
  { datapointId, values }: ExtractedColumn,
): Transaction | ColumnDataError => {
  const result: Transaction = [];
  const zippedValues: [string, Scalar][] = rowIds.map((uuid, index) => [uuid, values[index]]);
  for (const [uuid, cellValue] of zippedValues) {
    if (cellValue === null) continue;
    const cellKey = createKeyWithArgs(datapointId, [createScalarUuid(uuid)]);
    const fact = createAdditionFact(cellValue);
    const transactionPart = createTransactionPart(cellKey, fact);
    result.push(transactionPart);
  }
  return result;
};
