import React from "react";
import StateMachine, { PickState } from "../state/state_machine";
import { ExportPoliciesP, Permission } from "../pages/policy/permissions";
import { Button } from "/src/design_system/Button";
import { startExportPoliciesTask } from "../dal/dal";
import { Loader } from "/src/design_system/Loader";
import { DownloadIcon } from "@heroicons/react/solid";
import { Product, ProductId } from "../internal_types";
import { Modal } from "../design_system/Modal";
import { parseEmail } from "../utils";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/outline";

/**
 * A button that invokes the export policies task.
 * Results will be sent to the provided email.
 */
const ExportPoliciesButton = (props: {
  canExportPolicies: Permission<unknown, unknown, ExportPoliciesP>;
  product?: Product | undefined;
}): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      {props.canExportPolicies.check(undefined) && (
        <>
          <Button
            data-testid="export-policies-all-products-button"
            type="button"
            variant="secondary"
            onClick={async () => {
              setIsOpen(true);
            }}
            disabled={isOpen}
            className="space-x-2 relative"
          >
            {!isOpen && <DownloadIcon className="h-5 w-5" />}
            {isOpen && <Loader size="sm" color="white" />}
            <span>Export policies</span>
          </Button>
          <ModalContent
            onClose={() => setIsOpen(false)}
            isOpen={isOpen}
            productId={props.product?.id}
          />
        </>
      )}
    </>
  );
};

//------------------------------------------------------------------------
//-- State machine for the modal

type ModalStates = {
  fillingOut: {
    input: string;
    error: string | undefined;
    submittedOnce: boolean;
    productId: undefined | ProductId;
  };
  sending: {
    input: string;
    productId: undefined | ProductId;
  };
  sent: undefined;
};

type ModalTransitions = {
  fillingOut: {
    setInput: (data: string) => PickState<ModalStates, "fillingOut">;
    submit: () => PickState<ModalStates, "fillingOut" | "sending">;
  };
  sending: {
    send: () => Promise<PickState<ModalStates, "sent">>;
  };
};

const setInput = (
  state: ModalStates["fillingOut"],
  data: string,
): PickState<ModalStates, "fillingOut"> => {
  const validatedEmail = parseEmail(data).getRight();
  const error =
    data.trim().length === 0
      ? "Email cannot be empty"
      : validatedEmail === undefined
      ? "Incorrect email format"
      : undefined;
  return { type: "fillingOut", context: { ...state, input: data, error } };
};

const modalTransitions = StateMachine.transitions<ModalStates, ModalTransitions>({
  fillingOut: {
    setInput: setInput,
    submit: (state) => {
      if (state.error !== undefined) {
        return {
          type: "fillingOut",
          context: {
            ...state,
            submittedOnce: true,
          },
        };
      }
      return {
        type: "sending",
        context: {
          input: state.input,
          productId: state.productId,
        },
      };
    },
  },
  sending: {
    send: async (state) => {
      await startExportPoliciesTask(state.productId, state.input);
      const sleepPromise = new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });
      await sleepPromise;
      return { type: "sent", context: undefined };
    },
  },
});

const ModalContent = (props: {
  onClose: () => void;
  isOpen: boolean;
  productId: ProductId | undefined;
}): JSX.Element => {
  const [state, resetMachine] = StateMachine.useMachine<ModalStates, ModalTransitions>(
    {
      type: "fillingOut",
      context: {
        input: "",
        submittedOnce: false,
        error: "Email cannot be empty",
        productId: props.productId,
      },
    },
    modalTransitions,
  );

  //todo remove this once we have hooks in state machines
  React.useEffect(() => {
    if (state.type === "sending") {
      //onEnter hook
      //this is a hack since send is async function
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      state.transitions.send();
    }
  }, [state.type]);

  const onClose = () => {
    resetMachine();
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={onClose}>
      <div className="p-8 flex flex-col gap-5 w-96">
        <div>
          <p className="text-xl font-bold">Policies export</p>
        </div>

        {(state.type === "fillingOut" || state.type === "sending") && (
          <div>
            <p className="py-1">Email</p>
            {state.type === "fillingOut" &&
              state.context.submittedOnce &&
              state.context.error !== undefined && (
                <p className="text-red-500">{state.context.error}</p>
              )}
            <input
              className={clsx(
                "border border-gray-300 text-sm focus:outline-none  disabled:bg-gray-50 w-80",
                state.type === "sending" ||
                  !state.context.submittedOnce ||
                  state.context.error === undefined
                  ? "focus:ring-blue-400 focus:border-blue-500"
                  : "focus:ring-red-400 focus:border-red-500 border-red-300",
              )} // todo: change this when we have unified input component
              value={state.context.input}
              disabled={state.type === "sending"}
              type="email"
              onChange={(ev) => {
                if (state.type === "fillingOut") {
                  const value = ev.target.value;
                  state.transitions.setInput(value);
                }
              }}
            />
          </div>
        )}
        {state.type === "sent" && (
          <div>
            <CheckCircleIcon className="w-32 h-32 text-green-500 block m-auto" />
            <p>
              Export results will be sent to the provided email address once the export is complete
            </p>
          </div>
        )}
        <div className="flex flex-row justify-end gap-4">
          <Button variant="secondary" onClick={onClose} disabled={state.type === "sending"}>
            {state.type === "sent" ? "Close" : "Cancel"}
          </Button>
          {(state.type === "fillingOut" || state.type === "sending") && (
            <Button
              onClick={() => {
                state.type === "fillingOut" && state.transitions.submit();
              }}
              disabled={state.type === "sending"}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ExportPoliciesButton;
