import React, { Suspense, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  DownloadIcon,
  PencilIcon,
  EyeIcon,
  DocumentSearchIcon,
  ChevronDownIcon,
  TableIcon,
} from "@heroicons/react/solid";
import { Button } from "/src/design_system/Button";
import {
  useGetProduct,
  useGetProductVersions,
  useGetCurrentUser,
  downloadProductSpecification,
  useGetMrcProductAdapterMapping,
  setupMrcProductAdapters,
  invalidateMrcProductAdapterMapping,
  useGetMrcAdapters,
  setMrcAdapterForDatapoint,
  useGetSovAdapters,
  useGetSovProductAdapterMapping,
  setupSovProductAdapters,
  invalidateSovProductAdapterMapping,
  setSovAdapterForDatapoint,
} from "../dal/dal";
import { downloadFile } from "/src/utils";
import { Routes } from "../routing/routes";
import { useParams } from "../routing/routing";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Table, TD, THead, TR, TH, TBody } from "../design_system/Table";
import {
  CanDownloadSpec,
  CanCreateProducts,
  CanEditExtractionAdapters,
} from "./policy/permissions";
import { Link } from "react-router-dom";
import { Tabs } from "../design_system/Tabs";
import { TabPanel } from "../design_system/TabPanel";
import { throwIfAppError } from "/src/utils/app_error";
import {
  MrcAdapter,
  MrcAdapterId,
  ProductId,
  ProductVersion,
  SovAdapterId,
  SovAdapter,
  SetupMrcAdapterOptions,
  SetupSovAdapterOptions,
} from "../internal_types";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";

const MrcAdaptersDropdown = (props: {
  adapters: Map<MrcAdapterId, MrcAdapter> | undefined;
  adapterId: MrcAdapterId | undefined;
  onChange: (id: MrcAdapterId | null) => Promise<void>;
}): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const adaptersList = props.adapters === undefined ? [] : Array.from(props.adapters.values());
  const optLabel =
    props.adapterId !== undefined ? props.adapters?.get(props.adapterId)?.name : undefined;

  const selectItem = (key: null | MrcAdapterId, label: JSX.Element): JSX.Element => {
    return (
      <div
        key={key}
        className={
          "py-1 px-2 cursor-pointer hover:bg-gray-100" +
          (key === props.adapterId ? " bg-gray-200 hover:bg-gray-300" : "")
        }
        onClick={async (e) => {
          e.preventDefault();
          await props.onChange(key);
          setOpen(false);
        }}
      >
        {label}
      </div>
    );
  };
  return (
    <Popover.Root open={isOpen}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {optLabel === undefined && <span className="font-gray-500">No adapter</span>}
        {optLabel !== undefined && <span>{optLabel}</span>}
        <ChevronDownIcon className="ml-1 inline h-4 w-4" />
      </Popover.Trigger>
      <Popover.Content align="start" onPointerDownOutside={(_) => setOpen(false)}>
        <div className="bg-white rounded overflow-hidden border border-gray-200 mt-1 max-h-60 overflow-scroll">
          {selectItem(null, <span className="font-gray-500">No adapter</span>)}
          {adaptersList.map((adapter) =>
            selectItem(adapter.id, <span className="font-gray-800">{adapter.name}</span>),
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export const MRCAdapters = (props: {
  productId: ProductId;
  productVersion: ProductVersion;
}): JSX.Element => {
  const allAdapters = useGetMrcAdapters();
  throwIfAppError(allAdapters);
  const adaptersById: Map<MrcAdapterId, MrcAdapter> = useMemo(() => {
    const m = new Map();
    allAdapters.value.forEach((adapter) => {
      m.set(adapter.id, adapter);
    });
    return m;
  }, [allAdapters]);

  const adapterMapping = useGetMrcProductAdapterMapping(props.productId, props.productVersion);
  throwIfAppError(adapterMapping);

  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden mx-8 mb-2">
      <div className="bg-gray-100 px-6 py-3 text-gray-600 font-semibold">
        MRC Extraction Adapters
      </div>
      {adapterMapping.value.length === 0 && (
        <div className="p-6">
          <p className="text-gray-600 mb-4">No adapters found for this product version.</p>
          <Button
            onClick={async (ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              const options: SetupMrcAdapterOptions = {
                copyLastVersion: true,
              };
              await setupMrcProductAdapters(options, props.productId, props.productVersion);
              await invalidateMrcProductAdapterMapping(props.productId, props.productVersion);
            }}
          >
            Setup Adapters
          </Button>
        </div>
      )}
      {adapterMapping.value.length > 0 && (
        <Table className="table-fixed">
          <THead>
            <TR>
              <TH label="Datapoint" />
              <TH label="Adapter" />
            </TR>
          </THead>
          <TBody>
            {adapterMapping.value.map((mapping) => (
              <TR key={mapping.datapointName} className="hover:bg-gray-100">
                <TD>{mapping.datapointName}</TD>
                <TD>
                  <MrcAdaptersDropdown
                    adapters={adaptersById}
                    adapterId={mapping.adapterId}
                    onChange={async (adapterId) => {
                      await setMrcAdapterForDatapoint(
                        props.productId,
                        props.productVersion,
                        mapping.datapointName,
                        adapterId,
                      );
                      await invalidateMrcProductAdapterMapping(
                        props.productId,
                        props.productVersion,
                      );
                    }}
                  />
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      )}
    </div>
  );
};

const SovAdaptersDropdown = (props: {
  adapters: Map<SovAdapterId, SovAdapter> | undefined;
  adapterId: SovAdapterId | undefined;
  onChange: (id: SovAdapterId | null) => Promise<void>;
}): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const adaptersList = props.adapters === undefined ? [] : Array.from(props.adapters.values());
  const optLabel =
    props.adapterId !== undefined ? props.adapters?.get(props.adapterId)?.name : undefined;

  const selectItem = (key: null | SovAdapterId, label: JSX.Element): JSX.Element => {
    return (
      <div
        key={key}
        className={
          "py-1 px-2 cursor-pointer hover:bg-gray-100" +
          (key === props.adapterId ? " bg-gray-200 hover:bg-gray-300" : "")
        }
        onClick={async (e) => {
          e.preventDefault();
          await props.onChange(key);
          setOpen(false);
        }}
      >
        {label}
      </div>
    );
  };
  return (
    <Popover.Root open={isOpen}>
      <Popover.Trigger
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {optLabel === undefined && <span className="font-gray-500">No adapter</span>}
        {optLabel !== undefined && <span>{optLabel}</span>}
        <ChevronDownIcon className="ml-1 inline h-4 w-4" />
      </Popover.Trigger>
      <Popover.Content align="start" onPointerDownOutside={(_) => setOpen(false)}>
        <div className="bg-white rounded overflow-hidden border border-gray-200 mt-1 max-h-60 overflow-scroll">
          {selectItem(null, <span className="font-gray-500">No adapter</span>)}
          {adaptersList.map((adapter) =>
            selectItem(adapter.id, <span className="font-gray-800">{adapter.name}</span>),
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export const SoVAdapters = (props: {
  productId: ProductId;
  productVersion: ProductVersion;
}): JSX.Element => {
  const allAdapters = useGetSovAdapters();
  throwIfAppError(allAdapters);
  const adaptersById = useMemo(() => {
    const m = new Map();
    allAdapters.value.forEach((a) => {
      m.set(a.id, a);
    });
    return m;
  }, [allAdapters]);

  const adapterMapping = useGetSovProductAdapterMapping(props.productId, props.productVersion);
  throwIfAppError(adapterMapping);

  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden mx-8 mb-2">
      <div className="bg-gray-100 px-6 py-3 text-gray-600 font-semibold">
        SoV Extraction Adapters
      </div>
      {adapterMapping.value.length === 0 && (
        <div className="p-6">
          <p className="text-gray-600 mb-4">No adapters found for this product version.</p>
          <Button
            onClick={async (ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              const options: SetupSovAdapterOptions = {
                copyLastVersion: true,
              };
              await setupSovProductAdapters(options, props.productId, props.productVersion);
              await invalidateSovProductAdapterMapping(props.productId, props.productVersion);
            }}
          >
            Setup Adapters
          </Button>
        </div>
      )}
      {adapterMapping.value.length > 0 && (
        <Table className="table-fixed">
          <THead>
            <TR>
              <TH label="Table" />
              <TH label="Column" />
              <TH label="Type" />
              <TH label="Adapter" />
            </TR>
          </THead>
          <TBody>
            {adapterMapping.value.map((mapping) => (
              <TR key={mapping.columnDatapointName} className="hover:bg-gray-100">
                <TD>{mapping.tableDatapointName}</TD>
                <TD>{mapping.columnDatapointName}</TD>
                <TD>{mapping.datapointType}</TD>
                <TD>
                  <SovAdaptersDropdown
                    adapters={adaptersById}
                    adapterId={mapping.adapterId}
                    onChange={async (adapterId) => {
                      await setSovAdapterForDatapoint(props.productId, props.productVersion, {
                        tableDatapointName: mapping.tableDatapointName,
                        columnDatapointName: mapping.columnDatapointName,
                        sovAdapter: adapterId == null ? undefined : adapterId,
                      });
                      await invalidateSovProductAdapterMapping(
                        props.productId,
                        props.productVersion,
                      );
                    }}
                  />
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      )}
    </div>
  );
};

const Product: React.FC = () => {
  const {
    data: {
      capture: { productId },
    },
  } = useParams(Routes.product);
  const product = useGetProduct(productId);
  throwIfAppError(product);
  const versions = useGetProductVersions(productId);
  throwIfAppError(versions);
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const cs = { currentRole: currentUser.value.role };
  const canDownloadSpec = CanDownloadSpec(cs).check(undefined);
  const canEditExtractionAdapters = CanEditExtractionAdapters(cs).check(undefined);
  const canCreateProducts = CanCreateProducts(cs).check(undefined);
  const [whichTab, setTab] = React.useState("Version log");
  const history = useHistory();
  const [mrcPanelVersion, setMrcPanelVersion] = useState<ProductVersion | null>(null);
  const [sovPanelVersion, setSovPanelVersion] = useState<ProductVersion | null>(null);

  return (
    <Page>
      <PageHeader>
        <div className="flex flex-col w-full">
          <div>
            <Link to={Routes.products.generatePath({})} className="group py-2 pr-6 flex flex-row">
              ← Back to products
            </Link>
          </div>
          <div className="flex flex-row w-full justify-between">
            <div>
              <PageTitle>{product.value.name}</PageTitle>
            </div>
            <div className="flex flex-cols space-x-3">
              {canCreateProducts && (
                <Button
                  data-testid="edit-product-button"
                  type="button"
                  variant="secondary"
                  aria-hidden="true"
                  onClick={async (_) => {
                    history.push(Routes.editProduct.generatePath({ capture: { productId } }));
                  }}
                >
                  <PencilIcon className="h-5 w-5 mr-1" />
                  Edit Product
                </Button>
              )}
              {canDownloadSpec && (
                <Button
                  data-testid="download-product-spec"
                  type="button"
                  variant="secondary"
                  aria-hidden="true"
                  className="self-end"
                  onClick={async (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const blob = await downloadProductSpecification(product.value.id);
                    downloadFile(blob, `${product.value.name}.zip`);
                  }}
                >
                  <DownloadIcon className="h-5 w-5 mr-1" />
                  Download latest spec
                </Button>
              )}
            </div>
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <div data-testid="product-container" className="flex-1 px-8">
          <Tabs tabs={["Version log"]} selected={whichTab} onSelect={(rule) => setTab(rule)} />
          <TabPanel index={"Version log"} value={whichTab}>
            <Table className="table-fixed">
              <THead>
                <TR>
                  <TH label="Name" />
                  <TH label="Version" />
                  <TH label="Policies" />
                  <TH label="Uploaded by" />
                  <TH label="Created" />
                  <TH label="" />
                </TR>
              </THead>
              <TBody>
                {versions.value.slice().map((product, index) => {
                  return (
                    <>
                      <TR key={product.version}>
                        <TD>
                          <span data-testid="product-name">{product.name}</span>
                        </TD>
                        <TD>
                          <span data-testid="product-version">{product.version}</span>
                        </TD>
                        <TD>
                          <span data-testid="product-policyCount">{product.policyCount}</span>
                        </TD>
                        <TD>
                          <span data-testid="product-author">{product.createdBy}</span>
                        </TD>
                        <TD>
                          <span data-testid="product-createdAt">
                            {new Date(product.createdAt).toLocaleString()}
                          </span>
                        </TD>
                        <TD>
                          <div className="flex flex-row">
                            {canEditExtractionAdapters && (
                              <>
                                <Button
                                  type="button"
                                  size="xs"
                                  variant="secondary"
                                  title="SoV Adapters"
                                  className={
                                    "rounded-r-none border-r-0" +
                                    (sovPanelVersion === product.version ? " bg-gray-300" : "")
                                  }
                                  aria-hidden="true"
                                  onClick={async (ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    if (sovPanelVersion === product.version) {
                                      setSovPanelVersion(null);
                                    } else {
                                      setSovPanelVersion(product.version);
                                    }
                                  }}
                                >
                                  <TableIcon className="h-4 w-4" />
                                </Button>
                                <Button
                                  type="button"
                                  size="xs"
                                  variant="secondary"
                                  title="MRC Adapters"
                                  className={
                                    "rounded-none" +
                                    (mrcPanelVersion === product.version ? " bg-gray-300" : "")
                                  }
                                  aria-hidden="true"
                                  onClick={async (ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    if (mrcPanelVersion === product.version) {
                                      setMrcPanelVersion(null);
                                    } else {
                                      setMrcPanelVersion(product.version);
                                    }
                                  }}
                                >
                                  <DocumentSearchIcon className="h-4 w-4" />
                                </Button>
                              </>
                            )}
                            {canDownloadSpec && (
                              <>
                                <Button
                                  data-testid={"download-product-spec-" + index}
                                  type="button"
                                  size="xs"
                                  variant="secondary"
                                  title="Download Product Spec"
                                  className={clsx([
                                    canEditExtractionAdapters
                                      ? "rounded-none border-x-0"
                                      : "rounded-r-none border-r-0",
                                  ])}
                                  aria-hidden="true"
                                  onClick={async (ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    const blob = await downloadProductSpecification(
                                      product.id,
                                      product.version,
                                    );
                                    downloadFile(blob, `${product.name}.zip`);
                                  }}
                                >
                                  <DownloadIcon className="h-4 w-4" />
                                </Button>
                                <Button
                                  data-testid={"view-product-spec-" + index}
                                  type="button"
                                  size="xs"
                                  variant="secondary"
                                  title="View Product Spec"
                                  aria-hidden="true"
                                  className="self-end rounded-l-none"
                                  onClick={async (_) => {
                                    {
                                      history.push(
                                        Routes.readProduct.generatePath({
                                          capture: {
                                            productId: product.id,
                                            versionId: product.version,
                                          },
                                        }),
                                      );
                                    }
                                  }}
                                >
                                  <EyeIcon className="h-4 w-4" />
                                </Button>
                              </>
                            )}
                          </div>
                        </TD>
                      </TR>
                      <tr style={{ borderTopWidth: 0 }}>
                        <td colSpan={6}>
                          {mrcPanelVersion === product.version && (
                            <Suspense fallback={<></>}>
                              <MRCAdapters
                                productId={product.id}
                                productVersion={product.version}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                      <tr style={{ borderTopWidth: 0 }}>
                        <td colSpan={6}>
                          {sovPanelVersion === product.version && (
                            <Suspense fallback={<></>}>
                              <SoVAdapters
                                productId={product.id}
                                productVersion={product.version}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </TBody>
            </Table>
          </TabPanel>
        </div>
      </PageContent>
    </Page>
  );
};

export default Product;
