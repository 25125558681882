import React from "react";

import { TxStatus } from "/src/data_editor";
import { prettyPrintTime } from "/src/utils/time";
import { Loader } from "/src/design_system/Loader";
import { PolicyInfo } from "/src/internal_types";

export type ModifiedDateState = { type: "saving" } | { type: "saved"; modifiedDate?: string };

export const modifiedDateStateFromPolicy = (
  policy: PolicyInfo,
  txStatus: TxStatus,
): ModifiedDateState => {
  if (txStatus === "in_flight") {
    return { type: "saving" };
  } else {
    return {
      type: "saved",
      modifiedDate: policy.modified,
    };
  }
};

export const PolicySaveStatus = (props: {
  lastUpdatedAt: string;
  isUpdating: boolean;
}): JSX.Element => {
  if (props.isUpdating) {
    return (
      <div className="flex flex-row mr-6" data-policy-status="updating">
        <Loader size="sm" />
        <span className="text-gray-500 font-normal leading-4 text-sm pt-1 ml-2">Saving</span>
      </div>
    );
  } else {
    const prettyPrintedUpdatedAt = prettyPrintTime(props.lastUpdatedAt);
    return (
      <span
        className="text-gray-500 font-normal leading-4 text-sm mr-6"
        data-policy-status="updated"
      >
        Last updated at {prettyPrintedUpdatedAt.time} on {prettyPrintedUpdatedAt.date}
      </span>
    );
  }
};
