import React from "react";

export type DataViewProps = React.PropsWithChildren<{
  title?: string;
  titleActions?: JSX.Element;
}>;

export const DataView = (props: DataViewProps): JSX.Element => {
  return (
    <div>
      <div className="flex pb-3 items-center">
        {props.title !== undefined && (
          <h4 className="text-lg leading-7 font-semibold text-gray-900">{props.title}</h4>
        )}
        <div className="flex-1"></div>
        <div>{props.titleActions}</div>
      </div>
      {props.children}
    </div>
  );
};
