import { OrAppError } from "/src/dal/http";

type Success<T> = { status: "success"; value: T };
type ThrowAppError = <T>(x: OrAppError<T>) => asserts x is Success<T>;

/* Throws if the value has { status: "error"}. Also refines the type in
 * case it isn't
 */
export const throwIfAppError: ThrowAppError = <T>(x: OrAppError<T>): asserts x is Success<T> => {
  if (x.status === "error") {
    throw new Error(JSON.stringify(x.value));
  }
};
