import { assertNever } from "/src/utils";
import { CompletionSource, autocompletion } from "@codemirror/autocomplete";
import { EditorState, EditorView, basicSetup } from "@codemirror/basic-setup";
import { javascript } from "@codemirror/lang-javascript";
import React, { useRef, useEffect, useState } from "react";
import {
  extendHighlight,
  highlightLine,
  highlightLines,
  highlightRangeField,
} from "./HighlightLines";
import { clamp } from "/src/utils";
import { useHistory } from "react-router";
import { dequal } from "dequal";

/*

CodeMirror Primer.

It has two modules, EditorView and EditorState:

* EditorView is stateful.
* EditorState is a pure snapshot of the state of the EditorView.

Changes occur in transactions which update the state.

See also <https://codemirror.net/6/docs/guide/#functional-core%2C-imperative-shell>

Therefore it's perfectly natural to treat the EditorState as the
source of truth, and the EditorView is simply a redundant construct
that you can setup and teardown, have one or multiple at once, etc.

That robust architecture makes it trivial to use in React. You keep a
reference to the state (so e.g. hot-reloads don't lose editor state),
and the view you re-create casually without much regard.

 */

export type CodeMirrorProps = {
  // The initial text sets the contents of the editor, but if the user
  // makes edits, then this is ignored thereafter.
  initialText: string;
  // Identifier to use in URL hash for line highlighting
  fileIdentifier?: string;
  // Whenever the user makes an edit to the content, inform the
  // parent. Calling the function will produce the string. This is
  // more efficient than always producing the string immediately.
  onTextChange?: (newText: () => string) => void;
  // Read-only editor
  readOnly: boolean;
  highlightRange?: { from: number; to: number };
  // Push commands to the editor.
  commandPusher?: CommandPusher;
  // Autocomplete these names
  autocompletionNames?: string[];
};

/*
Adds the ability to send push event of new text content to the
CodeMirror instance, as if a user had typed it.
*/
export type PushCommand = { tag: "Replace"; content: string } | { tag: "Insert"; content: string };
export type CommandPusher = { push: (command: PushCommand) => void };
export const makeCommandPusher = () =>
  Object.create({
    push: () => {
      /* Do nothing, this will be updated by the CodeMirror component. */
    },
  });

// Make an autocompletion source from a list of words
function completeFromList(list: string[]): CompletionSource {
  return (cx) => {
    const word = cx.matchBefore(/\w+$/);
    if (word === null && !cx.explicit) return null;
    return {
      from: word !== null ? word.from : cx.pos,
      options: list.map((w) => ({ label: w })),
      validFor: /^\w*/,
    };
  };
}

export const CodeMirror = (props: CodeMirrorProps): JSX.Element => {
  // Handle for the parent (real) DOM element for CodeMirror to sit inside.
  const parentRef = useRef(null);
  // Preserve CodeMirror's internal EditorState.
  const editorState = useRef<EditorState | null>(null);
  // We keep a reference to this so that we can destroy it when the FC is re-initialized.
  const [editorView, setEditorView] = useState<EditorView | null>(null);
  // Keep a mutable reference to the onTextChange handler.
  const onTextChange = useRef(props.onTextChange);
  const history = useHistory();

  // Initialize the CodeMirror when the FC is initialized.
  useEffect(() => {
    // Preconditions
    if (parentRef.current == null) return;
    if (editorState.current !== null) return;

    // Clear existing editorView, if present.
    if (editorView !== null) editorView.destroy();

    // We create a listener to listen for changes to the document,
    // and record CodeMirror's internal state in a useState.
    //
    // The listener also updates URL hash if the highlighted range changed.
    const listener = EditorView.updateListener.of((update) => {
      if (props.readOnly) {
        const prevRange = update.startState.field(highlightRangeField);
        const newRange = update.state.field(highlightRangeField);
        if (newRange !== null && !dequal(newRange, prevRange)) {
          const { from, to } = newRange;
          history.push(`#${props.fileIdentifier}-${from}-${to}`);
        }
      }

      if (update.docChanged) {
        editorState.current = update.view.state;
        if (onTextChange.current !== undefined) {
          onTextChange.current(() => update.view.state.doc.toString());
        }
      }
    });

    // Make a new EditorView based on the current EditorState, if any.
    const source: CompletionSource = completeFromList(props.autocompletionNames ?? []);
    const readOnly = props.readOnly ? [EditorView.editable.of(false), highlightLines] : [];
    const view = new EditorView({
      parent: parentRef.current,
      state:
        editorState.current ??
        EditorState.create({
          extensions: [
            autocompletion({ override: [source] }),
            basicSetup,
            javascript(),
            listener,
          ].concat(readOnly),
          doc: props.initialText,
        }),
    });
    setEditorView(view);

    if (props.readOnly && props.highlightRange !== undefined) {
      let { from, to } = props.highlightRange;
      const clean = (x: number) => clamp(x, 1, view.state.doc.lines);
      from = clean(from);
      to = clean(to);
      view.dispatch({
        effects: [
          highlightLine.of(from),
          extendHighlight.of(to),
          EditorView.scrollIntoView(view.state.doc.line(from).from, {
            y: "center",
          }),
        ],
      });
    }

    if (props.commandPusher !== undefined) {
      props.commandPusher.push = function (command) {
        if (view !== null) {
          switch (command.tag) {
            case "Replace": {
              view.dispatch({
                changes: { from: 0, to: view.state.doc.length, insert: command.content },
              });
              break;
            }
            case "Insert": {
              view.dispatch(view.state.replaceSelection(command.content));
              break;
            }
            default: {
              assertNever(command);
            }
          }
        }
      };
    }
  }, []);

  // When the onTextChange handler is updated, update what CodeMirror will call on that event.
  useEffect(() => {
    onTextChange.current = props.onTextChange;
  }, [props.onTextChange]);

  // Clear highlight range if it moved to another document in the product editor.
  useEffect(() => {
    if (props.readOnly && editorView !== null && props.highlightRange === undefined) {
      editorView.dispatch({ effects: highlightLine.of(null) });
    }
  }, [props.highlightRange]);

  return <div ref={parentRef}></div>;
};

export default CodeMirror;
