import * as Yup from "yup";

import {
  AllRoles,
  AuthUser,
  Organisation,
  Role,
  roleToDisplayString,
  UserId,
} from "../internal_types";
import { CanAddUser, CanEditUser, EditUserP, GlobalState, Permission } from "./policy/permissions";
import { PlusSmIcon } from "@heroicons/react/solid";
import { Field, Form, Formik } from "formik";
import { FormError, Label } from "../design_system/kitchen_sink";
import { FormikInput, FormikSelect } from "../forms";
import { Page, PageContent, PageHeader, PageTitle, usePageTitle } from "../layout";
import {
  Pagination,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  TableLoader,
  toggleSortOrder,
} from "../design_system/Table";
import React, { Suspense, useEffect } from "react";
import {
  apiPost,
  apiPut,
  invalidatePath,
  resendInvite,
  useGetCurrentUser,
  useGetOrganisations,
  useGetUsers,
} from "../dal/dal";
import { Loader } from "../design_system/Loader";

import { Button } from "../design_system/Button";
import { ClearButton } from "../components/ClearButton";
import { Dropdown } from "../components/Dropdown";
import { FilterCheckbox } from "../components/FilterCheckbox";
import { Modal } from "../design_system/Modal";
import { Routes } from "../routing/routes";
import { SearchInput } from "../components/SearchInput";
import { checkInList } from "./policy/policies";
import { useParams } from "../routing/routing";
import { throwIfAppError } from "/src/utils/app_error";

const pageCount = 20; // TOD0

const Users: React.FC = () => {
  // Only get root users
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const organisations = useGetOrganisations();
  throwIfAppError(organisations);
  const {
    data: { query },
    setQuery,
  } = useParams(Routes.users);
  const selectedRoles = query.roles ?? [];
  const searchQuery = query.searchReferenceQuery;
  const page = query.page;
  const sortBy = query.sortOn;
  const sortOrder = query.sortOrder;

  const onRoleChange = (role: Role) => {
    const result = checkInList(selectedRoles, role);
    setQuery({ roles: result });
  };

  const globalState = { currentRole: currentUser.value.role };
  const canAddUser = CanAddUser(globalState);
  const canEditUser = CanEditUser(globalState);

  usePageTitle("Users");

  return (
    <Page>
      <PageHeader>
        <PageTitle>Users</PageTitle>
        <div className="flex-1"></div>
        <div>
          {canAddUser.check(undefined) && <AddUserDialog organisations={organisations.value} />}
        </div>
      </PageHeader>
      <PageContent>
        <div data-testid="users-container">
          <div className="flex flex-col bg-white w-full px-8 h-full pt-1 ">
            <div className="flex flex-row justify-between pb-4 pr-6">
              <div className="flex flex-row items-center space-x-2">
                <SearchInput
                  onChange={(e) => setQuery({ searchReferenceQuery: e.target.value })}
                  value={searchQuery}
                  placeholder="Name or email"
                />

                <Dropdown label="Role" itemsChecked={selectedRoles.length}>
                  {AllRoles.map((role) => (
                    <FilterCheckbox
                      key={role}
                      label={roleToDisplayString(role)}
                      checked={selectedRoles.find((x) => x === role) !== undefined}
                      name={role}
                      onChange={() => onRoleChange(role)}
                      className="capitalize"
                    />
                  ))}
                </Dropdown>
                {(searchQuery !== undefined || selectedRoles.length > 0) && (
                  <ClearButton
                    onClick={() => {
                      setQuery({}, { removeAll: true });
                    }}
                    size="lg"
                  />
                )}
              </div>
              <div className="flex flex-row items-center space-x-2 overflow-x-auto"></div>
            </div>

            <Suspense fallback={<TableLoader />}>
              <UsersTable
                page={page}
                setPage={(page) => setQuery({ page })}
                sortOrder={sortOrder}
                sortBy={sortBy}
                setSortBy={(x) => {
                  if (sortBy === x) {
                    setQuery({ sortOrder: toggleSortOrder(sortOrder ?? "desc") });
                  } else {
                    setQuery({ sortOrder: "desc", sortOn: x });
                  }
                }}
                selectedRoles={selectedRoles}
                searchQuery={searchQuery}
                canEditUsersPermission={canEditUser}
                organisations={organisations.value}
              />
            </Suspense>
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

const UsersTable = (props: {
  page: number | undefined;
  setPage: (n: number) => void;
  sortOrder: "asc" | "desc" | undefined;
  sortBy: "email" | "name" | undefined;
  setSortBy: (x: "email" | "name") => void;
  selectedRoles: string[];
  searchQuery: string | undefined;
  canEditUsersPermission: Permission<GlobalState, unknown, EditUserP>;
  organisations: Organisation[];
}) => {
  const { page, setPage, sortOrder, sortBy, selectedRoles, searchQuery, canEditUsersPermission } =
    props;
  const users = useGetUsers(page, 20, sortOrder, sortBy, selectedRoles, searchQuery);
  throwIfAppError(users);
  const canEditUsers = canEditUsersPermission.check(undefined);
  return (
    <>
      <div className="overflow-hidden">
        <Table>
          <THead>
            <TR>
              <TH
                onClick={() => props.setSortBy("name")}
                label="Name"
                sortOrder={sortBy === "name" ? sortOrder : undefined}
                className="cursor-pointer"
              />
              <TH
                onClick={() => props.setSortBy("email")}
                label="Email"
                sortOrder={sortBy === "email" ? sortOrder : undefined}
                className="cursor-pointer"
              />
              <TH label="Role" />
              <TH label="Organisation" />
              {canEditUsers && <TH label="Actions" />}
            </TR>
          </THead>
          <TBody>
            {users.value.users.map((user) => (
              <TR key={user.id} className="hover:bg-gray-50 cursor-pointer group">
                <TD className={"w-80 font-medium"}>{user.displayName}</TD>
                <TD className={"w-96"}>{user.email}</TD>
                <TD className="w-80">
                  <RoleBadge role={user.role} />
                </TD>
                <TD className="w-full">
                  {user.org !== undefined
                    ? props.organisations.find((org) => org.id === user.org)?.name ?? "-"
                    : "-"}
                </TD>
                {canEditUsers && (
                  <TD className={"w-96"}>
                    <EditUserDialog user={user} organisations={props.organisations} />
                  </TD>
                )}
              </TR>
            ))}
          </TBody>
        </Table>
      </div>
      <Pagination
        totalRowsCount={users.value.count}
        rowsPerPage={pageCount}
        currentPageNumber={page ?? 1}
        onPageChange={setPage}
      />
    </>
  );
};

function AddUserDialog(props: { organisations: Organisation[] }) {
  const [open, setOpen] = React.useState(false);
  const defaultOrgId = props.organisations.find((org) => org.id === 0)?.id;
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" />
        Add user
      </Button>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <UserForm
          onSubmit={async (displayName, email, role, orgId) => {
            await apiPost("/api/user", {
              params: {},
              query: {},
              body: {
                displayName: displayName,
                email: email,
                // We've to cast to `Role` because Formik does not narrow the type of `values` based
                // on the validation schema.
                role: role,
                org: orgId,
              },
            });
            await invalidatePath("/api/user");
          }}
          close={() => setOpen(false)}
          title="Add user"
          organisations={props.organisations}
          organisationId={defaultOrgId}
        />
      </Modal>
    </>
  );
}

function EditUserDialog(props: { user: AuthUser; organisations: Organisation[] }) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="cursor-default">
      <Button onClick={() => setOpen(true)} variant="secondary" size="xs">
        Edit
      </Button>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <UserForm
          {...props.user}
          organisationId={props.user.org}
          onSubmit={async (displayName, _email, role, orgId) => {
            await apiPut("/api/user/{id}", {
              params: {
                id: props.user.id,
              },
              query: {},
              body: {
                displayName,
                role,
                org: orgId,
              },
            });
            await invalidatePath("/api/user");
          }}
          close={() => setOpen(false)}
          title="Edit user"
          organisations={props.organisations}
          existingUser={props.user}
        />
      </Modal>
    </div>
  );
}

function UserForm(props: {
  displayName?: string;
  email?: string;
  role?: Role;
  organisationId?: Organisation["id"];
  onSubmit: (
    displayName: string,
    email: string,
    role: Role,
    organisationId: Organisation["id"] | undefined,
  ) => Promise<void>;
  close: () => void;
  title: string;
  organisations: Organisation[];
  existingUser?: AuthUser;
}) {
  type ActionState =
    | { state: "waiting" } // waiting for an action to be picked
    | { state: "executing"; action: "resend-invite"; user: UserId };
  const { existingUser } = props;
  const [error, setError] = React.useState<null | string>(null);
  const [actionState, setActionState] = React.useState<ActionState>({ state: "waiting" });

  const resendInviteCallback = (id: UserId) => {
    setActionState({
      state: "executing",
      action: "resend-invite",
      user: id,
    });
  };

  useEffect(() => {
    if (actionState.state === "executing" && actionState.action === "resend-invite") {
      // `useEffect` can't be an async function
      // but the function below is an async function.
      // So by using `void` operator we let the developer know
      // that we explicitly allow the promise to run in the background
      // without waiting for it's result
      void (async () => {
        try {
          await resendInvite(actionState.user);
          props.close();
        } catch {
          setError("Could not re-send invite email");
          setActionState({ state: "waiting" });
        }
      })();
    }
  }, [actionState]);

  const validationSchema = Yup.object({
    displayName: Yup.string().required("Field required."),
    email: Yup.string().email("The email address is invalid.").required("Field required."),
    role: Yup.string().oneOf(AllRoles).required("Field required."),
    organisationId: Yup.number().notRequired(),
  });

  const disabled = actionState.state === "executing";
  const hasResendInviteAction = existingUser?.status === "ForceChangePassword";
  const hasAnyActions = hasResendInviteAction;

  return (
    <>
      <Formik
        initialValues={{
          displayName: props.displayName ?? "",
          email: props.email ?? "",
          role: props.role ?? "",
          organisationId: props.organisationId?.toString(),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, form) => {
          try {
            setError(null);
            const orgId =
              values.organisationId !== undefined ? parseFloat(values.organisationId) : undefined;
            const organisationId = orgId !== undefined && !isNaN(orgId) ? orgId : undefined;
            await props.onSubmit(
              values.displayName,
              values.email,
              values.role as Role,
              organisationId,
            );
            props.close();
            form.resetForm();
          } catch (e) {
            setError("Something went wrong.");
          }
        }}
      >
        {(form) => (
          <Form noValidate>
            <div className="flex-1 w-[500px]">
              <div className="text-lg font-bold px-8 pt-8">{props.title}</div>
              <div className="flex-1 px-8 py-5 space-y-4 overflow-y-auto">
                {error !== null && (
                  <div className="bg-red-50 px-8 py-2 text-red-700 rounded-full">{error}</div>
                )}
                <div className="space-y-1">
                  <Label>Name</Label>
                  <Field
                    type="text"
                    name="displayName"
                    component={FormikInput}
                    disabled={disabled}
                  />
                  {form.submitCount > 0 &&
                    form.touched.displayName &&
                    form.errors.displayName !== undefined && (
                      <FormError>{form.errors.displayName}</FormError>
                    )}
                </div>
                <div style={{ height: 0 }}></div>
                <div className="space-y-1">
                  <Label>Email</Label>
                  <Field
                    type="email"
                    name="email"
                    component={FormikInput}
                    disabled={disabled || existingUser !== undefined}
                  />
                  {form.submitCount > 0 &&
                    form.touched.email &&
                    form.errors.email !== undefined && <FormError>{form.errors.email}</FormError>}
                </div>
                <div className="space-y-1">
                  <Label>Role</Label>
                  <Field name="role" component={FormikSelect} disabled={disabled}>
                    <option value=""></option>
                    {AllRoles.map((role) => {
                      return (
                        <option value={role} key={role}>
                          {roleToDisplayString(role)}
                        </option>
                      );
                    })}
                  </Field>
                  {form.submitCount > 0 && form.touched.role && form.errors.role !== undefined && (
                    <FormError>{form.errors.role}</FormError>
                  )}
                </div>
                <div className="space-y-1">
                  <Label>Organisation</Label>
                  <Field name="organisationId" component={FormikSelect} disabled={disabled}>
                    <option value="">-</option>
                    {props.organisations.map((org) => {
                      return (
                        <option value={org.id} key={org.id}>
                          {org.name}
                        </option>
                      );
                    })}
                  </Field>
                  {form.submitCount > 0 &&
                    form.touched.organisationId &&
                    form.errors.organisationId !== undefined && (
                      <FormError>{form.errors.organisationId}</FormError>
                    )}
                </div>
              </div>
              <div className="flex flex-row pb-8 px-8">
                {existingUser !== undefined && hasAnyActions && (
                  <>
                    <Dropdown label="Actions" disabled={disabled}>
                      {hasResendInviteAction && (
                        <div
                          className="p-3 font-medium cursor-pointer hover:bg-gray-100"
                          onClick={() => resendInviteCallback(existingUser.id)}
                        >
                          Re-send invite email
                        </div>
                      )}
                    </Dropdown>
                    {disabled && (
                      <div className="flex flex-col items-center justify-center pl-3">
                        <Loader size="sm" />
                      </div>
                    )}
                  </>
                )}
                <div className="flex flex-grow space-x-2 justify-end">
                  <Button variant="secondary" size="sm" onClick={props.close}>
                    Cancel
                  </Button>
                  <Button type="submit" size="sm" isPending={form.isSubmitting}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

function RoleBadge(props: { role: Role }): JSX.Element {
  const displayName = roleToDisplayString(props.role);
  switch (props.role) {
    case "underwriter":
      return (
        <div className="inline-block bg-red-100 text-red-700 rounded-full px-2 font-medium leading-relaxed text-xs">
          {displayName}
        </div>
      );
    case "broker":
      return (
        <div className="inline-block bg-blue-100 text-blue-700 rounded-full px-2 font-medium leading-relaxed text-xs">
          {displayName}
        </div>
      );
    case "admin":
      return (
        <div className="inline-block bg-green-100 text-green-700 rounded-full px-2 font-medium leading-relaxed text-xs">
          {displayName}
        </div>
      );
    case "read_only":
      return (
        <div className="inline-block bg-gray-100 text-gray-700 rounded-full px-2 font-medium leading-relaxed text-xs">
          {displayName}
        </div>
      );
  }
}

export default Users;
