import { EditFile } from "/src/components/ProductEditor";
import React from "react";
import { apiGet, useGetTaskNotifications, useGetPromiseById } from "../dal/dal";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { TaskId, TaskRunId, TaskSuccess, TaskSummary, UnifiedTaskId } from "../internal_types";
import { throwIfAppError } from "../utils/app_error";
import { ToastIcons, ToastParams, useToasts } from "./Toast";
import { assertNever, downloadFileById } from "../utils";
import { Routes } from "../routing/routes";
import { SummaryRowProps } from "/src/pages/task/runs";

////////////////////////////////////////////////////////////////////////////////
// Task provider

/*
 * Task provider fetches task notifications api endpoint every 5 seconds.
 * Based on the result, it upserts the toast for a given task.
 * Decision is based on the status of the task, and whether it already has
 * been upserted.
 */
export const TaskProvider = () => {
  const upsertToast = useToasts();
  const tasks = useGetTaskNotifications();
  throwIfAppError(tasks);
  // task map for keeping track of already upserted tasks
  const [taskMap, setTaskMap] = React.useState<TaskMap>(new Map());
  const history = useHistory();

  // useCallback is used here because the underlying function
  // is asynchronous. useEffect does not work if asynchronous function
  // is passed to it.
  const onTasksChange = React.useCallback(async () => {
    const newTaskMap: TaskMap = new Map();
    for (const taskSummary of tasks.value) {
      const taskKey = generateTaskKey(taskSummary);
      const previousTaskSummary = taskMap.get(taskKey);
      if (
        // it's a new task
        previousTaskSummary === undefined ||
        // the task status has changed
        previousTaskSummary.status.tag !== taskSummary.status.tag
      ) {
        upsertToast(taskKey, await taskToToastParams(taskSummary, history));
      }
      newTaskMap.set(taskKey, taskSummary);
    }
    setTaskMap(newTaskMap);
  }, [
    tasks.value
      .sort((taskSummary, taskSummary2) =>
        generateTaskKey(taskSummary).localeCompare(generateTaskKey(taskSummary2)),
      )
      .map(
        (taskSummary) =>
          // we upsert toast whenever there is a new task
          // or the task changes it's status
          `${generateTaskKey(taskSummary)}-${taskSummary.status.tag}`,
      )
      .join("#"),
  ]);

  React.useEffect(() => {
    // `useEffect` can't be an async function
    // but `onTasksChange` is an async function.
    // So by using `void` operator we let the developer know
    // that we explicitly allow the promise to run in the background
    // without waiting for it's result
    void onTasksChange();
  }, [onTasksChange]);

  return <></>;
};

////////////////////////////////////////////////////////////////////////////////
// Helpers

type TaskMap = Map<string, TaskSummary>;

/*
 * Generate a unique hash for every task based on it's id.
 */
export const generateTaskKey = (taskSummary: TaskSummary): string => {
  const [tag, idValue] = encodeTaskId(taskSummary.task.id);
  return `${tag}#${idValue}`;
};

/*
 * Encode task id into tag and number.
 */
export const encodeTaskId = (taskId: TaskId): [TaskId["tag"], number] => {
  const idValue: number = (() => {
    switch (taskId.tag) {
      case "SequelHubTaskId":
        return taskId.contents;
      case "ExportPoliciesTaskId":
        return taskId.contents;
      case "MrcExtractionTaskId":
        return taskId.contents;
      case "ArchivePoliciesTaskId":
        return taskId.contents;
    }
  })();
  return [taskId.tag, idValue];
};

/*
 * Encode task run id into tag and number.
 */
export const encodeTaskRunId = (taskRunId: TaskRunId): [TaskRunId["tag"], number] => {
  const idValue: number = (() => {
    switch (taskRunId.tag) {
      case "SequelHubTaskRunId":
        return taskRunId.contents.getTaskRunId;
      case "ExportPoliciesTaskRunId":
        return taskRunId.contents;
      case "MrcExtractionTaskRunId":
        return taskRunId.contents;
      case "ArchivePoliciesTaskRunId":
        return taskRunId.contents;
    }
  })();
  return [taskRunId.tag, idValue];
};

export const taskRunIdEquality = (taskRunId1: TaskRunId, taskRunId2: TaskRunId): boolean => {
  const t1 = encodeTaskRunId(taskRunId1);
  const t2 = encodeTaskRunId(taskRunId2);
  return t1[0] === t2[0] && t1[1] === t2[1];
};

/*
 * Decode task id from tag and number.
 */
export const decodeTaskId = (tag: TaskId["tag"], id: number): TaskId => {
  switch (tag) {
    case "SequelHubTaskId":
      return {
        tag,
        contents: id,
      };
    case "ExportPoliciesTaskId":
      return {
        tag,
        contents: id,
      };
    case "MrcExtractionTaskId":
      return {
        tag,
        contents: id,
      };
    case "ArchivePoliciesTaskId":
      return {
        tag,
        contents: id,
      };
  }
};

function goToTask(history: ReturnType<typeof useHistory>, id: UnifiedTaskId): void {
  const [taskTag, taskIdNumber] = encodeTaskId(id);
  return history.push(
    Routes.taskRuns.generatePath({
      capture: {
        taskTag,
        taskId: taskIdNumber,
      },
    }),
  );
}

/*
 * Create toast params based on the task
 */
const taskToToastParams = async (task: TaskSummary, history: History): Promise<ToastParams> => {
  return {
    dismissCaption: "Dismiss",
    action: {
      caption: (() => {
        switch (task.status.tag) {
          case "NotStarted":
          case "Failed":
          case "Started":
            return "View task";
          case "Succeeded": {
            switch (task.task.parameters.tag) {
              case "SequelHubParameters":
                return "View policy";
              case "ExportPoliciesParameters":
                return "Download file";
              case "MrcExtractionParameters":
                return "View document";
              case "ArchivePoliciesParameters":
                return "View task";
            }
          }
        }
      })(),
      onClick: async () => {
        switch (task.status.tag) {
          case "Succeeded":
            return getActionFromSuccess(task.status.contents.result.parameters, task, history)();
          default:
            goToTask(history, task.task.id);
        }
      },
    },
    icon: () => {
      switch (task.status.tag) {
        case "Started": {
          return ToastIcons.loading();
        }
        case "Succeeded": {
          return ToastIcons.success();
        }
        case "Failed": {
          return ToastIcons.failure();
        }
        case "NotStarted": {
          // should not happen
          return ToastIcons.loading();
        }
      }
    },
    title: (() => {
      const name: string = (() => {
        switch (task.task.parameters.tag) {
          case "SequelHubParameters":
            return `Sequel Hub request`;
          case "ExportPoliciesParameters":
            return "Export policies job";
          case "MrcExtractionParameters":
            return "MRC extraction job";
          case "ArchivePoliciesParameters":
            return "Archive policies job";
        }
      })();
      const status: string = (() => {
        switch (task.status.tag) {
          case "Started":
            return "started...";
          case "NotStarted":
            // should not happen
            return "was added to the queue.";
          case "Failed":
            return "failed.";
          case "Succeeded":
            return "succeeded.";
        }
      })();
      return `${name} ${status}`;
    })(),
    description: await (async () => {
      const description: string = await (async () => {
        switch (task.task.parameters.tag) {
          case "SequelHubParameters": {
            const policyId = task.task.parameters.contents.policyId;
            switch (task.status.tag) {
              case "Started":
                return `Sequel Hub request for policy #${policyId} is being processed.`;
              case "Failed":
                return `Sequel Hub request for policy #${policyId} failed.`;
              case "NotStarted":
                return `Sequel Hub request for policy #${policyId} is waiting in the queue.`;
              case "Succeeded":
                return `Sequel Hub request for policy #${policyId} completed.`;
              default:
                assertNever(task.status);
            }
          }
          case "ExportPoliciesParameters": {
            switch (task.status.tag) {
              case "Started":
                return "Policies export file is being generated.";
              case "NotStarted":
                // should not happen
                return "Policies export job waits in the queue to be processed.";
              case "Failed":
                return "Policies export file generation has failed.";
              case "Succeeded": {
                if (task.status.contents.result.parameters.tag === "ExportPoliciesResult") {
                  const fileInfo = await apiGet("/api/file/{hash}", {
                    params: {
                      hash: task.status.contents.result.parameters.contents.fileId,
                    },
                    query: {},
                  });
                  throwIfAppError(fileInfo);
                  return `${fileInfo.value.name} file has been generated.`;
                }
                // should not happen
                return "Policies export file has been generated";
              }
              default:
                assertNever(task.status);
            }
          }
          case "MrcExtractionParameters": {
            const documentId = task.task.parameters.contents.documentId;
            switch (task.status.tag) {
              case "Started":
                return `Mrc extraction for document #${documentId} is being processed.`;
              case "Failed":
                return `Mrc extraction for document #${documentId} failed.`;
              case "NotStarted":
                return `Mrc extraction for document #${documentId} is waiting in the queue.`;
              case "Succeeded":
                return `Mrc extraction for document #${documentId} completed.`;
              default:
                assertNever(task.status);
            }
          }
          case "ArchivePoliciesParameters": {
            switch (task.status.tag) {
              case "Started":
                return "Archiving policies with no activity.";
              case "NotStarted":
                return "Policy archiving job waits in the queue to be processed.";
              case "Failed":
                return "Policies archiving job has failed.";
              case "Succeeded": {
                const policyIds = (
                  task.status.contents.result.parameters.tag === "ArchivePoliciesResult"
                    ? task.status.contents.result.parameters.contents.archivedPolicies
                    : []
                ).map((policy) => `#${policy.id}`);
                const idsString =
                  policyIds.length > 3
                    ? policyIds.slice(0, 3).join(", ") + ", …"
                    : policyIds.join(", ") + ".";
                return policyIds.length === 0
                  ? "No policies were archived."
                  : `Archived policies ${idsString}`;
              }
              default:
                assertNever(task.status);
            }
          }
        }
      })();
      return description;
    })(),
  };
};

export const getActionFromSuccess = (
  taskSuccess: TaskSuccess,
  taskSummary: TaskSummary,
  history: ReturnType<typeof useHistory>,
): (() => Promise<void>) => {
  return async () => {
    switch (taskSuccess.tag) {
      case "SequelHubResult": {
        if (taskSummary.task.parameters.tag === "SequelHubParameters") {
          history.push(
            Routes.policy.generatePath({
              capture: {
                policyId: taskSummary.task.parameters.contents.policyId,
                productId: taskSummary.task.parameters.contents.productId,
              },
            }),
          );
        }
        return;
      }
      case "ExportPoliciesResult": {
        await downloadFileById(taskSuccess.contents.fileId);
        return;
      }

      case "MrcExtractionResult": {
        const hash: string | undefined = taskSuccess.contents.fileId;
        if (hash !== undefined) {
          await downloadFileById(hash);
        }
        return;
      }

      case "ArchivePoliciesResult": {
        goToTask(history, taskSummary.task.id);
        return;
      }

      default:
        assertNever(taskSuccess);
    }
    return;
  };
};

export const getInspectorFromSuccess = (
  taskSummary: TaskSummary,
  taskSuccess: TaskSuccess,
): SummaryRowProps | null => {
  switch (taskSuccess.tag) {
    case "SequelHubResult": {
      if (taskSummary.task.parameters.tag === "SequelHubParameters") {
        const promiseId = taskSuccess.contents.promiseId;
        const policyId = taskSummary.task.parameters.contents.policyId;
        const productId = taskSummary.task.parameters.contents.productId;
        return {
          label: "Promise output",
          content: (
            <PromiseViewer policyId={policyId} productId={productId} promiseId={promiseId} />
          ),
          mode: "multiline",
        };
      } else {
        return null;
      }
    }
    default:
      return null;
  }
};

export const PromiseViewer = (props: {
  productId: number;
  policyId: number;
  promiseId: number;
}): JSX.Element => {
  const promise = useGetPromiseById(props.productId, props.policyId, props.promiseId);
  throwIfAppError(promise);
  const file = {
    name: "Raw response",
    contents: JSON.stringify(JSON.parse(promise.value), undefined, 4),
  };
  return (
    <div className="flex flex-col pt-4">
      <EditFile
        file={file}
        readOnly={true}
        onRemove={() => {
          /* do nothing */
        }}
        onChange={() => {
          /* do nothing */
        }}
        collapsed={true}
      />
    </div>
  );
};

// Display a code snippet of a request, which can be any payload of raw text.
export const RequestViewer = (props: { contents: string }): JSX.Element => {
  const file = {
    name: "Raw request",
    contents: props.contents,
  };
  return (
    <div className="flex flex-col pt-4">
      <EditFile
        file={file}
        readOnly={true}
        onRemove={() => {
          /* do nothing */
        }}
        onChange={() => {
          /* do nothing */
        }}
        collapsed={true}
      />
    </div>
  );
};
