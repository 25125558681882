/**
 * This module contains a {@link TextField} component
 * that should be treated as a base for all fields that
 * have a text input
 * @module
 */
import { TextField as MTextField, TextFieldProps as MTextFieldProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { SingleNodeT_ } from "../types";

/**
 * Type determining the state of the field
 */
export type FieldState =
  /**
   *  Field is not focused and contains some errors
   */
  | "error"
  /**
   * Field is waiting to have its value sent to
   * the server
   */
  | "waiting_for_loading"
  /**
   * Field value has been sent to the server
   * and is waiting for a response
   */
  | "loading"
  /**
   * Field is focused and text is being input
   */
  | "none"
  /**
   * Field is not focused and contains no errors
   */
  | "correct";

/**
 * Function for determining the {@link FieldState}
 * @param focusedOnce A field has been focused at least once already
 * @param focused A field is currently focused
 * @param stateChanged State of the field has changed since blur
 * @param node {@link SingleNodeT_} Node that implements a SingleNodeT_ interface
 * @returns state: {@link FieldState}
 */
export const determineFieldState = (
  focusedOnce: boolean,
  focused: boolean,
  stateChanged: boolean,
  node: SingleNodeT_<any>,
): FieldState => {
  if (node.isCommitting) {
    return "loading";
  }
  if (focusedOnce || node.value !== null || node.formSubmitted) {
    if (focused) {
      return "none";
    }

    if (!stateChanged) {
      return "waiting_for_loading";
    }
    if (node.problems.length > 0 || node.needsCollecting) {
      return "error";
    }
    return "correct";
  }
  return "none";
};

/**
 * TextFieldProps implement TextFieldProps from Material-UI
 * and have additional field `node` that is a SingleNodeT_
 *
 * @todo: This should be changed at some point as we want to
 * move away from the Material UI stuff.
 */
export type TextFieldProps = MTextFieldProps & {
  node: SingleNodeT_<any>;
};

const useTextFieldStyles = makeStyles(() => ({
  input: {
    boxShadow: "none !important",
  },
}));

/**
 * A component that should be treated as a base component
 * for nodes that have a text input
 *
 * It deals with all loading states @see {@link FieldState}
 *
 * It also deals with a description metakeys
 */
export const TextField = React.forwardRef<null, TextFieldProps>((props: TextFieldProps, ref) => {
  const [focusedOnce, setFocusedOnce] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [stateChanged, setStateChanged] = React.useState(true);
  const state = determineFieldState(focusedOnce, focused, stateChanged, props.node);
  const classes = useTextFieldStyles();
  useEffect(() => {
    setStateChanged(true);
  }, [state]);
  return (
    <div className="">
      <MTextField
        ref={ref}
        {...props}
        onFocus={(e) => {
          props.onFocus !== undefined && props.onFocus(e);
          setFocused(true);
        }}
        onBlur={async (e) => {
          setStateChanged(false);
          props.onBlur !== undefined && (await props.onBlur(e));
          setFocused(false);
          setFocusedOnce(true);
        }}
        InputProps={{
          ...(props.InputProps ?? {}),
          classes,
        }}
      />
      {/* <NewTextComponent node={props.node} /> */}
    </div>
  );
});

/**
 * @wip
 *
 * This text component will take over mui text
 * component at some point. It's exported for
 * now to make compiler not complain about it not
 * being used. See {@link https://github.com/artificialio/brossa/issues/2583}
 */
export const NewTextComponent = React.forwardRef(
  (_props: { node: SingleNodeT_<any>; prefix?: string; helperText?: string }) => {
    return (
      <input
        type="text"
        // {...props}
        className="rounded w-full focus:ring-blue-400 border-gray-300 text-sm"
      />
    );
  },
);
