import { Asyncs, AsyncTaskState, getAsyncForDatapoint } from "/src/asyncs";
import { identity } from "rxjs";
import { v4 } from "uuid";
import { dequal } from "dequal";
import {
  Datapoint,
  DatapointInfo,
  FunCall,
  HowDefiniteA,
  Ident,
  KeyInfo,
  PolicyId,
  ProductId,
  QuoteId,
  Scalar,
  TcAliases,
  Transaction,
  Typ,
  Value,
  Violation,
  PromiseId,
} from "../internal_types";
import {
  checkNotUndefined,
  checkRecordOf,
  checkScalarDate,
  checkScalarInt,
  checkScalarIntOrNumber,
  checkScalarNumber,
  checkScalarPercent,
  checkScalarText,
  checkValueBool,
  checkValueConditions,
  checkValueDocument,
  checkValueFile,
  checkValueListOf,
  checkValueListOrSet,
  checkValueListScalarOf,
  checkValueNumber,
  checkValueRecord,
  checkValueScalar,
  checkValueCaselessText,
  checkValueText,
  checkValueUuid,
  createAdditionFact,
  createKeyWithArgs,
  createRetractionAllFact,
  createRetractionSingleFact,
  createScalarBool,
  createScalarDate,
  createScalarFile,
  createScalarInt,
  createScalarNumber,
  createScalarPercent,
  createScalarText,
  createScalarUuid,
  createTransactionPart,
  Either,
  invariant,
  Left,
  listEqual,
  Right,
  scalarEqual,
  sequenceEither,
  scalarToText,
  createScalarCaselessText,
  checkValueUmr,
  createScalarUmr,
} from "../utils";
import { METAS } from "./constants";
import { AddressNodeT_ } from "./nodes/address_node";
import { PromiseNodeT_ } from "./nodes/promise_node";
import { DateNodeT_ } from "./nodes/date_node";
import { DateSelectNodeT_ } from "./nodes/date_select_node";
import { DocumentNodeT_ } from "./nodes/document_node";
import { FileNodeT_ } from "./nodes/file_node";
import { FileSetNodeT_ } from "./nodes/file_set_node";
import { NumberNodeT_ } from "./nodes/number_node";
import { NumberSelectNodeT_ } from "./nodes/number_select_node";
import { NumberSuggestNodeT_ } from "./nodes/number_suggest_node";
import { PercentNodeT_ } from "./nodes/percent_node";
import { PercentSelectNodeT_ } from "./nodes/percent_select_node";
import { PremiumNodeT_ } from "./nodes/premium_node";
import { TextNodeT_, CaselessTextNodeT_ } from "./nodes/text_node";
import { TextSelectNodeT_ } from "./nodes/text_select_node";
import { TextSetSelectNodeT_ } from "./nodes/text_set_select_node";
import { TextSuggestNodeT_ } from "./nodes/text_suggest_node";
import { YesNoNodeT_ } from "./nodes/yes_no_node";
import { CellT, NodeOnTheRightT, NodeT } from "./types";
import { TableNodeT_ } from "/src/data_editor/nodes/table_node";
import { OptionalNodeT_ } from "/src/data_editor/nodes/optional_node";
import { UmrNodeT_ } from "./nodes/umr_node";

export const getDatapointId = (key: Ident, args: Array<Scalar>): string => {
  return key + "(" + args.map((x) => JSON.stringify(x.contents)).join(",") + ")";
};

export type BuildContext = {
  // Config
  readonly keyInfos: KeyInfo[];
  readonly aliasMap: TcAliases;
  readonly commit: (tx: Transaction, ids: string[]) => Promise<void>;
  readonly summaryView: boolean;
  // State
  nodes: Array<NodeT>;
  consumed: Set<string>;

  readonly productId: ProductId;
  readonly policyId: PolicyId;
  readonly quoteId: QuoteId | undefined;
  readonly isCommittingMap: { [key: string]: boolean | undefined };
  readonly formSubmitted: boolean;
  readonly asyncs: Asyncs;
};

export const buildTree = (
  keyInfos: KeyInfo[],
  aliasMap: TcAliases,
  commit: (tx: Transaction, ids: string[]) => Promise<void>,
  summaryView: boolean,
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId | undefined,
  isCommittingMap: { [key: string]: boolean | undefined },
  formSubmitted: boolean,
  asyncs: Asyncs,
): NodeT[] => {
  // Consumed data-points have at least one control for them
  // TODO: handle aliases
  const ctx: BuildContext = {
    keyInfos,
    aliasMap,
    commit,
    nodes: [],
    consumed: new Set(),
    summaryView,
    policyId,
    productId,
    quoteId,
    isCommittingMap,
    formSubmitted,
    asyncs,
  };

  // Build top-level nodes
  const topLevelNodes = buildPage(ctx);

  // TODO: clean this dirty shit up
  const leftoversCtx: BuildContext = {
    ...ctx,
    nodes: [],
  };

  // Problem with showing param'ed nodes here:
  // the ordering doesn't make sense
  // Idea: for groups, there might be a notion of a "sensible parent",
  // e.g. foo.fizz(x: Foo, y: Int) has "sensible parent" foo(x: Foo) : {}
  // Maybe better idea is to do a first pass to determine what will be consumed,
  // then a second pass to build the node tree
  for (const keyInfo of ctx.keyInfos) {
    for (const dpInfo of keyInfo.datapoints) {
      const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
      if (keyInfo.returnType.tag === "Scalar" && !ctx.consumed.has(datapointId)) {
        processScalarDatapoint(
          leftoversCtx,
          { ...keyInfo, returnType: keyInfo.returnType },
          dpInfo,
        );
      }
    }
  }

  return [...topLevelNodes, ...leftoversCtx.nodes];
};

/**
 * Build and return the nodes for a specific |page|. A page has params which must be
 * all of type alias, and args which are values of types of those aliases.
 * tw: for now I don't really get why we need params and args
 * im leaving it commented out as its not used anywhere
 */
const buildPage = (ctx: BuildContext, params?: unknown[], args?: Scalar[]): NodeT[] => {
  const subCtx = {
    ...ctx,
    nodes: [],
  };

  for (const keyInfo of ctx.keyInfos) {
    if (params !== undefined && !dequal(keyInfo.parameters, params)) {
      continue;
    }
    processKeyInfo(subCtx, keyInfo, args);
  }

  return subCtx.nodes;
};

export const buildRightPanel = (keyInfos: KeyInfo[]): Array<NodeOnTheRightT> => {
  const nodes: Array<NodeOnTheRightT> = [];
  for (const keyInfo of keyInfos) {
    for (const dpInfo of keyInfo.datapoints) {
      if (isDatapointOnTheRightPanel(dpInfo)) {
        if (keyInfo.returnType.tag === "Scalar") {
          nodes.push(
            processScalarDatapointOnTheRight(
              { ...keyInfo, returnType: keyInfo.returnType },
              dpInfo,
            ),
          );
        }
      }
      if (keyInfo.returnType.tag === "Conditions") {
        const node = processConditionsDatapointOnTheRight(
          { ...keyInfo, returnType: keyInfo.returnType },
          dpInfo,
        );
        node !== undefined && nodes.push(node);
      }
    }
  }
  return nodes;
};

const processKeyInfo = (ctx: BuildContext, keyInfo: KeyInfo, args?: Scalar[]): void => {
  for (const dpInfo of keyInfo.datapoints) {
    if (args !== undefined && !listEqual(dpInfo.arguments, args, scalarEqual)) {
      continue;
    }

    processDatapoint(ctx, keyInfo, dpInfo);
  }
};

const processDatapoint = (ctx: BuildContext, keyInfo: KeyInfo, dpInfo: DatapointInfo): void => {
  try {
    const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);

    if (!ctx.consumed.has(datapointId)) {
      switch (keyInfo.returnType.tag) {
        case "Scalar": {
          // we need to pass keyInfo in an awkward way to make typescript happy
          processScalarDatapoint(ctx, { ...keyInfo, returnType: keyInfo.returnType }, dpInfo);
          break;
        }
        case "Record": {
          // Note to future self: differentiate between "logical groups" and "visual groups".
          // Logical groups would be about groups of field that should be revealed together. while
          // visual groups are about visual grouping (e.g. showing a header, or indenting).
          processRecordDatapoint(ctx, keyInfo, dpInfo);
          break;
        }
        case "Set": {
          processSetDatapoint(ctx, { ...keyInfo, returnType: keyInfo.returnType }, dpInfo);
          break;
        }
        case "Document": {
          processDocumentDatapoint(ctx, { ...keyInfo, returnType: keyInfo.returnType }, dpInfo);
          break;
        }
        case "Optional": {
          processOptionalDatapoint(ctx, { ...keyInfo, returnType: keyInfo.returnType }, dpInfo);
          break;
        }
        case "Promise": {
          processPromiseDatapoint(
            ctx,
            { ...keyInfo, returnType: keyInfo.returnType },
            dpInfo,
            datapointId,
          );
          break;
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const processPromiseDatapoint = (
  ctx: BuildContext,
  _keyInfo: KeyInfo,
  dpInfo: DatapointInfo,
  datapointId: string,
): void => {
  const prompt = getMeta(dpInfo.prompt) ?? datapointId;
  const taskState: AsyncTaskState = getAsyncTaskState(ctx, datapointId, ctx.quoteId);
  let promiseId: PromiseId | undefined = undefined;
  switch (dpInfo.value?.tag) {
    case "Scalar": {
      switch (dpInfo.value.contents.tag) {
        case "Int":
          promiseId = dpInfo.value.contents.contents;
      }
    }
  }
  const node: PromiseNodeT_ = {
    type: "promise",
    datapointId: datapointId,
    problems: [],
    prompt,
    promiseId,
    taskState,
    asyncStatus: dpInfo.async,
    isCommitting: false,
    label: prompt,
    readOnly: !dpInfo.canWrite,
    needsCollecting: taskState.tag !== "Completed",
    hidden: !dpInfo.canRead,
    description: "",
    id: datapointId,
    summaryView: ctx.summaryView,
    productId: ctx.productId,
    policyId: ctx.policyId,
    mquoteId: ctx.quoteId,
  };
  ctx.nodes.push(node);
};

// Get async task state if there is information about it in the context. If
// not, produce "not running".
const getAsyncTaskState = (
  ctx: BuildContext,
  datapointId: string,
  mquoteId: QuoteId | undefined,
): AsyncTaskState => {
  // Get the latest async task in the list for this given datapointId
  const asyncs = getAsyncForDatapoint(ctx.asyncs, datapointId, mquoteId);
  return asyncs.length === 0 ? { tag: "NotRunning" } : asyncs[asyncs.length - 1];
};

const processConditionsDatapointOnTheRight = (
  keyInfo: KeyInfo & { returnType: { tag: "Conditions" } },
  dpInfo: DatapointInfo,
): NodeOnTheRightT | undefined => {
  const contentsE: Either<string, string[]> = checkNotUndefined(dpInfo.value)
    .andThen(checkValueConditions)
    .andThen((cs) => {
      if (
        cs.overall.tag === "OverallConditionFalse" ||
        cs.overall.tag === "OverallConditionUnknown"
      ) {
        return new Left("");
      }
      const vs = cs.overall.contents.flatMap((v) => {
        if (v.tag !== "CustomViolation") {
          return [];
        }
        return v._0;
      });
      if (vs.length === 0) {
        return new Left("");
      }

      return new Right(vs);
    });
  const contents = contentsE.getRight();
  if (contents !== undefined) {
    const severity = keyInfo.name === "decline" ? "error" : "warning";
    return {
      type: "condition",
      label: keyInfo.name,
      contents,
      severity,
    };
  }
};

export const processPercentScalarDatapoint = (
  dpInfo: DatapointInfo,
  commit: (x: number | null) => void,
  sharedNodeAttrs: {
    id: string;
    label: string;
    isCommitting: boolean;
    readOnly: boolean;
    hidden: boolean;
    needsCollecting: boolean;
    summaryView: boolean;
    formSubmitted: boolean;
    description: string | undefined;
  },
): PercentNodeT_ | PercentSelectNodeT_ => {
  const optionsValues = getMeta(dpInfo.options);
  if (optionsValues !== undefined) {
    const options = sequenceEither(
      optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarPercent)),
    ).getOrThrow();
    const node: PercentSelectNodeT_ = {
      type: "percent_select",
      ...sharedNodeAttrs,
      problems: dpInfo.violations.map((v) => violationToString("percent_select", v)),
      value:
        dpInfo.value !== undefined
          ? checkValueScalar(dpInfo.value).andThen(checkScalarPercent).getOrThrow()
          : null,
      options,
      commit: commit,
    };
    return node;
  } else {
    const node: PercentNodeT_ = {
      type: "percent",
      ...sharedNodeAttrs,
      problems: dpInfo.violations.map((v) => violationToString("percent", v)),
      value:
        dpInfo.value !== undefined
          ? checkValueScalar(dpInfo.value).andThen(checkScalarPercent).getOrThrow()
          : null,
      commit: commit,
    };
    return node;
  }
};

export const processNumberScalarDatapoint = (
  dpInfo: DatapointInfo,
  commit: (x: number | null) => void,
  sharedNodeAttrs: {
    id: string;
    label: string;
    isCommitting: boolean;
    readOnly: boolean;
    hidden: boolean;
    needsCollecting: boolean;
    summaryView: boolean;
    formSubmitted: boolean;
    description: string | undefined;
  },
): NumberNodeT_ | NumberSuggestNodeT_ | NumberSelectNodeT_ | PremiumNodeT_ => {
  const commit_ = commit;

  const minimum =
    checkNotUndefined(getMeta(dpInfo.minimum)).andThen(checkValueNumber).getRight() ?? null;

  const maximum =
    checkNotUndefined(getMeta(dpInfo.maximum)).andThen(checkValueNumber).getRight() ?? null;

  const prefix = checkNotUndefined(getMeta(dpInfo.metas[METAS.PREFIX]))
    .andThen(checkValueText)
    .getRight();
  const optionsValues = getMeta(dpInfo.options);

  const asPremium = checkNotUndefined(getMeta(dpInfo.metas[METAS.AS_PREMIUM]))
    .andThen(checkValueBool)
    .getRight();

  if (asPremium) {
    const value = dpInfo.value !== undefined ? checkValueNumber(dpInfo.value).getOrThrow() : null;

    const readOnly = sharedNodeAttrs.readOnly || !dpInfo.canWrite;

    // tw: temporary

    const node: PremiumNodeT_ = {
      type: "premium",
      ...sharedNodeAttrs,
      readOnly,
      problems: dpInfo.violations.map((v) => violationToString("number", v)),
      value,
      minimum,
      maximum,
      prefix,
      hidePremium: !dpInfo.canRead,
      commit: commit_,
    };
    return node;
  }
  if (optionsValues !== undefined) {
    const options = sequenceEither(
      optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarIntOrNumber)),
    ).getOrThrow();

    const node: NumberSelectNodeT_ = {
      type: "number_select",
      ...sharedNodeAttrs,
      problems: dpInfo.violations.map((v) => violationToString("number_select", v)),
      value: dpInfo.value !== undefined ? checkValueNumber(dpInfo.value).getOrThrow() : null,
      options,
      prefix,
      commit: commit_,
    };
    return node;
  } else {
    const suggestions = checkNotUndefined(getMeta(dpInfo.metas[METAS.SUGGESTIONS]))
      .andThen((s) => checkValueListScalarOf(s, checkScalarNumber))
      .getRight();

    if (suggestions !== undefined) {
      const node: NumberSuggestNodeT_ = {
        type: "number_suggest",
        ...sharedNodeAttrs,
        problems: dpInfo.violations.map((v) => violationToString("number", v)),
        value: dpInfo.value !== undefined ? checkValueNumber(dpInfo.value).getOrThrow() : null,
        options: suggestions,
        minimum,
        maximum,
        prefix,
        commit: commit_,
      };
      return node;
    } else {
      const node: NumberNodeT_ = {
        type: "number",
        ...sharedNodeAttrs,
        problems: dpInfo.violations.map((v) => violationToString("number", v)),
        value: dpInfo.value !== undefined ? checkValueNumber(dpInfo.value).getOrThrow() : null,
        minimum,
        maximum,
        prefix,
        commit: commit_,
      };
      return node;
    }
  }
};

export const processDateScalarDatapoint = (
  dpInfo: DatapointInfo,
  commit: (x: string | null) => void,
  sharedNodeAttrs: {
    id: string;
    label: string;
    isCommitting: boolean;
    readOnly: boolean;
    hidden: boolean;
    needsCollecting: boolean;
    summaryView: boolean;
    formSubmitted: boolean;
    description: string | undefined;
  },
): DateNodeT_ | DateSelectNodeT_ => {
  const optionsValues = getMeta(dpInfo.options);
  if (optionsValues !== undefined) {
    const options = sequenceEither(
      optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarDate)),
    ).getOrThrow();
    const node: DateSelectNodeT_ = {
      type: "date_select",
      ...sharedNodeAttrs,
      problems: dpInfo.violations.map((v) => violationToString("date_select", v)),
      value:
        dpInfo.value !== undefined
          ? checkValueScalar(dpInfo.value).andThen(checkScalarDate).getOrThrow()
          : null,
      options,
      commit: commit,
    };
    return node;
  } else {
    const minimum_ = getMeta(dpInfo.minimum);
    const minimum = minimum_ === undefined ? null : checkValueText(minimum_).getOrThrow();

    const maximum_ = getMeta(dpInfo.maximum);
    const maximum = maximum_ === undefined ? null : checkValueText(maximum_).getOrThrow();

    const relativeMinimum = getMeta(dpInfo.relative_date_minimum);
    const relativeMaximum = getMeta(dpInfo.relative_date_maximum);
    const node: DateNodeT_ = {
      type: "date",
      ...sharedNodeAttrs,
      problems: dpInfo.violations.map((v) => violationToString("date", v)),
      value:
        dpInfo.value !== undefined
          ? checkValueScalar(dpInfo.value).andThen(checkScalarDate).getOrThrow()
          : null,
      minimum,
      maximum,
      relativeMinimum,
      relativeMaximum,
      commit: commit,
    };
    return node;
  }
};

const processScalarDatapointOnTheRight = (
  keyInfo: KeyInfo & { returnType: { tag: "Scalar" } },
  dpInfo: DatapointInfo,
): NodeOnTheRightT => {
  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);

  const prompt = getMeta(dpInfo.prompt) ?? datapointId;
  const value = getStringValueOutOfDatapoint(keyInfo, dpInfo);

  return { type: "leaf", label: prompt, value };
};

const getStringValueOutOfDatapoint = (
  keyInfo: KeyInfo & { returnType: { tag: "Scalar" } },
  dpInfo: DatapointInfo,
): string => {
  if (dpInfo.value === undefined) {
    return "-";
  }
  const value = dpInfo.value;

  const map_ = <A>(f: (x: Value) => Either<string, A>, g: (y: A) => string): string => {
    return f(value).map(g).getOrThrow();
  };

  switch (keyInfo.returnType.contents.scalarType) {
    case "Text": {
      return map_(checkValueText, identity); // TODO: probably has to be changed because of address etc
    }
    case "Bool": {
      return map_(checkValueBool, (x) => (x ? "yes" : "no"));
    }
    case "Number": {
      const prefixMeta = getMeta(dpInfo.metas.ui_prefix);
      const prefix = prefixMeta === undefined ? "" : `${checkValueText(prefixMeta).getOrThrow()}`;

      return map_(checkValueNumber, (x) => `${prefix}${x.toString()}`);
    }
    default: {
      return "-"; // TODO: handle the rest if needed
    }
  }
};

const isDatapointOnTheRightPanel = (dpInfo: DatapointInfo): boolean => {
  const isOnTheRightPanelMeta_ = dpInfo.metas[METAS.SHOW_IN_QUICKVIEW];
  const isOnTheRightPanelMeta =
    isOnTheRightPanelMeta_ !== undefined ? getMeta(isOnTheRightPanelMeta_) : undefined;
  const isOnTheRight =
    isOnTheRightPanelMeta !== undefined && checkValueBool(isOnTheRightPanelMeta).getOrThrow();
  return isOnTheRight;
};

const processScalarDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo & { returnType: { tag: "Scalar" } },
  dpInfo: DatapointInfo,
): void => {
  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);

  ctx.consumed.add(datapointId);

  if (isDatapointOnTheRightPanel(dpInfo)) {
    return;
  }

  // Metas
  const prompt = getMeta(dpInfo.prompt) ?? datapointId;
  const description_ = getMeta(dpInfo.metas[METAS.DESCRIPTION]);
  const description =
    description_ === undefined ? undefined : checkValueText(description_).getOrThrow();

  const key = createKeyWithArgs(keyInfo.name, dpInfo.arguments);
  const commit =
    <T>(f: (v: T) => Scalar) =>
    (v: T | null) => {
      const fact = v !== null ? createAdditionFact(f(v)) : createRetractionAllFact();
      return ctx.commit([createTransactionPart(key, fact)], [datapointId]);
    };

  // if (hasBlockingObstacles(keyInfo, dpInfo)) {
  //   return;
  // }

  const sharedNodeAttrs = {
    id: datapointId,
    label: prompt,
    // TODO: setting isCommitting to false is temporary
    isCommitting: !!ctx.isCommittingMap[datapointId],
    // commit: commit,
    readOnly: !dpInfo.canWrite,
    hidden: !dpInfo.canRead,
    needsCollecting: dpInfo.needsCollecting,
    summaryView: ctx.summaryView,
    formSubmitted: ctx.formSubmitted,
    description,
  };

  const optionsValues = getMeta(dpInfo.options);

  const createScalarNumberFunction = (v: "Number" | "Int") => {
    if (v == "Number") {
      return createScalarNumber;
    } else {
      return createScalarInt;
    }
  };
  switch (keyInfo.returnType.contents.scalarType) {
    case "Text": {
      const control_ = getMeta(dpInfo.metas.ui_control);
      const control = control_ === undefined ? undefined : checkValueText(control_).getOrThrow();
      const problems = dpInfo.violations.map((v) => violationToString("text", v));
      const value = dpInfo.value !== undefined ? checkValueText(dpInfo.value).getOrThrow() : null;
      if (control === "address") {
        const countryRestriction = checkNotUndefined(getMeta(dpInfo.metas.country_restriction))
          .andThen((v) => checkValueListScalarOf(v, checkScalarText))
          .getOrThrow();
        const node: AddressNodeT_ = {
          type: "address",
          ...sharedNodeAttrs,
          problems,
          value,
          countryRestriction,
          commit: commit(createScalarText),
        };
        ctx.nodes.push(node);
      } else if (optionsValues !== undefined) {
        const options = sequenceEither(optionsValues.map(checkValueText)).getOrThrow();
        const node: TextSelectNodeT_ = {
          ...sharedNodeAttrs,
          type: "text_select",
          problems,
          value,
          options,
          commit: commit(createScalarText),
        };
        ctx.nodes.push(node);
      } else {
        const suggestions = checkNotUndefined(getMeta(dpInfo.metas[METAS.SUGGESTIONS]))
          .andThen((s) => checkValueListScalarOf(s, checkScalarText))
          .getRight();

        if (suggestions !== undefined) {
          const node: TextSuggestNodeT_ = {
            type: "text_suggest",
            ...sharedNodeAttrs,
            problems: dpInfo.violations.map((v) => violationToString("text", v)),
            value: dpInfo.value !== undefined ? checkValueText(dpInfo.value).getOrThrow() : null,
            options: suggestions,
            commit: commit(createScalarText),
          };
          ctx.nodes.push(node);
        } else {
          const isMultiline = getMeta(dpInfo.multiline) ?? false;
          const node: TextNodeT_ = {
            type: "text",
            ...sharedNodeAttrs,
            problems,
            value,
            isMultiline,
            commit: commit(createScalarText),
          };
          ctx.nodes.push(node);
        }
      }
      break;
    }
    case "CaselessText": {
      const problems = dpInfo.violations.map((v) => violationToString("text", v));
      const value =
        dpInfo.value !== undefined ? checkValueCaselessText(dpInfo.value).getOrThrow() : null;
      const isMultiline = getMeta(dpInfo.multiline) ?? false;
      const node: CaselessTextNodeT_ = {
        type: "caseless_text",
        ...sharedNodeAttrs,
        problems,
        value,
        isMultiline,
        commit: commit(createScalarCaselessText),
      };
      ctx.nodes.push(node);
      break;
    }
    case "Umr": {
      const problems = dpInfo.violations.map((v) => violationToString("text", v));
      const value = dpInfo.value !== undefined ? checkValueUmr(dpInfo.value).getOrThrow() : null;
      const node: UmrNodeT_ = {
        type: "umr",
        ...sharedNodeAttrs,
        problems,
        value,
        commit: commit(createScalarUmr),
      };
      ctx.nodes.push(node);
      break;
    }
    case "Bool": {
      const customYesPrompt = checkNotUndefined(getMeta(dpInfo.metas[METAS.CUSTOM_YES_PROMPT]))
        .andThen(checkValueText)
        .getRight();
      const customNoPrompt = checkNotUndefined(getMeta(dpInfo.metas[METAS.CUSTOM_NO_PROMPT]))
        .andThen(checkValueText)
        .getRight();
      const node: YesNoNodeT_ = {
        type: "yes_no",
        ...sharedNodeAttrs,
        problems: dpInfo.violations.map((v) => violationToString("yes_no", v)),
        value: dpInfo.value !== undefined ? checkValueBool(dpInfo.value).getOrThrow() : null,
        commit: commit(createScalarBool),
        customYesPrompt,
        customNoPrompt,
      };
      ctx.nodes.push(node);
      break;
    }
    case "Percent": {
      const node = processPercentScalarDatapoint(
        dpInfo,
        commit(createScalarPercent),
        sharedNodeAttrs,
      );
      ctx.nodes.push(node);
      break;
    }
    case "Int":
    case "Number": {
      const node = processNumberScalarDatapoint(
        dpInfo,
        commit(createScalarNumberFunction(keyInfo.returnType.contents.scalarType)),
        sharedNodeAttrs,
      );
      ctx.nodes.push(node);
      break;
    }
    case "File": {
      const node: FileNodeT_ = {
        type: "file",
        ...sharedNodeAttrs,
        problems: dpInfo.violations.map((v) => violationToString("file", v)),
        value: dpInfo.value !== undefined ? checkValueFile(dpInfo.value).getOrThrow() : null,
        commit: commit(createScalarFile),
      };
      ctx.nodes.push(node);
      break;
    }
    case "Date": {
      const node: DateNodeT_ | DateSelectNodeT_ = processDateScalarDatapoint(
        dpInfo,
        commit(createScalarDate),
        sharedNodeAttrs,
      );
      ctx.nodes.push(node);
      break;
    }
  }
};

const processRecordDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo,
  dpInfo: DatapointInfo,
): void => {
  const render = getMeta(dpInfo.render);
  const description_ = getMeta(dpInfo.metas[METAS.DESCRIPTION]);
  const description =
    description_ === undefined ? undefined : checkValueText(description_).getOrThrow();
  if (render !== undefined && checkValueText(render).getOrThrow() === "money") {
    const amountNodeId = getDatapointId(`${keyInfo.name}.amount`, dpInfo.arguments);
    const amountNode = ctx.nodes.find((node) => node.id === amountNodeId);
    invariant(
      amountNode !== undefined && amountNode.type === "number",
      "bad or missing amount node",
    );
    const currencyNodeId = getDatapointId(`${keyInfo.name}.currency`, dpInfo.arguments);
    const currencyNode = ctx.nodes.find((node) => node.id === currencyNodeId);
    invariant(
      currencyNode !== undefined && currencyNode.type === "text_select",
      "bad or missing currency node",
    );
    const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
    const prompt = getMeta(dpInfo.prompt) ?? datapointId;
    ctx.nodes.push({
      type: "money",
      id: datapointId,
      label: prompt,
      hidden: !dpInfo.canRead || amountNode.hidden || currencyNode.hidden,
      /**
       * Money nodes have two sub-components: the currency selector and
       * the input amount, and they can be disabled independently.
       */
      readOnly: amountNode.readOnly, // somehow dpInfo.canWrite is always false?
      currencyReadOnly: currencyNode.readOnly,
      needsCollecting: dpInfo.needsCollecting,
      summaryView: ctx.summaryView,
      problems: amountNode.problems.concat(currencyNode.problems),
      isCommitting: currencyNode.isCommitting || amountNode.isCommitting,
      currencyNode,
      amountNode,
      description,
    });
    ctx.nodes = ctx.nodes.filter((node) => node.id !== amountNodeId && node.id !== currencyNodeId);
  } else {
    const headingValue = getMeta(dpInfo.metas.heading);
    if (headingValue !== undefined) {
      const heading = checkValueText(headingValue).getOrThrow();
      const childNodes = ctx.nodes
        .filter((node) => node.id.startsWith(keyInfo.name))
        .map((node) => {
          if (node.type === "group") {
            return { ...node, level: node.level + 1 };
          }
          return node;
        });
      const otherNodes = ctx.nodes.filter((node) => !node.id.startsWith(keyInfo.name));
      ctx.nodes = otherNodes.concat([
        {
          type: "group",
          id: getDatapointId(keyInfo.name, dpInfo.arguments),
          heading,
          level: 1,
          nodes: childNodes,
          summaryView: ctx.summaryView,
          quoteId: ctx.quoteId,
        },
      ]);
    }
  }
};

const getUnderlyingType = (v: Typ, aliases: TcAliases): Scalar["tag"] | undefined => {
  switch (v.tag) {
    case "Scalar": {
      return v.contents.scalarType;
    }
    case "Alias": {
      const alias = aliases[v.contents];
      if (alias !== undefined && alias.tag === "Scalar") {
        return alias.contents.scalarType;
      }
    }
  }
};

const processMultiSelectDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo & { returnType: { tag: "Set" } },
  dpInfo: DatapointInfo,
): void => {
  const key = createKeyWithArgs(keyInfo.name, dpInfo.arguments);
  const scalarType = getUnderlyingType(keyInfo.returnType.contents, ctx.aliasMap);
  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);

  const prompt = getMeta(dpInfo.prompt) ?? datapointId;

  function valueToText(v: Value): Either<string, string> {
    return checkValueScalar(v).andThen(scalarToText);
  }
  // The inverse of scalarToText. We can play a little fast and loose here
  // since we are parsing values we created in scalarToText - we don't need to
  // handle edge cases.
  function createScalar(v: string): Scalar {
    switch (scalarType) {
      case "Text":
        return createScalarText(v);
      case "CaselessText":
        return createScalarText(v);
      case "Int":
        return createScalarInt(Number.parseInt(v));
      case "Number":
        return createScalarNumber(Number.parseFloat(v));
      case "Bool":
        return createScalarBool(v === "yes");
      case "Date":
        return createScalarDate(v);
      case "Percent":
        return createScalarPercent(Number.parseFloat(v));
    }
    throw "Unhandled case in createScalar " + scalarType;
  }

  const supportedTypes = ["Text", "Int", "Number", "Bool", "Date", "Percent", "CaselessText"];
  if (scalarType !== undefined && supportedTypes.includes(scalarType)) {
    const multiOptions = checkNotUndefined(
      getMeta(dpInfo.multiOptions),
      "options cant be undefined. Metas:",
    )
      .andThen((vs) => sequenceEither(vs.map(valueToText)))
      .getOrThrow();
    const value = checkNotUndefined(dpInfo.value)
      .andThen((v) => checkValueListScalarOf(v, scalarToText))
      .getOrThrow();
    const add = async (v: string): Promise<void> => {
      const fact = createAdditionFact(createScalar(v));
      await ctx.commit([createTransactionPart(key, fact)], [datapointId]);
    };
    const remove = async (v: string): Promise<void> => {
      const fact = createRetractionSingleFact(createScalar(v));
      await ctx.commit([createTransactionPart(key, fact)], [datapointId]);
    };
    const replace = async (newValue: string[]): Promise<void> => {
      const operations = [];

      for (const v of value) {
        if (newValue.indexOf(v) === -1) {
          operations.push(createTransactionPart(key, createRetractionSingleFact(createScalar(v))));
        }
      }
      for (const v of newValue) {
        if (value.indexOf(v) === -1) {
          operations.push(createTransactionPart(key, createAdditionFact(createScalar(v))));
        }
      }
      if (operations.length > 0) {
        await ctx.commit(operations, [datapointId]);
      }
    };

    const chipListPosition = getMeta(dpInfo.metas.chipListPosition);
    const allowCustomOption = getMeta(dpInfo.metas.allowCustomOption);

    const description_ = getMeta(dpInfo.metas[METAS.DESCRIPTION]);
    const description =
      description_ === undefined ? undefined : checkValueText(description_).getOrThrow();
    ctx.nodes.push({
      type: "text_set_select",
      id: datapointId,
      label: prompt,
      problems: [],
      readOnly: !dpInfo.canWrite,
      hidden: !dpInfo.canRead,
      value: value,
      chipListPosition:
        chipListPosition !== undefined ? checkValueText(chipListPosition).getOrThrow() : "before",
      allowCustomOption:
        allowCustomOption !== undefined
          ? checkValueBool(allowCustomOption).getOrThrow()
          : multiOptions.length === 0,
      add: add,
      remove: remove,
      replace: replace,
      isCommitting: !!ctx.isCommittingMap[datapointId],
      options: multiOptions,
      needsCollecting: false,
      summaryView: ctx.summaryView,
      description,
    } as TextSetSelectNodeT_);
    ctx.consumed.add(datapointId);
    return;
  }

  throw Error("Set value key not of type Text or UUID");
};

const processSetDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo & { returnType: { tag: "Set" } },
  dpInfo: DatapointInfo,
): void => {
  if (
    keyInfo.returnType.contents.tag === "Scalar" &&
    keyInfo.returnType.contents.contents.scalarType === "File"
  ) {
    const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
    const key = createKeyWithArgs(keyInfo.name, dpInfo.arguments);
    const node: FileSetNodeT_ = {
      type: "file_set",
      id: datapointId,
      label: getMeta(dpInfo.prompt) ?? datapointId,
      readOnly: !dpInfo.canWrite,
      hidden: !dpInfo.canRead,
      needsCollecting: dpInfo.needsCollecting,
      summaryView: ctx.summaryView,
      isCommitting: false,
      problems: dpInfo.violations.map((v) => violationToString("file_set", v)),
      value: checkNotUndefined(dpInfo.value)
        .andThen(checkValueListOrSet)
        .andThen((x) => sequenceEither(x.map(checkValueFile)))
        .getOrThrow(),
      add: async (file: string) => {
        await ctx.commit(
          [createTransactionPart(key, createAdditionFact(createScalarFile(file)))],
          [datapointId],
        );
      },
      remove: async (file: string) => {
        await ctx.commit(
          [createTransactionPart(key, createRetractionSingleFact(createScalarFile(file)))],
          [datapointId],
        );
      },
      description: undefined,
    };
    ctx.nodes.push(node);
    return;
  }
  const metaRender: MetaRender | undefined = extractMetaRender(
    dpInfo,
    "set-valued key without render meta-key: " +
      keyInfo.name +
      "\ndatapoint: " +
      JSON.stringify(dpInfo) +
      "\nkey:\n " +
      JSON.stringify(keyInfo),
  ).getRight();

  if (metaRender === undefined) {
    const isUuidLike = (t: Typ): boolean =>
      // NOTE: Assume aliases are always to UUID.
      t.tag === "Alias" || (t.tag === "Scalar" && t.contents.scalarType === "Uuid");

    if (isUuidLike(keyInfo.returnType.contents)) {
      const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
      const key = createKeyWithArgs(keyInfo.name, dpInfo.arguments);

      const uuids = checkNotUndefined(dpInfo.value)
        .andThen((x) => checkValueListOf(x, checkValueUuid))
        .getOrThrow();

      const problems = dpInfo.violations.map((v) => violationToString("subforms", v));
      ctx.nodes.push({
        type: "subforms",
        id: datapointId,
        label: getMeta(dpInfo.prompt) ?? datapointId,
        hidden: !dpInfo.canRead,
        elems: uuids.map((uuid) => ({
          id: uuid,
          nodes: buildPage(ctx, [keyInfo.returnType.contents], [createScalarUuid(uuid)]),
          removeItem: async () => {
            await ctx.commit(
              [createTransactionPart(key, createRetractionSingleFact(createScalarUuid(uuid)))],
              [datapointId],
            );
          },
        })),
        problems: problems,
        readOnly: keyInfo.readOnly,
        isCommitting: !!ctx.isCommittingMap[datapointId],
        needsCollecting: dpInfo.needsCollecting,
        summaryView: ctx.summaryView,
        addItem: async () => {
          const newKey: string = v4();
          const fact = createAdditionFact(createScalarUuid(newKey));
          await ctx.commit([createTransactionPart(key, fact)], [datapointId]);
        },
        description: undefined,
        quoteId: ctx.quoteId,
      });
      ctx.consumed.add(datapointId);
      return;
    } else {
      processMultiSelectDatapoint(ctx, keyInfo, dpInfo);
      return;
    }
  }

  const key = createKeyWithArgs(keyInfo.name, dpInfo.arguments);
  const scalarType = getUnderlyingType(keyInfo.returnType.contents, ctx.aliasMap);

  if (scalarType !== "Uuid") {
    throw new Error("Only tables with uuid keys are supported");
  }

  const rows: TableNodeT_["rows"] = [];

  let oneOfCellsNeedsCollecting = false;
  let oneOfCellsHasProblems = false;
  for (const rowInfo of metaRender.rows) {
    const rowId = rowInfo.id; // btoa(rowInfo.id);
    const cells: CellT[] = [];
    const removeTransactions = [
      createTransactionPart(key, createRetractionSingleFact(createScalarUuid(rowId))),
    ];

    for (const column of metaRender.columns) {
      const cellId = `${column.name}-${rowInfo.id}`;
      const cellInfo = checkNotUndefined(
        rowInfo.cells[column.name],
        "no cell for column: " + column.name,
      ).getOrThrow();

      if (cellInfo.tag === "Scalar" && cellInfo.contents.tag === "Text") {
        // this is not a "datapoint" value, so its always read only
        cells.push({
          type: "text",
          id: cellId,
          readOnly: true,
          hidden: false,
          value: cellInfo.contents.contents,
          isMultiline: false,
          commit: () => undefined,
          description: undefined,
          isCommitting: false,
          needsCollecting: false,
          summaryView: false,
          formSubmitted: ctx.formSubmitted,
          label: "",
          problems: [],
          columnName: column.name,
        });
      } else if (cellInfo.tag === "Record") {
        // we want to:
        // 1) look up the datapoint corresponding to the key + arguments
        // 2) create a CellNode from it, or throw if cannot.
        // 2.1) if the datapoint is not found, create a n/a node
        const key: Ident = checkNotUndefined(cellInfo.contents.key)
          .andThen(checkValueText)
          .getOrThrow();
        const args: Array<Scalar> = checkValueListOf(
          checkNotUndefined(cellInfo.contents.arguments).getOrThrow(),
          checkValueScalar,
        ).getOrThrow();
        const datapointId = getDatapointId(key, args);
        const cell: CellT = buildCell(
          ctx.keyInfos,
          ctx.commit,
          key,
          args,
          cellId,
          ctx.isCommittingMap,
          ctx.formSubmitted,
          column.name,
        );
        cells.push(cell);
        ctx.nodes = ctx.nodes.filter((n) => n.id !== datapointId);
        ctx.consumed.add(datapointId);
        if (!cell.readOnly) {
          removeTransactions.push(
            createTransactionPart(createKeyWithArgs(key, args), createRetractionAllFact()),
          );
        }
        if ("needsCollecting" in cell && cell.needsCollecting) {
          oneOfCellsNeedsCollecting = true;
        }
        if ("problems" in cell && cell.problems.length > 0) {
          oneOfCellsHasProblems = true;
        }
      }
    }

    rows.push({
      id: rowId,
      cells,
      removeItem: async () => {
        await ctx.commit(removeTransactions, [datapointId]);
      },
    });
  }

  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);

  ctx.nodes.push({
    type: "table",
    id: datapointId,
    columns: metaRender.columns,
    rows: rows,
    idConfig: { type: "select_text", options: [] },
    label: getMeta(dpInfo.prompt) ?? datapointId,
    showHeader: true,
    problems: dpInfo.violations
      .map((v) => violationToString("table", v))
      .concat(
        [
          oneOfCellsNeedsCollecting ? ["Some required table cells need to be filled out."] : [],
          oneOfCellsHasProblems ? ["Some table cells have problems."] : [],
        ].flat(),
      ),
    addItem: async () => {
      const newKey: string = v4();
      const fact = createAdditionFact(createScalarUuid(newKey));
      await ctx.commit([createTransactionPart(key, fact)], [datapointId]);
    },
    readOnly: keyInfo.readOnly || !dpInfo.canWrite,
    hidden: !dpInfo.canRead,
    isCommitting: false,
    needsCollecting: dpInfo.needsCollecting,
    summaryView: ctx.summaryView,
    description: undefined,
  });
  ctx.consumed.add(datapointId);
};

const processDocumentDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo & { returnType: { tag: "Document" } },
  dpInfo: DatapointInfo,
) => {
  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
  ctx.consumed.add(datapointId);

  const nodeT = {
    type: "document",
    id: datapointId,
    summaryView: ctx.summaryView,
    label: getMeta(dpInfo.prompt) ?? datapointId,
    hidden: !dpInfo.canRead,
    readOnly: !dpInfo.canWrite,
    productId: ctx.productId,
    policyId: ctx.policyId,
    quoteId: ctx.quoteId,
  };

  if (dpInfo.value === undefined) {
    const values = dpInfo.obstacles.flatMap((o) => {
      if (o.tag !== "OMissingData") {
        return [];
      }
      const dpInfo = findDatapoint(o.contents, ctx.keyInfos);
      if (dpInfo === undefined) {
        return [];
      }
      const datapointId = getDatapointId(o.contents.key, dpInfo.arguments);
      return `Missing value: ${getMeta(dpInfo.prompt) ?? datapointId}`;
    });
    const node: DocumentNodeT_ = {
      ...nodeT,
      type: "document",
      value: { type: "missing_values", values },
    };
    ctx.nodes.push(node);
  } else {
    const doc = checkValueDocument(dpInfo.value).getOrThrow();
    const contents = doc.parts
      .map((v) => {
        switch (v.tag) {
          case "FromHtml": {
            return v.contents;
          }
          case "FromStaticPdf": {
            return `<h1>static pdf: ${v.contents}</h1>`;
          }
        }
      })
      .join();

    const node: DocumentNodeT_ = {
      ...nodeT,
      type: "document",
      value: { type: "ready", template: contents, documentName: doc.name },
    };
    ctx.nodes.push(node);
  }
};

const processOptionalDatapoint = (
  ctx: BuildContext,
  keyInfo: KeyInfo,
  dpInfo: DatapointInfo,
): void => {
  const datapointId = getDatapointId(keyInfo.name, dpInfo.arguments);
  ctx.consumed.add(datapointId);
  // Unwrap the Optional's type, discarding the `Optional` tag.
  const buildInnerReturnType: (t: Typ) => Typ = (t) => {
    if (t.tag === "Optional") {
      return t.contents;
    } else {
      throw Error(`Unexpected type tag in Optional: ${t.tag}`);
    }
  };
  // Unwrap the Optional value, discarding the `Null` and `Some` tags.
  const buildInnerValue: (value: Value | null | undefined) => Value | undefined = (value) => {
    if (value === null || value === undefined) {
      return undefined;
    } else if (value.tag === "Null") {
      return undefined;
    } else if (value.tag === "Some") {
      return value.contents;
    } else {
      throw Error(`Unrecognized value tag in Optional: ${value.tag}.`);
    }
  };
  /** We synthesize a {@link KeyInfo} along with a modified {@link DatapointInfo}, in which we
   * "unwrap" the `Optional` tags so that they look like a non-optional node. Then we use
   * these "fakes" to build a {@link CellT} for the inner content of the optional.
   */
  const buildInnerCell: () => CellT = () => {
    const innerValue: Value | undefined = buildInnerValue(dpInfo.value);
    const innerReturnType: Typ = buildInnerReturnType(keyInfo.returnType);
    const innerDpInfo: DatapointInfo = { ...dpInfo, value: innerValue };
    const innerKeyInfo = {
      ...keyInfo,
      name: `optional+${datapointId}`,
      returnType: innerReturnType,
      datapoints: [innerDpInfo],
    };
    /** Why use {@link buildCell} to build a {@link CellT} instead of using
     *  {@link processDatapoint} to build a {@link NodeT}?
     *
     * The reason is that {@link processDatapoint} does too much and requires too much baggage,
     * like a {@link BuildContext} that we don't need here. It also returns the generated nodes
     * using side-effects instead of directly as the function's result.
     */
    return buildCell(
      [innerKeyInfo],
      // Currently, optional nodes are not collectable, so
      // we don't have to do anything here.
      (_tx, _string) => {
        return;
      },
      innerKeyInfo.name,
      innerDpInfo.arguments,
      "optional",
      {},
      false,
      "optional",
    );
  };
  const innerCell: CellT = buildInnerCell();
  const nodeT: OptionalNodeT_ = {
    type: "optional",
    id: datapointId,
    summaryView: ctx.summaryView,
    label: getMeta(dpInfo.prompt) ?? datapointId,
    hidden: !dpInfo.canRead,
    readOnly: !dpInfo.canWrite,
    isCommitting: !!ctx.isCommittingMap[datapointId],
    addItem: () => {
      return;
    },
    problems: dpInfo.violations.map((v) => violationToString("optional", v)),
    needsCollecting: dpInfo.needsCollecting,
    description: undefined,
    innerCell,
  };
  const node: OptionalNodeT_ = {
    ...nodeT,
  };
  ctx.nodes.push(node);
};

export const buildCell = (
  keyInfos: KeyInfo[],
  commit: (tx: Transaction, ids: string[]) => void,
  // datapoint: Datapoint,
  ident: Ident,
  args: Scalar[],
  id: string,
  isCommittingMap: { [key: string]: boolean | undefined },
  formSubmitted: boolean,
  columnName: string,
): CellT => {
  const key = keyInfos.find((k) => k.name === ident);
  if (key === undefined) {
    // key not found. assume the data-point is thus not applicable
    return {
      type: "n/a",
      id,
      readOnly: true,
      isCommitting: false,
      columnName,
    };
  }
  const dp = key.datapoints.find((d) => listEqual(d.arguments, args, scalarEqual));
  if (dp === undefined) {
    return {
      type: "n/a",
      id,
      readOnly: true,
      isCommitting: false,
      columnName,
    };
  }
  if (key.returnType.tag !== "Scalar") {
    throw new Error("cant render cell for key (not a scalar): " + key.name);
  }

  // const commitValue = mkCommitScalar(datapoint, commit);
  const key_ = createKeyWithArgs(key.name, args);
  const commit_ =
    <T>(f: (v: T) => Scalar) =>
    (v: T | null): void => {
      const fact = v !== null ? createAdditionFact(f(v)) : createRetractionAllFact();
      commit([createTransactionPart(key_, fact)], [id]);
    };
  const description_ = getMeta(dp.metas[METAS.DESCRIPTION]);
  const description =
    description_ === undefined ? undefined : checkValueText(description_).getOrThrow();

  if (key.returnType.contents.scalarType === "Int") {
    const ui_prefix_ = getMeta(dp.metas.ui_prefix);
    const optionsValues = getMeta(dp.options);
    if (optionsValues !== undefined) {
      const options = sequenceEither(
        optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarInt)),
      ).getOrThrow();
      const cell: CellT = {
        type: "number_select",
        id,
        problems: [],
        value: dp.value !== undefined ? checkValueNumber(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarInt),
        prefix: ui_prefix_ === undefined ? undefined : checkValueText(ui_prefix_).getOrThrow(),
        summaryView: false,
        needsCollecting: dp.needsCollecting,
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        label: "",
        options: options,
        formSubmitted,
        description,
        columnName,
      };
      return cell;
    } else {
      const cell: CellT = {
        type: "number",
        id,
        problems: [],
        value: dp.value !== undefined ? checkValueNumber(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarInt),
        prefix: ui_prefix_ === undefined ? undefined : checkValueText(ui_prefix_).getOrThrow(),
        summaryView: false,
        needsCollecting: dp.needsCollecting,
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        label: "",
        maximum: null,
        minimum: null,
        formSubmitted,
        description,
        columnName,
      };
      return cell;
    }
  } else if (key.returnType.contents.scalarType === "Number") {
    const ui_prefix_ = getMeta(dp.metas.ui_prefix);
    const optionsValues = getMeta(dp.options);
    if (optionsValues !== undefined) {
      const options = sequenceEither(
        optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarNumber)),
      ).getOrThrow();
      const cell: CellT = {
        type: "number_select",
        id,
        problems: [],
        value: dp.value !== undefined ? checkValueNumber(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarNumber),
        prefix: ui_prefix_ === undefined ? undefined : checkValueText(ui_prefix_).getOrThrow(),
        summaryView: false,
        needsCollecting: dp.needsCollecting,
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        label: "",
        options: options,
        formSubmitted,
        description,
        columnName,
      };
      return cell;
    } else {
      const numberCell: CellT = {
        type: "number",
        id,
        problems: [],
        value: dp.value !== undefined ? checkValueNumber(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarNumber),
        prefix: ui_prefix_ === undefined ? undefined : checkValueText(ui_prefix_).getOrThrow(),
        summaryView: false,
        needsCollecting: dp.needsCollecting,
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        label: "",
        maximum: null,
        minimum: null,
        formSubmitted,
        description,
        columnName,
      };
      const suggestions = checkNotUndefined(getMeta(dp.metas[METAS.SUGGESTIONS]))
        .andThen((s) => checkValueListScalarOf(s, checkScalarNumber))
        .getRight();
      if (suggestions !== undefined) {
        const suggestTextCell: CellT = {
          ...numberCell,
          type: "number_suggest",
          options: suggestions,
          columnName,
        };
        return suggestTextCell;
      } else {
        return numberCell;
      }
    }
  } else if (key.returnType.contents.scalarType === "Percent") {
    const optionsValues = getMeta(dp.options);
    if (optionsValues !== undefined) {
      const options = sequenceEither(
        optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarPercent)),
      ).getOrThrow();
      const node: CellT = {
        type: "percent_select",
        id,
        problems: dp.violations.map((v) => violationToString("percent", v)),
        value:
          dp.value !== undefined
            ? checkValueScalar(dp.value).andThen(checkScalarPercent).getOrThrow()
            : null,
        isCommitting: !!isCommittingMap[id],
        options,
        commit: commit_(createScalarPercent),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        formSubmitted,
        description,
        columnName,
      };
      return node;
    } else {
      return {
        type: "percent",
        id,
        problems: dp.violations.map((v) => violationToString("percent", v)),
        value:
          dp.value !== undefined
            ? checkValueScalar(dp.value).andThen(checkScalarPercent).getOrThrow()
            : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarPercent),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        formSubmitted,
        description,
        columnName,
      };
    }
  } else if (key.returnType.contents.scalarType === "Date") {
    const optionsValues = getMeta(dp.options);
    if (optionsValues !== undefined) {
      const options = sequenceEither(
        optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarDate)),
      ).getOrThrow();
      const node: CellT = {
        type: "date_select",
        id,
        problems: dp.violations.map((v) => violationToString("date_select", v)),
        value:
          dp.value !== undefined
            ? checkValueScalar(dp.value).andThen(checkScalarDate).getOrThrow()
            : null,
        isCommitting: !!isCommittingMap[id],
        options,
        commit: commit_(createScalarDate),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        formSubmitted,
        description,
        columnName,
      };
      return node;
    } else {
      return {
        type: "date",
        problems: [],
        id,
        value:
          dp.value !== undefined
            ? checkValueScalar(dp.value).andThen(checkScalarDate).getOrThrow()
            : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarDate),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        minimum: null,
        maximum: null,
        formSubmitted,
        description,
        columnName,
      };
    }
  } else if (key.returnType.contents.scalarType === "Text") {
    const optionsValues = getMeta(dp.options);
    if (optionsValues !== undefined) {
      const options = sequenceEither(
        optionsValues.map((o) => checkValueScalar(o).andThen(checkScalarText)),
      ).getOrThrow();
      return {
        type: "text_select",
        problems: [],
        id,
        value: dp.value !== undefined ? checkValueText(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarText),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        options,
        formSubmitted,
        description,
        columnName,
      };
    } else {
      const textCell: CellT = {
        type: "text",
        problems: [],
        id,
        value: dp.value !== undefined ? checkValueText(dp.value).getOrThrow() : null,
        isCommitting: !!isCommittingMap[id],
        commit: commit_(createScalarText),
        label: "",
        hidden: !dp.canRead,
        readOnly: !dp.canWrite,
        needsCollecting: dp.needsCollecting,
        summaryView: false,
        isMultiline: false,
        formSubmitted,
        description,
        columnName,
      };
      const suggestions = checkNotUndefined(getMeta(dp.metas[METAS.SUGGESTIONS]))
        .andThen((s) => checkValueListScalarOf(s, checkScalarText))
        .getRight();
      if (suggestions !== undefined) {
        const suggestTextCell: CellT = {
          ...textCell,
          type: "text_suggest",
          options: suggestions,
          columnName,
        };
        return suggestTextCell;
      } else {
        return textCell;
      }
    }
  } else if (key.returnType.contents.scalarType === "CaselessText") {
    const textCell: CellT = {
      type: "caseless_text",
      problems: [],
      id,
      value: dp.value !== undefined ? checkValueCaselessText(dp.value).getOrThrow() : null,
      isCommitting: !!isCommittingMap[id],
      commit: commit_(createScalarCaselessText),
      label: "",
      hidden: !dp.canRead,
      readOnly: !dp.canWrite,
      needsCollecting: dp.needsCollecting,
      summaryView: false,
      isMultiline: false,
      formSubmitted,
      description,
      columnName,
    };
    return textCell;
  } else if (key.returnType.contents.scalarType === "Umr") {
    const cell: CellT = {
      type: "umr",
      problems: [],
      id,
      value: dp.value !== undefined ? checkValueUmr(dp.value).getOrThrow() : null,
      isCommitting: !!isCommittingMap[id],
      commit: commit_(createScalarUmr),
      label: "",
      hidden: !dp.canRead,
      readOnly: !dp.canWrite,
      needsCollecting: dp.needsCollecting,
      summaryView: false,
      formSubmitted,
      description,
      columnName,
    };
    return cell;
  } else if (key.returnType.contents.scalarType === "Bool") {
    const customYesPrompt = checkNotUndefined(getMeta(dp.metas[METAS.CUSTOM_YES_PROMPT]))
      .andThen(checkValueText)
      .getRight();
    const customNoPrompt = checkNotUndefined(getMeta(dp.metas[METAS.CUSTOM_NO_PROMPT]))
      .andThen(checkValueText)
      .getRight();
    const cell: CellT = {
      type: "yes_no",
      problems: dp.violations.map((v) => violationToString("yes_no", v)),
      id,
      value: dp.value !== undefined ? checkValueBool(dp.value).getOrThrow() : null,
      isCommitting: !!isCommittingMap[id],
      commit: commit_(createScalarBool),
      label: "",
      hidden: !dp.canRead,
      readOnly: !dp.canWrite,
      // No table cell value absolutely needs to be collected.
      needsCollecting: false,
      summaryView: false,
      formSubmitted,
      description,
      customYesPrompt,
      customNoPrompt,
      columnName,
    };
    return cell;
  } else {
    throw new Error("cannot render cell for scalar type: " + key.returnType.contents.scalarType);
  }
};

export function getMeta<T>(v: HowDefiniteA<T> | undefined): T | undefined {
  if (v === undefined) {
    return undefined;
  } else {
    switch (v.tag) {
      case "NotDefined":
        return undefined;
      case "HasDefinition":
        return undefined; // tw: its ok when the meta key is not yet defined
      // it will be defined once enough data is filled in
      case "HasValue":
        return v.contents;
    }
  }
}

/**
 * Get a meta-key from datapoint information. Can be used both for special meta-keys
 * (prompt, minimum, maximum, options, multiline) and for custom ones. If the meta-key
 * is defined but fails to evaluate, throws an error.
 */
// function getMeta<T = unknown>(info: DatapointInfo, name: string): T | undefined {
//   const meta =
//     name === "prompt" ||
//     name === "minimum" ||
//     name === "maximum" ||
//     name === "options" ||
//     name === "multiline"
//       ? info[name]
//       : info.metas[name];
//   if (meta === undefined) {
//     return undefined;
//   } else if (meta.tag === "HasDefinition") {
//     // Hard failed on error'ed meta-keys. I am not entirely sure this is desirable behaviour, but I think it's
//     // the most reasonable action. This should only be reached if the front-end is attempting to display a node
//     // that has an error'ed meta-key. I think this indicates a mistake in the product specification.
//     throw new Error(`meta-key ${name} failed to evaluate`);
//   } else if (meta.tag === "NotDefined") {
//     return undefined;
//   } else {
//     return meta.contents as T;
//   }
// }

// function getStringMeta(info: DatapointInfo, name: string): string | undefined {
//   const meta = getMeta(info, name);
//   return meta === undefined ? undefined : checkValueText(meta).getOrThrow();
// }

// function getStringArrayMeta(info: DatapointInfo, name: string): string[] | undefined {
//   const meta = getMeta(info, name);
//   return meta === undefined ? undefined : checkStringArray(meta);
// }

// function getNumberMeta(info: DatapointInfo, name: string): number | undefined {
//   const meta = getMeta(info, name);
//   return meta === undefined ? undefined : checkValueNumber(meta).getOrThrow();
// }

// function getNumberArrayMeta(info: DatapointInfo, name: string): number[] | undefined {
//   const meta = getMeta(info, name);
//   return meta === undefined ? undefined : checkNumberArray(meta);
// }

// function getBooleanMeta(info: DatapointInfo, name: string): boolean | undefined {
//   const meta = getMeta(info, name);
//   return meta === undefined ? undefined : checkBoolean(meta);
// }

// TODO: dirty dirty
const violationToString = (nodeType: NodeT["type"], violation: Violation): string => {
  switch (violation.tag) {
    case "MinMaxViolation":
      switch (nodeType) {
        case "number": {
          switch (violation._0) {
            case "Minimum": {
              const v = checkValueScalar(violation._1).andThen(checkScalarIntOrNumber).getOrThrow();
              return `Enter a number above ${v} (inclusive).`;
            }
            case "Maximum": {
              const v = checkValueScalar(violation._1).andThen(checkScalarIntOrNumber).getOrThrow();
              return `Enter a number below ${v} (inclusive).`;
            }
          }
          break;
        }
        case "percent": {
          switch (violation._0) {
            case "Minimum": {
              const v = checkValueScalar(violation._1).andThen(checkScalarPercent).getOrThrow();
              return `Enter a number above ${v}% (inclusive).`;
            }
            case "Maximum": {
              const v = checkValueScalar(violation._1).andThen(checkScalarPercent).getOrThrow();
              return `Enter a number below ${v}% (inclusive).`;
            }
          }
          break;
        }
        case "date":
          switch (violation._0) {
            case "Minimum": {
              const contents = checkValueScalar(violation._1).andThen(checkScalarDate).getOrThrow();
              return `Enter a date after ${contents} (inclusive).`;
              break;
            }
            case "Maximum": {
              const contents = checkValueScalar(violation._1).andThen(checkScalarDate).getOrThrow();
              return `Enter a date before ${contents} (inclusive).`;
              break;
            }
          }
          break;
        default:
          return "MinMaxViolation";
      }
      break;
    case "OptionsViolation":
      return "Invalid selection.";
    case "MultiOptionsViolation":
      return "Invalid selections.";
    case "CustomViolation":
      return violation._0;
    case "DisallowedAdjustment":
      return "DisallowedAdjustment"; //todo: proper violation
  }
};

export const hasBlockingObstacles = (keyInfo: KeyInfo, dpInfo: DatapointInfo): boolean => {
  const datapoint: FunCall<string, Value> = {
    key: keyInfo.name,
    arguments: dpInfo.arguments.map((s) => ({ tag: "Scalar", contents: s })),
  };
  return dpInfo.obstacles.some(
    (o) => (o.tag === "OMissingData" && !dequal(o.contents, datapoint)) || o.tag === "OError",
  );
};

export const findDatapoint = (dp: Datapoint, keyInfos: KeyInfo[]): DatapointInfo | undefined => {
  const ki = keyInfos.find((v) => v.name === dp.key);
  if (ki !== undefined) {
    const dpInfo = ki.datapoints.find((v) =>
      dequal(
        v.arguments.map((s): Value => ({ tag: "Scalar", contents: s })),
        dp.arguments,
      ),
    );
    return dpInfo;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getMissingDatapoints = (
  obstacles: DatapointInfo["obstacles"],
  ctx: BuildContext,
): DatapointInfo[] => {
  const missing: Datapoint[] = obstacles.flatMap((o) =>
    o.tag === "OMissingData" ? [o.contents] : [],
  );
  const missingDpInfos = missing.flatMap((o) => {
    const dp = findDatapoint(o, ctx.keyInfos);
    return dp !== undefined ? [dp] : [];
  });

  return missingDpInfos;
};

type MetaRender = {
  type: "table";
  rows: {
    id: string;
    cells: {
      [x: string]: Value | undefined;
    };
  }[];
  columns: {
    name: string;
    label: string;
  }[];
};

const extractMetaRender = (
  dpInfo: DatapointInfo,
  errMsg: string,
): Either<string, MetaRender | undefined> =>
  checkNotUndefined(getMeta(dpInfo.render), errMsg)
    .andThen(checkValueRecord)
    .map((record) => {
      const type = checkNotUndefined(record.type).andThen(checkValueText).getOrThrow();
      switch (type) {
        case "table": {
          const rows_ = checkNotUndefined(record.rows).andThen((v) =>
            checkValueListOf(v, (row) => {
              return checkRecordOf(row, (rowRecord) => {
                const id_ = checkNotUndefined(rowRecord.id).andThen(checkValueUuid);
                const cells_ = checkNotUndefined(rowRecord.cells).andThen(checkValueRecord);

                return id_.andThen((id) => cells_.map((cells) => ({ id, cells })));
              });
            }),
          );

          const columns_ = checkNotUndefined(record.columns).andThen((v) =>
            checkValueListOf(v, (column) => {
              return checkRecordOf(column, (columnRecord) => {
                const name_ = checkNotUndefined(columnRecord.name).andThen(checkValueText);
                const label_ = checkNotUndefined(columnRecord.label).andThen(checkValueText);

                return name_.andThen((name) => label_.map((label) => ({ name, label })));
              });
            }),
          );

          return rows_
            .andThen((rows) =>
              columns_.map((columns) => ({ type: "table" as const, rows, columns })),
            )
            .getRight();
        }
        default:
          throw new Error("bad render type: " + type);
      }
    });
