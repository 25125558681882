import { scheme, Vector2 } from "vega";
import embed from "vega-embed";
import React, { useRef, useEffect } from "react";
import { isoCodeToCountryName } from "../utils/countries";
import { formatNumber } from "../utils";

export type RiskLocation = {
  code: number;
  value: number;
  rate: number;
};

type VegaRiskLocation = RiskLocation & { country: string };

export const ChoroplethMap = (props: { states: RiskLocation[] }): JSX.Element => {
  // Handle for the parent (real) DOM element for Vega to sit inside.
  const parentRef = useRef(null);
  const givenStates = props.states;

  // Pop the given set of states in a map.
  const givenStatesMap: Map<number, RiskLocation> = new Map();
  for (const state of givenStates) {
    givenStatesMap.set(state.code, state);
  }

  // Make a complete list of all countries, using the values from the map.
  const vegaValues: VegaRiskLocation[] = [];
  for (const [code, name] of isoCodeToCountryName.entries()) {
    const givenState = givenStatesMap.get(code);
    if (givenState !== undefined && givenState !== null) {
      vegaValues.push({
        ...givenState,
        country: name,
      });
    } else {
      vegaValues.push({
        code,
        rate: 0.0,
        value: 0,
        country: name,
      });
    }
  }

  // Vega spec
  const spec = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    width: "container" as const,
    height: 325,
    data: {
      url: "/world-110m.json",
      format: { type: "topojson" as const, feature: "countries" as const },
    },
    transform: [
      {
        lookup: "id",
        from: {
          data: { values: vegaValues },
          key: "code",
          fields: ["rate", "value", "country"],
        },
      },
    ],
    mark: {
      type: "geoshape" as const,
      tooltip: true,
    },
    projection: {
      type: "mercator" as const,
      // These numbers are very specific to the width/height of the container.
      // They're used because it looks good on the analytics
      // page. If used elsewhere, this ought to be passed in
      // instead.
      scale: 100,
      center: [0, 25] as Vector2<number>,
    },
    encoding: {
      color: {
        field: "rate",
        type: "quantitative" as const,
        legend: null,
        scale: { scheme: "artificialBlue" },
      },
      tooltip: [
        {
          field: "country",
          type: "nominal" as const,
        },
        {
          field: "value",
          type: "quantitative" as const,
        },
      ],
    },
    padding: 0,
    config: {},
  };

  useEffect(() => {
    // Preconditions
    if (parentRef.current == null) return;

    // Artificial's blue theme. Vega interpolates the colors automatically.
    scheme("artificialBlue", ["#DBEAFE", "#93C5FD", "#3B82F6"]);

    // Make a new Vega view
    embed(parentRef.current, spec, {
      mode: "vega-lite",
      actions: false,
      tooltip: {
        formatTooltip: ({ country, value }) => `<b>${country}</b> ${formatNumber(Number(value))}`,
      },
    }).catch(console.warn);
  }, []);

  return <div className="w-full" ref={parentRef}></div>;
};
