import React from "react";

export default function NotFound() {
  return (
    <div className="min-h-screen place-content-center flex flex-col p-8 md:p-16 bg-blue-700 text-white">
      <img
        src="https://media.giphy.com/media/vCHKWBlygEAtG/giphy.gif"
        className="flex-row w-80 rounded-lg shadow-xl hidden md:flex"
      ></img>
      <h2 className="w-initial text-2xl mb-2 mt-8 text-semi-bold">404!</h2>
      <div className="w-initial text-lg mb-1 text-blue-300 leading-8">
        Sorry, we can't find that page.
      </div>
      <div className="w-initial text-sm text-blue-300">
        Please{" "}
        <a
          className="underline transition ease-in-out hover:text-white"
          href="mailto:product@artificial.io"
        >
          contact us
        </a>{" "}
        if you're still having trouble.
      </div>
    </div>
  );
}
