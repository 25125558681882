import { DEFAULT_PAGE_NUMBER } from "../data_editor/constants";
import { Loader } from "./Loader";
import React from "react";
import clsx from "clsx";

const thStyles = [
  "px-6",
  "py-3",
  "text-left",
  "text-xs",
  "font-medium",
  "text-gray-500",
  "uppercase",
  "tracking-wider",
  "sticky",
  "top-0",
  "bg-gray-50",
];
const tdStyles = ["px-6", "py-2", "whitespace-nowrap", "text-sm", "text-gray-500", "align-top"];
export const TH = (props: {
  label: string;
  className?: string | undefined;
  sortOrder?: "asc" | "desc" | undefined;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <th className={clsx(thStyles, props.className)} onClick={props.onClick}>
      <span className="flex ">
        {props.label}
        <div className="h-4 w-4 -top-1">
          {props.sortOrder !== undefined && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              {props.sortOrder === "desc" && (
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              )}

              {props.sortOrder === "asc" && (
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          )}
        </div>
      </span>
    </th>
  );
};

export const THead = (props: React.PropsWithChildren<{ className?: string }>) => (
  <thead className={props.className}>{props.children}</thead>
);

export function TableHeader<A extends string = string>({
  headers,
  currentSortOrder,
  currentSortBy,
  setSortBy,
}: {
  headers: {
    sortBy?: A | undefined;
    label: string;
  }[];
  currentSortOrder?: SortOrder | undefined;
  currentSortBy?: A | undefined;
  setSortBy?: (sortBy: A, sortOrder: SortOrder) => void;
}) {
  return (
    <THead>
      <TR>
        {headers.map(({ sortBy, label }) => {
          const sortable = sortBy !== undefined;
          return (
            <TH
              label={label}
              key={label}
              sortOrder={sortable && sortBy === currentSortBy ? currentSortOrder : undefined}
              className={clsx(sortable && "cursor-pointer")}
              onClick={
                sortable && setSortBy !== undefined
                  ? () =>
                      setSortBy(
                        sortBy,
                        sortBy === currentSortBy
                          ? toggleSortOrder(currentSortOrder ?? "asc")
                          : "desc",
                      )
                  : undefined
              }
            />
          );
        })}
      </TR>
    </THead>
  );
}

export const TR = (
  props: React.PropsWithChildren<{ className?: string; onClick?: () => void }>,
) => (
  <tr className={props.className} onClick={props.onClick}>
    {props.children}
  </tr>
);

export const TD = (props: React.PropsWithChildren<{ className?: string }>) => (
  <td className={clsx(tdStyles, props.className)}>{props.children}</td>
);

const tbodyStyles = ["bg-white", "divide-y", "divide-gray-200"];

export const TBody = (props: React.PropsWithChildren<{ className?: string }>) => (
  <tbody className={clsx(tbodyStyles, props.className)}>{props.children}</tbody>
);

export const tableStyles = [
  "bg-white",
  "w-full",
  "rounded",
  "divide-y",
  "divide-gray-20",
  "h-full",
];

export function TableBody<A extends string | number = string | number>({
  rows,
  onClick,
}: {
  onClick?: (rowId: A) => void;
  rows: {
    rowId: A;
    columns: {
      size?:
        | "adjust" // adjusts the size to the widest element of that given column
        | "medium"
        | "large"
        | "full";
      content: string | number | JSX.Element;
      className?: string | undefined;
    }[];
  }[];
}) {
  return (
    <TBody>
      {rows.map(({ rowId, columns }) => {
        return (
          <TR
            key={rowId}
            className={clsx("hover:bg-gray-50 group", onClick !== undefined && "cursor-pointer")}
          >
            {columns.map(({ size, content, className }) => {
              const sizeToClass = (() => {
                switch (size) {
                  case "adjust":
                    return "";
                  case "medium":
                    return "w-80";
                  case "large":
                    return "w-96";
                  case "full":
                    return "w-full";
                }
              })();
              return <TD className={clsx(sizeToClass, className)}>{content}</TD>;
            })}
          </TR>
        );
      })}
    </TBody>
  );
}

// if you want to have the scrollable table then create a div above the table with "overflow-hidden"
export const Table = (props: React.PropsWithChildren<{ className?: string }>) => (
  <div className="w-full border-gray-200 flex flex-col max-h-full h-full overflow-auto">
    <table className={clsx(tableStyles, props.className)}>{props.children}</table>
  </div>
);

function generatePaginationNumbers({
  currentPageNumber = DEFAULT_PAGE_NUMBER,
  rowsPerPage = 20,
  totalRowsCount,
}: {
  currentPageNumber?: number;
  rowsPerPage?: number;
  totalRowsCount: number;
}) {
  let firstRowNumber = currentPageNumber;
  let lastRowNumber = rowsPerPage;

  if (currentPageNumber > 1) {
    firstRowNumber = (currentPageNumber - 1) * rowsPerPage;
    lastRowNumber = currentPageNumber * rowsPerPage;
  }

  if (lastRowNumber > totalRowsCount) {
    lastRowNumber = totalRowsCount;
  }

  return {
    firstRowNumber,
    lastRowNumber,
    currentPageNumber,
  };
}

export const Pagination = (props: {
  rowsPerPage?: number;
  totalRowsCount: number;
  currentPageNumber?: number;
  onPageChange: (n: number) => void;
}): JSX.Element => {
  const { totalRowsCount, onPageChange } = props;
  const { firstRowNumber, lastRowNumber, currentPageNumber } = generatePaginationNumbers(props);

  return (
    <div className="bg-white p-4 flex flex-row justify-end w-full border-t border-gray-200 text-gray-700">
      <div className="flex flex-row items-center">
        <p className="ml-2 mr-2">
          {firstRowNumber} - {lastRowNumber} of {totalRowsCount}
        </p>
      </div>
      <button
        className="appearance-none outline-none focus:outline-none disabled:opacity-20"
        disabled={currentPageNumber === 1}
        onClick={() => onPageChange(currentPageNumber - 1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 text-gray-900"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        className="appearance-none outline-none focus:outline-none disabled:opacity-20"
        disabled={lastRowNumber === totalRowsCount}
        onClick={() => onPageChange(currentPageNumber + 1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 text-gray-900"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  );
};

export function TableLoader() {
  return (
    <div className="bg-white flex flex-row h-full justify-center items-center overflow-hidden">
      <Loader />
    </div>
  );
}

export type SortOrder = "asc" | "desc";

export const toggleSortOrder = (s: SortOrder): SortOrder => {
  switch (s) {
    case "desc":
      return "asc";
    case "asc":
      return "desc";
  }
};
