/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { Box, BoxProps } from "./Box";
import { FONT_COLOUR } from "./Colour";

export type AccordionProps = PropsWithChildren<
  {
    title: string | JSX.Element;
    button?: { show: string; hide: string; type: "caption" } | { type: "chevron" };
    className?: string;
    initialOpen?: boolean;
  } & BoxProps
>;

export default function Accordion({
  title,
  button = { type: "chevron" },
  className,
  children,
  initialOpen,
  ...rest
}: AccordionProps) {
  const titleAsSpan = typeof title === "string";
  return (
    <Disclosure as="div" className={className} defaultOpen={!!initialOpen}>
      {({ open }) => (
        <>
          <Box
            className={overrideTailwindClasses(
              clsx(
                "text-lg",
                open && rest.border === "visible" && " rounded-b-none border-b",
                className,
              ),
            )}
            {...rest}
          >
            <Disclosure.Button className="text-left w-full flex justify-between  text-gray-400 items-center focus:ring-0">
              {titleAsSpan && (
                <span className={`font-medium ${FONT_COLOUR(rest.colour)}`}>{title}</span>
              )}
              {!titleAsSpan && title}
              <span className="ml-6 h-7 flex items-center">
                {button.type === "chevron" && (
                  <ChevronDownIcon
                    className={clsx(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                    aria-hidden="true"
                  />
                )}
                {button.type === "caption" && (
                  <span className="uppercase text-xs font-medium tracking-wider text-gray-400">
                    {open ? button.hide : button.show}
                  </span>
                )}
              </span>
            </Disclosure.Button>
          </Box>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel>
              <Box
                className={clsx(
                  rest.border === "visible" && "rounded-t-none border-t-0",
                  className,
                  "text-ellipsis max-w-full",
                )}
                {...rest}
              >
                {children}
              </Box>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
