// Goal: similar to Airtable's interface
import { TextField } from "@mui/material";
import React from "react";
import clsx from "clsx";
import { generalEditorOrSummaryView } from "../types";
import { FocusableRefObject } from "./focusable_node";
import { GoalInfo, ReferMap } from "/src/internal_types";
import { CellT } from "../types";
import { LeafNodeT_ } from "./base";
import NumberFormat from "react-number-format";
import { NumberNodeSummaryView, NumberNodeT_ } from "./number_node";
import { PercentNodeSummaryView, PercentNodeT_ } from "./percent_node";
import { TextNodeSummaryView, TextNodeT_ } from "./text_node";
import { DateNodeSummaryView, DateNodeT_ } from "./date_node";
export interface OptionalNodeT_ extends LeafNodeT_ {
  type: "optional";
  /** The "inner" content of the optional.
   * Its value will be `null` or `undefined` if the optional is empty.
   */
  innerCell: CellT;
  isCommitting: boolean;
  addItem: () => void;
}

export const OptionalNode = (props: {
  node: OptionalNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}): JSX.Element =>
  generalEditorOrSummaryView(
    props.node,
    (n) => (
      <OptionalNodeEditor
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
    (n) => (
      <OptionalNodeSummary
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
  );

export const OptionalNodeEditor = (props: {
  node: OptionalNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}) => {
  const { node } = props;
  return (
    <div>
      <div className="p-0 text-md text-gray-700 py-3">{node.label}</div>
      <div className="space-y-4">
        {renderOptionalCell(node.innerCell, node.label, node.summaryView)}
      </div>
    </div>
  );
};

export const OptionalNodeSummary = (props: {
  node: OptionalNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}) => {
  const { node } = props;
  /**
   * Unlike in {@link OptionalNodeEditor}, here we delegate in each particular
   * -NodeSummaryView component the task of displaying of the node's label.
   */
  return renderOptionalCell(node.innerCell, node.label, node.summaryView);
};

/**
 * Since we are using {@link CellT} why don't we use {@link renderCell} from the
 * table node instead of this function?  That would reduce code duplication. But
 * when using {@link renderCell} there were some problems with local state not
 * being reset when it should, resulting in components showing obsolete
 * information. Also, {@link renderCell} doesn't render node labels.
 *
 * TODO: reuse more components from the {@link renderNode} function instead.
 * Also rely on {@link renderOptionalCell} to render labels in all cases, even
 * while editing.
 * @param cell The {@link CellT} corresponding to the inner value of the {@link OptionalNode}.
 * @param label The label of the {@link OptionalNode}.
 * @param summaryView True if we want the summary view.
 * @returns
 */
export const renderOptionalCell = (
  cell: CellT,
  label: string,
  summaryView: boolean,
): JSX.Element => {
  /**
   * Why do we supply the label here, instead of baking it into {@link CellT}?
   * {@link CellT} in its full generality doesn't admit a "label" field, only
   * the subset which we are actually using does. So we attach the label after
   * having refined the type into one of the possible branches.
   *
   * TODO: Perhaps define an 'OptionalCellT' type with a label field.
   */
  switch (cell.type) {
    case "text":
      return <TextOptionalCellInput cell={{ ...cell, label, summaryView }} />;
    case "number":
      return <NumberOptionalCellInput cell={{ ...cell, label, summaryView }} />;
    case "percent":
      return <PercentOptionalCellInput cell={{ ...cell, label, summaryView }} />;
    case "date":
      return <DateOptionalCellInput cell={{ ...cell, label, summaryView }} />;
    case "n/a":
      return <div></div>;
    default:
      throw Error(`Unhandled optional cell type ${cell.type}.`);
  }
};

const TextOptionalCellInput = (props: { cell: TextNodeT_ }) => {
  const { cell } = props;
  const localValue = cell.value?.toString() ?? "";

  return cell.summaryView ? (
    <TextNodeSummaryView key={cell.id} node={cell} />
  ) : (
    <TextField
      disabled={cell.readOnly}
      value={localValue}
      multiline={cell.isMultiline}
      // helperText={cell.description}
    />
  );
};

const NumberOptionalCellInput = (props: { cell: NumberNodeT_ }) => {
  const { cell } = props;
  const localValue = cell.value?.toString() ?? "";

  return cell.summaryView ? (
    <NumberNodeSummaryView key={cell.id} node={cell} />
  ) : (
    <NumberFormat
      disabled={cell.readOnly}
      thousandSeparator={true}
      customInput={TextField}
      className={clsx(["aui-input border-none", "w-full", "focus:outline-none"])}
      prefix={cell.prefix}
      allowEmptyFormatting
      value={localValue}
    />
  );
};

const PercentOptionalCellInput = (props: { cell: PercentNodeT_ }) => {
  const { cell } = props;
  const localValue = cell.value?.toString() ?? "";

  return cell.summaryView ? (
    <PercentNodeSummaryView key={cell.id} node={cell} />
  ) : (
    <NumberFormat
      disabled={cell.readOnly}
      thousandSeparator={true}
      customInput={TextField}
      className={clsx(["aui-input border-none", "focus:outline-none"])}
      suffix="%"
      value={localValue}
    />
  );
};

const DateOptionalCellInput = (props: { cell: DateNodeT_ }) => {
  const { cell } = props;
  const localValue = cell.value?.toString() ?? "";

  return cell.summaryView ? (
    <DateNodeSummaryView key={cell.id} node={cell} />
  ) : (
    <NumberFormat
      disabled={cell.readOnly}
      customInput={TextField}
      format="####-##-##"
      mask="_"
      className="rounded border-gray-300 py-2"
      value={localValue}
    />
  );
};
