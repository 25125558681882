import React from "react";
import { PolicyInfo, QuoteInfo } from "/src/internal_types";

export const PolicyContext = React.createContext<{
  policy: PolicyInfo;
}>({
  get policy(): PolicyInfo {
    throw new Error("not in a policy context");
  },
});

export const QuoteNullableContext = React.createContext<{
  quote: QuoteInfo | undefined;
}>({
  get quote(): QuoteInfo | undefined {
    return undefined;
  },
});
