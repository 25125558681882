import React from "react";
import NumberFormat from "react-number-format";
import { generalEditorOrSummaryView, useSingleNodeState } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";
import { NumberNodeT_ } from "./number_node";
import { TextSelectNodeT_ } from "./text_select_node";
import { toNonEmptyString, toNumber, unique } from "/src/utils";

export interface MoneyNodeT_ extends LeafNodeT_ {
  type: "money";
  currencyNode: TextSelectNodeT_;
  amountNode: NumberNodeT_;
  description: string | undefined;
  readonly currencyReadOnly: boolean;
}

export const MoneyNode = (props: { node: MoneyNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, MoneyNodeEditorView, MoneyNodeSummaryView);
};

export const MoneyNodeEditorView = (props: { node: MoneyNodeT_ }): JSX.Element => {
  const { node } = props;
  const [localAmountValue, setLocalAmountValue] = useSingleNodeState(node.amountNode);
  const [localCurrencyValue, setLocalCurrencyValue] = useSingleNodeState(node.currencyNode);

  const handleOnBlur = async () => {
    node.amountNode.commit(toNumber(localAmountValue));
  };

  const onCurrencyChange = (newValue: string) => {
    setLocalCurrencyValue(newValue);
    node.currencyNode.commit(toNonEmptyString(newValue));
  };

  // TODO: NumberFormat<any> is ugly but equivalent to the type in react-number-format before
  // https://github.com/s-yadav/react-number-format/commit/1b6d2f39817cd2a8995980bec7a4caad1b6322b2
  return (
    <LeafNode
      node={{
        ...props.node,
        needsCollecting:
          props.node.needsCollecting ||
          props.node.amountNode.needsCollecting ||
          props.node.currencyNode.needsCollecting,
        problems: unique(
          props.node.amountNode.problems.concat(
            props.node.currencyNode.problems,
            props.node.problems,
          ),
        ),
        isCommitting:
          props.node.isCommitting ||
          props.node.amountNode.isCommitting ||
          props.node.currencyNode.isCommitting,
      }}
    >
      {
        /** Are the currency selector and the amount input both disabled?
         * Render them both as single immutable fields.
         */
        node.currencyReadOnly && node.readOnly ? (
          <NumberFormat<any>
            thousandSeparator={true}
            helperText={node.description}
            min={node.amountNode.minimum ?? undefined}
            max={node.amountNode.maximum ?? undefined}
            customInput={TextField}
            prefix={(node.currencyNode.value ?? "").trim().concat(" ")}
            allowEmptyFormatting
            value={localAmountValue ?? undefined}
            onBlur={handleOnBlur}
            disabled={node.readOnly}
            node={node}
          />
        ) : (
          <div className="flex border border-gray-300 rounded w-72 overflow-hidden">
            {/** Render the currency selector (possibly disabled) as a separate component. */}
            <select
              className="text-sm border-0 border-r border-gray-300 focus:border-gray-300"
              style={{ boxShadow: "none" }}
              value={localCurrencyValue}
              onChange={(evt) => onCurrencyChange(evt.target.value)}
              disabled={node.currencyReadOnly}
            >
              <option value=""></option>
              {node.currencyNode.options.map((curr) => (
                <option value={curr}>{curr}</option>
              ))}
            </select>
            {/** Render the amount. */}
            <div className="grow">
              <NumberFormat<any>
                className="border-0 text-sm flex-1"
                style={{ boxShadow: "none" }}
                thousandSeparator={true}
                helperText={node.description}
                min={node.amountNode.minimum ?? undefined}
                max={node.amountNode.maximum ?? undefined}
                customInput={TextField}
                allowEmptyFormatting
                value={localAmountValue ?? undefined}
                onValueChange={(e) => {
                  setLocalAmountValue(e.value);
                }}
                onBlur={handleOnBlur}
                disabled={node.amountNode.readOnly}
                node={node}
              />
            </div>
          </div>
        )
      }
    </LeafNode>
  );
};
//customInput={TextField}

const MoneyNodeSummaryView = (props: { node: MoneyNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[
        props.node.currencyNode.value !== null &&
        props.node.currencyNode.value !== undefined &&
        props.node.amountNode.value !== null &&
        props.node.amountNode.value !== undefined
          ? `${props.node.currencyNode.value} ${props.node.amountNode.value.toLocaleString()}`
          : "",
      ]}
    />
  );
};
