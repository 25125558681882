import React from "react";
import CodeMirror from "./components/CodeMirror";
import { FieldProps } from "formik";
import { Input, Select, Textarea } from "./design_system/kitchen_sink";

export function FormikInput({ field, form: _form, ...props }: FieldProps<any>) {
  return (
    <div>
      <Input {...field} {...props} />
    </div>
  );
}

export function FormikTextarea({ field, form: _form, ...props }: FieldProps<any>) {
  return (
    <div>
      <Textarea {...field} {...props} />
    </div>
  );
}

export function FormikCodeMirror({ field, form, ..._props }: FieldProps<any>) {
  return (
    <div>
      <CodeMirror
        initialText={field.value}
        onTextChange={(getText) => form.setFieldValue(field.name, getText(), true)}
        readOnly={false}
      />
    </div>
  );
}

export function FormikSelect({ field, form: _form, ...props }: FieldProps<any>) {
  return <Select {...field} {...props} />;
}
