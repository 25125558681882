import {
  ArchiveIcon,
  ChatAlt2Icon,
  CheckCircleIcon,
  CogIcon,
  DocumentAddIcon,
  ExclamationIcon,
  FolderOpenIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { format } from "date-fns";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Linkify from "react-linkify";
import ScrollableFeed from "react-scrollable-feed";
import { postChat, useGetAllUsers, useGetChat, usePolicyTimeline } from "./dal/dal";
import * as api_types from "/src/api_types";
import { Button } from "/src/design_system/Button";
import { assertNever, checkValueScalar } from "/src/utils";
import { throwIfAppError } from "/src/utils/app_error";

const TimelineEntry: React.FC<{
  whom: string;
  what: string;
  when: string;
  icon: any;
}> = (props) => {
  return (
    <div className="flex-col mb-4 pb-4">
      <div className="flex items-top ">
        <div className="mt-0.5">{props.icon}</div>
        <div>
          <div className="font-medium inline-flex mr-1">{props.whom}</div>
          <span className="inline-flex">{props.what}</span>
        </div>
      </div>
      <div className="flex ml-6">{props.children}</div>
      <div className="text-xs tracking-wide text-gray-500 pl-6 mt-2">
        {format(new Date(props.when), "dd/MM/yy @ KK:mmaaa")}
      </div>
    </div>
  );
};

const Comment: React.FC<{
  userFullName: string;
  timestamp: string;
  message?: string;
}> = (props) => {
  return (
    <div className="flex-col space-x-3 break-words">
      <TimelineEntry
        whom={props.userFullName}
        what="commented"
        when={props.timestamp}
        icon={<ChatAlt2Icon className="h-4 w-4 mr-2 text-gray-400" />}
      >
        <div className="max-w-full rounded shadow-sm bg-blue-500 text-white px-4 py-2 whitespace-pre-line mt-2">
          <Linkify
            componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
              return (
                <a target="_blank" href={decoratedHref} key={key} className="underline">
                  {decoratedText}
                </a>
              );
            }}
          >
            {props.message}
          </Linkify>
        </div>
      </TimelineEntry>
    </div>
  );
};

const PolicyCreatedEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="created the submission"
    when={props.timestamp}
    icon={<FolderOpenIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const PolicyBoundEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="bound the policy"
    when={props.timestamp}
    icon={<CheckCircleIcon className="h-4 w-4 mr-2 text-green-400" />}
  />
);

const PolicyNotTakenUpEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="not taken up the policy"
    when={props.timestamp}
    icon={<ArchiveIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const PolicyArchivedEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="archived the policy"
    when={props.timestamp}
    icon={<ArchiveIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const QuoteCreatedEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="created a quote"
    when={props.timestamp}
    icon={<DocumentAddIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const QuoteReferredEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="referred a quote"
    when={props.timestamp}
    icon={<CogIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const QuoteNotTakenUpEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="not taken up a quote"
    when={props.timestamp}
    icon={<ArchiveIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const QuoteAcceptedEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="accepted a quote"
    when={props.timestamp}
    icon={<CheckCircleIcon className="h-4 w-4 mr-2 text-green-400" />}
  />
);

const QuoteDeclinedEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="declined a quote"
    when={props.timestamp}
    icon={<XCircleIcon className="h-4 w-4 mr-2 text-red-400" />}
  />
);

const PolicyCancelledEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="scheduled a cancellation"
    when={props.timestamp}
    icon={<ExclamationIcon className="h-4 w-4 mr-2 text-yellow-400" />}
  />
);

const EndorsementCreateEvent: React.FC<{
  userFullName: string;
  timestamp: string;
}> = (props) => (
  <TimelineEntry
    whom={props.userFullName}
    what="created an endorsement"
    when={props.timestamp}
    icon={<DocumentAddIcon className="h-4 w-4 mr-2 text-gray-400" />}
  />
);

const SingleEdit: React.FC<api_types.components["schemas"]["KeyDiff"]> = (props) => {
  const getValue = (x: api_types.components["schemas"]["Value"]) => {
    const val = checkValueScalar(x).getRight();
    switch (val?.contents) {
      case true:
        return "Yes";
      case false:
        return "No";
      default:
        return val?.contents;
    }
  };
  switch (props.tag) {
    case "ValueAdded":
      const newVal = getValue(props.contents);
      return (
        <p className="relative overflow-x-auto rounded font-mono text-green-700 bg-green-100 px-3 py-2">
          <span className="absolute select-none">+</span>
          <span className="inline-flex ml-4">{newVal}</span>
        </p>
      );
    case "ValueRemoved":
      const oldVal = getValue(props.contents);
      return (
        <p className="relative overflow-x-auto rounded font-mono text-red-700 bg-red-100 px-3 py-2">
          <span className="absolute select-none">-</span>
          <span className="inline-flex ml-4 line-through">{oldVal}</span>
        </p>
      );
    case "ValueChanged":
      const current = getValue(props.contents[1]);
      const previous = getValue(props.contents[0]);
      return (
        <span className="grid grid-cols-2 break-all">
          <p className="relative overflow-x-auto rounded rounded-r-none font-mono text-red-700 bg-red-100 px-3 py-2">
            <span className="absolute select-none">-</span>
            <span className="inline-flex ml-4 line-through">{previous}</span>
          </p>
          <p className="relative overflow-x-auto rounded rounded-l-none font-mono text-green-700 bg-green-100 px-3 py-2">
            <span className="absolute select-none">+</span>
            <span className="inline-flex ml-4">{current}</span>
          </p>
        </span>
      );
  }
};

const EditCluster: React.FC<{
  userFullName: string;
  timestamp: string;
  eventType: api_types.components["schemas"]["TimelineEventType"] & { tag: "EditCluster" };
}> = (props) => {
  const quote = props.eventType.contents[0];
  const isQuoteChange = !(quote === null);
  return (
    <div className="relative flex-col space-x-3">
      <TimelineEntry
        whom={props.userFullName}
        what={
          isQuoteChange ? "made changes to quote " + props.eventType.contents[0] : "made changes"
        }
        when={props.timestamp}
        icon={<PencilIcon className="h-4 w-4 mr-2 text-gray-400" />}
      >
        <div className="w-full rounded shadow-sm bg-white text-gray-700 mt-2 border">
          <ul className="divide-y">
            {props.eventType.contents[1].map((el, index) => {
              // TODO: handle arrays
              const name = el[0][0];
              return (
                <li className="px-3 py-3" key={index}>
                  <h3 className="font-medium mb-2 break-words">{name}</h3>
                  <ErrorBoundary fallbackRender={() => <div>Can't show diff</div>}>
                    <SingleEdit {...el[1]} />
                  </ErrorBoundary>
                </li>
              );
            })}
          </ul>
        </div>
      </TimelineEntry>
    </div>
  );
};

export const Timeline: React.FC<{
  productId: number;
  policyId: number;
}> = ({ productId, policyId }) => {
  const timelineResp = usePolicyTimeline(productId, policyId);
  throwIfAppError(timelineResp);
  const chatResp = useGetChat(productId, policyId);
  throwIfAppError(chatResp);
  const userResp = useGetAllUsers();
  throwIfAppError(userResp);
  const [loading, setLoading] = useState<boolean>();
  const [commentContent, setCommentContent] = useState<string>();

  const submitComment = async () => {
    if (commentContent !== undefined && commentContent.trim() !== "") {
      setLoading(true);
      await postChat(productId, policyId, commentContent).then(
        () =>
          //chatResp.mutate(res, false),
          null,
      );
      setCommentContent("");
      setLoading(false);
    }
  };

  const getUserFullName = (userId: string) => {
    const userData = userResp.value.users.find((u) => u.id === userId);
    if (userData === undefined) {
      return "Unknown user";
    } else {
      return userData.displayName;
    }
  };

  // Merge timeline events and chat messages into one object type
  const events = [
    ...timelineResp.value.timeline.map((t) => ({
      eventType: t.eventType,
      userFullName: t.user !== undefined ? getUserFullName(t.user) : "System",
      timestamp: t.timestamp,
    })),
    ...chatResp.value.messages.map((m) => ({
      eventType: { tag: "Comment" } as const,
      message: m.contents,
      timestamp: m.time,
      userFullName: getUserFullName(m.author),
    })),
  ].sort(function (a, b) {
    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
  });

  return (
    <div data-testid="policy-timeline" className="flex-1 overflow-hidden flex flex-col">
      <ScrollableFeed className="flex-1 px-6 pt-4">
        {events.map((event, index): JSX.Element => {
          const eventType = event.eventType;
          switch (eventType.tag) {
            case "Comment":
              return <Comment key={index} {...event} />;
            case "EditCluster":
              return (
                <EditCluster
                  key={index}
                  eventType={eventType}
                  timestamp={event.timestamp}
                  userFullName={event.userFullName}
                />
              );
            case "PolicyCreatedEvent":
              return <PolicyCreatedEvent key={index} {...event} />;
            case "PolicyBoundEvent":
              return <PolicyBoundEvent key={index} {...event} />;
            case "PolicyNotTakenUpEvent":
              return <PolicyNotTakenUpEvent key={index} {...event} />;
            case "PolicyArchivedEvent":
              return <PolicyArchivedEvent key={index} {...event} />;
            case "QuoteCreatedEvent":
              return <QuoteCreatedEvent key={index} {...event} />;
            case "QuoteReferredEvent":
              return <QuoteReferredEvent key={index} {...event} />;
            case "QuoteNotTakenUpEvent":
              return <QuoteNotTakenUpEvent key={index} {...event} />;
            case "QuoteAcceptedEvent":
              return <QuoteAcceptedEvent key={index} {...event} />;
            case "QuoteDeclinedEvent":
              return <QuoteDeclinedEvent key={index} {...event} />;
            case "PolicyCancelledEvent":
              return <PolicyCancelledEvent key={index} {...event} />;
            case "EndorsementCreatedEvent":
              return <EndorsementCreateEvent key={index} {...event} />;
            default:
              assertNever(eventType);
          }
        })}
      </ScrollableFeed>
      <div className="space-y-2 px-6 py-4 shadow-sm border border-l-0 border-r-0 border-t-1">
        <textarea
          className="border border-gray-300 text-sm rounded w-full"
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              await submitComment();
            }
          }}
          rows={4}
          placeholder="Add your comment"
        />
        <Button
          variant="primary"
          disabled={loading || commentContent?.trim() === ""}
          onClick={submitComment}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export const TimelineSkeleton = () => {
  return (
    <div className="px-6 pt-4">
      <div className="mb-4 pb-4 border-b border-cool-gray-200 border-dashed">
        <div className="h-4 bg-gray-200 rounded animate-pulse w-1/2"></div>
      </div>
      <div className="mb-4 pb-4 border-b border-cool-gray-200 border-dashed">
        <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
      </div>
      <div className="mb-4 pb-4">
        <div className="h-4 bg-gray-200 rounded animate-pulse w-3/5"></div>
      </div>
    </div>
  );
};
