import embed from "vega-embed";
import React, { useRef, useEffect } from "react";
import { formatCurrency } from "../utils";

export type Value = { label: string; value: number };

const font =
  "Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
const fontSize = 14;
const fontWeight = 500 as const;
const textColor = "rgb(107,114,128)";

export const BarChart = (props: { values: Value[] }): JSX.Element => {
  const parentRef = useRef(null);

  const spec = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    width: "container" as const,
    height: {
      step: 40,
    },
    data: {
      values: props.values,
    },
    mark: {
      type: "bar" as const,
      tooltip: true,
    },
    encoding: {
      x: {
        field: "value",
        type: "quantitative" as const,
        axis: {
          title: "£",
          domainColor: "lightgray",
          tickMinStep: 1,
          tickWidth: 0,
        },
      },
      y: {
        field: "label",
        type: "nominal" as const,
        axis: {
          title: "",
          domain: false,
        },
      },
      color: {
        value: "#3B82F6",
      },
    },
    config: {
      axis: {
        labelFont: font,
        labelFontSize: fontSize,
        labelColor: textColor,
        labelFontWeight: fontWeight,
        titleFont: font,
        titleFontSize: fontSize,
        titleFontWeight: fontWeight,
        titleColor: textColor,
        titlePadding: 10,
      },
      view: {
        strokeWidth: 0,
      },
      bar: {
        size: 20,
      },
    },
    padding: 20,
  };

  useEffect(() => {
    if (parentRef.current === null) {
      return;
    }

    embed(parentRef.current, spec, {
      mode: "vega-lite",
      actions: false,
      tooltip: {
        formatTooltip: ({ label, value }) => `<b>${label}</b> ${formatCurrency(parseFloat(value))}`,
      },
    }).catch(console.warn);
  }, []);

  return <div className="w-full" ref={parentRef}></div>;
};
