import React, { Suspense, useState, useEffect } from "react";
import { prettyPrintTime } from "/src/utils/time";
import {
  cancelAsyncForProductPolicy,
  cancelAsyncForProductPolicyQuote,
  useGetCurrentUser,
  useTasksList,
} from "/src/dal/dal";
import { Loader } from "/src/design_system/Loader";
import { Pagination, Table, TBody, TD, TH, THead, TR } from "/src/design_system/Table";
import { TaskFilter, TaskStatus, TaskSummary } from "/src/internal_types";
import { Page, PageContent, PageHeader, PageTitle } from "/src/layout";
import { throwIfAppError } from "/src/utils/app_error";
import { encodeTaskId, generateTaskKey } from "/src/components/TaskProvider";
import { Badge, BadgeProps } from "/src/components/Badge";
import { Box } from "/src/design_system/Box";
import clsx from "clsx";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Menu, MenuButton, MenuItem, useMenuState } from "/src/design_system/Menu";
import { useHistory } from "react-router";
import { Routes } from "/src/routing/routes";
import { Button } from "/src/design_system/Button";
import { useParams } from "/src/routing/routing";
import { CanCancelSequelHubTask } from "../policy/permissions";
import { match, P } from "ts-pattern";
import { AsyncTaskState } from "/src/asyncs";
import { filterMap } from "/src/utils";
import {
  parseISO,
  isValid,
  intervalToDuration,
  differenceInMilliseconds,
  formatDuration,
  max,
} from "date-fns";

export const TaskList = (): JSX.Element => {
  return (
    <Page>
      <PageHeader>
        <PageTitle>Tasks</PageTitle>
      </PageHeader>
      <PageContent>
        <div
          data-testid="tasks-container"
          className={clsx("flex", "flex-col", "space-y-8", "px-8")}
        >
          <Suspense fallback={<TableLoader />}>
            <Box padding="none">
              <TaskTable
                title="In progress"
                emptyTableCaption="No tasks in progress"
                filter="in_progress"
                pageParam="inProgressPage"
              />
            </Box>
            <Box padding="none">
              <TaskTable
                title="Finished"
                emptyTableCaption="No finished tasks"
                filter="finished"
                pageParam="finishedPage"
              />
            </Box>
          </Suspense>
        </div>
      </PageContent>
    </Page>
  );
};

const TaskTable: typeof TaskTableContent = (props): JSX.Element => {
  return (
    <>
      <p className={clsx("text-base", "leading-6", "font-medium", "p-8")}>{props.title}</p>
      <Suspense
        fallback={
          <div className="min-w-full flex justify-center py-6">
            <Loader size="sm" />
          </div>
        }
      >
        <TaskTableContent {...props} />
      </Suspense>
    </>
  );
};

const TaskTableContent = (props: {
  title: string;
  emptyTableCaption: string;
  filter: TaskFilter;
  pageParam: "finishedPage" | "inProgressPage";
  pageCount?: number;
}) => {
  const {
    data: { query },
    setQuery,
  } = useParams(Routes.tasks);
  const currentPage = query[props.pageParam];
  const pageCount = props.pageCount ?? 50;
  const tasksResult = useTasksList({
    filter: props.filter,
    page: currentPage,
    pageCount,
  });
  const now = useNow();
  throwIfAppError(tasksResult);
  return (
    <div className="overflow-hidden">
      {tasksResult.value.tasks.length === 0 && (
        <div className={clsx("leading-6", "font-bold", "text-xl", "px-8", "pb-8")}>
          {props.emptyTableCaption}
        </div>
      )}
      {tasksResult.value.tasks.length > 0 && (
        <>
          <Table>
            <THead>
              <TR>
                <TH label="Id" />
                <TH label="Type" />
                <TH label="Scheduled" />
                <TH label="Name" />
                <TH label="Status" />
                <TH label="Running time" />
                <TH label="" />
              </TR>
            </THead>
            <TBody>
              {tasksResult.value.tasks.map((taskSummary) => {
                const labels = getTaskLabels(taskSummary);
                return (
                  <TR key={generateTaskKey(taskSummary)}>
                    <TD className="w-12 align-middle"> #{labels.id} </TD>
                    <TD className="w-80 align-middle"> {labels.type} </TD>
                    <TD className="w-80 align-middle"> {taskWhen(taskSummary)} </TD>
                    <TD className="w-80 align-middle"> {labels.name} </TD>
                    <TD className="w-64 align-middle">
                      <StatusBadge status={taskSummary.status} />
                    </TD>
                    <TD>{getRunningTime(taskSummary, now)}</TD>
                    <TD className="w-full flex justify-end">
                      <Actions taskSummary={taskSummary} />
                    </TD>
                  </TR>
                );
              })}
            </TBody>
          </Table>
          <Pagination
            currentPageNumber={currentPage}
            totalRowsCount={tasksResult.value.count}
            onPageChange={(newPage) => {
              const result: { [key in typeof props.pageParam]?: number } = {};
              result[props.pageParam] = newPage;
              setQuery(result);
            }}
            rowsPerPage={pageCount}
          />
        </>
      )}
    </div>
  );
};

const taskWhen = (taskSummary: TaskSummary) => {
  const dateTime = prettyPrintTime(taskSummary.task.scheduledAt);
  return (
    <>
      <div>
        {dateTime.date} {dateTime.time}
      </div>
    </>
  );
};

const Actions = React.forwardRef((props: { taskSummary: TaskSummary }, _ref) => {
  const menu = useMenuState({ gutter: 4 });
  const history = useHistory();
  const user = useGetCurrentUser();
  throwIfAppError(user);

  const parameters = props.taskSummary.task.parameters;
  const actions: { caption: string; onClick: () => void }[] = [
    {
      caption: "View details",
      onClick: () => {
        const [taskTag, taskId] = encodeTaskId(props.taskSummary.task.id);
        history.push(
          Routes.taskRuns.generatePath({
            capture: { taskId, taskTag },
          }),
        );
      },
    },
  ].concat(
    match(parameters)
      .with({ tag: "ExportPoliciesParameters" }, () => [])
      .with({ tag: "SequelHubParameters" }, (parameters) => {
        const asyncTaskStateTag = match<typeof props.taskSummary.status.tag, AsyncTaskState["tag"]>(
          props.taskSummary.status.tag,
        )
          .with("Failed", () => "Failed")
          // "NotStarted" task counts as running
          // from the perspective of a user writing the spec.
          // It's "NotStarted" from the perspective of task runner
          .with(P.union("Started", "NotStarted"), () => "Running")
          .with("Succeeded", () => "Completed")
          .exhaustive();
        const canCancelTask: boolean = CanCancelSequelHubTask({
          currentRole: user.value.role,
        }).check({
          tag: asyncTaskStateTag,
        });
        return filterMap(
          [
            canCancelTask
              ? {
                  caption: "Cancel",
                  onClick: () => {
                    if (parameters.contents.mquoteId === undefined) {
                      return cancelAsyncForProductPolicy(
                        parameters.contents.productId,
                        parameters.contents.policyId,
                        parameters.contents.key,
                      );
                    } else {
                      return cancelAsyncForProductPolicyQuote(
                        parameters.contents.productId,
                        parameters.contents.policyId,
                        parameters.contents.mquoteId,
                        parameters.contents.key,
                      );
                    }
                  },
                }
              : undefined,
          ],
          (action) => action,
        );
      })
      .with({ tag: "MrcExtractionParameters" }, () => [])
      .with({ tag: "ArchivePoliciesParameters" }, () => [])
      .exhaustive(),
  );
  return (
    <>
      {actions.length > 0 && (
        <>
          <MenuButton {...menu}>
            {(props) => (
              <Button {...props} variant="secondary">
                <DotsVerticalIcon className="w-4 h-4 -mx-0.5, BadgeProps" />
              </Button>
            )}
          </MenuButton>
          <Menu {...menu}>
            {actions.map((action) => (
              <MenuItem {...menu} onClick={action.onClick} key={action.caption}>
                {action.caption}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
});
const TableLoader = () => {
  return (
    <div className="bg-white flex flex-row h-full justify-center items-center overflow-hidden">
      <Loader />
    </div>
  );
};

export const getTaskLabels = (
  taskSummary: TaskSummary,
): { name: string; type: string; id: number } => {
  const parameters = taskSummary.task.parameters;
  const [_, taskId] = encodeTaskId(taskSummary.task.id);
  switch (parameters.tag) {
    case "SequelHubParameters":
      return {
        name: `${parameters.contents.key}`,
        type: "Sequel Hub request",
        id: taskId,
      };
    case "ExportPoliciesParameters": {
      const name =
        parameters.contents.productId === undefined
          ? "Export for all products"
          : `Export for product ${parameters.contents.productId}`;
      return {
        name,
        type: "Policies export",
        id: taskId,
      };
    }
    case "MrcExtractionParameters": {
      return {
        name: `MRC extraction for document ${parameters.contents.documentId}`,
        type: "MRC extraction",
        id: taskId,
      };
    }
    case "ArchivePoliciesParameters": {
      return {
        name: "Archiving of policies with no activity",
        type: "Policy archiving",
        id: taskId,
      };
    }
  }
};

const StatusBadge = (props: { status: TaskStatus }): JSX.Element => {
  const mapping: {
    [key in TaskStatus["tag"]]: { label: string; color: BadgeProps["color"]; withDot?: boolean };
  } = {
    NotStarted: { label: "Queued", color: "gray" },
    Started: { label: "In progress", color: "blue" },
    Failed: { label: "Failed", color: "red", withDot: true },
    Succeeded: { label: "Completed", color: "green" },
  };
  const values = mapping[props.status.tag];
  return <Badge label={values.label} color={values.color} important={values.withDot ?? false} />;
};

function useNow(): Date {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
}

class InvalidDate {
  constructor(private dateStr: string) {}
  toString() {
    return `Could not parse date ${this.dateStr}`;
  }
}

function parseDate(dateStr: string): Date {
  const date = parseISO(dateStr);
  if (!isValid(date)) {
    throw new InvalidDate(dateStr);
  }
  return date;
}

function formatDelta(started: Date, finished: Date): string {
  const ms = differenceInMilliseconds(finished, started);
  if (ms < 1000) {
    const unit = ms === 1 ? "millisecond" : "milliseconds";
    return `${ms} ${unit}`;
  }
  return formatDuration(intervalToDuration({ start: started, end: finished }));
}

function getRunningTime(taskSummary: TaskSummary, now: Date): string {
  try {
    const started = parseDate(taskSummary.task.scheduledAt);
    const status = taskSummary.status;
    if (status.tag === "Succeeded") {
      const finished = parseDate(status.contents.finishedAt);
      return formatDelta(started, finished);
    }
    if (status.tag === "Failed") {
      const finished = max(
        filterMap(taskSummary.finishedRuns, (run) =>
          run.progressStatus.tag === "Finished"
            ? parseDate(run.progressStatus.contents.finishedAt)
            : undefined,
        ),
      );
      return formatDelta(started, finished);
    }
    return formatDelta(started, now);
  } catch (e) {
    if (e instanceof InvalidDate) {
      console.error(e);
      return "?";
    }
    throw e;
  }
}
