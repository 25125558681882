import { Autocomplete } from "@mui/lab";
import React from "react";
import NumberFormat from "react-number-format";
import {
  ComparableNodeT_,
  generalEditorOrSummaryView,
  SelectableNodeT_,
  SingleNodeT_,
  useSingleNodeState,
  WithPrefixNodeT_,
} from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";
import { toNumber } from "/src/utils";

export interface NumberSuggestNodeT_
  extends SingleNodeT_<number>,
    ComparableNodeT_<number>,
    SelectableNodeT_<number>,
    WithPrefixNodeT_ {
  type: "number_suggest";
}

export const NumberSuggestNode = (props: { node: NumberSuggestNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(
    props.node,
    NumberSuggestNodeEditorView,
    NumberSuggestNodeSummaryView,
  );
};

export const NumberSuggestNodeEditorView = (props: { node: NumberSuggestNodeT_ }) => {
  const { node } = props;

  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = async () => {
    await node.commit(toNumber(localValue));
  };

  return (
    // <LeafNode node={node}>
    //   <div {...getComboboxProps()} className="mt-2 relative">
    //     <TextField
    //       {...getInputProps()}
    //       autoComplete="disabled"
    //       className="aui-input"
    //       disabled={node.readOnly}
    //       node={node}
    //     />
    //   </div>
    //   <div className="relative" style={{ maxWidth: 500 }}>
    //     <div {...getMenuProps()} className={styles.menuOuter}>
    //       {isOpen && inputItems.length > 0 && (
    //         <ul className={styles.menuInner}>
    //           {inputItems.map((item, index) => (
    //             <li
    //               key={`${item}${index}`}
    //               {...getItemProps({ item, index })}
    //               className={clsx(styles.menuItem, index === highlightedIndex && "bg-gray-100")}
    //             >
    //               {item}
    //             </li>
    //           ))}
    //         </ul>
    //       )}
    //     </div>
    //   </div>
    // </LeafNode>
    <LeafNode node={node}>
      <Autocomplete
        value={localValue}
        options={node.options.map((v) => {
          return `${node.prefix ?? ""}${v.toLocaleString()}`;
        })}
        multiple={false}
        onBlur={handleOnBlur}
        disabled={node.readOnly}
        onInputChange={(_evt, value) => {
          const v = (value ?? "")
            .replaceAll(",", "")
            .replace(node.prefix ?? "", "")
            .trim();
          setLocalValue(v);
        }}
        freeSolo={true}
        renderInput={(params) => {
          // TODO: NumberFormat<any> is ugly but equivalent to the type in react-number-format before
          // https://github.com/s-yadav/react-number-format/commit/1b6d2f39817cd2a8995980bec7a4caad1b6322b2
          const inputProps = params.inputProps as any;
          return (
            <div ref={params.InputProps.ref}>
              <NumberFormat<any>
                thousandSeparator={true}
                customInput={TextField}
                prefix={node.prefix}
                allowEmptyFormatting
                className={inputProps.className}
                autoComplete={inputProps.autoComplete}
                autoCapitalize={inputProps.autoCapitalize}
                disabled={inputProps.disabled}
                id={inputProps.id}
                onBlur={inputProps.onBlur}
                onChange={inputProps.onChange}
                onFocus={inputProps.onFocus}
                onMouseDown={inputProps.onMouseDown}
                getInputRef={(ref: HTMLInputElement) => {
                  inputProps.ref.current = ref;
                }}
                spellCheck={inputProps.spellCheck}
                value={inputProps.value}
                node={node}
              />
            </div>
          );
        }}
      />
    </LeafNode>
  );
};

const NumberSuggestNodeSummaryView = (props: { node: NumberSuggestNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[
        props.node.value !== null
          ? `${props.node.prefix ?? ""}${props.node.value.toLocaleString()}`
          : "",
      ]}
    />
  );
};
