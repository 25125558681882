import {
  QuoteId,
  ProductId,
  PolicyId,
  HowDefiniteAAsyncStatus,
  PromiseId,
} from "src/internal_types";
import React from "react";
import { Promise } from "../../components/Promise";
import { generalEditorOrSummaryView } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafNode } from "./base";
import { AsyncTaskState } from "../../asyncs";

export interface PromiseNodeT_ extends LeafNodeT_ {
  type: "promise";
  datapointId: string;
  prompt: string;
  taskState: AsyncTaskState;
  productId: ProductId;
  policyId: PolicyId;
  mquoteId?: QuoteId;
  asyncStatus: HowDefiniteAAsyncStatus;
  promiseId?: PromiseId;
}

export const PromiseNode = (props: { node: PromiseNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, PromiseNodeEditorView, PromiseNodeSummaryView);
};

const PromiseNodeEditorView = (props: { node: PromiseNodeT_ }): JSX.Element => {
  const { node } = props;

  return (
    <LeafNode node={node}>
      <Promise node={props.node} ident={dataPointToIdent(props.node.datapointId)} />
    </LeafNode>
  );
};

const PromiseNodeSummaryView = (props: { node: PromiseNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafNode label={props.node.label}>
      <Promise node={props.node} ident={dataPointToIdent(props.node.datapointId)} />
    </SummaryLeafNode>
  );
};

// Convert a 'datapointId' to an ident, i.e. strip off the () from foo().
export const dataPointToIdent = (string: string) => string.replace(/\(\)$/, "");
