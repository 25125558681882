import { useRef } from "react";

/**
 * A very simple hook to skip expensive taks on a field component's event handler, if the values haven't changed.
 *
 * Used with "uncontrolled" components
 * which manage their own state.
 * https://reactjs.org/docs/uncontrolled-components.html
 * @returns A pair of functions, where the first function
 * queries the "touched" state and sets it to "false" before returning,
 * and the second function sets the state to "touched".
 * Invoke the second when something changes,
 * invoke the first when deciding to perform some task depending on changes.
 */
export const useTouch: () => [() => boolean, () => void] = () => {
  const ref = useRef(false);
  return [
    () => {
      const touched = ref.current;
      ref.current = false;
      return touched;
    },
    () => {
      ref.current = true;
    },
  ];
};
