import {
  ArrowNarrowRightIcon,
  ArrowSmDownIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  SearchIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Button } from "/src/design_system/Button";
import {
  SovCandidateMatch,
  SovColumnMatches,
  SovTargetColumnId,
  SovExtractedTable,
  SovExtractedColumn,
  SovCandidateMatchId,
  SovSpecInfo,
} from "/src/internal_types";
import { useCancelOnUnmount } from "/src/utils";
import { SovTable } from "./format_cells";
import { dequal } from "dequal";

// This type is split so we can make unmapped columns untickable.
type MappedColumnState = UnmappedColumn | MappedColumn;

type UnmappedColumn = {
  tag: "Unmapped";
  sourceColumn: undefined;
  checked: undefined | false;
};

type MappedColumn = {
  tag: "Mapped";
  sourceColumn: SovCandidateMatchId;
  checked: undefined | boolean;
};

export type ColumnMappingPageState = {
  tab: { index: number; name: string };
  candidates: SovColumnMatches[];
  /**
   * The keys correspond to the 'id' field of type 'SovColumnMatches'.
   */
  mapped: Map<SovTargetColumnId, MappedColumnState>;
  isExtractingTable: boolean;
};

export const ColumnMappingPage = ({
  specInfo,
  pageState,
  setPageState,
  moveToNextPage,
  getExtractedTable,
}: {
  specInfo: SovSpecInfo;
  pageState: ColumnMappingPageState;
  setPageState: (state: ColumnMappingPageState) => void;
  moveToNextPage: (tableData: SovTable) => void;
  getExtractedTable: GetExtractedTable;
}): JSX.Element => {
  const [activeRow, setActiveRow] = useState<number | undefined>(undefined);
  const data = pageState.candidates;
  const numColumns = data.length;
  let numImporting = 0;
  let numIgnoring = 0;
  pageState.mapped.forEach((state) => {
    if (state.checked === true) {
      numImporting += 1;
    } else if (state.checked === false) {
      numIgnoring += 1;
    }
  });
  const numPending: number = numColumns - numImporting - numIgnoring;

  const abortController = useCancelOnUnmount();
  return (
    <>
      <div className="flex flex-row items-center gap-6">
        <h1 className="text-gray-900 text-xl leading-7 font-bold grow">Map column headers</h1>
        <div className="flex flex-col gap-1.5 shrink items-end">
          <div className="text-xs/leading-4/font-medium text-gray-500">
            {numColumns} Columns ({numImporting} importing, {numIgnoring} ignoring, {numPending}{" "}
            pending)
          </div>
          <ProgressBar value={numImporting + numIgnoring} total={numColumns} />
        </div>

        <Button
          disabled={numPending !== 0}
          isPending={pageState.isExtractingTable}
          onClick={async () => {
            setPageState({
              ...pageState,
              isExtractingTable: true,
            });
            const extractionResult = await getExtractedTable(pageState, abortController);
            if (extractionResult === null) {
              setPageState({
                ...pageState,
                isExtractingTable: false,
              });
            } else {
              moveToNextPage(extractedTableToSovTable(specInfo, extractionResult));
            }
          }}
        >
          Import columns
        </Button>
      </div>
      <div className="flex flex-row border border-gray-200 bg-gray-100 rounded-md overflow-x-hidden mt-6 mb-5">
        <LeftSide
          data={data}
          activeRow={activeRow}
          setActiveRow={setActiveRow}
          pageState={pageState.mapped}
          setMappedColumn={(targetColumn, state) => {
            const newMap = new Map(pageState.mapped);
            if (state === undefined) {
              newMap.delete(targetColumn);
            } else {
              newMap.set(targetColumn, state);
            }
            setPageState({ ...pageState, mapped: newMap });
          }}
        />
        <RightSide
          tab={pageState.tab}
          columnMatches={activeRow !== undefined ? data[activeRow] : undefined}
          mappedColumn={
            activeRow !== undefined ? pageState.mapped.get(data[activeRow].id) : undefined
          }
        />
      </div>
    </>
  );
};

const ProgressBar = (props: { value: number; total: number }): JSX.Element => {
  const percentage = Math.min(100, Math.round((props.value / props.total) * 100));
  return (
    <div className="w-60 h-2 bg-blue-100 rounded-lg">
      <div className="h-full bg-blue-500 rounded-lg" style={{ width: percentage + "%" }} />
    </div>
  );
};

const LeftSide = (props: {
  data: SovColumnMatches[];
  activeRow: undefined | number;
  setActiveRow: (row: number) => void;
  pageState: Map<SovTargetColumnId, MappedColumnState>;
  setMappedColumn: (targetColumn: SovTargetColumnId, state: MappedColumnState) => void;
}): JSX.Element => {
  return (
    <div className="overflow-y-scroll flex flex-col relative" style={{ width: "55%" }}>
      {/* This div constructs the dividing border between the two halves.
          We do this instead of using a normal CSS border, because we need the
          active row triangle to float above the divider. But overflow rules mean
          it can't, so we artificially construct this border.
      */}
      <div className="h-full absolute right-0 w-px bg-gray-200" />

      <div className="p-6 gap-4 flex flex-row">
        <h4 className="text-sm leading-4 font-medium text-gray-700">
          Match our target fields to your spreadsheet
        </h4>
      </div>

      <div className="border-b border-gray-200 px-5 pb-5 gap-4 flex flex-row">
        <h5 className="w-5/12 text-gray-900 text-sm leading-4 font-medium flex flex-row gap-2 grow">
          <ArrowSmDownIcon className="w-4 h-4 text-gray-500" />
          Target fields
        </h5>
        <h5 className="w-7/12 text-gray-900 text-sm leading-4 font-medium flex flex-row gap-2 grow">
          <ArrowSmDownIcon className="w-4 h-4 text-gray-500" />
          Your spreadsheet
        </h5>
      </div>

      <div className="flex flex-col gap-3 p-4 pr-6 overflow-y-scroll overflow-x-hidden min-w-min">
        {props.data.map((columnMatches, i) => {
          let mappedColumn = props.pageState.get(columnMatches.id);
          mappedColumn =
            mappedColumn !== undefined
              ? mappedColumn
              : { tag: "Unmapped", sourceColumn: undefined, checked: undefined };
          return (
            <TargetFieldRow
              key={columnMatches.id}
              columnMatches={columnMatches}
              selected={i === props.activeRow}
              mappedColumn={mappedColumn}
              setActiveRow={() => props.setActiveRow(i)}
              setMappedColumn={(state) => props.setMappedColumn(columnMatches.id, state)}
            />
          );
        })}
      </div>
    </div>
  );
};

export const useElementWidth = (ref: MutableRefObject<HTMLElement | null>): number => {
  const [width, setWidth] = useState(0);
  const setWidthFromRef = () => {
    if (ref.current !== null) {
      setWidth(ref.current.offsetWidth);
    }
  };
  useEffect(() => {
    setWidthFromRef();
    window.addEventListener("resize", setWidthFromRef);
    return () => {
      window.removeEventListener("resize", setWidthFromRef);
    };
  }, [ref]);
  return width;
};

const TargetFieldRow = (props: {
  columnMatches: SovColumnMatches;
  selected: boolean;
  setActiveRow: () => void;
  mappedColumn: MappedColumnState;
  setMappedColumn: (column: MappedColumnState) => void;
}): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  // This seems to be the only way to make Popover.Content match the parent
  // width
  const popoverRootRef = useRef(null);
  const popoverWidth = useElementWidth(popoverRootRef);

  const [searchString, setSearchString] = useState("");
  const matchesSearchString = (match: SovCandidateMatch): boolean => {
    return match.columnName.toLowerCase().includes(searchString.trim().toLowerCase());
  };
  return (
    <div
      onClick={() => {
        props.setActiveRow();
      }}
      className={clsx([
        "shadow-sm rounded-md px-4 py-2 flex flex-row grow relative min-w-min",
        props.selected && "ring-2 ring-blue-500 ring-offset-0",
        props.mappedColumn.checked === false ? "bg-white/50" : "bg-white",
        props.mappedColumn.checked === true && "text-gray-900",
        props.mappedColumn.checked === false && "text-gray-400",
        props.mappedColumn.checked === undefined && "text-gray-700",
      ])}
    >
      {/* This peculiar contraption is the arrow pointing at the active row.
          The arrow itself is the right hand border, which makes a triangle
          because the contents are 0px in size.
      */}
      <div
        className={clsx(["absolute top-1/2", !props.selected && "hidden"])}
        style={{
          right: "-35px",
          width: "26px",
          height: "26px",
          border: "13px solid transparent",
          borderLeftWidth: "0",
          borderRightWidth: "26px",
          borderRightColor: "white",
          transform: "translateY(-13px)",
        }}
      />
      <div className="w-5/12 flex flex-row items-center min-w-min">
        <div className={clsx(["grow", props.selected && "text-blue-700"])}>
          {props.columnMatches.displayName}
        </div>
        <ArrowNarrowRightIcon
          className={clsx(["w-5 h-5 mx-5", props.selected ? "text-blue-700" : "text-gray-400"])}
        />
      </div>
      <div ref={popoverRootRef} className="w-7/12 flex flex-row items-center gap-3 min-w-min">
        <Popover.Root open={isOpen} onOpenChange={setOpen}>
          <Popover.Trigger className="w-full border border-gray-300 shadow-sm px-4 py-2 rounded-md flex">
            {(() => {
              const match = props.columnMatches.candidateMatches.find((candidate) =>
                dequal(candidate.candidateMatchId, props.mappedColumn.sourceColumn),
              );
              if (match === undefined) {
                return (
                  <p
                    className={clsx([
                      "text-sm leading-5 font-medium grow text-left",
                      props.mappedColumn.checked !== false && "text-gray-500",
                    ])}
                  >
                    Select
                  </p>
                );
              } else {
                return (
                  <p className="text-sm leading-5 font-medium grow text-left">{match.columnName}</p>
                );
              }
            })()}
            <ChevronDownIcon className="w-5 h-5 text-gray-700" />
          </Popover.Trigger>
          <Popover.Content
            className="mt-1 bg-white z-index-1 flex flex-col ring-1 ring-black ring-opacity-5 shadow-lg rounded-lg overflow-hidden max-h-60"
            style={{ width: popoverWidth + 1 + "px", marginLeft: "-1px" }}
          >
            <SearchBox onChange={setSearchString} />
            <div className="overflow-scroll">
              {props.columnMatches.candidateMatches
                .filter((x) => matchesSearchString(x))
                .map((candidate) => {
                  return (
                    <div
                      key={candidateIdToKey(candidate.candidateMatchId)}
                      className="flex flex-row items-baseline font-normal px-3 py-2 gap-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        props.setMappedColumn({
                          tag: "Mapped",
                          sourceColumn: candidate.candidateMatchId,
                          checked: undefined,
                        });
                        setOpen(false);
                      }}
                    >
                      <div className="text-gray-700 text-sm leading-5 grow">
                        {candidate.columnName}
                      </div>
                      <div className="text-gray-500 text-xs leading-none whitespace-nowrap">
                        {Math.round(candidate.matchScore * 100)}% Match
                      </div>
                    </div>
                  );
                })}
            </div>
          </Popover.Content>
        </Popover.Root>
        <CheckCircleIcon
          className={clsx([
            "shrink-0 w-7 h-7 px-1 -mx-1 text-gray-400 hover:text-gray-500",
            props.mappedColumn.checked === true && "text-green-500 hover:text-green-500",
            props.mappedColumn.tag === "Mapped" ? "cursor-pointer" : "cursor-not-allowed",
          ])}
          onClick={() => {
            // Only mapped columns can be checked
            if (props.mappedColumn.tag === "Mapped") {
              props.setMappedColumn({
                ...props.mappedColumn,
                checked: true,
              });
            }
          }}
        />
        <XCircleIcon
          className={clsx([
            "shrink-0 w-7 h-7 px-1 -mx-1 text-gray-400 cursor-pointer hover:text-gray-500",
            props.mappedColumn?.checked === false && "text-red-500 hover:text-red-500",
          ])}
          onClick={() => {
            props.setMappedColumn({
              ...props.mappedColumn,
              checked: false,
            });
          }}
        />
      </div>
    </div>
  );
};

const SearchBox = (props: { onChange: (v: string) => void }): JSX.Element => {
  return (
    <div className="text-gray-500 relative rounded-md shadow-sm mx-3 my-2">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5" />
      </div>
      <input
        type="text"
        className="block w-full pl-10 border border-gray-300 rounded-md text-sm"
        placeholder="Search"
        autoFocus={false}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};

const MiniCard = (props: { header: string; children?: React.ReactNode }): JSX.Element => {
  return (
    <div className="flex flex-col gap-1 flex-1 relative">
      <h4 className="text-gray-500 text-sm leading-5 font-medium">{props.header}</h4>
      <p className="text-sm leading-5 font-normal">{props.children}</p>
    </div>
  );
};

const RightSide = (props: {
  tab: { index: number; name: string };
  columnMatches: SovColumnMatches | undefined;
  mappedColumn: MappedColumnState | undefined;
}): JSX.Element => {
  const EmptyState = () => {
    return (
      <p className="text-gray-500 text-sm leading-5 font-normal">
        Select a field to preview its contents
      </p>
    );
  };
  const candidate: SovCandidateMatch | undefined = props.columnMatches?.candidateMatches.find(
    (candidate) => dequal(candidate.candidateMatchId, props.mappedColumn?.sourceColumn),
  );
  return (
    <div className="bg-white p-6" style={{ width: "45%" }}>
      {props.columnMatches === undefined ? (
        <EmptyState />
      ) : (
        <>
          <h3 className="text-lg leading-6 font-medium mb-4">Mapping</h3>
          <div className="flex flex-row">
            <div className="flex-1 flex flex-row">
              <MiniCard header="Your field">
                {candidate === undefined ? (
                  <span className="text-gray-500">Please select</span>
                ) : (
                  <span className="text-gray-900">{candidate.columnName}</span>
                )}
              </MiniCard>
              <div className="flex flex-col justify-center items-center grow">
                <ArrowNarrowRightIcon className="w-5 h-5 text-gray-500" />
              </div>
            </div>
            <MiniCard header="Will be mapped to">
              <span className="text-blue-700">{props.columnMatches.displayName}</span>
            </MiniCard>
          </div>

          {candidate !== undefined && (
            <>
              <h3 className="text-lg leading-6 font-medium mt-8 mb-4">Data preview</h3>
              <div className="border border-gray-200 divide-y text-sm leading-5 font-normal text-gray-700">
                {candidate.previewData.map((jsonValue, i) => {
                  return (
                    <div key={i} className="p-2 pl-3">
                      {jsonValue as string}
                    </div>
                  );
                })}
              </div>

              <h3 className="text-lg leading-6 font-medium mt-8 mb-4">Data attributes</h3>
              <div className="grid grid-rows-2 grid-flow-col gap-6">
                <MiniCard header="Spreadsheet location">
                  <span className="text-gray-900">
                    {/* 1-indexed for humans */}
                    Tab {props.tab.index + 1} - {props.tab.name}
                  </span>
                </MiniCard>
                <MiniCard header="Missing data %">
                  <span className="text-green-600">
                    {Math.round(100 * (1 - candidate.completeness))}%
                  </span>
                </MiniCard>
                <MiniCard header="Column match confidence">
                  <span className="text-green-600">{Math.round(100 * candidate.matchScore)}%</span>
                </MiniCard>
                <MiniCard header="Content type match %">
                  <span className="text-green-600">{Math.round(100 * candidate.typeMatch)}%</span>
                </MiniCard>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export type GetExtractedTable = (
  { mapped }: ColumnMappingPageState,
  abortController: AbortController,
) => Promise<SovExtractedTable | null>;

const extractedTableToSovTable = (
  specInfo: SovSpecInfo,
  extractedTable: SovExtractedTable,
): SovTable => {
  const columns = extractedTable.extractedColumns;
  const table: SovTable = [];
  columns.forEach((column: SovExtractedColumn) => {
    const datapointInfo = specInfo[column.id];
    if (datapointInfo !== undefined) {
      table.push({
        datapointId: column.id,
        prettyName: datapointInfo.prettyName,
        targetType: datapointInfo.typ,
        values: column.values,
      });
    }
  });
  return table;
};

export const preselectMostLikelyCandidates = (
  columns: SovColumnMatches[],
): Map<SovTargetColumnId, MappedColumnState> => {
  const resultEntries: [SovTargetColumnId, MappedColumnState][] = columns.map(
    ({ candidateMatches, id }) => {
      if (candidateMatches.length === 0) {
        return [id, { tag: "Unmapped", sourceColumn: undefined, checked: undefined }];
      } else {
        const byMatchScoreDescending = (a: SovCandidateMatch, b: SovCandidateMatch): number =>
          b.matchScore - a.matchScore;
        const bestMatch: SovCandidateMatch = [...candidateMatches].sort(byMatchScoreDescending)[0];
        return [
          id,
          { tag: "Mapped", sourceColumn: bestMatch.candidateMatchId, checked: undefined },
        ];
      }
    },
  );
  return new Map(resultEntries);
};

const candidateIdToKey = ({ sheetIndex, columnIndex }: SovCandidateMatchId): string => {
  return `${sheetIndex}/${columnIndex}`;
};
