import React, { useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../../banner";
import Input from "../../input";
import { Button } from "../../design_system/Button";
import Layout from "./Layout";
import { PublicRoutes } from "../../routing/routes";
import { ChevronLeftIcon } from "@heroicons/react/outline";

export interface IFormValues {
  email: string;
}

const ForgotPassword = () => {
  const [areInstructionsSent, setInstructionsSent] = useState(false);

  const [formValues, setFormValues] = useState<IFormValues>({
    email: "",
  });

  const [formError, setFormError] = useState<string | null>();
  const [isLoading] = useState(false);
  const isValid = formValues.email.trim().length > 0;
  const canSubmit = !isLoading && isValid;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await aws_amplify.Auth.forgotPassword(formValues.email.trim());

      setInstructionsSent(true);
    } catch (error) {
      if (error instanceof Error) {
        setFormError(error.message);
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

  return (
    <Layout>
      {!areInstructionsSent && (
        <>
          <Link
            to={PublicRoutes.login.generatePath({})}
            className="border-2 text-xs rounded inline-flex items-center px-2 py-1 text-gray-800 mb-5"
          >
            <ChevronLeftIcon className="w-3 h-4 mr-1 stroke-2 text-gray-800" />
            Back
          </Link>

          <h3 className="mb-4 font-medium text-xl">Forgot Password</h3>

          <p className="mb-6 text-gray-700">
            Enter the email address you used when your account was created and we'll send you
            instructions on how to reset your password.
          </p>

          {formError != null && <Banner className="mb-6" variant="error" label={formError} />}

          <form onSubmit={onSubmit}>
            <Input
              className="mb-2"
              label="Email address"
              type="email"
              name="email"
              onChange={onChange}
              value={formValues.email}
            />

            <Button type="submit" fullWidth disabled={!canSubmit}>
              Send reset instructions
            </Button>
          </form>
        </>
      )}

      {areInstructionsSent && (
        <>
          <h3 className="mb-4 font-medium text-xl">Instructions sent</h3>

          <p className="mb-4">
            Instructions to reset your password have been sent to you. Please check your inbox.
          </p>

          <p className="mb-6">You may now close this window.</p>

          <div className="flex">
            <Link
              className="text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 px-4 py-2 rounded w-full text-center"
              to={PublicRoutes.login.generatePath({})}
            >
              Return to login
            </Link>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ForgotPassword;
