import { QueryClient, useQuery, UseQueryOptions } from "react-query";
import { SPECIAL_DATAPOINTS } from "../data_editor/constants";
import {
  BindingDates,
  FileInfo,
  PoliciesSortOn,
  PoliciesSortOrder,
  PolicyDerivedStatusFilter,
  PolicyId,
  Product,
  ProductId,
  QuoteDerivedStatusFilter,
  QuoteId,
  Ident,
  Role,
  Transaction,
  UserId,
  ProductVersion,
  AggregationTrigger,
  MrcDocumentId,
  MrcExtractionId,
  MrcCandidateId,
  MrcResolvedExtraction,
  MrcDocumentInfo,
  MrcCandidate,
  Scalar,
  MrcDocumentSummary,
  MrcProductAdapter,
  MrcAdapter,
  MrcAdapterId,
  Products,
  MrcTaskStatus,
  MrcExtractionTaskId,
  MrcUploadResult,
  TaskId,
  SovExecutionStatus,
  SovColumnMatches,
  SovColumnMappings,
  SovExtractedTable,
  SovProductAdapter,
  SovAdapter,
  SovSpecInfo,
  SovSelectedTabs,
  SovAvailableTabs,
  SovSetAdapter,
  SetupMrcAdapterOptions,
  MrcCheckedState,
  SovExtractionId,
  BeginSovExtractionResponse,
  SetupSovAdapterOptions,
  SovExtraction,
} from "../internal_types";
import * as apiTypes from "/src/api_types";
import { fetch } from "./fetch";
import {
  GetJsonPaths,
  ExtractParams,
  ExtractQuery,
  GetResponseFor,
  OrAppError,
  apiGet,
  apiDelete,
  apiPatch,
  apiPost,
  apiPut,
  PatchResponseFor,
} from "./http";

// Re-export useful types and procedures from http module
export { type OrAppError, apiGet, apiDelete, apiPatch, apiPost, apiPut } from "./http";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

//@ts-expect-error set query client on window
window.queryClient = queryClient;

// Hook helper

export function invalidatePath<T extends GetJsonPaths>(path: T): Promise<void> {
  return queryClient.invalidateQueries(path);
}

// Should be called by actions whose side-effects invalidate the timeline.
export function invalidateTimeline(productId: ProductId, policyId: PolicyId): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/policy/{policyId}/timeline", {
      params: { productId, policyId },
      query: {},
    }),
  );
}

// TODO(hm): consider using the path as the query key + params and query after, as opposed
// to substituting the params in the path. It might make it easier to do cache invalidation.
// not sure though.
export function getQueryKey<T extends GetJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["get"]>;
    query: ExtractQuery<apiTypes.paths[T]["get"]>;
  },
) {
  return [path, options];
}

export function useApiGet<T extends GetJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["get"]>;
    query: ExtractQuery<apiTypes.paths[T]["get"]>;
  },
  queryOptions?: UseQueryOptions<GetResponseFor<T>, unknown, GetResponseFor<T>>,
  customCb?: (v: () => Promise<GetResponseFor<T>>) => Promise<GetResponseFor<T>>,
  // used for conditional queries, see useGetSchema for an example of that
  // somehow "enabled: false" did not work for queryOptions
): GetResponseFor<T> {
  const { data } = useQuery(
    getQueryKey(path, options),
    () => {
      if (customCb !== undefined) {
        return customCb(() => apiGet(path, options));
      }
      return apiGet(path, options);
    },
    queryOptions,
  );
  return data as Exclude<typeof data, undefined>;
}

//
// API binding for getting users from Identity
// =================================================

//
// Brossa API bindings
// =============================

export const getConfig = () => apiGet("/config", { params: {}, query: {} });

// Tasks list API

// Returns UnifiedTaskSummary
export const useTasksList = (
  params: apiTypes.paths["/api/tasks/list"]["get"]["parameters"]["query"],
  refetchInterval?: number, // in seconds
) =>
  useApiGet(
    "/api/tasks/list",
    {
      params: {},
      query: params,
    },
    { refetchInterval },
  );

// Async API

export const useAsyncsForProductPolicy = (productId: ProductId, policyId: PolicyId) =>
  useApiGet(
    "/api/product/{productId}/policy/{policyId}/async/tasks",
    {
      params: {
        productId,
        policyId,
      },
      query: {},
    },
    { refetchInterval: 7000 },
  );

export const startAsyncForProductPolicy = (
  productId: ProductId,
  policyId: PolicyId,
  ident: Ident,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/async/start", {
    params: {
      productId,
      policyId,
    },
    query: {},
    body: { ident },
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/async/tasks", {
        params: {
          productId,
          policyId,
        },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/tasks/list", {
        params: {},
        query: {},
      }),
    );
    return r;
  });

export const startAsyncForProductPolicyQuote = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  ident: Ident,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/async/start", {
    params: {
      productId,
      policyId,
      quoteId,
    },
    query: {},
    body: { ident },
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/async/tasks", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/tasks/list", {
        params: {},
        query: {},
      }),
    );
    return r;
  });

export const cancelAsyncForProductPolicy = (
  productId: ProductId,
  policyId: PolicyId,
  ident: Ident,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/async/cancel", {
    params: {
      productId,
      policyId,
    },
    query: {},
    body: { ident },
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/async/tasks", {
        params: {
          productId,
          policyId,
        },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/tasks/list", {
        params: {},
        query: {},
      }),
    );
    return r;
  });

export const cancelAsyncForProductPolicyQuote = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  ident: Ident,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/async/cancel", {
    params: {
      productId,
      policyId,
      quoteId,
    },
    query: {},
    body: { ident },
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/async/tasks", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/tasks/list", {
        params: {},
        query: {},
      }),
    );
    return r;
  });

// Product API

export const newProductPending = (
  name: string,
  sourceTextFiles: { name: string; contents: string }[],
) =>
  apiPost("/api/product/pending", {
    params: {},
    query: {},
    body: {
      name,
      sourceTextFiles: sourceTextFiles.map((file) => ({
        fileName: file.name,
        fileContents: file.contents,
      })),
    },
  });

export const setProductPending = (
  pendingProductId: number,
  name: string,
  sourceTextFiles: { name: string; contents: string }[],
) =>
  apiPost("/api/product/pending/{pendingProductId}", {
    params: { pendingProductId },
    query: {},
    body: {
      name,
      sourceTextFiles: sourceTextFiles.map((file) => ({
        fileName: file.name,
        fileContents: file.contents,
      })),
    },
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/pending-product/editable/{pendingProductId}", {
        params: { pendingProductId },
        query: {},
      }),
    );
    return r;
  });

export const publishPendingProduct = (pendingProductId: number) =>
  apiPost("/api/product/pending/publish/{pendingProductId}", {
    params: { pendingProductId },
    query: {},
    body: {},
  });

export const disposePendingProduct = (pendingProductId: number) =>
  apiDelete("/api/product/pending/dispose/{pendingProductId}", {
    params: { pendingProductId },
    query: {},
  });

export const useGetEditableProduct = (
  productId: ProductId,
  version?: ProductVersion,
): OrAppError<apiTypes.components["schemas"]["EditableProduct"]> =>
  useApiGet("/api/product/editable/{productId}", {
    params: { productId },
    query: { version },
  });

export const useGetPendingProducts = (): OrAppError<
  apiTypes.components["schemas"]["PendingProducts"]
> =>
  useApiGet("/api/product/pending-product", {
    params: {},
    query: {},
  });

export const useGetPendingProduct = (
  pendingProductId: number,
): OrAppError<apiTypes.components["schemas"]["PendingProduct"]> =>
  useApiGet("/api/product/pending-product/{pendingProductId}", {
    params: { pendingProductId },
    query: {},
  });

export const useGetPendingProductEditable = (
  pendingProductId: number,
): OrAppError<apiTypes.components["schemas"]["EditableProduct"]> =>
  useApiGet("/api/product/pending-product/editable/{pendingProductId}", {
    params: { pendingProductId },
    query: {},
  });

// PolicyAPI

export const newPolicy = (productId: ProductId) =>
  apiPost("/api/product/{productId}/policy", {
    params: { productId },
    query: {},
    body: {},
  });

export const getPolicy = (productId: ProductId, policyId: PolicyId) =>
  apiGet("/api/product/{productId}/policy/{policyId}", {
    params: { productId, policyId },
    query: {},
  });

export type GetPoliciesArgs = {
  productId?: ProductId;
  page: number | undefined;
  pageCount: number;
  policyStatuses?: Array<PolicyDerivedStatusFilter> | undefined;
  quoteStatuses?: Array<QuoteDerivedStatusFilter> | undefined;
  query?: string | undefined;
  assignees?: Array<UserId> | undefined;
  additionalFields?: Array<string> | undefined;
  sortOn?: PoliciesSortOn;
  sortOrder?: PoliciesSortOrder;
  customSortOn?: string | undefined;
};

const listToQueryParam = <A>(l: Array<A>): string => {
  return `[${l.map((x) => `"${x}"`).join(",")}]`;
};

/**
 * See also {@link transactPolicySov}.
 */
export const transactPolicy = (
  productId: ProductId,
  policyId: PolicyId,
  transaction: Transaction,
) =>
  apiPatch("/api/product/{productId}/policy/{policyId}", {
    params: { productId, policyId },
    query: {},
    body: transaction,
  }).then((r) => {
    queryClient.setQueryData(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
      r,
    );
    return r;
  });

export const deletePolicy = (productId: ProductId, policyId: PolicyId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/delete", {
    params: { productId, policyId },
    query: {},
    body: {},
  }).then(async (response) => {
    await invalidatePath("/api/product/policies");
    return response;
  });

export const archivePolicy = (productId: ProductId, policyId: PolicyId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/archive", {
    params: { productId, policyId },
    query: {},
    body: {},
  }).then(async (response) => {
    queryClient.setQueryData(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
      response,
    );
    await invalidatePath("/api/product/policies");
    return response;
  });

export const setPolicyAssignee = async (
  productId: ProductId,
  policyId: PolicyId,
  assigneeId: UserId,
) => {
  await apiPut("/api/product/{productId}/policy/{policyId}/assignee", {
    params: { productId, policyId },
    query: {},
    body: JSON.stringify(assigneeId),
  }).then(() =>
    queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
    ),
  );
};

export const cancelPolicy = (productId: ProductId, policyId: PolicyId, cancellationDate: string) =>
  apiPost("/api/product/{productId}/policy/{policyId}/cancel", {
    params: { productId, policyId },
    query: {},
    body: { cancellationDate },
  });

export const clonePolicy = (productId: ProductId, policyId: PolicyId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/clone", {
    params: { productId, policyId },
    query: {},
    body: {},
  });

export const postChat = (
  productId: ProductId,
  policyId: PolicyId,
  message: string,
  quoteId?: QuoteId,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/chat", {
    params: { productId, policyId },
    query: quoteId != undefined ? { quoteId: quoteId } : {},
    body: { message },
  }).then(async (r) => {
    await invalidatePath("/api/product/{productId}/policy/{policyId}/chat");
    return r;
  });

export const getFirstChatMessage = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  userId: UserId,
  time: string,
) =>
  apiGet("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/chat/{userId}/{time}", {
    params: {
      productId,
      policyId,
      quoteId,
      userId,
      time,
    },
    query: {},
  });

// Quote API

export const newQuote = (productId: ProductId, policyId: PolicyId, bindingDates: BindingDates) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote", {
    params: { productId, policyId },
    query: { indicative: "Real" },
    body: bindingDates,
  }).then(async (r) => {
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote");
    await invalidateTimeline(productId, policyId);
    return r;
  });

const getQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiGet("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
    params: { productId, policyId, quoteId },
    query: {},
  });

export const bindQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/bind", {
    params: { productId, policyId, quoteId },
    query: {},
    body: {},
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote", {
        params: { productId, policyId },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await invalidateTimeline(productId, policyId);
    return r;
  });

export const notTakeUpQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiDelete("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
    params: { productId, policyId, quoteId },
    query: {},
  }).then(async (x) => {
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote");
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote/{quoteId}");
    await invalidateTimeline(productId, policyId);
    return x;
  });

export const undeclineQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiPatch("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/undecline", {
    params: { productId, policyId, quoteId },
    query: {},
    body: {},
  }).then(async (x) => {
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote");
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote/{quoteId}");
    await invalidateTimeline(productId, policyId);
    return x;
  });

export const setQuoteDates = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  dates: BindingDates,
) =>
  apiPut("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/set_dates", {
    params: { productId, policyId, quoteId },
    query: {},
    body: dates,
  }).then(async (_q) => {
    await queryClient.invalidateQueries("/api/product/{productId}/policy/{policyId}/quote");
    await queryClient.invalidateQueries(
      "/api/product/{productId}/policy/{policyId}/quote/{quoteId}",
    );
    await invalidateTimeline(productId, policyId);
  });

export const submitQuote = async (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/submit", {
    params: { productId, policyId, quoteId },
    query: {},
    body: {},
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote", {
        params: { productId, policyId },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await invalidateTimeline(productId, policyId);
    return r;
  });

export const transactQuote = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  transaction: Transaction,
) =>
  apiPatch("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
    params: { productId, policyId, quoteId },
    query: {},
    body: transaction,
  }).then((r) => {
    queryClient.setQueryData(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
      r,
    );
    return r;
  });

export const acceptQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/refer/accept", {
    params: { productId, policyId, quoteId },
    query: {},
    body: {},
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote", {
        params: { productId, policyId },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await invalidateTimeline(productId, policyId);
    return r;
  });

export const setQuoteReviewer = async (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  role: Role,
) =>
  apiPut("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/reviewer", {
    params: { productId, policyId, quoteId },
    query: {},
    body: { role },
  }).then(async () => {
    const q = await getQuote(productId, policyId, quoteId);
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote");
    await invalidatePath("/api/product/{productId}/policy/{policyId}/quote/{quoteId}");
    return q;
  });

export const rejectQuote = async (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) =>
  apiPost("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/refer/decline", {
    params: { productId, policyId, quoteId },
    query: {},
    body: {},
  }).then(async (r) => {
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote", {
        params: { productId, policyId },
        query: {},
      }),
    );
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: {
          productId,
          policyId,
          quoteId,
        },
        query: {},
      }),
    );
    await invalidateTimeline(productId, policyId);
    return r;
  });

// File API

export const uploadFile = (
  file: File,
): Promise<{ status: 200; result: FileInfo } | { status: 409 }> =>
  fetch
    .url(`/file`)
    .formData({ file })
    .post()
    .error(409, (_err) => {
      return { status: 409 };
    })
    .json((body) => ({
      status: 200,
      result: body as FileInfo,
    }));

export const fileDownloadPath = (fileId: string): string => `/api/file/${fileId}/download`;

export const getFile = (dataId: string) => fetch.url(`/file/${dataId}/download`).get().blob();

export const pdfPreviewByDatapoint = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId | undefined,
  datapointId: string,
) =>
  fetch
    .url(
      `/product/${productId}/policy/${policyId}/pdf_preview?${
        quoteId !== undefined ? `quoteId=${quoteId}&` : ""
      }datapointId=${datapointId}`,
    )
    .get()
    .blob();

export const useCheckMultiDocExists = (productId: ProductId, policyId: PolicyId) => {
  const query = `short_explain({
            ${SPECIAL_DATAPOINTS.MULTI_DOC_HEADER},
            ${SPECIAL_DATAPOINTS.MULTI_DOC_BODY},
            ${SPECIAL_DATAPOINTS.MULTI_DOC_FOOTER}
          })`;
  const queryKey = getQueryKey("/api/product/{productId}/policy/{policyId}/query", {
    params: {
      productId,
      policyId,
    },
    query: {
      query,
    },
  });
  // a special query key to avoid overlapping with query key for /query endpoint
  // since we are using a special fetcher here
  const specialQueryKey = [...queryKey, "useCheckMultiDocExists"];
  const { data } = useQuery(
    specialQueryKey,
    async () => {
      const result = await fetch
        .url(`/product/${productId}/policy/${policyId}/query?query=${query}`)
        .get()
        // 400 (bad request) is thrown when one of the MULTI_DOC datapoints
        // does not exist in the spec file
        .badRequest(() => {
          return false;
        })
        .json<apiTypes.components["schemas"]["LangResultTypedValueOmitted"]>();

      return result.tag === "LangSuccess";
    },
    { staleTime: Infinity },
  );
  return data;
};

export const multiDocPreview = (productId: ProductId, policyId: PolicyId, quoteIds: QuoteId[]) => {
  const quotesString = quoteIds.map((qid) => `quoteId[]=${qid}`).join("&");

  return fetch
    .url(
      `/product/${productId}/policy/${policyId}/quote_pdf_preview?header=${SPECIAL_DATAPOINTS.MULTI_DOC_HEADER}&body=${SPECIAL_DATAPOINTS.MULTI_DOC_BODY}&footer=${SPECIAL_DATAPOINTS.MULTI_DOC_FOOTER}&${quotesString}`,
    )
    .get()
    .blob();
};

// User management API

export const useGetAllUsers = (role?: Role) => {
  return useGetUsers(
    1,
    99999,
    undefined,
    "name",
    role !== undefined ? [role] : undefined,
    undefined,
  );
};

export const useGetUsers = (
  page: number | undefined,
  count: number | undefined,
  sortOrder: "desc" | "asc" | undefined,
  sortOn: "name" | "email" | undefined,
  filterByRole: string[] | undefined,
  filterByEmailOrDisplayName: string | undefined,
) => {
  return useApiGet("/api/user", {
    params: {},
    query: {
      page,
      count,
      sortOrder,
      sortOn,
      filterByRole:
        filterByRole !== undefined && filterByRole.length > 0
          ? listToQueryParam(filterByRole)
          : undefined,
      filterByEmailOrDisplayName,
    },
  });
};

// Organisations API

export const useGetOrganisations = () => {
  return useApiGet("/api/org", { params: {}, query: {} });
};

// Hooks

export const useGetChat = (productId: ProductId, policyId: PolicyId) => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/chat",
    {
      params: { productId, policyId },
      query: {},
    },
    {
      refetchInterval: 5000,
    },
  );
};

export const useGetFirstChatMessage = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
  userId: UserId,
  time: string,
) => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/quote/{quoteId}/chat/{userId}/{time}",
    {
      params: {
        productId,
        policyId,
        quoteId,
        userId,
        time,
      },
      query: {},
    },
  );
};

// Query hooks

export const useGetProduct = (productId: ProductId) => {
  return useApiGet("/api/product/{productId}", {
    params: { productId },
    query: {},
  });
};

export const useGetAdditionalDatapoints = (productId: ProductId | undefined) => {
  return useApiGet("/api/product/additional_datapoints", {
    params: {},
    query: { product: productId },
  });
};

export const useGetProductAnalytics = (productId?: ProductId | undefined) => {
  return useApiGet("/api/stats", {
    params: {},
    query: { productId },
  });
};

interface UseGetProductOptions {
  onlyLatestVersion?: boolean;
}

// Use for preserving the order of products from the backend. See
// commentary of 'useGetProducts'.
type IndexedProduct = { index: number; product: Product };

export const useGetProducts = (opts?: UseGetProductOptions): OrAppError<Products> => {
  const data = useApiGet("/api/product", {
    params: {},
    query: {},
  });

  if (data.status == "error") {
    return data;
  }

  if (opts !== undefined && opts.onlyLatestVersion) {
    // We want only the latest versions of each product.
    //
    // This loop constructs a map from ID to a product
    // ('maxProducts').
    //
    // The products from 'data.products' are versioned, so the loop
    // below inserts into the id->product map ('maxProducts') for
    // each product ('prod') whose version is greater.
    //
    // However, we want to preserve the original order of the
    // products as returned by the backend, so we assign a
    // monotonically increasing index ('i') and construct an
    // IndexedProduct value.
    const maxProducts: Record<number, IndexedProduct> = {};
    let i = 0;
    for (const prod of data.value.products) {
      const cur = maxProducts[prod.id];
      const version = cur !== undefined ? cur.product.version : -1;
      if (version < prod.version) {
        const indexed = { product: prod, index: i++ };
        Object.assign(maxProducts, { [prod.id]: indexed });
      }
    }
    // Restore the original backend order. We need this intermediate
    // value with the type signature, because TypeScript is not smart
    // enough to infer all the types by itself.
    const indexedProducts: Array<IndexedProduct> = Object.values(maxProducts);
    indexedProducts.sort((x, y) => x.index - y.index);
    // Unwrap the original products:
    return {
      ...data,
      value: {
        ...data.value,
        products: indexedProducts.map((indexed) => indexed.product),
      },
    };
  }
  return data;
};

export const useGetProductVersions = (productId: ProductId) => {
  const data = useApiGet("/api/product", {
    params: {},
    query: {},
  });
  if (data.status === "error") {
    return data;
  } else {
    return {
      status: "success" as const,
      value: data.value.products.filter((product) => product.id === productId),
    };
  }
};

type ProductPolicyCounts = { [productId: number]: number };

export const useGetProductPolicyCounts = (): ProductPolicyCounts => {
  const data = useApiGet("/api/product", {
    params: {},
    query: {},
  });

  if (data.status === "error") {
    return data;
  }

  const policyCounts: ProductPolicyCounts = {};

  for (const product of data.value.products) {
    const policyCount = (policyCounts[product.id] ?? 0) + product.policyCount;
    policyCounts[product.id] = policyCount;
  }

  return policyCounts;
};

export const useGetAggregations = (params: { productId?: ProductId; page?: number }) => {
  return useApiGet("/api/stats/aggregation", {
    params: {},
    query: { product: params.productId, page: params.page },
  });
};

export const useGetPrimitiveAggregations = () => {
  return useApiGet("/api/stats/primitive-aggregation", {
    params: {},
    query: {},
  });
};

export const useGetAggregationTriggers = () => {
  return useApiGet("/api/stats/aggregation/triggers", {
    params: {},
    query: {},
  });
};

export const useGetAggregationTriggersForPolicy = (
  productId: ProductId,
  policyId: PolicyId,
  quote?: QuoteId,
) => {
  return useApiGet("/api/stats/aggregation/triggers/{product}/{policy}", {
    params: { product: productId, policy: policyId },
    query: {
      quote,
    },
  });
};

export const newAggregationTrigger = (newTrigger: AggregationTrigger) =>
  apiPost("/api/stats/aggregation/triggers", {
    params: {},
    query: {},
    body: newTrigger,
  });

export const editAggregationTrigger = (triggerId: number, editedTrigger: AggregationTrigger) =>
  apiPatch("/api/stats/aggregation/triggers/{id}", {
    params: { id: triggerId },
    query: {},
    body: editedTrigger,
  });

export const downloadProductSpecification = (
  productId: ProductId,
  productVersion?: ProductVersion,
) => {
  return fetch
    .url(`/product/${productId}/specification`)
    .query({ version: productVersion })
    .get()
    .blob();
};

export const startExportPoliciesTask = (productId: ProductId | undefined, email: string) => {
  return apiPost("/api/product/export", {
    params: {},
    query: productId !== undefined ? { product: productId, email } : { email },
    body: {},
  });
};

export const useGetPolicies = (args: GetPoliciesArgs) => {
  const {
    productId,
    page,
    pageCount,
    policyStatuses,
    quoteStatuses,
    query,
    assignees,
    additionalFields,
    sortOn,
    sortOrder,
    customSortOn,
  } = args;

  const filterByPolicyStatuses =
    policyStatuses !== undefined ? listToQueryParam(policyStatuses) : undefined;
  const filterByQuoteStatuses =
    quoteStatuses !== undefined && quoteStatuses.length > 0
      ? listToQueryParam(quoteStatuses)
      : undefined;
  const filterByAssignees = assignees !== undefined ? listToQueryParam(assignees) : undefined;
  const filterByReferenceOrInsured = query !== undefined ? encodeURIComponent(query) : undefined;
  const additionalFieldsString =
    additionalFields !== undefined ? listToQueryParam(additionalFields) : undefined;

  return useApiGet("/api/product/policies", {
    params: {},
    query: {
      product: productId,
      page,
      count: pageCount,
      filterByPolicyStatuses,
      filterByQuoteStatuses,
      filterByAssignees,
      filterByReferenceOrInsured,
      additionalFields: additionalFieldsString,
      sortOn,
      sortOrder,
      customSortParam: customSortOn,
    },
  });
};

/**
 * @see {@link invalidatePolicy}
 */
export const useGetPolicy = (productId: ProductId, policyId: PolicyId) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}", {
    params: { productId, policyId },
    query: {},
  });
};

/**
 * @see {@link useGetPolicy}
 */
export function invalidatePolicy(productId: ProductId, policyId: PolicyId): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/policy/{policyId}", {
      params: { productId, policyId },
      query: {},
    }),
  );
}

export const useGetQueryPolicyKeysInfo = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId | undefined,
  query: string,
) => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/query_keys_info",
    {
      params: {
        productId,
        policyId,
      },
      query: {
        quoteId,
        query,
      },
    },
    {
      // stale time is set for 5 minutes, so the endpoint is not
      // hit too often. This data almost never changes during the
      // mrc import.
      staleTime: 5 * 1000 * 60,
    },
  );
};

export const useGetExplainTrace = (
  productId: ProductId,
  policyId: PolicyId,
  dp: string,
  quote?: number,
) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/query", {
    params: { productId, policyId },
    query: Object.assign(
      { query: `short_explain(${dp})` },
      quote !== undefined ? { quoteId: quote } : {},
    ),
  });
};

export const usePolicyTimeline = (productId: ProductId, policyId: PolicyId) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/timeline", {
    params: { productId, policyId },
    query: {},
  });
};

export const useGetQuotes = (productId: ProductId, policyId: PolicyId) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/quote", {
    params: { productId, policyId },
    query: {},
  });
};

export const useGetQuoteDocuments = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/quote/{quoteId}/documents", {
    params: { productId, policyId, quoteId },
    query: {},
  });
};

export const useGetQuote = (productId: ProductId, policyId: PolicyId, quoteId: QuoteId) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
    params: { productId, policyId, quoteId },
    query: {},
  });
};

export const useGetGoalInfo = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId | undefined,
  datapointId: string,
  withoutPolicyAndQuote: boolean,
) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/goal_info", {
    params: { productId, policyId },
    query: {
      quoteId,
      query: datapointId,
      withoutPolicyAndQuote,
    },
  });
};

export const useGetFileInfo = (fileId: string) => {
  return useApiGet("/api/file/{hash}", {
    params: { hash: fileId },
    query: {},
  });
};

export const useGetProductConfig = (productId: ProductId) => {
  return useApiGet(
    "/api/product/{productId}/config",
    { params: { productId }, query: {} },
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCurrentUser = () => {
  return useApiGet("/api/user/me", {
    params: {},
    query: {},
  });
};

export const resendInvite = (id: UserId) =>
  apiPost("/api/user/{id}/resend-invite", {
    params: { id },
    query: {},
    body: {},
  }).then(async () => {
    await invalidatePath("/api/user");
    await queryClient.invalidateQueries(
      getQueryKey("/api/user/{user-id}", {
        params: { "user-id": id },
        query: {},
      }),
    );
  });

export const useGetDeployment = () => {
  return useApiGet(
    "/deployment",
    {
      params: {},
      query: {},
    },
    // All these are set so that we can discover when a new deployment
    // happens (grep for `DeploymentRefresher').
    {
      refetchInterval: 1000 * 30,
      refetchOnWindowFocus: "always",
      notifyOnChangeProps: ["data", "error"],
    },
  );
};

export const useGetSchema = (productId: ProductId | undefined) => {
  if (productId === undefined) {
    const path = "/api/product/schema";
    const options = {
      params: {},
      query: {},
    };
    return useApiGet(path, options);
  }
  const path = "/api/product/{productId}/schema";
  const options = {
    params: { productId: productId ?? 0 },
    query: {},
  };
  return useApiGet(
    path,
    options,
    {
      placeholderData: { status: "success", value: [] },
    },
    async (v) => {
      if (productId !== undefined) {
        return v();
      }
      return { status: "success", value: [] };
    },
  );
};

export const uploadMrcDocument = (
  productId: ProductId,
  policyId: PolicyId,
  file: File,
): Promise<{ status: 200; result: MrcUploadResult } | { status: 404 }> =>
  fetch
    .url(`/product/${productId}/policy/${policyId}/mrc/upload`)
    .formData({ file })
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as MrcUploadResult,
    }));

export const useGetMrcTaskStatus = (
  productId: ProductId,
  policyId: PolicyId,
  taskId: MrcExtractionTaskId,
): OrAppError<MrcTaskStatus> => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/mrc/task/{taskId}/status", {
    params: { productId, policyId, taskId },
    query: {},
  });
};

export function invalidateMrcTaskStatus(
  productId: ProductId,
  policyId: PolicyId,
  taskId: MrcExtractionTaskId,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/policy/{policyId}/mrc/task/{taskId}/status", {
      params: { productId, policyId, taskId },
      query: {},
    }),
  );
}

export const getMrcDocumentPage = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  pageNum: number,
): Promise<string> =>
  fetch
    .url(`/product/${productId}/policy/${policyId}/mrc/document/${documentId}/page/${pageNum}`)
    .get()
    .blob((blob) => URL.createObjectURL(blob));

export const useGetMrcDocuments = (
  productId: ProductId,
  policyId: PolicyId,
): OrAppError<MrcDocumentSummary[]> => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/mrc/documents", {
    params: { productId, policyId },
    query: {},
  });
};

export const useGetMrcDocumentInfo = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
): OrAppError<null | MrcDocumentInfo> => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/info", {
    params: { productId, policyId, documentId },
    query: {},
  });
};

export const useGetMrcDocumentCandidates = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
): OrAppError<MrcCandidate[]> => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/candidates",
    {
      params: { productId, policyId, documentId },
      query: {},
    },
  );
};

export const useGetMrcResolvedDatapoints = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
): OrAppError<MrcResolvedExtraction[]> => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoints",
    { params: { productId, policyId, documentId }, query: {} },
  );
};

export function invalidateMrcResolvedDatapoints(
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoints", {
      params: { productId, policyId, documentId },
      query: {},
    }),
  );
}

export const useGetMrcResolvedExtraction = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
): OrAppError<MrcResolvedExtraction> => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoint/{extractionId}",
    { params: { productId, policyId, documentId, extractionId }, query: {} },
  );
};

export function invalidateMrcResolvedExtraction(
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey(
      "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoint/{extractionId}",
      {
        params: { productId, policyId, documentId, extractionId },
        query: {},
      },
    ),
  );
}

export const storeMrcExtractionCandidate = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
  value: { Left: MrcCandidateId } | { Right: Scalar },
): Promise<{ status: 200 } | { status: 404 }> =>
  fetch
    .url(
      `/product/${productId}/policy/${policyId}/mrc/document/${documentId}/datapoint/${extractionId}`,
    )
    .json(value)
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .text((_) => {
      return { status: 200 };
    });

export const useGetMrcDatapointCandidates = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
): OrAppError<MrcCandidate[]> => {
  return useApiGet(
    "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoint/{extractionId}/candidates",
    { params: { productId, policyId, documentId, extractionId }, query: {} },
  );
};

export function invalidateMrcDatapointCandidates(
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey(
      "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoint/{extractionId}/candidates",
      {
        params: { productId, policyId, documentId, extractionId },
        query: {},
      },
    ),
  );
}

export const storeMrcExtractionCheckedState = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
  extractionId: MrcExtractionId,
  checkedState: MrcCheckedState | undefined,
) =>
  apiPost(
    "/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/datapoint/{extractionId}/set-checked-state",
    {
      params: {
        productId,
        policyId,
        documentId,
        extractionId,
      },
      query: {},
      body: { checkedState: checkedState },
    },
  ).then(async (r) => {
    await invalidateMrcResolvedDatapoints(productId, policyId, documentId);
    await invalidateMrcResolvedExtraction(productId, policyId, documentId, extractionId);
    return r;
  });

export const postMrcImport = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/import", {
    params: {
      productId,
      policyId,
      documentId,
    },
    query: {},
    body: {},
  });

export const postMrcCancelImport = (
  productId: ProductId,
  policyId: PolicyId,
  documentId: MrcDocumentId,
) =>
  apiPost("/api/product/{productId}/policy/{policyId}/mrc/document/{documentId}/cancel-import", {
    params: {
      productId,
      policyId,
      documentId,
    },
    query: {},
    body: {},
  });

export const useGetMrcProductAdapterMapping = (
  productId: ProductId | undefined,
  productVersion: ProductVersion | undefined,
): OrAppError<MrcProductAdapter[]> => {
  return useApiGet(
    "/api/product/{productId}/version/{productVersion}/mrc-adapter-mapping",
    {
      params: {
        // We need to satisfy typescript and always provide a product ID and
        // version, even when they might not be available.
        // We use -1 here because our real product IDs are 0..N (positive), and
        // we can't just use (0, 0) because then the request won't be fired for
        // a product which happens to be (0, 0) itself.
        // The actual request should never be performed with (-1, -1), due to
        // the check in the fourth argument to useApiGet.
        productId: productId === undefined ? -1 : productId,
        productVersion: productVersion === undefined ? -1 : productVersion,
      },
      query: {},
    },
    {},
    async (v) => {
      if (productId !== undefined && productVersion !== undefined) {
        return v();
      }
      return { status: "success", value: [] };
    },
  );
};

export function invalidateMrcProductAdapterMapping(
  productId: ProductId,
  productVersion: ProductVersion,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/version/{productVersion}/mrc-adapter-mapping", {
      params: { productId, productVersion },
      query: {},
    }),
  );
}

export const setupMrcProductAdapters = (
  options: SetupMrcAdapterOptions,
  productId: ProductId,
  productVersion: ProductVersion,
) =>
  apiPost("/api/product/{productId}/version/{productVersion}/mrc-adapter-mapping/setup", {
    params: {
      productId,
      productVersion,
    },
    query: {},
    body: options,
  });

export const useGetMrcAdapters = (): OrAppError<MrcAdapter[]> => {
  return useApiGet("/api/mrc-adapters/list-by-type", { params: {}, query: {} });
};

export function invalidateMrcAdapters(): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/mrc-adapters/list-by-type", {
      params: {},
      query: {},
    }),
  );
}

export const setMrcAdapterForDatapoint = (
  productId: ProductId,
  productVersion: ProductVersion,
  datapointName: string,
  adapterId: MrcAdapterId | null,
): Promise<boolean> =>
  fetch
    .url(`/product/${productId}/version/${productVersion}/mrc-adapter-mapping/set`)
    .post([datapointName, adapterId])
    .json();

export const useGetAnyMrcImported = (
  productId: ProductId,
  policyId: PolicyId,
): OrAppError<boolean> => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/mrc/any_imported", {
    params: { productId, policyId },
    query: {},
  });
};

export function invalidateGetAnyMrcImported(
  productId: ProductId,
  policyId: PolicyId,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/policy/{policyId}/mrc/any_imported", {
      params: { productId, policyId },
      query: {},
    }),
  );
}

// SoV

/**
 * Like {@link transactPolicy} but for when the transactions belong to a SoV extraction.
 */
export const transactPolicySov = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  transaction: Transaction,
): Promise<PatchResponseFor<"/api/product/{productId}/policy/{policyId}/sov/{extractionId}">> =>
  apiPatch("/api/product/{productId}/policy/{policyId}/sov/{extractionId}", {
    params: { productId, policyId, extractionId },
    query: {},
    body: transaction,
  }).then(async (r) => {
    // Finishing the import of a SoV document makes it appear as "completed".
    await queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/sov", {
        params: { productId, policyId },
        query: {},
      }),
    );
    queryClient.setQueryData(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
      r,
    );
    return r;
  });

/**
 * Returns the list of completed and ongoing SoV extractions for a policy.
 */
export const useGetSovExtractions = (
  productId: ProductId,
  policyId: PolicyId,
): OrAppError<SovExtraction[]> =>
  useApiGet(
    "/api/product/{productId}/policy/{policyId}/sov",
    {
      params: {
        productId,
        policyId,
      },
      query: {},
    },
    {},
  );

export const beginSovExtraction = (
  productId: ProductId,
  policyId: PolicyId,
  abortController: AbortController,
): Promise<{ status: 200; result: SovExtractionId } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov`)
    .signal(abortController)
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: (body as BeginSovExtractionResponse).sovExtractionId,
    }));
};

export const uploadSovDocument = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  file: File,
  abortController: AbortController,
): Promise<{ status: 200 } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/upload`)
    .signal(abortController)
    .formData({ file })
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((): { status: 200 } | { status: 404 } => ({
      status: 200,
    }))
    .then(async (r) => {
      // Uploading a SoV document makes it appear as "draft".
      await queryClient.invalidateQueries(
        getQueryKey("/api/product/{productId}/policy/{policyId}/sov", {
          params: { productId, policyId },
          query: {},
        }),
      );
      return r;
    });
};

export const getSovTabs = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  abortController: AbortController,
): Promise<{ status: 200; result: SovAvailableTabs } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/get_tabs`)
    .signal(abortController)
    .formData([])
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as SovAvailableTabs,
    }));
};

export const selectSovTabs = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  selectedTabs: SovSelectedTabs,
  abortController: AbortController,
): Promise<{ status: 200; result: unknown } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/select_tabs`)
    .signal(abortController)
    .post(selectedTabs)
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as unknown,
    }));
};

export const getSovExecutionStatus = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  abortController: AbortController,
): Promise<{ status: 200; result: SovExecutionStatus } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/get_execution_status`)
    .signal(abortController)
    .post()
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as SovExecutionStatus,
    }));
};

export const runMapperSov = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  targetTableDatapoint: string,
  selectedTabs: SovSelectedTabs,
  abortController: AbortController,
): Promise<{ status: 200; result: SovColumnMatches[] } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/run_mapper`)
    .signal(abortController)
    .post({
      tabsAndId: selectedTabs,
      targetTableDatapoint: targetTableDatapoint,
    })
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as SovColumnMatches[],
    }));
};

export const getSovMappedOutput = (
  productId: ProductId,
  policyId: PolicyId,
  extractionId: SovExtractionId,
  columnMappings: SovColumnMappings,
  abortController: AbortController,
): Promise<{ status: 200; result: SovExtractedTable } | { status: 404 }> => {
  return fetch
    .url(`/product/${productId}/policy/${policyId}/sov/${extractionId}/get_mapped_output`)
    .signal(abortController)
    .post(columnMappings)
    .error(404, (_err) => {
      return { status: 404 };
    })
    .json((body) => ({
      status: 200,
      result: body as SovExtractedTable,
    }));
};

export const useGetSovProductAdapterMapping = (
  productId: ProductId | undefined,
  productVersion: ProductVersion | undefined,
): OrAppError<SovProductAdapter[]> => {
  return useApiGet(
    "/api/product/{productId}/version/{productVersion}/sov-adapter-mapping",
    {
      params: {
        productId: productId === undefined ? -1 : productId,
        productVersion: productVersion === undefined ? -1 : productVersion,
      },
      query: {},
    },
    {},
    async (v) => {
      if (productId !== undefined && productVersion !== undefined) {
        return v();
      }
      return { status: "success", value: [] };
    },
  );
};

export function invalidateSovProductAdapterMapping(
  productId: ProductId,
  productVersion: ProductVersion,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/version/{productVersion}/sov-adapter-mapping", {
      params: { productId, productVersion },
      query: {},
    }),
  );
}

export const useGetSovProductTableDatapoints = (
  productId: ProductId,
  productVersion: ProductVersion,
): OrAppError<string[]> => {
  return useApiGet("/api/product/{productId}/version/{productVersion}/sov-adapter-mapping/tables", {
    params: { productId, productVersion },
    query: {},
  });
};

export function invalidateSovProductTableDatapoints(
  productId: ProductId,
  productVersion: ProductVersion,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/product/{productId}/version/{productVersion}/sov-adapter-mapping/tables", {
      params: { productId, productVersion },
      query: {},
    }),
  );
}

export const useGetSovProductSpecInfo = (
  productId: ProductId,
  productVersion: ProductVersion,
  tableDatapointName: string,
): OrAppError<SovSpecInfo> => {
  return useApiGet(
    "/api/product/{productId}/version/{productVersion}/sov-adapter-mapping/spec-info/{tableDatapointName}",
    {
      params: { productId, productVersion, tableDatapointName },
      query: {},
    },
  );
};

export function invalidateSovProductSpecInfo(
  productId: ProductId,
  productVersion: ProductVersion,
  tableDatapointName: string,
): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey(
      "/api/product/{productId}/version/{productVersion}/sov-adapter-mapping/spec-info/{tableDatapointName}",
      {
        params: { productId, productVersion, tableDatapointName },
        query: {},
      },
    ),
  );
}

export const setupSovProductAdapters = (
  options: SetupSovAdapterOptions,
  productId: ProductId,
  productVersion: ProductVersion,
) =>
  apiPost("/api/product/{productId}/version/{productVersion}/sov-adapter-mapping/setup", {
    params: {
      productId,
      productVersion,
    },
    query: {},
    body: options,
  });

export const useGetSovAdapters = (): OrAppError<SovAdapter[]> => {
  return useApiGet("/api/sov-adapters/list-by-type", { params: {}, query: {} });
};

export function invalidateSovAdapters(): Promise<void> {
  return queryClient.invalidateQueries(
    getQueryKey("/api/sov-adapters/list-by-type", {
      params: {},
      query: {},
    }),
  );
}

export const setSovAdapterForDatapoint = (
  productId: ProductId,
  productVersion: ProductVersion,
  setAdapter: SovSetAdapter,
): Promise<boolean> =>
  fetch
    .url(`/product/${productId}/version/${productVersion}/sov-adapter-mapping/set`)
    .post(setAdapter)
    .json();

/*
 * Hook for getting task notifications.
 * Keep in mind that the default behaviour for react query is to
 * work only in the active tab.
 *
 * We fetch the result every 5 seconds.
 */
export const useGetTaskNotifications = () => {
  return useApiGet(
    "/api/tasks/notifications",
    {
      params: {},
      query: {},
    },
    {
      refetchInterval: 5000, // 5s
    },
  );
};

export const useGetTaskById = (taskId: TaskId) => {
  return useApiGet("/api/tasks/single/{taskId}", {
    params: { taskId: JSON.stringify(taskId) },
    query: {},
  });
};

export const useGetPromiseById = (productId: ProductId, policyId: PolicyId, promiseId: number) => {
  return useApiGet("/api/product/{productId}/policy/{policyId}/async/promise/{promiseId}", {
    params: { productId, policyId, promiseId },
    query: {},
  });
};
