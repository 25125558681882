const containsUppercaseAndLowercaseCharacter = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])/);
const containsNumber = new RegExp(/^(?=.*[0-9])/);
const specialCharacters = new RegExp(/^(?=.*[!@#$%^&*()_+\-[\]{};':"\\|,.<>/?])/);

interface PasswordConditions {
  newPassword: string;
  confirmNewPassword: string;
}

export const passwordConditions = ({
  newPassword = "",
  confirmNewPassword,
}: PasswordConditions) => ({
  minimumCharacters: newPassword.length >= 10,
  uppercaseLowercase: containsUppercaseAndLowercaseCharacter.test(newPassword),
  numberMinimum: containsNumber.test(newPassword),
  specialCharacter: specialCharacters.test(newPassword),
  passwordMatch: newPassword.length >= 10 && newPassword === confirmNewPassword,
});
