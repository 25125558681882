import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { getConfig } from "./dal/dal";
import { ConfigContext } from "./env";
import { loadScript } from "./utils";
import "/src/styles.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "/src/ag-grid.css";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "CompanyName=Artificial Labs Ltd,LicensedApplication=Brossa,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-033587,SupportServicesEnd=3_October_2023_[v2]_MTY5NjI4NzYwMDAwMA==94e2adcd2aa943e17067b2cf8d815fbf",
);

getConfig()
  .then(async (config) => {
    if (config.status === "error") {
      throw config;
    }
    if (config.value.sentry !== undefined) {
      Sentry.init({
        dsn: config.value.sentry.dsn,
        environment: config.value.sentry.environment,
      });
    }
    aws_amplify.Auth.configure({
      ...config.value.auth.cognito,
      ...{ oauth: config.value.auth.oauth },
    });
    // Could do this in the `AddressNode` component
    await loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${config.value.googleMapsApiKey}&libraries=places`,
    );

    ReactDOM.render(
      React.createElement(
        React.StrictMode,
        {},
        React.createElement(
          ConfigContext.Provider,
          { value: config.value },
          React.createElement(App),
        ),
      ),
      document.getElementById("root"),
    );
  })
  .catch((reason) => {
    throw new Error(`Error on the setup of the app: ${JSON.stringify(reason)}`);
  });
