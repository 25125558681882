import React, { PropsWithChildren } from "react";
import { XIcon } from "@heroicons/react/solid";
import { Loader } from "/src/design_system/Loader";
import { Transition } from "@headlessui/react";

export type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

export const Modal = (props: ModalProps): JSX.Element => {
  return (
    <Transition show={props.isOpen} className="absolute">
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={props.onClose}
              ></div>
            </div>
          </Transition.Child>
          <Transition.Child
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}
            enterTo={"opacity-100 translate-y-0 sm:scale-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100 translate-y-0 sm:scale-100"}
            leaveTo={"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}
          >
            <div
              className="inline-block align-bottom bg-white rounded-xl shadow-2xl transform transition-all overscroll-auto max-h-screen overflow-y-auto m-6 text-black"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <XIcon
                className="h-6 w-6 absolute top-0 right-0 m-6 cursor-pointer text-black z-[60]"
                onClick={props.onClose}
              />
              {props.children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export const ModalLoader = () => {
  return (
    <div className="flex items-center justify-center p-10 w-60 h-60">
      <Loader size="lg" />
    </div>
  );
};
