import { Table, TD, THead, TR, TH, TBody } from "../design_system/Table";
import * as apiTypes from "/src/api_types";
import { PencilIcon, XCircleIcon, BookOpenIcon } from "@heroicons/react/solid";
import { Button } from "/src/design_system/Button";
import {
  publishPendingProduct,
  disposePendingProduct,
  useGetCurrentUser,
  useGetPendingProduct,
  useGetPendingProductEditable,
} from "../dal/dal";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routing/routes";
import { CanCreateProducts } from "./policy/permissions";
import { useParams } from "../routing/routing";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Link } from "react-router-dom";
import { throwIfAppError } from "/src/utils/app_error";

////////////////////////////////////////////////////////////////////////////////
// Main entry point

const PendingProduct: React.FC = () => {
  const {
    data: {
      capture: { pendingProductId },
    },
  } = useParams(Routes.pendingProduct);
  const pendingProduct = useGetPendingProduct(pendingProductId);
  throwIfAppError(pendingProduct);
  const editable = useGetPendingProductEditable(pendingProductId);
  throwIfAppError(editable);
  return (
    <Page>
      <PageHeader>
        <PendingProductHeader name={pendingProduct.value.name} />
      </PageHeader>
      <PageContent>
        <Actions pendingProductId={pendingProductId} />
        <Overview editable={editable.value} />
      </PageContent>
    </Page>
  );
};

const Actions = (props: { pendingProductId: number }) => {
  const { pendingProductId } = props;
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const cs = { currentRole: currentUser.value.role };
  const canCreateProducts = CanCreateProducts(cs).check(undefined);
  const history = useHistory();
  return (
    <div className="px-8 flex-row flex space-x-3">
      {canCreateProducts && (
        <>
          <Button
            data-testid="edit-product-button"
            type="button"
            variant="secondary"
            aria-hidden="true"
            onClick={async (_) => {
              history.push(
                Routes.editPendingProduct.generatePath({ capture: { pendingProductId } }),
              );
            }}
          >
            <PencilIcon className="h-5 w-5 mr-1" />
            Edit
          </Button>
          <Button
            data-testid="edit-product-button"
            type="button"
            variant="secondary"
            aria-hidden="true"
            onClick={async (_) => {
              if (confirm("Please confirm that you want to publish this pending product.")) {
                const result = await publishPendingProduct(pendingProductId);
                if (result.status == "success") {
                  history.push(
                    Routes.product.generatePath({ capture: { productId: result.value.id } }),
                  );
                } else {
                  // The error from the server is not useful: it
                  // says "unexpected error". So the server needs to
                  // provide more information.
                  alert("You cannot publish at this time.");
                }
              }
            }}
          >
            <BookOpenIcon className="h-5 w-5 mr-1" />
            Publish
          </Button>
          <Button
            data-testid="dispose-product-button"
            type="button"
            variant="danger"
            aria-hidden="true"
            onClick={async (_) => {
              if (confirm("Please confirm that you want to delete this pending product.")) {
                const result = await disposePendingProduct(pendingProductId);
                if (result.status == "success") {
                  history.push(Routes.products.generatePath({}));
                } else {
                  alert(`You cannot delete at this time. Error message: ${result.value.message}`);
                }
              }
            }}
          >
            <XCircleIcon className="h-5 w-5 mr-1" />
            Delete
          </Button>
        </>
      )}
    </div>
  );
};

const PendingProductHeader = (props: { name: string }) => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <Link to={Routes.products.generatePath({})} className="group py-2 pr-6 flex flex-row">
          ← Back to products
        </Link>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div>
          <PageTitle>{props.name}</PageTitle>
        </div>
      </div>
      <div className="flex flex-row w-full pt-4">
        <p>
          This product is <strong>pending</strong> publication. It will not be seen or accessible as
          a live product until you publish it.
        </p>
      </div>
    </div>
  );
};

const Overview = (props: { editable: apiTypes.components["schemas"]["EditableProduct"] }) => {
  return (
    <div className="px-8 py-4 flex-row flex space-x-3">
      <Table>
        <THead>
          <TR>
            <TH label="Name" />
            <TH label="Size" />
          </TR>
        </THead>
        <TBody>
          {props.editable.sourceTextFiles.slice().map((file) => {
            const sizeDisplay =
              file.fileContents.length < 1024
                ? file.fileContents.length + " bytes"
                : Math.round(file.fileContents.length / 1000) + " KB";
            return (
              <TR>
                <TD>{file.fileName}</TD>
                <TD>{sizeDisplay}</TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

export default PendingProduct;
