// Goal: similar to Airtable's interface
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TablePagination,
  SxProps,
  Theme,
} from "@mui/material";
import { Modal } from "../../design_system/Modal";
import IconButton from "@mui/material/IconButton";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { Autocomplete } from "@mui/lab";
import clsx from "clsx";
import { isValid, parseISO } from "date-fns";
import React, { forwardRef, PropsWithChildren, useEffect, useMemo, useRef } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { CellT } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafNode } from "./base";
import { DateNodeT_ } from "./date_node";
import { DateSelectNodeT_ } from "./date_select_node";
import { DateSelectNode } from "./date_select_node";
import { NumberNodeT_ } from "./number_node";
import { NumberSelectNodeT_ } from "./number_select_node";
import { NumberSelectNode } from "./number_select_node";
import { PercentNodeT_ } from "./percent_node";
import { PercentSelectNodeT_ } from "./percent_select_node";
import { PercentSelectNode } from "./percent_select_node";
import { CaselessTextNodeT_, TextNodeT_ } from "./text_node";
import { TextSelectNodeT_ } from "./text_select_node";
import { notNil, toNonEmptyString } from "/src/utils";
import { useTouch } from "/src/utils/use_touch";
import {
  VariableSizeGrid as Grid,
  GridChildComponentProps,
  GridItemKeySelector,
} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Button } from "/src/design_system/Button";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { TextSuggestNode, TextSuggestNodeT_ } from "./text_suggest_node";
import { NumberSuggestNode, NumberSuggestNodeT_ } from "./number_suggest_node";
import { YesNoNode, YesNoNodeT_ } from "./yes_no_node";
import { UmrNode, UmrNodeT_ } from "./umr_node";
import { Loader } from "/src/design_system/Loader";
import { NodeProblems } from "../node_problems";

// const useStyles = makeStyles((theme) => ({
// }));

// id type:
// - UUID
// - Text
// - Text (subset of)

// tw: think about once you have more info
export interface TableNodeT_ extends LeafNodeT_ {
  type: "table";
  // the schema for an item identifier
  idConfig: { type: "select_text"; options: string[] };
  showHeader: boolean;
  columns: Array<{
    name: string;
    label: string;
  }>;
  rows: Array<{
    id: string; // assuming text for now
    cells: Array<CellT>;
    removeItem: () => void;
  }>;
  addItem: () => void;
  isCommitting: boolean;
}

export const TableNode = (props: { node: TableNodeT_ }) => {
  const { node } = props;

  const [isTableModalOpen, setTableModalOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableKey, triggerTableRemount] = React.useReducer(
    (state: number, increase: number) => state + increase,
    1,
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnCountIncludingActions: number = node.summaryView
    ? node.columns.length
    : node.columns.length + 1;
  const rowCount: number = node.rows.length;
  const rowCountIncludingHeader = rowCount + 1;
  const columnMinWidth = 200;
  const rowHeight = 100;
  return (
    <>
      <Modal
        isOpen={isTableModalOpen}
        onClose={() => {
          setTableModalOpen(false);
          triggerTableRemount(1);
        }}
      >
        <MemoizedVirtualizedTable
          columnCountIncludingActions={columnCountIncludingActions}
          columnMinWidth={columnMinWidth}
          rowCountIncludingHeader={rowCountIncludingHeader}
          rowHeight={rowHeight}
          node={node}
        />
      </Modal>
      <LeafOrSummaryLeafNode node={props.node}>
        <div className="border rounded flex flex-col">
          <div className="flex flex-row justify-end">
            <Tooltip title="Open table popup">
              <IconButton
                onClick={() => setTableModalOpen(true)}
                size="small"
                aria-label="open table popup"
              >
                {<ZoomOutMapIcon />}
              </IconButton>
            </Tooltip>
          </div>
          <TableContainer key={tableKey} sx={{ width: "100%" }}>
            <Table stickyHeader aria-label="simple-table">
              <TableHead>
                <TableRow>
                  {node.columns.map((column) => (
                    <TableCell
                      key={column.name}
                      sx={{
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        minWidth: "10rem",
                        backgroundColor: "grey.50",
                        textTransform: "uppercase",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {!node.summaryView && (
                    <TableCell
                      key={"actions"}
                      align={"center"}
                      sx={{
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        backgroundColor: "grey.50",
                      }}
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                  ? node.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : node.rows
                ).map((row) => {
                  return (
                    <TableRow key={row.id} data-testid={`table-node-row`} data-tableid={row.id}>
                      {row.cells.map((cell) => (
                        <Cell
                          innerSx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: "4px",
                            paddingRight: "4px",
                          }}
                          key={cell.id}
                          cell={cell}
                          summaryView={node.summaryView}
                        />
                      ))}
                      {!node.summaryView && (
                        <TableCell
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: "4px",
                            paddingRight: "4px",
                          }}
                          key={`${row.id}-remove`}
                          align={"center"}
                        >
                          <DeleteRowButton row={row} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <AddRowButton node={node} />
          <div className="flex flex-row">
            <div />
            <div className="grow" />
            <TablePagination
              sx={{ borderBottom: "none" }}
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={node.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              showFirstButton={true}
              showLastButton={true}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                  "data-testid": "table-node-rows-per-page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </LeafOrSummaryLeafNode>
    </>
  );
};

const LeafOrSummaryLeafNode = (props: PropsWithChildren<{ node: TableNodeT_ }>) => {
  return (
    <>
      {props.node.summaryView ? (
        <SummaryLeafNode label={props.node.label} vertical>
          {props.children}
        </SummaryLeafNode>
      ) : (
        <LeafNode node={props.node}>{props.children}</LeafNode>
      )}
    </>
  );
};

/**
 * A cell in the table modal.
 * @param param When rowIndex is 0, the cell is a header cell.
 */
const GridCell = ({ columnIndex, rowIndex, style, data }: GridChildComponentProps<TableNodeT_>) => {
  const editing = !data.summaryView;
  if (rowIndex === 0) {
    // The right corner of the header row (actions column) remains blank when editing.
    if (editing && columnIndex === data.columns.length) {
      return <div className="border-b border-b-gray-200 bg-gray-50" style={style}></div>;
    } else {
      const column = data.columns[columnIndex];
      return (
        <div
          className="border-b border-b-gray-200 flex flex-col justify-center font-medium my-auto bg-gray-50 p-3"
          style={style}
        >
          <span className="min-w-[10rem] uppercase">{column.label}</span>
        </div>
      );
    }
  }
  const dataRowIndex = rowIndex - 1;
  const row = data.rows[dataRowIndex];
  if (editing && columnIndex === data.columns.length) {
    return (
      <div className="border-b border-b-gray-200 p-4 flex flex-column" style={style}>
        <div className="my-auto">
          <DeleteRowButton row={row} />
        </div>
      </div>
    );
  }
  const cell = row.cells[columnIndex];
  const innerStyle: SxProps<Theme> = useMemo(() => {
    return {
      borderBottom: "none",
      overflowY: "auto",
    };
  }, []);
  return (
    <div className="border-b border-b-gray-200 flex flex-col justify-center" style={style}>
      {
        // Because of #4170, we display a loader here when the cell is committing .
        cell.isCommitting ? (
          <div className="flex flex-row justify-center">
            <Loader size="sm" />
          </div>
        ) : (
          <Cell key={cell.id} cell={cell} summaryView={data.summaryView} innerSx={innerStyle} />
        )
      }
    </div>
  );
};

/**
 * We reflect each row's actual row id in the React key.
 * See the doc for member 'itemKey' of react-window 'GridProps' type.
 * @param param0
 * @returns the Key for the react-window cell.
 */
const makeGridCellKey: GridItemKeySelector<TableNodeT_> = ({ columnIndex, rowIndex, data }) => {
  const rowKeyPart = rowIndex === 0 ? "_header" : data.rows[rowIndex - 1].id;
  return `${rowKeyPart} ${columnIndex}`;
};

const Cell = (props: {
  cell: CellT;
  summaryView: boolean;
  innerSx?: SxProps<Theme>;
}): JSX.Element => {
  const { cell, summaryView } = props;
  const needsCollecting = "needsCollecting" in cell && cell.needsCollecting;
  const invalidOption =
    cell.type === "text_select" && cell.value !== null && !cell.options.includes(cell.value);
  return (
    <TableCell
      sx={props.innerSx}
      className="relative"
      data-testid={`table-node-cell-${cell.columnName}`}
    >
      {renderCell(cell, summaryView)}
      {!summaryView && (needsCollecting || invalidOption) && (
        <svg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          className="w-2.5 h-2.5 absolute right-3 top-5 animate-pulse fill-red-700"
          data-testid={`table-node-cell-needs-collecting`}
        >
          <circle cx="50" cy="50" r="50" />
        </svg>
      )}
    </TableCell>
  );
};

export const renderCell = (cell: CellT, summaryView: boolean): JSX.Element => {
  switch (cell.type) {
    case "text":
      return <TextCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "text_select":
      return <TextSelectCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "text_suggest":
      return <TextSuggestCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "caseless_text":
      return <TextCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "umr":
      return <UmrCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "yes_no":
      return <YesNoCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "number":
      return <NumberCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "number_select":
      return <NumberSelectCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "number_suggest":
      return <NumberSuggestCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "percent":
      return <PercentCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "percent_select":
      return <PercentSelectCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "date":
      return <DateCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "date_select":
      return <DateSelectCellInput cell={{ ...cell, summaryView: summaryView }} />;
    case "n/a":
      return <div></div>;
  }
};

const DateCellInput = (props: { cell: DateNodeT_ }) => {
  const { cell } = props;
  const propsValue: string = cell.value?.toString() ?? "";
  const [localValue, setLocalValue] = React.useState(propsValue);

  const commitValue = async (value: string) => {
    await cell.commit(toNonEmptyString(value));
  };
  const handleOnBlur = async () => {
    if (localValue.trim().length > 0) {
      const date = parseISO(localValue);
      if (isValid(date)) {
        await commitValue(localValue);
      }
    } else {
      await commitValue("");
    }
  };
  return cell.summaryView ? (
    <> {cell.value !== null ? `${cell.value}` : ""} </>
  ) : (
    <NumberFormat
      customInput={TextField}
      format="####-##-##"
      mask="_"
      className="rounded border-gray-300 py-2"
      value={cell.readOnly ? propsValue : localValue}
      onValueChange={(e) => {
        setLocalValue(e.formattedValue);
      }}
      disabled={cell.readOnly}
      onBlur={handleOnBlur}
    />
  );
};

const TextCellInput = (props: { cell: TextNodeT_ | CaselessTextNodeT_ }) => {
  const { cell } = props;
  const propsValue: string = cell.value?.toString() ?? "";
  const [localValue, setLocalValue] = React.useState(propsValue);
  const [ifTouched, touch] = useTouch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    touch();
    setLocalValue(e.target.value);
  };
  const handleOnBlur = () => {
    ifTouched() && cell.commit(localValue.trim() === "" ? null : localValue.trim());
  };

  return cell.summaryView ? (
    <> {cell.value !== null ? `${cell.value}` : ""} </>
  ) : (
    <TextField
      value={cell.readOnly ? propsValue : localValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      disabled={cell.readOnly}
      multiline={cell.isMultiline}
      data-testid={`table-text-cell-input`}
    />
  );
};

const UmrCellInput = (props: { cell: UmrNodeT_ }) => {
  const { cell } = props;
  return <UmrNode node={cell} />;
};

const TextSelectCellInput = (props: { cell: TextSelectNodeT_ }) => {
  const { cell } = props;

  const onChange = (newValue: string | null) => {
    if (newValue === null) {
      cell.commit(null);
    } else {
      cell.commit(newValue.trim() === "" ? null : newValue);
    }
  };

  return cell.summaryView ? (
    <> {cell.value !== null ? `${cell.value}` : ""} </>
  ) : (
    <Autocomplete
      value={cell.value}
      options={cell.options}
      multiple={false}
      onChange={(_evt, value) => onChange(value)}
      disabled={cell.readOnly}
      renderInput={(params) => <TextField {...params} helperText={cell.description} />}
    />
  );
};

const TextSuggestCellInput = (props: { cell: TextSuggestNodeT_ }) => {
  const { cell } = props;
  return <TextSuggestNode node={cell} />;
};

const YesNoCellInput = (props: { cell: YesNoNodeT_ }) => {
  const { cell } = props;
  return <YesNoNode key={cell.id} node={cell} />;
};

const NumberCellInput = (props: { cell: NumberNodeT_ }) => {
  const { cell } = props;
  const propsValue: string = cell.value?.toString() ?? "";
  const [localValue, setLocalValue] = React.useState(propsValue);
  const [ifTouched, touch] = useTouch();

  useEffect(() => {
    if (!cell.isCommitting) {
      setLocalValue(cell.value?.toString() ?? "");
    }
  }, [cell.value]);

  const handleOnValueChange = (e: NumberFormatValues) => {
    touch();
    setLocalValue(e.value);
  };
  const handleOnBlur = () => {
    ifTouched() && cell.commit(localValue.trim() === "" ? null : +localValue);
  };

  return cell.summaryView ? (
    <> {cell.value !== null ? `${cell.value}${cell.prefix ?? ""}` : ""} </>
  ) : (
    <NumberFormat
      thousandSeparator={true}
      customInput={TextField}
      className={clsx(["aui-input border-none", "w-full", "focus:outline-none"])}
      prefix={cell.prefix}
      allowEmptyFormatting
      value={cell.readOnly ? propsValue : localValue}
      onValueChange={handleOnValueChange}
      onBlur={handleOnBlur}
      disabled={cell.readOnly}
    />
  );
};

const NumberSelectCellInput = (props: { cell: NumberSelectNodeT_ }) => {
  const { cell } = props;
  return <NumberSelectNode node={cell} />;
};

const NumberSuggestCellInput = (props: { cell: NumberSuggestNodeT_ }) => {
  const { cell } = props;
  return <NumberSuggestNode node={cell} />;
};

const PercentCellInput = (props: { cell: PercentNodeT_ }) => {
  const { cell } = props;
  const propsValue: string = cell.value?.toString() ?? "";
  const [localValue, setLocalValue] = React.useState(propsValue);
  const [ifTouched, touch] = useTouch();

  useEffect(() => {
    if (!cell.isCommitting) {
      setLocalValue(cell.value?.toString() ?? "");
    }
  }, [cell.value]);

  const handleOnValueChange = (e: NumberFormatValues) => {
    touch();
    setLocalValue(e.value);
  };
  const handleOnBlur = () => {
    ifTouched() && cell.commit(localValue.trim() === "" ? null : +localValue);
  };

  return cell.summaryView ? (
    <> {cell.value !== null ? `${cell.value}%` : ""} </>
  ) : (
    <>
      <NodeProblems problems={cell.problems} />
      <NumberFormat
        thousandSeparator={true}
        customInput={TextField}
        className={clsx(["aui-input border-none", "focus:outline-none"])}
        suffix="%"
        value={cell.readOnly ? propsValue : localValue}
        onValueChange={handleOnValueChange}
        onBlur={handleOnBlur}
        disabled={cell.readOnly}
      />
    </>
  );
};

const PercentSelectCellInput = (props: { cell: PercentSelectNodeT_ }) => {
  const { cell } = props;
  return <PercentSelectNode node={cell} />;
};

const DateSelectCellInput = (props: { cell: DateSelectNodeT_ }) => {
  const { cell } = props;
  return <DateSelectNode node={cell} />;
};

const AddRowButton = ({ node }: { node: TableNodeT_ }) => {
  if (node.summaryView) {
    return null;
  }
  return (
    <div className="flex flex-row justify-start bg-gray-50 outline outline-slate-200">
      <div className="flex flex-col justify-center ml-3 my-2">
        <Button
          className=""
          variant="secondary"
          onClick={node.addItem}
          data-testid={`table-node-add-row-${node.id}`}
        >
          <PlusIcon className="h-5 w-5 mr-1 -ml-1 text-gray-500" />
          <span>Add row</span>
        </Button>
      </div>
    </div>
  );
};

const DeleteRowButton = ({
  row: { removeItem },
}: {
  row: {
    removeItem: () => void;
  };
}) => (
  <Button className="px-2" variant="secondary" onClick={removeItem}>
    <TrashIcon className="h-5 w-5 mr-1 ml-1 text-gray-500" />
  </Button>
);

const innerElementType = (
  data: TableNodeT_,
  rowHeight: number,
  columnCount: number,
  columnWidth: (idx: number) => number,
) =>
  forwardRef<HTMLDivElement>(({ children, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      <div className="w-full sticky top-0 left-0 z-10">
        {(() => {
          const cells = [];
          let offset = 0;
          for (let i = 0; i < columnCount; ++i) {
            const width = columnWidth(i);
            cells.push(
              <GridCell
                data={data}
                rowIndex={0}
                columnIndex={i}
                style={{
                  width: `${width}px`,
                  height: `${rowHeight}px`,
                  position: "absolute",
                  top: 0,
                  left: `${offset}px`,
                }}
              />,
            );
            offset += width;
          }
          return cells;
        })()}
      </div>
      {children}
    </div>
  ));

type VirtualizedGridProps = {
  columnCountIncludingActions: number;
  columnMinWidth: number;
  rowCountIncludingHeader: number;
  rowHeight: number;
  node: TableNodeT_;
  width: number;
  height: number;
};

const VirtualizedGrid = ({
  columnCountIncludingActions,
  columnMinWidth,
  rowCountIncludingHeader,
  rowHeight,
  node,
  width,
  height,
}: VirtualizedGridProps) => {
  const columnWidth = (index: number) => {
    const actionsWidth = 100;
    if (!node.summaryView && index === columnCountIncludingActions - 1) {
      return actionsWidth;
    }
    const columnCountWithoutActions = node.summaryView
      ? columnCountIncludingActions
      : columnCountIncludingActions - 1;
    const widthWithoutActions = node.summaryView ? width : width - actionsWidth;
    return Math.max(columnMinWidth, Math.floor(widthWithoutActions / columnCountWithoutActions));
  };

  const gridRef = useRef<Grid>(null);

  useEffect(() => {
    if (notNil(gridRef.current)) {
      // Clear react-window's cached offsets in case the window is resized
      gridRef.current.resetAfterIndices({ columnIndex: 0, rowIndex: 0 });
    }
  }, [height, width]);

  return (
    <Grid<TableNodeT_>
      columnCount={columnCountIncludingActions}
      columnWidth={columnWidth}
      height={height}
      rowCount={rowCountIncludingHeader}
      rowHeight={() => rowHeight}
      width={width}
      itemData={node}
      itemKey={makeGridCellKey}
      innerElementType={innerElementType(node, rowHeight, columnCountIncludingActions, columnWidth)}
      ref={gridRef}
    >
      {(props) => {
        // innerElementType renders sticky header so skip it here
        if (props.rowIndex === 0) {
          return null;
        }
        return <GridCell {...props} data={node}></GridCell>;
      }}
    </Grid>
  );
};

type VirtualizedTableProps = Omit<VirtualizedGridProps, "width" | "height">;

/**
 * Scrollable component that renders only the cells visible in the viewport, in order
 * to avoid slowness for large tables.
 */
const VirtualizedTable = (props: VirtualizedTableProps) => {
  return (
    <div className="flex flex-col h-[80vh] w-[80vw]">
      <div className="w-100 h-[30px] bg-gray-50"></div>
      <div className={"grow"}>
        <AutoSizer>
          {({ height, width }) => <VirtualizedGrid {...props} height={height} width={width} />}
        </AutoSizer>
      </div>
      <AddRowButton node={props.node} />
    </div>
  );
};

/**
 * We memoize for efficiency, and also to avoid the loss of internal state
 * during re-renders. See issue #4145.
 */
const MemoizedVirtualizedTable = React.memo(VirtualizedTable);
