import wretch, { ConfiguredMiddleware } from "wretch";

export const authMiddleware: ConfiguredMiddleware = (next) => async (url, opts) => {
  const amplifyUser = await aws_amplify.Auth.currentAuthenticatedUser();
  const authHeader = { Authorization: "Bearer " + amplifyUser.signInUserSession.idToken.jwtToken };
  const response = await next(url, { ...opts, headers: { ...opts.headers, ...authHeader } });

  if (response.status === 401) {
    // await Auth.currentSession();
    // do something with unauthorized requests;
  }

  return response;
};

export const fetch = wretch("/api").middlewares([authMiddleware]);
