import React from "react";
import { Product, ProductId } from "../internal_types";
import { Dropdown } from "./Dropdown";
import { FilterCheckbox } from "./FilterCheckbox";

export type ProductsDropdownProps = {
  label: string;
  productId: ProductId | undefined;
  onSelectProduct: (productId: ProductId | undefined) => void;
  products: Product[];
};

/**
 * Product Dropdown molecule built out of the {@link Dropdown}
 * and {@link FilterCheckbox}
 *
 * Side note: Maybe in the future this should be distilled to a Dropdown component?
 * Probably after the routing is done?
 */
export const ProductDropdown = (props: ProductsDropdownProps): JSX.Element => {
  return (
    <Dropdown label={props.label} itemsChecked={props.productId !== undefined ? 1 : 0}>
      {props.products.map((product) => {
        const checked = product.id == props.productId;
        return (
          <FilterCheckbox
            key={product.id}
            label={product.name}
            name={product.name}
            checked={checked}
            onChange={() => {
              // This logic just toggles on/off when clicking the same product.
              const productChoice = checked ? undefined : product.id;
              props.onSelectProduct(productChoice);
            }}
          />
        );
      })}
    </Dropdown>
  );
};
