import qs from "qs";
import { History } from "history";
import {
  Conditions,
  DateDuration,
  Day,
  Document_,
  Fact,
  GoalsInfo,
  Ident,
  Key_,
  Scalar,
  TransactionPart,
  UMR,
  UUID,
  Value,
} from "./internal_types";
import React, { useEffect, useMemo } from "react";
import { dequal } from "dequal";
import { apiGet, getFile } from "./dal/dal";
import { throwIfAppError } from "./utils/app_error";

export const parseParams = <T extends { [key: string]: string }>(search: string): T =>
  qs.parse(search, { ignoreQueryPrefix: true }) as T;

export function loadScript(src: string): Promise<Event> {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  return new Promise((accept, reject) => {
    script.addEventListener("load", accept);
    script.addEventListener("error", reject);
    document.body.appendChild(script);
  });
}

export function invariant(condition: boolean, message: string): asserts condition {
  if (!condition) {
    throw new Error("invariant violation: " + message);
  }
}

export function hasOwnProperty<
  X extends Record<string | number | symbol, unknown>,
  Y extends PropertyKey,
>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
export abstract class Either<A, B> {
  protected log = false;
  public abstract andThen<C>(f: (v: B) => Either<A, C>): Either<A, C>;
  public abstract map<C>(f: (v: B) => C): Either<A, C>;
  public abstract andMap<C>(f: Either<A, (v2: B) => C>): Either<A, C>;
  public abstract mapError(f: (v: A) => A): Either<A, B>;
  public abstract getOrThrow(): B;
  public abstract getRight(): B | undefined;
  public abstract getLeft(): A | undefined;
  public logValues() {
    this.log = true;
    return this;
  }
  public setLog(b: boolean) {
    this.log = b;
    return this;
  }
}

export class Right<A, B> extends Either<A, B> {
  protected v: B;
  constructor(v: B) {
    super();
    this.v = v;
  }
  public map<C>(f: (v: B) => C): Either<A, C> {
    return new Right(f(this.v));
  }

  public andThen<C>(f: (v: B) => Either<A, C>): Either<A, C> {
    const result = f(this.v);
    if (this.log) {
      console.log(`andThen before:`, this.v, `after: `, result);
    }
    return result.setLog(this.log);
  }

  public andMap<C>(v: Either<A, (v2: B) => C>): Either<A, C> {
    return v.map((f) => f(this.v));
  }

  public getOrThrow() {
    return this.v;
  }

  public getRight() {
    return this.v;
  }
  public getLeft() {
    return undefined;
  }
  public mapError() {
    return this;
  }
}

export function joinEither<A, B>(v: Either<A, Either<A, B>>): Either<A, B> {
  const l = v.getLeft();
  if (l !== undefined) {
    return new Left(l);
  }
  return v.getOrThrow();
}

export function sequenceEither<A, B>(v: Array<Either<A, B>>): Either<A, Array<B>> {
  const result: Array<B> = [];
  let counter = 0;
  for (const either of v) {
    const r = either.getRight();
    if (r !== undefined) {
      result.push(r);
    } else if (either instanceof Left) {
      return either.mapError((x) => `error in list at index ${counter}: ${x}`);
    }
    counter++;
  }
  return new Right(result);
}

export class Left<A, B> extends Either<A, B> {
  protected v: A;
  constructor(v: A) {
    super();
    this.v = v;
  }
  public getLeft(): A | undefined {
    return this.v;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public map<C>(_f: (v: B) => C): Either<A, C> {
    return new Left(this.v);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public andThen<C>(_f: (v: B) => Either<A, C>): Either<A, C> {
    return new Left(this.v);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public andMap<C>(_v: Either<A, (v2: B) => C>): Either<A, C> {
    return new Left(this.v);
  }

  public getOrThrow(): B {
    throw new Error(`${this.v}`);
  }

  public getRight() {
    return undefined;
  }

  public mapError(f: (v: A) => A): Either<A, B> {
    return new Left(f(this.v));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function left(value: any, notA: string): Either<string, any> {
  return new Left(`value ${value} is not a ${notA}`);
}

export function checkValueScalar(value: Value): Either<string, Scalar> {
  if (value.tag === "Scalar") {
    return new Right(value.contents);
  }
  return left(value, "Scalar");
}

export function checkValueConditions(value: Value): Either<string, Conditions> {
  if (value.tag === "Conditions") {
    return new Right(value.contents);
  }
  return left(value, "Conditions");
}

export function checkValueListOrSet(value: Value): Either<string, Array<Value>> {
  if (value.tag === "List") {
    return new Right(value.contents);
  } else if (value.tag === "Set") {
    return new Right(value.contents);
  }
  return left(value, "List or Set");
}

export function checkValueRecord(
  value: Value,
): Either<string, { [key in Ident]: Value | undefined }> {
  if (value.tag === "Record") {
    return new Right(value.contents);
  }
  return left(value, "Record");
}

export function checkScalarBool(value: Scalar): Either<string, boolean> {
  if (value.tag === "Bool") {
    return new Right(value.contents);
  }
  return left(value, "Bool");
}
export function checkScalarText(value: Scalar): Either<string, string> {
  if (value.tag === "Text") {
    return new Right(value.contents);
  }
  return left(value, "Text");
}

export function checkScalarCaselessText(value: Scalar): Either<string, string> {
  if (value.tag === "CaselessText") {
    return new Right(value.contents);
  }
  return left(value, "CaselessText");
}

export function checkScalarUmr(value: Scalar): Either<string, UMR> {
  if (value.tag === "Umr") {
    return new Right(value.contents);
  }
  return left(value, "Umr");
}

export function checkScalarInt(value: Scalar): Either<string, number> {
  if (value.tag === "Int") {
    return new Right(value.contents);
  }
  return left(value, "Int");
}
export function checkScalarNumber(value: Scalar): Either<string, number> {
  if (value.tag === "Number") {
    return new Right(value.contents);
  }
  return left(value, "Number");
}
export function checkScalarIntOrNumber(value: Scalar): Either<string, number> {
  if (value.tag === "Number") {
    return new Right(value.contents);
  } else if (value.tag === "Int") {
    return new Right(value.contents);
  }
  return left(value, "Number");
}

export function checkScalarPercent(value: Scalar): Either<string, number> {
  if (value.tag === "Percent") {
    return new Right(value.contents);
  }
  return left(value, "Percent");
}
export function checkScalarDate(value: Scalar): Either<string, Day> {
  if (value.tag === "Date") {
    return new Right(value.contents);
  }
  return left(value, "Date");
}
export function checkScalarDateDuration(value: Scalar): Either<string, DateDuration> {
  if (value.tag === "DateDuration") {
    return new Right(value.contents);
  }
  return left(value, "DateDuration");
}
export function checkScalarUuid(value: Scalar): Either<string, UUID> {
  if (value.tag === "Uuid") {
    return new Right(value.contents);
  }
  return left(value, "Uuid");
}
export function checkScalarFile(value: Scalar): Either<string, string> {
  if (value.tag === "File") {
    return new Right(value.contents);
  }
  return left(value, "File");
}
export function checkNotUndefined<A>(value: A | undefined, errMsg?: string): Either<string, A> {
  if (value !== undefined) {
    return new Right(value);
  }
  return new Left(errMsg ?? "Value that supposed to be defined is undefined");
}
export function checkNotNull<A>(value: A | null, errMsg?: string): Either<string, A> {
  if (value !== null) {
    return new Right(value);
  }
  return new Left(errMsg ?? "Value that supposed to be defined is null");
}
export function checkAnyString(value: any): Either<string, string> {
  if (value !== null && typeof value === "string") {
    return new Right(value);
  }
  return new Left(`value "${value}" is not a string`);
}

export function checkAnyNumber(value: any): Either<string, number> {
  if (value !== null && typeof value === "number") {
    return new Right(value);
  }
  return new Left(`value "${value}" is not a number`);
}

export function checkAnyListOf<T>(
  value: any,
  checker: (v: any) => Either<string, T>,
): Either<string, T[]> {
  if (Array.isArray(value)) {
    return sequenceEither(value.map(checker));
  }
  return new Left(`value ${value} is not a list`);
}

export function checkValueText(v: Value): Either<string, string> {
  return checkValueScalar(v).andThen(checkScalarText);
}

export function checkValueCaselessText(v: Value): Either<string, string> {
  return checkValueScalar(v).andThen(checkScalarCaselessText);
}

export function checkValueUmr(v: Value): Either<string, UMR> {
  return checkValueScalar(v).andThen(checkScalarUmr);
}

export function checkValuePercent(v: Value): Either<string, number> {
  return checkValueScalar(v).andThen(checkScalarPercent);
}

export function checkValueUuid(v: Value): Either<string, string> {
  return checkValueScalar(v).andThen(checkScalarUuid);
}

export function checkValueFile(v: Value): Either<string, string> {
  return checkValueScalar(v).andThen(checkScalarFile);
}

export function checkValueBool(v: Value): Either<string, boolean> {
  return checkValueScalar(v).andThen(checkScalarBool);
}

export function checkValueNumber(v: Value): Either<string, number> {
  return checkValueScalar(v).andThen(checkScalarIntOrNumber);
}

export function checkValueDocument(v: Value): Either<string, Document_> {
  if (v.tag === "Doc") {
    return new Right(v.contents);
  }
  return left(v, "Document");
}

export function checkValueListOf<T>(
  v: Value,
  checker: (v: Value) => Either<string, T>,
): Either<string, Array<T>> {
  return checkValueListOrSet(v).andThen((vs) => sequenceEither(vs.map(checker)));
}

export function checkValueListScalarOf<T>(
  v: Value,
  checker: (v_: Scalar) => Either<string, T>,
): Either<string, Array<T>> {
  return checkValueListOf(v, (v_) => checkValueScalar(v_).andThen(checker));
}

export function checkRecordOf<T>(
  v: Value,
  checker: (v_: { [key in Ident]: Value | undefined }) => Either<string, T>,
): Either<string, T> {
  return checkValueRecord(v).andThen(checker);
}

type AnyRecord = Record<string | number | symbol, unknown>;

export function checkAnyRecord(value: any): Either<string, AnyRecord> {
  if (value != null && typeof value === "object") {
    const obj = value as AnyRecord;
    return new Right(obj);
  }
  return new Left("value is not an object");
}

export function checkRecordValues<T>(
  record: AnyRecord,
  checker: (v: any) => Either<string, T>,
): Either<string, Record<string | number | symbol, T>> {
  let result: Either<string, Record<string | number | symbol, T>> = new Right({});
  Object.entries(record).map(([key, value]) => {
    const converted = checker(value).mapError(
      (x) => `error in ${key} for value ${value} in record ${JSON.stringify(record)}: ${x}`,
    );
    result = converted.andThen((x) =>
      result.map((obj) => {
        obj[key] = x;
        return obj;
      }),
    );
  });
  return result;
}

export function checkRecordHasProperty<Y extends number | string | symbol>(
  value: AnyRecord,
  key: Y,
): Either<string, Record<Y, unknown>> {
  if (hasOwnProperty(value, key)) {
    return new Right(value);
  }
  return new Left(`record does not have key ${value}`);
}

export function getValueFromNonListDatapoint(
  fields: GoalsInfo,
  name: Ident,
): Either<string, Value | undefined> {
  const quoteFields = fields.quoteGoal;
  const policyFields = fields.submissionGoal;
  return checkNotUndefined(
    quoteFields.keys.find((ki) => ki.name === name) ??
      policyFields.keys.find((ki) => ki.name === name),
  )
    .andThen((ki) => checkNotUndefined(ki.datapoints[0]))
    .map((dp) => dp.value);
}

export function assertNever(value: never): never {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function humanFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

export const fromEvent: (
  f: React.Dispatch<React.SetStateAction<string>>,
) => (e: React.ChangeEvent<HTMLInputElement>) => void = (f) => (e) => f(e.currentTarget.value);

export const createSingleKey = (key: Ident): Key_<Scalar> => ({ key: key, arguments: [] });

export const createKeyWithArgs = (key: Ident, args: Array<Scalar>): Key_<Scalar> => ({
  key: key,
  arguments: args,
});

export const createTransactionPart = (key: Key_<Scalar>, fact: Fact): TransactionPart => ({
  key: key,
  fact: fact,
});

export const createAdditionFact = (value: Scalar): Fact => ({
  tag: "Addition",
  contents: value,
});
export const createRetractionSingleFact = (value: Scalar): Fact => ({
  tag: "RetractionSingle",
  contents: value,
});
export const createRetractionAllFact = (): Fact => ({ tag: "RetractionAll" });

export const createScalarBool = (value: boolean): Scalar => ({ tag: "Bool", contents: value });
export const createScalarText = (value: string): Scalar => ({ tag: "Text", contents: value });
export const createScalarCaselessText = (value: string): Scalar => ({
  tag: "CaselessText",
  contents: value,
});
export const createScalarUmr = (value: UMR): Scalar => ({
  tag: "Umr",
  contents: value,
});
export const createScalarInt = (value: number): Scalar => ({ tag: "Int", contents: value });
export const createScalarNumber = (value: number): Scalar => ({ tag: "Number", contents: value });
export const createScalarPercent = (value: number): Scalar => ({ tag: "Percent", contents: value });
export const createScalarDate = (value: Day): Scalar => ({ tag: "Date", contents: value });
export const createScalarDateDuration = (value: DateDuration): Scalar => ({
  tag: "DateDuration",
  contents: value,
});
export const createScalarUuid = (value: UUID): Scalar => ({ tag: "Uuid", contents: value });
export const createScalarFile = (value: string): Scalar => ({ tag: "File", contents: value });

export const toNumber = (s: string): number | null => {
  if (s.length === 0) {
    return null;
  }
  const possibleNumber = +s;
  return isNaN(possibleNumber) ? null : possibleNumber;
};

export const toNumberEither = (s: string): Either<string, number> => {
  const result = toNumber(s);
  if (result === null) return new Left(`${s} is not a number`);
  return new Right(result);
};

export const toNonEmptyString = (s: string): string | null => {
  return s.trim() === "" ? null : s;
};

export type PromiseReturnType<T extends Promise<any>> = T extends Promise<infer R> ? R : never;

export function filterMap<T, S>(v: Array<T>, f: (v: T) => S | undefined): Array<S> {
  return v.flatMap((x) => {
    const a = f(x);
    return a !== undefined ? [a] : [];
  });
}

export function joinClasses(...classes: string[]) {
  return classes.filter((c) => c !== "").join(" ");
}

export function classNames(...classes: string[]): { className: string } {
  return { className: joinClasses(...classes) };
}

export function optionalClass(predicate: boolean, ...classes: string[]) {
  return predicate ? joinClasses(...classes) : "";
}

export function getClass(className: string, classNames: string) {
  return classNames !== ""
    ? classNames
        .split(" ")
        .filter((cn) => cn.indexOf(className) > -1)
        .join(" ")
    : "";
}

export const useQueryParamsState = (
  key: string,
  query: URLSearchParams,
  history: History<unknown>,
): [string[], (v: string[]) => void] => {
  const [s, setS] = React.useState<string[]>(query.getAll(key) ?? []);
  React.useEffect(() => {
    history.listen((location) => {
      const newQuery = new URLSearchParams(location.search);
      const newS_ = newQuery.getAll(key);
      if (newS_ !== s) {
        setS(newS_);
      }
    });
  }, [history]);
  return [
    s,
    (newS) => {
      query.delete(key);
      newS.map((i) => query.append(key, i));
      history.push({ search: query.toString() });
      setS(newS);
    },
  ];
};

export const useQueryParamState = <T extends string | number | boolean | undefined>(
  key: string,
  query: URLSearchParams,
  history: History<unknown>,
  fromString: (v: string | undefined) => T,
  initialValue?: string,
): [T, (v: T) => void] => {
  const v = fromString(query.get(key) ?? initialValue);
  const [s, setS] = React.useState<T>(v);
  React.useEffect(() => {
    history.listen((location) => {
      const newQuery = new URLSearchParams(location.search);
      const newS_ = newQuery.get(key);
      // If there's no change, don't do anything.
      if (newS_ !== s) {
        // If the parameter has been removed, then assign the
        // default value, if any. If there's no default value,
        // this acts like setting it to nothing.
        if (newS_ === undefined || newS_ === null) {
          setS(fromString(initialValue));
        }
        // If the value is there and differs, apply it.
        else {
          setS(fromString(newS_));
        }
      }
    });
  }, [history]);
  return [
    s,
    (newS) => {
      query.delete(key);
      newS !== undefined && query.append(key, newS.toString());
      history.push({ search: query.toString() });
      setS(newS);
    },
  ];
};

export const useCurrentTime = () => {
  const [dateTime, setDateTime] = React.useState(new Date());

  React.useEffect(() => {
    const id = setInterval(() => setDateTime(new Date()), 5000); //every 30s
    return () => {
      clearInterval(id);
    };
  }, []);

  return dateTime;
};

export const unique = <T>(v: T[]): T[] => {
  return [...new Set(v)];
};

/**
 * Typesafe for each function for objects
 * @param obj object that we map over
 * @param f function that we call on every key and value
 *
 * K only extends string because Object.entries always returns strings
 * regardless of it's actual type (ie they could be numbers in typescript
 * but in reality they would be strings)
 */
export const objectTypesafeForEach = <K extends string, T>(
  obj: Record<K, T>,
  f: (k: K, v: T) => void,
): void => {
  Object.entries<T>(obj).forEach(([k, v]) => {
    f(k as K, v);
  });
};

export const scalarEqual = (a: Scalar, b: Scalar): boolean => {
  return dequal(a, b);
};

export const listEqual = <T>(a: T[], b: T[], f: (x: T, y: T) => boolean): boolean => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (!f(a[i], b[i])) return false;
  }
  return true;
};

type EitherObjectIteratorShape = {
  [key in string]: ((v: any) => Either<string, any>) | EitherObjectIteratorShape;
};

/**
 * Type used to iterate over the Shape.
 * It will convert:
 * ```typescript
 * //from
 * {a: any => Either<string, string>, b: {x: any => Either<string, number>}}
 * //to
 * {a: string, b: {x: number}}
 * ```
 */
type EitherObjectIterator<Shape> = Shape extends (v: any) => Either<string, infer V>
  ? EitherObjectIterator<V>
  : Shape extends EitherObjectIteratorShape
  ? { [key in keyof Shape]: EitherObjectIterator<Shape[key]> }
  : Shape;

/**
 * The function that generically decodes any record into the shape we want
 *
 * @example
 * ```typescript
 *
 * const schema = {
 *   a: checkAnyString,
 *   b: checkAnyNumber,
 *   c: {
 *     x: checkAnyNumber
 *   }
 * }
 *
 * const v: Record<string, any> = {a: "test", b: 1, c: { x: 2 }}
 *
 * const x: Either<string, {a: string, b: number, c:{x: number}}> = sequenceEitherTypesafeObject(v, schema);
 * ```
 *
 */
export function sequenceEitherTypesafeObject<T extends EitherObjectIteratorShape>(
  record: Record<string, any>,
  v: T,
): Either<string, EitherObjectIterator<T>> {
  let result: Either<string, Record<string, any>> = new Right({});
  objectTypesafeForEach(v, (key, value) => {
    if (record[key] === undefined) {
      result = new Left(`could not find key ${key} in record ${JSON.stringify(record)} `);
    } else {
      if (typeof value === "function") {
        const converted = value(record[key]).mapError(
          (x) => `error in key ${key} in record ${JSON.stringify(record)}: ${x}`,
        );
        result = converted.andThen((right) =>
          result.map((obj) => {
            obj[key] = right;
            return obj;
          }),
        );
      } else if (typeof value === "object") {
        const convertedKey = checkAnyRecord(record[key]).mapError(
          (x) => `error in ${key} in record ${JSON.stringify(record)}: ${x}`,
        );
        result = convertedKey.andThen((newR) =>
          sequenceEitherTypesafeObject(newR, value)
            .mapError((x) => `error in ${key} in record ${JSON.stringify(record)}: ${x}`)
            .andThen((convertedRecord) =>
              result.map((obj) => {
                obj[key] = convertedRecord;
                return obj;
              }),
            ),
        );
      }
    }
  });
  return result as Either<string, EitherObjectIterator<T>>;
}

const locale = { name: "en-GB", options: { style: "currency", currency: "GBP" } };

export function formatCurrency(value: number): string {
  return value.toLocaleString(locale.name, locale.options);
}

export function formatNumber(x: number): string {
  return x.toLocaleString("en-GB");
}

export function partitionMap<A, B, C>(xs: A[], f: (x: A) => Either<B, C>): [B[], C[]] {
  const lefts = [];
  const rights = [];

  for (const val of xs) {
    const either = f(val);
    const left = either.getLeft();
    const right = either.getRight();
    if (left !== undefined) {
      lefts.push(left);
    }
    if (right !== undefined) {
      rights.push(right);
    }
  }

  return [lefts, rights];
}

export function zip<A, B>(xs: A[], ys: B[]): [A, B][] {
  const len = Math.min(xs.length, ys.length);
  const res: [A, B][] = [];
  for (let i = 0; i < len; ++i) {
    res.push([xs[i], ys[i]]);
  }
  return res;
}

/*
 * Function that parses string into an email
 * Taken from {@link https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression}
 */
export function parseEmail(s: string): Either<string, string> {
  const emailRegexp =
    /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const result = s.match(emailRegexp);

  if (result === null) {
    return new Left("Unable to parse email");
  }
  return new Right(result[0]);
}

export function parseUmr(s: string): Either<string, UMR> {
  if (s.length === 0 || s.charAt(0) !== "B") {
    return new Left("Invalid UMR: Must begin with a 'B'.");
  }
  const brokerId = s.substring(1, 5);

  if (brokerId.length !== 4) {
    return new Left("Invalid UMR: broker id must be 4 characters.");
  }

  if (!/^\d+$/.test(brokerId)) {
    return new Left("Invalid UMR: broker id must be composed of digits.");
  }

  const extraData = s.substring(5);

  if (extraData.length > 12) {
    return new Left("Invalid UMR: extra data longer than 12 chars.");
  }

  if (!/^[0-9a-zA-Z]*$/.test(extraData)) {
    return new Left("Invalid UMR: extra data must be alphanumeric.");
  }

  return new Right({ brokerId, extraData });
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const downloadFile = (blob: Blob, filename: string) => {
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

export const downloadFileById = async (hash: string) => {
  const fileInfo = await apiGet("/api/file/{hash}", {
    params: {
      hash: hash,
    },
    query: {},
  });
  throwIfAppError(fileInfo);
  const blob = await getFile(fileInfo.value.id);
  downloadFile(blob, fileInfo.value.name);
};

export function clamp(x: number, low: number, high: number): number {
  return Math.max(Math.min(x, high), low);
}

export function scalarToText(s: Scalar): Either<string, string> {
  switch (s.tag) {
    case "Text":
      return new Right(s.contents);
    case "CaselessText":
      return new Right(s.contents);
    case "Umr":
      return new Right(`B${s.contents.brokerId}${s.contents.extraData}`);
    case "Int":
      return new Right(s.contents.toString());
    case "Number":
      return new Right(s.contents.toString());
    case "Bool":
      return new Right(s.contents ? "yes" : "no");
    case "Date":
      return new Right(s.contents);
    case "Percent":
      return new Right(s.contents.toString() + "%");
  }
  return new Left("Unhandled case in scalarToText " + s.tag);
}

/**
 * @returns An {@link AbortController} on which {@link AbortController#abort} is called when the component unmounts.
 *          The {@link AbortController} is one-use, see {@link https://stackoverflow.com/questions/51091109/how-can-i-start-another-request-after-abortcontroller-abort}.
 */
export const useCancelOnUnmount = (): AbortController => {
  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);
  return abortController;
};

export function isNil<T>(x: T | undefined | null): x is undefined | null {
  return x === undefined || x === null;
}

export function notNil<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}
