import { TabPanel } from "../design_system/TabPanel";
import { Tabs } from "../design_system/Tabs";
import { CanDownloadSpec, CanExportPolicies, CanCreateProducts } from "./policy/permissions";
import { Page, PageHeader, PageTitle, usePageTitle } from "../layout";
import {
  downloadProductSpecification,
  useGetCurrentUser,
  useGetProducts,
  useGetProductPolicyCounts,
  useGetPendingProducts,
} from "../dal/dal";
import { LocationDescriptor } from "history";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "/src/design_system/Button";
import { Product } from "../internal_types";
import { Routes } from "../routing/routes";
import { downloadFile } from "/src/utils";
import { Table, TD, THead, TR, TH, TBody } from "../design_system/Table";
import ExportPoliciesButton from "../components/ExportPoliciesButton";
import CreateProductButton from "../components/CreateProductButton";
import { CogIcon, DownloadIcon, ExternalLinkIcon } from "@heroicons/react/solid";
import { throwIfAppError } from "/src/utils/app_error";

const ProductsOverview: React.FC = () => {
  const data = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(data);

  const pendingData = useGetPendingProducts();
  throwIfAppError(pendingData);
  const policyCounts = useGetProductPolicyCounts();
  usePageTitle("Products");

  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const constantState = { currentRole: currentUser.value.role };
  const canExportPolicies = CanExportPolicies(constantState);
  const canCreateProducts = CanCreateProducts(constantState);
  const history = useHistory();

  const [whichTab, setTab] = React.useState("Live");

  return (
    <Page>
      <PageHeader>
        <PageTitle>Products</PageTitle>
        <div className="flex-1" />
        <CreateProductButton canCreateProducts={canCreateProducts}></CreateProductButton>
        <ExportPoliciesButton canExportPolicies={canExportPolicies}></ExportPoliciesButton>
      </PageHeader>

      <div className="px-8 pb-4">
        <Tabs tabs={["Live", "Pending"]} selected={whichTab} onSelect={(rule) => setTab(rule)} />
      </div>
      <TabPanel index={"Live"} value={whichTab}>
        <div data-testid="product-container" className="overflow-y-auto px-8">
          <Table>
            <THead>
              <TR>
                <TH label="Name" />
                <TH label="Version" />
                <TH label="Policies" />
                <TH label="Created" />
                <TH label="" />
              </TR>
            </THead>
            <TBody>
              {data.value.products.slice().map((product) => {
                return (
                  <TR className="hover:bg-gray-50" key={product.id}>
                    <TD>
                      <span data-testid="product-name">{product.name}</span>
                      <span className="ml-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        <svg
                          className="mr-1.5 h-2 w-2 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 8 8"
                        >
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                        Live
                      </span>
                    </TD>
                    <TD>{product.version}</TD>
                    <TD>{policyCounts[product.id]}</TD>
                    <TD>{new Date(product.createdAt).toLocaleString()}</TD>
                    <TD>
                      <div className="flex flex-row space-x-3 justify-end">
                        <Button
                          data-testid="goto-product-policies"
                          type="button"
                          variant="secondary"
                          size="xs"
                          onClick={() => {
                            history.push(
                              Routes.policies.generatePath({ query: { productId: product.id } }),
                            );
                          }}
                        >
                          <ExternalLinkIcon className="h-6 w-6 mr-2 p-1" />
                          View policies
                        </Button>
                        <Button
                          data-testid="settings-button"
                          type="button"
                          variant="secondary"
                          aria-hidden="true"
                          onClick={() => {
                            history.push(
                              Routes.product.generatePath({ capture: { productId: product.id } }),
                            );
                          }}
                        >
                          <CogIcon className="h-6 w-6 mr-2 p-1" />
                          Settings
                        </Button>
                      </div>
                    </TD>
                  </TR>
                );
              })}
            </TBody>
          </Table>
        </div>
      </TabPanel>
      <TabPanel index={"Pending"} value={whichTab}>
        <div data-testid="pending-product-container" className="overflow-y-auto px-8">
          <Table>
            <THead>
              <TR>
                <TH label="Name" />
                <TH label="Created" />
                <TH label="Modified" />
                <TH label="" />
              </TR>
            </THead>
            <TBody>
              {pendingData.value.pendingProducts.slice().map((pendingProduct) => {
                return (
                  <TR className="hover:bg-gray-50" key={pendingProduct.id}>
                    <TD>
                      <span data-testid="product-name">{pendingProduct.name}</span>
                    </TD>
                    <TD>{new Date(pendingProduct.createdAt).toLocaleString()}</TD>
                    <TD>{new Date(pendingProduct.modifiedAt).toLocaleString()}</TD>
                    <TD>
                      {canCreateProducts.check(undefined) && (
                        <Button
                          data-testid="settings-button"
                          type="button"
                          variant="secondary"
                          aria-hidden="true"
                          onClick={() => {
                            history.push(
                              Routes.pendingProduct.generatePath({
                                capture: { pendingProductId: pendingProduct.id },
                              }),
                            );
                          }}
                        >
                          <CogIcon className="h-6 w-6 mr-2 p-1" />
                          Settings
                        </Button>
                      )}
                    </TD>
                  </TR>
                );
              })}
            </TBody>
          </Table>
        </div>
      </TabPanel>
    </Page>
  );
};

export const ProductOverview = (props: {
  product: Product;
  onClick?: (ev: React.MouseEvent<unknown, MouseEvent>) => void;
  path?: LocationDescriptor;
  outlined?: boolean;
  hideDownloadSpec?: boolean;
}): JSX.Element => {
  const { product } = props;
  const history = useHistory();
  const user = useGetCurrentUser();
  throwIfAppError(user);

  const cs = { currentRole: user.value.role };
  const canDownloadSpec =
    (props.hideDownloadSpec !== undefined && !props.hideDownloadSpec) ||
    CanDownloadSpec(cs).check(undefined);
  return (
    <a
      key={product.id}
      className={`group relative rounded-lg bg-white transition ease-in-out px-6 py-6 border-2 border-gray-200 flex items-center  cursor-pointer ${
        props.outlined ? "border-blue-500" : ""
      }`}
      onClick={(ev) => {
        if (props.onClick !== undefined) {
          props.onClick(ev);
        } else if (props.path !== undefined) {
          history.push(props.path);
        }
      }}
    >
      <div className="flex-1 min-w-0">
        <div className={`focus:outline-none`}>
          {/* all products will be "Live" by virtue of being shown here at the moment */}
          <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <svg className="mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
            Live
          </span>
          <div className="text-base pb-1 capitalize break-all font-semibold mt-3 text-gray-900">
            {product.name}
          </div>
          <div className="text-sm max-w-prose mb-4 text-gray-500 group-hover:text-gray-600 hidden lg:block">
            {product.description ?? ""}
          </div>
          <div className="flex">
            <div className="text-xs flex-initial font-medium text-left text-gray-500 group-hover:text-gray-500">
              {/* TODO: pull in version dynamically per product stored in product meta */}
              Version {product.version}
            </div>
            <div className="text-xs flex-1 float-right font-normal text-right text-gray-400 group-hover:text-gray-500 hidden lg:block">
              Created {new Date(product.createdAt).toLocaleString()}
            </div>
          </div>
          {canDownloadSpec && (
            <span
              className="absolute top-6 right-16 text-gray-300 transition ease-in-out hover:text-blue-500"
              aria-hidden="true"
              onClick={async (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const blob = await downloadProductSpecification(product.id);
                downloadFile(blob, `${product.name}.zip`);
              }}
            >
              <DownloadIcon className="h-6 w-6" />
            </span>
          )}
        </div>
      </div>
    </a>
  );
};

export default ProductsOverview;
