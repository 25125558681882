import * as React from "react";
import * as apiTypes from "./api_types";

export const ConfigContext = React.createContext<
  null | apiTypes.components["schemas"]["FrontendConfig"]
>(null);

export const useConfig = () => {
  const config = React.useContext(ConfigContext);
  if (config === null) {
    throw new Error("Error: No config set, use ConfigContext.Provider to set one");
  }
  return config;
};
