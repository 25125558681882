import { throwIfAppError } from "/src/utils/app_error";
import { getDatapointId } from "/src/data_editor/builder";
import { PolicyId, ProductId, QuoteId, UnifiedTaskId } from "/src/internal_types";
import { getQueryKey, queryClient, useAsyncsForProductPolicy } from "/src/dal/dal";
import React from "react";
import { objectTypesafeForEach } from "./utils";

export type AsyncTaskState =
  | { tag: "NotRunning" }
  | { tag: "Running"; started: string; unifiedTaskId: UnifiedTaskId }
  | { tag: "Completed"; started: string; ended: string; unifiedTaskId: UnifiedTaskId }
  | { tag: "Failed"; reason: string; unifiedTaskId: UnifiedTaskId };

// Opaque type so that we can evolve this over time -- it is spread
// over a wide surface area of call sites.
export type Asyncs = {
  [key in string]: { [quoteId in QuoteId]: AsyncTaskState[] };
};

// it's used as a key for the submission asyncs
const SUBMISSION_QUOTE_ID = -1;

const getQuoteId = (mquoteId: number | undefined): QuoteId => {
  return mquoteId ?? SUBMISSION_QUOTE_ID;
};

export const getAsyncForDatapoint = (
  asyncs: Asyncs,
  datapoint: string,
  mquoteId?: QuoteId,
): AsyncTaskState[] => {
  const allAsyncsForDatapoint = asyncs[datapoint] ?? {};
  return allAsyncsForDatapoint[getQuoteId(mquoteId)] ?? [];
};

export const useMappedAsyncTasks = (productId: ProductId, policyId: PolicyId): Asyncs => {
  // Collect sets of data
  const asyncs = useAsyncsForProductPolicy(productId, policyId);
  throwIfAppError(asyncs);

  const mapRef = React.useRef<Asyncs>();

  // Create a dictionary of datapoint key to AsyncTaskStates
  const map: Asyncs = {};
  asyncs.value.map(({ ident, taskId, scheduledAt, status, mquoteId }) => {
    const datapointId = getDatapointId(ident, []);
    if (!(datapointId in map)) {
      map[datapointId] = {};
    }
    const quoteId = getQuoteId(mquoteId);
    if (!(quoteId in map[datapointId])) {
      map[datapointId][quoteId] = [];
    }

    const state: AsyncTaskState = (() => {
      switch (status.tag) {
        case "Running":
          return {
            tag: "Running",
            started: scheduledAt,
            unifiedTaskId: {
              tag: "SequelHubTaskId",
              contents: taskId,
            },
          };
        case "Completed":
          return {
            tag: "Completed",
            started: scheduledAt,
            ended: status.contents,
            unifiedTaskId: {
              tag: "SequelHubTaskId",
              contents: taskId,
            },
          };
        case "Failed":
          return {
            tag: "Failed",
            reason: status.contents,
            unifiedTaskId: {
              tag: "SequelHubTaskId",
              contents: taskId,
            },
          };
      }
    })();

    map[datapointId][quoteId].push(state);
  });

  // invalidation for policy and quote queries
  let shouldUpdatePolicy = false;
  const quotesToUpdate = new Set<QuoteId>();

  mapRef.current !== undefined &&
    objectTypesafeForEach(mapRef.current, (datapoint, quoteIdMap) => {
      Object.entries(quoteIdMap).forEach(([quoteIdOrPolicyIdString, oldAsyncs]) => {
        const quoteIdOrPolicyId = Number(quoteIdOrPolicyIdString);
        const mquoteId = quoteIdOrPolicyId === SUBMISSION_QUOTE_ID ? undefined : quoteIdOrPolicyId;
        const currentAsyncs = getAsyncForDatapoint(map, datapoint, mquoteId);
        if (
          // we have a new async
          currentAsyncs.length !== oldAsyncs.length ||
          // the last async changed it status to "Completed"
          (currentAsyncs.length > 0 &&
            currentAsyncs[currentAsyncs.length - 1].tag === "Completed" &&
            // this is safe because currentAsyncs.length === oldAsyncs.length
            oldAsyncs[currentAsyncs.length - 1].tag !== "Completed")
        ) {
          if (mquoteId === undefined) {
            shouldUpdatePolicy = true;
          } else {
            quotesToUpdate.add(mquoteId);
          }
        }
      });
    });

  if (shouldUpdatePolicy) {
    // we let the promise run in the background
    void queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}", {
        params: { productId, policyId },
        query: {},
      }),
    );
  }
  quotesToUpdate.forEach((quoteId) => {
    // we let the promise run in the background
    void queryClient.invalidateQueries(
      getQueryKey("/api/product/{productId}/policy/{policyId}/quote/{quoteId}", {
        params: { productId, policyId, quoteId },
        query: {},
      }),
    );
  });

  mapRef.current = map;

  return map;
};
