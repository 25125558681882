import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { cancelPolicy } from "/src/dal/dal";
import { Button } from "/src/design_system/Button";
import { Loader } from "/src/design_system/Loader";
import { Modal } from "../../design_system/Modal";
import { PolicyInfo } from "/src/internal_types";
import { formatISO } from "date-fns";
import { Routes } from "/src/routing/routes";
import { ToastIcons, useToasts } from "/src/components/Toast";

type CancellationModalProps = {
  policy: PolicyInfo;
  isOpen: boolean;
  close: () => void;
};

export const CancellationModal = (props: CancellationModalProps): JSX.Element => {
  const currentDate = new Date();
  const [cancellationDate, setCancellationDate] = React.useState(
    `${formatISO(currentDate).substring(0, "2020-01-01".length)}`, // "2020-01-01".length is just a way of getting the lenght of properly formatted date
  );
  const [isAwaiting, setAwaiting] = React.useState(false);
  const [error, setError] = React.useState("");
  const history = useHistory();
  const addToast = useToasts();

  const close = () => {
    setError("");
    setAwaiting(false);
    props.close();
  };

  const goToPolicies = () => {
    history.push(Routes.policies.generatePath({ query: { productId: props.policy.productId } }));
  };

  const submitCancellation = async () => {
    setAwaiting(true);
    const newDate = new Date(cancellationDate);
    if (isNaN(newDate.getTime())) {
      setError(
        cancellationDate === ""
          ? "Cancellation date is missing"
          : "Incorrect cancellation date format",
      );
      setAwaiting(false);
      return;
    }
    await cancelPolicy(props.policy.productId, props.policy.id, cancellationDate).then((e) => {
      if (e.status === "success") {
        setAwaiting(false);
        setError("");
        goToPolicies();
        addToast(`cancel-policy-${props.policy.productId}-${props.policy.id}`, {
          icon: ToastIcons.success,
          dismissCaption: "Dismiss",
          description: `Policy #${props.policy.id} has been cancelled successfully.`,
          title: `Policy #${props.policy.id}`,
        });
      } else {
        setError(e.value.message);
        setAwaiting(false);
      }
    });
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.close}>
      <div className="px-1 py-2 border border-gray-400 shadow-sm">
        <div className="flex">
          <div className="flex-1 w-60"></div>
          <div className="flex-1 w-60"></div>
        </div>
        <div className="px-6">
          <div className="mt-3">
            <h3 className="text-xl font-semibold self-centered">Cancel policy</h3>
            <div className="mt-2">
              <p className="text-m text-gray-500">
                Are you sure you want to cancel this policy? This action cannot be undone.
              </p>
            </div>
            <div className="pt-5">
              <label className="font-semibold text-gray-500"> Cancellation date</label>
              <div>
                <NumberFormat
                  format="####-##-##"
                  mask="_"
                  value={cancellationDate}
                  onValueChange={(e) => setCancellationDate(e.formattedValue)}
                  className="rounded w-full text-sm border-gray-300 py-2"
                />
              </div>
            </div>
          </div>
          <div className="mt-5 mb-4 sm:mt-6 sm:grid sm:grid-cols-5 sm:gap-2">
            <div className="col-span-3">{error}</div>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={submitCancellation} disabled={isAwaiting} variant="primary">
              {isAwaiting ? <Loader size="sm" color="white" /> : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
