import React from "react";
import { Page, usePageTitle } from "/src/layout";
import { AggregationForm, NewAggregation } from "./aggregations";
import { useGetProducts, apiPost, invalidatePath, useGetCurrentUser } from "/src/dal/dal";
import { CanAddAggregation } from "../policy/permissions";
import { throwIfAppError } from "/src/utils/app_error";
import NotFound from "../404";

export function CreateAggregation() {
  usePageTitle("Create aggregation");

  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const canAddAggregation = CanAddAggregation({ currentRole: currentUser.value.role }).check(
    undefined,
  );

  if (!canAddAggregation) {
    return <NotFound />;
  }

  const products = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(products);

  const onSubmit = async (newAggregation: NewAggregation) => {
    const create = (async () => {
      if (newAggregation.type === "Primitive") {
        const resp = await apiPost("/api/stats/primitive-aggregation", {
          params: {},
          query: {},
          body: newAggregation,
        });
        await invalidatePath("/api/stats/primitive-aggregation");
        if (resp.status === "error") {
          return resp.value.message;
        }
      } else {
        const resp = await apiPost("/api/stats/aggregation", {
          params: {},
          query: {},
          body: newAggregation,
        });
        await invalidatePath("/api/stats/aggregation");
        if (resp.status === "error") {
          return resp.value.message;
        }
      }
      return undefined;
    })();
    return await create;
  };

  return (
    <Page>
      <AggregationForm
        title="Add aggregation"
        onSubmit={onSubmit}
        products={products.value.products}
        initialFields={{}}
        productEditable={true}
        functionEditable={true}
      />
    </Page>
  );
}
