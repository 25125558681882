import React, { FunctionComponent, Suspense } from "react";
import { useTitle } from "react-use";
import * as Dal from "/src/dal/dal";
import { Sidebar } from "/src/header";
import { Loader } from "./design_system/Loader";

type LayoutProps = {
  className?: string;
  withSidebar?: boolean;
  pageTitle: string[];
};

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const deployment = Dal.useGetDeployment();
  if (deployment.status === "error") {
    throw deployment;
  }
  useTitle([...props.pageTitle, deployment.value.env, "Brossa"].join(" - "));
  return (
    <React.Fragment>
      <div className="h-screen w-screen flex">
        <Sidebar />
        <Suspense
          fallback={
            <div className="h-screen w-screen flex items-center justify-center">
              <Loader size="lg" />
            </div>
          }
        >
          <div className="flex-1 h-screen overflow-x-auto">{props.children}</div>
        </Suspense>
      </div>
    </React.Fragment>
  );
};

export function usePageTitle(...title: string[]) {
  const deployment = Dal.useGetDeployment();
  if (deployment.status === "error") {
    throw deployment;
  }
  useTitle([...title, deployment.value.env, "Brossa"].join(" - "));
}

// TOOD: investigate weird Vite bug with TypeScript namespaces
// https://github.com/vitejs/vite/issues/3900

export function Page(props: { children: React.ReactNode }) {
  return <div className="h-screen bg-white flex flex-col">{props.children}</div>;
}

export function PageHeader(props: { children: React.ReactNode }) {
  return <div className="px-8 py-6 flex">{props.children}</div>;
}

export function PageTitle(props: { children: React.ReactNode }) {
  return <div className="text-2xl font-bold">{props.children}</div>;
}

export function PageContent(props: { children: React.ReactNode }) {
  return <div className="overflow-y-scroll flex-1">{props.children}</div>;
}

export default Layout;
