import { Transition } from "@headlessui/react";
import { MinusIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import { Path } from "../routing/routing";
import { Badge } from "./Badge";

export type AccordionEntry = {
  title: string;
  count: number;
};

export type AccordionButtonProps = {
  isOpen: boolean;
  selected: boolean;
  to: Path;
  mainEntry: AccordionEntry;
  subentries: AccordionEntry[];
};

export const AccordionButton = (props: AccordionButtonProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className={clsx([props.selected ? "bg-gray-200" : "hover:bg-gray-200"])}>
      <div className={clsx(["flex justify-between"])}>
        <Link
          to={props.to}
          className={clsx(
            "py-2.5 font-medium flex gap-3 group cursor-pointer items-center select-none flex-1",
            props.isOpen ? "px-5 " : "px-5",
          )}
        >
          <Badge small label={props.mainEntry.count.toString()} color="white" />
          {props.isOpen && (
            <div className="flex gap-1">
              <span className={clsx(["font-medium"])}>{props.mainEntry.title}</span>
            </div>
          )}
        </Link>

        {props.isOpen && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={clsx(["relative w-12", "hover:bg-gray-300"])}
          >
            <MinusIcon
              className={clsx(
                "transition ease-out duration-300 absolute right-4 top-3 w-4 h-4 text-gray-500",
                !isExpanded && "-rotate-180",
              )}
            />
            <MinusIcon
              className={clsx(
                "transition ease-out duration-300 absolute right-4 top-3  w-4 h-4 text-gray-500",
                !isExpanded && "-rotate-90",
              )}
            />
          </button>
        )}
      </div>
      {props.isOpen && (
        <Transition
          show={isExpanded}
          className="overflow-hidden"
          enter="transition-all transition-[height] duration-300 ease-in"
          enterFrom="transform h-0"
          enterTo="transform h-28"
          leave="transition-all transition-[height] duration-300 ease-in"
          leaveFrom="transform h-28"
          leaveTo="transform h-0"
        >
          <div className="space-y-2 py-2 px-5">
            {props.subentries.map((subentry) => {
              return (
                <div
                  className={clsx([
                    "flex justify-between",
                    "text-gray-500",
                    "font-normal leading-4",
                  ])}
                >
                  <span>{subentry.title}</span>
                  <span> {subentry.count}</span>
                </div>
              );
            })}
          </div>
        </Transition>
      )}
    </div>
  );
};
