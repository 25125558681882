import { prettyPrintTimeString } from "/src/utils/time";
import React from "react";
import { Page, PageContent, PageHeader, PageTitle } from "/src/layout";
import {
  decodeTaskId,
  encodeTaskId,
  encodeTaskRunId,
  getActionFromSuccess,
  taskRunIdEquality,
  getInspectorFromSuccess,
  RequestViewer,
} from "/src/components/TaskProvider";
import { useGetTaskById } from "/src/dal/dal";
import { Box } from "/src/design_system/Box";
import { Routes } from "/src/routing/routes";
import { useParams } from "/src/routing/routing";
import { throwIfAppError } from "/src/utils/app_error";
import { TaskRun, TaskRunId, TaskSummary } from "/src/internal_types";
import clsx from "clsx";
import { getTaskLabels } from "./list";
import { useHistory } from "react-router";

export const TaskRuns = (): JSX.Element => {
  const {
    data: {
      capture: { taskTag, taskId: taskId_ },
    },
  } = useParams(Routes.taskRuns);
  const taskId = decodeTaskId(taskTag, taskId_);
  const taskSummaryList = useGetTaskById(taskId);
  throwIfAppError(taskSummaryList);

  if (taskSummaryList.value[0] == undefined) {
    return (
      <Page>
        <PageHeader>
          <PageTitle>Missing task</PageTitle>
        </PageHeader>
        <PageContent>
          <div className="px-8 space-y-4">
            Either the task doesn't exist or you don't have access to view it.
          </div>
        </PageContent>
      </Page>
    );
  } else {
    const taskSummary = taskSummaryList.value[0];

    const taskRuns = React.useMemo(() => {
      let taskRuns_: TaskRun[] = taskSummary.finishedRuns;
      if (taskSummary.status.tag === "Started") {
        taskRuns_ = taskRuns_.concat(taskSummary.status.contents);
      }
      return taskRuns_.sort((t1, t2) => {
        return t2.startedAt.localeCompare(t1.startedAt);
      });
    }, [taskSummary]);

    const [selectedTaskRunId0, setSelectedTaskRunId] = React.useState<TaskRunId>();

    const selectedTaskRunId =
      selectedTaskRunId0 == undefined
        ? taskRuns[0] == undefined
          ? undefined
          : taskRuns[0].id
        : selectedTaskRunId0;

    const selectedRun =
      selectedTaskRunId !== undefined
        ? taskRuns.find((taskRun) => taskRunIdEquality(taskRun.id, selectedTaskRunId))
        : undefined;

    const taskLabels = getTaskLabels(taskSummary);
    const [_, taskIdNumber] = encodeTaskId(taskSummary.task.id);

    const maybeRequest = (() => {
      switch (taskSummary.task.parameters.tag) {
        case "SequelHubParameters": {
          return JSON.stringify(
            taskSummary.task.parameters.contents.requestPayload.value,
            undefined,
            4,
          );
        }
        default: {
          return null;
        }
      }
    })();
    return (
      <Page>
        <PageHeader>
          <PageTitle>
            {taskLabels.type} - {taskIdNumber}
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div data-testid="task-runs-container" className="px-8 space-y-4 flex flex-col pb-4">
            <p className="font-medium text-lg">Task history</p>
            {maybeRequest !== null && (
              <Box className="flex flex-auto flex-col p-5" padding="none">
                <p className="text-lg font-medium pb-4">Parameters</p>
                <div className="border-t border-b divide-y border-gray-300 flex flex-col">
                  <SummaryRow
                    key="task-request"
                    mode="multiline"
                    label="Request"
                    content={<RequestViewer contents={maybeRequest} />}
                  />
                </div>
              </Box>
            )}
            <Box className="flex flex-auto overflow-y-auto" padding="none">
              <div className="bg-gray-100 p-6 space-y-3 border-gray-300 border-r overflow-y-auto ">
                {taskRuns.map((taskRun) => {
                  const [taskTag, taskId] = encodeTaskRunId(taskRun.id);
                  return (
                    <TaskRunComponent
                      taskRun={taskRun}
                      selected={
                        selectedTaskRunId !== undefined &&
                        taskRunIdEquality(taskRun.id, selectedTaskRunId)
                      }
                      onClick={() => setSelectedTaskRunId(taskRun.id)}
                      key={`${taskTag}-${taskId}`}
                    />
                  );
                })}
              </div>

              {selectedRun !== undefined && (
                <TaskRunSummaryComponent taskRun={selectedRun} taskSummary={taskSummary} />
              )}
              {selectedRun === undefined && <EmptyTaskRunSummaryComponent />}
            </Box>
          </div>
        </PageContent>
      </Page>
    );
  }
};

const TaskRunComponent = (props: {
  taskRun: TaskRun;
  selected: boolean;
  onClick: () => void;
}): JSX.Element => {
  const [_taskRunTag, taskRunId] = encodeTaskRunId(props.taskRun.id);
  const status: string = computeStatus(props.taskRun);
  return (
    <Box
      className={clsx(
        "py-4 px-6 space-y-0 text-sm leading-5 w-80 border-2",
        props.selected ? "border-blue-500" : "border-white",
      )}
      onClick={props.onClick}
      border="hidden"
    >
      <p className="font-medium">#{taskRunId}</p>
      <p className="font-normal">{prettyPrintTimeString(props.taskRun.startedAt)}</p>
      <p className="font-medium">{status}</p>
    </Box>
  );
};

const TaskRunSummaryComponent = (props: {
  taskRun: TaskRun;
  taskSummary: TaskSummary;
}): JSX.Element => {
  const [taskRunTag, taskRunId] = encodeTaskRunId(props.taskRun.id);
  const [_taskTag, taskId] = encodeTaskId(props.taskRun.taskId);
  const history = useHistory();

  const additionalSummaryRows: SummaryRowProps[] = (() => {
    switch (props.taskRun.progressStatus.tag) {
      case "InProgress":
        return [];
      case "Finished": {
        const rows: SummaryRowProps[] = [
          {
            mode: "singleline",
            label: "Finished at",
            content: props.taskRun.progressStatus.contents.finishedAt,
          },
        ];

        switch (props.taskRun.progressStatus.contents.result.tag) {
          case "Success": {
            const success = props.taskRun.progressStatus.contents.result.contents.parameters;
            const singlelineResult = (content: string) => (
              <span
                onClick={getActionFromSuccess(success, props.taskSummary, history)}
                className="text-blue-500 font-medium cursor-pointer"
              >
                {content}
              </span>
            );

            rows.push({
              mode: "singleline",
              label: "Success result",
              content: (() => {
                switch (taskRunTag) {
                  case "SequelHubTaskRunId":
                    return singlelineResult("View policy");
                  case "ExportPoliciesTaskRunId":
                    return singlelineResult("Download File");
                  case "MrcExtractionTaskRunId":
                    return singlelineResult("Download document");
                  case "ArchivePoliciesTaskRunId":
                    if (success.tag !== "ArchivePoliciesResult") {
                      return <></>;
                    }
                    if (success.contents.archivedPolicies.length === 0) {
                      return <span>No policies were archived.</span>;
                    }
                    return (
                      <>
                        {success.contents.archivedPolicies.map((policy) => (
                          <div key={policy.id}>
                            <span
                              className="text-blue-500 font-medium cursor-pointer"
                              onClick={() =>
                                history.push(
                                  Routes.policy.generatePath({
                                    capture: {
                                      policyId: policy.id,
                                      productId: policy.productId,
                                    },
                                  }),
                                )
                              }
                            >
                              View policy #{policy.id}
                            </span>
                          </div>
                        ))}
                      </>
                    );
                }
              })(),
            });

            const minspector = getInspectorFromSuccess(props.taskSummary, success);
            if (minspector != null) {
              rows.push(minspector);
            }
            break;
          }
          case "Failure": {
            rows.push({
              mode: "singleline",
              label: "Failure log",
              content: (
                <code className="break-all">
                  {props.taskRun.progressStatus.contents.result.contents}
                </code>
              ),
            });
            break;
          }
        }
        return rows;
      }
    }
  })();
  return (
    <div className="p-6 flex-1 space-y-6">
      <p className="text-lg font-medium">{taskRunId}</p>
      <div className="border-t border-b divide-y border-gray-300 flex flex-col">
        <SummaryRow
          key="task-run-id"
          mode="singleline"
          label="Task run id"
          content={taskRunId.toString()}
        />
        <SummaryRow key="task-id" mode="singleline" label="Task id" content={taskId.toString()} />
        <SummaryRow
          key="started-at"
          mode="singleline"
          label="Started at"
          content={prettyPrintTimeString(props.taskRun.startedAt)}
        />
        <SummaryRow
          key="status"
          mode="singleline"
          label="Status"
          content={computeStatus(props.taskRun)}
        />
        {additionalSummaryRows.map((sr) => {
          return <SummaryRow key={sr.label} {...sr} />;
        })}
      </div>
    </div>
  );
};

const EmptyTaskRunSummaryComponent = () => {
  return (
    <div className="self-center px-8">
      <p className="text-xl font-medium self-center"> No task run selected </p>
    </div>
  );
};

export type SummaryRowProps = {
  label: string;
  content: string | JSX.Element;
  mode: "multiline" | "singleline";
};

const SummaryRow = (props: SummaryRowProps) => {
  switch (props.mode) {
    case "multiline":
      return (
        <div className="flex flex-col py-4 px-6">
          <div className="text-gray-500 flex-1 pb-4">{props.label}</div>
          <div className="text-gray-700 flex-1">
            {typeof props.content === "string" ? props.content : props.content}
          </div>
        </div>
      );
    default:
      return (
        <div className="flex flex-1 justify-between py-4 px-6">
          <span className="text-gray-500 flex-1">{props.label}</span>
          <span className="text-gray-700 flex-1">
            {typeof props.content === "string" ? props.content : props.content}
          </span>
        </div>
      );
  }
};

const computeStatus = (taskRun: TaskRun): string => {
  switch (taskRun.progressStatus.tag) {
    case "InProgress":
      return "In progress";
    case "Finished": {
      switch (taskRun.progressStatus.contents.result.tag) {
        case "Failure":
          return "Failed";
        case "Success":
          return "Completed";
      }
    }
  }
};
