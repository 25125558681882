import React from "react";
import artificialBg from "../../assets/images/artificial-bg-dark.png";
import logo from "../../assets/images/artificial-logo.svg";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => (
  <div className="flex flex-row">
    <div className="h-screen bg-white p-10 xl:max-w-lg w-full flex content-center flex-col justify-center items-center">
      <div className="-mt-32 max-w-lg w-full">
        <img src={logo} alt="Artificial Logo" className="mb-12" />
        {children}
      </div>
    </div>
    <div className="xl:block w-full hidden">
      <img src={artificialBg} alt="Artificial Labs" className="object-cover w-full h-screen" />
    </div>
  </div>
);

export default Layout;
