import * as apiTypes from "/src/api_types";

import clsx from "clsx";
import React, { Suspense, useEffect, useRef, useState } from "react";
import * as H from "history";
import { useHistory } from "react-router-dom";
import { ProductOverview } from "../ProductsOverview";
import {
  ActionRequired,
  ActionsRequired,
  AuthUser,
  InsuredName,
  PoliciesSortOn,
  PoliciesSortOrder,
  PolicyDerivedStatusFilter,
  PolicyDerivedStatusFilters,
  PolicyId,
  PolicyInfo,
  Product,
  ProductId,
  ProductVersion,
  QuoteDerivedStatusFilter,
  QuoteDerivedStatusFilters,
  QuoteInfo,
} from "/src/internal_types";
import {
  AddP,
  CanAddNewPolicies,
  CanExportPolicies,
  CanSeeAdditionalFields,
  CanSeeAdminAssignment,
  CanSeeBrokerAssignment,
  CanSeeUnderwriterAssignment,
  GlobalState,
  Permission,
  actionsForRole,
  actionsRequiredForQuote,
  determinePartialQuoteState,
  filtersForRequiredAction,
} from "./permissions";
import { Page, PageHeader, PageTitle, usePageTitle } from "/src/layout";
import {
  Pagination,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  TableLoader,
  toggleSortOrder,
} from "../../design_system/Table";
import { Path, RouteArguments, useParams } from "../../routing/routing";
import {
  newPolicy,
  useGetAdditionalDatapoints,
  useGetAllUsers,
  useGetCurrentUser,
  useGetMrcProductAdapterMapping,
  useGetPolicies,
  useGetProducts,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import { Button } from "/src/design_system/Button";
import { ClearButton } from "/src/components/ClearButton";
import { Dropdown } from "/src/components/Dropdown";
import { FilterCheckbox } from "/src/components/FilterCheckbox";
import { Modal } from "../../design_system/Modal";
import { ProductDropdown } from "/src/components/ProductDropdown";
import { Routes } from "/src/routing/routes";
import { SearchInput } from "/src/components/SearchInput";
import { scalarToText } from "/src/utils";

import ExportPoliciesButton from "/src/components/ExportPoliciesButton";
import {
  ChevronRightIcon,
  ClockIcon,
  ExclamationCircleIcon,
  InboxIcon,
  PlusSmIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { ReplyIcon, XCircleIcon } from "@heroicons/react/outline";
import { assertNever } from "/src/utils";
import { Loader } from "/src/design_system/Loader";
import { Badge, BadgeProps } from "/src/components/Badge";

const quoteStatusFilterLabel = (f: QuoteDerivedStatusFilter): string => {
  switch (f) {
    case "QAcceptedWithBroker":
      return "Accepted with broker";
    case "QAcceptedWithUnderwriter":
      return "Accepted with underwriter";
    case "QNotTakenUp":
      return "Not taken up";
    case "QDraft":
      return "Draft";
    case "QReferredWithUnderwriter":
      return "Referred with underwriter";
    case "QReferredWithBroker":
      return "Referred with broker";
    case "QBound":
      return "Bound";
    case "QDeclined":
      return "Declined";
  }
};
const policyStatusFilterLabel = (f: PolicyDerivedStatusFilter): string => {
  switch (f) {
    case "PDraft":
      return "Draft";
    case "PBound":
      return "Bound";
    case "PArchived":
      return "Archived";
    case "PActive":
      return "Active";
  }
};

const EmptyState = ({
  searchQuery,
  selectedPolicyFilters,
  selectedQuoteFilters,
  selectedRequiredActions,
  selectedAssignees,
  canCreateNewPolicy,
  productId,
  products,
  policyPathFunction,
}: {
  searchQuery: string | undefined;
  selectedPolicyFilters: Array<PolicyDerivedStatusFilter>;
  selectedQuoteFilters: Array<QuoteDerivedStatusFilter>;
  selectedRequiredActions: Array<ActionRequired>;
  selectedAssignees: Array<apiTypes.components["schemas"]["User"]>;
  canCreateNewPolicy: Permission<unknown, unknown, AddP>;
  productId: ProductId | undefined;
  products: Product[];
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path;
}) => {
  const noFilters = !(
    searchQuery !== undefined ||
    selectedPolicyFilters.length > 0 ||
    selectedQuoteFilters.length > 0 ||
    selectedAssignees.length > 0 ||
    selectedRequiredActions.length > 0
  );
  const canCreateNewPolicy_ = canCreateNewPolicy.check(undefined);

  return (
    <div className="w-full h-full bg-white flex flex-col justify-center items-center py-3">
      {noFilters && (
        <>
          <div className="rounded-full bg-gray-100 h-10 w-10 flex justify-center items-center">
            <InboxIcon className="h-6 w-6 text-blue-600" />
          </div>
          <p className="font-semibold mt-2">0 Policies</p>
          {canCreateNewPolicy_ && (
            <>
              <p className="mb-2">Create a policy to get started</p>
              <NewPolicyComponent
                productId={productId}
                canCreateNewPolicy={canCreateNewPolicy}
                products={products}
                policyPathFunction={policyPathFunction}
              />
            </>
          )}
          {!canCreateNewPolicy_ && (
            <p className="mb-2">
              Policies will start appearing when brokers create them in the system.
            </p>
          )}
        </>
      )}
      {!noFilters && (
        <>
          <div className="rounded-full bg-gray-100 h-10 w-10 flex justify-center items-center">
            <XCircleIcon className="h-6 w-6 text-gray-600" />
          </div>
          <p className="font-semibold my-2">No results</p>
          <p>There are no policies</p>
          {searchQuery !== undefined && (
            <p>
              matching <span className="font-semibold">"{searchQuery}"</span>
            </p>
          )}
          {selectedPolicyFilters.length > 0 && (
            <p>
              with Policy statuses{" "}
              <span className="font-semibold">
                "{selectedPolicyFilters.map(policyStatusFilterLabel).join(", ")}"
              </span>
            </p>
          )}
          {selectedQuoteFilters.length > 0 && (
            <p>
              with Quote statuses{" "}
              <span className="font-semibold">
                "{selectedQuoteFilters.map(quoteStatusFilterLabel).join(", ")}"
              </span>
            </p>
          )}
          {selectedRequiredActions.length > 0 && (
            <p>
              with Required Actions{" "}
              <span className="font-semibold">
                "{selectedRequiredActions.map(actionRequiredLabel).join(", ")}"
              </span>
            </p>
          )}
          {selectedAssignees.length > 0 && (
            <p>
              assigned to{" "}
              <span className="font-semibold">
                "{selectedAssignees.map((u) => u.displayName).join(", ")}"
              </span>
            </p>
          )}
        </>
      )}
    </div>
  );
};

export function checkInList<S>(l: S[], v: S): S[] {
  let result = [];
  if (l.find((u) => u === v) !== undefined) {
    result = l.filter((u) => u !== v);
  } else {
    result = [...l, v];
  }
  return result;
}

export type PoliciesUrlParams = RouteArguments<typeof Routes.policies.route>["query"];

const Policies: React.FC = () => {
  const productsResp = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(productsResp);
  const products = productsResp.value.products;
  const { data, setQuery } = useParams(Routes.policies);

  const selectedAssigneeIds = data.query.selectedAssigneeIds ?? [];
  const selectedQuoteFilters = data.query.selectedQuoteFilters ?? [];
  const selectedPolicyFilters = data.query.selectedPolicyFilters ?? [];
  const selectedActionsRequired = data.query.selectedActionsRequired ?? [];
  const searchReferenceQuery = data.query.searchReferenceQuery;
  const page = data.query.page ?? 1;
  const additionalDatapoints = data.query.additionalDatapoints ?? [];
  const sortOn = data.query.sortOn ?? "id";
  const sortOrder = data.query.sortOrder ?? "desc";
  const customSortOn = data.query.customSortOn;
  const productId = data.query.productId;

  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const allUsers = useGetAllUsers(); // tw: todo: change this after identity is merged
  throwIfAppError(allUsers);
  usePageTitle("Inbox");
  const constantState = { currentRole: currentUser.value.role };
  const allAdditionalDatapoints = useGetAdditionalDatapoints(productId);
  throwIfAppError(allAdditionalDatapoints);
  const allAssigneesPossible = allUsers.value.users.filter((u) => {
    switch (u.role) {
      case "broker":
        return CanSeeBrokerAssignment(constantState).check(undefined);
      case "underwriter":
        return CanSeeUnderwriterAssignment(constantState).check(undefined);
      case "admin":
        return CanSeeAdminAssignment(constantState).check(undefined);
    }
  });
  const selectedAssignees = selectedAssigneeIds.flatMap((id) => {
    const u = allAssigneesPossible.find((x) => x.id === id);
    return u !== undefined ? [u] : [];
  });

  function onQuoteFilterChange(f: QuoteDerivedStatusFilter) {
    const result = checkInList(selectedQuoteFilters, f);
    setQuery({ selectedQuoteFilters: result, page: 1 });
  }

  function onPolicyFilterChange(f: PolicyDerivedStatusFilter) {
    const result = checkInList(selectedPolicyFilters, f);
    setQuery({ selectedPolicyFilters: result, page: 1 });
  }

  function setProductChoice(productChoice?: number) {
    setQuery({ productId: productChoice, page: 1 });
  }

  function onActionsRequiredChange(f: ActionRequired) {
    const result = checkInList(selectedActionsRequired, f);
    setQuery({ selectedActionsRequired: result, page: 1 });
  }

  function onAssigneesChange(assignee: apiTypes.components["schemas"]["User"]) {
    const result = checkInList(selectedAssigneeIds, assignee.id);
    setQuery({ selectedAssigneeIds: result, page: 1 });
  }

  function onAdditionalDatapointsChange(id: string) {
    const result = checkInList(additionalDatapoints, id);
    setQuery({ additionalDatapoints: result });
  }

  const handleSearchQuery = (s: string | undefined) => {
    setQuery({ searchReferenceQuery: s });
  };

  const handlePageChange = (q: number) => {
    setQuery({ page: q > 1 ? q : undefined });
  };

  const canCreateNewPolicyPermission: Permission<GlobalState, unknown, AddP> =
    CanAddNewPolicies(constantState);
  const canExportPolicies = CanExportPolicies(constantState);
  const canSeeAdditionalFields = CanSeeAdditionalFields(constantState);

  const hasFilters =
    productId !== undefined ||
    selectedAssignees.length > 0 ||
    selectedPolicyFilters.length > 0 ||
    selectedQuoteFilters.length > 0 ||
    selectedActionsRequired.length > 0 ||
    additionalDatapoints.length > 0;

  const [shouldShowFiltersDisregardScreenSize, setShouldShowFiltersDisregardScreenSize] =
    useState<boolean>(true);

  const product = products.find((x) => x.id === productId);

  return (
    <Page>
      <PageHeader>
        <PageTitle>Inbox</PageTitle>
        {/* move new policy component to the right */}
        <div className="flex-1" />
        <NewPolicyComponent
          productId={productId}
          canCreateNewPolicy={canCreateNewPolicyPermission}
          products={products}
          policyPathFunction={(productId, policyId, editing) =>
            Routes.policy.generatePath({
              capture: { productId, policyId },
              query: { ...data.query, editing },
            })
          }
        />
      </PageHeader>

      <div className="px-8">
        <PredefinedFilters productId={productId} params={data.query} setQuery={setQuery} />
      </div>

      <div className="px-8 overflow-x-auto flex flex-1 h-full" data-testid="policies-container">
        <div className="align-middle w-full mx-auto h-full">
          <div className="flex bg-white w-full h-full">
            <div className="flex flex-1 overflow-y-auto m-0">
              <div className="flex flex-col justify-between w-full h-full overflow-y-auto relative">
                <div
                  className={clsx(
                    " bg-gray-50",
                    " flex-wrap justify-between pb-3 px-2 pr-3 border-t border-gray-200",
                  )}
                >
                  <div className="flex flex-row items-center pr-3 float-left mt-4">
                    <SearchInput
                      onChange={(e) => handleSearchQuery(e.target.value)}
                      value={searchReferenceQuery}
                      placeholder={"Insured name or reference"}
                    />
                    {searchReferenceQuery !== undefined && (
                      <ClearButton
                        onClick={() => {
                          handlePageChange(1);
                          handleSearchQuery("");
                        }}
                        size="lg"
                      />
                    )}
                  </div>
                  <div className="self-end flex flex-row float-right mt-4">
                    {hasFilters && (
                      <span className="mr-2">
                        <ClearButton
                          onClick={() => {
                            setQuery({}, { removeAll: true });
                          }}
                          size="lg"
                        />
                      </span>
                    )}
                    <button
                      onClick={() => {
                        setShouldShowFiltersDisregardScreenSize(
                          !shouldShowFiltersDisregardScreenSize,
                        );
                      }}
                      /* Below:
                         We display this button on anything smaller than 2xl.
                       */
                      className="w-auto justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      {shouldShowFiltersDisregardScreenSize ? "Hide filters" : "Show filters"}
                    </button>
                    <span className="ml-2">
                      <ExportPoliciesButton
                        canExportPolicies={canExportPolicies}
                        product={product}
                      ></ExportPoliciesButton>
                    </span>
                  </div>
                  <div
                    className={clsx(
                      "pt-4 float-left flex flex-row space-x-2 overflow-x-auto lg:overflow-visible",
                      shouldShowFiltersDisregardScreenSize ? "" : "hidden",
                    )}
                  >
                    <ProductDropdown
                      label="Product"
                      productId={productId}
                      products={products}
                      onSelectProduct={setProductChoice}
                    />
                    {canSeeAdditionalFields.check(undefined) &&
                      allAdditionalDatapoints.value.length > 0 && (
                        <Dropdown
                          label="Additional Datapoints"
                          itemsChecked={additionalDatapoints.length}
                        >
                          {allAdditionalDatapoints.value.map((f) => (
                            <FilterCheckbox
                              key={f}
                              label={f}
                              checked={additionalDatapoints.find((x) => x === f) !== undefined}
                              name={f}
                              onChange={() => onAdditionalDatapointsChange(f)}
                            />
                          ))}
                        </Dropdown>
                      )}
                    <Dropdown label="Policy status" itemsChecked={selectedPolicyFilters.length}>
                      {PolicyDerivedStatusFilters.map((f) => (
                        <FilterCheckbox
                          key={f}
                          label={policyStatusFilterLabel(f)}
                          checked={selectedPolicyFilters.find((x) => x === f) !== undefined}
                          name={policyStatusFilterLabel(f)}
                          onChange={() => onPolicyFilterChange(f)}
                        />
                      ))}
                    </Dropdown>
                    <Dropdown label="Quote status" itemsChecked={selectedQuoteFilters.length}>
                      {QuoteDerivedStatusFilters.map((f) => (
                        <FilterCheckbox
                          key={f}
                          label={quoteStatusFilterLabel(f)}
                          checked={selectedQuoteFilters.find((x) => x === f) !== undefined}
                          name={quoteStatusFilterLabel(f)}
                          onChange={() => onQuoteFilterChange(f)}
                        />
                      ))}
                    </Dropdown>
                    <Dropdown label="Action" itemsChecked={selectedActionsRequired.length}>
                      {actionsForRole(currentUser.value.role).map((f) => (
                        <FilterCheckbox
                          key={f}
                          label={actionRequiredLabel(f)}
                          checked={selectedActionsRequired.find((x) => x === f) !== undefined}
                          name={actionRequiredLabel(f)}
                          onChange={() => onActionsRequiredChange(f)}
                        />
                      ))}
                    </Dropdown>
                    <Dropdown label="Assigned to" itemsChecked={selectedAssigneeIds.length}>
                      {allAssigneesPossible.map((a) => (
                        <FilterCheckbox
                          key={a.id}
                          label={a.displayName}
                          checked={selectedAssigneeIds.find((x) => x === a.id) !== undefined}
                          name={a.displayName}
                          onChange={() => onAssigneesChange(a)}
                        />
                      ))}
                    </Dropdown>
                  </div>
                </div>
                <Suspense fallback={<TableLoader />}>
                  <PoliciesTable
                    productId={productId}
                    searchReferenceQuery={searchReferenceQuery}
                    selectedPolicyStatusFilters={selectedPolicyFilters}
                    selectedQuoteStatusFilters={selectedQuoteFilters}
                    selectedActionsRequired={selectedActionsRequired}
                    selectedAssignees={selectedAssignees}
                    allAssigneesPossible={allAssigneesPossible}
                    handlePageChange={handlePageChange}
                    pageNumber={page}
                    canCreateNewPolicyPermission={canCreateNewPolicyPermission}
                    products={products}
                    policyPathFunction={(productId, policyId, editing) => {
                      return Routes.policy.generatePath({
                        capture: { productId, policyId },
                        query: { editing: editing, ...data.query },
                      });
                    }}
                    additionalDatapoints={additionalDatapoints}
                    sortOn={sortOn}
                    customSortOn={customSortOn}
                    sortOrder={sortOrder}
                    setSortOn={(newSortOn, newCustomSortOn) => {
                      if (sortOn === newSortOn && customSortOn === newCustomSortOn) {
                        // Below, we apply the sort on field (e.g. id
                        // or name), so that it'll force the query param
                        // to be added to the page.
                        //
                        // The awkward nature of this setter is that
                        // it doesn't really "set", it actually toggles,
                        // which is a little different.
                        //
                        // The issue is described in detail here
                        // <https://github.com/artificialio/brossa/issues/2471#issuecomment-1058284704>
                        setQuery({
                          sortOn: newSortOn,
                          sortOrder: toggleSortOrder(sortOrder ?? "desc"),
                        });
                      } else {
                        setQuery({
                          sortOn: newSortOn,
                          sortOrder: "desc",
                          customSortOn: newCustomSortOn,
                        });
                      }
                    }}
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const allFilterTypes = [
  "All submissions",
  "Needs action",
  "Awaiting broker",
  "Awaiting underwriter",
  "Assigned to me",
] as const;

type PredefinedFilterType = typeof allFilterTypes[number];
type PredefinedFilterParams = Pick<
  PoliciesUrlParams,
  | "searchReferenceQuery"
  | "selectedAssigneeIds"
  | "selectedPolicyFilters"
  | "selectedQuoteFilters"
  | "selectedActionsRequired"
  | "additionalDatapoints"
  | "sortOrder"
  | "sortOn"
  | "customSortOn"
>;

const filterTypeToUrlParams = (
  ft: PredefinedFilterType,
  currentUser: AuthUser,
): PredefinedFilterParams => {
  // for brokers, we want to show only policies that are created by them
  // for others, all policies
  const assignees = currentUser.role === "broker" ? [currentUser.id] : [];
  switch (ft) {
    case "All submissions":
      return {
        selectedAssigneeIds: [],
        selectedQuoteFilters: [],
        selectedPolicyFilters: [],
        selectedActionsRequired: [],
        searchReferenceQuery: undefined,
        additionalDatapoints: [],
        sortOn: undefined,
        sortOrder: undefined,
        customSortOn: undefined,
      };
    case "Awaiting broker":
      return {
        selectedAssigneeIds: assignees,
        selectedQuoteFilters: [],
        selectedPolicyFilters: [],
        selectedActionsRequired: ["awaiting_broker"],
        searchReferenceQuery: undefined,
        additionalDatapoints: [],
        sortOn: undefined,
        sortOrder: undefined,
        customSortOn: undefined,
      };
    case "Awaiting underwriter":
      return {
        selectedAssigneeIds: assignees,
        selectedQuoteFilters: [],
        selectedPolicyFilters: [],
        selectedActionsRequired: ["awaiting_underwriter"],
        searchReferenceQuery: undefined,
        additionalDatapoints: [],
        sortOn: undefined,
        sortOrder: undefined,
        customSortOn: undefined,
      };
    case "Needs action":
      return {
        selectedAssigneeIds: assignees,
        selectedQuoteFilters: [],
        selectedPolicyFilters: [],
        selectedActionsRequired: ["ready_to_bind", "needs_review"],
        searchReferenceQuery: undefined,
        additionalDatapoints: [],
        sortOn: undefined,
        sortOrder: undefined,
        customSortOn: undefined,
      };
    case "Assigned to me":
      return {
        selectedAssigneeIds: [currentUser.id],
        selectedQuoteFilters: [],
        selectedPolicyFilters: ["PActive"],
        selectedActionsRequired: [],
        searchReferenceQuery: undefined,
        additionalDatapoints: [],
        sortOn: undefined,
        sortOrder: undefined,
        customSortOn: undefined,
      };
  }
};

const getPredefinedFiltersForUser = (currentUser: AuthUser): PredefinedFilterType[] => {
  switch (currentUser.role) {
    case "broker": {
      return ["All submissions", "Needs action", "Awaiting underwriter"];
    }
    case "underwriter": {
      return ["All submissions", "Needs action", "Awaiting broker", "Assigned to me"];
    }
    case "admin": {
      return ["All submissions", "Awaiting broker", "Awaiting underwriter"];
    }
    case "read_only": {
      return ["All submissions", "Awaiting broker", "Awaiting underwriter"];
    }
  }
};

const urlFiltersAreEqual = (
  currentParams: PredefinedFilterParams,
  predefinedFilterParams: PredefinedFilterParams,
): boolean => {
  const isEqual = (key: keyof PredefinedFilterParams): boolean => {
    switch (key) {
      case "searchReferenceQuery":
      case "sortOn":
      case "sortOrder":
      case "customSortOn":
        return currentParams[key] === predefinedFilterParams[key];
      default: {
        const a = (currentParams[key] ?? []).sort();
        const b = (predefinedFilterParams[key] ?? []).sort();
        if (a.length !== b.length) {
          return false;
        }
        let i = 0;
        for (i = 0; i < a.length; i++) {
          if (a[i] !== b[i]) {
            return false;
          }
        }
        return true;
      }
    }
  };
  const keys = Object.keys(predefinedFilterParams) as Array<keyof PredefinedFilterParams>;
  let i = 0;
  for (i = 0; i < keys.length; i++) {
    if (!isEqual(keys[i])) {
      return false;
    }
  }
  return true;
};

const PredefinedFilters = (props: {
  productId: number | undefined;
  params: PoliciesUrlParams;
  setQuery: (x: PoliciesUrlParams) => void;
}): JSX.Element => {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const filters = getPredefinedFiltersForUser(currentUser.value);

  return (
    <>
      <div className="lg:w-72 w-48 flex flex-row">
        <Suspense
          fallback={
            <>
              {filters.map((type) => (
                <LeftPanelButton
                  key={type}
                  label={type}
                  onClick={() => {
                    return;
                  }}
                  selected={false}
                  icon={predefinedFilterTypeIcon(type)}
                />
              ))}
            </>
          }
        >
          {filters.map((type) => {
            return (
              <PolicyPredefinedFilter
                key={type}
                type={type}
                params={props.params}
                productId={props.productId}
                setQuery={props.setQuery}
              />
            );
          })}
        </Suspense>
      </div>
    </>
  );
};

const predefinedFilterTypeIcon = (type: PredefinedFilterType): LeftPanelButtonProps["icon"] => {
  switch (type) {
    case "All submissions":
      return "all";
    case "Assigned to me":
      return "user";
    case "Awaiting broker":
      return "clock";
    case "Awaiting underwriter":
      return "clock";
    case "Needs action":
      return "warning";
  }
};

const PolicyPredefinedFilter = (props: {
  productId: number | undefined;
  type: PredefinedFilterType;
  params: PoliciesUrlParams;
  setQuery: (v: PoliciesUrlParams) => void;
}): JSX.Element => {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const typeParams = filterTypeToUrlParams(props.type, currentUser.value);
  const { policyStatuses, quoteStatuses } = getFiltersFromUrl(
    typeParams.selectedActionsRequired,
    typeParams.selectedQuoteFilters,
    typeParams.selectedPolicyFilters,
    currentUser.value,
  );
  const policies = useGetPolicies({
    page: 1,
    pageCount: 1,
    productId: props.productId,
    policyStatuses,
    quoteStatuses,
    query: typeParams.searchReferenceQuery,
    assignees: typeParams.selectedAssigneeIds,
  });
  throwIfAppError(policies);
  return (
    <LeftPanelButton
      count={policies.value.count}
      label={props.type}
      selected={urlFiltersAreEqual(props.params, typeParams)}
      onClick={() => {
        props.setQuery({ ...typeParams, page: 1, productId: props.productId });
      }}
      icon={predefinedFilterTypeIcon(props.type)}
    />
  );
};

const getFiltersFromUrl = (
  selectedActionsRequired: string[] | undefined,
  selectedQuoteStatusFilters: string[] | undefined,
  selectedPolicyStatusFilters: string[] | undefined,
  currentUser: AuthUser,
): {
  selectedActions: ActionRequired[];
  quoteStatuses: QuoteDerivedStatusFilter[] | undefined;
  policyStatuses: PolicyDerivedStatusFilter[] | undefined;
  selectedQuoteFilters: QuoteDerivedStatusFilter[];
  selectedPolicyFilters: PolicyDerivedStatusFilter[];
} => {
  const selectedActions = ActionsRequired.filter((x) =>
    (selectedActionsRequired ?? []).includes(x),
  );

  const additionalStatuses = selectedActions.reduce<
    [QuoteDerivedStatusFilter[], PolicyDerivedStatusFilter[]]
  >(
    (acc, v) => {
      const s = filtersForRequiredAction(currentUser.role, v);
      return [acc[0].concat(s.quoteStatuses), acc[1].concat(s.policyStatuses)];
    },
    [[], []],
  );

  const selectedQuoteFilters = QuoteDerivedStatusFilters.filter((f) =>
    (selectedQuoteStatusFilters ?? []).includes(f),
  );
  const selectedPolicyFilters = PolicyDerivedStatusFilters.filter((f) =>
    (selectedPolicyStatusFilters ?? []).includes(f),
  );

  const quoteStatuses =
    selectedQuoteFilters.length === 0
      ? selectedActions.length === 0
        ? undefined
        : additionalStatuses[0]
      : selectedActions.length === 0
      ? selectedQuoteFilters
      : selectedQuoteFilters.filter((f) => additionalStatuses[0].includes(f));
  const policyStatuses =
    selectedPolicyFilters.length === 0
      ? selectedActions.length === 0
        ? undefined
        : additionalStatuses[1]
      : selectedActions.length === 0
      ? selectedPolicyFilters
      : selectedPolicyFilters.filter((f) => additionalStatuses[1].includes(f));
  return {
    selectedActions,
    selectedQuoteFilters,
    selectedPolicyFilters,
    quoteStatuses,
    policyStatuses,
  };
};

function PoliciesTable({
  searchReferenceQuery,
  selectedQuoteStatusFilters,
  selectedPolicyStatusFilters,
  selectedActionsRequired,
  additionalDatapoints,
  productId,
  selectedAssignees,
  allAssigneesPossible,
  handlePageChange,
  pageNumber,
  canCreateNewPolicyPermission,
  products,
  policyPathFunction,
  sortOrder,
  sortOn,
  setSortOn,
  customSortOn,
}: {
  searchReferenceQuery: string | undefined;
  selectedQuoteStatusFilters: string[];
  selectedPolicyStatusFilters: string[];
  selectedActionsRequired: string[];
  additionalDatapoints: string[];
  productId: ProductId | undefined;
  selectedAssignees: apiTypes.components["schemas"]["User"][];
  allAssigneesPossible: apiTypes.components["schemas"]["User"][];
  pageNumber: number | undefined;
  handlePageChange: (page: number) => void;
  canCreateNewPolicyPermission: Permission<GlobalState, unknown, AddP>;
  products: Product[];
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path;
  sortOrder: PoliciesSortOrder;
  sortOn: PoliciesSortOn;
  customSortOn: string | undefined;
  setSortOn: (x: PoliciesSortOn, customSortOn?: string) => void;
}) {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);

  const history = useHistory();
  const tableWrapperElement = useRef<HTMLDivElement>(null);

  const pageCount = 50;

  const {
    policyStatuses,
    quoteStatuses,
    selectedActions,
    selectedPolicyFilters,
    selectedQuoteFilters,
  } = getFiltersFromUrl(
    selectedActionsRequired,
    selectedQuoteStatusFilters,
    selectedPolicyStatusFilters,
    currentUser.value,
  );

  const data = useGetPolicies({
    productId,
    page: pageNumber,
    pageCount,
    query: searchReferenceQuery,
    quoteStatuses: quoteStatuses,
    policyStatuses: policyStatuses,
    assignees: selectedAssignees.map((u) => u.id),
    additionalFields: additionalDatapoints,
    sortOn,
    sortOrder,
    customSortOn,
  });
  throwIfAppError(data);

  useEffect(() => {
    // This ensures scroll position is reset when new
    // data results come in e.g. via pagination, filtering or search
    const tableNode = tableWrapperElement.current;
    if (tableNode !== null) {
      tableNode.scrollTop = 0;
    }
  }, [data.value.policies]);

  return (
    <div className="border-gray-400 flex flex-col max-h-full h-full overflow-hidden">
      {data.value.policies.length > 0 && (
        <div ref={tableWrapperElement} className="bg-white overflow-auto">
          <Table className="min-w-full divide-y divide-gray-200 h-full">
            <THead className="z-100 relative">
              <TR>
                <TH
                  label="Ref"
                  sortOrder={sortOn === "id" ? sortOrder : undefined}
                  className="cursor-pointer"
                  onClick={() => setSortOn("id")}
                />
                <TH label="Product" />
                <TH
                  label="Name"
                  sortOrder={sortOn === "insured_name" ? sortOrder : undefined}
                  className="cursor-pointer"
                  onClick={() => setSortOn("insured_name")}
                />
                <TH label="Status" />
                <TH label="Action" />
                <TH
                  label="Created"
                  sortOrder={sortOn === "created_at" ? sortOrder : undefined}
                  className="cursor-pointer"
                  onClick={() => setSortOn("created_at")}
                />
                <TH
                  label="Last"
                  sortOrder={sortOn === "last_modified" ? sortOrder : undefined}
                  className="cursor-pointer"
                  onClick={() => setSortOn("last_modified")}
                />
                <TH label="Assigned" />
                {additionalDatapoints.map((v) => (
                  <TH
                    label={v}
                    sortOrder={sortOn === "custom" && customSortOn === v ? sortOrder : undefined}
                    className="cursor-pointer"
                    onClick={() => setSortOn("custom", v)}
                  />
                ))}
              </TR>
            </THead>
            <TBody>
              {data.value.policies.map((policy) => {
                const assignedId =
                  currentUser.value.role === "broker"
                    ? policy.created.performedBy
                    : policy.assignedTo?.id;
                const assigned = allAssigneesPossible.find((a) => a.id === assignedId);
                const assignedCaption = assigned !== undefined ? assigned.displayName : "N/A";
                const product = products.find((x) => x.id === policy.productId);
                return (
                  <TR
                    key={policy.id}
                    className="cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      history.push(policyPathFunction(policy.productId, policy.id, undefined));
                    }}
                  >
                    <TD>#{policy.id}</TD>
                    <TD>
                      {product !== undefined ? getProductCaption(product) : ""} (v
                      {policy.productVersion})
                    </TD>
                    <TD>
                      <ul className="list-none">
                        <li className="font-medium text-gray-700 mb-4 h-6" key="policy-name">
                          {policyInsuredName(policy.insuredName)}
                        </li>
                        {policy.quotes.quotes
                          .slice()
                          .reverse()
                          .map((quote) => (
                            <li key={quote.id} className="mb-2 h-6">
                              <ReplyIcon className="h-4 inline-block text-gray-400 -mt-1 transform rotate-180" />
                              Quote #{quote.quoteNumber}
                            </li>
                          ))}
                      </ul>
                    </TD>
                    <TD>
                      <ul className="list-none">
                        <li className="mb-4 h-6" key="policy-status">
                          <PolicyStatusBadge policy={policy} />
                        </li>
                        {policy.quotes.quotes
                          .slice()
                          .reverse()
                          .map((quote) => (
                            <li className="mb-2 h-6" key={quote.id}>
                              <QuoteStatusBadge quote={quote} policy={policy} />
                            </li>
                          ))}
                      </ul>
                    </TD>
                    <TD>
                      <ul>
                        <li className="mb-4 h-6" key="policy-action"></li>
                        {policy.quotes.quotes
                          .slice()
                          .reverse()
                          .map((quote) => (
                            <li className="mb-2 h-6" key={quote.id}>
                              <ActionRequiredBadges quote={quote} policy={policy} />
                            </li>
                          ))}
                      </ul>
                    </TD>
                    <TD>{new Date(policy.created.happenedAt).toLocaleString()}</TD>
                    <TD>
                      {policy.modified == null
                        ? new Date(policy.created.happenedAt).toLocaleString()
                        : new Date(policy.modified).toLocaleString()}
                    </TD>
                    <TD>{assignedCaption}</TD>
                    {additionalDatapoints.map((v) => (
                      <TD>
                        {policy.additionalFields[v] === undefined
                          ? ""
                          : scalarToText(policy.additionalFields[v]).getRight() ?? ""}
                      </TD>
                    ))}
                  </TR>
                );
              })}
            </TBody>
          </Table>
        </div>
      )}
      {data.value.policies.length < 1 && (
        <EmptyState
          searchQuery={searchReferenceQuery}
          selectedAssignees={selectedAssignees}
          selectedPolicyFilters={selectedPolicyFilters}
          selectedQuoteFilters={selectedQuoteFilters}
          selectedRequiredActions={selectedActions}
          canCreateNewPolicy={canCreateNewPolicyPermission}
          productId={productId}
          products={products}
          policyPathFunction={(productId, policyId, editing) =>
            policyPathFunction(productId, policyId, editing)
          }
        />
      )}
      {data.value.policies.length > 0 && (
        <Pagination
          totalRowsCount={data.value.count}
          rowsPerPage={pageCount}
          currentPageNumber={pageNumber}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}

function policyInsuredName(insuredName: InsuredName): JSX.Element {
  switch (insuredName.tag) {
    case "FieldSpecificationNotYetKnown": {
      return (
        <span
          className="text-gray-500 italic font-normal"
          title="This indicates a software error; please report this."
        >
          Unknown
        </span>
      );
    }
    case "FieldIsNotSpecified": {
      return (
        <span
          className="text-gray-500 italic font-normal"
          title="The name has not been specified in the Brossa spec, please update it."
        >
          Not specified
        </span>
      );
    }
    case "FieldIsSpecified": {
      if (insuredName.contents === undefined) {
        return (
          <span className="text-gray-500 italic font-normal" title="Name has not been submitted.">
            Not submitted
          </span>
        );
      } else {
        return <span>{ellipsis(normalizeBrossaString(insuredName.contents ?? ""), 60)}</span>;
      }
    }
  }
}

// Given a text representation of a Brossa string literal, normalize
// it to the text that the literal represents.
const normalizeBrossaString = (quoted: string) =>
  quoted
    // Splice the quoted text out of its quotes.
    .replace(/^"(.*)"$/, "$1")
    // Replace any escape sequences with a space
    .replace(/\\(.)/g, " ");

// Apply an ellipsis if `string' is longer than `len'.
const ellipsis = (string: string, len: number) =>
  string.length > len ? string.substring(0, len) + "..." : string;

export function PolicyStatusBadge(props: { policy: PolicyInfo }): JSX.Element {
  const policyState = props.policy.derivedStatus;
  switch (policyState.tag) {
    case "Draft":
      return <Badge testid="policy-status-badge" label="Draft" color="gray" />;
    case "Bound":
      return <Badge testid="policy-status-badge" label="Bound" color="blue" />;
    case "Active":
      return <Badge testid="policy-status-badge" label="In progress" color="green" />;
    case "CancellationPending":
      return <Badge testid="policy-status-badge" label="Cancellation pending" color="red" />;
    case "Archived":
      return <Badge testid="policy-status-badge" label="Archived" color="red" />;
  }
}

export function badgeColor(
  quote: Pick<QuoteInfo, "derivedStatus">,
  policy: PolicyInfo,
): BadgeProps["color"] {
  const quoteState = determinePartialQuoteState(policy, quote);
  switch (quoteState.tag) {
    case "Draft":
      return "gray";
    case "Bound":
      return "blue";
    case "Referred":
      return "orange";
    case "NotTakenUp":
      return "red";
    case "Declined":
      return "red";
    case "Accepted":
      return "green";
  }
}

export function QuoteStatusBadge(props: {
  quote: Pick<QuoteInfo, "derivedStatus">;
  policy: PolicyInfo;
}): JSX.Element {
  const quoteState = determinePartialQuoteState(props.policy, props.quote);
  const color = badgeColor(props.quote, props.policy);
  switch (quoteState.tag) {
    case "Draft":
      return <Badge label="Draft" color={color} />;
    case "Bound":
      return <Badge label="Bound" color={color} />;
    case "NotTakenUp":
      return <Badge label="Not taken up" color={color} />;
    case "Declined":
      return <Badge label="Declined" color={color} />;
    case "Referred":
      return <Badge label="Referred" color={color} />;
    case "Accepted":
      return <Badge label="Accepted" color={color} />;
  }
}

const actionRequiredLabel = (actionRequired: ActionRequired): string => {
  switch (actionRequired) {
    case "awaiting_broker":
      return "Awaiting broker";
    case "awaiting_underwriter":
      return "Awaiting underwriter";
    case "needs_review":
      return "Needs review";
    case "ready_to_bind":
      return "Ready to bind";
  }
};

const ActionRequiredBadge = (props: { actionRequired: ActionRequired }): JSX.Element => {
  const label = actionRequiredLabel(props.actionRequired);
  switch (props.actionRequired) {
    case "awaiting_broker":
      return <Badge label={label} color="blue" />;
    case "awaiting_underwriter":
      return <Badge label={label} color="blue" />;
    case "needs_review":
      return <Badge label={label} color="red" important={true} />;
    case "ready_to_bind":
      return <Badge label={label} color="green" important={true} />;
  }
};

export const ActionRequiredBadges = (props: {
  quote: Pick<QuoteInfo, "derivedStatus">;
  policy: PolicyInfo;
}): JSX.Element => {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const actions = actionsRequiredForQuote(
    { currentRole: currentUser.value.role },
    props.policy,
    props.quote,
  );
  return (
    <div>
      {actions.map((a) => (
        <ActionRequiredBadge key={a} actionRequired={a} />
      ))}
    </div>
  );
};

type LeftPanelButtonProps = {
  label: string;
  icon: "all" | "single" | "warning" | "clock" | "user";
  selected: boolean;
  count?: undefined | number;
  onClick: () => void;
};

const LeftPanelButtonIcon = (props: LeftPanelButtonProps, className: string): JSX.Element => {
  switch (props.icon) {
    case "all":
      return <InboxIcon className={className} />;
    case "single":
      return <ChevronRightIcon className={className} />;
    case "warning":
      return <ExclamationCircleIcon className={className} />;
    case "clock":
      return <ClockIcon className={className} />;
    case "user":
      return <UserIcon className={className} />;
  }
};

const LeftPanelButton = (props: LeftPanelButtonProps): JSX.Element => {
  return (
    <button
      className={`px-4 py-1 transition-colors pb-4 duration-200 text-left flex space-x-2 focus:outline-none ${
        props.selected
          ? "border-b-2 border-blue-500 text-blue-500"
          : "border-b-2 border-grey-50 text-gray-900 hover:text-blue-700"
      }`}
      onClick={props.onClick}
    >
      <span className={`${props.selected ? "text-blue-500" : "text-gray-300"}`}>
        {LeftPanelButtonIcon(props, "h-5 w-5 -ml-1 shrink-0")}
      </span>
      <span className="truncate">{props.label}</span>
      <span className="flex-1"></span>
      {props.count !== undefined && (
        <span
          className={clsx(
            "rounded-full",
            props.selected ? "bg-blue-100 text-blue-800" : "bg-gray-100",
            "px-3",
            props.selected ? "text-white" : "text-gray-500",
            "font-medium",
          )}
        >
          {props.count}
        </span>
      )}
    </button>
  );
};

export const getProductCaption = (product: Product): string => {
  return product.name; // TW: TODO change this to readable name or something like that once we have it
};

const SelectProductUI = (props: {
  productId: ProductId | undefined;
  products: Product[];
  setChosenProductId: (productId: ProductId, productVersion: ProductVersion) => void;
}): JSX.Element => {
  return (
    <>
      {props.products.map((product) => {
        return (
          <ProductOverview
            key={product.id}
            product={product}
            outlined={props.productId === product.id}
            onClick={(ev) => {
              props.setChosenProductId(product.id, product.version);
              ev.stopPropagation();
            }}
            hideDownloadSpec={true}
          />
        );
      })}
    </>
  );
};

const enum PolicyInitType {
  IMPORT_FROM_MRC,
  BLANK_POLICY,
}

const SelectPolicyTypeUI = (props: {
  productId: ProductId;
  productVersion: ProductVersion;
  selectedPolicyType: PolicyInitType | null;
  setPolicyType: (policyType: PolicyInitType) => void;
}): JSX.Element => {
  const mrcAdapterMapping = useGetMrcProductAdapterMapping(props.productId, props.productVersion);
  throwIfAppError(mrcAdapterMapping);
  const options = [];
  if (mrcAdapterMapping.value.length > 0) {
    options.push(PolicyInitType.IMPORT_FROM_MRC);
  }
  options.push(PolicyInitType.BLANK_POLICY);
  return (
    <>
      {options.map((option) => {
        const selected = option === props.selectedPolicyType;
        const [topText, bottomText] = (() => {
          switch (option) {
            case PolicyInitType.IMPORT_FROM_MRC:
              return ["Import an existing MRC", "Import using an existing word document or PDF"];
            case PolicyInitType.BLANK_POLICY:
              return ["New blank policy", "Start with a blank template"];
            default:
              assertNever(option);
          }
        })();
        return (
          <div
            key={option}
            className={
              (selected ? "bg-blue-50 border-blue-600" : "bg-white border-gray-200") +
              " cursor-pointer p-4 rounded-md border-solid border-2 flex flex-col gap-2.5"
            }
            data-testid={option === PolicyInitType.BLANK_POLICY ? "new-blank-policy" : undefined}
            onClick={() => props.setPolicyType(option)}
          >
            <h1 className="text-lg leading-7 font-semibold text-gray-700 flex flex-row gap-2.5 items-center">
              <input type="radio" className="" readOnly={true} checked={selected}></input>
              {topText}
            </h1>
            <p className="text-sm leading-5 font-normal text-gray-700">{bottomText}</p>
          </div>
        );
      })}
    </>
  );
};

const NewPolicyComponent = (props: {
  productId: ProductId | undefined;
  canCreateNewPolicy: Permission<unknown, unknown, AddP>;
  products: Product[];
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path;
}): JSX.Element => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const canCreateNewPolicy = props.canCreateNewPolicy.check(undefined);
  const product = props.products.find((product) => product.id == props.productId);
  const history = useHistory();

  const mrcAdapterMapping = useGetMrcProductAdapterMapping(
    product !== undefined ? product.id : undefined,
    product !== undefined ? product.version : undefined,
  );
  throwIfAppError(mrcAdapterMapping);

  const launchModal = async () => {
    if (product !== undefined && mrcAdapterMapping.value.length === 0) {
      // No MRC adapters, and the user already selected a product, so we can just create the policy.
      await createAndGoToPolicy(product.id, "true", history, props.policyPathFunction);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      {canCreateNewPolicy && props.products.length > 0 && (
        <Button
          data-testid="new-policy-button"
          type="button"
          variant="primary"
          onClick={launchModal}
          disabled={isLoading}
        >
          <PlusSmIcon className="h-5 w-5 mr-1" />
          New policy
        </Button>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <NewPolicyModal
          productId={props.productId}
          canCreateNewPolicy={props.canCreateNewPolicy}
          products={props.products}
          policyPathFunction={props.policyPathFunction}
          setIsLoading={setIsLoading}
        />
      </Modal>
    </>
  );
};

const createAndGoToPolicy = async (
  pid: ProductId,
  editing: "true" | "mrc-upload",
  history: H.History<H.LocationState>,
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path,
) => {
  const r = await newPolicy(pid);
  if (r.status === "success") {
    if (editing === "mrc-upload") {
      history.push(
        Routes.mrcUpload.generatePath({ capture: { policyId: r.value.id, productId: pid } }),
      );
    } else if (editing === "true") {
      history.push(policyPathFunction(pid, r.value.id, "true"));
    } else {
      assertNever(editing);
    }
  }
};

const enum ModalPage {
  SELECT_PRODUCT,
  SELECT_TYPE,
}

type SelectProduct = {
  page: ModalPage.SELECT_PRODUCT;
  productId: ProductId | undefined;
  productVersion: ProductVersion | undefined;
};

type SelectInitType = {
  page: ModalPage.SELECT_TYPE;
  productId: ProductId;
  productVersion: ProductVersion;
  initType: PolicyInitType | null;
};

type ModalState = SelectProduct | SelectInitType;

const NewPolicyModal = (props: {
  productId: ProductId | undefined;
  canCreateNewPolicy: Permission<unknown, unknown, AddP>;
  products: Product[];
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path;
  setIsLoading: (loading: boolean) => void;
}): JSX.Element => {
  const product = props.products.find((product) => product.id == props.productId);

  const defaultModalState = (product: Product | undefined): ModalState => {
    if (product === undefined) {
      return {
        page: ModalPage.SELECT_PRODUCT,
        productId: undefined,
        productVersion: undefined,
      };
    } else {
      return {
        page: ModalPage.SELECT_TYPE,
        productId: product.id,
        productVersion: product?.version,
        initType: null,
      };
    }
  };

  const [modalState, setModalState] = React.useState(defaultModalState(product));
  const [modalHistory, setModalHistory] = React.useState<ModalState[]>([]);

  function loader(): JSX.Element {
    return (
      <div className="h-30 flex justify-center">
        <Loader />
      </div>
    );
  }

  function renderPage(): JSX.Element {
    const page = modalState.page;
    switch (page) {
      case ModalPage.SELECT_PRODUCT:
        return (
          <SelectProductUI
            productId={modalState.productId}
            products={props.products}
            setChosenProductId={(productId, productVersion) => {
              setModalState({ page: ModalPage.SELECT_PRODUCT, productId, productVersion });
            }}
          />
        );
      case ModalPage.SELECT_TYPE:
        return (
          <SelectPolicyTypeUI
            productId={modalState.productId}
            productVersion={modalState.productVersion}
            selectedPolicyType={modalState.initType}
            setPolicyType={(policyInitType) => {
              setModalState({ ...modalState, initType: policyInitType });
            }}
          />
        );
      default:
        assertNever(page);
    }
  }

  return (
    <div className="p-6 space-y-3 w-screen-md">
      <div className="space-y-1">
        <p className="text-xl font-bold">New policy</p>
      </div>
      <div className="py-1 space-y-4 flex flex-col max-h-3/4">
        <div className="p-3 bg-gray-50 overflow-auto flex flex-col gap-4 rounded-lg">
          <Suspense fallback={loader()}>{renderPage()}</Suspense>
        </div>
        <div className="flex flex-row gap-4 justify-end">
          {modalHistory.length > 0 && (
            <Button
              variant="secondary"
              onClick={async (ev) => {
                ev.stopPropagation();
                setModalHistory((history) => {
                  const states = [...history];
                  const lastState = states.pop();
                  if (lastState !== undefined) {
                    setModalState(lastState);
                  }
                  return states;
                });
              }}
            >
              Prev
            </Button>
          )}
          <Suspense
            fallback={
              <Button variant="primary" disabled={true}>
                Next
              </Button>
            }
          >
            <NewPolicyModalNextButton
              modalState={modalState}
              selectTypePage={(productId, productVersion) => {
                setModalHistory([...modalHistory, modalState]);
                setModalState({
                  page: ModalPage.SELECT_TYPE,
                  productId,
                  productVersion,
                  initType: null,
                });
              }}
              policyPathFunction={props.policyPathFunction}
              setIsLoading={props.setIsLoading}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

const NewPolicyModalNextButton = (props: {
  modalState: ModalState;
  selectTypePage: (productId: ProductId, productVersion: PolicyId) => void;
  policyPathFunction: (
    productId: ProductId,
    policyId: PolicyId,
    editing: "true" | undefined,
  ) => string | Path;
  setIsLoading: (loading: boolean) => void;
}): JSX.Element => {
  const history = useHistory();
  const mrcAdapterMapping = useGetMrcProductAdapterMapping(
    props.modalState.page === ModalPage.SELECT_PRODUCT ? props.modalState.productId : undefined,
    props.modalState.page === ModalPage.SELECT_PRODUCT
      ? props.modalState.productVersion
      : undefined,
  );
  throwIfAppError(mrcAdapterMapping);

  const createAndGoToPolicy_ = async (pid: ProductId, editing: "true" | "mrc-upload") => {
    props.setIsLoading(true);
    await createAndGoToPolicy(pid, editing, history, props.policyPathFunction);
    props.setIsLoading(false);
  };

  // Returns a function which should be called when the next button is pressed,
  // or null if the button should be disabled
  const continueAction = (state: ModalState): (() => void) | null => {
    const page = state.page;
    if (page === ModalPage.SELECT_PRODUCT) {
      const productId = state.productId;
      const productVersion = state.productVersion;
      if (productId === undefined || productVersion == undefined) {
        return null;
      } else if (mrcAdapterMapping.value.length === 0) {
        return () => createAndGoToPolicy_(productId, "true");
      } else {
        return () => {
          props.selectTypePage(productId, productVersion);
        };
      }
    } else if (page === ModalPage.SELECT_TYPE) {
      const policyInitType = state.initType;
      if (policyInitType === null) {
        return null;
      } else if (policyInitType === PolicyInitType.IMPORT_FROM_MRC) {
        return () => createAndGoToPolicy_(state.productId, "mrc-upload");
      } else if (policyInitType === PolicyInitType.BLANK_POLICY) {
        return () => createAndGoToPolicy_(state.productId, "true");
      } else {
        assertNever(policyInitType);
      }
    } else {
      assertNever(page);
    }
  };

  return (
    <Button
      variant="primary"
      data-testid="new-policy-modal-next-button"
      onClick={async (ev) => {
        ev.stopPropagation();
        const action = continueAction(props.modalState);
        if (action !== null) {
          action();
        }
      }}
      disabled={continueAction(props.modalState) === null}
    >
      Next
    </Button>
  );
};

export default Policies;
