import React from "react";

type Props = {
  className?: string;
  id?: string;
  label: string;
  name: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
  type: string;
  value: string;
  disabled?: boolean;
} & typeof defaultProps;
const defaultProps = {
  type: "text",
};

const Input = ({ className, id, label, name, onChange, type, value, disabled }: Props) => (
  <div className={className}>
    <label className="block text-sm text-gray-700 font-medium mb-2" htmlFor={id ?? name}>
      {label}
    </label>

    <input
      className="px-3 py-2 border border-gray-300 shadow-sm rounded w-full focus:outline-none focus:ring-blue-500 focus:border-blue-600 sm:text-sm"
      id={id ?? name}
      name={name}
      onChange={onChange}
      type={type}
      value={value}
      disabled={disabled}
    />
  </div>
);

export default Input;
