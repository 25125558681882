// Goal: similar to Airtable's interface
import { Button } from "/src/design_system/Button";
import React from "react";
import { renderNode } from "../node";
import { generalEditorOrSummaryView, NodeT } from "../types";
import { LeafNodeT_ } from "./base";
import { FocusableRefObject } from "./focusable_node";
import { Loader } from "/src/design_system/Loader";
import { GoalInfo, QuoteId, ReferMap } from "/src/internal_types";

export interface SubformsNodeT_ extends LeafNodeT_ {
  type: "subforms";
  elems: Array<{
    id: string;
    nodes: Array<NodeT>;
    removeItem: () => void;
  }>;
  isCommitting: boolean;
  addItem: () => void;
  quoteId?: QuoteId;
}

export const SubformsNode = (props: {
  node: SubformsNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}): JSX.Element =>
  generalEditorOrSummaryView(
    props.node,
    (n) => (
      <SubformsNodeEditor
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
    (n) => (
      <SubformsNodeSummary
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
  );

export const SubformsNodeEditor = (props: {
  node: SubformsNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}) => {
  const { node } = props;
  return (
    <div>
      <div className="p-0 text-md text-gray-700 font-semibold py-3">{node.label}</div>
      <div className="space-y-4">
        {node.elems.map((elem) => (
          <div key={elem.id} className="px-4 pt-2 pb-4 space-y-6 rounded border border-gray-200">
            {elem.nodes.map((childNode) => (
              <div key={childNode.id} data-nodeid={childNode.id} data-quoteid={node.quoteId}>
                {renderNode(childNode, props.refObject, props.referMap, props.fields)}
              </div>
            ))}
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                elem.removeItem();
              }}
            >
              Remove
            </Button>
          </div>
        ))}
        <div className="flex space-x-2">
          <Button
            onClick={() => {
              node.addItem();
            }}
            className="space-x-2"
          >
            <span>Add</span>
            {node.isCommitting && <Loader size="sm" color="white" />}
          </Button>
          {node.problems.length > 0 && (
            <span className="text-red-500 font-normal self-center">{node.problems.join(", ")}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export const SubformsNodeSummary = (props: {
  node: SubformsNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}) => {
  const { node } = props;
  return (
    <div>
      <div className="space-y-4 pb-2">
        {node.elems.map((elem) => (
          <div className="m-4 pt-2 space-y-6 rounded border  border-gray-00">
            {elem.nodes.map((childNode) => (
              <div key={childNode.id} data-nodeid={childNode.id} data-quoteid={node.quoteId}>
                {renderNode(childNode, props.refObject, props.referMap, props.fields)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
