import { Asyncs } from "/src/asyncs";
import {
  BadgeCheckIcon,
  ChatIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  RefreshIcon,
  XIcon,
} from "@heroicons/react/solid";
import * as Popover from "@radix-ui/react-popover";
import { assertNever } from "/src/utils";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { throwIfAppError } from "/src/utils/app_error";
import { Reason, parseReason, renderReason } from "./reason";
import { QuoteNullableContext } from "./context";
import { prettyPrintTime } from "/src/utils/time";
import {
  AcceptP,
  AddP,
  ArchiveP,
  BindP,
  DeleteP,
  determineQuoteState,
  EditP,
  GenerateMultiQuoteFileP,
  GlobalPolicyState,
  NotTakeUpP,
  PartialQuoteState,
  Permission,
  PolicyState,
  QuoteState,
  RejectP,
  SeeAppetiteP,
  SendToBrokerP,
  SendToUnderwriterP,
  Show,
  ShowAcceptedFieldsP,
  ShowBoundFieldsP,
  ShowConditionsWarningP,
  ShowDeclineReasonsInHeaderP,
  ShowReferralHeaderP,
  ShowReferralReasonsInHeaderP,
  ShowReferralStatusForDatapoints,
  ShowRejectedFieldsP,
  SubmitP,
  UndeclineP,
} from "./permissions";
import { ActionRequiredBadges, badgeColor, QuoteStatusBadge } from "./policies";
import {
  acceptQuote,
  bindQuote,
  postChat,
  rejectQuote,
  setQuoteReviewer,
  submitQuote,
  notTakeUpQuote,
  undeclineQuote,
  useGetAggregationTriggersForPolicy,
  useGetAllUsers,
  useGetCurrentUser,
  useGetFirstChatMessage,
  useGetQuote,
  OrAppError,
} from "/src/dal/dal";
import {
  AcceptEditor,
  BindEditor,
  getFirstErrorNode,
  NotTakenUpEditor,
  QuoteEditor,
  RejectEditor,
  TxStatus,
} from "/src/data_editor";
import { getDatapointId } from "/src/data_editor/builder";
import { useFocusableRefObject } from "/src/data_editor/nodes/focusable_node";
import { NodeT } from "/src/data_editor/types";
import { Button } from "/src/design_system/Button";
import { Modal } from "../../design_system/Modal";
import {
  Datapoint,
  getDeclineConditions,
  getReferConditions,
  PolicyId,
  PolicyInfo,
  ProductId,
  QuoteId,
  QuoteInfo,
  UserId,
} from "/src/internal_types";
import { filterMap } from "/src/utils";
import { Routes } from "/src/routing/routes";
import { useParams } from "/src/routing/routing";
import { BadgeProps } from "/src/components/Badge";

export type QuoteViewProps = {
  quoteId: QuoteId;
  quoteNumber: number;
  isOpen: boolean;
  policy: PolicyInfo;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  permissions: QuoteViewPermissions;
  initialIsEditing: boolean;
  highlightErrorInPolicy: (dp: Datapoint[]) => void;
  asyncs: Asyncs;
};

type AdditionalWindowMode =
  | "accept"
  | "pass_to_broker"
  | "decline"
  | "pass_to_underwriter"
  | "not_taken_up";

export const QuoteView = (props: QuoteViewProps): JSX.Element => {
  const quoteResp = useGetQuote(props.policy.productId, props.policy.id, props.quoteId);
  throwIfAppError(quoteResp);
  const quote = quoteResp.value;
  const quoteState = determineQuoteState(quote, props.policy);
  const quoteKeys = [
    { keys: quote.fields.submissionGoal.keys },
    { keys: quote.fields.quoteGoal.keys, quoteId: quote.id },
  ];
  const triggers = useGetAggregationTriggersForPolicy(
    props.policy.productId,
    props.policy.id,
    props.quoteId,
  );
  throwIfAppError(triggers);

  const triggersReferred: Reason[] = triggers.value
    .filter((x) => x.tag === "AggregationTriggerTriggered" && x.contents[1] === "Refer")
    .map((x) => {
      return { segments: [{ type: "text", value: x.contents[2] ?? "Reason not given" }] };
    });
  const triggersDeclined: Reason[] = triggers.value
    .filter((x) => x.tag === "AggregationTriggerTriggered" && x.contents[1] === "Decline")
    .map((x) => {
      return { segments: [{ type: "text", value: x.contents[2] ?? "Reason not given" }] };
    });

  const [additionalWindowModeSubmit, setAdditionalWindowModeSubmit_] =
    React.useState<AdditionalWindowProps["submit"]>();
  const [isEditing, setIsEditing] = React.useState<boolean>(props.initialIsEditing);
  useEffect(() => {
    if (
      !props.permissions.canEditQuote.check(quoteState) ||
      additionalWindowModeSubmit !== undefined
    ) {
      setIsEditing(false);
    }
  }, [quoteState]);
  const additionalWindowRef = useRef(null);
  const setAdditionalWindowModeSubmit: typeof setAdditionalWindowModeSubmit_ = (x) => {
    setAdditionalWindowModeSubmit_(x);
    if (x !== undefined && additionalWindowRef.current !== null) {
      setTimeout(() => {
        (additionalWindowRef.current as any as HTMLDivElement).scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 250);
    }
  };

  const [bindModalOpened, setBindModalOpened] = React.useState<boolean>(false);
  const showReferralHeader = props.permissions.canShowReferralHeader.check(quoteState);
  const showAcceptedCaption = props.permissions.canShowAcceptedCaption.check(quoteState);
  const showReferralReasonsInHeader =
    props.permissions.canShowReferralReasonsInHeader.check(quoteState);
  const showDeclineReasonsInHeader =
    props.permissions.canShowDeclineReasonsInHeader.check(quoteState);

  const showAcceptedFields = props.permissions.canShowAcceptedFields.check(quoteState);
  const showRejectedFields = props.permissions.canShowRejectedFields.check(quoteState);
  const showBoundFields = props.permissions.canShowBoundFields.check(quoteState);

  const nodesRef = React.useRef<NodeT[]>([]);
  const [submitted, setSubmitted] = React.useState(false);

  const chatMessage = determineChatMessageToShow(quote);
  const [refObjectQuote, scrollToQuoteField] = useFocusableRefObject();
  const [refObjectBind, _scrollToBindField] = useFocusableRefObject();
  const [editorIsLoading, setEditorIsLoading] = React.useState(false);

  const onSubmitError = async (dps?: Datapoint[]) => {
    setSubmitted(true);
    setAdditionalWindowModeSubmit(undefined);
    setIsEditing(true);
    const firstNode = getFirstErrorNode(nodesRef.current);
    await new Promise((resolve) => setTimeout(resolve, 100));
    const id =
      dps !== undefined && dps.length > 0
        ? getDatapointId(
            dps[0].key,
            dps[0].arguments.flatMap((x) => {
              if (x.tag === "Scalar") {
                return [x.contents];
              } else {
                return [];
              }
            }),
          )
        : firstNode?.id;
    const focusableDiv = await scrollToQuoteField(id);
    if (focusableDiv === undefined && dps !== undefined) {
      setIsEditing(false);
      props.highlightErrorInPolicy(dps);
    }
  };

  const onSubmitSuccess = () => {
    setIsEditing(false);
    setSubmitted(false);
  };

  const callback = async (result: OrAppError<QuoteInfo>) => {
    switch (result.status) {
      case "success": {
        onSubmitSuccess();
        break;
      }
      case "error":
        {
          let errs: Datapoint[] | undefined;
          if (result.value.tag === "QuoteErr" && result.value.contents.tag === "BadRelativeDates") {
            errs = result.value.contents.contents.map((x) => x.datapoint);
          } else if (
            result.value.tag === "QuoteErr" &&
            result.value.contents.tag === "GoalObstacle"
          ) {
            errs = result.value.contents.contents.flatMap((x) => {
              switch (x.tag) {
                case "OViolation":
                  return [x.contents.datapoint];
                case "OError":
                  return [];
                case "OMissingData":
                  return [x.contents];
                case "OForbiddenAdjustment":
                  return [];
                case "OGlobalInvalid":
                  return [];
                default:
                  assertNever(x);
              }
            });
          }
          await onSubmitError(errs);
        }
        break;
    }
  };

  return (
    <QuoteNullableContext.Provider value={{ quote: quote }}>
      <div className="bg-white rounded overflow-hidden">
        <HeaderCaptionComponent
          title={`Quote #${quote.quoteNumber}`}
          borderColor={badgeColor(quote, props.policy)}
          color={badgeColor(quote, props.policy)}
          caption={
            showReferralHeader
              ? "The quote has been referred"
              : showAcceptedCaption
              ? "The quote has been accepted"
              : undefined
          }
          reasons={
            showDeclineReasonsInHeader
              ? getDeclineConditions(quote)
                  .map((r) => parseReason(r, quoteKeys))
                  .concat(triggersDeclined) // TODO here we are using quote.fields, and for Rules we are using policy.fields, that is inconsistent and relies on the fact that we have no data in quotes in Talbot, and on the fact that at some point quotes will be removed
              : showReferralReasonsInHeader
              ? getReferConditions(quote)
                  .map((r) => parseReason(r, quoteKeys))
                  .concat(triggersReferred)
              : []
          }
          badge={() => (
            <div className="flex space-x-2">
              <QuoteStatusBadge quote={quote} policy={props.policy} />
              <ActionRequiredBadges quote={quote} policy={props.policy} />
            </div>
          )}
        />
        {chatMessage !== undefined && (
          <ChatMessageHeader
            userId={chatMessage.userId}
            time={chatMessage.time}
            policyId={props.policy.id}
            quoteId={quote.id}
            quoteNumber={quote.quoteNumber}
            productId={props.policy.productId}
          />
        )}
        <QuoteEditor
          summaryView={!isEditing}
          canEdit={props.permissions.canEditQuote}
          quoteInfo={quote}
          policyInfo={props.policy}
          onTxStatusChange={(x) => {
            setEditorIsLoading(x === "in_flight");
            props.onTxStatusChange?.(x);
          }}
          onNodesChange={(n) => (nodesRef.current = n)}
          refObject={refObjectQuote}
          formSubmitted={submitted}
          canShowReferralStatusForDatapointsForQuote={
            props.permissions.canShowReferralStatusForDatapointsForQuote
          }
          hideAcceptDatapoints={showAcceptedFields}
          canShowConditionsWarning={props.permissions.canShowConditionsWarningQuote}
          asyncs={props.asyncs}
        />
        {showAcceptedFields && (
          <AcceptEditor
            summaryView={true}
            quoteInfo={quote}
            policyInfo={props.policy}
            onTxStatusChange={props.onTxStatusChange}
            onNodesChange={() => {
              return;
            }}
            refObject={refObjectBind}
            formSubmitted={false}
            asyncs={props.asyncs}
          />
        )}
        {showRejectedFields && (
          <RejectEditor
            summaryView={true}
            quoteInfo={quote}
            policyInfo={props.policy}
            onTxStatusChange={props.onTxStatusChange}
            onNodesChange={() => {
              return;
            }}
            refObject={refObjectBind}
            formSubmitted={false}
            asyncs={props.asyncs}
          />
        )}
        {showBoundFields && (
          <BindEditor
            summaryView={true}
            quoteInfo={quote}
            policyInfo={props.policy}
            onTxStatusChange={props.onTxStatusChange}
            showAdjustable={false}
            onNodesChange={() => {
              return;
            }}
            refObject={refObjectBind}
            formSubmitted={false}
            asyncs={props.asyncs}
          />
        )}
        <Actions
          setActionLoading={setEditorIsLoading}
          mainActions={[
            props.permissions.canShowBindQuote.check(quoteState) && {
              onClick: async () => setBindModalOpened(true),
              label: "Request to bind",
            },
            props.permissions.canShowSubmitQuote.check(quoteState) && {
              onClick: async () => {
                if (props.permissions.canSubmitQuote.check(quoteState)) {
                  const res = await submitQuote(props.policy.productId, props.policy.id, quote.id);
                  await callback(res);
                } else {
                  await onSubmitError();
                }
              },
              label: "Submit",
            },
            props.permissions.canShowAcceptQuote.check(quoteState) && {
              onClick: async () => {
                setAdditionalWindowModeSubmit(
                  submitFunction("accept", quote, props.policy.productId),
                );
              },
              label: "Accept",
            },
            quoteState.tag === "Referred" &&
              props.permissions.canSendToUnderwriter.check(quoteState) && {
                onClick: async () => {
                  setAdditionalWindowModeSubmit(
                    submitFunction("pass_to_underwriter", quote, props.policy.productId),
                  );
                },
                label: "Pass to underwriter",
              },
          ]}
          warningActions={[
            props.permissions.canShowRejectQuote.check(quoteState) && {
              onClick: async () => {
                setAdditionalWindowModeSubmit(
                  submitFunction("decline", quote, props.policy.productId),
                );
              },
              label: "Decline",
            },
          ]}
          secondaryActions={[
            !isEditing &&
              nodesRef.current.length > 0 &&
              props.permissions.canEditQuote.check(quoteState) && {
                onClick: async () => {
                  setAdditionalWindowModeSubmit(undefined);
                  setIsEditing(true);
                },
                label: "Edit",
              },
            isEditing &&
              nodesRef.current.length > 0 && {
                onClick: async () => {
                  setIsEditing(false);
                },
                label: "Done editing",
              },
          ]}
          otherActions={[
            props.permissions.canSendToBroker.check(quoteState) && {
              onClick: async () => {
                setAdditionalWindowModeSubmit(
                  submitFunction("pass_to_broker", quote, props.policy.productId),
                );
              },
              label: "Pass to broker",
            },
            quoteState.tag !== "Referred" &&
              props.permissions.canSendToUnderwriter.check(quoteState) && {
                onClick: async () => {
                  setAdditionalWindowModeSubmit(
                    submitFunction("pass_to_underwriter", quote, props.policy.productId),
                  );
                },
                label: "Pass to underwriter",
              },
            props.permissions.canShowNotTakeUpQuote.check(quoteState) && {
              onClick: async () => {
                setAdditionalWindowModeSubmit(
                  submitFunction("not_taken_up", quote, props.policy.productId),
                );
              },
              label: "Not taken up",
            },
            props.permissions.canUndeclineQuote.check(quoteState) && {
              onClick: async () => {
                if (confirm("Are you sure you want to undecline this quote?")) {
                  await undeclineQuote(props.policy.productId, props.policy.id, quote.id);
                }
              },
              label: "Undecline",
            },
          ]}
          isLoading={editorIsLoading}
        />
        <div ref={additionalWindowRef}>
          {additionalWindowModeSubmit !== undefined && (
            <AdditionalWindow
              quote={quote}
              policy={props.policy}
              submit={additionalWindowModeSubmit}
              close={() => setAdditionalWindowModeSubmit(undefined)}
              messageShouldBeNotEmpty={determineMessageShouldBeNotEmpty(
                additionalWindowModeSubmit.mode,
              )}
              canAcceptPermission={props.permissions.canAcceptQuote}
              canRejectPermission={props.permissions.canRejectQuote}
              canNotTakeUpPermission={props.permissions.canNotTakeUpQuote}
              asyncs={props.asyncs}
            />
          )}
        </div>
        <BindModal
          isOpen={bindModalOpened}
          quote={quote}
          policy={props.policy}
          close={() => setBindModalOpened(false)}
          asyncs={props.asyncs}
        />
      </div>
    </QuoteNullableContext.Provider>
  );
};

const submitFunction = (
  mode: AdditionalWindowMode,
  quote: QuoteInfo,
  productId: ProductId,
): AdditionalWindowProps["submit"] => {
  switch (mode) {
    case "accept": {
      return {
        mode: mode,
        callback: async (message: string) => {
          await acceptQuote(productId, quote.policyId, quote.id);
          message.trim().length > 0 &&
            (await postChat(productId, quote.policyId, message, quote.id));
        },
      };
    }
    case "pass_to_broker": {
      return {
        mode: mode,
        callback: async (message: string) => {
          await setQuoteReviewer(productId, quote.policyId, quote.id, "broker");
          message.trim().length > 0 &&
            (await postChat(productId, quote.policyId, message, quote.id));
        },
      };
    }
    case "pass_to_underwriter": {
      return {
        mode: mode,
        callback: async (message: string) => {
          await setQuoteReviewer(productId, quote.policyId, quote.id, "underwriter");
          message.trim().length > 0 &&
            (await postChat(productId, quote.policyId, message, quote.id));
        },
      };
    }
    case "decline": {
      return {
        mode: mode,
        callback: async (message: string) => {
          await rejectQuote(productId, quote.policyId, quote.id);
          message.trim().length > 0 &&
            (await postChat(productId, quote.policyId, message, quote.id));
        },
      };
    }
    case "not_taken_up": {
      return {
        mode: mode,
        callback: async (message: string) => {
          if (confirm("Are you sure you want to not take up this quote? It cannot be undone.")) {
            await notTakeUpQuote(productId, quote.policyId, quote.id);
            message.trim().length > 0 &&
              (await postChat(productId, quote.policyId, message, quote.id));
          }
        },
      };
    }
  }
};

type AdditionalWindowProps = {
  submit: {
    mode: AdditionalWindowMode;
    callback: (message: string) => Promise<void>;
  };
  quote: QuoteInfo;
  policy: PolicyInfo;
  close: () => void;
  messageShouldBeNotEmpty?: boolean;
  canAcceptPermission: Permission<GlobalPolicyState, QuoteState, AcceptP>;
  canRejectPermission: Permission<GlobalPolicyState, QuoteState, RejectP>;
  canNotTakeUpPermission: Permission<GlobalPolicyState, QuoteState, NotTakeUpP>;
  asyncs: Asyncs;
};

const additionalWindowTitle = (mode: AdditionalWindowMode): string => {
  switch (mode) {
    case "accept":
      return "Accept";
    case "pass_to_broker":
      return "Pass to broker";
    case "pass_to_underwriter":
      return "Pass to underwriter";
    case "decline":
      return "Decline";
    case "not_taken_up":
      return "Not taken up";
  }
};

const AdditionalWindow = (props: AdditionalWindowProps): JSX.Element => {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const title = additionalWindowTitle(props.submit.mode);
  const note =
    currentUser.value.role === "underwriter" ? "Message to broker" : "Message to underwriter";
  const [message, setMessage] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState(false);
  const [submittedOnce, setSubmittedOnce] = React.useState(false);
  const nodesRef = React.useRef<NodeT[]>([]);
  const [refObject, scrollToField] = useFocusableRefObject();

  return (
    <div className="px-4">
      <div className="flex justify-between">
        <span className="text-base font-medium">{title}</span>
        <button onClick={props.close}>
          <XIcon className="h-5 text-gray-500" />
        </button>
      </div>
      <div className="space-y-3 py-6">
        {props.submit.mode === "accept" && (
          <AcceptEditor
            quoteInfo={props.quote}
            policyInfo={props.policy}
            refObject={refObject}
            onNodesChange={(n) => (nodesRef.current = n)}
            formSubmitted={submittedOnce}
            asyncs={props.asyncs}
          />
        )}
        {props.submit.mode === "decline" && (
          <RejectEditor
            quoteInfo={props.quote}
            policyInfo={props.policy}
            refObject={refObject}
            onNodesChange={(n) => (nodesRef.current = n)}
            formSubmitted={submittedOnce}
            asyncs={props.asyncs}
          />
        )}
        {props.submit.mode === "not_taken_up" && (
          <NotTakenUpEditor
            quoteInfo={props.quote}
            policyInfo={props.policy}
            refObject={refObject}
            onNodesChange={(n) => (nodesRef.current = n)}
            formSubmitted={submittedOnce}
            asyncs={props.asyncs}
          />
        )}
        <div className="flex flex-col">
          <p className="pb-1">{note}</p>
          {submittedOnce && props.messageShouldBeNotEmpty && message.trim().length === 0 && (
            <p className="text-red-500"> Note cannot be empty</p>
          )}
          <textarea
            className="rounded border text-sm border-gray-300 h-24"
            onChange={(e) => setMessage(e.currentTarget.value)}
          ></textarea>
        </div>
        <div className="space-x-3 flex flex-row">
          <Button
            variant="primary"
            onClick={async () => {
              setSubmittedOnce(true);
              setSubmitting(true);
              if (props.messageShouldBeNotEmpty && message.trim().length === 0) {
                setSubmitting(false);
                return;
              }
              const quoteState = determineQuoteState(props.quote, props.policy);
              switch (props.submit.mode) {
                case "accept":
                  const canAccept = props.canAcceptPermission.check(quoteState);
                  if (canAccept) {
                    await props.submit.callback(message);
                    setSubmitting(false);
                    props.close();
                  } else {
                    const firstNode = getFirstErrorNode(nodesRef.current);
                    await scrollToField(firstNode?.id);
                    setSubmitting(false);
                  }
                  break;
                case "decline":
                  const canReject = props.canRejectPermission.check(quoteState);
                  if (canReject) {
                    await props.submit.callback(message);
                    setSubmitting(false);
                    props.close();
                  } else {
                    const firstNode = getFirstErrorNode(nodesRef.current);
                    await scrollToField(firstNode?.id);
                    setSubmitting(false);
                  }
                  break;
                case "not_taken_up":
                  const canNotTakeUp = props.canNotTakeUpPermission.check(quoteState);
                  if (canNotTakeUp) {
                    await props.submit.callback(message);
                    setSubmitting(false);
                    props.close();
                  } else {
                    const firstNode = getFirstErrorNode(nodesRef.current);
                    await scrollToField(firstNode?.id);
                    setSubmitting(false);
                  }
                  break;
                case "pass_to_broker":
                case "pass_to_underwriter":
                  await props.submit.callback(message);
                  setSubmitting(false);
                  props.close();
                  break;
              }
            }}
            disabled={submitting}
          >
            Submit
          </Button>
          <Button
            className="rounded px-3 py-2 border border-gray-300 font-semibold"
            onClick={props.close}
            disabled={submitting}
            variant="secondary"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

type Action = { onClick: () => Promise<void>; label: string };

const Actions = (props: {
  mainActions: (Action | false)[];
  warningActions: (Action | false)[];
  secondaryActions: (Action | false)[];
  otherActions: (Action | false)[];
  isLoading: boolean;
  setActionLoading: (isLoading: boolean) => void;
}): JSX.Element => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const filterActions = (as: (Action | false)[]): Action[] => {
    return filterMap(as, (c) => {
      if (c !== false) {
        return c;
      } else {
        return undefined;
      }
    });
  };
  const mainActions = filterActions(props.mainActions);
  const warningActions = filterActions(props.warningActions);
  const secondaryActions = filterActions(props.secondaryActions);
  const otherActions = filterActions(props.otherActions);
  if (
    mainActions.length + warningActions.length + secondaryActions.length + otherActions.length ===
    0
  ) {
    return <></>;
  }
  const onClick = (oc: () => Promise<void>) => {
    return async () => {
      setPopoverOpen(false);
      props.setActionLoading(true);
      await oc();
      props.setActionLoading(false);
    };
  };
  return (
    <div className="px-4 py-4 flex justify-between">
      <div className="flex space-x-2">
        {mainActions.map((a) => (
          <Button key={a.label} onClick={onClick(a.onClick)} disabled={props.isLoading}>
            {a.label}
          </Button>
        ))}
        {warningActions.map((a) => (
          <Button
            key={a.label}
            onClick={onClick(a.onClick)}
            disabled={props.isLoading}
            variant="danger"
          >
            {a.label}
          </Button>
        ))}
        {secondaryActions.map((a) => (
          <Button
            key={a.label}
            onClick={onClick(a.onClick)}
            disabled={props.isLoading}
            variant="secondary"
          >
            {a.label}
          </Button>
        ))}
      </div>
      <div>
        {otherActions.length > 0 && (
          <Popover.Root open={popoverOpen} onOpenChange={setPopoverOpen}>
            <Popover.Trigger
              className={`w-36 inline-flex justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ${
                props.isLoading ? "opacity-50" : ""
              }`}
            >
              <span className={"w-full whitespace-nowrap"}> More options </span>
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" />
            </Popover.Trigger>

            <Popover.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              align="start"
              className={`rounded ring-1 ring-black ring-opacity-5  overflow-y-auto outline-none`}
            >
              {otherActions.map((v) => {
                return (
                  <Button
                    className="w-36 "
                    onClick={onClick(v.onClick)}
                    disabled={props.isLoading}
                    variant={"secondary"}
                    key={v.label}
                  >
                    <span className="w-full text-left"> {v.label}</span>
                  </Button>
                );
              })}
            </Popover.Content>
          </Popover.Root>
        )}
      </div>
    </div>
  );
};

export type HeaderCaption = {
  caption?: string;
  reasons?: Reason[];
  title: string;
  color: BadgeProps["color"];
  borderColor: BadgeProps["color"];
  badge: () => JSX.Element;
};

const HeaderCaptionComponent = (props: HeaderCaption): JSX.Element => {
  const color =
    props.color === "red"
      ? "bg-red-50 text-red-700"
      : props.color === "green"
      ? "bg-green-50 text-green-600"
      : props.color === "blue"
      ? "bg-blue-50 text-blue-500"
      : "bg-gray-5 text-gray-700";
  const borderColor =
    props.color === "red"
      ? "border-red-700"
      : props.color === "green"
      ? "border-green-500"
      : props.color === "blue"
      ? "border-blue-500"
      : "border-blue-500";

  return (
    <div className={`rounded-t border-t-2 ${borderColor}`}>
      <div className={`${color} border-b border-gray-200`}>
        <div className="flex justify-between bg-white px-4 py-6">
          <span className="text-base text-gray-700 font-medium tracking-wide">{props.title}</span>
          {props.badge()}
        </div>
        {(props.caption !== undefined ||
          (props.reasons !== undefined && props.reasons.length > 0)) && (
          <div className="py-4 pl-4">
            {props.caption != undefined && (
              <div className="flex pr-3 space-x-2">
                {props.color === "red" && <ExclamationCircleIcon className="h-5" />}
                {props.color === "green" && <CheckCircleIcon className="h-5" />}
                <span className="font-semibold"> {props.caption} </span>
              </div>
            )}
            {props.reasons !== undefined && props.reasons.length > 0 && (
              <ul className="text-gray-900 space-y-2 mt-2">
                {props.reasons.map((r) => {
                  return <li className="mx-5 list-disc">{renderReason(r)}</li>;
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export type ChatMessageHeaderProps = {
  productId: ProductId;
  quoteId: QuoteId;
  quoteNumber: number;
  policyId: PolicyId;
  userId: UserId;
  time: string;
};

const ChatMessageHeader = (props: ChatMessageHeaderProps): JSX.Element => {
  const { productId, quoteId, policyId, userId, time } = props;
  const msg = useGetFirstChatMessage(productId, policyId, quoteId, userId, time);
  const users = useGetAllUsers();
  const mDateTime = prettyPrintTime(time);

  if (msg.status === "error" || users.status === "error") {
    return <></>;
  }
  const user = users.value.users.find((u) => u.id === userId);
  if (user === undefined) {
    return <></>;
  }
  if (msg.value.length === 0) {
    return <></>;
  }

  return (
    <div className="">
      <div className={`bg-gray-200 `}>
        <div className="px-4 pb-3 flex flex-row space-x-2 pt-4">
          <div className="flex space-x-2">
            <ChatIcon className="h-5 text-blue-600" />
          </div>
          <div className="space-y-3">
            <p>
              {user.displayName} left a note @ {mDateTime.time} on {mDateTime.date}
            </p>
            <p className="font-semibold">{msg.value[0].contents}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

type BindModalProps = {
  policy: PolicyInfo;
  quote: QuoteInfo;
  isOpen: boolean;
  close: () => void;
  asyncs: Asyncs;
};

type BindModalMode = "form" | "confirm" | "binding" | "bound";

const BindModal = (props: BindModalProps): JSX.Element => {
  const { data } = useParams(Routes.policy);
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);

  const [bindModalMode, setBindModalMode] = React.useState<BindModalMode>("form");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const nodesRef = React.useRef<NodeT[]>([]);
  const [refObject, scrollToField] = useFocusableRefObject();
  const history = useHistory();
  const canSubmit =
    !props.quote.fields.bindGoal.obstructed && !props.quote.fields.quoteGoal.obstructed;
  const close = async () => {
    props.close();
    setTimeout(() => {
      setBindModalMode("form");
    }, 300);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={close}>
      <div className="">
        <div className="flex">
          <div className="flex-1 w-80"></div>
          <div className="flex-1 w-80"></div>
        </div>
        {bindModalMode === "form" && (
          <div className="py-8">
            <div className="pb-3 flex justify-between px-6">
              <span className="text-xl font-semibold self-centered">Request to bind</span>
            </div>
            <div className="text-sm text-gray-500 px-6">
              Are you sure you want to bind this quote? This action cannot be undone.
            </div>
            <div className="py-3">
              <BindEditor
                policyInfo={props.policy}
                quoteInfo={props.quote}
                showAdjustable={true}
                onTxStatusChange={() => {
                  return;
                }}
                onNodesChange={(n) => (nodesRef.current = n)}
                formSubmitted={isSubmitted}
                refObject={refObject}
                asyncs={props.asyncs}
              />
            </div>
            <div className="flex justify-end space-x-3 px-6">
              <Button onClick={close} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setIsSubmitted(true);
                  if (canSubmit) {
                    setBindModalMode("confirm");
                  } else {
                    const firstNode = getFirstErrorNode(nodesRef.current);
                    await scrollToField(firstNode?.id);
                  }
                }}
                variant="primary"
              >
                Request to bind
              </Button>
            </div>
          </div>
        )}
        {bindModalMode === "confirm" && (
          <ViewWithIcon
            icon={(className: string) => <ExclamationIcon className={className} />}
            iconBackground="bg-red-200"
            iconColor="text-red-800"
            secondaryButton={["Cancel", close]}
            primaryButton={[
              "Yes I want to bind this quote",
              async () => {
                setBindModalMode("binding");
                const result = await bindQuote(
                  props.policy.productId,
                  props.policy.id,
                  props.quote.id,
                );
                if (result.status === "success") {
                  setBindModalMode("bound");
                } else {
                  setBindModalMode("form");
                  if (
                    result.value.tag === "QuoteErr" &&
                    result.value.contents.tag === "BadRelativeDates"
                  ) {
                    const dps = result.value.contents.contents.map((x) => x.datapoint);
                    if (dps === undefined || dps.length === 0) {
                      return;
                    }
                    const id = getDatapointId(
                      dps[0].key,
                      dps[0].arguments.flatMap((x) => {
                        if (x.tag === "Scalar") {
                          return [x.contents];
                        } else {
                          return [];
                        }
                      }),
                    );
                    await scrollToField(id);
                  }
                }
              },
            ]}
            title="Are you sure you want to request to bind?"
            description="Are you sure you want to bind this quote? This action cannot be undone."
          />
        )}
        {bindModalMode === "binding" && (
          <ViewWithIcon
            icon={(className: string) => <RefreshIcon className={className} />}
            iconBackground="bg-green-100"
            iconColor="text-green-600"
            title="Binding quote..."
          />
        )}
        {bindModalMode === "bound" && (
          <ViewWithIcon
            icon={(className: string) => <BadgeCheckIcon className={className} />}
            iconBackground="bg-green-100"
            iconColor="text-green-700"
            title="Quote bound!"
            description={`Your quote was successfully bound - nice work, ${currentUser.value.displayName}!`}
            primaryButton={["View policy", close]}
            secondaryButton={[
              "Back to inbox",
              () => {
                history.push(
                  Routes.policies.generatePath({
                    query: {
                      ...data.query,
                      productId: props.policy.productId,
                    },
                  }),
                );
              },
            ]}
          />
        )}
      </div>
    </Modal>
  );
};

type ViewWithIconProps = {
  icon: (className: string) => JSX.Element;
  iconBackground: string;
  iconColor: string;
  title: string;
  description?: string;
  primaryButton?: [string, () => void];
  secondaryButton?: [string, () => void];
};

const ViewWithIcon = (props: ViewWithIconProps): JSX.Element => {
  return (
    <div className="flex px-8 py-8 space-x-5">
      <div>
        <div className={`rounded-3xl p-1.5 ${props.iconBackground}`}>
          {props.icon(`h-5 ${props.iconColor}`)}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <p className="text-xl font-semibold">{props.title}</p>
        </div>
        <div className="text-gray-500 text-base py-3">
          {props.description !== undefined && <p>{props.description}</p>}
        </div>
        <div className="flex justify-end space-x-3">
          {props.secondaryButton !== undefined && (
            <Button onClick={props.secondaryButton[1]} variant="secondary">
              {props.secondaryButton[0]}
            </Button>
          )}
          {props.primaryButton !== undefined && (
            <Button onClick={props.primaryButton[1]} variant="primary">
              {props.primaryButton[0]}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const determineChatMessageToShow = (
  quoteInfo: QuoteInfo,
): undefined | { time: string; userId: UserId } => {
  if (
    quoteInfo.derivedStatus.tag === "Referred" &&
    quoteInfo.referral.tag === "Happened" &&
    quoteInfo.isWith.happened.performedBy !== undefined
  ) {
    return {
      time: quoteInfo.isWith.happened.happenedAt,
      userId: quoteInfo.isWith.happened.performedBy,
    };
  }
  if (
    quoteInfo.derivedStatus.tag === "Declined" &&
    quoteInfo.referral.tag === "Happened" &&
    quoteInfo.referral.contents.happened.tag === "Happened" &&
    quoteInfo.referral.contents.happened.contents.performedBy !== undefined
  ) {
    return {
      time: quoteInfo.referral.contents.happened.contents.happenedAt,
      userId: quoteInfo.referral.contents.happened.contents.performedBy,
    };
  }
};

const determineMessageShouldBeNotEmpty = (mode: AdditionalWindowMode): boolean => {
  const modes: AdditionalWindowMode[] = ["pass_to_broker", "pass_to_underwriter"];
  return modes.includes(mode);
};

export type QuoteViewPermissions = {
  canAddNewQuote: Permission<GlobalPolicyState, PolicyState, AddP>;
  canShowAddNewQuote: Permission<GlobalPolicyState, PolicyState, Show<AddP>>;
  canEditQuote: Permission<GlobalPolicyState, QuoteState, EditP>;
  canNotTakeUpQuote: Permission<GlobalPolicyState, QuoteState, NotTakeUpP>;
  canShowNotTakeUpQuote: Permission<GlobalPolicyState, QuoteState, Show<NotTakeUpP>>;
  canUndeclineQuote: Permission<GlobalPolicyState, QuoteState, UndeclineP>;
  canBindQuote: Permission<GlobalPolicyState, QuoteState, BindP>;
  canShowBindQuote: Permission<GlobalPolicyState, QuoteState, Show<BindP>>;
  canShowSubmitQuote: Permission<GlobalPolicyState, QuoteState, Show<SubmitP>>;
  canSubmitQuote: Permission<GlobalPolicyState, QuoteState, SubmitP>;
  canShowAcceptQuote: Permission<GlobalPolicyState, QuoteState, Show<AcceptP>>;
  canAcceptQuote: Permission<GlobalPolicyState, QuoteState, AcceptP>;
  canShowRejectQuote: Permission<GlobalPolicyState, QuoteState, Show<RejectP>>;
  canRejectQuote: Permission<GlobalPolicyState, QuoteState, RejectP>;
  canSendToBroker: Permission<GlobalPolicyState, QuoteState, SendToBrokerP>;
  canSendToUnderwriter: Permission<GlobalPolicyState, QuoteState, SendToUnderwriterP>;
  canShowReferralHeader: Permission<GlobalPolicyState, QuoteState, ShowReferralHeaderP>;
  canShowAcceptedCaption: Permission<GlobalPolicyState, QuoteState, ShowReferralHeaderP>;
  canShowReferralReasonsInHeader: Permission<
    GlobalPolicyState,
    QuoteState,
    ShowReferralReasonsInHeaderP
  >;
  canShowDeclineReasonsInHeader: Permission<
    GlobalPolicyState,
    QuoteState,
    ShowDeclineReasonsInHeaderP
  >;
  canShowAcceptedFields: Permission<GlobalPolicyState, QuoteState, ShowAcceptedFieldsP>;
  canShowRejectedFields: Permission<GlobalPolicyState, QuoteState, ShowRejectedFieldsP>;
  canShowBoundFields: Permission<GlobalPolicyState, QuoteState, ShowBoundFieldsP>;
  canGenerateMultiQuoteFile: Permission<
    GlobalPolicyState,
    PartialQuoteState,
    GenerateMultiQuoteFileP
  >;
  canShowReferralStatusForDatapointsForQuote: Permission<
    GlobalPolicyState,
    QuoteState,
    ShowReferralStatusForDatapoints
  >;
  canShowConditionsWarningQuote: Permission<
    GlobalPolicyState,
    PartialQuoteState,
    ShowConditionsWarningP
  >;
  // policy permissions
  canArchivePolicy: Permission<GlobalPolicyState, PolicyState, ArchiveP>;
  canDeletePolicy: Permission<GlobalPolicyState, PolicyState, DeleteP>;
  canShowConditionsWarningPolicy: Permission<
    GlobalPolicyState,
    PolicyState,
    ShowConditionsWarningP
  >;
  canSeeAppetite: Permission<GlobalPolicyState, PolicyState, SeeAppetiteP>;
};
