import React, { useEffect, useState } from "react";
import {
  useGetMrcResolvedExtraction,
  storeMrcExtractionCandidate,
  invalidateMrcResolvedExtraction,
  useGetMrcDatapointCandidates,
  invalidateMrcDatapointCandidates,
  invalidateMrcResolvedDatapoints,
  useGetAnyMrcImported,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import { Routes } from "/src/routing/routes";
import { useParams } from "react-router";
import {
  MrcCandidateId,
  MrcDocumentId,
  MrcResolvedValue,
  PolicyId,
  ProductId,
  Scalar,
  ScalarTyp,
} from "/src/internal_types";
import { SearchCircleIcon } from "@heroicons/react/solid";
import {
  BreadcrumbBackArrow,
  BreadcrumbLink,
  BreadcrumbSep,
  BreadcrumbText,
  BreadcrumbWrapper,
} from "./breadcrumbs";
import { scalarString } from "./all_datapoints";
import { YesNoNode } from "/src/data_editor/nodes/yes_no_node";
import { DateNode } from "/src/data_editor/nodes/date_node";
import { SingleNodeT_ } from "/src/data_editor/types";
import { TextNode } from "/src/data_editor/nodes/text_node";
import { NumberNode } from "/src/data_editor/nodes/number_node";
import { PercentNode } from "/src/data_editor/nodes/percent_node";
import { assertNever } from "/src/utils";
import clsx from "clsx";
import { UmrNode } from "/src/data_editor/nodes/umr_node";
import { Badge } from "/src/components/Badge";

type NullableContents<T> = {
  [P in keyof T]: P extends "contents" ? T[P] | null : T[P];
};

// Scalar, but with each of the "contents" being unioned with null
type NullableScalar = Scalar extends any ? NullableContents<Scalar> : never;

const nullableScalarToScalar = (scalar: NullableScalar): Scalar | null => {
  if (scalar.contents === null) {
    return null;
  }
  const tag = scalar.tag;
  // This is a little awkward but it appeases typescript
  switch (tag) {
    case "Bool":
      return { tag: "Bool", contents: scalar.contents };
    case "CaselessText":
      return { tag: "CaselessText", contents: scalar.contents };
    case "Date":
      return { tag: "Date", contents: scalar.contents };
    case "DateDuration":
      return { tag: "DateDuration", contents: scalar.contents };
    case "File":
      return { tag: "File", contents: scalar.contents };
    case "Int":
      return { tag: "Int", contents: scalar.contents };
    case "Number":
      return { tag: "Number", contents: scalar.contents };
    case "Percent":
      return { tag: "Percent", contents: scalar.contents };
    case "Text":
      return { tag: "Text", contents: scalar.contents };
    case "Time":
      return { tag: "Time", contents: scalar.contents };
    case "Umr":
      return { tag: "Umr", contents: scalar.contents };
    case "Uuid":
      return { tag: "Uuid", contents: scalar.contents };
    default:
      assertNever(tag);
  }
};

export const SingleDatapointEditView = (props: {
  productId: ProductId;
  policyId: PolicyId;
  documentId: MrcDocumentId;
  jumpToBoundingBox: (candidateId: number) => void;
}): JSX.Element => {
  const params = useParams<{ extractionId: string }>();
  const extractionId = Number.parseInt(params.extractionId);
  const anyImported = useGetAnyMrcImported(props.productId, props.policyId);
  throwIfAppError(anyImported);

  const extraction = useGetMrcResolvedExtraction(
    props.productId,
    props.policyId,
    props.documentId,
    extractionId,
  );
  throwIfAppError(extraction);
  const remoteState = extraction.value.resolvedValue;
  const candidates = useGetMrcDatapointCandidates(
    props.productId,
    props.policyId,
    props.documentId,
    extractionId,
  );
  throwIfAppError(candidates);

  // State types
  type Extracted = { type: "Extracted"; value: Scalar; candidateId: MrcCandidateId };
  type Overridden = { type: "Overridden"; value: NullableScalar; initialRawValue: string };
  type Missing = { type: "Missing"; scalarType: ScalarTyp };
  type State = Extracted | Overridden | Missing;

  // Transitions
  const overrideValue = (value: Scalar): Overridden => {
    return { type: "Overridden", value: value, initialRawValue: "" };
  };
  const overrideFromExtracted = (extraction: Extracted): Overridden => {
    return { type: "Overridden", value: extraction.value, initialRawValue: "" };
  };
  const useExtractedValue = (value: Scalar, id: MrcCandidateId): Extracted => {
    return { type: "Extracted", value: value, candidateId: id };
  };

  const remoteToLocalState = (rv: MrcResolvedValue): State => {
    const tag = rv.tag;
    if (tag === "ResolvedValueExtracted") {
      return {
        type: "Extracted",
        value: rv.contents.value,
        candidateId: rv.contents.candidateId,
      };
    } else if (tag === "ResolvedValueOverridden") {
      return {
        type: "Overridden",
        value: rv.contents.value,
        initialRawValue: "",
      };
    } else if (tag === "ResolvedValueMissing") {
      return {
        type: "Missing",
        scalarType: rv.contents,
      };
    } else {
      assertNever(tag);
    }
  };

  const [localState, setLocalState] = useState<State>(remoteToLocalState(remoteState));

  useEffect(() => {
    setLocalState(remoteToLocalState(remoteState));
  }, [remoteState]);

  const setState = async (state: State, push = true): Promise<void> => {
    if (anyImported.value) {
      return;
    }
    setLocalState(state);
    if (push) {
      if (state.type === "Missing") {
        return;
      }
      const scalar = nullableScalarToScalar(state.value);
      if (scalar === null) {
        return;
      }
      await storeMrcExtractionCandidate(
        props.productId,
        props.policyId,
        props.documentId,
        extraction.value.id,
        state.type === "Extracted" ? { Left: state.candidateId } : { Right: scalar },
      );
      await invalidateMrcResolvedExtraction(
        props.productId,
        props.policyId,
        props.documentId,
        extraction.value.id,
      );
      await invalidateMrcDatapointCandidates(
        props.productId,
        props.policyId,
        props.documentId,
        extraction.value.id,
      );
      await invalidateMrcResolvedDatapoints(props.productId, props.policyId, props.documentId);
    }
  };

  function mkNode<T>(
    value: T | null,
    initialRawValue: string,
    toScalar: (v: T) => Scalar,
  ): SingleNodeT_<T> {
    return {
      type: "",
      value: value,
      initialRawValue: initialRawValue,
      commit: async (v) => {
        if (v !== null) {
          await setState(overrideValue(toScalar(v)));
        }
      },
      id: "",
      label: "",
      description: "",
      readOnly: localState.type === "Extracted" || (anyImported.value as any),
      hidden: false,
      isCommitting: false,
      formSubmitted: false,
      needsCollecting: false,
      summaryView: false,
      problems: [],
    };
  }

  const scalarInput = (value: NullableScalar, initialRawValue: string): JSX.Element => {
    const clickable = (el: JSX.Element): JSX.Element => {
      return (
        <div className="relative">
          {el}
          {localState.type === "Extracted" && (
            <div
              onClick={async (_) => {
                await setState(overrideFromExtracted(localState));
              }}
              className={
                "absolute left-0 top-0 w-full h-full" + (anyImported.value ? "" : " cursor-pointer")
              }
            ></div>
          )}
        </div>
      );
    };
    const typ = value.tag;
    switch (typ) {
      case "Bool":
        return clickable(
          <YesNoNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Bool", contents: v })),
              type: "yes_no",
              customNoPrompt: undefined,
              customYesPrompt: undefined,
            }}
          />,
        );
      case "CaselessText":
        return clickable(
          <TextNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({
                tag: "CaselessText",
                contents: v,
              })),
              type: "text",
              isMultiline: false,
            }}
          />,
        );
      case "Date":
        return clickable(
          <DateNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Date", contents: v })),
              type: "date",
              minimum: null,
              maximum: null,
            }}
          />,
        );
      case "Int":
        return clickable(
          <NumberNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Int", contents: v })),
              type: "number",
              minimum: null,
              maximum: null,
              prefix: undefined,
            }}
          />,
        );
      case "Number":
        return clickable(
          <NumberNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Number", contents: v })),
              type: "number",
              minimum: null,
              maximum: null,
              prefix: undefined,
            }}
          />,
        );
      case "Percent":
        return clickable(
          <PercentNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Percent", contents: v })),
              type: "percent",
            }}
          />,
        );
      case "Text":
        return clickable(
          <TextNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Text", contents: v })),
              type: "text",
              // Sometimes this may be necessary
              isMultiline: false,
            }}
          />,
        );
      case "Uuid":
        return clickable(
          <TextNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Uuid", contents: v })),
              type: "text",
              isMultiline: false,
            }}
          />,
        );
      case "Umr":
        return clickable(
          <UmrNode
            node={{
              ...mkNode(value.contents, initialRawValue, (v) => ({ tag: "Umr", contents: v })),
              type: "umr",
            }}
          />,
        );
      case "DateDuration":
      case "File":
      case "Time":
        return <p>Not yet implemented</p>;
      default:
        assertNever(typ);
    }
  };

  const stateInput = (state: State): JSX.Element => {
    switch (state.type) {
      case "Extracted":
        return scalarInput(state.value, "");
      case "Overridden":
        return scalarInput(state.value, state.initialRawValue);
      case "Missing":
        return scalarInput({ tag: state.scalarType, contents: null }, "");
    }
  };

  return (
    <div className="p-6">
      <BreadcrumbWrapper>
        <BreadcrumbLink
          to={Routes.mrcDocumentView.generatePath({
            capture: {
              productId: props.productId,
              policyId: props.policyId,
              documentId: props.documentId,
            },
          })}
        >
          <BreadcrumbBackArrow />
          Extraction results
        </BreadcrumbLink>
        <BreadcrumbSep />
        <BreadcrumbText truncate={true}>{extraction.value.displayName}</BreadcrumbText>
        <BreadcrumbSep />
        <BreadcrumbText grow={true}>Edit</BreadcrumbText>
      </BreadcrumbWrapper>
      <h3 className="my-4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-500">
        Manual Override
      </h3>
      <h4 className="my-1.5 text-sm leading-5 font-medium text-gray-700">
        {extraction.value.displayName}
      </h4>
      {stateInput(localState)}
      <h3 className="my-4 text-xs leading-4 font-medium tracking-wider uppercase text-gray-500">
        Suggested Pairs
      </h3>
      <ul className="flex flex-col gap-4">
        {candidates.value.map((candidate) => {
          return (
            <li className="flex flex-row" key={candidate.id}>
              <div
                className={clsx([
                  "grow flex mr-4 overflow-hidden items-center gap-2",
                  anyImported.value ? "" : " cursor-pointer",
                ])}
                onClick={async (_) => {
                  if (candidate.extractedScalar !== undefined) {
                    // If we actually have an extracted scalar, we can just set
                    // that as selected and use it as-is
                    await setState(useExtractedValue(candidate.extractedScalar, candidate.id));
                  } else {
                    // Otherwise, we need to force the user to edit the value
                    // until it is valid.
                    await setState({
                      type: "Overridden",
                      value: { tag: extraction.value.scalarTyp, contents: null },
                      initialRawValue: candidate.extractedValue as string,
                    });
                  }
                }}
              >
                <input
                  type="radio"
                  className={
                    "h-4 w-4 border-gray-300 mt-0.5 mr-2 " +
                    (anyImported.value
                      ? "checked:hover:bg-gray-500 checked:bg-gray-500"
                      : "cursor-pointer")
                  }
                  disabled={true}
                  checked={
                    localState.type === "Extracted" && localState.candidateId === candidate.id
                  }
                />
                <label
                  className={clsx([
                    "overflow-hidden break-words",
                    anyImported.value ? "" : "cursor-pointer",
                  ])}
                >
                  {candidate.extractedScalar !== undefined &&
                    scalarString(candidate.extractedScalar)}
                  {candidate.extractedScalar === undefined && (candidate.extractedValue as string)}
                </label>
                {candidate.extractedScalar === undefined && <Badge label="Invalid" color="red" />}
              </div>
              <div
                className="text-gray-500 text-xs flex flex-col whitespace-nowrap text-right cursor-pointer"
                onClick={(_) => props.jumpToBoundingBox(candidate.id)}
              >
                <div>
                  <SearchCircleIcon className="text-blue-500 w-4 h-4 inline mr-1 -mt-0.5" />
                  Page {candidate.pageNum}
                </div>
                <div>
                  {/* TODO textConfidence? */}
                  {candidate.matchConfidence}% Confidence
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
