import React, { Suspense } from "react";
import { Route as ReactRoute, Switch, useHistory } from "react-router-dom";
import {
  invalidateGetAnyMrcImported,
  postMrcCancelImport,
  postMrcImport,
  useGetAnyMrcImported,
  useGetPolicy,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import { useParams } from "/src/routing/routing";
import { Routes } from "/src/routing/routes";
import { MrcPage, MrcSplitPane } from "./page";
import { MrcDocumentViewer, useMapState } from "./document/viewer";
import { SingleDatapointEditView } from "./document/single_datapoint_edit";
import { AllDatapointsView } from "./document/all_datapoints";
import { Loader } from "/src/design_system/Loader";
import { assertNever } from "/src/utils";

export const MrcDocumentView = (): JSX.Element => {
  const { data } = useParams(Routes.mrcDocumentView);
  const productId = data.capture.productId;
  const policyId = data.capture.policyId;
  const documentId = data.capture.documentId;
  const anyImported = useGetAnyMrcImported(productId, policyId);
  let onImport: null | (() => Promise<string | null>) = null;
  if (anyImported.status === "success" && !anyImported.value) {
    onImport = async () => {
      const result = await postMrcImport(productId, policyId, documentId);
      await invalidateGetAnyMrcImported(productId, policyId);
      const status = result.status;
      if (status === "error") {
        if (result.value.tag === "MrcImportErr") {
          return result.value.message;
        } else {
          return "Something went wrong";
        }
      } else if (status === "success") {
        history.push(
          Routes.policy.generatePath({
            capture: { productId, policyId },
          }),
        );
        return null;
      } else {
        assertNever(status);
      }
    };
  }

  const policy = useGetPolicy(productId, policyId);
  throwIfAppError(policy);
  const history = useHistory();

  // A map from candidate ID to element ref of the bounding box
  const [boundingBoxRefs, tellBoundingBoxRef] = useMapState(new Map());

  const jumpToBoundingBox = (candidateId: number) => {
    const ref = boundingBoxRefs.get(candidateId);
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MrcPage policy={policy.value} isUpdating={false}>
      <MrcSplitPane
        onCancel={async () => {
          await postMrcCancelImport(productId, policyId, documentId);
          history.push(
            Routes.policy.generatePath({
              capture: {
                productId: productId,
                policyId: policyId,
              },
            }),
          );
        }}
        onImport={onImport}
        leftPanel={
          <MrcDocumentViewer
            policy={policy.value}
            documentId={documentId}
            tellBoundingBoxRef={tellBoundingBoxRef}
          />
        }
        rightPanel={
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            }
          >
            <Switch>
              <ReactRoute exact path="*/datapoint/:extractionId">
                <SingleDatapointEditView
                  productId={productId}
                  policyId={policyId}
                  documentId={documentId}
                  jumpToBoundingBox={jumpToBoundingBox}
                />
              </ReactRoute>
              <ReactRoute>
                <AllDatapointsView
                  productId={productId}
                  policyId={policyId}
                  documentId={documentId}
                  jumpToBoundingBox={jumpToBoundingBox}
                />
              </ReactRoute>
            </Switch>
          </Suspense>
        }
      />
    </MrcPage>
  );
};
