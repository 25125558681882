import { ChevronDownIcon } from "@heroicons/react/solid";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";

import React from "react";

/**
 * Dropdown atom
 */
export const Dropdown = ({
  label,
  itemsChecked,
  children,
  noFixedHeight,
  disabled,
}: {
  label: string;
  itemsChecked?: number;
  children: React.ReactNode;
  noFixedHeight?: boolean;
  disabled?: boolean;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  disabled = disabled ?? false;
  return (
    <Popover.Root open={open && !disabled} onOpenChange={setOpen}>
      <Popover.Trigger
        className={clsx(
          "inline-flex",
          "justify-center",
          "rounded-md",
          "border",
          "border-gray-300",
          "shadow-sm",
          "px-4",
          "py-2",
          "bg-white",
          "text-sm",
          "font-medium",
          "text-gray-700",
          !disabled && "hover:bg-gray-50",
          "focus:outline-none",
          "focus:ring-2",
          "focus:ring-offset-2",
          "focus:ring-offset-gray-100",
          "focus:ring-blue-500",
          disabled && "opacity-50",
          disabled && "cursor-default",
        )}
      >
        <div className="relative flex">
          <span className={"w-full whitespace-nowrap"}> {label}</span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" />
          {itemsChecked !== undefined && itemsChecked > 0 && (
            <span className="absolute -top-2 -right-4 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-blue-600 rounded-full"></span>
          )}
        </div>
      </Popover.Trigger>

      <Popover.Content
        onOpenAutoFocus={(e) => e.preventDefault()}
        align="start"
        className={`mt-2 ${
          noFixedHeight ? "" : "max-h-52"
        } w-52 min-w-full rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5  overflow-y-auto outline-none`}
      >
        {children}
      </Popover.Content>
    </Popover.Root>
  );
};
