import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "./Layout";
import PasswordForm, { PasswordFormValues } from "./PasswordForm";
import { PublicRoutes } from "../../routing/routes";
import { parseParams } from "/src/utils";

const ResetPassword = () => {
  const { push } = useHistory();
  const location = useLocation();
  const { email, code } = parseParams(location.search);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (formValues: PasswordFormValues) => {
    setLoading(true);

    try {
      await aws_amplify.Auth.forgotPasswordSubmit(email, code, formValues.newPassword);

      setLoading(false);
      push(PublicRoutes.login.generatePath({}));
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <PasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
    </Layout>
  );
};

export default ResetPassword;
