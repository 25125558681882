import { PaperClipIcon, UploadIcon } from "@heroicons/react/solid";
import React, { ChangeEvent } from "react";
import {
  fileDownloadPath,
  getQueryKey,
  queryClient,
  uploadFile,
  useGetFileInfo,
} from "../../dal/dal";
import { humanFileSize } from "../../utils";
import { generalEditorOrSummaryView, SingleNodeT_ } from "../types";
import { LeafNode, SummaryLeafNode } from "./base";
import { throwIfAppError } from "/src/utils/app_error";

export const FileInfoNode = (props: {
  fileId: string;
  removeFile: () => void;
  readOnly: boolean;
}): JSX.Element => {
  const fileInfo = useGetFileInfo(props.fileId);
  throwIfAppError(fileInfo);

  return (
    <a href={fileDownloadPath(fileInfo.value.id)}>
      <div>
        <div className="cursor-pointer border max-w-min rounded px-3 my-2 py-2 group flex items-center hover:bg-gray-50">
          <PaperClipIcon className="flex-none lg:flex hidden h-5 w-5 p-0.5 mr-2 text-gray-400" />
          <div className="truncate">
            <div className="truncate max-w-fitext-left font-medium">{fileInfo.value.name}</div>
            <div className="text-left text-xs">{humanFileSize(fileInfo.value.size, true)}</div>
          </div>
          <div className="flex justify-end ">
            <UploadIcon className=" h-6 w-6 px-1 text-gray-400 group-hover:text-blue-600" />
          </div>
        </div>
        {!props.readOnly && (
          <button
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={props.removeFile}
          >
            Remove file
          </button>
        )}
      </div>
    </a>
  );
};

export interface FileNodeT_ extends SingleNodeT_<string> {
  type: "file";
}

export const FileNode = (props: { node: FileNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, FileNodeEditorView, FileNodeSummaryView);
};

const FileNodeEditorView = (props: { node: FileNodeT_ }): JSX.Element => {
  const { node } = props;
  const [localError, setLocalError] = React.useState<string | null>(null);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      setLocalError(null);
      const fileInfo = await uploadFile(event.target.files[0]);
      switch (fileInfo.status) {
        case 200:
          queryClient.setQueryData(
            getQueryKey("/api/file/{hash}", {
              params: { hash: fileInfo.result.id },
              query: {},
            }),
            { status: "success", value: fileInfo.result },
          );
          node.commit(fileInfo.result.id);
          break;
        case 409:
          setLocalError("File is too big");
          break;
      }
    }
  };

  const problems = node.problems.slice();
  if (localError !== null) {
    problems.push(localError);
  }

  const removeFile = () => {
    node.commit(null);
  };

  return (
    <LeafNode node={node}>
      {node.value === null ? (
        <div>{node.readOnly ? "--" : <input type="file" onChange={onFileChange} />}</div>
      ) : (
        <FileInfoNode fileId={node.value} removeFile={removeFile} readOnly={node.readOnly} />
      )}
    </LeafNode>
  );
};

const FileNodeSummaryView = (props: { node: FileNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafNode label={props.node.label}>
      {props.node.value !== null ? (
        <FileInfoNode
          removeFile={() => {
            return;
          }}
          readOnly={true}
          fileId={props.node.value}
        />
      ) : (
        ""
      )}
    </SummaryLeafNode>
  );
};
