import { Asyncs } from "/src/asyncs";
import { PencilIcon, XIcon } from "@heroicons/react/solid";
import * as History from "history";
import React, { Suspense } from "react";
import { useHistory } from "react-router";
import { CancellationModal } from "./cancellation_modal";
import { CloneModal } from "./clone_modal";
import {
  ActionButtonProps,
  Appetite,
  AssigneePicker,
  PolicyActions,
  QuoteDocuments,
} from "./right_panel";
import { deletePolicy, clonePolicy, archivePolicy, useGetCurrentUser } from "/src/dal/dal";
import { PolicyEditor, TxStatus } from "/src/data_editor";
import { Routes } from "/src/routing/routes";
import { FocusableRefObject } from "/src/data_editor/nodes/focusable_node";
import { NodeT } from "/src/data_editor/types";
import { Button } from "/src/design_system/Button";
import { AuthUser, PolicyId, PolicyInfo, ProductId, Quotes } from "/src/internal_types";
import { ItemLoader } from "/src/item_loader";
import { DataView } from "/src/pages/policy/data_view";
import {
  AddP,
  AssignToPolicyP,
  DeleteP,
  CancelP,
  CloneP,
  determinePolicyState,
  EditP,
  GlobalPolicyState,
  ArchiveP,
  Permission,
  PolicyState,
  ShowConditionsWarningP,
  ShowReferralStatusForDatapoints,
  SeeAppetiteP,
} from "/src/pages/policy/permissions";
import { throwIfAppError } from "/src/utils/app_error";
import { ToastIcons, useToasts } from "/src/components/Toast";

type PolicyViewProps = {
  policy: PolicyInfo;
  quotes: Quotes;
  canEditPolicy: Permission<GlobalPolicyState, PolicyState, EditP>;
  canArchivePolicy: Permission<GlobalPolicyState, PolicyState, ArchiveP>;
  canDeletePolicy: Permission<GlobalPolicyState, PolicyState, DeleteP>;
  canCancelPolicy: Permission<GlobalPolicyState, PolicyState, CancelP>;
  canClonePolicy: Permission<GlobalPolicyState, PolicyState, CloneP>;
  canAddNewQuotes: Permission<GlobalPolicyState, PolicyState, AddP>;
  canShowReferralStatusForDatapointsForPolicy: Permission<
    GlobalPolicyState,
    PolicyState,
    ShowReferralStatusForDatapoints
  >;
  canAssign: Permission<GlobalPolicyState, PolicyState, AssignToPolicyP>;
  canSeeAppetite: Permission<GlobalPolicyState, PolicyState, SeeAppetiteP>;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  isEditing: boolean;
  setIsEditing: (v: boolean) => void;
  refObject?: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  canShowConditionsWarningPolicy: Permission<
    GlobalPolicyState,
    PolicyState,
    ShowConditionsWarningP
  >;
  children?: React.ReactNode;
  asyncs: Asyncs;
};

export function PolicyView(props: PolicyViewProps) {
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);

  const addToast = useToasts();

  const policyState = determinePolicyState(props.policy);
  const canEdit = props.canEditPolicy.check(policyState);
  const history = useHistory();
  const [isCancellationModalOpen, setCancellationModalOpened] = React.useState(false);
  const boundQuote = props.quotes.quotes.find((q) => q.derivedStatus.tag === "Bound");

  const [isPolicyCloning, setCloningPolicy] = React.useState(false);
  const [isCloneModalOpen, setCloneModalOpened] = React.useState(false);

  const summaryView = !(canEdit && props.isEditing);

  return (
    <>
      <div className="grid justify-center w-full grid-cols-1 4xl:grid-cols-1 gap-6">
        <DataView>
          <Suspense fallback={<ItemLoader type={"loader"} size={"lg"} />}>
            <PolicyEditor
              policyInfo={props.policy}
              quotes={props.quotes}
              onTxStatusChange={(v) => {
                props.onTxStatusChange?.(v);
              }}
              refObject={props.refObject}
              onNodesChange={props.onNodesChange}
              summaryView={summaryView}
              formSubmitted={false}
              canShowReferralStatusForDatapointsForPolicy={
                props.canShowReferralStatusForDatapointsForPolicy
              }
              canShowConditionsWarningPolicy={props.canShowConditionsWarningPolicy}
              asyncs={props.asyncs}
            />
            {canEdit && (
              <div className="flex py-4">
                <div className="flex-1 justify-end -ml-4 -mr-4"></div>
                <Button
                  className=""
                  variant="secondary"
                  onClick={() => props.setIsEditing(!props.isEditing)}
                >
                  {props.isEditing && <XIcon className="h-5 w-5 mr-1 -ml-1 text-gray-500" />}
                  {!props.isEditing && <PencilIcon className="h-5 w-5 mr-1 -ml-1 text-gray-500" />}
                  <span>{props.isEditing ? "Close editing" : "Edit policy information"}</span>
                </Button>
              </div>
            )}
          </Suspense>
        </DataView>
        {props.children}
      </div>
      <div
        style={{
          height: `
        /* this has to be calculated in css unfortunately */
        /* the goal is to have a sticky div that takes up */
        /* the remaining height of the screen */
        calc(
        /* whole viewport height */
        100vh
        /* policy tab */
        - 54px
        /* page header */
        - 65px
        /* padding from top and policy tab */
        - 4px
        /* padding from the bottom of the page */
        - 48px)`,
        }}
        className=" flex flex-row top-4 w-96  sticky"
      >
        <div className="flex-auto overflow-y-auto overflow-x-hidden grow space-y-3">
          <Suspense fallback={<ItemLoader type={"loader"} size={"lg"} />}>
            {props.canAssign.check(policyState) && <AssigneePicker policyInfo={props.policy} />}
            <PolicyActions
              actions={determineActions(
                history,
                {
                  setCancellationModalOpened: setCancellationModalOpened,
                  isPolicyCloning,
                  setCloningPolicy,
                  setCloneModalOpened,
                  currentUser: currentUser.value,
                  ...props,
                },
                addToast,
              )}
            />
            {boundQuote !== undefined && (
              <QuoteDocuments
                productId={props.policy.productId}
                policyId={props.policy.id}
                quoteId={boundQuote.id}
              />
            )}
          </Suspense>
          {props.canSeeAppetite.check(policyState) && (
            <Appetite policy={props.policy} quotes={props.quotes} />
          )}
        </div>
      </div>
      <CloneModal isOpen={isCloneModalOpen} close={() => setCloneModalOpened(false)} />
      <CancellationModal
        isOpen={isCancellationModalOpen}
        close={() => setCancellationModalOpened(false)}
        policy={props.policy}
      />
    </>
  );
}

type determineActionsArgs = {
  policy: PolicyInfo;
  quotes: Quotes;
  currentUser: AuthUser;
  setCancellationModalOpened: (b: boolean) => void;
  setCloningPolicy: (b: boolean) => void;
  setCloneModalOpened: (b: boolean) => void;
  isPolicyCloning: boolean;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  canCancelPolicy: Permission<GlobalPolicyState, PolicyState, CancelP>;
  canArchivePolicy: Permission<GlobalPolicyState, PolicyState, ArchiveP>;
  canDeletePolicy: Permission<GlobalPolicyState, PolicyState, DeleteP>;
  canClonePolicy: Permission<GlobalPolicyState, PolicyState, CloneP>;
};

const determineActions = (
  history: History.History,
  args: determineActionsArgs,
  addToast: ReturnType<typeof useToasts>,
): Array<ActionButtonProps> => {
  const state = determinePolicyState(args.policy);
  const action = (f: (p: ProductId, pid: PolicyId) => Promise<unknown>): (() => Promise<void>) => {
    return async () => {
      args.onTxStatusChange?.("in_flight");
      await f(args.policy.productId, args.policy.id);
      args.onTxStatusChange?.("idle");
      return;
    };
  };
  const check = (p: Permission<GlobalPolicyState, PolicyState, unknown>): boolean => {
    return p.check(state);
  };

  const doPolicyClone = async (p: ProductId, pid: PolicyId): Promise<void> => {
    args.setCloningPolicy(true);
    try {
      const value = await clonePolicy(p, pid);
      throwIfAppError(value);
      args.setCloningPolicy(false);
      const newPolicyId = value.value.id;
      // This isn't beautiful, but without pushing / first, the page does not
      // reload
      history.push("/");
      history.push(
        Routes.policy.generatePath({
          capture: { productId: p, policyId: newPolicyId },
          query: {},
        }),
      );
      addToast(`clone-policy-${p}-${pid}`, {
        icon: ToastIcons.success,
        title: `Policy #${pid}`,
        description: `Policy #${pid} has been successfully cloned to #${newPolicyId}.`,
        dismissCaption: `Dismiss`,
      });
    } catch (err) {
      args.setCloningPolicy(false);
      args.setCloneModalOpened(true);
    }
  };
  return [
    check(args.canArchivePolicy) && {
      label: "Archive policy",
      onClick: action(async (productId, policyId) => {
        if (confirm("Are you sure you want to archive this policy? It cannot be undone.")) {
          const result = await archivePolicy(productId, policyId);
          if (result.status === "error") {
            alert(`Something went wrong: ${result.value.message}`);
          }
        }
      }),
      icon: "archive" as const,
    },
    check(args.canDeletePolicy) && {
      label: "Delete policy",
      onClick: action(async (productId, policyId) => {
        if (confirm("Are you sure you want to delete this policy? It cannot be undone.")) {
          return deletePolicy(productId, policyId).then(() => {
            history.push("/");
            addToast(`delete-policy-${productId}-${policyId}`, {
              icon: ToastIcons.success,
              title: `Policy #${policyId}`,
              description: `Policy #${policyId} has been successfully deleted.`,
              dismissCaption: `Dismiss`,
            });
          });
        }
      }),
      icon: "delete" as const,
    },
    check(args.canCancelPolicy) && {
      label: "Cancel policy",
      testid: "cancel-policy-button",
      onClick: action(async () => {
        args.setCancellationModalOpened(true);
      }),
      icon: "archive" as const,
    },
    check(args.canClonePolicy) && {
      label: "Clone policy",
      testid: "clone-policy-button",
      loading: args.isPolicyCloning,
      onClick: action(async (productId, policyId) => {
        return doPolicyClone(productId, policyId);
      }),
      icon: "clone" as const,
    },
  ].flatMap((action) => (action !== false ? [action] : []));
};
