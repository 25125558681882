import React from "react";
import {
  fileDownloadPath,
  getQueryKey,
  queryClient,
  uploadFile,
  useGetFileInfo,
} from "../../dal/dal";
import { Button } from "../../design_system/Button";
import { humanFileSize } from "../../utils";
import { generalEditorOrSummaryView } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafNode } from "./base";

export interface FileSetNodeT_ extends LeafNodeT_ {
  type: "file_set";
  value: string[];
  add: (file: string) => void;
  remove: (file: string) => void;
}

export const FileSetNode = (props: { node: FileSetNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, FileNodeEditorView, FileNodeSummaryView);
};

const FileNodeEditorView = (props: { node: FileSetNodeT_ }): JSX.Element => {
  const { node } = props;

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    for (const file of event.target.files ?? []) {
      await uploadFile(file).then((resp) => {
        if (resp.status === 200) {
          queryClient.setQueryData(
            getQueryKey("/api/file/{hash}", {
              params: { hash: resp.result.id },
              query: {},
            }),
            { status: "success", value: resp.result },
          );
          node.add(resp.result.id);
          event.target.value = "";
        } else if (resp.status === 409) {
          alert("File is too big.");
        }
      });
    }
  };

  return (
    <LeafNode node={node}>
      <div className="divide-y max-w-sm">
        {node.value.map((fileId) => (
          <FileRow key={fileId} fileId={fileId} onRemove={() => node.remove(fileId)} />
        ))}
      </div>

      <input className="mt-2" type="file" multiple onChange={onFileChange} />
    </LeafNode>
  );
};

function FileRow(props: { fileId: string; onRemove?: () => void }) {
  const { fileId, onRemove } = props;
  const fileInfo = useGetFileInfo(fileId);
  if (fileInfo.status === "error") {
    throw fileInfo;
  }
  return (
    <div className="flex items-center">
      <div className="py-2 pr-4 flex-1 overflow-hidden">
        <a
          className="underline truncate block font-medium text-blue-700"
          href={fileDownloadPath(fileInfo.value.id)}
        >
          {fileInfo.value.name}
        </a>
        <div className="text-xs mt-1 text-gray-600">{humanFileSize(fileInfo.value.size)}</div>
      </div>
      {onRemove !== undefined && (
        <div className="py-2 w-20 align-right">
          <Button size="xs" variant="danger-secondary" onClick={() => onRemove()}>
            Remove
          </Button>
        </div>
      )}
    </div>
  );
}

const FileNodeSummaryView = (props: { node: FileSetNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafNode label={props.node.label}>
      <div className="divide-y max-w-sm">
        {props.node.value.map((fileId) => (
          <FileRow key={fileId} fileId={fileId} />
        ))}
      </div>
    </SummaryLeafNode>
  );
};
