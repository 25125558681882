import clsx from "clsx";
import { useCombobox } from "downshift";
import { IsoOfficiallyAssigned, isoOfficiallyAssigned } from "iso-alpha2";
import React, { useEffect, useMemo } from "react";
import { Observable, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { generalEditorOrSummaryView, SingleNodeT_, useSingleNodeState } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";

export interface AddressNodeT_ extends SingleNodeT_<string> {
  readonly type: "address";
  countryRestriction?: string[];
}

type AddressNodeProps = { node: AddressNodeT_ };

const styles = {
  menuInner:
    "m-0 overflow-y-auto max-h-56 bg-white border border-gray-300 rounded text-gray-900  shadow",
  menuItem: "flex items-center px-3 py-2 cursor-pointer",
  menuOuter: "absolute inset-x-0 top-0 z-10 mt-2",
};

export const AddressNode = (props: AddressNodeProps): JSX.Element => {
  return generalEditorOrSummaryView(props.node, AddressNodeEditorView, AddressNodeSummaryView);
};

const AddressNodeEditorView = (props: AddressNodeProps): JSX.Element => {
  const { node } = props;
  const service = useMemo(() => new window.google.maps.places.AutocompleteService(), []);
  const inputValue$ = useMemo(() => new Subject<string | undefined>(), []);
  const [inputItems, setInputItems] = React.useState<string[]>([]);
  const [localValue, setLocalValue] = useSingleNodeState(node);

  const countryRestrictionCodes = useMemo(() => {
    const countryRestriction = node.countryRestriction?.map((c) => c.toUpperCase());
    const result =
      countryRestriction !== undefined
        ? isoOfficiallyAssigned.filter((code) => countryRestriction.includes(code))
        : undefined;
    if (
      countryRestriction !== undefined &&
      result !== undefined &&
      countryRestriction.length > result.length
    ) {
      const notIso = countryRestriction.filter((c) => {
        return isoOfficiallyAssigned.find((code) => code === c);
      });
      console.log(
        `Address node contains country restriction that does not match ISO 3166-1 alpha-2: ${notIso}`,
      );
    }
    return result;
  }, [JSON.stringify(node.countryRestriction)]);

  useEffect(() => {
    const itemsSub = inputValue$
      .pipe(
        debounceTime(100),
        switchMap((inputValue) => {
          if (inputValue === undefined || inputValue.trim() === "") {
            return [[]];
          } else {
            return getPlacePredictions$(service, inputValue, countryRestrictionCodes);
          }
        }),
      )
      .subscribe(setInputItems);
    const commitSub = inputValue$.pipe(debounceTime(2000)).subscribe((inputValue) => {
      node.commit(inputValue === undefined || inputValue.trim() === "" ? null : inputValue);
    });
    const localValueSub = inputValue$.subscribe((inputValue) => {
      setLocalValue(inputValue ?? "");
    });
    return () => {
      itemsSub.unsubscribe();
      commitSub.unsubscribe();
      localValueSub.unsubscribe();
    };
  }, []);

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } =
    useCombobox({
      items: inputItems,
      initialInputValue: node.value ?? "",
      onInputValueChange: ({ inputValue }) => {
        inputValue$.next(inputValue);
      },
      inputValue: localValue,
    });
  return (
    <LeafNode node={node}>
      <div {...getComboboxProps()} className="mt-2 relative">
        <TextField
          {...getInputProps()}
          autoComplete="disabled"
          className="aui-input"
          disabled={node.readOnly}
          node={node}
        />
      </div>
      <div className="relative" style={{ maxWidth: 500 }}>
        <div {...getMenuProps()} className={styles.menuOuter}>
          {isOpen && inputItems.length > 0 && (
            <ul className={styles.menuInner}>
              {inputItems.map((item, index) => (
                <li
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                  className={clsx(styles.menuItem, index === highlightedIndex && "bg-gray-100")}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </LeafNode>
  );
};

function getPlacePredictions$(
  service: google.maps.places.AutocompleteService,
  input: string,
  countryRestriction?: IsoOfficiallyAssigned[],
): Observable<string[]> {
  return new Observable((observer) => {
    void service.getPlacePredictions(
      {
        input,
        types: ["address"],
        ...(countryRestriction !== undefined
          ? { componentRestrictions: { country: countryRestriction } }
          : {}),
      },
      (results) => {
        observer.next(
          results?.map(
            (r) =>
              r.structured_formatting.main_text + ", " + r.structured_formatting.secondary_text,
          ) ?? [],
        );
        observer.complete();
      },
    );
  });
}

const AddressNodeSummaryView = (props: AddressNodeProps): JSX.Element => {
  return <SummaryLeafStringNode label={props.node.label} contents={[props.node.value ?? ""]} />;
};
