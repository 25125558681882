import { dequal } from "dequal";
import {
  ArchiveIcon,
  BanIcon,
  CheckCircleIcon,
  DownloadIcon,
  DuplicateIcon,
  ExclamationCircleIcon,
  PaperClipIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { TextField } from "@mui/material";
import { Loader } from "/src/design_system/Loader";
import { Autocomplete } from "@mui/lab";
import React, { PropsWithChildren, Suspense } from "react";
import {
  getFile,
  setPolicyAssignee,
  useGetAggregationTriggersForPolicy,
  useGetAllUsers,
  useGetFileInfo,
  useGetQuote,
  useGetQuoteDocuments,
} from "/src/dal/dal";
import {
  checkNotUndefined,
  checkValueConditions,
  downloadFile,
  getValueFromNonListDatapoint,
  filterMap,
} from "/src/utils";
import {
  ConditionResult,
  GoalsInfo,
  Ident,
  PolicyId,
  PolicyInfo,
  ProductId,
  QuoteId,
  QuoteInfo,
  Quotes,
} from "/src/internal_types";
import { throwIfAppError } from "/src/utils/app_error";
import { Reason, parseReason, renderReason } from "./reason";
import { SPECIAL_DATAPOINTS } from "/src/data_editor/constants";
import Accordion from "/src/design_system/Accordion";
import { Box } from "/src/design_system/Box";

const RightPanelContent = (props: PropsWithChildren<{ label: string }>): JSX.Element => {
  return (
    <div className="rounded w-96 pl-14 pr-6">
      <p className="font-semibold mb-3 text-base">{props.label}</p>
      <hr />
      <div className="pt-3 space-y-1">{props.children}</div>
    </div>
  );
};

export function QuoteDocuments(props: {
  productId: ProductId;
  policyId: PolicyId;
  quoteId: QuoteId;
}) {
  const data = useGetQuoteDocuments(props.productId, props.policyId, props.quoteId);
  throwIfAppError(data);
  if (data.value.documents.length === 0) {
    return <></>;
  }
  return (
    <RightPanelContent label={"Documentation"}>
      <div className="border rounded border-gray-300 divide-y divide-gray-300">
        {data.value.documents.map((doc) => {
          return <FileDownloadComponent key={doc.file} fileId={doc.file} filename={doc.document} />;
        })}
      </div>
    </RightPanelContent>
  );
}

const FileDownloadComponent = (props: { fileId: string; filename: string }): JSX.Element => {
  const data = useGetFileInfo(props.fileId);
  throwIfAppError(data);
  return (
    <ActionButton
      label={props.filename}
      icon="document"
      onClick={async () => {
        const blob = await getFile(data.value.id);
        downloadFile(blob, data.value.name);
      }}
    ></ActionButton>
  );
};

export type ActionButtonProps = {
  onClick: () => Promise<void>;
  label: string;
  testid?: string;
  loading?: boolean;
  icon: "document" | "archive" | "delete" | "clone";
};

function ActionButtonPropsIcon(props: ActionButtonProps, className: string): JSX.Element {
  switch (props.icon) {
    case "document":
      return <PaperClipIcon className={className} />;
    case "archive":
      return <ArchiveIcon className={className} />;
    case "delete":
      return <TrashIcon className={className} />;
    case "clone":
      return <DuplicateIcon className={className} />;
  }
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  return (
    <div className="flex">
      <button
        onClick={props.onClick}
        data-testid={props.testid}
        disabled={props.loading}
        className="px-3 py-2 group grow flex justify-start items-center hover:bg-gray-100 truncate"
      >
        {props.loading ? (
          <div className="mr-2">
            <Loader size="sm" />
          </div>
        ) : (
          ActionButtonPropsIcon(props, "flex-none lg:flex hidden h-5 w-5 p-0.5 mr-2 text-gray-400")
        )}
        <span className="grow truncate text-left font-medium leading-4">{props.label}</span>

        {props.icon === "document" && (
          <DownloadIcon className="flex-none h-6 w-6 px-1 text-gray-400 group-hover:text-blue-600" />
        )}
      </button>
    </div>
  );
};

export type PolicyActionsProps = {
  actions: ActionButtonProps[];
};

export const PolicyActions = (props: PolicyActionsProps): JSX.Element => {
  if (props.actions.length === 0) {
    return <></>;
  }
  return (
    <RightPanelContent label="Policy actions">
      <div className="border rounded border-gray-300 divide-y divide-gray-300">
        {props.actions.map((a) => {
          return <ActionButton key={a.label} {...a} />;
        })}
      </div>
    </RightPanelContent>
  );
};

// returns ALL of the conditions (including those that were not trigerred)
const getConditions = (fields: GoalsInfo, name: Ident): ConditionResult[] => {
  const result = getValueFromNonListDatapoint(fields, name)
    .andThen(checkNotUndefined)
    .andThen(checkValueConditions)
    .map((x) => x.reasons);
  return result.getRight() ?? [];
};

type Rules = {
  passed: Reason[];
  declined: Reason[];
  referred: Reason[];
};

function getRules(
  referralConditions: ConditionResult[],
  declineConditions: ConditionResult[],
  fields: GoalsInfo,
  quoteId?: QuoteId,
  quoteNumber?: number,
): Rules {
  const keys = [{ keys: fields.submissionGoal.keys }, { keys: fields.quoteGoal.keys, quoteId }];

  const passed = filterMap(referralConditions.concat(declineConditions), (x) => {
    if (x.tag === "ConditionFalse" && x._0 !== undefined) {
      return x._0;
    }
    return;
  }).map((s) => parseReason(s, keys, quoteNumber));
  const declined = filterMap(declineConditions, (x) => {
    if (x.tag === "ConditionTrue" && x.contents.tag === "CustomViolation") {
      return x.contents._0;
    }
    return;
  }).map((s) => parseReason(s, keys, quoteNumber));
  const referred = filterMap(referralConditions, (x) => {
    if (x.tag === "ConditionTrue" && x.contents.tag === "CustomViolation") {
      return x.contents._0;
    }
    return;
  }).map((s) => parseReason(s, keys, quoteNumber));

  return {
    passed,
    declined,
    referred,
  };
}

function getAllRules(policy: PolicyInfo, quotes: QuoteInfo[]): Rules {
  const policyReferralConditions = getConditions(policy.fields, SPECIAL_DATAPOINTS.REFER);
  const policyDeclineConditions = getConditions(policy.fields, SPECIAL_DATAPOINTS.DECLINE);
  let { passed, declined, referred } = getRules(
    policyReferralConditions,
    policyDeclineConditions,
    policy.fields,
  );

  // Only add quote conditions if they're not present in policy conditions
  const notSeen = (condition: ConditionResult, seenConditions: ConditionResult[]) =>
    seenConditions.find((seenCondition) => dequal(condition, seenCondition)) === undefined;

  for (const quote of quotes) {
    const referralConditions = getConditions(quote.fields, SPECIAL_DATAPOINTS.REFER).filter(
      (condition) => notSeen(condition, policyReferralConditions),
    );
    const declineConditions = getConditions(quote.fields, SPECIAL_DATAPOINTS.DECLINE).filter(
      (condition) => notSeen(condition, policyDeclineConditions),
    );
    const {
      passed: quotePassed,
      declined: quoteDeclined,
      referred: quoteReferred,
    } = getRules(referralConditions, declineConditions, quote.fields, quote.id, quote.quoteNumber);

    passed = passed.concat(quotePassed);
    declined = declined.concat(quoteDeclined);
    referred = referred.concat(quoteReferred);
  }

  return {
    passed,
    declined,
    referred,
  };
}

const Rule: React.FC<{ reason: Reason; testId: string }> = ({ reason, testId }) => {
  return (
    <Box colour="white" className="py-3 overflow-x-auto">
      <span className="text-ellipsis" data-testid={testId}>
        {" "}
        {renderReason(reason)}
      </span>
    </Box>
  );
};

const Rules: React.FC<{
  variant: "Referred" | "Passed" | "Declined";
  items: Reason[];
  initialOpen: boolean;
}> = ({ variant, items, initialOpen }) => {
  return (
    <Accordion
      title={
        <p className="flex space-x-2">
          {variant === "Referred" && <ExclamationCircleIcon className="text-yellow-500 w-5 h-5" />}
          {variant === "Passed" && <CheckCircleIcon className="text-green-400 w-5 h-5" />}
          {variant === "Declined" && <BanIcon className="text-red-600 w-5 h-5" />}
          <span className="text-sm font-medium text-gray-700 leading-5 items-center">
            {variant}
          </span>
        </p>
      }
      className="py-1 max-w-full"
      border="hidden"
      button={{ type: "caption", show: "show", hide: "hide" }}
      initialOpen={initialOpen}
      padding="none"
    >
      <div className="space-y-3">
        {items.map((reason, idx) => (
          <Rule
            key={`appetite-${variant.toLowerCase()}-rule-${idx}`}
            reason={reason}
            testId={`appetite-${variant.toLowerCase()}-rule-${idx}`}
          />
        ))}
        {items.length === 0 && (
          <Box className="py-3" border="hidden">
            No rules
          </Box>
        )}
      </div>
    </Accordion>
  );
};

export const Appetite = (props: { policy: PolicyInfo; quotes: Quotes }) => {
  return (
    <Suspense
      fallback={
        <RightPanelContent label="Appetite">
          <div className="flex-1 flex justify-center">
            <Loader />
          </div>
        </RightPanelContent>
      }
    >
      <LoadingAppetite {...props} />
    </Suspense>
  );
};

const LoadingAppetite: React.FC<{
  policy: PolicyInfo;
  quotes: Quotes;
}> = ({ policy, quotes }) => {
  const quoteInfos = quotes.quotes.map((quote) => {
    const quoteInfo = useGetQuote(policy.productId, quote.policyId, quote.id);
    throwIfAppError(quoteInfo);
    return quoteInfo.value;
  });
  const [error, setError] = React.useState<string>();

  const {
    passed: rulesPassed,
    declined: rulesDeclined,
    referred: rulesReferred,
  } = getAllRules(policy, quoteInfos);

  const triggers = useGetAggregationTriggersForPolicy(policy.productId, policy.id);

  const triggersReferred: Reason[] = React.useMemo(() => {
    if (triggers.status === "error") {
      return [];
    }
    return triggers.value
      .filter((x) => x.tag === "AggregationTriggerTriggered" && x.contents[1] === "Refer")
      .map((x) => {
        return { segments: [{ type: "text", value: x.contents[2] ?? "Reason not given" }] };
      });
  }, [triggers]);
  const triggersDeclined: Reason[] = React.useMemo(() => {
    if (triggers.status === "error") {
      return [];
    }
    return triggers.value
      .filter((x) => x.tag === "AggregationTriggerTriggered" && x.contents[1] === "Decline")
      .map((x) => {
        return { segments: [{ type: "text", value: x.contents[2] ?? "Reason not given" }] };
      });
  }, [triggers]);
  const triggersPassed: Reason[] = React.useMemo(() => {
    if (triggers.status === "error") {
      return [];
    }
    return triggers.value
      .filter((x) => x.tag === "AggregationTriggerPassed")
      .map((x) => {
        return { segments: [{ type: "text", value: x.contents[1] }] };
      });
  }, [triggers]);

  React.useEffect(() => {
    if (triggers.status === "error" && triggers.value.tag === "LanguageErr") {
      setError(triggers.value.message);
    } else if (triggers.status === "error") {
      throwIfAppError(triggers);
    } else {
      setError(undefined);
    }
  }, [triggers]);
  return (
    <RightPanelContent label="Appetite">
      {error !== undefined && (
        <div>
          <p> There has been an error when computing the appetite: </p>
          <pre className="overflow-x-auto bg-gray-200 max-h-64">{error}</pre>
        </div>
      )}
      {rulesDeclined.concat(triggersDeclined).length > 0 && (
        <Rules
          key="declined"
          variant="Declined"
          items={rulesDeclined.concat(triggersDeclined)}
          initialOpen={true}
        />
      )}
      <Rules
        key="referred"
        variant="Referred"
        items={rulesReferred.concat(triggersReferred)}
        initialOpen={true}
      />
      <Rules
        key="passed"
        variant="Passed"
        items={rulesPassed.concat(triggersPassed)}
        initialOpen={false}
      />
    </RightPanelContent>
  );
};

export const AssigneePicker = (props: { policyInfo: PolicyInfo }): JSX.Element => {
  const users = useGetAllUsers();
  throwIfAppError(users);
  const underwriters = users.value.users.filter((user) => user.role === "underwriter");
  const assignee = props.policyInfo.assignedTo;

  const onChange = async (newValue: string | null) => {
    if (newValue !== null) {
      // What the backend calls ID the frontend calls username. (It's a UUID)
      await setPolicyAssignee(props.policyInfo.productId, props.policyInfo.id, newValue);
    }
  };

  return (
    <RightPanelContent label="Assignee">
      <Autocomplete
        // This should be 'value'; but there's an annoying race
        // condition, where a request that's older than the
        // assignee-setting one returns later, and overrides in the
        // UI the set value. Note that with the current system
        // there's no feedback for the user if the setting failed.
        defaultValue={assignee}
        options={underwriters}
        getOptionLabel={(option) => (option === null ? "Select user" : option.displayName)}
        onChange={(_evt, value) => onChange(value?.id ?? null)}
        renderInput={(params) => <TextField {...params} />}
      />
    </RightPanelContent>
  );
};
