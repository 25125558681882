import { XIcon } from "@heroicons/react/outline";
import React, { Suspense } from "react";
import { Loader } from "./design_system/Loader";
import { Timeline } from "./timeline";
import { PolicyId, ProductId } from "/src/internal_types";

export const Activity = (props: {
  productId: ProductId;
  policyId: PolicyId;
  onClose: () => void;
}) => {
  return (
    <div className="w-96 bg-white h-full border-l border-gray-200">
      <section className="flex flex-col h-full ">
        <div className="h-16 border-b border-gray-200 mt-px z-10 flex flex-col bg-white justify-center">
          <div className="px-4 py-6">
            <div className="flex items-start justify-between">
              <h2 id="slide-over-heading" className="text-lg font-semibold text-gray-900">
                Activity
              </h2>
              <div className="ml-3 h-7 flex items-center">
                <button
                  onClick={() => props.onClose()}
                  className="bg-white rounded text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 flex"
                >
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Suspense
          fallback={
            <div className="flex py-12 justify-center w-full flex-1">
              <Loader size="lg"></Loader>
            </div>
          }
        >
          <Timeline productId={props.productId} policyId={props.policyId} />
        </Suspense>
      </section>
    </div>
  );
};
