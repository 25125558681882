import React, { useEffect, useState } from "react";
import { Button } from "/src/design_system/Button";
import { useDropzone } from "react-dropzone";
import { CloudUploadIcon, XIcon } from "@heroicons/react/solid";
import {
  invalidateMrcTaskStatus,
  uploadMrcDocument,
  useGetMrcTaskStatus,
  useGetPolicy,
} from "/src/dal/dal";
import { useParams } from "/src/routing/routing";
import { Routes } from "/src/routing/routes";
import { MrcPage, MrcSplitPane } from "./page";
import { useHistory } from "react-router";
import { Loader } from "/src/design_system/Loader";
import { throwIfAppError } from "/src/utils/app_error";
import { MrcDocumentId, MrcExtractionTaskId, PolicyId, ProductId } from "/src/internal_types";

function useInterval(action: () => void, delayMs: number) {
  React.useEffect(() => {
    const id = setInterval(action, delayMs);
    return () => clearInterval(id);
  }, [delayMs]);
}

type State = StateUpload | StateWaitForExtraction;

type StateUpload = {
  type: "upload";
  error: null | string;
};

type StateWaitForExtraction = {
  type: "waitForExtraction";
  documentId: MrcDocumentId;
  taskId: MrcExtractionTaskId;
};

export const MrcUploadView = (): JSX.Element => {
  const { data } = useParams(Routes.mrcUpload);
  const productId = data.capture.productId;
  const policyId = data.capture.policyId;
  const policy = useGetPolicy(productId, policyId);
  throwIfAppError(policy);

  const [state, setState] = useState<State>({ type: "upload", error: null });
  const history = useHistory();

  const onFilesSelected = async (files: File[]) => {
    if (files.length < 1) return;
    const file = files[0];
    const result = await uploadMrcDocument(productId, policyId, file);
    if (result.status !== 200) {
      setState({ type: "upload", error: "Failed to upload" });
    } else {
      setState({ type: "waitForExtraction", ...result.result });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFilesSelected });

  const setError = (err: null | string) => {
    setState({ type: "upload", error: err });
  };

  return (
    <MrcPage policy={policy.value} isUpdating={false}>
      <MrcSplitPane
        onCancel={async () => {
          history.push(
            Routes.policy.generatePath({
              capture: {
                productId: productId,
                policyId: policyId,
              },
            }),
          );
        }}
        onImport={null}
        leftPanel={
          <>
            {state.type === "waitForExtraction" && (
              <MrcLoading
                productId={productId}
                policyId={policyId}
                documentId={state.documentId}
                taskId={state.taskId}
                setError={setError}
              />
            )}
            {state.type === "upload" && (
              <div
                {...getRootProps()}
                className="h-3/5 flex flex-col justify-center p-6 text-center"
              >
                <h1 className="text-2xl leading-7 font-bold">MRC Extraction</h1>
                <p className="text-sm leading-5 font-normal text-gray-700 mt-2">
                  Click upload or drag and drop your file here
                </p>
                <input
                  {...getInputProps()}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
                <Button type="button" variant="primary" className="self-center mt-6">
                  <CloudUploadIcon className="w-4 h-4 -ml-1 mr-2" />
                  Upload file
                </Button>
                {state.error !== null && <p className="text-red-500 mt-4">{state.error}</p>}
              </div>
            )}
          </>
        }
        rightPanel={<></>}
      />
    </MrcPage>
  );
};

export const MrcLoading = (props: {
  productId: ProductId;
  policyId: PolicyId;
  documentId: MrcDocumentId;
  taskId: MrcExtractionTaskId;
  setError: (err: null | string) => void;
}): JSX.Element => {
  const history = useHistory();
  const taskStatus = useGetMrcTaskStatus(props.productId, props.policyId, props.taskId);
  // Periodically re-query the status
  useInterval(async () => {
    await invalidateMrcTaskStatus(props.productId, props.policyId, props.taskId);
  }, 1000);
  useEffect(() => {
    if (taskStatus.status === "success") {
      if (taskStatus.value === "MrcTaskFailedWontRetry") {
        props.setError("Extraction failed");
      } else if (taskStatus.value === "MrcTaskSucceeded") {
        history.push(
          Routes.mrcDocumentView.generatePath({
            capture: {
              productId: props.productId,
              policyId: props.policyId,
              documentId: props.documentId,
            },
          }),
        );
      }
    }
  }, [taskStatus]);
  return (
    <div className="h-3/5 flex flex-col justify-center p-6 text-center">
      <div className="w-full h-20 flex items-center justify-center">
        <Loader />
      </div>
      <h1 className="text-2xl leading-7 font-bold">Extracting MRC contents...</h1>
      {/*TODO make this work*/}
      <Button type="button" variant="primary" className="self-center mt-6" disabled={true}>
        <XIcon className="w-4 h-4 -ml-1 mr-2" />
        Cancel
      </Button>
    </div>
  );
};
