import React from "react";
import clsx from "clsx";
import {
  useMenuState as rkUseMenuState,
  Menu as RkMenu,
  MenuProps as RkMenuProps,
  MenuItem as RkMenuItem,
  MenuItemProps as RkMenuItemProps,
  MenuButton as RkMenuButton,
} from "reakit/Menu";

export interface MenuProps extends RkMenuProps {}

export const Menu = (props: MenuProps) => {
  return (
    <RkMenu
      {...props}
      className={clsx(
        "focus:outline-none",
        "focus:shadow-outline",
        "shadow",
        "border",
        "border-gray-300",
        "rounded",
        "flex",
        "flex-col",
      )}
      style={{ minWidth: "10rem" }}
    >
      {props.children}
    </RkMenu>
  );
};

/*
    <RkMenuButton {...menu} {...disclosure.props}>
        {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
      </RkMenuButton>*/
export interface MenuItemProps extends RkMenuItemProps {}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  return (
    <RkMenuItem
      {...props}
      className={clsx(
        "focus:outline-none",
        "focus:bg-gray-100",
        "px-5",
        "py-3",
        "border-b",
        "last:border-b-0",
        "border-gray-300",
        "text-left",
        "text-sm",
        "font-medium",
        "bg-white",
      )}
    >
      {props.children}
    </RkMenuItem>
  );
};

export const MenuButton = RkMenuButton;

export const useMenuState = rkUseMenuState;
