import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React from "react";
import { renderNode } from "../node";
import { generalEditorOrSummaryView, NodeT, NodeT_ } from "../types";
import { FocusableRefObject } from "./focusable_node";
import { GoalInfo, QuoteId, ReferMap } from "/src/internal_types";

export interface GroupNodeT_ extends NodeT_ {
  type: "group";
  heading: string;
  level: number;
  nodes: Array<NodeT>;
  quoteId?: QuoteId;
}

export const isGroupNodeT_ = (n: NodeT_): n is GroupNodeT_ => {
  const casted = n as GroupNodeT_;
  return casted.type !== undefined && casted.type === "group";
};

export const GroupNode = (props: {
  node: GroupNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo; // fields that are being used for the group node
}): JSX.Element =>
  generalEditorOrSummaryView(
    props.node,
    (n) => (
      <GroupNodeEditorView
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
    (n) => (
      <GroupNodeSummaryView
        node={n.node}
        refObject={props.refObject}
        referMap={props.referMap}
        fields={props.fields}
      />
    ),
  );

export const GroupNodeEditorView = (props: {
  node: GroupNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}): JSX.Element => {
  const { node } = props;
  return (
    <>
      <div className="p-0 text-lg text-gray-700 font-semibold py-3">{node.heading}</div>
      <div>
        <div className="space-y-6">
          {node.nodes.map((childNode) => (
            <div key={childNode.id} data-nodeid={childNode.id} data-quoteid={node.quoteId}>
              {renderNode(childNode, props.refObject, props.referMap, props.fields)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const GroupNodeSummaryView = (props: {
  node: GroupNodeT_;
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
}): JSX.Element => {
  const { node } = props;
  return (
    <Disclosure as="div" defaultOpen>
      {({ open }) => {
        return (
          <>
            <Disclosure.Button className="flex justify-between text-xs border-b bg-gray-50 border-gray-200  w-full px-4 py-4">
              <span className="font-medium leading-4 text-gray-600 uppercase ">{node.heading}</span>
              <span className="w-5 h-5 text-gray-600">
                <ChevronUpIcon
                  className={clsx(
                    "transition duration-300",
                    open ? "ease-out" : "ease-in",
                    open && "-rotate-180",
                  )}
                />
              </span>
            </Disclosure.Button>

            <Transition
              show={open}
              className="overflow-hidden"
              enter="transition-all transition-[max-height] duration-300 ease-in"
              enterFrom="max-h-0"
              enterTo="max-h-screen"
              leave="transition-all transition-[max-height] duration-300 ease-out"
              leaveFrom="max-h-screen"
              leaveTo="max-h-0"
            >
              <Disclosure.Panel static>
                <div className="text-lg">
                  {node.nodes.map((childNode) => (
                    <div key={childNode.id} data-nodeid={childNode.id} data-quoteid={node.quoteId}>
                      {renderNode(childNode, props.refObject, props.referMap, props.fields)}
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
};

// function getHeaderFontSizeByLevel(_level: number): string {
//   return "1.25rem";
// }

/*
 <div className="mb-4 mt-2 border-b border-gray-200">
      <div
        className=" mb-4"
        style={{
          fontWeight: "bold",
          fontSize: getHeaderFontSizeByLevel(node.level),
        }}
      >
        {node.heading}
      </div>

      {node.nodes.map((childNode) => (
        <div key={childNode.id} data-nodeid={childNode.id}>
          {renderNode(childNode)}
        </div>
      ))}
    </div>*/
