import { Asyncs, useMappedAsyncTasks } from "/src/asyncs";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { PolicyContext } from "./context";
import { PolicySaveStatus } from "./modified_date";
import {
  CanAcceptQuote,
  CanAddNewQuotes,
  CanAssignToPolicyP,
  CanBindQuote,
  CanDeletePolicy,
  CanCancelPolicy,
  CanClonePolicy,
  CanEditPolicy,
  CanEditQuoteData,
  CanGenerateMultiQuoteFile,
  CanNotTakeUpQuote,
  CanRejectQuote,
  CanSeeAppetite,
  CanSendToBroker,
  CanSendToUnderwriter,
  CanShowAcceptedCaption,
  CanShowAcceptedFields,
  CanShowAcceptQuote,
  CanShowAddNewQuotes,
  CanShowBindQuote,
  CanShowBoundFields,
  CanShowConditionsWarningPolicy,
  CanShowConditionsWarningQuote,
  CanShowDeclineReasonsInHeader,
  CanShowNotTakeUpQuote,
  CanShowReferralHeader,
  CanShowReferralReasonsInHeader,
  CanShowReferralStatusForDatapointsForPolicy,
  CanShowReferralStatusForDatapointsForQuote,
  CanShowRejectedFields,
  CanShowRejectQuote,
  CanShowSubmitQuote,
  CanSubmitQuote,
  CanArchivePolicy,
  CanUndeclineQuote,
  determinePolicyState,
  GlobalPolicyState,
  CanSeeProductSpec,
  CanImportIntoPolicy,
} from "./permissions";
import { PolicyStatusBadge } from "./policies";
import { QuoteViewPermissions } from "./quote_view";
import { Activity } from "/src/activity";
import {
  newQuote,
  useGetCurrentUser,
  useGetMrcDocuments,
  useGetPolicy,
  useGetProduct,
  useGetProductConfig,
  useGetQuotes,
} from "/src/dal/dal";
import { getFirstErrorNode } from "/src/data_editor";
import { getDatapointId } from "/src/data_editor/builder";
import { useFocusableRefObject } from "/src/data_editor/nodes/focusable_node";
import { NodeT } from "/src/data_editor/types";
import { Datapoint, PolicyInfo, Product, ProductVersion, QuoteId, Role } from "/src/internal_types";
import { Page, usePageTitle } from "/src/layout";
import { PolicyView } from "/src/pages/policy/policy_view";
import { RealQuotesView } from "/src/pages/policy/quotes_view";
import { Routes } from "../../routing/routes";
import { useParams } from "/src/routing/routing";
import { InformationCircleIcon, MenuAlt2Icon } from "@heroicons/react/solid";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import { throwIfAppError } from "/src/utils/app_error";
import { Badge } from "/src/components/Badge";
import { TabPanel } from "/src/design_system/TabPanel";
import { Tabs } from "/src/design_system/Tabs";
import { ImportTools } from "./import_tools";
import { memo } from "react";
import { dequal } from "dequal";

export function Policy() {
  const { data } = useParams(Routes.policy);
  const productId = data.capture.productId;
  const policyId = data.capture.policyId;
  const asyncs = useMappedAsyncTasks(productId, policyId);
  return <PolicyImplMemo asyncs={asyncs} />;
}

const PolicyImpl = ({ asyncs }: { asyncs: Asyncs }) => {
  const { data, setQuery } = useParams(Routes.policy);
  const productId = data.capture.productId;
  const policyId = data.capture.policyId;

  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const config = useGetProductConfig(productId);
  throwIfAppError(config);
  const product = useGetProduct(productId);
  throwIfAppError(product);
  const policyResp = useGetPolicy(productId, policyId);
  throwIfAppError(policyResp);
  const policy = policyResp.value;
  const quotesResp = useGetQuotes(productId, policyId);
  throwIfAppError(quotesResp);
  const quotes = quotesResp.value;
  const mrcDocuments = useGetMrcDocuments(productId, policyId);
  throwIfAppError(mrcDocuments);
  const history = useHistory();

  useEffect(() => {
    if (mrcDocuments.value.length > 0) {
      // Redirect to the "newest" (highest ID) MRC document, provided it isn't imported.
      let highestDocId = 0;
      let highestDocIsImported = false;
      mrcDocuments.value.forEach((document) => {
        if (document.documentId > highestDocId) {
          highestDocId = document.documentId;
          highestDocIsImported = document.importedOrCancelled;
        }
      });
      if (!highestDocIsImported) {
        history.push(
          Routes.mrcDocumentView.generatePath({
            capture: {
              productId: productId,
              policyId: policyId,
              documentId: highestDocId,
            },
          }),
        );
      }
    }
  }, [JSON.stringify(mrcDocuments.value.map((x) => [x.documentId, x.importedOrCancelled]))]);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [quoteOpened, setQuoteOpened] = React.useState<QuoteId>();
  const [showActivity, setShowActivity] = useLocalStorage("policy/show-activity", false);

  const nodesRef = React.useRef<NodeT[]>([]);
  const [refObject, scrollToField] = useFocusableRefObject();

  const globalPolicyState: GlobalPolicyState = {
    currentRole: currentUser.value.role,
    productConfig: config.value,
  };

  const quoteViewPermissions: QuoteViewPermissions = {
    canAcceptQuote: CanAcceptQuote(globalPolicyState),
    canShowAcceptQuote: CanShowAcceptQuote(globalPolicyState),
    canAddNewQuote: CanAddNewQuotes(globalPolicyState),
    canShowAddNewQuote: CanShowAddNewQuotes(globalPolicyState),
    canSubmitQuote: CanSubmitQuote(globalPolicyState),
    canShowSubmitQuote: CanShowSubmitQuote(globalPolicyState),
    canBindQuote: CanBindQuote(globalPolicyState),
    canShowBindQuote: CanShowBindQuote(globalPolicyState),
    canEditQuote: CanEditQuoteData(globalPolicyState),
    canRejectQuote: CanRejectQuote(globalPolicyState),
    canShowRejectQuote: CanShowRejectQuote(globalPolicyState),
    canNotTakeUpQuote: CanNotTakeUpQuote(globalPolicyState),
    canShowNotTakeUpQuote: CanShowNotTakeUpQuote(globalPolicyState),
    canUndeclineQuote: CanUndeclineQuote(globalPolicyState),
    canSendToBroker: CanSendToBroker(globalPolicyState),
    canSendToUnderwriter: CanSendToUnderwriter(globalPolicyState),
    canShowReferralHeader: CanShowReferralHeader(globalPolicyState),
    canShowAcceptedCaption: CanShowAcceptedCaption(globalPolicyState),
    canShowReferralReasonsInHeader: CanShowReferralReasonsInHeader(globalPolicyState),
    canShowDeclineReasonsInHeader: CanShowDeclineReasonsInHeader(globalPolicyState),
    canShowAcceptedFields: CanShowAcceptedFields(globalPolicyState),
    canShowRejectedFields: CanShowRejectedFields(globalPolicyState),
    canShowBoundFields: CanShowBoundFields(globalPolicyState),
    canGenerateMultiQuoteFile: CanGenerateMultiQuoteFile(globalPolicyState),
    canShowReferralStatusForDatapointsForQuote:
      CanShowReferralStatusForDatapointsForQuote(globalPolicyState),
    canShowConditionsWarningPolicy: CanShowConditionsWarningPolicy(globalPolicyState),
    canShowConditionsWarningQuote: CanShowConditionsWarningQuote(globalPolicyState),
    canArchivePolicy: CanArchivePolicy(globalPolicyState),
    canDeletePolicy: CanDeletePolicy(globalPolicyState),
    canSeeAppetite: CanSeeAppetite(globalPolicyState),
  };

  const policyState = determinePolicyState(policy);

  const canProceedToQuote = quoteViewPermissions.canAddNewQuote.check(policyState);
  const canEditPolicy = CanEditPolicy(globalPolicyState).check(policyState);
  const policyIsEditing = canEditPolicy && data.query.editing === "true";

  usePageTitle(`Policy #${policyId}`, product.value.name);

  const [whichTab, setTab] = React.useState("Policy Information");

  return (
    <PolicyContext.Provider value={{ policy: policy }}>
      <Page>
        <div className="flex-1 flex overflow-x-hidden">
          <div className="flex-1 flex flex-col">
            <div className="bg-white border-b border-gray-200 z-10">
              <div className=" mx-auto h-16 py-3 font-semibold flex items-center justify-between px-6">
                <div className=" h-20 font-semibold flex flex-row items-center justify-between">
                  <Link
                    to={Routes.policies.generatePath({ query: { ...data.query } })}
                    className="group py-2 pr-6"
                  >
                    <ArrowNarrowLeftIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                  </Link>
                  <span className="pr-6 text-lg inline-block">Policy #{policyId}</span>
                  <div className="flex space-x-3">
                    <SpecVersionBadge
                      product={product.value}
                      productVersion={policy.productVersion}
                      currentRole={currentUser.value.role}
                    />
                    <ClonedFromBadge policy={policy} />
                    <PolicyStatusBadge policy={policy} />
                  </div>
                </div>
                <div className="flex-1"></div>
                <div>
                  <PolicySaveStatus
                    lastUpdatedAt={policy.modified ?? policy.created.happenedAt}
                    isUpdating={isUpdating}
                  />
                </div>
                <button
                  type="button"
                  data-testid="toggle-activity-button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setShowActivity(!showActivity)}
                >
                  <MenuAlt2Icon className="w-5 mr-2" />
                  {showActivity ? "Hide activity" : "Show activity"}
                </button>
              </div>
            </div>
            <div className="flex flex-col grow overflow-y-auto">
              {policy.derivedStatus.tag === "CancellationPending" && (
                <PendingCancellationBanner
                  cancellationDate={policy.derivedStatus.contents.cancellationDate}
                />
              )}
              <Tabs
                tabs={["Policy Information"].concat(
                  CanImportIntoPolicy(globalPolicyState).check(policyState)
                    ? ["Data Extraction"]
                    : [],
                )}
                selected={whichTab}
                onSelect={(rule: string) => setTab(rule)}
                className="mx-8"
              />
              <TabPanel
                index={"Policy Information"}
                value={whichTab}
                className="w-full overflow-y-auto px-8"
              >
                <div className="flex flex-row items-start justify-center w-full pb-12">
                  <PolicyView
                    policy={policy}
                    quotes={quotes}
                    canEditPolicy={CanEditPolicy(globalPolicyState)}
                    canArchivePolicy={CanArchivePolicy(globalPolicyState)}
                    canDeletePolicy={CanDeletePolicy(globalPolicyState)}
                    canCancelPolicy={CanCancelPolicy(globalPolicyState)}
                    canClonePolicy={CanClonePolicy(globalPolicyState)}
                    canAddNewQuotes={CanAddNewQuotes(globalPolicyState)}
                    canShowReferralStatusForDatapointsForPolicy={CanShowReferralStatusForDatapointsForPolicy(
                      globalPolicyState,
                    )}
                    canAssign={CanAssignToPolicyP(globalPolicyState)}
                    canSeeAppetite={CanSeeAppetite(globalPolicyState)}
                    onTxStatusChange={(txStatus) => setIsUpdating(txStatus === "in_flight")}
                    refObject={refObject}
                    onNodesChange={(n) => (nodesRef.current = n)}
                    isEditing={policyIsEditing}
                    setIsEditing={(x) => setQuery({ editing: x ? "true" : undefined })}
                    canShowConditionsWarningPolicy={
                      quoteViewPermissions.canShowConditionsWarningPolicy
                    }
                    asyncs={asyncs}
                  >
                    <div>
                      <RealQuotesView
                        policy={policy}
                        asyncs={asyncs}
                        quotes={quotes}
                        permissions={quoteViewPermissions}
                        quoteOpened={quoteOpened}
                        onOpenQuote={(quoteId) => setQuoteOpened(quoteId)}
                        onAddQuoteClick={async (quotesRef) => {
                          if (canProceedToQuote) {
                            setQuery({ editing: undefined });
                            const resp = await newQuote(policy.productId, policy.id, {
                              policyStartDate: policy.defaultStartDate,
                              policyEndDate: policy.defaultEndDate,
                            });
                            if (resp.status === "success") {
                              if (quotesRef.current !== null) {
                                setQuoteOpened(resp.value.id);
                                setTimeout(() => {
                                  (quotesRef.current as any as HTMLDivElement).scrollIntoView({
                                    behavior: "smooth",
                                    block: "end",
                                  });
                                }, 250);
                              }
                            }
                          } else {
                            const firstNode = getFirstErrorNode(nodesRef.current);
                            !policyIsEditing && setQuery({ editing: "true" });
                            setTimeout(async () => {
                              await scrollToField(firstNode?.id);
                            }, 100);
                          }
                        }}
                        highlightErrorInPolicy={async (dps: Datapoint[]) => {
                          setQuery({ editing: "true" });
                          if (dps.length == 0) {
                            return;
                          }
                          const id = getDatapointId(
                            dps[0].key,
                            dps[0].arguments.flatMap((x) => {
                              if (x.tag === "Scalar") {
                                return [x.contents];
                              } else {
                                return [];
                              }
                            }),
                          );
                          await new Promise((resolve) => setTimeout(resolve, 100));
                          await scrollToField(id);
                        }}
                      />
                    </div>
                  </PolicyView>
                </div>
              </TabPanel>

              <TabPanel
                index={"Data Extraction"}
                value={whichTab}
                className="px-8 shrink flex flex-col overflow-y-auto overflow-x-hidden grow"
              >
                <ImportTools
                  productId={productId}
                  productVersion={policy.productVersion}
                  onImportComplete={() => setTab("Policy Information")}
                />
              </TabPanel>
            </div>
          </div>
          {showActivity && (
            <div id="sidebar" className="bg-white">
              <Activity
                productId={productId}
                policyId={policyId}
                onClose={() => setShowActivity(false)}
              />
            </div>
          )}
        </div>
      </Page>
    </PolicyContext.Provider>
  );
};

const PolicyImplMemo = memo(PolicyImpl, dequal);

function PendingCancellationBanner({ cancellationDate }: { cancellationDate: string }) {
  return (
    <div className="bg-yellow-100 bg-opacity-80 max-w-screen-2xl w-auto mx-5 mt-5 rounded-lg h-8 py-10 flex flex-row items-center">
      <div className="px-6 text-yellow-600 justify-between">
        <h2 className="font-semibold truncate mr-2">
          <InformationCircleIcon className="h-5 w-5 -mt-1 mr-2 inline-block" />
          Cancellation pending
        </h2>
        <p className="px-6 py-2">A cancellation is scheduled for {cancellationDate}</p>
      </div>
    </div>
  );
}

function ClonedFromBadge({ policy }: { policy: PolicyInfo }): JSX.Element {
  if (policy.clonedFrom === undefined) {
    return <></>;
  } else {
    return (
      <Link
        to={Routes.policy.generatePath({
          capture: {
            policyId: policy.clonedFrom,
            productId: policy.productId,
          },
        })}
      >
        <Badge
          label={`Cloned from`}
          color="white"
          border
          secondaryLabel={`#${policy.clonedFrom}`}
        />
      </Link>
    );
  }
}

function SpecVersionBadge({
  product,
  productVersion,
  currentRole,
}: {
  product: Product;
  productVersion: ProductVersion;
  currentRole: Role;
}) {
  const canSeeProductSpec = CanSeeProductSpec({ currentRole }).check(undefined);
  if (canSeeProductSpec) {
    return (
      <Link
        to={Routes.readProduct.generatePath({
          capture: {
            productId: product.id,
            versionId: productVersion,
          },
        })}
      >
        <BaseBadge product={product} productVersion={productVersion} />
      </Link>
    );
  }
  return <BaseBadge product={product} productVersion={productVersion} />;
}

const BaseBadge = ({
  product,
  productVersion,
}: {
  product: Product;
  productVersion: ProductVersion;
}) => (
  <Badge
    label={`${product.name}`}
    secondaryLabel={`v${productVersion}`}
    color="white"
    border={true}
  />
);
