import React from "react";
import { GoalInfo, ReferMap } from "../internal_types";
import { assertNever } from "../utils";
import { AddressNode } from "./nodes/address_node";
import { LeafNodeOnTheRight, ReferRow } from "./nodes/base";
import { ConditionNodeOnTheRight } from "./nodes/condition_node";
import { DateNode } from "./nodes/date_node";
import { DateSelectNode } from "./nodes/date_select_node";
import { DocumentNode } from "./nodes/document_node";
import { FileNode } from "./nodes/file_node";
import { FileSetNode } from "./nodes/file_set_node";
import { FocusableDiv, FocusableRefObject } from "./nodes/focusable_node";
import { GroupNode } from "./nodes/group_node";
import { MoneyNode } from "./nodes/money_node";
import { NumberNode } from "./nodes/number_node";
import { NumberSelectNode } from "./nodes/number_select_node";
import { NumberSuggestNode } from "./nodes/number_suggest_node";
import { OptionalNode } from "./nodes/optional_node";
import { PercentNode } from "./nodes/percent_node";
import { PercentSelectNode } from "./nodes/percent_select_node";
import { PremiumNode } from "./nodes/premium_node";
import { SubformsNode } from "./nodes/subforms_node";
import { TableNode } from "./nodes/table_node";
import { TextNode } from "./nodes/text_node";
import { PromiseNode } from "./nodes/promise_node";
import { TextSelectNode } from "./nodes/text_select_node";
import { TextSetSelectNode } from "./nodes/text_set_select_node";
import { TextSuggestNode } from "./nodes/text_suggest_node";
import { YesNoNode } from "./nodes/yes_no_node";
import { NodeOnTheRightT, NodeT } from "./types";
import { UmrNode } from "./nodes/umr_node";

export function renderNode(
  node: NodeT,
  refObject: FocusableRefObject | undefined,
  referMap: ReferMap | undefined,
  fields: GoalInfo,
) {
  switch (node.type) {
    case "text":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <TextNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "text_select":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <TextSelectNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "text_suggest":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <TextSuggestNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "caseless_text":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <TextNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "umr":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <UmrNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "number":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <NumberNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "premium":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <PremiumNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "number_select":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <NumberSelectNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "number_suggest":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <NumberSuggestNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "text_set_select":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <TextSetSelectNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "percent":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <PercentNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "percent_select":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <PercentSelectNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "yes_no":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <YesNoNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "date":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <DateNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "date_select":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <DateSelectNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "file":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <FileNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "address":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <AddressNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "table":
      return (
        <FocusableNode
          fields={fields}
          node={node}
          refObject={refObject}
          referMap={referMap}
          additionalIds={node.rows.map((r) => r.cells.map((c) => c.id)).flat()}
        >
          <TableNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "group":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={undefined}>
          <GroupNode
            key={node.id}
            node={node}
            refObject={refObject}
            referMap={referMap}
            fields={fields}
          />
        </FocusableNode>
      );
    case "document":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={undefined}>
          <DocumentNode key={node.id} node={node} />
        </FocusableNode>
      );
    // TODO: this is experimental
    case "blocked":
      return (
        <div>
          <b>Blocked: {node.id}</b>
          <pre>{JSON.stringify(node.blockers, null, 2)}</pre>
        </div>
      );
    case "subforms":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={undefined}>
          <SubformsNode
            key={node.id}
            node={node}
            refObject={refObject}
            referMap={referMap}
            fields={fields}
          />
        </FocusableNode>
      );
    case "file_set":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={undefined}>
          <FileSetNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "money":
      return (
        <FocusableNode
          fields={fields}
          node={node}
          refObject={refObject}
          referMap={referMap}
          additionalIds={[node.amountNode.id, node.currencyNode.id]}
        >
          <MoneyNode key={node.id} node={node} />
        </FocusableNode>
      );
    case "optional":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <OptionalNode
            key={node.id}
            node={node}
            refObject={refObject}
            referMap={referMap}
            fields={fields}
          ></OptionalNode>
        </FocusableNode>
      );
    case "promise":
      return (
        <FocusableNode fields={fields} node={node} refObject={refObject} referMap={referMap}>
          <PromiseNode key={node.id} node={node} />
        </FocusableNode>
      );
    default:
      return assertNever(node);
  }
}

/** Wraps its children into a {@link FocusableDiv}, and also adds a {@link ReferRow} when required. */
const FocusableNode = (props: {
  node: { id: string; summaryView: boolean };
  refObject: FocusableRefObject | undefined;
  referMap: ReferMap | undefined;
  fields: GoalInfo;
  additionalIds?: string[];
  /** Typically the children will be field nodes. */
  children: JSX.Element | null;
}): JSX.Element => {
  const showReferStatus = props.referMap !== undefined;
  const reasons = props.referMap !== undefined ? props.referMap[props.node.id] : undefined;
  if (props.children === null) {
    return <></>;
  }
  return (
    <FocusableDiv
      divIds={[props.node.id].concat(props.additionalIds ?? [])}
      refObject={props.refObject}
    >
      <>
        {showReferStatus && (
          <ReferRow reasons={reasons} fields={props.fields}>
            {props.children}
          </ReferRow>
        )}
        {!showReferStatus && props.children}
      </>
    </FocusableDiv>
  );
};

export function renderNodeOnTheRight(node: NodeOnTheRightT) {
  switch (node.type) {
    case "leaf": {
      return <LeafNodeOnTheRight {...node} />;
    }
    case "condition": {
      return <ConditionNodeOnTheRight {...node} />;
    }
  }
}
