import * as Sentry from "@sentry/react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { PublicRouteComponents, PublicRoutes, RouteComponents, Routes } from "./routing/routes";
import { QueryClientProvider, useQuery } from "react-query";
import React, { Suspense } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { queryClient, useGetCurrentUser, useGetDeployment } from "./dal/dal";

import Layout from "./layout";
import { Loader } from "./design_system/Loader";
import { ReactQueryDevtools } from "react-query/devtools";
import { objectTypesafeForEach } from "./utils";
import theme from "/src/theme/theme";
import NotFound from "./pages/404";
import { ToastsProvider } from "./components/Toast";
import { TaskProvider } from "./components/TaskProvider";

// For some reason it doesn't work if I put this in global.d.ts
declare global {
  interface Window {
    // We only load segment scripts outside of dev mode, so window.analytics
    // might be undefined.
    analytics: import("@segment/analytics.js-core").SegmentAnalytics.AnalyticsJS | undefined;
  }
}

/**
 * Renderless component setting Segment's identity.
 * TODO: should be extracted out of the react tree
 * as part of an auth state refactor. As things stand
 * the identify call's timing is not predictable, and
 * so some events post-auth could be missing user info.
 */
const SegmentIdentityTracker = () => {
  const history = useHistory();
  const currentUser = useGetCurrentUser();

  React.useEffect(() => {
    if (window.analytics !== undefined && currentUser.status === "success") {
      window.analytics.identify(currentUser.value.id, currentUser.value);
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (window.analytics !== undefined) {
      window.analytics.page();
    }
  }, [history.location.pathname]);

  return null;
};

const AppLoader = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Loader size="lg" />
    </div>
  );
};

const PrivateApp: React.FC = () => {
  const { data: authStatus } = useQuery("authStatus", () =>
    aws_amplify.Auth.currentSession()
      .then(() => "signed_in" as const)
      .catch(() => "signed_out" as const),
  );

  if (authStatus === "signed_out") {
    return <Redirect to={PublicRoutes.login.generateRouterString()} />;
  }

  const routes: JSX.Element[] = [];
  objectTypesafeForEach(Routes, (key, route) => {
    routes.push(
      <Route
        key={route.generateRouterString()}
        exact={route.exact}
        path={route.generateRouterString()}
        component={RouteComponents[key]}
      />,
    );
  });
  routes.push(<Route key="NotFound" component={NotFound} />);

  return (
    <ToastsProvider>
      <Suspense fallback={<></>}>
        <TaskProvider />
      </Suspense>
      <ThemeProvider theme={theme}>
        <SegmentIdentityTracker />
        <CssBaseline />
        <Layout pageTitle={[]}>
          <Switch>{routes}</Switch>
        </Layout>
      </ThemeProvider>
    </ToastsProvider>
  );
};

const App: React.FC = () => {
  const routes: JSX.Element[] = [];
  objectTypesafeForEach(PublicRoutes, (key, route) => {
    routes.push(
      <Route
        key={route.generateRouterString()}
        exact={route.exact}
        path={route.generateRouterString()}
        component={PublicRouteComponents[key]}
      />,
    );
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary
        showDialog
        fallback={() => (
          <div className="min-h-screen flex flex-col p-8 md:p-16 bg-blue-700 text-white">
            <h2 className="w-initial text-2xl mb-2 mt-8 text-semi-bold">
              {" "}
              Sorry, something went wrong.
            </h2>
            <div className="w-initial text-lg mb-1 text-blue-300 leading-8">
              We've been notified and will get that fixed.
            </div>
            <div className="w-initial text-sm text-blue-300">
              Please{" "}
              <a
                className="underline transition ease-in-out hover:text-white"
                href="mailto:product@artificial.io"
              >
                contact us
              </a>{" "}
              if you're still having trouble.
            </div>
          </div>
        )}
      >
        <Suspense fallback={<AppLoader />}>
          <DeploymentRefresher />
          <Router>
            <Switch>
              {routes}
              <Route component={PrivateApp} />
            </Switch>
          </Router>
        </Suspense>
      </Sentry.ErrorBoundary>
      {import.meta.env.MODE === "development" && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>
  );
};

const DeploymentRefresher = (_props: Record<string, never>): JSX.Element => {
  const deployment = useGetDeployment();
  const [startingDeployment, _] = React.useState(deployment);
  if (deployment.status === "error") {
    return <></>;
  } else {
    if (JSON.stringify(startingDeployment) !== JSON.stringify(deployment)) {
      return (
        <div className="absolute top-0 bg-blue-500 text-white rounded-b-lg p-4 left-1/2 transform -translate-x-1/2 font-bold cursor-default drop-shadow-md z-50">
          The server has been updated, please refresh this page when ready.
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default App;
