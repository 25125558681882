import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect } from "react";
import { generalEditorOrSummaryView, SingleNodeT_ } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";

export interface YesNoNodeT_ extends SingleNodeT_<boolean> {
  type: "yes_no";
  customYesPrompt: string | undefined;
  customNoPrompt: string | undefined;
}

export const YesNoNode = (props: { node: YesNoNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, YesNoNodeEditorView, YesNoNodeSummaryView);
};

export const YesNoNodeEditorView = (props: { node: YesNoNodeT_ }) => {
  const { node } = props;
  const [localValue, setLocalValue] = React.useState(node.value);

  useEffect(() => {
    if (!node.isCommitting) {
      setLocalValue(node.value);
    }
  }, [node.value, node.isCommitting]);

  const handleChange = (newValue: boolean) => {
    setLocalValue(newValue);
    node.commit(newValue);
  };

  return (
    <LeafNode node={node}>
      <div className="flex justify-between relative">
        <FormControl>
          <RadioGroup
            row
            value={localValue === null ? null : localValue ? "yes" : "no"}
            onChange={(_e, v) => {
              handleChange(v === "yes");
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" size={"small"} />}
              label={props.node.customYesPrompt ?? "Yes"}
              disabled={node.readOnly}
              data-testid="yes-button"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" size={"small"} />}
              label={props.node.customNoPrompt ?? "No"}
              disabled={node.readOnly}
              data-testid="no-button"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </LeafNode>
  );
};

const YesNoNodeSummaryView = (props: { node: YesNoNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[
        props.node.value !== null
          ? `${
              props.node.value
                ? props.node.customYesPrompt ?? "Yes"
                : props.node.customNoPrompt ?? "No"
            }`
          : "",
      ]}
    />
  );
};
