import React from "react";
import NumberFormat from "react-number-format";
import {
  ComparableNodeT_,
  generalEditorOrSummaryView,
  SingleNodeT_,
  useSingleNodeState,
  WithPrefixNodeT_,
} from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";
import { toNumber } from "/src/utils";

export interface NumberNodeT_
  extends SingleNodeT_<number>,
    ComparableNodeT_<number>,
    WithPrefixNodeT_ {
  type: "number";
}

export const NumberNode = (props: { node: NumberNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, NumberNodeEditorView, NumberNodeSummaryView);
};

export const NumberNodeEditorView = (props: { node: NumberNodeT_ }): JSX.Element => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = async () => {
    await node.commit(toNumber(localValue));
  };

  // TODO: NumberFormat<any> is ugly but equivalent to the type in react-number-format before
  // https://github.com/s-yadav/react-number-format/commit/1b6d2f39817cd2a8995980bec7a4caad1b6322b2
  return (
    <LeafNode node={node}>
      <NumberFormat<any>
        thousandSeparator={true}
        min={node.minimum ?? undefined}
        max={node.maximum ?? undefined}
        customInput={TextField}
        prefix={node.prefix}
        allowEmptyFormatting
        value={localValue ?? undefined}
        onValueChange={(e) => {
          setLocalValue(e.value);
        }}
        onBlur={handleOnBlur}
        disabled={node.readOnly}
        node={node}
      />
    </LeafNode>
  );
};

export const NumberNodeSummaryView = (props: { node: NumberNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[
        props.node.value !== undefined && props.node.value !== null
          ? `${props.node.prefix ?? ""}${props.node.value.toLocaleString()}`
          : "",
      ]}
    />
  );
};
