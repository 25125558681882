export const predefinedColours = [
  "gray",
  "light-gray",
  "red",
  "light-red",
  "white",
  "yellow",
  "blue",
] as const;

export type PredefinedColours = typeof predefinedColours[number];
type ColourMap = { [key in PredefinedColours]: string };

const bg: ColourMap = {
  gray: "bg-gray-300",
  "light-gray": "bg-gray-100",
  red: "bg-red-500",
  "light-red": "bg-red-100",
  white: "bg-white",
  blue: "bg-blue-500",
  yellow: "bg-yellow-300",
};

const border: ColourMap = {
  gray: "border-gray-500",
  "light-gray": "border-gray-300",
  red: "border-red-700",
  "light-red": "border-red-300",
  white: "border-gray-300",
  blue: "border-black",
  yellow: "border-yellow-500",
};

const fontColour: ColourMap = {
  gray: "text-black",
  "light-gray": "text-black",
  red: "text-white",
  "light-red": "text-black",
  white: "text-black",
  blue: "text-white",
  yellow: "text-black",
};

export const DEFAULT_COLOUR: PredefinedColours = "white";

export const COLOUR_SCHEME = (colour: PredefinedColours): string => {
  return `${BG(colour)} ${BORDER(colour)} ${FONT_COLOUR(colour)}`;
};

export const BG = (colour: PredefinedColours | undefined): string => {
  return bg[colour ?? DEFAULT_COLOUR];
};

export const BORDER = (colour: PredefinedColours | undefined): string => {
  return border[colour ?? DEFAULT_COLOUR];
};

export const FONT_COLOUR = (colour: PredefinedColours | undefined): string => {
  return fontColour[colour ?? DEFAULT_COLOUR];
};
