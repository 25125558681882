import React from "react";
import { PolicyContext } from "./context";
import {
  beginSovExtraction,
  useGetSovExtractions,
  useGetSovProductTableDatapoints,
} from "/src/dal/dal";
import { ProductId, ProductVersion, SovExtraction, SovExtractionId } from "/src/internal_types";
import { SovImport, SovImportLauncher } from "/src/pages/policy/sov_extraction/launcher";
import { SovImportWizard, SovImportWizardState } from "/src/pages/policy/sov_extraction/wizard";
import { useCancelOnUnmount } from "/src/utils";

type ImportToolsState =
  | { tag: "ImportLauncher" }
  | {
      tag: "SovImportOngoing";
      content: { extractionId: SovExtractionId; state: SovImportWizardState };
    };

export const ImportTools = ({
  productId,
  productVersion,
  onImportComplete,
}: {
  productId: ProductId;
  productVersion: ProductVersion;
  onImportComplete: () => void;
}): JSX.Element => {
  const { policy } = React.useContext(PolicyContext);
  const [importState, setImportState] = React.useState<ImportToolsState>({ tag: "ImportLauncher" });
  const extractions = useGetSovExtractions(productId, policy.id);
  const tableDatapoints = useGetSovProductTableDatapoints(productId, productVersion);
  const abortController = useCancelOnUnmount();
  const productSupportsSovImport =
    tableDatapoints.status === "success" && tableDatapoints.value.length > 0;
  // TODO let the user choose this, but for now we assume there's only one target table
  const tableDatapointName = productSupportsSovImport ? tableDatapoints.value[0] : undefined;
  return (
    <>
      {importState.tag === "ImportLauncher" && (
        <div className="flex flex-col gap-6 mt-6">
          <div>
            <p className="text-base font-semibold">Previous imports</p>
            <p className="text-sm">These are the imports you've already started on.</p>
          </div>
          <div className="grid grid-cols-3 gap-6">
            {extractions.status !== "success" || tableDatapointName === undefined
              ? null
              : extractions.value.map((extraction: SovExtraction) => (
                  <SovImport
                    key={extraction.sovExtractionId}
                    extraction={extraction}
                    onContinueImport={() => {
                      const importState: {
                        extractionId: SovExtractionId;
                        state: SovImportWizardState;
                      } = {
                        extractionId: extraction.sovExtractionId,
                        state: {
                          tag: "UploadSpreadsheet",
                          content: {
                            selectedFile: {
                              tag: "RemoteFileSelected",
                              remoteFile: extraction.sovFile,
                            },
                            targetTableDatapoint: tableDatapointName,
                            isUploading: false,
                            isGettingCandidates: false,
                          },
                        },
                      };
                      setImportState({ tag: "SovImportOngoing", content: importState });
                    }}
                  />
                ))}
          </div>
          <div>
            <p className="text-base font-semibold">Import tools</p>
            <p className="text-sm">
              Select a tool to begin your import, or jump back into a previous one.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-6">
            {tableDatapointName !== undefined && (
              <SovImportLauncher
                onLaunch={async () => {
                  const beginExtractionResponse = await beginSovExtraction(
                    productId,
                    policy.id,
                    abortController,
                  );
                  if (beginExtractionResponse.status !== 200) return null;
                  const extractionId = beginExtractionResponse.result;
                  const importState: {
                    extractionId: SovExtractionId;
                    state: SovImportWizardState;
                  } = {
                    extractionId,
                    state: {
                      tag: "UploadSpreadsheet",
                      content: {
                        selectedFile: { tag: "NoFileYet" },
                        targetTableDatapoint: tableDatapointName,
                        isUploading: false,
                        isGettingCandidates: false,
                      },
                    },
                  };
                  setImportState({ tag: "SovImportOngoing", content: importState });
                }}
              />
            )}
          </div>
        </div>
      )}
      {tableDatapointName !== undefined && importState.tag === "SovImportOngoing" && (
        <SovImportWizard
          extractionId={importState.content.extractionId}
          tableDatapointName={tableDatapointName}
          wizardState={importState.content.state}
          setWizardState={(wizardState) =>
            setImportState({
              tag: "SovImportOngoing",
              content: { ...importState.content, state: wizardState },
            })
          }
          onImportComplete={onImportComplete}
        />
      )}
    </>
  );
};
