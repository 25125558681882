import React from "react";
import { generalEditorOrSummaryView, SelectableNodeT_, SingleNodeT_ } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";
import { Autocomplete } from "@mui/lab";

export interface TextSelectNodeT_ extends SingleNodeT_<string>, SelectableNodeT_<string> {
  type: "text_select";
}

export const TextSelectNode = (props: { node: TextSelectNodeT_ }) => {
  return generalEditorOrSummaryView(
    props.node,
    TextSelectNodeEditorView,
    TextSelectNodeSummaryView,
  );
};

export const TextSelectNodeEditorView = (props: { node: TextSelectNodeT_ }) => {
  const { node } = props;

  const onChange = (newValue: string | null) => {
    if (newValue === null) {
      node.commit(null);
    } else {
      node.commit(newValue.trim() === "" ? null : newValue);
    }
  };

  return (
    <LeafNode node={node}>
      <Autocomplete
        value={node.value}
        options={node.options}
        multiple={false}
        onChange={(_evt, value) => onChange(value)}
        disabled={node.readOnly}
        renderInput={(params) => <TextField {...params} node={node} />}
      />
    </LeafNode>
  );
};

export const TextSelectNodeSummaryView = (props: { node: TextSelectNodeT_ }) => {
  return <SummaryLeafStringNode label={props.node.label} contents={[props.node.value ?? ""]} />;
};
