import Cleave from "cleave.js/react";
import { add, formatISO, isAfter, isBefore, isValid, parseISO } from "date-fns";
import React from "react";
import "react-day-picker/lib/style.css";
import {
  ComparableNodeT_,
  generalEditorOrSummaryView,
  SingleNodeT_,
  useSingleNodeState,
} from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";
import { DateDuration } from "/src/internal_types";
import { toNonEmptyString, useCurrentTime } from "/src/utils";

export interface DateNodeT_ extends SingleNodeT_<string>, ComparableNodeT_<string> {
  type: "date";
  relativeMinimum?: DateDuration;
  relativeMaximum?: DateDuration;
}
export interface DateNodeProps {
  node: DateNodeT_;
}

export const DateNode = (props: DateNodeProps): JSX.Element => {
  return generalEditorOrSummaryView(props.node, DateNodeEditorView, DateNodeSummaryView);
};

const DateNodeEditorView = (props: DateNodeProps): JSX.Element => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);
  const currentTime = useCurrentTime();

  const commitValue = async (value: string) => {
    await node.commit(toNonEmptyString(value));
  };
  const handleOnBlur = async () => {
    const date = parseISO(localValue);
    if (isValid(date)) {
      await commitValue(localValue);
    } else {
      node.commit(null);
    }
  };

  const bounds = [];
  let hasProblem = false;
  if (node.minimum !== null) {
    bounds.push(
      `after ${formatISO(new Date(node.minimum), { representation: "date" })} (inclusive).`,
    );
  }
  if (node.maximum !== null) {
    bounds.push(
      `before ${formatISO(new Date(node.maximum), { representation: "date" })} (inclusive).`,
    );
  }
  const nodeDate = node.value !== null ? parseISO(node.value) : null;
  if (node.relativeMinimum !== undefined) {
    const newDate = add(currentTime, {
      days: node.relativeMinimum.days,
      months: node.relativeMinimum.months,
    });
    newDate.setHours(0, 0, 0, 0);
    hasProblem = hasProblem || (nodeDate !== null ? isBefore(nodeDate, newDate) : false);
    bounds.push(`after ${formatISO(newDate, { representation: "date" })} (inclusive).`);
  }
  if (node.relativeMaximum !== undefined) {
    const newDate = add(currentTime, {
      days: node.relativeMaximum.days,
      months: node.relativeMaximum.months,
    });
    newDate.setHours(0, 0, 0, 0);
    hasProblem = hasProblem || (nodeDate !== null ? isAfter(nodeDate, newDate) : false);
    bounds.push(`before ${formatISO(newDate, { representation: "date" })} (inclusive).`);
  }
  const helperText = `Format: YYYY-MM-DD${
    bounds.length > 0 ? `, Enter a date ${bounds.join(", ")}` : ""
  }`;

  const newNode = { ...node, needsCollecting: hasProblem || node.needsCollecting };

  return (
    <LeafNode node={newNode}>
      <div>
        <TextField
          node={newNode}
          helperText={helperText}
          value={localValue}
          onChange={(e) => {
            setLocalValue(e.target.value);
          }}
          onBlur={handleOnBlur}
          inputProps={{ value: localValue }}
          InputProps={{
            inputComponent: DateCleave,
          }}
          className="focus:border-blue"
          disabled={node.readOnly}
        />
      </div>
    </LeafNode>
  );
};

const DateCleave = (props: any) => {
  return (
    <Cleave
      {...props}
      options={{
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      }}
      placeholder="YYYY-MM-DD"
      className="rounded border-0 w-full text-sm focus:ring-0"
    />
  );
};

export const DateNodeSummaryView = (props: DateNodeProps): JSX.Element => {
  return <SummaryLeafStringNode label={props.node.label} contents={[props.node.value ?? ""]} />;
};
