import React, { useEffect, useState } from "react";
import { UploadIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, CloudUploadIcon } from "@heroicons/react/solid";
import { useDropzone } from "react-dropzone";
import { Button } from "/src/design_system/Button";
import { SovAvailableTabs, SovColumnMatches, SovFile, SovSelectedTabs } from "/src/internal_types";
import { useCancelOnUnmount } from "/src/utils";

import { Loader } from "/src/design_system/Loader";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

/**
 * We can select files locally, but we might also be continuing the import of a
 * file that has already been uploaded to Brossa.
 */
export type SovFileState =
  | { tag: "NoFileYet" }
  | { tag: "LocalFileSelected"; localFile: File }
  | { tag: "RemoteFileSelected"; remoteFile: SovFile };

export type UploadPageState = {
  selectedFile: SovFileState;
  targetTableDatapoint: string;
  isUploading: boolean;
  availableTabs?: SovAvailableTabs;
  isGettingCandidates: boolean;
};

export const UploadPage = ({
  pageState,
  setPageState,
  moveToNextPage,
  getTabs,
  uploadAndGetTabs,
  selectTabsAndGetCandidates,
}: {
  pageState: UploadPageState;
  setPageState: (pageState: UploadPageState) => void;
  moveToNextPage: (tab: { index: number; name: string }, candidates: SovColumnMatches[]) => void;
  getTabs: GetTabs;
  uploadAndGetTabs: UploadAndGetTabs;
  selectTabsAndGetCandidates: SelectTabsAndGetCandidates;
}): JSX.Element => {
  /*
  When we continue the import of a file that has already been uploaded to Brossa,
  we need to upload *to the underlying extraction API* and get the tabs.

  We wouldn't need this useEffect if *uploading* and *extracting the tabs* were
  separate actions in the UI, with different controls.
  */
  useEffect(() => {
    if (
      pageState.selectedFile.tag === "RemoteFileSelected" &&
      pageState.availableTabs === undefined
    ) {
      onRemoteFileSelected().catch(() => {
        return;
      });
    }
    return;
  }, []);

  const onRemoteFileSelected = async () => {
    setPageState({
      ...pageState,
      isUploading: true,
    });
    const uploadResult = await getTabs(abortController);
    if (uploadResult == null) {
      setPageState({
        ...pageState,
        isUploading: false,
      });
    } else {
      setPageState({
        ...pageState,
        isUploading: false,
        availableTabs: uploadResult,
      });
    }
  };

  /*
  This callback is for locally uploaded files.
  */
  const onFilesSelected = async (files: File[]) => {
    if (files.length < 1) return;
    const file: File = files[0];
    setPageState({
      ...pageState,
      selectedFile: { tag: "LocalFileSelected", localFile: file },
      isUploading: true,
    });
    const uploadResult = await uploadAndGetTabs(file, abortController);
    if (uploadResult == null) {
      setPageState({
        ...pageState,
        selectedFile: { tag: "NoFileYet" },
        isUploading: false,
      });
    } else {
      setPageState({
        ...pageState,
        selectedFile: { tag: "LocalFileSelected", localFile: file },
        isUploading: false,
        availableTabs: uploadResult,
      });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFilesSelected });
  const abortController = useCancelOnUnmount();
  const availableTabs = pageState.availableTabs;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <div>
      <p className="text-xl leading-7 font-bold text-gray-900">Import a spreadsheet</p>
      <div className="border border-solid border-gray-200 rounded p-4 mt-5">
        <div className="grid grid-cols-[1fr_2fr] gap-4 ">
          <div>
            <p className="text-base leading-6 font-medium text-gray-900">Upload a file</p>
            <p className="text-sm leading-5 font-normal text-gray-500">
              You can upload .xls or .xlsx files for Artificial to parse. For details on how to
              improve the quality of your import, download our sov-template.csv or read the data
              extraction documentation.
            </p>
          </div>
          <div
            {...getRootProps()}
            className="bg-gray-50 rounded p-5 flex flex-col items-center cursor-pointer"
          >
            <input
              {...getInputProps()}
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
              accept=".xls,.xlsx"
            />
            <div className="flex items-center justify-center rounded-full w-14 h-14 bg-white shadow-sm">
              {pageState.selectedFile.tag === "NoFileYet" && (
                <UploadIcon className="w-5 h-5 text-gray-900" />
              )}
              {pageState.isUploading && <Loader size="sm" />}
              {pageState.selectedFile.tag !== "NoFileYet" && !pageState.isUploading && (
                <CheckCircleIcon className="w-5 h-5 text-green-500" />
              )}
            </div>
            <div className="mt-3 flex flex-col items-center">
              {pageState.selectedFile.tag === "NoFileYet" && (
                <>
                  <p className="text-sm leading-5 font-medium text-gray-600">
                    Choose file or drag and drop here
                  </p>
                  <p className="text-xs leading-4 font-normal text-gray-400">
                    Supports xls or xlsx files
                  </p>
                </>
              )}
              {pageState.isUploading && (
                <>
                  <p className="text-sm leading-5 font-medium text-blue-600">Importing file...</p>
                  <p className="text-xs leading-4 font-normal text-gray-400">
                    Please wait while we parse this file...
                  </p>
                </>
              )}
              {pageState.selectedFile.tag !== "NoFileYet" && !pageState.isUploading && (
                <>
                  <p className="text-sm leading-5 font-medium text-gray-700">
                    {pageState.selectedFile.tag == "LocalFileSelected"
                      ? pageState.selectedFile.localFile.name
                      : pageState.selectedFile.remoteFile.sovFileName}
                  </p>
                  <p className="text-xs leading-4 font-normal text-blue-500">Remove file</p>
                </>
              )}
            </div>
          </div>
          {availableTabs !== undefined && (
            <>
              <hr className="mt-4 border-gray-200 col-span-2" />
              <div>
                <p className="text-base leading-6 font-medium text-gray-900">Select tab(s)</p>
                <p className="text-sm leading-5 font-normal text-gray-500">
                  Select the tab(s) on the spreadsheet where the schedule of values is located
                </p>
              </div>
              <RadioGroup
                value={selectedTab}
                onChange={(_e, v) => {
                  console.log("selected tab: ", v);
                  setSelectedTab(parseInt(v));
                }}
              >
                {availableTabs.sheetNames.map((tab, i) => (
                  <FormControlLabel
                    key={i}
                    value={i}
                    control={<Radio color="primary" size={"small"} />}
                    label={tab}
                  />
                ))}
              </RadioGroup>
            </>
          )}
        </div>
        <hr className="mt-4 border-gray-200" />
        {availableTabs !== undefined && (
          <Button
            type="button"
            variant="primary"
            className="ml-auto mr-0 mt-6"
            isPending={pageState.isGettingCandidates}
            onClick={async () => {
              setPageState({
                ...pageState,
                isGettingCandidates: true,
              });
              const selectTabsResult = await selectTabsAndGetCandidates(
                pageState.targetTableDatapoint,
                {
                  sheetIndices: [selectedTab],
                },
                abortController,
              );
              if (selectTabsResult == null) {
                setPageState({
                  ...pageState,
                  isGettingCandidates: false,
                });
              } else {
                const tabName = availableTabs.sheetNames[selectedTab];
                const tab = { index: selectedTab, name: tabName };
                moveToNextPage(tab, selectTabsResult);
              }
            }}
          >
            <CloudUploadIcon className="w-4 h-4" />
            <p className="ml-3">Import schedule</p>
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * When the file is already in Brossa.
 */
export type GetTabs = (abortController: AbortController) => Promise<SovAvailableTabs | null>;

/**
 * When the file hasn't been uploaded to Brossa yet.
 */
export type UploadAndGetTabs = (
  file: File,
  abortController: AbortController,
) => Promise<SovAvailableTabs | null>;

export type SelectTabsAndGetCandidates = (
  targetTableDatapoint: string,
  selectedTabsWithCorrelationId: SovSelectedTabs,
  abortController: AbortController,
) => Promise<SovColumnMatches[] | null>;
