import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Modal } from "../../design_system/Modal";

interface CloneModalProps {
  isOpen: boolean;
  close: () => void;
}

export const CloneModal: React.FC<CloneModalProps> = ({ isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <div className="px-1 py-2 max-w-md border border-gray-400 shadow-sm">
        <div className="px-6">
          <div className="mt-3">
            <div className="flex space-x-2">
              <ExclamationCircleIcon className="text-red-500 h-7" />
              <h3 className="text-xl font-semibold self-centered">Cannot clone policy</h3>
            </div>
            <div className="mt-4 mb-3">
              <p className="text-l text-gray-500 mb-3">
                This product has a new version that is incompatible with the information in the
                current policy.
              </p>
              <p className="text-l text-gray-500">
                Please create a new policy and copy the information over manually instead.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
