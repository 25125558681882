import React from "react";
import { Routes } from "../routing/routes";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Link } from "react-router-dom";
import { useParams } from "../routing/routing";
import { ProductEditor } from "../components/ProductEditor";
import { useGetEditableProduct } from "../dal/dal";
import { throwIfAppError } from "/src/utils/app_error";

////////////////////////////////////////////////////////////////////////////////
// Main entry point

const ReadProduct: React.FC = () => {
  const {
    data: {
      capture: { productId, versionId },
    },
  } = useParams(Routes.readProduct);
  const product = useGetEditableProduct(productId, versionId);
  throwIfAppError(product);
  const files = product.value.sourceTextFiles.map((file) => ({
    name: file.fileName,
    contents: file.fileContents,
  }));
  return (
    <Page>
      <PageHeader>
        <EditProductHeader productId={productId} />
      </PageHeader>
      <PageContent>
        <ProductEditor
          actionButtonTitle="Read"
          name={product.value.name}
          files={files}
          onSubmit={() => null}
          onFinish={() => null}
          readOnly={true}
        />
      </PageContent>
    </Page>
  );
};

const EditProductHeader = (props: { productId: number }) => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <Link
          to={Routes.product.generatePath({ capture: { productId: props.productId } })}
          className="group py-2 pr-6 flex flex-row"
        >
          ← Back to product
        </Link>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div>
          <PageTitle>Read product version</PageTitle>
        </div>
      </div>
    </div>
  );
};

export default ReadProduct;
