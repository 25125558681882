import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routing/routes";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Link } from "react-router-dom";
import { ProductEditor, Meta, OnFinish, File } from "../components/ProductEditor";
import { newProductPending } from "/src/dal/dal";

////////////////////////////////////////////////////////////////////////////////
// Constants

const defaultMainDotArt = `insured.name = "Empty";

submission = {};
quote = {};
accept = {};
reject = {};
not_taken_up = {};
bind = {};
cancel = {};

condition refer;
condition decline;

premium: Number = 0;`;

const defaultDescription = "An empty product.";

const defaultProductName = "Empty product";

const defaultFilesList = [
  { name: "description.txt", contents: defaultDescription },
  { name: "main.art", contents: defaultMainDotArt },
];

////////////////////////////////////////////////////////////////////////////////
// Main entry point

const CreateProduct: React.FC = () => {
  const history = useHistory();
  return (
    <Page>
      <PageHeader>
        <NewProductHeader />
      </PageHeader>
      <PageContent>
        <ProductEditor
          actionButtonTitle="Create"
          name={defaultProductName}
          files={defaultFilesList}
          readOnly={false}
          onSubmit={createTheProduct}
          onFinish={(id) =>
            history.push(Routes.pendingProduct.generatePath({ capture: { pendingProductId: id } }))
          }
        />
      </PageContent>
    </Page>
  );
};

const NewProductHeader: React.FC = () => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <Link to={Routes.products.generatePath({})} className="group py-2 pr-6 flex flex-row">
          ← Back to products
        </Link>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div>
          <PageTitle>Create pending product</PageTitle>
        </div>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Functions

const createTheProduct = async (
  files: File[],
  meta: Meta,
  onFinish: OnFinish,
  onError: (error: string) => void,
) => {
  await newProductPending(meta.name, files).then((r) => {
    if (r.status === "success") {
      onFinish(r.value);
    } else {
      onError(`Couldn't edit product. The error was ${r.value.message}`);
    }
  });
};

export default CreateProduct;
