import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/solid";
import React, { MutableRefObject, useEffect } from "react";
import { FieldState } from "./text_component";
import { Loader } from "/src/design_system/Loader";

/**
 * A function that scrolls to a given element
 * @param el an element that we want to scroll to
 *
 * @todo include an example of how to use it
 */
const scrollToField = (el: HTMLDivElement) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      el.animate(
        [
          {
            offset: 0.0,
            transform: "translateX(0px)",
            easing: "ease-in",
          },
          {
            offset: 0.37,
            transform: "translateX(6px)",
            easing: "ease-out",
          },
          {
            offset: 0.55,
            transform: "translateX(-6px)",
            easing: "ease-in",
          },
          {
            offset: 0.73,
            transform: "translateX(5px)",
            easing: "ease-out",
          },
          {
            offset: 0.82,
            transform: "translateX(-5px)",
            easing: "ease-in",
          },
          {
            offset: 0.91,
            transform: "translateX(3px)",
            easing: "ease-out",
          },
          {
            offset: 0.96,
            transform: "translateX(-3px)",
            easing: "ease-in",
          },
          {
            offset: 1,
            transform: "translateX(0px)",
            easing: "ease-in",
          },
        ],
        1000,
      );
      // }
      resolve();
    }, 250);
  });
};

export function useFocusableRefObject(): [
  FocusableRefObject,
  (id: string | undefined) => Promise<HTMLDivElement | undefined>,
] {
  const refObject: FocusableRefObject = React.useRef<{
    [key in string]: HTMLDivElement | null | undefined;
  }>({});
  const scrollToFieldById = async (id: string | undefined): Promise<HTMLDivElement | undefined> => {
    if (id !== undefined) {
      const focusableDiv = refObject.current[id];
      if (focusableDiv !== undefined && focusableDiv !== null) {
        await scrollToField(focusableDiv);
        return focusableDiv;
      }
    }
    return undefined;
  };
  return [refObject, scrollToFieldById];
}

/**
 * @todo documentation with thorough explanation on what this thing does
 *
 * @see {scrollToField}
 */
export type FocusableRefObject = MutableRefObject<{
  [key in string]: HTMLDivElement | null | undefined;
}>;

/** A component that renders its children and also adds itself to a {@link FocusableRefObject}. */
export function FocusableDiv(props: {
  refObject: FocusableRefObject | undefined;
  divIds: string[];
  children: JSX.Element | null;
}): JSX.Element {
  const { refObject, divIds, children } = props;
  return (
    <div
      // a callback ref https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
      ref={(e) => {
        if (refObject !== undefined) {
          divIds.forEach((id) => {
            refObject.current[id] = e;
          });
        }
      }}
      className="empty:hidden"
    >
      {children}
    </div>
  );
}

/**
 * A component that shows an icon for a field that depends on the {@link FieldState}
 */
export const FieldIcon = (props: { state: FieldState }): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.state === "correct" && ref.current !== null) {
      ref.current.animate(
        [
          {
            offset: 0.0,
            opacity: 1,
          },
          {
            offset: 1,
            easing: "ease-out",
            opacity: 0,
          },
        ],
        1000,
      );
    }
  }, [props.state, ref]);
  return (
    <div ref={ref} className={` ${props.state === "correct" ? "opacity-0" : ""}`}>
      {props.state === "error" && <InformationCircleIcon className="h-5 text-red-500" />}
      {props.state === "correct" && <CheckCircleIcon className="h-5 text-green-600" />}
      {props.state === "loading" && <Loader size="sm" />}
    </div>
  );
};
