import React from "react";
import NumberFormat from "react-number-format";
import {
  ComparableNodeT_,
  generalEditorOrSummaryView,
  SingleNodeT_,
  useSingleNodeState,
  WithPrefixNodeT_,
} from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { toNumber } from "/src/utils";
import { TextField } from "./text_component";
import { CAPTIONS } from "../constants";

export interface PremiumNodeT_
  extends SingleNodeT_<number>,
    ComparableNodeT_<number>,
    WithPrefixNodeT_ {
  type: "premium";
  hidePremium: boolean;
}

export const PremiumNode = (props: { node: PremiumNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, PremiumNodeEditorView, PremiumNodeSummaryView);
};

export const PremiumNodeEditorView = (props: { node: PremiumNodeT_ }): JSX.Element => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = async () => {
    await node.commit(toNumber(localValue));
  };

  if (node.readOnly) {
    return (
      <div className="bg-gray-100 rounded p-3 mt-3">
        <p>{node.label}</p>
        <p className="pt-2 text-2xl font-bold">
          {node.hidePremium ? (
            <span className="text-gray-400">{CAPTIONS.PREMIUM_HIDDEN}</span>
          ) : node.value !== null ? (
            `${node.prefix ?? ""}${node.value.toLocaleString()}`
          ) : (
            "-"
          )}
        </p>
      </div>
    );
  }

  // TODO: NumberFormat<any> is ugly but equivalent to the type in react-number-format before
  // https://github.com/s-yadav/react-number-format/commit/1b6d2f39817cd2a8995980bec7a4caad1b6322b2
  return (
    <LeafNode node={node}>
      <NumberFormat<any>
        thousandSeparator={true}
        min={node.minimum ?? undefined}
        max={node.maximum ?? undefined}
        customInput={TextField}
        prefix={node.prefix}
        allowEmptyFormatting
        value={localValue ?? undefined}
        onValueChange={(e) => {
          setLocalValue(e.value);
        }}
        onBlur={handleOnBlur}
        node={node}
        // disabled={node.readOnly}
      />
    </LeafNode>
  );
};

const PremiumNodeSummaryView = (props: { node: PremiumNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[
        props.node.hidePremium
          ? CAPTIONS.PREMIUM_HIDDEN
          : props.node.value !== null
          ? `${props.node.prefix ?? ""}${props.node.value.toLocaleString()}`
          : "",
      ]}
      size="large"
    />
  );
};
