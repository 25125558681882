import { Asyncs } from "/src/asyncs";
import { ExclamationIcon } from "@heroicons/react/solid";
import hash from "object-hash";
import React, { PropsWithChildren, Suspense, useEffect, useRef, useMemo } from "react";
import { transactPolicy, transactQuote, invalidatePolicy, invalidateTimeline } from "./dal/dal";
import { buildTree } from "./data_editor/builder";
import { DataEditorContext } from "./data_editor/context";
import { renderNode } from "./data_editor/node";
import { FocusableRefObject } from "./data_editor/nodes/focusable_node";
import { NodeT, NodeT_ } from "./data_editor/types";
import {
  getDatapointsTriggeringReferral,
  getDeclineConditions,
  getDeclineConditionsFromGoalInfo,
  getQuoteReferConditions,
  getReferConditionsFromGoalInfo,
  GoalInfo,
  PolicyId,
  PolicyInfo,
  ProductId,
  QuoteId,
  QuoteInfo,
  Quotes,
  ReferMap,
  TcAliases,
  Transaction,
} from "./internal_types";
import {
  determinePolicyState,
  determineQuoteState,
  EditP,
  GlobalPolicyState,
  PartialQuoteState,
  Permission,
  PolicyState,
  QuoteState,
  ShowConditionsWarningP,
  ShowReferralStatusForDatapoints,
} from "./pages/policy/permissions";
import { PromiseReturnType } from "./utils";
import { Modal } from "./design_system/Modal";
import { Button } from "./design_system/Button";

interface TxState {
  txInFlight: null | Transaction;
  txQueue: Array<[Transaction, string[]]>;
}
export type TxStatus = "idle" | "in_flight";

interface TheDataEditorProps {
  productId: ProductId;
  policyId: PolicyId;
  quoteId?: QuoteId | undefined;
  summaryView?: boolean;
  fields: GoalInfo;
  aliases: TcAliases;
  transact: (tx: Transaction) => Promise<void>;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  refObject?: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  formSubmitted: boolean;
  withoutShadowAndPadding?: boolean;
  referMap: ReferMap | undefined; // undefined means to not to show referral status for datapoints
  asyncs: Asyncs;
}

const TheDataEditor = (props: TheDataEditorProps): JSX.Element => {
  const txState = useRef<TxState>({
    txInFlight: null,
    txQueue: [],
  });

  const [isCommittingMap, setIsCommittingMap] = React.useState<{
    [key in string]: boolean | undefined;
  }>({});

  const processNextTransaction = async () => {
    if (txState.current.txInFlight === null) {
      const tx = txState.current.txQueue.pop();
      if (tx !== undefined) {
        txState.current.txInFlight = tx[0];
        await props.transact(tx[0]);
        txState.current.txInFlight = null;
        tx[1].map((id) => {
          isCommittingMap[id] = false;
        });
        setIsCommittingMap(isCommittingMap);
        await processNextTransaction();
      } else {
        props.onTxStatusChange?.("idle");
      }
    }
  };

  const commit = (tx: Transaction, ids: string[]) => {
    // TODO: add queue compression
    ids.map((id) => {
      isCommittingMap[id] = true;
    });
    setIsCommittingMap(isCommittingMap);
    txState.current.txQueue.push([tx, ids]);
    props.onTxStatusChange !== undefined &&
      txState.current.txQueue.length === 1 &&
      props.onTxStatusChange("in_flight");
    return processNextTransaction();
  };

  const nodes: NodeT[] = useMemo(
    () =>
      buildTree(
        // If you add a field here, also add it below.
        props.fields.keys,
        props.aliases,
        commit,
        props.summaryView ?? false,
        props.productId,
        props.policyId,
        props.quoteId,
        isCommittingMap,
        props.formSubmitted,
        props.asyncs,
      ),
    [
      // It's important to be careful about how equality works in JS - e.g. {}
      // !== {}, and [] !== [].
      // So if you just put an entire object or array in, it may cause more
      // re-renders than needed. Using it's hash will help instead, but be aware
      // that it's expensive for large objects!
      //
      // props.fields.keys might lead to unnecessary re-renders, but computing
      // it's hash is too expensive.
      props.fields.keys,
      props.aliases,
      // We don't add 'commit' since its const
      props.summaryView ?? false,
      props.productId,
      props.policyId,
      props.quoteId,
      hash(isCommittingMap),
      props.formSubmitted,
      hash(props.asyncs),
    ],
  );

  useEffect(() => {
    props.onNodesChange(nodes);
  }, [nodes]);

  return (
    <div className="bg-white rounded overflow-hidden ">
      <div
        className={`empty:hidden ${
          props.summaryView || props.withoutShadowAndPadding ? "" : "border-b px-4 py-4"
        } ${props.summaryView ? "" : "space-y-6"}`}
        data-testid="data-editor"
      >
        <DataEditorContext.Provider
          value={{
            productId: props.productId,
            policyId: props.policyId,
            quoteId: props.quoteId,
          }}
        >
          {nodes.map((node) => (
            <div
              key={node.id}
              data-nodeid={node.id}
              data-quoteid={props.quoteId}
              className="empty:hidden"
            >
              {renderNode(node, props.refObject, props.referMap, props.fields)}
            </div>
          ))}
        </DataEditorContext.Provider>
      </div>
      <FallbackErrorNotice nodes={nodes} fields={props.fields} />
    </div>
  );
};

const ConditionStrip = (props: PropsWithChildren<{ color: "yellow" | "red" }>): JSX.Element => {
  return (
    <div
      className={`px-6 py-2 flex items-center  ${
        props.color === "yellow" ? " bg-yellow-50  text-yellow-700" : " bg-red-100 text-red-700"
      } `}
    >
      <ExclamationIcon className="w-5 inline-block mr-2" />
      {props.children}
    </div>
  );
};

export const DataEditor: typeof TheDataEditor = (props) => (
  <Suspense fallback="Loading...">
    <TheDataEditor {...props} />
  </Suspense>
);

export type TransactQuoteResult = PromiseReturnType<ReturnType<typeof transactQuote>>;

export interface QuoteEditorProps {
  summaryView?: boolean;
  canEdit: Permission<GlobalPolicyState, QuoteState, EditP>;
  canShowReferralStatusForDatapointsForQuote: Permission<
    GlobalPolicyState,
    QuoteState,
    ShowReferralStatusForDatapoints
  >;
  canShowConditionsWarning: Permission<
    GlobalPolicyState,
    PartialQuoteState,
    ShowConditionsWarningP
  >;
  quoteInfo: QuoteInfo;
  policyInfo: PolicyInfo;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  refObject?: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  formSubmitted: boolean;
  // some fields might overlap in quote and accept goal (most usually its the premium)
  // to avoid overlap in ui, this option hides those fields
  hideAcceptDatapoints?: boolean;
  asyncs: Asyncs;
}

export const QuoteEditor = (props: QuoteEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;

  const quoteState = determineQuoteState(props.quoteInfo, props.policyInfo);
  const summaryView =
    props.summaryView === undefined ? !props.canEdit.check(quoteState) : props.summaryView;

  const fields = props.hideAcceptDatapoints
    ? {
        ...props.quoteInfo.fields.quoteGoal,
        keys: props.quoteInfo.fields.quoteGoal.keys.filter(
          (k) =>
            props.quoteInfo.fields.acceptGoal.keys.find((k2) => k2.name === k.name) === undefined,
        ),
      }
    : props.quoteInfo.fields.quoteGoal;
  const showReferralStatus = props.canShowReferralStatusForDatapointsForQuote.check(quoteState);
  const referMap =
    summaryView && showReferralStatus
      ? getDatapointsTriggeringReferral(props.quoteInfo.fields)
      : undefined;

  const transact = transactQuoteFunction(productId, props.policyInfo.id, props.quoteInfo.id);
  const showConditionsStrip = props.canShowConditionsWarning.check(quoteState);

  const editorProps = {
    productId,
    summaryView,
    fields,
    transact,
    onTxStatusChange: props.onTxStatusChange,
    aliases: props.quoteInfo.fields.typeAliases,
    policyId: props.policyInfo.id,
    quoteId: props.quoteInfo.id,
    refObject: props.refObject,
    onNodesChange: props.onNodesChange,
    formSubmitted: props.formSubmitted,
    referMap,
    showConditionsStrip: false,
    asyncs: props.asyncs,
  };

  return (
    <div>
      <TheDataEditor {...editorProps} />
      {showConditionsStrip && <QuoteNotice info={props.quoteInfo} />}
    </div>
  );
};

export type TransactPolicyResult = PromiseReturnType<ReturnType<typeof transactPolicy>>;

export interface PolicyEditorProps {
  policyInfo: PolicyInfo;
  quotes: Quotes;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  canShowReferralStatusForDatapointsForPolicy: Permission<
    GlobalPolicyState,
    PolicyState,
    ShowReferralStatusForDatapoints
  >;
  canShowConditionsWarningPolicy: Permission<
    GlobalPolicyState,
    PolicyState,
    ShowConditionsWarningP
  >;
  refObject?: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  formSubmitted: boolean;
  summaryView?: boolean;
  asyncs: Asyncs;
}

export const PolicyEditor = (props: PolicyEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;

  const summaryView = props.summaryView;

  const fields = props.policyInfo.fields.submissionGoal;

  const transact = async (tx: Transaction) => {
    const qinfo = await transactPolicy(productId, props.policyInfo.id, tx);
    if (qinfo.status == "success") {
      await invalidatePolicy(productId, props.policyInfo.id);
      await invalidateTimeline(productId, props.policyInfo.id);
    }
  };
  const policyState = determinePolicyState(props.policyInfo);
  const showReferralStatus = props.canShowReferralStatusForDatapointsForPolicy.check(policyState);
  const referMap: ReferMap | undefined =
    summaryView && showReferralStatus
      ? getDatapointsTriggeringReferral(props.policyInfo.fields)
      : undefined;

  const showConditionsStrip = props.canShowConditionsWarningPolicy.check(policyState);
  const editorProps: TheDataEditorProps = {
    productId,
    summaryView: summaryView,
    fields,
    transact,
    onTxStatusChange: props.onTxStatusChange,
    aliases: props.policyInfo.fields.typeAliases,
    policyId: props.policyInfo.id,
    refObject: props.refObject,
    onNodesChange: props.onNodesChange,
    formSubmitted: props.formSubmitted,
    referMap,
    asyncs: props.asyncs,
  };

  return (
    <div>
      <TheDataEditor {...editorProps} />
      {showConditionsStrip && <PolicyNotice info={props.policyInfo} />}
    </div>
  );
};

export interface BindEditorProps {
  quoteInfo: QuoteInfo;
  policyInfo: PolicyInfo;
  summaryView?: boolean;
  showAdjustable: boolean;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  refObject?: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  formSubmitted: boolean;
  asyncs: Asyncs;
}

export const BindEditor = (props: BindEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;

  const summaryView = props.summaryView;
  const fields: GoalInfo = props.showAdjustable
    ? {
        keys: props.quoteInfo.fields.quoteGoal.keys
          .filter((k) => k.adjustable)
          .concat(props.quoteInfo.fields.bindGoal.keys),
        obstructed:
          props.quoteInfo.fields.quoteGoal.obstructed && props.quoteInfo.fields.bindGoal.obstructed,
        globalObstacles: props.quoteInfo.fields.quoteGoal.globalObstacles.concat(
          props.quoteInfo.fields.bindGoal.globalObstacles,
        ),
      }
    : props.quoteInfo.fields.bindGoal;

  const transact = transactQuoteFunction(productId, props.policyInfo.id, props.quoteInfo.id);

  const editorProps = {
    productId,
    summaryView,
    fields,
    transact,
    onTxStatusChange: props.onTxStatusChange,
    aliases: props.quoteInfo.fields.typeAliases,
    policyId: props.policyInfo.id,
    quoteId: props.quoteInfo.id,
    refObject: props.refObject,
    onNodesChange: props.onNodesChange,
    formSubmitted: props.formSubmitted,
    referMap: undefined,
    hideReferralMessage: true,
    asyncs: props.asyncs,
  };

  return <TheDataEditor {...editorProps} />;
};
function QuoteNotice(props: { info: QuoteInfo }) {
  const referReasons = getQuoteReferConditions(props.info);
  const declineReasons = getDeclineConditions(props.info);
  if (declineReasons.length > 0) {
    return <ConditionStrip color="red">This quote will be declined.</ConditionStrip>;
  }
  if (referReasons.length > 0) {
    return (
      <ConditionStrip color="yellow">This quote will be referred to underwriter.</ConditionStrip>
    );
  }
  return null;
}
function PolicyNotice(props: { info: PolicyInfo }) {
  const referReasons = getReferConditionsFromGoalInfo(props.info.fields.submissionGoal);
  const declineReasons = getDeclineConditionsFromGoalInfo(props.info.fields.submissionGoal);
  if (declineReasons.length > 0) {
    return <ConditionStrip color="red">Quotes will be declined.</ConditionStrip>;
  }
  if (referReasons.length > 0) {
    return <ConditionStrip color="yellow">Quotes will be referred to underwriter.</ConditionStrip>;
  }
  return null;
}

/**
 * A component for showing errors that have not been taken care of by
 * the builder. It's a fallback as the name says.
 */
function FallbackErrorNotice(props: { nodes: NodeT[]; fields: GoalInfo }) {
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  // getFirstErrorNode function is the function that's used
  // when clicking on the "new quote" button for example
  // if it does not return anything, and we have some violations,
  // then we show this compenent
  const firstVisibleError = getFirstErrorNode(props.nodes);
  // we show the strip if first node cannot be found
  // (according to our getFirstErrorNode function),
  // fields are obstructed,
  // and we have more than 0 fields at least
  const shouldShowStrip =
    firstVisibleError === undefined && props.fields.obstructed && props.fields.keys.length > 0;
  // hide modal if there are no obstacles/violations to be shown
  useEffect(() => {
    if (shouldShowStrip) {
      setIsModalOpened(false);
    }
  }, [firstVisibleError === undefined, props.fields.obstructed, props.fields.keys.length]);
  if (!shouldShowStrip) {
    return <></>;
  }
  const errors = props.fields.globalObstacles;

  return (
    <>
      <ConditionStrip color="red">
        <div className="flex gap-3 items-center">
          <span>Form has some unhandled errors. Contact admin about it.</span>
          <Button
            variant="secondary"
            size="xs"
            onClick={() => {
              setIsModalOpened(true);
            }}
          >
            Show errors
          </Button>
        </div>
      </ConditionStrip>

      <Modal isOpen={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <div className="min-w-[600px] max-w-screen-xl flex flex-col gap-3 px-5 py-5 max-h-3/4 overflow-auto">
          <p className="text-xl font-medium"> Raw errors </p>

          <div>
            <div className="py-3 gap-3">
              {props.fields.keys.map((keyInfo) => {
                const hasViolationsOrObstacles =
                  keyInfo.datapoints.find(
                    (dp) => dp.obstacles.length > 0 || dp.violations.length > 0,
                  ) !== undefined;

                if (!hasViolationsOrObstacles) {
                  return <></>;
                }
                return (
                  <>
                    <p className="font-bold"> - {keyInfo.name}</p>
                    {keyInfo.datapoints.map((dp) => {
                      return (
                        <div className="pl-5">
                          {dp.arguments.length > 0 && (
                            <FallbackErrorView caption="Arguments">
                              {JSON.stringify(dp.arguments)}
                            </FallbackErrorView>
                          )}
                          {dp.violations.length > 0 && (
                            <FallbackErrorView caption="Violations">
                              {JSON.stringify(dp.violations)}
                            </FallbackErrorView>
                          )}
                          {dp.obstacles.length > 0 && (
                            <FallbackErrorView caption="Obstacles ">
                              {JSON.stringify(dp.obstacles)}
                            </FallbackErrorView>
                          )}
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>
          <div>
            <FallbackErrorView caption="Global obstacles">
              {JSON.stringify(errors)}
            </FallbackErrorView>
          </div>
        </div>
      </Modal>
    </>
  );
}

const FallbackErrorView = (props: { caption: string; children: string }) => {
  return (
    <div>
      <p>{props.caption}</p>
      <pre className="p-2 bg-gray-200 rounded overflow-auto max-h-64">{props.children}</pre>
    </div>
  );
};

export const getFirstErrorNode = (nodes: NodeT[]): NodeT_ | undefined => {
  for (const node of nodes) {
    if (node.type === "group") {
      const n = getFirstErrorNode(node.nodes);
      if (n !== undefined) {
        return n;
      }
    }
    if (node.type === "table") {
      for (const row of node.rows) {
        for (const cell of row.cells) {
          if (cell.type !== "n/a" && (cell.needsCollecting || cell.problems.length > 0)) {
            return cell;
          }
        }
      }
    }
    if (node.type === "subforms") {
      for (const elem of node.elems) {
        const n = getFirstErrorNode(elem.nodes);
        if (n !== undefined) {
          return n;
        }
      }
    }
    if (node.type === "money") {
      const n = getFirstErrorNode([node.amountNode, node.currencyNode]);
      if (n !== undefined) {
        return n;
      }
    }
    if (
      ("problems" in node && node.problems.length > 0) ||
      ("needsCollecting" in node && node.needsCollecting)
    ) {
      return node;
    }
  }
};

interface AdditionalGoalEditorProps {
  summaryView?: boolean;
  quoteInfo: QuoteInfo;
  policyInfo: PolicyInfo;
  refObject: FocusableRefObject;
  onNodesChange: (nodes: NodeT[]) => void;
  formSubmitted: boolean;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  fields: GoalInfo;
  transact: (tx: Transaction) => Promise<void>;
  asyncs: Asyncs;
}

const AdditionalGoalEditor = (props: AdditionalGoalEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;

  const editorProps = {
    productId,
    summaryView: props.summaryView,
    fields: props.fields,
    transact: props.transact,
    onTxStatusChange: props.onTxStatusChange,
    aliases: props.quoteInfo.fields.typeAliases,
    policyId: props.policyInfo.id,
    quoteId: props.quoteInfo.id,
    refObject: props.refObject,
    onNodesChange: props.onNodesChange,
    formSubmitted: props.formSubmitted,
    withoutShadowAndPadding: true,
    referMap: undefined,
    hideReferralMessage: true,
    asyncs: props.asyncs,
  };

  return (
    <div>
      <TheDataEditor {...editorProps} />
    </div>
  );
};

export type AcceptEditorProps = Omit<AdditionalGoalEditorProps, "fields" | "transact">;

export const AcceptEditor = (props: AcceptEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;
  const fields = props.quoteInfo.fields.acceptGoal;
  const transact = transactQuoteFunction(productId, props.policyInfo.id, props.quoteInfo.id);
  return <AdditionalGoalEditor fields={fields} transact={transact} {...props} />;
};

export type RejectEditorProps = Omit<AdditionalGoalEditorProps, "fields" | "transact">;

export const RejectEditor = (props: RejectEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;
  const fields = props.quoteInfo.fields.rejectGoal;
  const transact = transactQuoteFunction(productId, props.policyInfo.id, props.quoteInfo.id);

  return <AdditionalGoalEditor fields={fields} transact={transact} {...props} />;
};

export type NotTakenUpEditorProps = Omit<AdditionalGoalEditorProps, "fields" | "transact">;

export const NotTakenUpEditor = (props: NotTakenUpEditorProps): JSX.Element => {
  const productId = props.policyInfo.productId;
  const fields = props.quoteInfo.fields.notTakenUpGoal;
  const transact = transactQuoteFunction(productId, props.policyInfo.id, props.quoteInfo.id);

  return <AdditionalGoalEditor fields={fields} transact={transact} {...props} />;
};

const transactQuoteFunction = (
  productId: ProductId,
  policyId: PolicyId,
  quoteId: QuoteId,
): ((tx: Transaction) => Promise<void>) => {
  return async (tx: Transaction) => {
    const qinfo = await transactQuote(productId, policyId, quoteId, tx);
    if (qinfo.status == "success") {
      await invalidateTimeline(productId, policyId);
    }
  };
};
