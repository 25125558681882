import React, { useState } from "react";
import { passwordConditions } from "./validators";
import Input from "../../input";
import { Button } from "../../design_system/Button";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

type Props = {
  isValid: boolean;
  description: string;
};

const Checkmark = () => <CheckCircleIcon className="fill-current mr-3 w-6 -m-1 text-green-500" />;

const Cross = () => <XCircleIcon className="fill-current mr-3 w-6 -m-1 text-red-500" />;

const PasswordCondition = ({ isValid, description }: Props) => (
  <div className="flex items-center mb-3">
    {!isValid && <Cross />}

    {isValid && <Checkmark />}

    <p>{description}</p>
  </div>
);

export interface PasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

export interface PasswordFormProps {
  handleSubmit: (formValues: PasswordFormValues) => void;
  isLoading: boolean;
}

const PasswordForm = ({ handleSubmit, isLoading }: PasswordFormProps) => {
  const [formValues, setFormValues] = useState<PasswordFormValues>({
    newPassword: "",
    confirmNewPassword: "",
  });
  const validation = passwordConditions(formValues);
  const isValid = !Object.values(validation).some((value) => !value);
  const canSubmit = !isLoading && isValid;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(formValues);
      }}
    >
      <h2 className="mb-4 font-medium text-2xl">Create new password</h2>

      <p className="mb-6">
        For security we require that your password matches all of these requirements:
      </p>

      <div className="mb-6">
        <PasswordCondition
          description="Must be a minimum of 10 characters."
          isValid={validation.minimumCharacters}
        />

        <PasswordCondition
          description="Must contain upper and lower case letters."
          isValid={validation.uppercaseLowercase}
        />

        <PasswordCondition
          description="Must contain at least 1 number (between 0-9)"
          isValid={validation.numberMinimum}
        />

        <PasswordCondition
          description="Must contain at least 1 special character (@$!%*?&)"
          isValid={validation.specialCharacter}
        />

        <PasswordCondition
          description="Password confirmation must match password"
          isValid={validation.passwordMatch}
        />
      </div>

      <Input
        className="mb-2"
        label="New Password"
        name="newPassword"
        onChange={onChange}
        placeholder="*********"
        type="password"
        value={formValues.newPassword}
      />

      <Input
        className="mb-2"
        label="Confirm New Password"
        name="confirmNewPassword"
        onChange={onChange}
        placeholder="*********"
        type="password"
        value={formValues.confirmNewPassword}
      />

      <Button type="submit" fullWidth disabled={!canSubmit}>
        Create new password
      </Button>
    </form>
  );
};

export default PasswordForm;
