import { DatapointInfo, KeyInfo, QuoteId } from "/src/internal_types";
import { findDatapoint, getMeta } from "/src/data_editor/builder";
import { filterMap, invariant, isNil, notNil } from "/src/utils";
import React from "react";
import Linkify from "react-linkify";

/**
 * Type defining a reason for declining or referring
 */
export type Reason = {
  segments: Array<ReasonSegment>;
  quoteNumber?: number;
};

type ReasonSegment =
  | {
      type: "text";
      value: string;
    }
  // syntax: §datapoint_id
  // for now, only non-parametarised datapoints
  | {
      type: "datapoint-ref";
      id: string;
      label: string;
      value?: string | number;
      quoteId?: QuoteId;
    };

/*
 * Keys from some goal (submission, quote, ...). Define quoteId if the keys
 * originate from quote goal.
 */
type GoalKeys = {
  keys: KeyInfo[];
  quoteId?: QuoteId;
};

/**
 * Function for parsing reasons from strings
 * @param reasonStr reason straight out of api result
 * @param goalKeys
 * @returns Reason that has datapoint references in it
 */
export function parseReason(reasonStr: string, goalKeys: GoalKeys[], quoteNumber?: number): Reason {
  /*
   * 'goalKeys' is used to look for datapoint 'foo' if the reason contains
   * '§foo'. Often we pass the keys from the submission and quote goals to
   * 'parseReason' but of course 'foo' could be in any goal or in no goal!
   */
  const segments: ReasonSegment[] = [];
  let currentInput = reasonStr;

  while (currentInput.length > 0) {
    let matchResult: RegExpMatchArray | null;
    if ((matchResult = currentInput.match(/^§([a-zA-Z_0-9.]+)/)) !== null) {
      const keyName = matchResult[1];

      const datapointAndQuote = filterMap(goalKeys, ({ keys, quoteId }) => {
        const datapoint = findDatapoint(
          {
            key: keyName,
            arguments: [],
          },
          keys,
        );
        if (isNil(datapoint)) {
          return undefined;
        }
        return {
          datapoint,
          quoteId,
        };
      }).find(notNil);

      if (isNil(datapointAndQuote)) {
        segments.push({
          type: "text",
          value: matchResult[0],
        });
        currentInput = currentInput.slice(matchResult[0].length);
      } else {
        const { datapoint, quoteId } = datapointAndQuote;
        const datapointLabel = getMeta(datapoint.prompt) ?? keyName;
        const datapointValue = getDatapointValue(datapoint);
        segments.push({
          type: "datapoint-ref",
          // todo: it's a lie
          id: keyName,
          label: datapointLabel,
          value: datapointValue,
          quoteId,
        });
        currentInput = currentInput.slice(matchResult[0].length);
      }
    } else if ((matchResult = currentInput.match(/^([^§]+)/)) !== null) {
      segments.push({
        type: "text",
        value: matchResult[0],
      });
      currentInput = currentInput.slice(matchResult[0].length);
    } else {
      throw new Error("could not parse reason");
    }
  }

  return {
    segments,
    quoteNumber,
  };
}

function getDatapointValue(dp: DatapointInfo) {
  if (dp.value === undefined) {
    return undefined;
  }
  invariant(dp.value.tag === "Scalar", "not a scalar");
  switch (dp.value.contents.tag) {
    case "Text":
      return dp.value.contents.contents;
    case "Bool":
      return dp.value.contents.contents ? "Yes" : "No";
    case "Number":
      return dp.value.contents.contents.toLocaleString();
    case "Int":
      return dp.value.contents.contents.toLocaleString();
    case "Percent":
      return dp.value.contents.contents.toLocaleString() + "%";
    default:
      return undefined;
  }
}

/**
 * Renders a reason
 *
 * See parseReason function to get Reason from string
 * @param reason Converted Reason
 * @returns Rendered Reason as list of elements
 */
export function renderReason(reason: Reason): JSX.Element {
  return (
    <>
      {renderQuote(reason.quoteNumber)}
      {reason.segments.map((segment) => renderReasonSegment(segment))}
    </>
  );
}

function renderQuote(quoteNumber?: number) {
  if (quoteNumber !== undefined) {
    return (
      <>
        <span className="border border-gray-200 text-gray-500 font-medium text-xs px-2 py-1 rounded-full">
          Quote #{quoteNumber}
        </span>{" "}
      </>
    );
  }
  return undefined;
}

function renderReasonSegment(segment: ReasonSegment) {
  switch (segment.type) {
    case "text":
      return (
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => {
            return (
              <a target="_blank" href={decoratedHref} key={key} className="underline text-blue-700">
                {decoratedText}
              </a>
            );
          }}
        >
          {segment.value}
        </Linkify>
      );
    case "datapoint-ref":
      return (
        <span
          className="hover:bg-gray-100 rounded leading-relaxed text-gray-900 cursor-pointer"
          onClick={() => {
            const selector =
              `[data-nodeid="${segment.id}()"]` +
              (segment.quoteId === undefined ? "" : `[data-quoteid="${segment.quoteId}"]`);
            const node = document.querySelector(selector);
            node?.scrollIntoView({
              behavior: "smooth",
            });
          }}
          data-testid={`reason-datapoint-ref-${segment.id}`}
        >
          <span className="font-medium text-gray-900 underline">{segment.label}</span>
          {segment.value !== undefined && (
            <span className="rounded text-gray-700 "> = {segment.value}</span>
          )}
        </span>
      );
  }
}
