import React, { Dispatch, SetStateAction, useEffect } from "react";
import { AddressNodeT_ } from "./nodes/address_node";
import { LeafNodeOnTheRightT, LeafNodeT_ } from "./nodes/base";
import { ConditionNodeOnTheRightT } from "./nodes/condition_node";
import { DateNodeT_ } from "./nodes/date_node";
import { DateSelectNodeT_ } from "./nodes/date_select_node";
import { DocumentNodeT_ } from "./nodes/document_node";
import { FileNodeT_ } from "./nodes/file_node";
import { FileSetNodeT_ } from "./nodes/file_set_node";
import { GroupNodeT_ } from "./nodes/group_node";
import { MoneyNodeT_ } from "./nodes/money_node";
import { NumberNodeT_ } from "./nodes/number_node";
import { NumberSelectNodeT_ } from "./nodes/number_select_node";
import { NumberSuggestNodeT_ } from "./nodes/number_suggest_node";
import { PercentNodeT_ } from "./nodes/percent_node";
import { PercentSelectNodeT_ } from "./nodes/percent_select_node";
import { PremiumNodeT_ } from "./nodes/premium_node";
import { SubformsNodeT_ } from "./nodes/subforms_node";
import { TableNodeT_ } from "./nodes/table_node";
import { TextNodeT_, CaselessTextNodeT_ } from "./nodes/text_node";
import { PromiseNodeT_ } from "./nodes/promise_node";
import { TextSelectNodeT_ } from "./nodes/text_select_node";
import { TextSetSelectNodeT_ } from "./nodes/text_set_select_node";
import { TextSuggestNodeT_ } from "./nodes/text_suggest_node";
import { YesNoNodeT_ } from "./nodes/yes_no_node";
import { OptionalNodeT_ } from "./nodes/optional_node";
import { UmrNodeT_ } from "./nodes/umr_node";

export type CellT = (
  | TextNodeT_
  | TextSelectNodeT_
  | TextSuggestNodeT_
  | CaselessTextNodeT_
  | UmrNodeT_
  | YesNoNodeT_
  | NumberNodeT_
  | NumberSelectNodeT_
  | NumberSuggestNodeT_
  | PercentNodeT_
  | PercentSelectNodeT_
  | DateNodeT_
  | DateSelectNodeT_
  | { type: "n/a"; id: string; readOnly: true; isCommitting: false }
) & { readOnly: boolean; isCommitting: boolean; columnName: string }; // All cell nodes share these properties.

export type NodeT =
  // Inner nodes
  | TableNodeT_
  | BlockedNodeT_
  | GroupNodeT_
  // Outer nodes
  | TextNodeT_
  | CaselessTextNodeT_
  | UmrNodeT_
  | PromiseNodeT_
  | TextSelectNodeT_
  | TextSuggestNodeT_
  | TextSetSelectNodeT_
  | NumberNodeT_
  | PremiumNodeT_
  | NumberSelectNodeT_
  | NumberSuggestNodeT_
  | YesNoNodeT_
  | DateNodeT_
  | DateSelectNodeT_
  | FileNodeT_
  | AddressNodeT_
  | PercentNodeT_
  | PercentSelectNodeT_
  | DocumentNodeT_
  | SubformsNodeT_
  | FileSetNodeT_
  | MoneyNodeT_
  | OptionalNodeT_;

export type NodeOnTheRightT = LeafNodeOnTheRightT | ConditionNodeOnTheRightT;

export interface NodeT_ {
  readonly type: string;
  readonly id: string;
  readonly summaryView: boolean;
}

export const generalEditorOrSummaryView = <T extends NodeT_>(
  node: T,
  editorView: (n: { node: T }) => JSX.Element,
  summaryView: (n: { node: T }) => JSX.Element,
): JSX.Element => {
  return node.summaryView ? summaryView({ node }) : editorView({ node });
};

export interface BlockedNodeT_ extends NodeT_ {
  blockers: Array<{
    key: string;
    label: string;
  }>;
  type: "blocked";
}

export interface SingleNodeT_<T> extends LeafNodeT_ {
  commit: (newValue: null | T) => void;
  value: null | T;
  initialRawValue?: string;
  isCommitting: boolean;
  formSubmitted: boolean;
}

// tw: TODO better genralistation of the tabular node once I have more info
// export interface TabularNodeT_<ID, T> extends LeafNodeT_ {
//   add: (id: ID, values: T) => Promise<void>;
//   remove: (id: ID) => Promise<void>;
//   value: Array<[ID,T]>;
//   isCommitting: boolean;
// }

export function useSingleNodeState<T extends number | string | boolean>(
  node: SingleNodeT_<T>,
): [string, Dispatch<SetStateAction<string>>] {
  const [localValue, setLocalValue] = React.useState(node.value?.toString() ?? "");
  useEffect(() => {
    if (!node.isCommitting) {
      setLocalValue(node.value?.toString() ?? "");
    }
  }, [node.value, node.isCommitting]);
  return [localValue, setLocalValue];
}

export interface ComparableNodeT_<T> {
  minimum: null | T;
  maximum: null | T;
}

export interface WithPrefixNodeT_ {
  prefix: string | undefined;
}

export interface SelectableNodeT_<T> {
  options: Array<T>;
}
