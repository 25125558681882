import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../routing/routes";
import { Page, PageTitle, PageContent, PageHeader } from "../layout";
import { Link } from "react-router-dom";
import { useParams } from "../routing/routing";
import { ProductEditor, Meta, OnFinish, File } from "../components/ProductEditor";
import { useGetEditableProduct } from "../dal/dal";
import { newProductPending } from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";

////////////////////////////////////////////////////////////////////////////////
// Main entry point

const CreateProduct: React.FC = () => {
  const history = useHistory();
  const {
    data: {
      capture: { productId },
    },
  } = useParams(Routes.product);
  const product = useGetEditableProduct(productId);
  throwIfAppError(product);
  const files = product.value.sourceTextFiles.map((file) => ({
    name: file.fileName,
    contents: file.fileContents,
  }));
  return (
    <Page>
      <PageHeader>
        <EditProductHeader productId={productId} />
      </PageHeader>
      <PageContent>
        <ProductEditor
          actionButtonTitle="Create"
          name={product.value.name}
          files={files}
          readOnly={false}
          onSubmit={editTheProduct}
          onFinish={(id) =>
            history.push(Routes.pendingProduct.generatePath({ capture: { pendingProductId: id } }))
          }
        />
      </PageContent>
    </Page>
  );
};

const EditProductHeader = (props: { productId: number }) => {
  return (
    <div className="flex flex-col w-full">
      <div>
        <Link
          to={Routes.product.generatePath({ capture: { productId: props.productId } })}
          className="group py-2 pr-6 flex flex-row"
        >
          ← Back to product
        </Link>
      </div>
      <div className="flex flex-row w-full justify-between">
        <div>
          <PageTitle>Create pending product</PageTitle>
        </div>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Functions

const editTheProduct = async (
  files: File[],
  meta: Meta,
  onFinish: OnFinish,
  onError: (error: string) => void,
) => {
  await newProductPending(meta.name, files).then((r) => {
    if (r.status === "success") {
      onFinish(r.value);
    } else {
      onError(`Couldn't edit product. The error was ${r.value.message}`);
    }
  });
};

export default CreateProduct;
