import React from "react";

import { QuestionMarkCircleIcon, TableIcon } from "@heroicons/react/solid";
import { SovExtraction } from "/src/internal_types";
import { Badge } from "/src/components/Badge";
import { Button } from "/src/design_system/Button";
import { DownloadIcon, ArrowCircleRightIcon } from "@heroicons/react/solid";
import { downloadFileById } from "/src/utils";
import { format } from "date-fns";

type SovImportLauncherProps = {
  onLaunch: () => void;
};

export const SovImport = ({
  extraction: { sovFile, completedAt, startedAt },
  onContinueImport,
}: {
  extraction: SovExtraction;
  onContinueImport: () => void;
}): JSX.Element => {
  const dateFormat = "dd/MM/y";
  return (
    <div className="p-6 grid grid-rows-[auto_auto_1fr] grid-cols-[auto_1fr_auto] gap-2 border border-gray-200 rounded-md">
      <p className="text-sm break-all font-semibold text-gray-900">{sovFile.sovFileName}</p>
      {completedAt === null && (
        <div className="flex flex-col justify-self-end ">
          <Badge testid="sov-import-status-badge" label="Draft" color="gray" />
        </div>
      )}
      <TableIcon className="col-start-3 w-5 text-blue-500" />

      <div className="row-start-2 col-start-1 col-span-3 text-gray-500">
        {completedAt === undefined || completedAt === null
          ? `Last modified: ${format(new Date(startedAt), dateFormat)}`
          : `Imported on: ${format(new Date(completedAt), dateFormat)}`}
      </div>

      <Button
        variant="secondary"
        className="row-start-3 justify-self-start px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => downloadFileById(sovFile.sovFileId)}
      >
        <DownloadIcon className="h-5 w-5" />
        <span className="ml-2">Download file</span>
      </Button>

      {completedAt === null ? (
        <Button
          variant="secondary"
          className="row-start-3 col-span-2 justify-self-end px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onContinueImport()}
        >
          <ArrowCircleRightIcon className="h-5 w-5" />
          <span className="ml-2">Continue import</span>
        </Button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export const SovImportLauncher = ({ onLaunch }: SovImportLauncherProps): JSX.Element => {
  return (
    <div className="flex p-6 border border-gray-200 rounded-md">
      <div className="flex flex-col gap-2">
        <p className="text-sm capitalize break-all font-semibold text-gray-900">
          Schedule of values extractor
        </p>
        <p className="text-sm max-w-prose text-gray-500 group-hover:text-gray-600 hidden lg:block">
          Use this tool if you would like to import or create a schedule of values for this
          submission.
        </p>
        <button
          type="button"
          className="self-start px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={onLaunch}
        >
          Select
        </button>
      </div>
      <div className="ml-auto flex flex-col grow-0 justify-between gap-2">
        <TableIcon className="w-5 text-blue-500" />
        <QuestionMarkCircleIcon className="w-4 text-gray-400 self-end" />
      </div>
    </div>
  );
};
