import * as apiTypes from "./api_types";
import { SPECIAL_DATAPOINTS } from "./data_editor/constants";
import { checkNotUndefined, checkValueConditions, unique } from "./utils";

// Brossa types

export type PolicyRef = [productId: number, policyId: number];

export type ProductId = number;
export type ProductVersion = number;

export type Product = apiTypes.components["schemas"]["Product"];

export type HowDefiniteAAsyncStatus = apiTypes.components["schemas"]["HowDefiniteAAsyncStatus"];
export type AsyncStatus = apiTypes.components["schemas"]["AsyncStatus"];
export type AsyncTaskStatus = apiTypes.components["schemas"]["AsyncTaskStatus"];

export type UnifiedTaskSummary = apiTypes.components["schemas"]["UnifiedTaskSummary"];
export type UnifiedTaskId = apiTypes.components["schemas"]["UnifiedTaskId"];

/*
export interface FrontendProductConfig {
  rightHandSideDatapoints: Array<Ident>;
  submitButtonCaption: string;
}*/

/*
- Base data
- Base data at time T
- Version data
- Version data at time T
*/

export type PolicyId = number;

export type QuoteId = number;

export type Day = string; // tw: not sure if that's the best idea

/*
export type PolicyDerivedStatus =
  | { tag: "Draft"; contents: unknown }
  | { tag: "Active"; contents: unknown }
  | { tag: "Bound"; contents: unknown };
*/

export const PolicyDerivedStatusFilters = ["PDraft", "PArchived", "PActive", "PBound"] as const;

export type PolicyDerivedStatusFilter = typeof PolicyDerivedStatusFilters[number];

export type PolicyDerivedStatus = apiTypes.components["schemas"]["PolicyDerivedStatus"];

export type Policy = apiTypes.components["schemas"]["Policy"];

export type PoliciesSortOn =
  apiTypes.paths["/api/product/policies"]["get"]["parameters"]["query"]["sortOn"];

export type PoliciesSortOrder =
  apiTypes.paths["/api/product/policies"]["get"]["parameters"]["query"]["sortOrder"];

export type Omitted = { tag: "Omitted" };

export type BarePolicy = apiTypes.components["schemas"]["Policy"];

export type GoalsInfo = apiTypes.components["schemas"]["GoalsInfo"];

export type GoalInfo = apiTypes.components["schemas"]["GoalInfo"];

export type Aggregations = apiTypes.components["schemas"]["AggregationResults"];

export type NewAggregation = apiTypes.components["schemas"]["NewAggregation"];

export type BrossaAggregation = apiTypes.components["schemas"]["AggregationResult"];

export type AggregationTrigger = apiTypes.components["schemas"]["AggregationTrigger"];

export type AggregationTriggerOutput = apiTypes.components["schemas"]["AggregationTriggerOutput"];

export type Widget = apiTypes.components["schemas"]["Widget"];

export type PrimitiveAggregation = apiTypes.components["schemas"]["PrimitiveAggregation"];

export type PrimitiveAggregationOutput = apiTypes.components["schemas"]["AggregationOutput"];

export type Projection = apiTypes.components["schemas"]["Projection"];

export type ProjectionOperation = apiTypes.components["schemas"]["Operation"];

// export const getPremiumFromQuote = (q: QuoteInfo, type: "total" | "net"): DatapointInfo | null => {
//   const isReal = q.indicative === "Real";
//   const premiumName =
//     type === "total"
//       ? isReal
//         ? SPECIAL_DATAPOINTS.PREMIUM
//         : SPECIAL_DATAPOINTS.INDICATIVE_PREMIUM
//       : SPECIAL_DATAPOINTS.NET_PREMIUM;
//   const fields = isReal ? q.fields.quoteGoal : q.fields.indicativeQuote;
//   const premium = checkNotUndefined(
//     fields.keys.find((ki) => {
//       return ki.name === premiumName;
//     }),
//   )
//     .map((ki) => ki.datapoints[0])
//     .andThen(checkNotUndefined);
//   return premium.getRight() ?? null;
// };

// export const createNetPremiumTransaction = (p: number): Transaction => {
// const fact = createAdditionFact(createScalarNumber(p));
//   const key = createKeyWithArgs(SPECIAL_DATAPOINTS.NET_PREMIUM, []);
//   return [createTransactionPart(key, fact)];
// };

export const getConditionsFromGoalInfo = (
  info: apiTypes.components["schemas"]["GoalInfo"],
  goalName: string,
): string[] => {
  const reasons = checkNotUndefined(info.keys.find((ki) => ki.name === goalName))
    .map((ki) => ki.datapoints[0])
    .andThen(checkNotUndefined)
    .map((dp) => dp.value)
    .andThen(checkNotUndefined)
    .andThen(checkValueConditions)
    .map((cd) =>
      cd.reasons.flatMap((reason) =>
        reason.tag !== "ConditionTrue"
          ? []
          : reason.contents.tag !== "CustomViolation"
          ? []
          : [reason.contents._0],
      ),
    );
  return reasons.getRight() ?? [];
};

export const getReferConditionsFromGoalInfo = (
  info: apiTypes.components["schemas"]["GoalInfo"],
): string[] => {
  return getConditionsFromGoalInfo(info, SPECIAL_DATAPOINTS.REFER);
};

export const getDeclineConditionsFromGoalInfo = (
  info: apiTypes.components["schemas"]["GoalInfo"],
): string[] => {
  return getConditionsFromGoalInfo(info, SPECIAL_DATAPOINTS.DECLINE);
};

export const getQuoteReferConditions = (q: QuoteInfo): string[] => {
  // const isReal = q.indicative === "Real";
  const referDpName = SPECIAL_DATAPOINTS.REFER;
  // const quoteFields = isReal ? q.fields.quoteGoal : q.fields.indicativeQuote;
  // const policyFields = isReal ? q.fields.submissionGoal : q.fields.indicativeSubmission;
  const quoteFields = q.fields.quoteGoal;
  const policyFields = q.fields.submissionGoal;
  const refer = checkNotUndefined(
    quoteFields.keys.find((ki) => ki.name === referDpName) ??
      policyFields.keys.find((ki) => ki.name === referDpName),
  )
    .map((ki) => ki.datapoints[0])
    .andThen(checkNotUndefined)
    .map((dp) => dp.value)
    .andThen(checkNotUndefined)
    .andThen(checkValueConditions)
    .map((cd) =>
      cd.reasons.flatMap((reason) =>
        reason.tag !== "ConditionTrue"
          ? []
          : reason.contents.tag !== "CustomViolation"
          ? []
          : [reason.contents._0],
      ),
    );
  return refer.getRight() ?? [];
};

export const getDeclineConditions = (q: QuoteInfo): string[] => {
  return getDeclineConditionsFromGoalInfo(q.fields.quoteGoal).concat(
    getDeclineConditionsFromGoalInfo(q.fields.submissionGoal),
  );
};

export const getReferConditions = (q: QuoteInfo): string[] => {
  // const isReal = q.indicative === "Real";
  const referDpName = SPECIAL_DATAPOINTS.REFER;
  // const quoteFields = isReal ? q.fields.quoteGoal : q.fields.indicativeQuote;
  // const policyFields = isReal ? q.fields.submissionGoal : q.fields.indicativeSubmission;
  const quoteFields = q.fields.quoteGoal;
  const policyFields = q.fields.submissionGoal;
  const refer = checkNotUndefined(
    quoteFields.keys.find((ki) => ki.name === referDpName) ??
      policyFields.keys.find((ki) => ki.name === referDpName),
  )
    .map((ki) => ki.datapoints[0])
    .andThen(checkNotUndefined)
    .map((dp) => dp.value)
    .andThen(checkNotUndefined)
    .andThen(checkValueConditions)
    .map((cd) =>
      cd.reasons.flatMap((reason) =>
        reason.tag !== "ConditionTrue"
          ? []
          : reason.contents.tag !== "CustomViolation"
          ? []
          : [reason.contents._0],
      ),
    );
  return refer.getRight() ?? [];
};

/**
 * A map of refer explanations indexed by node ids.
 */
export type ReferMap = { [key in Ident]: undefined | { trace: Trace; reason: string }[] };

const getDatapointsFromTrace = (t: Trace): Ident[] => {
  switch (t.tag) {
    case "EPlain": {
      return [];
    }
    case "ECustom": {
      if (t.contents.includes(" = ")) {
        const result = t.contents.split(" = ")[0];
        if (result.endsWith(")")) {
          return [result];
        } else {
          return [`${result}()`];
        }
      } else {
        return [];
      }
    }
    case "EPar": {
      return unique(t.contents.flatMap(getDatapointsFromTrace));
    }
    case "ESeq": {
      return unique(t.contents.flatMap(getDatapointsFromTrace));
    }
  }
};

export const getDatapointsTriggeringReferral = (fields: GoalsInfo): ReferMap => {
  const referDpName = SPECIAL_DATAPOINTS.REFER;
  const quoteFields = fields.quoteGoal;
  const policyFields = fields.submissionGoal;
  const referMap: ReferMap = {};
  checkNotUndefined(
    quoteFields.keys.find((ki) => ki.name === referDpName) ??
      policyFields.keys.find((ki) => ki.name === referDpName),
  ).andThen((ki) => {
    const dp_ = ki.datapoints[0];
    return checkNotUndefined(dp_).andThen((dp) => {
      const v = dp.value;
      return checkNotUndefined(v)
        .andThen(checkValueConditions)
        .map((conds) => {
          conds.reasons.map((reason) => {
            if (reason.tag === "ConditionTrue" && reason.contents.tag === "CustomViolation") {
              const trace = reason.contents._1;
              const stringReason = reason.contents._0;
              const dps = getDatapointsFromTrace(trace);
              dps.map((dp) => {
                if (referMap[dp] === undefined) {
                  referMap[dp] = [];
                }
                referMap[dp]?.push({ trace, reason: stringReason });
              });
            }
          });
        });
    });
  });
  return referMap;
};

export type TcAliases = { [key in Ident]: Typ | undefined };

export type BindingDates = apiTypes.components["schemas"]["BindingDates"];

export type Binding = apiTypes.components["schemas"]["Binding"];

export type AggBinding = apiTypes.components["schemas"]["AggBinding"];

export type PolicyInfo = apiTypes.components["schemas"]["Policy"];

export type InsuredName = apiTypes.components["schemas"]["FieldSpecified"];

/*
export type PolicyInfo = Policy<
  GoalsInfo,
  AggBinding,
  Quotes,
  TotalPremium | null,
  PolicyDerivedStatus
>;

export type BasePolicyInfo = Policy<Omitted, AggBinding, Quotes, Omitted, PolicyDerivedStatus>;

export type Policies = {
  policies: Array<BasePolicyInfo>;
  count: number;
>;*/

export type Policies = apiTypes.components["schemas"]["Policies"];

export type FunCall<fun, arg> = {
  key: fun;
  arguments: Array<arg>;
};

export type Key_<s> = FunCall<Ident, s>;

export type Fact = apiTypes.components["schemas"]["Fact"];

export type Ident = string;
export type KeyFact = apiTypes.components["schemas"]["KeyFactScalarRep"];
export type KV = apiTypes.components["schemas"]["KV"];
export type VarRef = apiTypes.components["schemas"]["VarRef"];
export type Unit = apiTypes.components["schemas"]["Unit"];

export type Transaction = Array<TransactionPart>;

export type TransactionPart = KeyFact;

export type Step<T> = { tag: "HasntHappened" } | { tag: "Happened"; contents: HappenedAt<T> };

export type HappenedAt<T> = { happenedAt: string; performedBy: string | null; happened: T };

export interface PostChatResponse {
  quoteId: number;
  messages: Array<{ author: string; time: string; contents: string }>;
}

export type User = {
  username: UserId;
  enabled: boolean;
  createdAt: string;
  sub: string;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  tenantId: string;
  subscribedTo: string;
};

export type Organisation = apiTypes.components["schemas"]["Org"];

export type GetUsersResponse = Array<User>;

export type AuthUser = apiTypes.components["schemas"]["User"];

export type Products = apiTypes.components["schemas"]["Products"];

type ReferralDecison = "ReferralAccepted" | "ReferralDeclined";

export type ReferralResult = {
  referralDecision: ReferralDecison;
  referralDates: BindingDates;
};

export type EndState = "Bound" | "NotTakenUp";

export type IndicativeOrReal = "Indicative" | "Real";

export interface Quote<with_, a, derivedStatus> {
  id: QuoteId;
  policyId: PolicyId;
  created: HappenedAt<[]>;
  referral: Step<Step<ReferralResult>>;
  status: Step<EndState>;
  dates: BindingDates;
  indicative: IndicativeOrReal;
  isWith: with_;
  fields: a;
  derivedStatus: derivedStatus;
}

export type QuoteDerivedStatus = apiTypes.components["schemas"]["QuoteDerivedStatus"];

/*
export type QuoteDerivedStatus =
  | { tag: "Draft"; contents: unknown }
  | { tag: "Referred"; contents: { isWith: IsWith } }
  | { tag: "Accepted"; contents: { isWith: IsWith } }
  | { tag: "Bound"; contents: unknown };
*/

export const QuoteDerivedStatusFilters = [
  "QDeclined",
  "QNotTakenUp",
  "QDraft",
  "QReferredWithUnderwriter",
  "QReferredWithBroker",
  "QAcceptedWithUnderwriter",
  "QAcceptedWithBroker",
  "QBound",
] as const;

export type QuoteDerivedStatusFilter = typeof QuoteDerivedStatusFilters[number];

export const ActionsRequired = [
  "awaiting_broker",
  "awaiting_underwriter",
  "needs_review",
  "ready_to_bind",
] as const;

export type ActionRequired = typeof ActionsRequired[number];

export type IsWith = apiTypes.components["schemas"]["IsWith"]; //{ role: Role; since: Date };

export type QuoteInfo = apiTypes.components["schemas"]["QuoteIsWithGoalsInfo"];
export type BaseQuoteInfo = apiTypes.components["schemas"]["QuoteIsWithOmitted"];

export type Role = apiTypes.components["schemas"]["Role"];
export const AllRoles: Role[] = ["underwriter", "broker", "admin", "read_only"];

export const roleToDisplayString = (role: Role): string => {
  switch (role) {
    case "underwriter":
      return "Underwriter";
    case "broker":
      return "Broker";
    case "admin":
      return "Admin";
    case "read_only":
      return "Read only";
  }
};

export type Quotes = {
  quotes: Array<BaseQuoteInfo>;
};

/*
export interface FileInfo {
  id: string;
  name: string;
  contentType: string;
  size: number;
}
*/

export type FileInfo = apiTypes.components["schemas"]["File"];

export type MrcDocumentId = number;
export type MrcDocumentSummary = apiTypes.components["schemas"]["MrcDocumentSummary"];
export type MrcExtractionId = number;
export type MrcExtractionTaskId = number;
export type MrcCandidateId = number;
export type MrcBoundingBox = apiTypes.components["schemas"]["BoundingBox"];
export type MrcDocumentInfo = apiTypes.components["schemas"]["MrcDocumentInfo"];
export type MrcCandidate = apiTypes.components["schemas"]["MrcCandidate"];
export type MrcResolvedValue = apiTypes.components["schemas"]["MrcResolvedValue"];
export type MrcResolvedExtraction = apiTypes.components["schemas"]["MrcResolvedExtraction"];
export type MrcResolvedStatus = apiTypes.components["schemas"]["MrcResolvedStatus"];
export type MrcExtractionData = [MrcResolvedExtraction, MrcCandidate[]];
export type MrcAdapter = apiTypes.components["schemas"]["MrcAdapter"];
export type MrcProductAdapter = apiTypes.components["schemas"]["MrcProductAdapter"];
export type SetupMrcAdapterOptions = apiTypes.components["schemas"]["SetupMrcAdaptersOptions"];
export type MrcAdapterId = number;
export type MrcImportError = apiTypes.components["schemas"]["MrcImportError"];
export type MrcTaskStatus = apiTypes.components["schemas"]["MrcTaskStatus"];
export type MrcUploadResult = apiTypes.components["schemas"]["MrcUploadResult"];
export type MrcCheckedState = apiTypes.components["schemas"]["MrcCheckedState"];

export type UTCTime = apiTypes.components["schemas"]["UTCTime"];

export type SovExtraction = apiTypes.components["schemas"]["SovExtraction"];
export type SovFile = SovExtraction["sovFile"];
export type BeginSovExtractionResponse =
  apiTypes.components["schemas"]["BeginSovExtractionResponse"];
export type SovExtractionId = number;
export type SovAvailableTabs = apiTypes.components["schemas"]["AvailableTabs"];
export type SovSelectedTabs = apiTypes.components["schemas"]["SelectedTabs"];

export type SetupSovAdapterOptions = apiTypes.components["schemas"]["SetupSovAdaptersOptions"];
export type SovColumnMatches = apiTypes.components["schemas"]["ColumnMatches"];
export type SovCandidateMatch = apiTypes.components["schemas"]["CandidateMatch"];
export type SovTargetColumnId = string;
export type SovExecutionStatus = apiTypes.components["schemas"]["SovExecutionStatus"];
export type SovColumnMapping = apiTypes.components["schemas"]["ColumnMapping"];
export type SovColumnMappings = apiTypes.components["schemas"]["ColumnMappings"];
export type SovExtractedTable = apiTypes.components["schemas"]["ExtractedTable"];
export type SovExtractedColumn = apiTypes.components["schemas"]["ExtractedColumn"];
export type SovAdapterId = number;
export type SovAdapter = apiTypes.components["schemas"]["SovAdapter"];
export type SovProductAdapter = apiTypes.components["schemas"]["SovAdapterProductMapping"];
export type SovSetAdapter = apiTypes.components["schemas"]["SetSovAdapter"];
export type SovSpecInfo = apiTypes.components["schemas"]["SovSpecInfo"];
export type SovScalarTyp = apiTypes.components["schemas"]["SovScalarTyp"];
export type SovSourceColumnIndex = number;
export type SovCandidateMatchId = apiTypes.components["schemas"]["CandidateMatchId"];

export type UUID = string;

export type DateDuration = apiTypes.components["schemas"]["CalendarDiffDays"]; // tw: wip

export type Scalar = apiTypes.components["schemas"]["Scalar"];
export type ScalarTyp = apiTypes.components["schemas"]["ScalarTyp"];

export type UnitedScalar = apiTypes.components["schemas"]["UnitedScalar"];

export type UMR = apiTypes.components["schemas"]["UMR"];

export type Typ = apiTypes.components["schemas"]["Typ"];

export type KeyInfo = apiTypes.components["schemas"]["KeyInfo"];

export type KeysInfo = Array<KeyInfo>;

export type KeysDiff = Record<string, KeyDiff>;

export type KeyDiff =
  | {
      tag: "ValueAdded";
      contents: Value;
    }
  | {
      tag: "ValueRemoved";
      contents: Value;
    }
  | {
      tag: "ValueChanged";
      contents: Array<Value>;
    }
  | {
      tag: "NoChange";
      contents: Array<Value>;
    };

export type AppErrorTag = apiTypes.components["schemas"]["AppError"]["tag"];

export type DatapointInfos = Array<DatapointInfo>;

export type Value = apiTypes.components["schemas"]["Value"];

export type Document_ = { name: string; parts: Array<DocumentPartDesc> };

export type DocumentPartDesc = apiTypes.components["schemas"]["DocumentPartDesc"];

export type ConditionResult = apiTypes.components["schemas"]["ConditionResult"];

export type OverallConditionResult = apiTypes.components["schemas"]["OverallConditionResult"];

export type Conditions = apiTypes.components["schemas"]["Conditions"];

export type Datapoint = Key_<Value>;

export type Obstacle = apiTypes.components["schemas"]["Obstacle"];

export type DatapointInfo = apiTypes.components["schemas"]["DatapointInfo"];

export type Arguments = Scalar[];

export type A<T, U> = T extends HowDefiniteA<U> ? U : never;

export type HowDefiniteA<T> =
  | {
      tag: "HasDefinition";
    }
  | { tag: "HasValue"; contents: T }
  | { tag: "NotDefined" };

export type Violation = apiTypes.components["schemas"]["Violation"];

export type UserId = string;

export type Trace = apiTypes.components["schemas"]["ExplCustExpl"];

export type GetExplainTraceResponse = {
  tag: "LangSuccess";
  contents: {
    result: {
      value: {
        tag: "Trace";
        contents: Trace;
      };
    };
  };
};

export type ProductConfig = apiTypes.components["schemas"]["ProductConfig"];

export type TaskSummary = apiTypes.components["schemas"]["UnifiedTaskSummary"];

export type TaskStatus = apiTypes.components["schemas"]["UnifiedTaskStatus"];

export type TaskId = apiTypes.components["schemas"]["UnifiedTaskId"];

export type TaskRun = apiTypes.components["schemas"]["UnifiedTaskRun"];

export type TaskRunId = apiTypes.components["schemas"]["UnifiedTaskRunId"];

export type TaskSuccess = apiTypes.components["schemas"]["UnifiedTaskResultsCase"];

export type TaskFilter = NonNullable<
  apiTypes.paths["/api/tasks/list"]["get"]["parameters"]["query"]["filter"]
>;

export type PromiseId = number;

export type TextFile = apiTypes.components["schemas"]["TextFile"];
