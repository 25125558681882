/**
 * @module Global toasts for notifications
 */
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { Box } from "../design_system/Box";
import { Loader } from "../design_system/Loader";
import { isNil, notNil } from "../utils";

////////////////////////////////////////////////////////////////////////////////
// Use toasts

/**
 * Hook for using toasts. Use it wherever you want to be able to
 * push new toasts. It returns a function for adding toasts
 *
 * id should be unique. If you push a toast with an id that already
 * exists, then the toast will be replaced. Useful for updating the
 * toast from 'loading' to 'succeeded' for example.
 */
export const useToasts = (): ((id: string, params: ToastParams) => void) => {
  const { upsertToast } = React.useContext(ToastContext);
  return upsertToast;
};

/**
 * Parameters for a toast
 */
export type ToastParams = Omit<ToastProps, "dismissOnClick" | "show">;

export const ToastIcons = {
  loading: () => {
    return <Loader size="sm" />;
  },
  success: () => {
    return <CheckCircleIcon className="text-green-400 w-5 h-5" />;
  },
  failure: () => {
    return <ExclamationCircleIcon className="w-5 h-5 text-red-500" />;
  },
};

////////////////////////////////////////////////////////////////////////////////
// Toasts provider

/**
 * It should be used in {@link PrivateApp}, so we make sure that it appears
 * on all of the pages that are private (so does not leak to non auth pages),
 * and that it persists when changing pages.
 */
export const ToastsProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const [toastsState, setToastsState] = React.useState<{
    toasts: {
      // unique id used for differentiating between toasts
      // ie we would like to update a given toast when
      // the task has been finished
      id: string;
      // we need createdAt to sort toasts by it
      createdAt: Date;
      // toast parameters needed for rendering
      params: ToastParams;
      // deletedAt is needed so we do not prematurely remove the toast from the list
      // ie we have to wait until the fade away animation finished
      deletedAt: Date | undefined;
      // unique id needed for key attribute of each toast in the list
      uniqueId: number;
    }[];
    latestUniqueId: number;
  }>({ toasts: [], latestUniqueId: 0 });

  // effect for clearing deleted toasts
  // we cannot delete them from the array straight away
  // because there is a 'leave' animation
  // so we delete them when 'deletedAt'
  // happened more than 1 second ago
  React.useEffect(() => {
    const interval = setInterval(() => {
      setToastsState((ts) => {
        const now = new Date();
        const toasts = ts.toasts;
        const changed = toasts.some(
          (toast) =>
            (notNil(toast.deletedAt) && now.getTime() - toast.deletedAt.getTime() >= 1000) ||
            (isNil(toast.deletedAt) && now.getTime() - toast.createdAt.getTime() > TOAST_TIMEOUT),
        );
        if (!changed) {
          return ts;
        }
        return {
          ...ts,
          toasts: toasts
            .filter((toast) => {
              return (
                toast.deletedAt === undefined || now.getTime() - toast.deletedAt.getTime() < 1000
              );
            })
            .map((toast) => {
              if (
                toast.deletedAt === undefined &&
                now.getTime() - toast.createdAt.getTime() > TOAST_TIMEOUT
              ) {
                return { ...toast, deletedAt: now };
              }
              return toast;
            }),
        };
      });
    }, 1000);
    return () => {
      // interval cleanup
      clearInterval(interval);
    };
  }, []);

  // function for adding a new toast or updating an existing one
  const upsertToast = (id: string, params: ToastParams) => {
    setToastsState((ts) => {
      let replaced = false;
      let latestUniqueId = ts.latestUniqueId;
      const newToasts = ts.toasts.map((x) => {
        if (x.id === id && x.deletedAt === undefined) {
          replaced = true;
          return { ...x, params };
        }
        return x;
      });
      if (!replaced) {
        newToasts.push({
          id,
          params,
          createdAt: new Date(),
          deletedAt: undefined,
          uniqueId: latestUniqueId,
        });
        // update latest unique id if new toast has been added
        latestUniqueId++;
      }
      return { toasts: newToasts, latestUniqueId: latestUniqueId };
    });
  };
  // function for dismissing the toast
  const onDismiss = (id: string) => {
    setToastsState((ts) => {
      return {
        ...ts,
        toasts: ts.toasts.map((x) => {
          if (x.id === id) {
            return { ...x, deletedAt: new Date() };
          }
          return x;
        }),
      };
    });
  };
  return (
    <ToastContext.Provider value={{ upsertToast }}>
      {props.children}
      <div
        className={clsx(
          "absolute", // absolute based on the screen
          "bottom-4 right-4", // the bottom right of the screen
          "space-y-4", // space between toasts
          "max-h-screen overflow-y-auto", // scroll bar appears if needed
          "pb-4", // needed so the bottom toast has a visible shadow
          "px-4", // needed so the sides of toasts have a visible shadow
        )}
      >
        {toastsState.toasts
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
          .map((toast) => {
            return (
              <ToastView
                key={toast.uniqueId}
                {...toast.params}
                dismissOnClick={() => onDismiss(toast.id)}
                show={toast.deletedAt === undefined}
              />
            );
          })}
      </div>
    </ToastContext.Provider>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Internal

/**
 * Toast context
 */
const ToastContext = React.createContext<{
  upsertToast: (id: string, params: ToastParams) => void;
}>({
  get upsertToast(): () => void {
    // throwing an error straight away so it fails quickly
    // when it's used not in the toast context
    throw new Error("not in a toast context");
  },
});

/**
 * Toast view props
 */
type ToastProps = {
  dismissCaption: string;
  dismissOnClick: () => void;
  action?: {
    caption: string;
    onClick: () => void;
  };
  title: string;
  description: string;
  icon: () => JSX.Element;
  show: boolean;
};

/**
 * Toast view molecule
 */
const ToastView = (props: ToastProps): JSX.Element => {
  return (
    <Transition
      appear={true}
      show={props.show}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Box className="w-96 shadow-lg flex flex-row space-y-0 space-x-2 z-30" border="hidden">
        {/* Icon */}
        <div className="pt-0.5">{props.icon()}</div>
        {/* Main part */}
        <div className="flex-1 p-0 mt-0 space-y-2">
          {/* title */}
          <p className="text-sm leading-5 font-medium"> {props.title}</p>
          {/* description */}
          <p className="text-sm leading-5 text-gray-500"> {props.description} </p>
          {/* Actions */}
          <div className="flex space-x-4">
            {/* Action click */}
            {props.action !== undefined && (
              <button onClick={props.action.onClick}>
                <span className="text-sm leading-5 font-medium text-blue-600">
                  {props.action.caption}
                </span>
              </button>
            )}
            {/* Dismiss */}
            <button onClick={props.dismissOnClick}>
              <span className="text-sm leading-5 font-medium text-gray-700">
                {props.dismissCaption}
              </span>
            </button>
          </div>
        </div>
        {/* Close button */}
        <button data-testid="toast-close" className="self-start" onClick={props.dismissOnClick}>
          <XIcon className="w-5 h-5 text-gray-400" />
        </button>
      </Box>
    </Transition>
  );
};

const TOAST_TIMEOUT = 10 * 1000; //10s
