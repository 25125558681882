import { SearchIcon } from "@heroicons/react/outline";
import React from "react";

/**
 * Search input atom
 */
export const SearchInput = ({
  value,
  onChange,
  placeholder,
}: {
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}) => (
  <div className="relative rounded-md shadow-sm w-64">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <SearchIcon className="h-5 w-5 text-gray-900" />
    </div>
    <input
      autoComplete="off"
      type="text"
      name="email"
      id="email"
      className="pl-10 px-3 py-2 border border-gray-300 shadow-sm rounded-md w-full focus:outline-none focus:ring-blue-500 focus:border-blue-600 sm:text-sm"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
    />
  </div>
);
