import clsx from "clsx";
import React from "react";

const variants = {
  error: "bg-red-100 border-red-400",
  info: "bg-blue-100 border-blue-400",
  success: "bg-green-100 border-green-400",
  warning: "bg-orange-100 border-orange-400",
};

type Props = {
  children?: React.ReactNode;
  className?: string;
  label: string;
  variant: "info" | "success" | "warning" | "error";
};

const Banner = ({ children, className, label, variant }: Props) => (
  <div className={clsx("py-4 px-4 rounded border-solid border-t-8", variants[variant], className)}>
    {label.length > 0 && <p className="font-medium">{label}</p>}

    {children !== undefined && <div className="mt-2">{children}</div>}
  </div>
);

export default Banner;
