import React from "react";
import { NodeT_ } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafNode, SummaryLeafStringNode } from "./base";
import { pdfPreviewByDatapoint } from "/src/dal/dal";
import { FileCard } from "/src/file_card";
import { PolicyInfo, ProductId, QuoteInfo } from "/src/internal_types";
import { PolicyContext, QuoteNullableContext } from "/src/pages/policy/context";
import {
  CanGeneratePolicyFile,
  CanGenerateQuoteFile,
  determinePartialQuoteState,
} from "/src/pages/policy/permissions";
import { downloadFile } from "/src/utils";

export type DocumentNodeValue =
  | { type: "missing_values"; values: Array<string> }
  | { type: "ready"; template: string; documentName: string };

export interface DocumentNodeT_ extends NodeT_ {
  value: DocumentNodeValue;
  type: "document";
  label: string;
  readOnly: boolean;
  hidden: boolean;
  productId: ProductId;
}

const showComponent = (
  policy: PolicyInfo,
  quote: QuoteInfo | undefined,
  filename: string,
): boolean => {
  if (quote !== undefined) {
    const quoteState = determinePartialQuoteState(policy, quote);
    return CanGenerateQuoteFile(filename).check(quoteState);
  } else {
    const policyState = policy.derivedStatus;
    return CanGeneratePolicyFile.check(policyState);
  }
};

export const DocumentNode = (props: { node: DocumentNodeT_ }): JSX.Element | null => {
  const { node } = props;
  const { quote } = React.useContext(QuoteNullableContext);
  const { policy } = React.useContext(PolicyContext);
  const show = !node.hidden && showComponent(policy, quote, node.id);
  if (!show) {
    return null;
  }
  switch (node.value.type) {
    case "missing_values": {
      if (node.summaryView) {
        return <SummaryLeafStringNode label={props.node.label} contents={["-"]} />;
      } else {
        const leafNode: LeafNodeT_ = {
          ...node,
          needsCollecting: false,
          problems: node.value.values,
          isCommitting: false,
          description: undefined,
        };
        return <LeafNode node={leafNode} />;
      }
    }
    case "ready": {
      const name = node.value.documentName;
      const onClick = async () => {
        const blob = await pdfPreviewByDatapoint(node.productId, policy.id, quote?.id, node.id);
        downloadFile(blob, name);
      };
      if (node.summaryView) {
        return (
          <SummaryLeafNode label={props.node.label}>
            <FileCard
              name={node.value.documentName}
              onClick={onClick}
              size={node.value.template.length}
              contentType={"application/pdf"}
            />
          </SummaryLeafNode>
        );
      } else {
        const leafNode: LeafNodeT_ = {
          ...node,
          needsCollecting: false,
          problems: [],
          isCommitting: false,
          description: undefined,
        };
        return (
          <LeafNode node={leafNode}>
            <FileCard
              name={node.value.documentName}
              onClick={onClick}
              size={node.value.template.length}
              contentType={"application/pdf"}
            />
          </LeafNode>
        );
      }
    }
  }
};
