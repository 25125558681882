import React from "react";
import clsx, { ClassValue } from "clsx";
import { Box } from "reakit/Box";

export type LoaderSize = "sm" | "lg";

interface LoaderProps {
  size?: LoaderSize;
  color?: "white" | "gray";
}

/* Note:
SVG animations (animateTransform) are apparently not supported in Internet Explorer.
*/

export const Loader = React.forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
  const { size = "lg", color = "gray" } = props;

  /* Below:
  The logic refering to "gray" means that the foreground is grey and
  the background is light.  Conversely, any other color yields an
  almost-white circle, appropriate for a dark background.

  The actual color for the "gray" style is blue from the Artificial palette.
  See <https://play.tailwindcss.com/ETdDqcs9t7> for an example.
  */
  return (
    <Box ref={ref} {...props} className={clsx(["select-none", getClsBySize(size)])}>
      <svg viewBox="0 0 20 20">
        <g
          fill="none"
          stroke={color === "gray" ? "rgb(37, 99, 235)" : "#f2f2f2"}
          strokeLinecap="round"
          strokeWidth="3"
        >
          <g>
            <circle cx="10" cy="10" r="8" opacity=".3" />
            <path d="M10 2c6.075 0 8 4.925 8 8">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                dur="0.7s"
                from="0 10 10"
                repeatCount="indefinite"
                to="360 10 10"
                type="rotate"
              />
            </path>
          </g>
        </g>
      </svg>
    </Box>
  );
});

function getClsBySize(size: LoaderSize): ClassValue[] {
  switch (size) {
    case "sm":
      return ["w-5"];
    case "lg":
      return ["w-10"];
  }
}
