import { createTheme } from "@mui/material/styles";
import { blue, grey } from "/src/theme/color";

export default createTheme({
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: 28,
      fontWeight: 600,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
    },
    htmlFontSize: 16,

    body1: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      ...blue,
      main: blue["600"],
      dark: blue["1000"],
      light: blue["300"],
      contrastText: "#ffffff",
    },
    background: {
      paper: "#fff",
    },
    grey: grey,
  },
  breakpoints: {},
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "default",
        elevation: 3,
        position: "sticky",
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary: {
          "&$focusVisible": {
            outline: "none",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        rows: 3,
        maxRows: 20,
        size: "small",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          // Read-only fields should be displayed with black letters in a grayed-out backgroud (see #2872).
          // We have both .Mui-disabled and :disabled to increase specificity, otherwise we were overriden
          // by another rule which set -webkit-text-fill-color to more greyed-out letters.
          "& .Mui-disabled:disabled": {
            color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "rgb(249, 250, 251)",
            // https://github.com/mui/material-ui/issues/23908
            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        labelSmall: {
          paddingTop: 2,
          paddingBottom: 2,
          fontSize: 12,
          lineHeight: 1.333333333333,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        //    root: {
        //      paddingTop: 10,
        //      paddingBottom: 10,
        //    },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
        fixed: true,
      },
    },

    MuiListItem: {
      defaultProps: {
        disableGutters: true,
        dense: true,
      },
    },
  },
});
