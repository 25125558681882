import React from "react";

/*
 * A *Shape* is a type to which others will try to
 * extend. For example, StateName should always extend string.
 */
type StateNameShape = string;

type StateContextShape = any;

type StatesSchemaShape = Record<StateNameShape, StateContextShape>;

/*
 * Converts any StatesSchema to union of possible States
 *
 * @example
 * ```typescript
 * type States = {
 *   a: {counter: number},
 *   b: {thing: string}
 * }
 *
 * type S1 = State<States>
 * type S2 =
 *   {type: "a", context: {counter: number}}
 *   | {type: "b", context: {thing: string}}
 *
 * // S1 === S2
 * ```
 */
type State<StatesSchema extends StatesSchemaShape> = {
  [StateName in keyof StatesSchema]: { type: StateName; context: StatesSchema[StateName] };
}[keyof StatesSchema];

/*
 * Generates a subset of States based on the StateName
 */
export type PickState<
  StatesSchema extends StatesSchemaShape,
  StateName extends keyof StatesSchema,
> = {
  [K in keyof StatesSchema]: { type: K; context: StatesSchema[K] };
}[StateName] extends State<StatesSchema>
  ? { [K in keyof StatesSchema]: { type: K; context: StatesSchema[K] } }[StateName]
  : never;

type StateWithTransitions<
  StatesSchema extends StatesSchemaShape,
  TransitionsSchema extends TransitionsSchemaShape<StatesSchema>,
> = {
  [StateName in keyof StatesSchema]: {
    type: StateName;
    context: StatesSchema[StateName];
    transitions: TransitionsSchema[StateName] extends undefined
      ? undefined
      : {
          [TransitionName in keyof TransitionsSchema[StateName]]: TransitionsSchema[StateName][TransitionName] extends (
            ...args: infer Args
          ) => infer Result
            ? Result extends Promise<any>
              ? (...args: Args) => Promise<void>
              : (...args: Args) => void
            : never;
        };
  };
}[keyof StatesSchema];

type TransitionsSchemaShape<States extends StatesSchemaShape> = {
  [StateName in keyof States]?: {
    [TransitionName in string]: (data: any) => State<States> | Promise<State<States>>;
  };
};

type StateTransitionsImplementation<
  StatesSchema extends StatesSchemaShape,
  TransitionsSchema extends TransitionsSchemaShape<StatesSchema>,
  StateName extends keyof TransitionsSchema,
> = StateName extends keyof StatesSchema
  ? {
      [TransitionName in keyof TransitionsSchema[StateName]]: TransitionsSchema[StateName][TransitionName] extends (
        ...args: infer Args
      ) => infer Result
        ? (...args: [StatesSchema[StateName], ...Args]) => Result
        : never;
    }
  : never;

type TransitionsImplementation<
  StatesSchema extends StatesSchemaShape,
  TransitionsSchema extends TransitionsSchemaShape<StatesSchema>,
> = {
  [StateName in keyof TransitionsSchema]: StateTransitionsImplementation<
    StatesSchema,
    TransitionsSchema,
    StateName
  >;
};

/*
 * This function is used merely for typechecking
 */
function transitions<
  StatesSchema extends StatesSchemaShape,
  TransitionsSchema extends TransitionsSchemaShape<StatesSchema>,
>(
  implementation: TransitionsImplementation<StatesSchema, TransitionsSchema>,
): TransitionsImplementation<StatesSchema, TransitionsSchema> {
  return implementation;
}

// type HookKeys = "onEnter" // todo, add 'onExit' and 'always'

// type HooksSchemaShape<StatesSchema extends StatesSchemaShape> = {
//   [StateName in keyof StatesSchema]?: {
//     [HookName in HookKeys]?: (state: StatesSchema[StateName]) => State<StatesSchema> | Promise<State<StatesSchema>>
//   }
// }

//todo hooks
//right now i dont feel comfortable enough to know how to do it properly
///*
// * Beware! Using hooks might lead to some unpredicted behaviour!
// * When using onExit and onEnter, both will be called!
// * Use with caution
// */
//async function execHooks<
//  StatesSchema extends StatesSchemaShape,
//  HooksSchema extends HooksSchemaShape<StatesSchema>
//>(
//  oldState: State<StatesSchema> | undefined,
//  newState: State<StatesSchema>,
//  setState: (newState: State<StatesSchema>) => void,
//  hooks: HooksSchema
//): Promise<State<StatesSchema>> {
//  if (oldState!==undefined && oldState.type === newState.type) {
//    return newState;
//  }
//  //onEnter
//  const newStateHooks = hooks[newState.type];
//  if (newStateHooks !== undefined && newStateHooks["onEnter"] !== undefined) {
//    const newerState = await Promise.resolve(newStateHooks["onEnter"](newState.context));
//    setState(newerState);
//    return execHooks(newState, newerState, setState, hooks);
//  }
//  return newState;
//}

function useMachine<
  StatesSchema extends StatesSchemaShape,
  TransitionsSchema extends TransitionsSchemaShape<StatesSchema>,
  // HooksSchema extends HooksSchemaShape<StatesSchema>
>(
  initial: State<StatesSchema>,
  transitions: TransitionsImplementation<StatesSchema, TransitionsSchema>,
  // hooks: HooksSchema
): [StateWithTransitions<StatesSchema, TransitionsSchema>, () => void] {
  const [state, setState] = React.useState<State<StatesSchema>>(initial);
  const unsafeTransitions = transitions as Record<keyof StatesSchema, any>;
  const newTransitions = {} as Record<string, any>;

  const transitionsForState = unsafeTransitions[state.type];
  if (typeof transitionsForState === "object" && transitionsForState !== null) {
    const unsafeTransitionsForState = transitionsForState as Record<string, any>;
    Object.keys(transitionsForState).forEach((action) => {
      newTransitions[action] = async (data: any) => {
        const newState = await Promise.resolve(
          unsafeTransitionsForState[action](state.context, data),
        );
        setState(newState);
        // execHooks(state, newState, setState, hooks);
      };
    });
  }
  return [
    { ...state, transitions: newTransitions } as StateWithTransitions<
      StatesSchema,
      TransitionsSchema
    >,
    () => {
      setState(initial);
      // execHooks(state, initial, setState, hooks)
    },
  ];
}

export default {
  useMachine,
  transitions,
};
