import { Loader } from "/src/design_system/Loader";
import { TrashIcon, ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Button } from "/src/design_system/Button";
import { CodeMirror } from "../components/CodeMirror";
import Input from "../input";

////////////////////////////////////////////////////////////////////////////////
// Types

export type File = { name: string; contents: string };

export type Meta = { name: string };

export type OnFinish = (pendingProductId: number) => void;

export type OnError = (error: string) => void;

// Given some content, produce a PendingProductId.
type OnSubmit = (files: File[], meta: Meta, onFinish: OnFinish, onError: OnError) => void;

type ProductEditorProps = {
  name: string;
  files: File[];
  onFinish: OnFinish;
  onSubmit: OnSubmit;
  readOnly: boolean;
  actionButtonTitle: string;
};

////////////////////////////////////////////////////////////////////////////////
// Constants

const defaultNewFileName = "newfile.art";

const defaultNewFileContents = `// Some file!`;

////////////////////////////////////////////////////////////////////////////////
// Main component

export const ProductEditor = (props: ProductEditorProps) => {
  const [files, setFiles] = useState<File[]>(
    props.files.slice().sort((p1, p2) => (p1.name < p2.name ? -1 : 1)),
  );
  const [meta, setMeta] = useState<Meta>({ name: props.name });
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [rpcErrors, setRpcErrors] = useState<string | undefined>(undefined);
  const errors = validateProduct(files, meta);
  return (
    <>
      <div className="px-8">
        <ProductName name={meta.name} onChange={(name) => setMeta({ name })} />
        <ProductFiles
          readOnly={props.readOnly}
          files={files}
          onRemove={(index) => setFiles(files.filter((_, index_) => index_ !== index))}
          onChange={(index, fileNew) =>
            setFiles(
              files.map((fileOriginal, index_) => (index_ === index ? fileNew : fileOriginal)),
            )
          }
        />
        {props.readOnly == false && (
          <>
            <ValidationMessage errors={errors ?? rpcErrors} />
            <div className="flex flex-row justify-between">
              <AddFile onAdd={(file) => setFiles(files.concat([file]))} />
              <CreateButton
                actionButtonTitle={props.actionButtonTitle}
                inProgress={inProgress}
                errors={errors !== undefined}
                onClick={() => {
                  setInProgress(true);
                  props.onSubmit(files, meta, props.onFinish, (error) => {
                    setRpcErrors(error);
                    setInProgress(false);
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Functions

const validateProduct = (files: File[], meta: Meta) => {
  if (meta.name === "") return "Missing product name!";
  if (Array.from(new Set(files.map((file) => file.name))).length !== files.length)
    return "Duplicate file names!";
};

////////////////////////////////////////////////////////////////////////////////
// Components

const ProductName = (props: { name: string; onChange: (name: string) => void }): JSX.Element => {
  return (
    <Input
      onChange={(event) => props.onChange(event.target.value)}
      value={props.name}
      placeholder=""
      className=""
      label="Name"
      name="product_name"
      type="text"
    />
  );
};

function fileIdentifier(fileName: string): string {
  return encodeURIComponent(fileName).replace("-", "__");
}

function getHighlightRange(
  fileName: string,
  readOnly: boolean,
): {
  collapsed: boolean;
  highlightRange: { from: number; to: number } | undefined;
} {
  if (!readOnly) {
    return {
      collapsed: fileName !== "main.art",
      highlightRange: undefined,
    };
  }

  const result = {
    collapsed: true,
    highlightRange: undefined,
  };

  const components = window.location.hash.substring(1).split("-");
  if (components.length !== 3) {
    return result;
  }

  if (fileIdentifier(fileName) !== components[0]) {
    return result;
  }

  const from = parseInt(components[1], 10);
  const to = parseInt(components[2], 10);
  if (isNaN(from) || isNaN(to)) {
    return result;
  }

  return {
    collapsed: false,
    highlightRange: { from, to },
  };
}

const ProductFiles = (props: {
  files: File[];
  onRemove: (index: number) => void;
  onChange: (index: number, file: File) => void;
  readOnly: boolean;
}): JSX.Element => {
  return (
    <>
      <h2 className="text-xl font-bold mt-5 mb-3">Files</h2>
      <div>
        {props.files.map((file, index) => {
          const { collapsed, highlightRange } = getHighlightRange(file.name, props.readOnly);
          return (
            <div key={index} className="mb-5">
              <EditFile
                file={file}
                onChange={(file) => props.onChange(index, file)}
                onRemove={() => props.onRemove(index)}
                readOnly={props.readOnly}
                collapsed={collapsed}
                highlightRange={highlightRange}
                data-testid={`collapse-product-file-${index}`}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const EditFile = (props: {
  file: File;
  onChange: (file: File) => void;
  onRemove: () => void;
  readOnly: boolean;
  collapsed: boolean;
  "data-testid"?: string;
  highlightRange?: { from: number; to: number };
}): JSX.Element => {
  const [collapsed, setCollapsed] = useState(props.collapsed);
  return (
    <>
      <div
        className={
          "flex flex-row justify-between bg-gray-100 border border-gray-300 shadow-sm py-2" +
          " " +
          (collapsed ? "rounded" : "rounded-tl rounded-tr") +
          " " +
          (props.readOnly ? "pr-2" : "")
        }
      >
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="px-3 py-2 group grow flex justify-center items-center hover:bg-gray-100 truncate rounded w-12"
          data-testid={props["data-testid"]}
        >
          {collapsed ? (
            <ChevronRightIcon className="flex-none flex h-5 w-5 p-0.5 text-gray-400 rounded" />
          ) : (
            <ChevronDownIcon className="flex-none flex h-5 w-5 text-gray-400 rounded" />
          )}
        </button>
        <input
          value={props.file.name}
          onChange={(event) => props.onChange({ ...props.file, name: event.target.value })}
          className="px-3 py-2 border border-gray-300 shadow-sm rounded w-full focus:outline-none focus:ring-blue-500 focus:border-blue-600 font-bold"
        />
        {props.readOnly == false && (
          <button
            onClick={props.onRemove}
            className="px-3 py-2 group grow flex justify-center items-center hover:bg-gray-100 truncate rounded w-12"
          >
            <TrashIcon className="flex-none flex h-5 w-5 p-0.5 text-gray-400 rounded" />
          </button>
        )}
      </div>
      {!collapsed && (
        <div className="border-x border-b border-gray-300 shadow-sm rounded-bl rounded-br w-full overflow-hidden">
          <CodeMirror
            initialText={props.file.contents}
            fileIdentifier={fileIdentifier(props.file.name)}
            onTextChange={(getText) => props.onChange({ ...props.file, contents: getText() })}
            readOnly={props.readOnly}
            highlightRange={props.highlightRange}
          />
        </div>
      )}
    </>
  );
};

const AddFile = (props: { onAdd: (file: File) => void }): JSX.Element => {
  return (
    <Button
      onClick={() => props.onAdd({ name: defaultNewFileName, contents: defaultNewFileContents })}
    >
      Add file
    </Button>
  );
};

const CreateButton = (props: {
  actionButtonTitle: string;
  onClick: () => void;
  inProgress: boolean;
  errors: boolean;
}): JSX.Element => {
  if (props.inProgress) {
    return (
      <div className="flex flex-row">
        <div className="mr-2">Creating...</div>
        <Loader size="sm" />
      </div>
    );
  } else {
    return (
      <Button
        onClick={() => {
          if (!props.errors) props.onClick();
        }}
        className={props.errors ? "opacity-20" : ""}
        data-testid="product-create-button"
      >
        {props.actionButtonTitle}
      </Button>
    );
  }
};

const ValidationMessage = (props: { errors?: string }): JSX.Element => {
  return (
    <>
      {props.errors !== undefined && (
        <p className="text-red-800 font-bold mt-5 mb-5" data-testid="product-validation-error">
          {props.errors}
        </p>
      )}
    </>
  );
};
