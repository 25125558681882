import { DownloadIcon, PaperClipIcon } from "@heroicons/react/solid";
import React from "react";
import { fileDownloadPath, useGetFileInfo } from "./dal/dal";
import { humanFileSize } from "/src/utils";

/**
 * @param size - size of the document (in bytes)
 */
export function FileCard(props: {
  name: string;
  path?: string;
  onClick?: () => void;
  size: number;
  contentType: string;
}) {
  return (
    <a
      className="cursor-pointer border flex-none w-min min-w-[32rem] max-w-3xl rounded px-3 py-2 group flex  hover:bg-gray-50 truncate "
      href={props.path}
      onClick={props.onClick}
      target="_blank"
    >
      <PaperClipIcon className="flex-none lg:flex hidden h-5 w-5 p-0.5 mr-2 text-gray-400" />

      <div className="flex grow shrink flex-col max-w-full space-y-1">
        <span className="grow truncate text-left font-medium">New Business Quotation</span>
        <span className="grow text-left font-mono text-xs">{humanFileSize(props.size, true)}</span>
      </div>
      <DownloadIcon className="md:flex hidden flex-none h-6 w-6 px-1 text-gray-400 group-hover:text-blue-600" />
    </a>
  );
}

export function FileCardById(props: { fileId: string }) {
  const data = useGetFileInfo(props.fileId);
  if (data.status === "error") {
    throw data;
  }
  return (
    <FileCard
      name={data.value.name}
      path={fileDownloadPath(data.value.id)}
      size={data.value.size}
      contentType={data.value.contentType}
    />
  );
}
