import { EditorView, Decoration } from "@codemirror/view";
import { Extension, StateEffect, StateField } from "@codemirror/state";
import { lineNumbers } from "@codemirror/gutter";

const highlightDecoration = Decoration.line({
  class: "cm-highlight-lines",
});

const highlightTheme = EditorView.baseTheme({
  ".cm-highlight-lines": {
    backgroundColor: "#fef7e8",
  },
  ".cm-gutters": {
    cursor: "default",
  },
});

export const highlightLine = StateEffect.define<number | null>();

export const extendHighlight = StateEffect.define<number>();

export const highlightRangeField = StateField.define<{ from: number; to: number } | null>({
  create() {
    return null;
  },
  update(range, transaction) {
    for (const effect of transaction.effects) {
      if (effect.is(highlightLine)) {
        if (effect.value === null) {
          range = null;
        } else {
          range = { from: effect.value, to: effect.value };
        }
      }
      if (effect.is(extendHighlight) && range !== null) {
        let { from, to } = range;
        if (effect.value < from) {
          to = from;
          from = effect.value;
        } else {
          to = effect.value;
        }
        range = { from, to };
      }
    }
    return range;
  },
  provide: (field) =>
    EditorView.decorations.compute([field], (state) => {
      const range = state.field(field);
      if (range == null) {
        return Decoration.none;
      }
      const decorations = [];
      for (let i = range.from; i <= range.to; ++i) {
        decorations.push(highlightDecoration.range(state.doc.line(i).from));
      }
      return Decoration.set(decorations);
    }),
});

const clickableGutter: Extension = lineNumbers({
  domEventHandlers: {
    mousedown(view, pos, event) {
      const shiftDown = (event as MouseEvent).shiftKey;
      const line = view.state.doc.lineAt(pos.from).number;
      const effect = (shiftDown ? extendHighlight : highlightLine).of(line);
      view.dispatch({
        effects: effect,
      });
      return true;
    },
  },
});

export const highlightLines: Extension = [highlightTheme, highlightRangeField, clickableGutter];
