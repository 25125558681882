export const grey = {
  50: "#F9FAFB",
  100: "#F8F9FA",
  200: "#F1F3F5",
  300: "#D2D6DC",
  400: "#DEE2E6",
  500: "#6B7280",
  600: "#ADB5BD",
  700: "#868E96",
  800: "#495057",
  900: "#343A40",
  A100: "#212529",
};

export const green = {
  100: "#EBFBEE",
  200: "#DCF4E3",
  300: "#B2F2BB",
  400: "#8CE99A",
  500: "#69DB7C",
  600: "#51CF66",
  700: "#40C057",
  800: "#37B24D",
  900: "#2F9E44",
  1000: "#2B8A3B",
};

export const orange = {
  100: "#FFF4E6",
  200: "#FFE8CC",
  300: "#FFD8A8",
  400: "#FFC078",
  500: "#FFA94D",
  600: "#FF922B",
  700: "#FD7E14",
  800: "#F76707",
  900: "#E8590C",
  1000: "#D9480F",
};

export const blue = {
  100: "#E7F5FF",
  200: "#D0EBFF",
  300: "#A5D8FF",
  400: "#74C0FC",
  500: "#4DABF7",
  600: "#339AF0",
  700: "#228BE6",
  800: "#1C7ED6",
  900: "#1971C2",
  1000: "#1864AB",
};

export const red = {
  100: "#FFF5F5",
  200: "#FFE3E3",
  300: "#FFC9C9",
  400: "#FFA8A8",
  500: "#FF8787",
  600: "#FF6B6B",
  700: "#FA5252",
  800: "#F03E3E",
  900: "#E03131",
  1000: "#C92A2A",
};

export const yellow = {
  100: "#FFF9DB",
  200: "#FFF3BF",
  300: "#FFEC99",
  400: "#FFE066",
  500: "#FFD43B",
  600: "#FCC419",
  700: "#FAB005",
  800: "#F59F00",
  900: "#F08C00",
  1000: "#E67700",
};

export const pink = {
  100: "#FFF0F6",
  200: "#FFDEEB",
  300: "#FCC2D7",
  400: "#FAA2C1",
  500: "#F783AC",
  600: "#F06595",
  700: "#E64980",
  800: "#D6336C",
  900: "#C2255C",
  1000: "#A61E4D",
};

export const grape = {
  100: "#F8F0FC",
  200: "#F3D9FA",
  300: "#EEBEFA",
  400: "#E599F7",
  500: "#DA77F2",
  600: "#CC5DE8",
  700: "#BE4BDB",
  800: "#AE3EC9",
  900: "#9C36B5",
  1000: "#862E9C",
};

export const violet = {
  100: "#F3F0FF",
  200: "#E5DBFF",
  300: "#D0BFFF",
  400: "#B197FC",
  500: "#9775FA",
  600: "#845EF7",
  700: "#7950F2",
  800: "#7048E8",
  900: "#6741D9",
  1000: "#5F3DC4",
};

export const indigo = {
  100: "#EDF2FF",
  200: "#DBE4FF",
  300: "#BAC8FF",
  400: "#91A7FF",
  500: "#748FFC",
  600: "#5C7CFA",
  700: "#4C6EF5",
  800: "#4263EB",
  900: "#3B5BDB",
  1000: "#364FC7",
};

export const cyan = {
  100: "#E3FAFC",
  200: "#C5F6FA",
  300: "#99E9F2",
  400: "#66D9E8",
  500: "#3BC9DB",
  600: "#22B8CF",
  700: "#15AABF",
  800: "#1098AD",
  900: "#0C8599",
  1000: "#0B7285",
};

export const teal = {
  100: "#E6FCF5",
  200: "#C3FAE8",
  300: "#96F2D7",
  400: "#63E6BE",
  500: "#38D9A9",
  600: "#20C997",
  700: "#12B886",
  800: "#0CA678",
  900: "#099268",
  1000: "#087F5B",
};

export const lime = {
  100: "#F4FCE3",
  200: "#E9FAC8",
  300: "#D8F5A2",
  400: "#C0EB75",
  500: "#A9E34B",
  600: "#94D82D",
  700: "#82C91E",
  800: "#74B816",
  900: "#66A80F",
  1000: "#5C940D",
};
