import { Card, CardContent, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { NodeProblems } from "../node_problems";
import { NodeT_ } from "../types";
import Accordion from "/src/design_system/Accordion";
import { Modal } from "../../design_system/Modal";
import { GoalInfo, Trace } from "/src/internal_types";
import { classNames } from "/src/utils";
import { parseReason, renderReason } from "/src/pages/policy/reason";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  summaryGridItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  summaryGrid: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: -1,
    display: "flex",
    paddingLeft: (summaryView) => (summaryView != null ? 16 : 16),
    marginLeft: (summaryView) => (summaryView != null ? 24 : 16),
    marginRight: (summaryView) => (summaryView != null ? 24 : 16),
  },
  nodeOnTheRight: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}));

export interface LeafNodeT_ extends NodeT_ {
  readonly label: string;
  readonly readOnly: boolean;
  readonly hidden: boolean;
  needsCollecting: boolean;
  problems: string[];
  isCommitting: boolean;
  description: string | undefined;
}

type LeafNodeProps = React.PropsWithChildren<{
  node: LeafNodeT_;
  className?: string;
}>;

const TraceComponent = (props: { trace: Trace }): JSX.Element => {
  const { trace } = props;

  switch (trace.tag) {
    case "ESeq":
      return (
        <div>
          {trace.contents.map((t, i) => (
            <div key={i} className="flex">
              <div className="w-6 flex-none font-bold">•</div>
              <div>
                <TraceComponent trace={t} />
              </div>
            </div>
          ))}
        </div>
      );
    case "EPar":
      if (trace.contents.length > 0) {
        const [x, ...xs] = trace.contents;
        return (
          <div>
            <div className="flex">
              <div className="w-6 flex-none"></div>
              <div>
                <TraceComponent trace={x} />
              </div>
            </div>
            {xs.map((t, i) => (
              <div key={i} className="flex">
                <div className="w-6 flex-none font-bold">∴</div>
                <div>
                  <TraceComponent trace={t} />
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return <></>;
      }
    case "ECustom":
      return <div>{trace.contents}</div>;
    case "EPlain":
      return <></>;
  }
};

/*
const ExplainTrace = (props: { dp: string }) => {
  const { productId, policyId, quoteId } = React.useContext(DataEditorContext);
  const r = useGetExplainTrace(productId, policyId, props.dp, quoteId);

  if (r.tag === "LangFailure" || r.contents.result.value.tag !== "Trace") {
    throw new Error("not a successful trace");
  }
  return (
    <pre>
      <TraceComponent trace={r.contents.result.value.contents} />
    </pre>
  );
};

const ShowExplainTraceButton = (props: { node: LeafNodeT_ }) => {
  const [isShiftPressed] = useKeyPress("Alt");
  return (
    <ModalDialog
      title={"Explain trace for " + props.node.id}
      disclosure={
        isShiftPressed ? (
          <Button size="xs" variant="secondary">
            show explain trace
          </Button>
        ) : null
      }
    >
      {(dialog) =>
        dialog.visible && (
          <Suspense fallback="Loading...">
            <ExplainTrace dp={props.node.id} />
          </Suspense>
        )
      }
    </ModalDialog>
  );
};
*/

// Bad name for the component, the semantics are a bit confusing.
// todo(@hmaurer): revisit
function NodeStatusLabel({ node }: { node: LeafNodeT_ }) {
  if (node.isCommitting) {
    return (
      <span className="leading-5 inline-flex flex-none text-blue-600 text-sm font-medium">
        [saving...]
      </span>
    );
  } else if (node.problems.length > 0) {
    return (
      <span className="leading-5 inline-flex flex-none text-red-600 text-sm font-medium ml-1">
        [invalid]
      </span>
    );
  } else if (node.needsCollecting) {
    return (
      <span className="leading-5 inline-flex flex-none text-red-600 text-sm font-medium">
        [required]
      </span>
    );
  }
  return null;
}

export const LeafNode: React.FC<LeafNodeProps> = (props: LeafNodeProps) => {
  const { node } = props;
  return (
    <>
      <div>
        <div className="flex flex-1 align-baseline justify-between">
          <span className="inline-flex align-baseline grow max-w-prose text-sm leading-5 text-gray-700 font-medium">
            {node.label}
          </span>
          <div className="w-24 flex-none text-right">
            <NodeStatusLabel node={node} />
          </div>
        </div>
      </div>
      <NodeProblems problems={node.problems} />
      <div {...classNames("mt-2")}>{props.children}</div>
      <div className={"ml-3.5 text-xs pt-1 text-gray-500"}>{node.description}</div>
    </>
  );
};

export type LeafNodeOnTheRightT = {
  readonly type: "leaf";
  readonly value?: string;
  readonly label: string;
};

export const LeafNodeOnTheRight = (props: LeafNodeOnTheRightT): JSX.Element => {
  const classes = useStyles();
  return (
    <Card className={classes.nodeOnTheRight} elevation={0}>
      <CardContent>
        <Typography variant="caption">{props.label}</Typography>
        <Typography variant="h2">{props.value ?? "-"}</Typography>
      </CardContent>
    </Card>
  );
};

export type SummaryLeafStringNodeProps = {
  label: string;
  contents: Array<string>;
  className?: string;
  size?: "large" | "medium";
};

export const SummaryLeafStringNode = (props: SummaryLeafStringNodeProps): JSX.Element => {
  const size = props.size ?? "medium";
  return (
    <SummaryLeafNode label={props.label} className={props.className}>
      {props.contents.map((c, id) => (
        <div key={id} className={`font-semibold break-words ${size === "large" ? "text-xl" : ""}`}>
          {c}
        </div>
      ))}
    </SummaryLeafNode>
  );
};

export type SummaryLeafNodeProps = PropsWithChildren<{
  label: string;
  className?: string;
  vertical?: boolean;
}>;

export const SummaryLeafNode = (props: SummaryLeafNodeProps): JSX.Element => {
  return (
    <div
      className={clsx([
        props.vertical !== true
          ? "grid grid-cols-1 gap-4 py-2 md:py-0 md:grid-cols-[1fr_2fr]"
          : "grid grid-rows-1 gap-2",
        "max-w-full",
      ])}
    >
      <div className="flex flex-1 flex-row md:flex-col space-y-4 px-4 py-2 md:py-4 h-full text-gray-700 text-sm self-center">
        {props.label}
      </div>
      <div className="flex flex-1 flex-row md:flex-col space-y-4 md:py-4 h-full text-gray-700 text-sm self-center max-w-full overflow-x-auto">
        {props.children}
      </div>
    </div>
  );
};

const ReferStatusIcon = (props: { type: "success" | "error" }): JSX.Element => {
  return (
    <div>
      {props.type == "error" && <ExclamationIcon className="h-5 text-red-600" />}
      {props.type == "success" && <CheckCircleIcon className="h-5 text-green-500" />}
    </div>
  );
};

export type ReferRowProps = { reasons?: { reason: string; trace: Trace }[]; fields: GoalInfo };

export const ReferRow = (props: PropsWithChildren<ReferRowProps>): JSX.Element => {
  const [showModal, setShowModal] = React.useState(false);
  const isError = props.reasons !== undefined && props.reasons.length > 0;
  return (
    <div className="max-w-full">
      <div
        className={`${
          isError ? "bg-red-50 cursor-pointer" : ""
        } relative flex border-b border-gray-200 px-3 max-w-full`}
        onClick={
          isError
            ? () => {
                setShowModal(true);
              }
            : undefined
        }
      >
        <div className="pl-4 flex-auto max-w-full">{props.children}</div>
        <div className="top-4 absolute">
          {<ReferStatusIcon type={isError ? "error" : "success"} />}
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <div className="p-8 max-w-6xl">
          <div className="flex justify-between">
            <span className="text-2xl">Referral reasons</span>
          </div>
          {props.reasons !== undefined &&
            props.reasons.length > 0 &&
            props.reasons.map((r, i) => {
              return (
                <div key={i} className="py-2">
                  <Accordion
                    title={
                      <span className={`font-medium text-black`}>
                        {`#${i + 1}: `}{" "}
                        {renderReason(parseReason(r.reason, [{ keys: props.fields.keys }]))}
                      </span>
                    }
                    className="w-screen-md"
                  >
                    <pre className="bg-gray-200 p-3 overflow-auto">
                      <TraceComponent trace={r.trace} />
                    </pre>
                  </Accordion>
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};
