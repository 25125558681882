import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { ChevronDownIcon, DocumentTextIcon } from "@heroicons/react/solid";
import {
  getMrcDocumentPage,
  useGetMrcDocumentInfo,
  useGetMrcDocumentCandidates,
} from "/src/dal/dal";
import { throwIfAppError } from "/src/utils/app_error";
import {
  MrcBoundingBox,
  MrcCandidate,
  MrcDocumentId,
  MrcDocumentInfo,
  Policy,
} from "/src/internal_types";
import * as Popover from "@radix-ui/react-popover";

// Utility hook for storing Map data.
// Returns the current state, and a function for setting new values.
export function useMapState<K, V>(init: Map<K, V>): [Map<K, V>, (k: K, v: V) => void] {
  const [maps, setMaps] = useState<Map<K, V>>(new Map(init));
  const tell = (k: K, v: V) => {
    setMaps((current) => {
      const next = new Map(current);
      next.set(k, v);
      return next;
    });
  };
  return [maps, tell];
}

// The MRC document viewer (i.e. the left pane).
// Contains the controls (jump to page) and all of the individual pages.
export const MrcDocumentViewer = (props: {
  policy: Policy;
  documentId: MrcDocumentId;
  tellBoundingBoxRef: (candidateId: number, ref: MutableRefObject<null | Element>) => void;
}): JSX.Element => {
  const docInfo = useGetMrcDocumentInfo(props.policy.productId, props.policy.id, props.documentId);
  throwIfAppError(docInfo);
  const candidates = useGetMrcDocumentCandidates(
    props.policy.productId,
    props.policy.id,
    props.documentId,
  );
  throwIfAppError(candidates);
  const pageNums = Array.from(
    { length: docInfo.value === null ? 0 : docInfo.value.pageCount },
    (_, k) => 1 + k,
  );

  // A map from page number to the intersection ratio (higher = more visible)
  const [pageIntersections, setPageIntersection] = useMapState(
    pageNums.reduce((m, v) => m.set(v, 0.0), new Map()),
  );
  const currentPage = (() => {
    let amount = 0.0;
    let page = 1;
    pageIntersections.forEach((v, k) => {
      if (v > amount) {
        amount = v;
        page = k;
      }
    });
    return page;
  })();

  // A map from page number to the element ref containing that page
  const [pageRefs, setPageRef] = useMapState(new Map());
  const jumpToPage = (page: number) => {
    const ref = pageRefs.get(page);
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const pagesParentRef = useRef(null);

  const candidatesByPage: Map<number, MrcCandidate[]> = new Map();
  candidates.value.forEach((candidate) => {
    const bbs = candidatesByPage.get(candidate.pageNum);
    if (bbs === undefined) {
      candidatesByPage.set(candidate.pageNum, [candidate]);
    } else {
      bbs.push(candidate);
    }
  });

  return (
    <div className="flex flex-col h-full">
      <div className="grow-0 shrink-0 flex flex-row items-center">
        <h2 className="bg-white p-3 text-sm leading-5 font-medium border-r border-gray-200 truncate shrink">
          <DocumentTextIcon className="w-4 h-4 mr-1 inline text-gray-500 -mt-0.5" />
          {docInfo.value === null ? "" : docInfo.value.mrcFileName}
        </h2>
        <div className="border-b border-gray-200 whitespace-nowrap grow flex flex-row items-center">
          <p className="whitespace-nowrap text-sm leading-5 font-medium text-gray-500 m-3">
            Jump to page
          </p>
          <Popover.Root>
            <Popover.Trigger className="flex gap-2 items-center h-min-content rounded-md border border-gray-300 shadow-sm mr-3 pl-2 pr-1 py-1 bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 font-mono">
              {currentPage}
              <ChevronDownIcon className="h-4 w-4" />
            </Popover.Trigger>
            <Popover.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              align="end"
              className="mt-2 text-center rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-scroll outline-none flex flex-col max-h-40"
            >
              {pageNums.map((pageNum) => {
                const active = pageNum === currentPage;
                return (
                  <a
                    key={pageNum}
                    onClick={(_) => jumpToPage(pageNum)}
                    className={
                      "select-none cursor-pointer px-4 py-0.5 hover:bg-gray-200" +
                      (active ? " bg-gray-100" : "")
                    }
                  >
                    {pageNum}
                  </a>
                );
              })}
            </Popover.Content>
          </Popover.Root>
        </div>
      </div>
      <div ref={pagesParentRef} className="grow overflow-scroll">
        {docInfo.value !== null &&
          pageNums.map((pageNum) => {
            return (
              <MrcDocumentPage
                key={pageNum}
                policy={props.policy}
                // Not sure why ts thinks this can still be null
                document={docInfo.value as any}
                pageNum={pageNum}
                candidates={candidatesByPage.get(pageNum)}
                parentRef={pagesParentRef}
                tellBoundingBoxRef={props.tellBoundingBoxRef}
                tellRef={(ref) => setPageRef(pageNum, ref)}
                tellIntersection={(amount) => setPageIntersection(pageNum, amount)}
              />
            );
          })}
      </div>
    </div>
  );
};

// An individual document page
export const MrcDocumentPage = (props: {
  policy: Policy;
  document: MrcDocumentInfo;
  pageNum: number;
  candidates: MrcCandidate[] | undefined;
  parentRef: MutableRefObject<null | Element>;
  tellBoundingBoxRef: (candidateId: number, ref: MutableRefObject<null | Element>) => void;
  tellRef: (ref: MutableRefObject<null | Element>) => void;
  tellIntersection: (amount: number) => void;
}): JSX.Element => {
  const [url, setUrl] = useState<undefined | string>(undefined);
  const observerRef = useRef(null);

  useEffect(() => {
    props.tellRef(observerRef);
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        props.tellIntersection(entry.intersectionRatio);
      },
      {
        root: props.parentRef.current,
        rootMargin: "50% 0% 0% 0%",
        threshold: [0.0, 0.25, 0.5, 0.75, 1.0],
      },
    );
    if (observerRef.current !== null) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current !== null) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef.current]);

  useEffect(() => {
    const fetch = async () => {
      const r = await getMrcDocumentPage(
        props.policy.productId,
        props.policy.id,
        props.document.id,
        props.pageNum,
      );
      setUrl(r);
    };
    // `useEffect` can't be an async function
    // but the function below is an async function.
    // So by using `void` operator we let the developer know
    // that we explicitly allow the promise to run in the background
    // without waiting for it's result
    void fetch();
  }, []);

  return (
    <div ref={observerRef} className="relative m-2">
      {props.candidates !== undefined &&
        props.candidates.map((candidate) => {
          return (
            <BoundingBox
              key={candidate.id}
              tellRef={(ref) => props.tellBoundingBoxRef(candidate.id, ref)}
              boundingBox={candidate.boundingBox}
            />
          );
        })}
      <img src={url} />
    </div>
  );
};

export const BoundingBox = (props: {
  boundingBox: MrcBoundingBox;
  tellRef: (ref: MutableRefObject<Element | null>) => void;
}): JSX.Element => {
  const ref = useRef(null);
  const bb = props.boundingBox;
  const top = `${bb.top * 100}%`;
  const right = `${(1 - bb.left - bb.width) * 100}%`;
  const bottom = `${(1 - bb.top - bb.height) * 100}%`;
  const left = `${bb.left * 100}%`;

  useEffect(() => props.tellRef(ref), []);

  return (
    <div
      ref={ref}
      className="absolute bg-blue-500 opacity-25 rounded-sm"
      style={{ top, right, bottom, left }}
    ></div>
  );
};
