import React from "react";
import { PolicyId, ProductId, QuoteId } from "../internal_types";

export const DataEditorContext = React.createContext<{
  productId: ProductId;
  policyId: PolicyId;
  quoteId?: QuoteId | undefined;
}>({
  get productId(): ProductId {
    throw new Error("not in a data editor context");
  },
  get policyId(): PolicyId {
    throw new Error("not in a data editor context");
  },
  get quoteId(): QuoteId {
    throw new Error("not in a data editor context");
  },
});
