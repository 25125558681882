import { DropdownItem } from "/src/components/DropdownItem";
import { Dropdown } from "/src/components/Dropdown";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
  BrossaAggregation,
  PrimitiveAggregationOutput,
  Widget,
  NewAggregation as NewBrossaAggregation,
  AggregationTrigger,
  Product,
  PrimitiveAggregation,
  Projection as APIProjection,
  ProjectionOperation,
  Scalar,
} from "/src/internal_types";
import { Either, Left, Right, assertNever, filterMap, toNumber, hasOwnProperty } from "/src/utils";
import { Field, Form, Formik, FormikProps, FieldArray } from "formik";
import { FormError, Label } from "/src/design_system/kitchen_sink";
import { FormikInput, FormikSelect, FormikTextarea, FormikCodeMirror } from "/src/forms";
import {
  Pagination,
  TBody,
  TD,
  TH,
  THead,
  TR,
  Table,
  TableBody,
  TableHeader,
} from "/src/design_system/Table";
import React, { useEffect } from "react";
import {
  apiDelete,
  invalidatePath,
  useGetAggregationTriggers,
  useGetAggregations,
  useGetCurrentUser,
  useGetSchema,
  useGetPrimitiveAggregations,
} from "/src/dal/dal";
import { Button } from "/src/design_system/Button";
import { CanAddAggregation, CanAddTrigger } from "/src/pages/policy/permissions";
import { Modal } from "/src/design_system/Modal";
import { ConfirmationModal } from "/src/components/ConfirmationModal";
import { NotificationModal } from "/src/components/NotificationModal";
import { getProductCaption } from "/src/pages/policy/policies";
import { PlusSmIcon, XIcon, EyeIcon } from "@heroicons/react/solid";
import { CodeMirror, makeCommandPusher, CommandPusher } from "/src/components/CodeMirror";
import { Routes } from "/src/routing/routes";
import { throwIfAppError } from "/src/utils/app_error";

export function Aggregations(props: {
  productId?: number;
  products: Product[];
  page?: number;
  setPage: (page: number) => void;
}) {
  const history = useHistory();
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const canAddAggregation = CanAddAggregation({ currentRole: currentUser.value.role }).check(
    undefined,
  );
  return (
    <>
      <div className="ml-2 mt-4 mb-4">
        <p>
          Create aggregations to track a certain datapoint on your product(s). Trigger rules and
          referrals based on those datapoints.
        </p>
      </div>
      <div className="ml-2 mb-4">
        {canAddAggregation && (
          <Button
            data-testid="add-aggregation-button"
            onClick={() => history.push(Routes.createAggregation.generatePath({}))}
          >
            <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" />
            Add aggregation
          </Button>
        )}
      </div>
      <AggregationsTable {...props} />
    </>
  );
}

const AggregationsTable: React.FC<{
  productId?: number;
  products: Product[];
  page?: number;
  setPage: (page: number) => void;
}> = ({ productId, products, page, setPage }) => {
  const history = useHistory();
  const { count, aggregations } = (() => {
    const aggregationsResult = useGetAggregations({ productId, page });
    throwIfAppError(aggregationsResult);
    const aggs = aggregationsResult.value.aggregations.map(toConvertedAggregation);
    if (page === undefined || page === 0) {
      const primitiveAggregationsResult = useGetPrimitiveAggregations();
      throwIfAppError(primitiveAggregationsResult);
      const primAggs = primitiveAggregationsResult.value.outputs
        .filter((output) => productId === undefined || productId === output.aggregation.productId)
        .map(toConvertedAggregation);
      return {
        count: aggregationsResult.value.count + primAggs.length,
        aggregations: primAggs.concat(aggs),
      };
    }
    return {
      count: aggregationsResult.value.count,
      aggregations: aggs,
    };
  })();

  return (
    <div>
      <Table>
        <TableHeader
          headers={[
            { label: "Name" },
            { label: "Description" },
            { label: "Product" },
            { label: "Version" },
            { label: "Function" },
            { label: "" },
          ]}
        />
        <TableBody
          rows={aggregations.map((aggregation) => {
            const product = products.find((x) => x.id === aggregation.productId);
            const function_ = aggregation.type;
            return {
              rowId: aggregation.name,
              columns: [
                { content: aggregation.name },
                { content: aggregation.description, size: "large" },
                { content: product?.name ?? "" },
                { content: product?.version ?? "" },
                { content: function_ },
                {
                  content: (
                    <div className="flex flex-row gap-x-5">
                      <ShowOutputDialog aggregation={aggregation} />
                      <Button
                        onClick={() =>
                          history.push(
                            Routes.editAggregation.generatePath({
                              capture: {
                                aggregationType: aggregation.type.toLowerCase() as any,
                                aggregationId: aggregation.id,
                              },
                            }),
                          )
                        }
                        variant="secondary"
                      >
                        Edit
                      </Button>
                    </div>
                  ),
                },
              ],
            };
          })}
        />
      </Table>
      {aggregations.length > 0 && (
        <Pagination totalRowsCount={count} currentPageNumber={page ?? 1} onPageChange={setPage} />
      )}
    </div>
  );
};

export function Triggers(props: { productId?: number; products: Product[] }) {
  const history = useHistory();
  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const canAddTrigger = CanAddTrigger({ currentRole: currentUser.value.role }).check(undefined);
  return (
    <>
      <div className="ml-2 mt-4 mb-4">
        {" "}
        <p>Create triggers for decline or referral reasons.</p>
      </div>
      <div className="ml-2 mb-4">
        {canAddTrigger && (
          <Button
            onClick={() => history.push(Routes.createTrigger.generatePath({}))}
            data-testid="add-trigger-button"
          >
            <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" />
            Add trigger
          </Button>
        )}
      </div>
      <TriggersTable productId={props.productId} products={props.products} />
    </>
  );
}

const TriggersTable: React.FC<{
  productId: number | undefined;
  products: Product[];
}> = ({ productId, products }) => {
  const history = useHistory();
  const data = useGetAggregationTriggers();
  throwIfAppError(data);
  const aggregationsResult = useGetAggregations({});
  throwIfAppError(aggregationsResult);
  const scriptingAggregationNames = new Map(
    aggregationsResult.value.aggregations.map((aggregation) => [aggregation.id, aggregation.name]),
  );
  const primitiveAggregationResp = useGetPrimitiveAggregations();
  throwIfAppError(primitiveAggregationResp);
  const primitiveAggregationNames = new Map(
    primitiveAggregationResp.value.outputs.map(({ aggregation, aggregationId }) => [
      aggregationId,
      aggregation.name,
    ]),
  );
  return (
    <div>
      <Table className="min-w-full divide-y divide-gray-200 h-full">
        <THead className="z-100 relative">
          <TR>
            <TH label="Name" />
            <TH label="Aggregation Name" />
            <TH label="Condition" />
            {productId === undefined && <TH label="Product" />}
            <TH label="Action" />
            <TH label="Action Message" />
            <TH label="" />
          </TR>
        </THead>
        <TBody>
          {data.value.value.map((triggerWithId) => {
            const { trigger, id } = triggerWithId;
            const product = products.find((x) => x.id === trigger.productId);
            const aggregationName = (() => {
              if (trigger.aggregation.tag === "ScriptingAggregationId") {
                return scriptingAggregationNames.get(trigger.aggregation.contents) ?? "";
              }
              return primitiveAggregationNames.get(trigger.aggregation.contents) ?? "";
            })();
            return (
              <TR
                key={trigger.name}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => {
                  return;
                }}
              >
                <TD>{trigger.name}</TD>
                <TD>{aggregationName}</TD>
                <TD className={"w-64"}>{trigger.condition}</TD>
                {productId === undefined && (
                  <TD>{product !== undefined ? getProductCaption(product) : ""}</TD>
                )}
                <TD>{trigger.triggerType}</TD>
                <TD>{trigger.message}</TD>
                <TD>
                  <div className="flex flex-row space-x-3 justify-end">
                    <Button
                      variant="secondary"
                      onClick={() =>
                        history.push(
                          Routes.editTrigger.generatePath({
                            capture: {
                              triggerId: id,
                            },
                          }),
                        )
                      }
                    >
                      Edit
                    </Button>
                    <DeleteTriggerDialog trigger={triggerWithId.id} />
                  </div>
                </TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

const ShowOutputDialog: React.FC<{
  aggregation: ConvertedAggregation;
}> = ({ aggregation }) => {
  const [open, setOpen] = React.useState(false);
  const error = aggregation.type === "Custom" ? aggregation.error : undefined;
  const output = (() => {
    if (error !== undefined) {
      return "Error";
    }
    switch (aggregation.type) {
      case "Custom":
        return aggregation.finalValue ?? "";
      case "Grouped":
        return aggregation.brossaValue;
    }
  })();
  return (
    <>
      <Button onClick={() => setOpen(true)} variant="secondary">
        <EyeIcon className="mr-3 w-5 h-5 text-gray-500" />
        Show output
      </Button>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <div className="p-8 flex flex-col gap-5 w-screen-md min-h-[12rem]">
          <div>
            <p className="text-xl font-bold">Output of aggregation "{aggregation.name}"</p>
          </div>
          {error === undefined ? (
            <CodeMirror initialText={output} readOnly={true} />
          ) : (
            <div className="font-bold text-red-400 whitespace-normal">{error}</div>
          )}
        </div>
      </Modal>
    </>
  );
};

const DeleteAggregationDialog = (props: {
  products: Product[];
  aggregation: ConvertedAggregation;
}): JSX.Element => {
  type DeletionState =
    | { state: "waiting" } // Waiting for the user to hit Delete
    | { state: "confirming" } // Showing the user the confirmation dialog
    | { state: "deleting" } // The delete request is in flight
    | { state: "error"; message: string }; // Deletion failed and we are showing some error message.

  const [deletionState, setDeletionState] = React.useState<DeletionState>({ state: "waiting" });
  const history = useHistory();
  const backToAggregations = () =>
    history.push(
      Routes.portfolioTabs.generatePath({
        capture: { tab: "aggregates" },
      }),
    );

  const performDeletion = async (): Promise<void> => {
    const deleter = (() => {
      if (props.aggregation.type === "Grouped") {
        return apiDelete("/api/stats/primitive-aggregation/{primitiveAggregationId}", {
          params: { primitiveAggregationId: props.aggregation.id },
          query: {},
        }).then(() => invalidatePath("/api/stats/primitive-aggregation"));
      } else {
        return apiDelete("/api/stats/aggregation/{id}", {
          params: { id: props.aggregation.id },
          query: {},
        }).then(async (e) => {
          if (e.status === "success") {
            await invalidatePath("/api/stats/aggregation");
          } else {
            setDeletionState({ state: "error", message: e.value.message });
          }
        });
      }
    })();

    await deleter.then(backToAggregations);
  };

  useEffect(() => {
    if (deletionState.state === "deleting") {
      // `useEffect` can't be an async function
      // but `performDeletion` is an async function.
      // So by using `void` operator we let the developer know
      // that we explicitly allow the promise to run in the background
      // without waiting for it's result
      void performDeletion();
    }
  }, [deletionState]);

  return (
    <>
      <Button
        variant="danger-secondary"
        onClick={() => setDeletionState({ state: "confirming" })}
        disabled={deletionState.state !== "waiting"}
      >
        Delete
      </Button>
      <ConfirmationModal
        title="Delete aggregation"
        isOpen={deletionState.state === "confirming" || deletionState.state === "deleting"}
        isAwaiting={deletionState.state === "deleting"}
        onClose={() => setDeletionState({ state: "waiting" })}
        onConfirm={() => setDeletionState({ state: "deleting" })}
        confirmButtonLabel="Delete"
      >
        <p>
          Are you sure you want to permanently delete this aggregation? This cannot be reversed.
        </p>
      </ConfirmationModal>
      <NotificationModal
        title="Cannot delete aggregation"
        isOpen={deletionState.state === "error"}
        onClose={() => setDeletionState({ state: "waiting" })}
      >
        <p>{deletionState.state === "error" ? deletionState.message : ""}</p>
      </NotificationModal>
    </>
  );
};

export type Projection =
  | {
      operation: "Min" | "Max" | "Sum" | "Product" | "Average" | "Group By";
      datapoint: string;
      projectAs?: string;
      finalExpr?: string;
      index: number;
    }
  | {
      operation: "Count";
      projectAs?: string;
      index: number;
    };

const blankProjection: Projection = {
  operation: "Group By",
  finalExpr: "",
  datapoint: "",
  projectAs: undefined,
  index: 0,
};

type Datapoint = {
  key: {
    base: string;
  };
};

function cmpDatapoints(a: Datapoint, b: Datapoint): number {
  if (a.key.base < b.key.base) {
    return -1;
  }
  if (a.key.base > b.key.base) {
    return 1;
  }
  return 0;
}

const aggregationValidationSchema = Yup.object({
  name: Yup.string().required("Field required."),
  description: Yup.string().optional().default(""),
  productId: Yup.number().optional(),
  productVersion: Yup.number().optional(),
  function: Yup.string().required("Field required"),
  initExpr: Yup.string().when("function", {
    is: "Custom",
    then: Yup.string().required("Field required."),
  }),
  foldExpr: Yup.string().when("function", {
    is: "Custom",
    then: Yup.string().required("Field required."),
  }),
  finalExpr: Yup.string().optional(),
  widgetType: Yup.string().optional(),
  projections: Yup.array()
    .of(
      Yup.object({
        operation: Yup.string().required("Operation field required."),
        datapoint: Yup.string().when("operation", {
          is: (op: string) => op !== "Count",
          then: Yup.string().required("Datapoint field required."),
        }),
        projectAs: Yup.string().optional().default(undefined),
        finalExpr: Yup.string().optional().default(""),
        index: Yup.number().required(),
      }),
    )
    .defined()
    .test(
      "datapoint-does-not-appear-in-group-by-and-another-projection",
      "A datapoint can not be used in a group by projection and another type of projection at the same time.",
      (projections, { parent }) => {
        if (projections === undefined || parent.function !== "Grouped") {
          return true;
        }
        const groupByDatapoints = new Set(
          projections
            .filter(({ operation }) => operation === "Group By")
            .map(({ datapoint }) => datapoint),
        );
        return (
          projections.filter(
            ({ operation, datapoint }) =>
              operation !== "Group By" && groupByDatapoints.has(datapoint),
          ).length == 0
        );
      },
    )
    .test(
      "at-least-one-projection",
      "An aggregation should have at least one projection.",
      (projections, { parent }) =>
        parent.function !== "Grouped" || (projections !== undefined && projections.length > 0),
    )
    .test(
      "at-least-one-non-group-by",
      "An aggregation with grouping should have at least one projection of another type.",
      (projections, { parent }) => {
        if (parent.function !== "Grouped" || projections === undefined) {
          return true;
        }
        const hasGroupBy =
          projections.find(({ operation }) => operation === "Group By") !== undefined;
        const nonGroupBys = projections.filter(({ operation }) => operation !== "Group By").length;
        return !hasGroupBy || nonGroupBys > 0;
      },
    ),
});

export const AggregationForm: React.FC<{
  initialFields: Partial<ConvertedAggregation>;
  onSubmit: (fields: NewAggregation) => Promise<string | undefined>;
  title: string;
  products: Product[];
  productEditable: boolean;
  functionEditable: boolean;
  initialError?: string;
  existingAggregation?: ConvertedAggregation;
}> = (props) => {
  const history = useHistory();
  const backToPortfolio = () =>
    history.push(
      Routes.portfolioTabs.generatePath({
        capture: { tab: "aggregates" },
      }),
    );
  const [error, setError] = React.useState<null | string>(props.initialError ?? null);
  const [chosenProductId, setChosenProductId] = React.useState<number>();
  const schema = useGetSchema(chosenProductId);
  throwIfAppError(schema);
  const datapointsOfType = (types: string[]) => {
    return filterMap(schema.value, (v) => {
      if (
        v.value.typ.tag === "Function" &&
        v.value.typ.contents.params.length === 0 &&
        v.value.typ.contents.ret.tag === "Scalar" &&
        v.key.meta === undefined &&
        types.includes(v.value.typ.contents.ret.contents.scalarType)
      ) {
        return { key: v.key, unit: v.value.typ.contents.ret.contents.unit };
      }
      return undefined;
    });
  };
  const numberDatapoints = datapointsOfType(["Number", "Int", "Percent"]).sort(cmpDatapoints);
  const stringDatapoints = datapointsOfType(["Text", "CaselessText"]); // do we need CaselessText here?
  const allDatapoints = numberDatapoints.concat(stringDatapoints).sort(cmpDatapoints);
  const datapointCount = numberDatapoints.length + stringDatapoints.length;
  const projectionDatapoints = (projection: Projection) =>
    projection.operation === "Group By" ? allDatapoints : numberDatapoints;
  const splitError = error !== null ? error.split("\n") : null;
  const chosenProduct =
    chosenProductId !== undefined
      ? props.products.find((product) => product.id === chosenProductId)
      : undefined;
  const { description, initExpr, foldExpr, finalExpr, projections } = (() => {
    const blank = {
      description: "",
      initExpr: "",
      foldExpr: "",
      finalExpr: "",
      projections: [],
    };
    switch (props.initialFields.type) {
      case "Custom":
        return {
          description: props.initialFields.description ?? "",
          initExpr: props.initialFields.initExpr ?? "",
          foldExpr: props.initialFields.foldExpr ?? "",
          finalExpr: props.initialFields.finalExpr ?? "",
          projections: [],
        };
      case "Grouped":
        return {
          ...blank,
          description: props.initialFields.description ?? "",
          projections: props.initialFields.projections ?? [],
        };
      default:
        return blank;
    }
  })();
  return (
    <div data-testid="add-aggregation-form">
      <div className="p-8 font-medium">
        <Link
          to={Routes.portfolioTabs.generatePath({ capture: { tab: "aggregates" } })}
          className="text-gray-500 hover:text-gray-400 leading-none"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2 inline-block" />
          Back to portfolio
        </Link>
      </div>
      <Formik
        initialValues={{
          name: props.initialFields.name ?? "",
          description,
          initExpr,
          foldExpr,
          finalExpr,
          productId: props.initialFields.productId?.toString(),
          productVersion: props.initialFields.productVersion?.toString(),
          function: props.initialFields.type,
          prefix: undefined,
          widgetType:
            props.initialFields.widgetType === "Choropleth"
              ? "World Map"
              : props.initialFields.widgetType,
          projections,
        }}
        validationSchema={aggregationValidationSchema}
        onSubmit={async (values, form) => {
          try {
            setError(null);
            const productId = values.productId === "" ? undefined : values.productId;
            const productVersion = values.productVersion === "" ? undefined : values.productVersion;
            const validatedValues = await aggregationValidationSchema.validate({
              ...values,
              productId,
              productVersion,
            });
            const widgetType =
              validatedValues.widgetType === undefined || validatedValues.widgetType === ""
                ? undefined
                : toWidgetType(validatedValues.widgetType).getOrThrow();
            let result;
            if (validatedValues.function === "Custom") {
              result = await props.onSubmit({
                type: "Brossa" as const,
                ...validatedValues,
                widgetType,
                initExpr: validatedValues.initExpr ?? "", //safe
                foldExpr: validatedValues.foldExpr ?? "", //safe
                finalExpr: validatedValues.finalExpr ?? "", //safe
              });
            } else {
              const projections = validatedValues.projections.map((projection) => {
                const convertedProjection = toAPIProjection({ ...projection }).getOrThrow();
                const projectAs = (() => {
                  if (projection.projectAs !== undefined) {
                    return projection.projectAs;
                  }
                  if (projection.operation === "Group By") {
                    // Validation schema doesn't allow it to be undefined actually
                    return projection.datapoint ?? "";
                  }
                  return `${projection.operation} ${projection.datapoint}`;
                })();
                return {
                  index: projection.index,
                  projectAs,
                  projection: convertedProjection,
                };
              });
              result = await props.onSubmit({
                type: "Primitive" as const,
                name: validatedValues.name,
                description: validatedValues.description,
                productId: validatedValues.productId,
                productVersion: validatedValues.productVersion,
                projections,
                widgetType,
              });
            }
            if (result !== undefined) {
              setError(result);
              return;
            }
            form.resetForm();
            backToPortfolio();
          } catch (e) {
            setError("Something went wrong.");
          }
        }}
      >
        {(form) => {
          const newProductId = form.values.productId;
          useEffect(() => {
            if (newProductId !== chosenProductId) {
              const x =
                newProductId !== undefined ? toNumber(newProductId) ?? undefined : undefined;
              setChosenProductId(x);
            }
          }, [newProductId]);
          const function_ = form.values.function;
          const allowedWidgetTypes =
            function_ === "Custom" ? ["Raw", "Counter"] : ["Bar", "Table", "Counter", "World Map"];
          return (
            <Form noValidate>
              <div className="flex-1 w-screen-md">
                <div className="text-xl px-8 font-bold">{props.title}</div>
                <div className="flex-1 px-8 pt-4 space-y-4 overflow-y-auto">
                  {splitError !== null && splitError.length > 0 && (
                    <div className="bg-red-50 px-4 py-2 text-red-700 rounded whitespace-normal">
                      {splitError.map((e) => (
                        <p> {e} </p>
                      ))}
                    </div>
                  )}
                  <div className="space-y-1">
                    <Label>Name</Label>
                    <Field
                      type="text"
                      name="name"
                      component={FormikInput}
                      data-testid="aggregation-name"
                    />
                    {form.submitCount > 0 &&
                      form.touched.name &&
                      form.errors.name !== undefined && <FormError>{form.errors.name}</FormError>}
                  </div>
                  <div className="space-y-1">
                    <Label>Product</Label>
                    <Field
                      name="productId"
                      component={FormikSelect}
                      disabledHint="This aggregation has associated triggers and the product cannot be edited"
                      disabled={!props.productEditable}
                      data-testid="product-id"
                    >
                      <option value="">All products</option>
                      {props.products.map((product) => {
                        return (
                          <option value={product.id} key={product.id}>
                            {getProductCaption(product)}
                          </option>
                        );
                      })}
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.productId &&
                      form.errors.productId !== undefined && (
                        <FormError>{form.errors.productId}</FormError>
                      )}
                  </div>
                  <div className="space-y-1">
                    <Label>Product Version</Label>
                    <Field
                      name="productVersion"
                      component={FormikSelect}
                      disabledHint="This aggregation has associated triggers and the product version cannot be edited"
                      disabled={!props.productEditable}
                    >
                      <option value="">All versions</option>
                      {chosenProduct !== undefined &&
                        // WARNING: we are assuming that all of the versions are from 1 to `chosenProduct.version`
                        // ie, there are no deleted versions in between
                        // also, we are adding + 1 here because indexing on versions start from 0
                        [...Array(chosenProduct.version + 1).keys()].map((version) => {
                          return (
                            <option value={version} key={version}>
                              {version}
                            </option>
                          );
                        })}
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.productVersion &&
                      form.errors.productVersion !== undefined && (
                        <FormError>{form.errors.productVersion}</FormError>
                      )}
                  </div>
                  <div className="space-y-1">
                    <Label>Description</Label>
                    <Field type="text" name="description" component={FormikTextarea} />
                    {form.submitCount > 0 &&
                      form.touched.description &&
                      form.errors.description !== undefined && (
                        <FormError>{form.errors.description}</FormError>
                      )}
                  </div>
                  <div className="space-y-1">
                    <Label>Function</Label>
                    <Field
                      name="function"
                      component={FormikSelect}
                      disabled={!props.functionEditable}
                      data-testid="new-aggregation-function"
                    >
                      <option value=""></option>
                      {["Custom", "Grouped"].map((function_) => {
                        return (
                          <option value={function_} key={function_}>
                            {function_}
                          </option>
                        );
                      })}
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.function &&
                      form.errors.function !== undefined && (
                        <FormError>{form.errors.function}</FormError>
                      )}
                  </div>
                  {function_ === "Grouped" && (
                    <div className="space-y-1">
                      <div className="block text-sm font-medium text-gray-700">Projections</div>
                      <FieldArray name="projections">
                        {({ remove, push }) => (
                          <div className="border rounded-md shadow-sm divide-y overflow-hidden">
                            {form.values.projections.map((projection, index) => (
                              <div className="p-3 space-y-3" key={projection.index}>
                                <div className="flex space-x-1">
                                  <div className="flex-none">
                                    <Field
                                      name={`projections.${index}.operation`}
                                      component={FormikSelect}
                                    >
                                      {[
                                        "Average",
                                        "Count",
                                        "Group By",
                                        "Max",
                                        "Min",
                                        "Product",
                                        "Sum",
                                      ].map((op) => (
                                        <option value={op}>{op}</option>
                                      ))}
                                    </Field>
                                  </div>
                                  <div className="grow">
                                    <Field
                                      name={`projections.${index}.projectAs`}
                                      type="text"
                                      component={FormikInput}
                                      placeholder="Project as"
                                    />
                                  </div>
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => remove(index)}
                                    className="flex-none"
                                  >
                                    <XIcon className="h-4 w-4 fill-gray-600" />
                                  </Button>
                                </div>
                                {datapointCount > 0 && projection.operation !== "Count" && (
                                  <div className="shadow-sm overflow-hidden">
                                    <p>Source of data:</p>
                                    <Field
                                      name={`projections.${index}.datapoint`}
                                      type="text"
                                      projectionDatapoints={projectionDatapoints}
                                      projection={projection}
                                      component={ExpressionInput}
                                      suffix=""
                                      index={index}
                                    />
                                  </div>
                                )}

                                <FinalExpr
                                  form={form}
                                  key={index}
                                  index={index}
                                  projection={projection}
                                />

                                {form.submitCount > 0 &&
                                  form.touched.projections?.at(index) !== undefined &&
                                  Array.isArray(form.errors.projections) &&
                                  form.errors.projections?.at(index) !== undefined && (
                                    <FormError>
                                      {Object.values(form.errors.projections[index]).concat("\n")}
                                    </FormError>
                                  )}
                              </div>
                            ))}
                            <div
                              className="rounded-b-md bg-gray-50 hover:bg-white text-center cursor-pointer"
                              data-testid="add-projection-button"
                              onClick={() => {
                                const index =
                                  Math.max(
                                    -1,
                                    ...form.values.projections.map(({ index }) => index),
                                  ) + 1;
                                push({ ...blankProjection, index });
                              }}
                            >
                              <PlusSmIcon className="h-5 w-5 m-3 inline-block fill-gray-600" />
                            </div>
                          </div>
                        )}
                      </FieldArray>
                      {form.submitCount > 0 &&
                        form.touched.projections !== undefined &&
                        form.touched.projections.length > 0 &&
                        typeof form.errors.projections === "string" && (
                          <FormError>{form.errors.projections}</FormError>
                        )}
                    </div>
                  )}
                  {function_ === "Custom" && (
                    <>
                      <div className="space-y-1" data-testid="init-expression">
                        <Label>Initial value</Label>
                        <Field type="text" name="initExpr" component={FormikCodeMirror} />
                        {form.submitCount > 0 &&
                          form.touched.initExpr &&
                          form.errors.initExpr !== undefined && (
                            <FormError>{form.errors.initExpr}</FormError>
                          )}
                      </div>
                      <div className="space-y-1" data-testid="fold-expression">
                        <Label>
                          Folding expression (reminder: use <code>acc</code>)
                        </Label>
                        <Field type="text" name="foldExpr" component={FormikCodeMirror} />
                        {form.submitCount > 0 &&
                          form.touched.foldExpr &&
                          form.errors.foldExpr !== undefined && (
                            <FormError>{form.errors.foldExpr}</FormError>
                          )}
                      </div>
                      <div className="space-y-1" data-testid="final-expression">
                        <Label>
                          Final expression (reminder: use <code>result</code>)
                        </Label>
                        <Field type="text" name="finalExpr" component={FormikCodeMirror} />
                        {form.submitCount > 0 &&
                          form.touched.finalExpr &&
                          form.errors.finalExpr !== undefined && (
                            <FormError>{form.errors.finalExpr}</FormError>
                          )}
                      </div>
                    </>
                  )}
                  <div className="space-y-1">
                    <Label>Widget type</Label>
                    <Field name="widgetType" component={FormikSelect}>
                      <option value=""></option>
                      {allowedWidgetTypes.map((widgetType) => (
                        <option value={widgetType} key={widgetType}>
                          {widgetType}
                        </option>
                      ))}
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.widgetType &&
                      form.errors.widgetType !== undefined && (
                        <FormError>{form.errors.widgetType}</FormError>
                      )}
                  </div>
                </div>
                <div className="px-8 pt-4 pb-8 flex">
                  {props.existingAggregation !== undefined && (
                    <DeleteAggregationDialog
                      products={props.products}
                      aggregation={props.existingAggregation}
                    />
                  )}
                  <div className="flex flex-grow space-x-2 justify-end">
                    <Button variant="secondary" size="sm" onClick={backToPortfolio}>
                      Cancel
                    </Button>
                    <Button type="submit" size="sm" isPending={form.isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

// Final expression for a primitive aggregation projection.
//
// Can be toggled on or off. Toggling it off sets the value to empty.
const FinalExpr = (props: {
  form: FormikProps<{
    name: string;
    description: string;
    initExpr: string;
    foldExpr: string;
    finalExpr: string;
    productId: string | undefined;
    productVersion: string | undefined;
    function: "Custom" | "Grouped" | undefined;
    prefix: undefined;
    widgetType: string | undefined;
    projections: Projection[];
  }>; // Got this big type from the compiler output, it's not
  // important, I'm just trying to shut up the type checker.
  index: number;
  projection: Projection;
}) => {
  const index = props.index;
  const fieldName = `projections.${index}.finalExpr`;

  // This drives the toggling of the input.
  const [addFinalAdjustmentChecked, setAddFinalAdjustmentChecked] = React.useState<boolean>(
    props.projection.operation !== "Count" &&
      props.projection.operation !== "Group By" &&
      (props.projection.finalExpr ?? "").length !== 0,
  );

  // We use this to hide/show the input.
  const classN = addFinalAdjustmentChecked ? "" : "hidden";

  const [incremented, setIncremented] = React.useState<number>(0);

  if (props.projection.operation !== "Count" && props.projection.operation !== "Group By") {
    return (
      <div className="shadow-sm overflow-hidden">
        <p>
          <label className="cursor-pointer">
            <input
              className="m-1"
              type="checkbox"
              checked={addFinalAdjustmentChecked}
              onChange={() => {
                // When they 'uncheck' the final adjustment input,
                // clear the form input's value. I know, it's gross,
                // but that's Formik life.
                const newAdjustment = !addFinalAdjustmentChecked;
                if (!newAdjustment) {
                  props.form.setFieldValue(fieldName, "", true);

                  // Oh, it gets worse. I want the form input to be
                  // reset. So I use a counter that is used as the
                  // key of the DOM node below. Nasty, but works.
                  //
                  // Without this, the value won't be reset, so will
                  // /display/ as if the value is still there, but it
                  // won't be when we submit. Hell is other people's
                  // libraries.
                  setIncremented(incremented + 1);
                }
                setAddFinalAdjustmentChecked(newAdjustment);
              }}
            />
            Final adjustment
            {addFinalAdjustmentChecked && (
              <span>
                {" "}
                (reminder: use <code>result</code>):
              </span>
            )}
          </label>
        </p>
        <div className={classN}>
          <Field
            key={incremented}
            name={fieldName}
            type="text"
            projectionDatapoints={(_: any) => []}
            projection={props.projection}
            component={ExpressionInput}
            suffix="final"
            index={index}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export type ConvertedAggregation =
  | ({
      type: "Grouped";
      id: number;
      projections: Projection[];
      values: { [key in number]: Scalar }[]; // Rows of: map from projection id to value
      brossaValue: string;
      triggers: AggregationTrigger[];
    } & Omit<PrimitiveAggregation, "projections">)
  | ({ type: "Custom" } & BrossaAggregation);

export type NewAggregation =
  | (NewBrossaAggregation & { type: "Brossa" })
  | (PrimitiveAggregation & { type: "Primitive" });

export const toConvertedAggregation = (
  agg: BrossaAggregation | PrimitiveAggregationOutput,
): ConvertedAggregation => {
  if (hasOwnProperty(agg, "initExpr")) {
    return { type: "Custom", ...(agg as BrossaAggregation) };
  }

  // Primitive aggregation
  const projections = agg.aggregation.projections.map(({ projection, projectAs, index }) => {
    switch (projection.tag) {
      case "GroupByProjection": {
        return {
          operation: "Group By" as const,
          datapoint: projection.contents.datapoint,
          projectAs,
          index,
        };
      }
      case "OperationProjection": {
        const operation = (() => {
          switch (projection.contents.operation) {
            case "MinOperation":
              return "Min" as const;
            case "MaxOperation":
              return "Max" as const;
            case "AverageOperation":
              return "Average" as const;
            case "ProductOperation":
              return "Product" as const;
            case "SumOperation":
              return "Sum" as const;
          }
        })();
        return {
          operation,
          datapoint: projection.contents.argument,
          finalExpr: projection.contents.finalExpr,
          projectAs,
          index,
        };
      }
      case "CountProjection": {
        return {
          operation: "Count" as const,
          projectAs,
          index,
        };
      }
    }
  });
  return {
    ...agg.aggregation,
    type: "Grouped",
    id: agg.aggregationId,
    projections,
    values: agg.datums,
    brossaValue: agg.brossaValue,
    triggers: agg.triggers,
  };
};

function toAPIProjection(projection: {
  operation: string;
  datapoint?: string;
  projectAs?: string;
  finalExpr?: string;
}): Either<string, APIProjection> {
  if (projection.operation === "Count") {
    return new Right({
      tag: "CountProjection" as const,
    });
  }
  if (projection.datapoint === undefined) {
    return new Left("Datapoint has to be defined for projections other than count");
  }

  const operationProjection = (
    (datapoint: string) =>
    (operation: ProjectionOperation): Either<string, APIProjection> => {
      const trimmedFinalExpr = (projection.finalExpr ?? "").trim();
      return new Right({
        tag: "OperationProjection" as const,
        contents: {
          operation,
          argument: datapoint,
          finalExpr: trimmedFinalExpr == "" ? undefined : trimmedFinalExpr,
        },
      });
    }
  )(projection.datapoint);

  switch (projection.operation) {
    case "Sum": {
      return operationProjection("SumOperation" as const);
    }
    case "Average": {
      return operationProjection("AverageOperation" as const);
    }
    case "Product": {
      return operationProjection("ProductOperation" as const);
    }
    case "Min": {
      return operationProjection("MinOperation" as const);
    }
    case "Max": {
      return operationProjection("MaxOperation" as const);
    }
    case "Group By": {
      return new Right({
        tag: "GroupByProjection" as const,
        contents: {
          datapoint: projection.datapoint,
        },
      });
    }
    default:
      return new Left("Unknown operation " + projection.operation);
  }
}

function toWidgetType(s: string): Either<string, Widget> {
  const result: Widget | undefined = (() => {
    switch (s) {
      case "Bar":
        return "Bar";
      case "World Map":
        return "Choropleth";
      case "Table":
        return "Table";
      case "Counter":
        return "Counter";
      case "Raw":
        return "Raw";
    }
  })();
  if (result === undefined) {
    return new Left("Unknown widget type " + s);
  }
  const _typecheck = (() => {
    switch (result) {
      case "Bar":
      case "Choropleth":
      case "Table":
      case "Counter":
      case "Raw":
        return;
      default:
        assertNever(result);
    }
  })();
  return new Right(result);
}

export const TriggerForm: React.FC<{
  initialFields: Partial<AggregationTrigger>;
  onSubmit: (fields: AggregationTrigger) => Promise<string | undefined>;
  title: string;
  products: Product[];
  initialError?: string;
}> = (props) => {
  const history = useHistory();
  const backToTriggers = () => {
    history.push(
      Routes.portfolioTabs.generatePath({
        capture: { tab: "triggers" },
      }),
    );
  };
  const [error, setError] = React.useState<null | string>(props.initialError ?? null);
  // The product ID implied by the aggregation, if any.
  const [impliedProductId, setImpliedProductId] = React.useState<number | undefined>();
  const primitiveAggregationsResp = useGetPrimitiveAggregations();
  throwIfAppError(primitiveAggregationsResp);
  const primitiveAggregations = primitiveAggregationsResp.value.outputs.map(toConvertedAggregation);
  const aggregationsResp = useGetAggregations({});
  throwIfAppError(aggregationsResp);
  const aggregations = aggregationsResp.value.aggregations
    .map(toConvertedAggregation)
    .concat(primitiveAggregations)
    .filter((agg) => agg.productId !== undefined);
  const validationSchema = Yup.object({
    name: Yup.string().required("Field required."),
    aggregation: Yup.string().required("Field required."),
    condition: Yup.string().required("Field required."),
    message: Yup.string().required("Field required."),
    triggerType: Yup.mixed().oneOf(["Refer", "Decline"]).required("Field required."),
  });
  const splitError = error !== null ? error.split("\n") : null;
  const aggregation = (() => {
    const initialAgg = props.initialFields.aggregation;
    if (initialAgg === undefined) {
      return "";
    }
    const findType = initialAgg.tag === "ScriptingAggregationId" ? "Custom" : "Grouped";
    return (
      aggregations.find((agg) => agg.type === findType && agg.id === initialAgg.contents)?.name ??
      ""
    );
  })();
  return (
    <div data-testid="trigger-form">
      <div className="p-8 font-medium">
        <Link
          to={Routes.portfolioTabs.generatePath({ capture: { tab: "triggers" } })}
          className="text-gray-500 hover:text-gray-400 leading-none"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2 inline-block" />
          Back to portfolio
        </Link>
      </div>
      <Formik
        initialValues={{
          name: props.initialFields.name ?? "",
          aggregation,
          condition: props.initialFields.condition ?? "",
          message: props.initialFields.message ?? "",
          triggerType: props.initialFields.triggerType ?? "Refer",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, form) => {
          try {
            setError(null);
            if (impliedProductId === undefined) {
              throw Error("Product undefined");
            }
            const aggregation = (() => {
              const agg = aggregations.find((agg) => agg.name === values.aggregation);
              if (agg === undefined) {
                // Shouldn't happen
                throw Error(`Unknown aggregation {values.aggregation}`);
              }
              if (agg.type === "Grouped") {
                return {
                  tag: "PrimitiveAggregationId" as const,
                  contents: agg.id,
                };
              } else {
                return {
                  tag: "ScriptingAggregationId" as const,
                  contents: agg.id,
                };
              }
            })();
            const result = await props.onSubmit({
              ...values,
              aggregation,
              productId: impliedProductId,
            });
            if (result !== undefined) {
              setError(result);
              return;
            }
            form.resetForm();
            backToTriggers();
          } catch (e) {
            setError("Something went wrong.");
          }
        }}
      >
        {(form) => {
          const newAggregation = form.values.aggregation;
          useEffect(() => {
            const aggregation = aggregations.find(
              (aggregation) => aggregation.name == newAggregation,
            );
            if (aggregation !== undefined && aggregation !== null) {
              setImpliedProductId(aggregation.productId);
            }
          }, [newAggregation]);
          return (
            <Form noValidate>
              <div className="flex-1 w-screen-md">
                <div className="text-xl font-bold px-8">{props.title}</div>
                <div className="flex-1 px-8 pt-4 space-y-4 overflow-y-auto">
                  {splitError !== null && splitError.length > 0 && (
                    <div className="bg-red-50 px-4 py-2 text-red-700 rounded whitespace-normal">
                      {splitError.map((e) => (
                        <p> {e} </p>
                      ))}
                    </div>
                  )}
                  <div className="space-y-1">
                    <Label>Name</Label>
                    <Field
                      type="text"
                      name="name"
                      component={FormikInput}
                      data-testid="trigger-name"
                    />
                    {form.submitCount > 0 &&
                      form.touched.name &&
                      form.errors.name !== undefined && <FormError>{form.errors.name}</FormError>}
                  </div>
                  <div className="space-y-1">
                    <Label>Aggregation name</Label>
                    <Field
                      type="text"
                      name="aggregation"
                      component={FormikSelect}
                      data-testid="trigger-aggregation"
                    >
                      <option value="" key="foo"></option>
                      {aggregations.map((aggregation) => {
                        return (
                          <option value={aggregation.name} key={aggregation.name}>
                            {aggregation.name}
                          </option>
                        );
                      })}
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.aggregation &&
                      form.errors.aggregation !== undefined && (
                        <FormError>{form.errors.name}</FormError>
                      )}
                  </div>
                  <div className="space-y-1" data-testid="trigger-condition-expression">
                    <Label>Condition</Label>
                    <Field type="text" name="condition" component={FormikCodeMirror} />
                    {form.submitCount > 0 &&
                      form.touched.condition &&
                      form.errors.condition !== undefined && (
                        <FormError>{form.errors.condition}</FormError>
                      )}
                  </div>
                  <div className="space-y-1">
                    <Label>Action Message</Label>
                    <Field
                      type="text"
                      name="message"
                      component={FormikInput}
                      data-testid="trigger-message"
                    />
                    {form.submitCount > 0 &&
                      form.touched.message &&
                      form.errors.message !== undefined && (
                        <FormError>{form.errors.message}</FormError>
                      )}
                  </div>
                  <div className="space-y-1">
                    <Label>Action</Label>
                    <Field type="text" name="triggerType" component={FormikSelect}>
                      <option value="Refer" key="Refer">
                        Refer
                      </option>
                      <option value="Decline" key="Decline">
                        Decline
                      </option>
                    </Field>
                    {form.submitCount > 0 &&
                      form.touched.triggerType &&
                      form.errors.triggerType !== undefined && (
                        <FormError>{form.errors.triggerType}</FormError>
                      )}
                  </div>
                  <div style={{ height: 0 }}></div>
                </div>
                <div className="px-8 pb-8 pt-4 flex space-x-2 justify-end">
                  <Button variant="secondary" size="sm" onClick={backToTriggers}>
                    Cancel
                  </Button>
                  <Button type="submit" size="sm" isPending={form.isSubmitting}>
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const DeleteTriggerDialog: React.FC<{
  trigger: number;
}> = (props) => {
  type DeletionState =
    | { state: "waiting" } // Waiting for the user to hit Delete
    | { state: "confirming" } // Showing the user the confirmation dialog
    | { state: "deleting" }; // The delete request is in flight

  const [deletionState, setDeletionState] = React.useState<DeletionState>({ state: "waiting" });

  const performDeletion = async (): Promise<void> => {
    await apiDelete("/api/stats/aggregation/triggers/{id}", {
      params: { id: props.trigger },
      query: {},
    });
    await invalidatePath("/api/stats/aggregation/triggers");
    return;
  };

  useEffect(() => {
    if (deletionState.state === "deleting") {
      // `useEffect` can't be an async function
      // but `performDeletion` is an async function.
      // So by using `void` operator we let the developer know
      // that we explicitly allow the promise to run in the background
      // without waiting for it's result
      void performDeletion();
    }
  }, [deletionState]);

  return (
    <>
      <Button variant="secondary" onClick={() => setDeletionState({ state: "confirming" })}>
        Delete
      </Button>
      <ConfirmationModal
        title="Delete aggregation trigger"
        isOpen={deletionState.state === "confirming" || deletionState.state === "deleting"}
        isAwaiting={deletionState.state === "deleting"}
        onClose={() => setDeletionState({ state: "waiting" })}
        onConfirm={() => setDeletionState({ state: "deleting" })}
        confirmButtonLabel="Delete"
      >
        <p>
          Are you sure you want to permanently delete this aggregation trigger? This cannot be
          reversed.
        </p>
      </ConfirmationModal>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Expression input component

const ExpressionInput = ({
  suffix,
  projectionDatapoints,
  projection,
  field,
  form,
  index,
}: {
  projectionDatapoints: (projection: Projection) => {
    key: {
      base: string;
      meta?: string | undefined;
    };
    unit: {
      [key: string]: number;
    };
  }[];
  projection: Projection;
  field: { value: string; name: string };
  index: number;
  suffix: string;
  form: { setFieldValue: (name: string, value: string, validate: boolean) => void };
}) => {
  const [commandPusher, _] = React.useState<CommandPusher>(makeCommandPusher());
  const withDatapoints = projectionDatapoints(projection).length != 0;
  return (
    <div className="flex flex-row space-x-1" data-testid={"expression-area-" + index + suffix}>
      <div className="grow border-gray-300 border rounded shadow-sm overflow-hidden">
        <CodeMirror
          initialText={field.value}
          onTextChange={(getText) => form.setFieldValue(field.name, getText(), true)}
          readOnly={false}
          commandPusher={commandPusher}
          autocompletionNames={projectionDatapoints(projection).map((dp) => dp.key.base)}
        />
      </div>
      {withDatapoints && (
        <Dropdown label="Datapoint" itemsChecked={0}>
          {projectionDatapoints(projection).map((dp, index) => (
            <DropdownItem
              testid={"datapoint-item-" + index}
              key={dp.key.base}
              label={dp.key.base}
              onClick={function () {
                commandPusher.push({ tag: "Insert", content: dp.key.base });
              }}
            />
          ))}
        </Dropdown>
      )}
    </div>
  );
};
