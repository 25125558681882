/**
 * This module is a small routing library
 * that has been partially inspired by [Servant]{@link https://hackage.haskell.org/package/servant}
 *
 * It tries to solve our usecases by using type safe
 * routes that are defined using constant arrays.
 *
 * The function {@link route} generates a route that can
 * be used with hook {@link useParams}.
 *
 * @module
 */
import { toNumber, filterMap } from "../utils";
import { Location } from "history";
import {
  useHistory,
  useLocation,
  useParams as _useParams,
  generatePath as _generatePath,
} from "react-router";
import React from "react";

/**
 * Type that denotes an encoder and decoder in one
 * For the example see {@link number}
 *
 * @typeparam Type - a generic input type
 */
export type EncoderDecoder<Type> = {
  decoder: (s: string) => Type | undefined;
  encoder: (v: NonNullable<Type>) => string;
};
export const string: EncoderDecoder<string> = { decoder: (x) => x, encoder: (x) => x };
export const number: EncoderDecoder<number> = {
  decoder: (x) => {
    const v = toNumber(x);
    return v !== null ? v : undefined;
  },
  encoder: (x) => x.toString(),
};
export const boolean: EncoderDecoder<boolean> = {
  decoder: (x) => x === "true",
  encoder: (x) => x.toString(),
};
export const enumED = <S extends string, K extends ReadonlyArray<S>>(
  enums: K,
): EncoderDecoder<K[number]> => {
  return {
    decoder: (x) => {
      return enums.includes(x as K[number]) ? (x as K[number]) : undefined;
    },
    encoder: (x) => x,
  };
};

/**
 * Helper type that is used by all params
 *
 * @typeparam Name - a constant string, which denotes a name for the param
 * @typeparam Type - a type of the param, used here for {@link EncoderDecoder}
 */
type Param<Name extends string, Type> = {
  name: Name;
  /**
   * if a label is provided, then a given param will
   * have it's value when shown in url
   */
  label?: string;
} & EncoderDecoder<Type>;

/**
 * Gets a label from a {@link Param}
 */
const getLabel = <K extends string>(p: Param<K, any>): string => p.label ?? p.name;

/**
 * const used for all occurrences of {@link Capture}
 * both in type and as a value
 */
const captureT = "capture" as const;
/**
 * Type that captures the route parameter.
 * The naming is inspired by [Servant]{@link https://hackage.haskell.org/package/servant-0.19/docs/Servant-API-Capture.html}
 */
type Capture<K extends string, T> = {
  type: typeof captureT;
} & Param<K, T>;

/**
 * A function that creates a {@link Capture}
 */
export function capture<Name extends string, T>(
  name: Name,
  encoderDecoder: EncoderDecoder<T>,
  label?: string,
): Capture<Name, T> {
  return {
    type: captureT,
    name,
    label,
    ...encoderDecoder,
  };
}

/**
 * const used for all occurrences of {@link QueryParam}
 * both in type and as a value
 */
const queryParamT = "queryParam" as const;

/**
 * Type that denotes the query parameter.
 * The naming is inspired by [Servant]{@link https://hackage.haskell.org/package/servant-0.19/docs/Servant-API-QueryParam.html#t:QueryParam}
 */
type QueryParam<K extends string, T> = {
  type: typeof queryParamT;
} & Param<K, T>;

/**
 * A function that creates a {@link QueryParam}
 */
export function queryParam<Name extends string, T>(
  name: Name,
  encoderDecoder: EncoderDecoder<T>,
  label?: string,
): QueryParam<Name, T> {
  return {
    type: queryParamT,
    name,
    label,
    ...encoderDecoder,
  };
}

/**
 * const used for all occurrences of {@link QueryParams}
 * both in type and as a value
 */
const queryParamsT = "queryParams" as const;
/**
 * Type that denotes the query parameter that holds a list of values.
 * The naming is inspired by [Servant]{@link https://hackage.haskell.org/package/servant-0.19/docs/Servant-API-QueryParam.html#t:QueryParams}
 */
type QueryParams<K extends string, T> = {
  type: typeof queryParamsT;
} & Param<K, T>;

/**
 * A function that creates a {@link QueryParams}
 */
export function queryParams<Name extends string, T>(
  name: Name,
  encoderDecoder: EncoderDecoder<T>,
  label?: string,
): QueryParams<Name, T> {
  return {
    type: queryParamsT,
    name,
    label,
    ...encoderDecoder,
  };
}

/**
 * Type that denotes a route specification
 * in a similar way to [Servant]{@link https://docs.servant.dev/en/stable/tutorial/ApiType.html#a-web-api-as-a-type}
 *
 * But here we are using an array instead of Combinators
 *
 * The name strings should be alpha numeric
 */
export type RouteSpecification = readonly (
  | string
  | Capture<any, any>
  | QueryParam<any, any>
  | QueryParams<any, any>
)[];

/**
 * Type that denotes a route
 */
export type Route<R extends RouteSpecification> = {
  route: R;
  generatePath: (args: RouteArgumentsNeeded<R>, state?: any) => Path;
  generateRouterString: () => string;
  exact: boolean;
};

/**
 * Main function for creating [Routes]{@link Route}
 */
export const route = <R extends RouteSpecification>(r: R, exact = true): Route<R> => {
  const path: string[] = [];
  r.forEach((x) => {
    if (typeof x === "string") {
      path.push(encodeURIComponent(x));
      return;
    }
    switch (x.type) {
      case captureT: {
        path.push(`:${encodeURIComponent(getLabel(x))}`);
        break;
      }
      default:
        break;
    }
  });
  const routerString = `/${path.join("/")}`;

  const generatePath = (args: RouteArgumentsNeeded<R>, state: any) => {
    const untypedArgs = args as { capture?: Record<string, any>; query?: Record<string, any> };
    const queryParams: Record<string, string[] | string> = {};
    const captures: Record<string, string> = {};
    r.forEach((x) => {
      if (typeof x === "string") {
        return;
      }
      switch (x.type) {
        case captureT: {
          if (untypedArgs?.capture === undefined) {
            break; // should not happen
          }
          captures[encodeURIComponent(getLabel(x))] = x.encoder(untypedArgs.capture[x.name]);
          break;
        }
        case queryParamT: {
          if (untypedArgs?.query === undefined) {
            break; // should not happen
          }
          const v = untypedArgs.query[x.name];
          if (v !== undefined) {
            queryParams[getLabel(x)] = x.encoder(v); // no need for encodeURIComponent since bulidPath will take care of it
          }
          break;
        }
        case queryParamsT: {
          if (untypedArgs?.query === undefined) {
            break; // should not happen
          }
          const v = untypedArgs.query[x.name] as any[] | undefined;
          if (v !== undefined && v.length !== 0) {
            queryParams[getLabel(x)] = v.map(x.encoder);
          }
          break;
        }
      }
    });
    const location = _generatePath(routerString, captures);
    return buildPath(location, queryParams, state);
  };

  return {
    route: r,
    generatePath,
    generateRouterString: () => routerString,
    exact: exact,
  };
};

/** Helper type that denotes a Path for a page */
export type Path = Location;

/** Helper type that denotes parameters needed to build a path */
export type HelperPathParams = Record<string, string | number | boolean | string[] | undefined>;

/**
 * Helper function that builds a path.
 * @param location location for a path
 * @param params parameters for a path
 * @returns Path accepted by history.push or Router.location
 *
 * @todo remove the "export", so its used only in this module
 */
export const buildPath = (location: string, params?: HelperPathParams, state?: any): Path => {
  const query = new URLSearchParams();
  params !== undefined &&
    Object.entries(params).forEach(([k, v]) => {
      if (v === undefined) {
        return;
      }
      typeof v === "string"
        ? query.append(k, v)
        : typeof v === "number" || typeof v === "boolean"
        ? query.append(k, `${v}`)
        : v.forEach((elem) => query.append(k, elem));
    });
  return {
    pathname: location,
    search: query.toString(),
    state,
    hash: "",
  };
};

/**
 * Helper type that creates the final {@link RouteArguments} type
 *
 * It is an iterator that takes
 * @typeparam CaptureObj - an object type that holds the {@link Capture} types
 * @typeparam QueryParamsObj - an object type that holds the {@link QueryParam} and {@link QueryParams} types
 * @typeparam R - a current route
 */
type RouteArgumentsIterator<CaptureObj, QueryParamsObj, R> = R extends readonly [
  infer X,
  ...infer Rest,
]
  ? X extends string
    ? RouteArgumentsIterator<CaptureObj, QueryParamsObj, Rest>
    : X extends Capture<infer Name, infer Result>
    ? RouteArgumentsIterator<{ [k in Name]: Result } & CaptureObj, QueryParamsObj, Rest>
    : X extends QueryParam<infer Name, infer Result>
    ? RouteArgumentsIterator<
        CaptureObj,
        { [k in Name]?: Result | undefined } & QueryParamsObj,
        Rest
      >
    : X extends QueryParams<infer Name, infer Result>
    ? RouteArgumentsIterator<
        CaptureObj,
        { [k in Name]?: Result[] | undefined } & QueryParamsObj,
        Rest
      >
    : never
  : R extends readonly []
  ? { query: QueryParamsObj; capture: CaptureObj }
  : never;

/**
 * This type denotes an object that has no keys
 */
type EmptyObject = Record<string, never>;

/**
 * Type that returns Y if R is empty, and N if it's not
 */
type IsEmpty<R, Y, N> = keyof R extends never ? Y : N;

/**
 * helper const that is used to get an empty object type in {@link RouteArguments}
 */
const emptyObject = {} as const;

/**
 * Type that gathers captures and queries
 */
export type RouteArguments<R> = RouteArgumentsIterator<typeof emptyObject, typeof emptyObject, R>;

/**
 * Return type for {@link useParams}
 */
export type UseParamsReturnType<R> = RouteArguments<R> extends {
  capture: infer C;
  query: infer Q;
}
  ? {
      data: { query: Q; capture: C };
      setQuery: (
        args?: Partial<IsEmpty<Q, EmptyObject, Q>>,
        options?: QueryParamsChangeOptions,
      ) => void;
      setCapture: (args: Partial<IsEmpty<C, EmptyObject, C>>) => void;
    }
  : never;

/**
 * This type is needed for {@link generatePath} function
 *
 * The subtle difference with {@link RouteArgumentsReturned}
 * is that queryParameters can be empty here
 */
type RouteArgumentsNeeded<R> = RouteArguments<R> extends {
  capture: infer C;
  query: infer Q;
}
  ? IsEmpty<
      C,
      IsEmpty<Q, EmptyObject, { query?: Q }>,
      IsEmpty<Q, { capture: C }, { capture: C; query?: Q }>
    >
  : never;

/**
 * Type that defines possible options for {@link setQueryParams}
 */
type QueryParamsChangeOptions = {
  /**
   * if true then it will set all other query parameters to undefined
   */
  removeAll?: boolean;
};

/**
 * useParams hook, that deals with all of the data changes
 *
 * @todo add default param values
 */
export const useParams = <R extends RouteSpecification>(
  route: Route<R>,
): UseParamsReturnType<R> => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const untypedParams: Record<string, string | undefined> = _useParams();
  const capture: Record<string, any> | undefined = {};
  const query: Record<string, any> | undefined = {};
  route.route.forEach((x) => {
    if (typeof x === "string") {
      return;
    }
    switch (x.type) {
      case captureT: {
        const value = untypedParams[getLabel(x)];
        if (value === undefined) {
          throw new Error(
            `Routing error: Parameter :${x.name} does not exist in ${
              location.search
            }, the location should be of a shape ${route.generateRouterString()}`,
          );
        }
        const typedValue = x.decoder(value);
        if (typedValue === undefined) {
          throw new Error(
            `Routing error: Parameter ${x.name} has parameter of a wrong type in ${location.search}: ${value}`,
          );
        }
        capture[x.name] = typedValue;
        break;
      }
      case queryParamT: {
        const value = searchParams.get(getLabel(x));
        const typedValue = value !== null ? x.decoder(value) : undefined;
        query[x.name] = typedValue;
        break;
      }
      case queryParamsT: {
        const value = searchParams.getAll(getLabel(x));
        const typedValue = filterMap(value, x.decoder);
        query[x.name] = typedValue;
        break;
      }
    }
  });
  const [res, setRes] = React.useState<UseParamsReturnType<R>["data"]>({
    capture,
    query,
  } as UseParamsReturnType<R>["data"]);
  React.useEffect(() => {
    setRes({ capture, query } as UseParamsReturnType<R>["data"]);
  }, [location.search, location.pathname]);

  const setCapture: UseParamsReturnType<R>["setCapture"] = (args) => {
    const untypedArgs = args === undefined ? {} : (args as Record<string, any>);
    const capture_ = (res.capture ?? {}) as Record<string, any>;
    const captureResult = { ...capture_ } as Record<string, any>; // copy the object
    if (untypedArgs !== undefined) {
      const untypedCapture = untypedArgs as Record<string, any>;
      Object.entries(untypedCapture).forEach(([k, v]) => {
        captureResult[k] = v;
      });
    }
    const newRes = { ...res, capture: captureResult };
    history.push(route.generatePath(newRes as unknown as RouteArgumentsNeeded<R>));
    setRes(newRes as RouteArguments<R>);
  };
  const setQuery: UseParamsReturnType<R>["setQuery"] = (args, options) => {
    const untypedArgs = args === undefined ? {} : (args as Record<string, any>);
    const query_ = (res.query ?? {}) as Record<string, any>;
    const queryParamsResult = (options?.removeAll === true ? {} : { ...query_ }) as Record<
      string,
      any
    >;
    if (untypedArgs !== undefined) {
      const untypedQuery = untypedArgs as Record<string, any>;
      Object.entries(untypedQuery).forEach(([k, v]) => {
        queryParamsResult[k] = v;
      });
    }
    const newRes = { ...res, query: queryParamsResult };
    history.push(route.generatePath(newRes as unknown as RouteArgumentsNeeded<R>));
    setRes(newRes as RouteArguments<R>);
  };
  /**
   * Type '{ data: UseParamsReturnType<R>["data"]; setCapture: UseParamsReturnType<R>["setCapture"]; setQuery: UseParamsReturnType<R>["setQuery"]; }' is not assignable to type 'UseParamsReturnType<R>'
   *
   * Nice error message, 'as' is needed here
   */
  return { data: res, setCapture, setQuery } as UseParamsReturnType<R>;
};

/**
 * This const consists of things that we don't want to export
 * but we want to test
 *
 * There are ways of achieving that via [rewire](https://www.npmjs.com/package/rewire)
 * but I had troubles with making it work. It does not really like jest/ts-node.
 */
export const __exportedForTesting = {
  getLabel,
  captureT,
  queryParamT,
  queryParamsT,
};
