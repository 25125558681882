import React from "react";
import { Modal } from "../design_system/Modal";
import { Button } from "../design_system/Button";

/**
 * Similar to {@link ConfirmationModal2}, but merely informative, so it lacks a
 * "Cancel" button.
 * @param props
 * @returns
 */
export const NotificationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  confirmButtonLabel?: string;
}> = (props): JSX.Element => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="p-8 flex flex-col gap-5">
        <div>
          <p className="text-xl font-bold">{props.title}</p>
        </div>
        {props.children}
        <div className="flex flex-row justify-end gap-4">
          <Button onClick={props.onClose}>{props.confirmButtonLabel ?? "Ok"}</Button>
        </div>
      </div>
    </Modal>
  );
};
