import { AsyncTaskState } from "/src/asyncs";
import {
  ActionRequired,
  ActionsRequired,
  getQuoteReferConditions,
  IsWith,
  PolicyDerivedStatus,
  PolicyDerivedStatusFilter,
  PolicyInfo,
  ProductConfig,
  QuoteDerivedStatus,
  QuoteDerivedStatusFilter,
  QuoteInfo,
  Role,
} from "/src/internal_types";

export type PolicyState = PolicyDerivedStatus & {
  dataComplete: boolean;
  obstacles: Array<string>;
  hasReferredOrDeclinedQuotes: boolean;
  allQuotesNotTakenUpOrDeclined: boolean;
};

export const determinePolicyState = (policy: PolicyInfo): PolicyState => {
  const partial = policy.derivedStatus;
  const hasReferredOrDeclinedQuotes =
    policy.quotes.quotes.find(
      (q) => q.derivedStatus.tag === "Referred" || q.derivedStatus.tag === "Declined",
    ) !== undefined;
  const allQuotesNotTakenUpOrDeclined =
    policy.quotes.quotes.filter(
      (q) => q.derivedStatus.tag === "Declined" || q.derivedStatus.tag === "NotTakenUp",
    ).length === policy.quotes.quotes.length;
  return {
    ...partial,
    dataComplete: !policy.fields.submissionGoal.obstructed,
    obstacles: getObstacles(policy, "submission"),
    hasReferredOrDeclinedQuotes,
    allQuotesNotTakenUpOrDeclined,
  };
};

export type PartialQuoteState = QuoteDerivedStatus & {
  PolicyDerivedStatusName: PolicyDerivedStatus["tag"];
  isWith: undefined | IsWith;
};
const getObstacles = (node: PolicyInfo | QuoteInfo, goal: "submission" | "quote") => {
  const goalKeys = goal === "submission" ? node.fields.submissionGoal : node.fields.quoteGoal;
  return goalKeys.keys
    .filter((k) => k.name === goal)
    .flatMap((k) => k.datapoints.flatMap((dp) => dp.obstacles))
    .flatMap((o) => {
      switch (o.tag) {
        case "OViolation":
          return (
            goalKeys.keys
              .find((k) => o.contents.datapoint.key === k.name)
              ?.datapoints.flatMap((dp) =>
                dp.prompt.tag === "HasValue" ? dp.prompt.contents : o.contents.datapoint.key,
              ) ?? ["NotDefined"]
          );
        case "OMissingData":
          return (
            goalKeys.keys
              .find((k) => o.contents.key === k.name)
              ?.datapoints.flatMap((dp) =>
                dp.prompt.tag === "HasValue" ? dp.prompt.contents : o.contents.key,
              ) ?? ["NotDefined"]
          );
        case "OForbiddenAdjustment":
          return [o.contents];
        case "OError":
          return [o.contents];
        default:
          return ["NotDefined"];
      }
    })
    .filter((f) => f !== "NotDefined");
};

export type QuoteState = PartialQuoteState & {
  dataComplete: boolean;
  acceptDataComplete: boolean;
  rejectDataComplete: boolean;
  notTakenUpDataComplete: boolean;
  obstacles: Array<string>;
  hasReferralConditions: boolean;
  quote: QuoteInfo;
};

export const determinePartialQuoteState = (
  policy: PolicyInfo,
  quote: Pick<QuoteInfo, "derivedStatus">,
): PartialQuoteState => {
  const partialState = quote.derivedStatus;
  const PolicyDerivedStatusName = policy.derivedStatus.tag;
  const isWith =
    partialState.tag === "Accepted" || partialState.tag === "Referred"
      ? partialState.contents.isWith
      : undefined;
  return {
    ...partialState,
    isWith,
    PolicyDerivedStatusName,
  };
};

export const determineQuoteState = (quote: QuoteInfo, policy: PolicyInfo): QuoteState => {
  const partialState = determinePartialQuoteState(policy, quote);
  const hasReferralConditions = getQuoteReferConditions(quote).length > 0;
  return {
    ...partialState,
    dataComplete: !quote.fields.quoteGoal.obstructed,
    acceptDataComplete: !quote.fields.acceptGoal.obstructed,
    rejectDataComplete: !quote.fields.rejectGoal.obstructed,
    notTakenUpDataComplete: !quote.fields.notTakenUpGoal.obstructed,
    obstacles: getObstacles(quote, "quote"),
    hasReferralConditions,
    quote,
  };
};

export class Permission<constantState, variableState, permission> {
  constructor(constantState: constantState, p?: (vstate: variableState) => boolean) {
    this.p = p ?? (() => true);
    this.constantState = constantState;
  }
  protected p: (vstate: variableState) => boolean;
  protected constantState: constantState;
  public check(vstate: variableState): boolean {
    return this.p(vstate);
  }
  public and(
    next: Permission<constantState, variableState, permission>,
  ): Permission<constantState, variableState, permission> {
    return new Permission<constantState, variableState, permission>(
      this.constantState,
      (state) => this.p(state) && next.p(state),
    );
  }
  public or(
    next: Permission<constantState, variableState, permission>,
  ): Permission<constantState, variableState, permission> {
    return new Permission<constantState, variableState, permission>(
      this.constantState,
      (state) => this.p(state) || next.p(state),
    );
  }
  public negated(): Permission<constantState, variableState, permission> {
    return new Permission(this.constantState, (state) => !this.p(state));
  }
}

const Allow = <CS, VS, permission>(cs: CS): Permission<CS, VS, permission> => {
  return new Permission<CS, VS, permission>(cs, () => true);
};

const AsRole = <CS extends { currentRole: Role }, VS, permission>(
  cs: CS,
  role: Role,
): Permission<CS, VS, permission> => {
  return new Permission<CS, VS, permission>(cs, () => cs.currentRole === role);
};
const InState = <CS, VS extends { tag: string }, permission>(
  cs: CS,
  ss: Array<VS["tag"]>,
): Permission<CS, VS, permission> => {
  return new Permission<CS, VS, permission>(cs, (v: VS) => ss.includes(v.tag));
};

const AsBrokerIn = <CS extends { currentRole: Role }, VS extends { tag: string }, P>(
  cs: CS,
  st: VS["tag"][],
) => AsRole<CS, VS, P>(cs, "broker").and(InState<CS, VS, P>(cs, st));
const AsBrokerNotIn = <CS extends { currentRole: Role }, VS extends { tag: string }, P>(
  cs: CS,
  st: VS["tag"][],
) => AsRole<CS, VS, P>(cs, "broker").and(InState<CS, VS, P>(cs, st).negated());
const AsUnderwriterNotIn = <CS extends { currentRole: Role }, VS extends { tag: string }, P>(
  cs: CS,
  st: VS["tag"][],
) => AsRole<CS, VS, P>(cs, "underwriter").and(InState<CS, VS, P>(cs, st).negated());

const IsWith_ = <CS, VS extends PartialQuoteState, permission>(
  cs: CS,
  role: Role,
): Permission<CS, VS, permission> => {
  return new Permission<CS, VS, permission>(cs, (v: VS) => {
    return v?.isWith !== undefined && role === v.isWith.happened.happened.role;
  });
};

const IsWithCurrentUser = <
  CS extends { currentRole: Role },
  VS extends PartialQuoteState,
  permission,
>(
  cs: CS,
): Permission<CS, VS, permission> => {
  return IsWith_(cs, cs.currentRole);
};

const InGivenRole = <CS extends { currentRole: Role }, VS extends PartialQuoteState, permission>(
  cs: CS,
  ss: Array<VS["tag"]>,
): Permission<CS, VS, permission> => {
  return IsWithCurrentUser<CS, VS, permission>(cs).and(InState(cs, ss));
};

const InGivenRoleAs = <CS extends { currentRole: Role }, VS extends PartialQuoteState, permission>(
  cs: CS,
  ss: Array<VS["tag"]>,
  role: Role,
): Permission<CS, VS, permission> => {
  return InGivenRole(cs, ss).and(AsRole(cs, role));
};

// const HasReferralConditions = <CS, VS extends { hasReferralConditions: boolean }, permission>(
//   cs: CS,
// ): Permission<CS, VS, permission> => {
//   return new Permission<CS, VS, permission>(cs, (v) => v.hasReferralConditions);
// };

export type GlobalPolicyState = {
  productConfig: ProductConfig;
} & GlobalState;

type Permission_<VS, P> = Permission<GlobalPolicyState, VS, P>;

// Can't edit anything if the policy state is "Rejected", "NotTakenUp" or "Bound"
const CanEditQuoteAccordingToPolicyState = <
  CS extends GlobalState,
  VS extends { PolicyDerivedStatusName: PolicyDerivedStatus["tag"] },
  permission,
>(
  cs: CS,
): Permission<CS, VS, permission> =>
  new Permission<CS, VS, any>(
    cs,
    (state) => !["Rejected", "NotTakeUp", "Bound"].includes(state.PolicyDerivedStatusName),
  ); // has to be changed when we do endorsements

const DataComplete = <VS extends { dataComplete: boolean }, permission>(
  cs: GlobalPolicyState,
): Permission_<VS, permission> => new Permission(cs, (state) => state.dataComplete === true);

export class EditP {}
export const CanEditPolicy = (cs: GlobalPolicyState): Permission_<PolicyState, EditP> =>
  AsBrokerIn<GlobalPolicyState, PolicyState, EditP>(cs, ["Draft"]);

export const CanEditQuoteData = (cs: GlobalPolicyState): Permission_<QuoteState, EditP> =>
  AsBrokerIn<GlobalPolicyState, QuoteState, EditP>(cs, ["Draft"])
    .or(InGivenRole(cs, ["Referred"]))
    .and(CanEditQuoteAccordingToPolicyState(cs));

export class PassP {}
export const PassingQuotesNotHidden = (cs: GlobalPolicyState): Permission_<QuoteState, PassP> =>
  new Permission(cs, () => cs.productConfig.frontend.passQuotesHidden === false);

export class SendToBrokerP {}
export const CanSendToBroker = (cs: GlobalPolicyState): Permission_<QuoteState, SendToBrokerP> =>
  InGivenRoleAs<GlobalPolicyState, QuoteState, SendToBrokerP>(
    cs,
    ["Referred", "Accepted"],
    "underwriter",
  )
    .and(PassingQuotesNotHidden(cs))
    .and(CanEditQuoteAccordingToPolicyState(cs));

export class SendToUnderwriterP {}
export const CanSendToUnderwriter = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, SendToUnderwriterP> =>
  InGivenRoleAs<GlobalPolicyState, QuoteState, SendToUnderwriterP>(
    cs,
    ["Referred", "Accepted"],
    "broker",
  )
    .and(PassingQuotesNotHidden(cs))
    .and(CanEditQuoteAccordingToPolicyState(cs));

export class AddP {}
export const CanShowAddNewQuotes = (cs: GlobalPolicyState): Permission_<PolicyState, Show<AddP>> =>
  AsBrokerIn<GlobalPolicyState, PolicyState, AddP>(cs, ["Draft", "Active"]);

export const CanAddNewQuotes = (cs: GlobalPolicyState): Permission_<PolicyState, AddP> =>
  CanShowAddNewQuotes(cs).and(DataComplete(cs));
export const CanAddNewPolicies = (cs: GlobalState): Permission<GlobalState, unknown, AddP> =>
  AsRole(cs, "broker");

const AllQuotesNotTakenUpOrDeclined = <
  CS,
  VS extends { allQuotesNotTakenUpOrDeclined: boolean },
  P,
>(
  cs: CS,
) => new Permission<CS, VS, P>(cs, (vs: VS) => vs.allQuotesNotTakenUpOrDeclined);

export class ArchiveP {}
export const CanArchivePolicy = (cs: GlobalPolicyState): Permission_<PolicyState, ArchiveP> =>
  AsBrokerNotIn<GlobalPolicyState, PolicyState, ArchiveP>(cs, [
    "Bound",
    "CancellationPending",
    "Archived",
  ]).and(AllQuotesNotTakenUpOrDeclined<GlobalPolicyState, PolicyState, ArchiveP>(cs));

export class DeleteP {}
export const CanDeletePolicy = (cs: GlobalPolicyState): Permission_<PolicyState, DeleteP> =>
  AsUnderwriterNotIn<GlobalPolicyState, PolicyState, DeleteP>(cs, ["Bound"]);

export class CancelP {}
export const CanCancelPolicy = (cs: GlobalPolicyState): Permission_<PolicyState, CancelP> =>
  AsBrokerIn<GlobalPolicyState, PolicyState, CancelP>(cs, ["Bound"]);

export class CloneP {}
export const CanClonePolicy = (cs: GlobalPolicyState): Permission_<PolicyState, CloneP> =>
  AsRole(cs, "broker");

export class PolicyImportP {}
export const CanImportIntoPolicy = (
  cs: GlobalPolicyState,
): Permission_<PolicyState, PolicyImportP> => AsRole(cs, "broker");

export class NotTakeUpP {}
export const CanShowNotTakeUpQuote = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, Show<NotTakeUpP>> =>
  AsBrokerNotIn<GlobalPolicyState, QuoteState, Show<NotTakeUpP>>(cs, [
    "NotTakenUp",
    "Declined",
    "Bound",
    "Referred",
  ])
    .or(InGivenRoleAs(cs, ["Referred"], "broker"))
    .and(CanEditQuoteAccordingToPolicyState(cs));

export const CanNotTakeUpQuote = (cs: GlobalPolicyState): Permission_<QuoteState, NotTakeUpP> =>
  CanShowNotTakeUpQuote(cs).and(new Permission(cs, (vs) => vs.notTakenUpDataComplete));

export class UndeclineP {}
export const CanUndeclineQuote = (cs: GlobalPolicyState): Permission_<QuoteState, UndeclineP> =>
  AsRole<GlobalPolicyState, QuoteState, UndeclineP>(cs, "underwriter").and(
    InState<GlobalPolicyState, QuoteState, UndeclineP>(cs, ["Declined"]),
  );

export class Show<_P> {}

export class SubmitP {}
export const CanShowSubmitQuote = (cs: GlobalPolicyState): Permission_<QuoteState, Show<SubmitP>> =>
  AsBrokerIn<GlobalPolicyState, QuoteState, SubmitP>(cs, ["Draft"]).and(
    CanEditQuoteAccordingToPolicyState(cs),
  );
export const CanSubmitQuote = (cs: GlobalPolicyState): Permission_<QuoteState, SubmitP> =>
  CanShowSubmitQuote(cs).and(DataComplete(cs));

export class AcceptP {}
export const CanShowAcceptQuote = (cs: GlobalPolicyState): Permission_<QuoteState, Show<AcceptP>> =>
  InGivenRoleAs<GlobalPolicyState, QuoteState, AcceptP>(cs, ["Referred"], "underwriter").and(
    CanEditQuoteAccordingToPolicyState(cs),
  );

export const CanAcceptQuote = (cs: GlobalPolicyState): Permission_<QuoteState, AcceptP> =>
  CanShowAcceptQuote(cs)
    .and(DataComplete(cs))
    .and(new Permission(cs, (vs) => vs.acceptDataComplete));

export class RejectP {}
export const CanShowRejectQuote = (cs: GlobalPolicyState): Permission_<QuoteState, Show<RejectP>> =>
  InGivenRoleAs<GlobalPolicyState, QuoteState, RejectP>(
    cs,
    ["Referred"], //there should be accept state as well, but its not working yet
    "underwriter",
  ).and(CanEditQuoteAccordingToPolicyState(cs));

export const CanRejectQuote = (cs: GlobalPolicyState): Permission_<QuoteState, RejectP> =>
  CanShowRejectQuote(cs).and(new Permission(cs, (vs) => vs.rejectDataComplete));

export class BindP {}
export const CanShowBindQuote = (cs: GlobalPolicyState): Permission_<QuoteState, Show<BindP>> =>
  InGivenRoleAs<GlobalPolicyState, QuoteState, BindP>(cs, ["Accepted"], "broker").and(
    CanEditQuoteAccordingToPolicyState(cs),
  );

export const CanBindQuote = (cs: GlobalPolicyState): Permission_<QuoteState, BindP> =>
  CanShowBindQuote(cs).and(DataComplete(cs));

export class ShowReferralHeaderP {}
export const CanShowReferralHeader = (cs: GlobalPolicyState) =>
  InGivenRole<GlobalPolicyState, PartialQuoteState, ShowReferralHeaderP>(cs, ["Referred"]);

export class ShowAcceptedCaptionP {}
export const CanShowAcceptedCaption = (cs: GlobalPolicyState) =>
  AsBrokerIn<GlobalPolicyState, PartialQuoteState, ShowAcceptedCaptionP>(cs, ["Accepted"]);

export class ShowReferralReasonsInHeaderP {}
export const CanShowReferralReasonsInHeader = (cs: GlobalPolicyState) =>
  InGivenRoleAs<GlobalPolicyState, PartialQuoteState, ShowReferralReasonsInHeaderP>(
    cs,
    ["Referred"],
    "underwriter",
  );

export class ShowDeclineReasonsInHeaderP {}
export const CanShowDeclineReasonsInHeader = (cs: GlobalPolicyState) =>
  InState<GlobalPolicyState, PartialQuoteState, ShowDeclineReasonsInHeaderP>(cs, ["Declined"]);

export class ShowConditionsWarningP {}
export const CanShowConditionsWarningPolicy = (cs: GlobalPolicyState) =>
  InState<GlobalPolicyState, PolicyDerivedStatus, ShowConditionsWarningP>(cs, [
    "Draft",
    "Active",
  ]).and(AsRole(cs, "broker"));
export const CanShowConditionsWarningQuote = (cs: GlobalPolicyState) =>
  InState<GlobalPolicyState, PartialQuoteState, ShowConditionsWarningP>(cs, ["Draft"]).and(
    AsRole(cs, "broker"),
  );

// Actions Required

export type ActionsRequiredMap = {
  [key in Role]: { [key in ActionRequired]?: Array<QuoteDerivedStatusFilter> };
};

export const actionsRequiredMap: ActionsRequiredMap = {
  broker: {
    awaiting_underwriter: ["QAcceptedWithUnderwriter", "QReferredWithUnderwriter"],
    needs_review: ["QReferredWithBroker"],
    ready_to_bind: ["QAcceptedWithBroker"],
  },
  underwriter: {
    awaiting_broker: ["QAcceptedWithBroker", "QReferredWithBroker"],
    needs_review: ["QAcceptedWithUnderwriter", "QReferredWithUnderwriter"],
  },
  admin: {
    awaiting_broker: ["QAcceptedWithBroker", "QReferredWithBroker"],
    awaiting_underwriter: ["QReferredWithUnderwriter", "QAcceptedWithUnderwriter"],
  },
  read_only: {
    awaiting_broker: ["QAcceptedWithBroker", "QReferredWithBroker"],
    awaiting_underwriter: ["QReferredWithUnderwriter", "QAcceptedWithUnderwriter"],
  },
};

export class QuoteDerivedStatusFilterPermission_<
  P extends QuoteDerivedStatusFilter,
> extends Permission<GlobalState, PartialQuoteState, P> {}

const QuoteDerivedStatusFilterPermission_Helper = (
  cs: GlobalState,
  quoteDerivedStatusFilter: QuoteDerivedStatusFilter,
): Permission<GlobalState, PartialQuoteState, QuoteDerivedStatusFilter> => {
  switch (quoteDerivedStatusFilter) {
    case "QAcceptedWithBroker":
      return InState<GlobalState, PartialQuoteState, "QAcceptedWithBroker">(cs, ["Accepted"]).and(
        IsWith_(cs, "broker"),
      );
    case "QAcceptedWithUnderwriter":
      return InState<GlobalState, PartialQuoteState, "QAcceptedWithUnderwriter">(cs, [
        "Accepted",
      ]).and(IsWith_(cs, "underwriter"));
    case "QDeclined":
      return InState<GlobalState, PartialQuoteState, "QDeclined">(cs, ["Declined"]);
    case "QNotTakenUp":
      return InState<GlobalState, PartialQuoteState, "QNotTakenUp">(cs, ["NotTakenUp"]);
    case "QDraft":
      return InState<GlobalState, PartialQuoteState, "QDraft">(cs, ["Draft"]);
    case "QReferredWithBroker":
      return InState<GlobalState, PartialQuoteState, "QReferredWithBroker">(cs, ["Referred"]).and(
        IsWith_(cs, "broker"),
      );
    case "QReferredWithUnderwriter":
      return InState<GlobalState, PartialQuoteState, "QReferredWithUnderwriter">(cs, [
        "Referred",
      ]).and(IsWith_(cs, "underwriter"));
    case "QBound":
      return InState<GlobalState, PartialQuoteState, "QBound">(cs, ["Bound"]);
  }
};

export const QuoteDerivedStatusFilterPermission_F = (
  cs: GlobalState,
  quoteDerivedStatusFilter: QuoteDerivedStatusFilter,
): Permission<GlobalState, PartialQuoteState, QuoteDerivedStatusFilter> => {
  const p = QuoteDerivedStatusFilterPermission_Helper(cs, quoteDerivedStatusFilter);
  return p.and(CanEditQuoteAccordingToPolicyState(cs));
};

export const actionsForRole = (currentUserRole: Role): Array<ActionRequired> => {
  const m = actionsRequiredMap[currentUserRole];
  return ActionsRequired.filter((a) => {
    return m[a];
  });
};

export const actionsRequiredForQuote = (
  cs: GlobalState,
  policy: PolicyInfo,
  quote: Pick<QuoteInfo, "derivedStatus">,
): Array<ActionRequired> => {
  const partialState = determinePartialQuoteState(policy, quote);
  const m = actionsRequiredMap[cs.currentRole];
  return ActionsRequired.filter((a) => {
    const l = m[a];
    if (l === undefined) return false;
    else {
      return (
        l.find((qdsf) => QuoteDerivedStatusFilterPermission_F(cs, qdsf).check(partialState)) !==
        undefined
      );
    }
  });
};

export const filtersForRequiredAction = (
  currentUserRole: Role,
  actionRequired: ActionRequired,
): {
  quoteStatuses: Array<QuoteDerivedStatusFilter>;
  policyStatuses: Array<PolicyDerivedStatusFilter>;
} => {
  const m = actionsRequiredMap[currentUserRole];
  const l = m[actionRequired];
  if (l === undefined) {
    return { quoteStatuses: [], policyStatuses: [] };
  } else {
    return { quoteStatuses: l, policyStatuses: ["PActive"] };
  }
};

export type GlobalState = { currentRole: Role };

export class SeeUnderwriterAssignmentP {}
export const CanSeeUnderwriterAssignment = (
  cs: GlobalState,
): Permission<unknown, unknown, SeeUnderwriterAssignmentP> =>
  AsRole(cs, "underwriter").or(AsRole(cs, "admin"));

export class SeeBrokerAssignmentP {}
export const CanSeeBrokerAssignment = (
  cs: GlobalState,
): Permission<unknown, unknown, SeeBrokerAssignmentP> =>
  AsRole(cs, "broker").or(AsRole(cs, "admin"));

export class SeeAdminAssignmentP {}
export const CanSeeAdminAssignment = (
  cs: GlobalState,
): Permission<unknown, unknown, SeeAdminAssignmentP> => Allow(cs).negated();

export class AssignToPolicyP {}
export const CanAssignToPolicyP = (
  cs: GlobalPolicyState,
): Permission_<PolicyState, AssignToPolicyP> => AsRole(cs, "underwriter");

export class GenerateQuoteFileP {}
export const CanGenerateQuoteFile = (
  filename: string,
): Permission<unknown, PartialQuoteState, GenerateQuoteFileP> => {
  return new Permission<unknown, PartialQuoteState, GenerateQuoteFileP>(undefined, (state) => {
    switch (filename) {
      case "documents.quote()":
        return state.tag === "Accepted";
      default:
        return false;
    }
  });
};

export class GenerateMultiQuoteFileP {}
export const CanGenerateMultiQuoteFile = (
  cs: GlobalPolicyState,
): Permission_<PartialQuoteState, GenerateMultiQuoteFileP> =>
  AsBrokerIn<GlobalPolicyState, PartialQuoteState, GenerateMultiQuoteFileP>(cs, ["Accepted"]).and(
    CanEditQuoteAccordingToPolicyState(cs),
  );

export class GeneratePolicyFileP {}
export const CanGeneratePolicyFile: Permission<unknown, PolicyDerivedStatus, GeneratePolicyFileP> =
  InState(undefined, ["Bound"]);

export class ShowBoundFieldsP {}
// this is assumming that the backend knows when to show the bind goal
// ie sends empty goal when we are not supposed to show it on the frontend
// the only difference is that be sends it when quote is accepted as well,
// then we want to show it only when "Request to bind" button is clicked
export const CanShowBoundFields = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, ShowBoundFieldsP> => InState(cs, ["Accepted"]).negated();

export class ShowAcceptedFieldsP {}
// this is assumming that the backend knows when to show the accept goal
// the only difference is that we don't want to show it while it is referred (be sends accept goal then)
// then it should be shown only when uw clicks accept
export const CanShowAcceptedFields = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, ShowAcceptedFieldsP> => InState(cs, ["Referred"]).negated();

export class ShowRejectedFieldsP {}
// this is assumming that the backend knows when to show the reject goal
// the only difference is that we don't want to show it while it is referred (be sends reject goal then)
// then it should be shown only when uw clicks reject
export const CanShowRejectedFields = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, ShowRejectedFieldsP> => InState(cs, ["Referred"]).negated();

export class ShowReferralStatusForDatapoints {}
export const CanShowReferralStatusForDatapointsForPolicy = (
  cs: GlobalPolicyState,
): Permission_<PolicyState, ShowReferralStatusForDatapoints> =>
  InState(cs, ["Active"]).and(AsRole(cs, "underwriter"));
export const CanShowReferralStatusForDatapointsForQuote = (
  cs: GlobalPolicyState,
): Permission_<QuoteState, ShowReferralStatusForDatapoints> =>
  InState(cs, ["Referred"]).and(AsRole(cs, "underwriter"));

export class AddUserP {}
export const CanAddUser = (cs: GlobalState): Permission<GlobalState, unknown, AddUserP> =>
  AsRole<GlobalState, unknown, AddUserP>(cs, "admin");

export class EditUserP {}
export const CanEditUser = (cs: GlobalState): Permission<GlobalState, unknown, EditUserP> =>
  AsRole<GlobalState, unknown, EditUserP>(cs, "admin");

export class ViewTasksP {}
export const CanViewTasks = (cs: GlobalState): Permission<GlobalState, unknown, ViewTasksP> =>
  AsRole<GlobalState, unknown, ViewTasksP>(cs, "admin");

// warning:
// this is a bit ugly. Overall probably it would be nicer for
// context to be the UnifiedTaskStatus tag. But because we have
// AsyncTaskState being used in all of the Promise's context,
// we have to use it (despite those two being closely related, almost the
// same)
type SequelHubTaskContext = { tag: AsyncTaskState["tag"] };

export class CancelSequelHubTaskP {}
// warning:
// here we rely on the fact that this permission is only used for
// brokers when they are able to edit the policy/quote
export const CanCancelSequelHubTask = (
  cs: GlobalState,
): Permission<GlobalState, SequelHubTaskContext, CancelSequelHubTaskP> =>
  AsRole<GlobalState, SequelHubTaskContext, CancelSequelHubTaskP>(cs, "admin")
    .or(AsRole<GlobalState, SequelHubTaskContext, CancelSequelHubTaskP>(cs, "broker"))
    .and(InState(cs, ["Running"]));

export class StartSequelHubTaskP {}
// warning:
// here we rely on the fact that this permission is only used for
// brokers when they are able to edit the policy/quote
export const CanStartSequelHubTask = (
  cs: GlobalState,
): Permission<GlobalState, SequelHubTaskContext, StartSequelHubTaskP> =>
  AsRole<GlobalState, SequelHubTaskContext, StartSequelHubTaskP>(cs, "admin")
    .or(AsRole<GlobalState, SequelHubTaskContext, StartSequelHubTaskP>(cs, "broker"))
    .and(InState(cs, ["NotRunning", "Failed"]));

export class SeeAggregationsP {}
export const CanSeeAggregations = (
  cs: GlobalState,
): Permission<GlobalState, unknown, SeeAggregationsP> =>
  AsRole<GlobalState, unknown, SeeAggregationsP>(cs, "admin").or(
    AsRole<GlobalState, unknown, SeeAggregationsP>(cs, "underwriter"),
  );

export class SeePortfolioP {}
export const CanSeePortfolio = (cs: GlobalState): Permission<GlobalState, unknown, SeePortfolioP> =>
  AsRole<GlobalState, unknown, SeePortfolioP>(cs, "admin").or(
    AsRole<GlobalState, unknown, SeePortfolioP>(cs, "underwriter"),
  );

export class AddAggregationP {}
export const CanAddAggregation = (
  cs: GlobalState,
): Permission<GlobalState, unknown, AddAggregationP> =>
  AsRole<GlobalState, unknown, AddAggregationP>(cs, "admin");

export class AddTriggerP {}
export const CanAddTrigger = (cs: GlobalState): Permission<GlobalState, unknown, AddTriggerP> =>
  AsRole<GlobalState, unknown, AddTriggerP>(cs, "admin");

export class SeeAdditionalFieldsP {}
export const CanSeeAdditionalFields = (
  cs: GlobalState,
): Permission<GlobalState, unknown, SeeAdditionalFieldsP> =>
  AsRole<GlobalState, unknown, SeeAdditionalFieldsP>(cs, "admin").or(
    AsRole<GlobalState, unknown, SeeAdditionalFieldsP>(cs, "underwriter"),
  );

export class SeeProductSpecP {}
export const CanSeeProductSpec = (
  cs: GlobalState,
): Permission<GlobalState, unknown, SeeProductSpecP> =>
  AsRole<GlobalState, unknown, SeeProductSpecP>(cs, "admin").or(
    AsRole<GlobalState, unknown, SeeProductSpecP>(cs, "underwriter"),
  );

export class DownloadSpecP {}
export const CanDownloadSpec = (cs: GlobalState): Permission<GlobalState, unknown, DownloadSpecP> =>
  AsRole<GlobalState, unknown, DownloadSpecP>(cs, "admin").or(
    AsRole<GlobalState, unknown, DownloadSpecP>(cs, "underwriter"),
  );

export class EditExtractionAdaptersP {}
export const CanEditExtractionAdapters = (
  cs: GlobalState,
): Permission<GlobalState, unknown, DownloadSpecP> =>
  AsRole<GlobalState, unknown, DownloadSpecP>(cs, "admin");

export class ExportPoliciesP {}
export const CanExportPolicies = (
  cs: GlobalState,
): Permission<GlobalState, unknown, ExportPoliciesP> =>
  AsRole(cs, "underwriter").or(AsRole(cs, "admin"));

export class CreateProductsP {}
export const CanCreateProducts = (
  cs: GlobalState,
): Permission<GlobalState, unknown, CreateProductsP> => AsRole(cs, "admin");

export class SeeAppetiteP {}
export const CanSeeAppetite = (
  cs: GlobalPolicyState,
): Permission<GlobalPolicyState, PolicyState, SeeAppetiteP> =>
  AsRole<GlobalPolicyState, PolicyState, SeeAppetiteP>(cs, "underwriter").or(AsRole(cs, "admin"));
