import React from "react";
import NumberFormat from "react-number-format";
import { generalEditorOrSummaryView, SingleNodeT_, useSingleNodeState } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { TextField } from "./text_component";

export interface PercentNodeT_ extends SingleNodeT_<number> {
  type: "percent";
}

export const PercentNode = (props: { node: PercentNodeT_ }): JSX.Element => {
  return generalEditorOrSummaryView(props.node, PercentNodeEditorView, PercentNodeSummaryView);
};

export const PercentNodeEditorView = (props: { node: PercentNodeT_ }): JSX.Element => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = () => {
    node.commit(localValue.trim() === "" ? null : +localValue);
  };

  // TODO: NumberFormat<any> is ugly but equivalent to the type in react-number-format before
  // https://github.com/s-yadav/react-number-format/commit/1b6d2f39817cd2a8995980bec7a4caad1b6322b2
  return (
    <LeafNode node={node}>
      <NumberFormat<any>
        value={localValue}
        customInput={TextField}
        onValueChange={(e) => {
          setLocalValue(e.value);
        }}
        onBlur={handleOnBlur}
        disabled={node.readOnly}
        suffix="%"
        node={node}
      />
    </LeafNode>
  );
};

export const PercentNodeSummaryView = (props: { node: PercentNodeT_ }): JSX.Element => {
  return (
    <SummaryLeafStringNode
      label={props.node.label}
      contents={[props.node.value !== null ? `${props.node.value}%` : ""]}
    />
  );
};
