import clsx from "clsx";
import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import * as Popover from "@radix-ui/react-popover";

function inputClasses(disabled: boolean): string {
  return clsx(
    "border-0",
    "grow",
    "sm:text-sm",
    disabled && "bg-gray-50",
    disabled && "text-gray-500",
    disabled && "bg-none",
  );
}

const WithTooltip: React.FC<{
  disabled: boolean;
  disabledHint?: string;
}> = (props): JSX.Element => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  return (
    <div
      className={clsx(
        "shadow-sm",
        "focus:ring-blue-500",
        "focus:border-blue-500",
        "block",
        "w-full",
        "border",
        "border-gray-300",
        "rounded-md",
        "overflow-hidden",
        "flex",
        "flex-row",
      )}
    >
      {props.children}
      {props.disabled && props.disabledHint !== undefined && (
        <Popover.Root open={popoverOpen}>
          <Popover.Trigger
            className={"px-2 bg-gray-50 outline-none"}
            onMouseEnter={() => setPopoverOpen(true)}
            onMouseLeave={() => setPopoverOpen(false)}
          >
            <div className={"w-6 text-gray-400"}>
              <QuestionMarkCircleIcon />
            </div>
          </Popover.Trigger>
          <Popover.Content onOpenAutoFocus={(e) => e.preventDefault()} side="top" sideOffset={-4}>
            <div className={"rounded-md overflow-hidden max-w-xs border border-gray-300 shadow-sm"}>
              <div className={"bg-white p-2 relative z-20"}>{props.disabledHint}</div>
            </div>
            <Popover.Arrow
              width={14}
              height={8}
              className="fill-white stroke-2 stroke-gray-300 mt-[-2px] relative z-10"
            />
          </Popover.Content>
        </Popover.Root>
      )}
    </div>
  );
};

export const FormError: React.FC = (props) => {
  return <div className="text-sm text-red-600">{props.children}</div>;
};

export const Label = React.forwardRef<
  HTMLLabelElement,
  Omit<React.ComponentPropsWithRef<"label">, "class" | "className">
>((props, ref) => {
  return (
    <label
      ref={ref}
      {...props}
      className={clsx("block", "text-sm", "font-medium", "text-gray-700")}
    ></label>
  );
});

interface InputProps extends Omit<React.ComponentPropsWithRef<"input">, "class" | "className"> {
  disabledHint?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { disabledHint, ...rest } = props;
  const disabled = props.disabled === true;
  return (
    <WithTooltip disabled={disabled} disabledHint={disabledHint}>
      <input ref={ref} {...rest} className={inputClasses(disabled)}></input>
    </WithTooltip>
  );
});

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  Omit<React.ComponentPropsWithRef<"textarea">, "class" | "className">
>((props, ref) => {
  return (
    <textarea
      ref={ref}
      {...props}
      className={clsx(
        "shadow-sm",
        "focus:ring-blue-500",
        "focus:border-blue-500",
        "block",
        "w-full",
        "sm:text-sm",
        "border-gray-300",
        "rounded-md",
      )}
    ></textarea>
  );
});

interface SelectProps extends Omit<React.ComponentPropsWithRef<"select">, "class" | "className"> {
  disabledHint?: string;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { disabledHint, ...rest } = props;
  const disabled = props.disabled === true;
  return (
    <WithTooltip disabled={disabled} disabledHint={disabledHint}>
      <select ref={ref} {...rest} className={inputClasses(disabled)}></select>
    </WithTooltip>
  );
});
