import { useParams as _useParams } from "react-router";
import Policies from "../pages/policy/policies";
import { PoliciesSortOn, PoliciesSortOrder, TaskId } from "/src/internal_types";
import {
  capture,
  EncoderDecoder,
  enumED,
  number,
  queryParam,
  queryParams,
  route,
  string,
} from "./routing";
import { Portfolio, PortfolioTabs } from "/src/pages/portfolio/portfolio";
import Product from "/src/pages/product";
import CreateProduct from "/src/pages/create_product";
import EditProduct from "/src/pages/edit_product";
import ReadProduct from "/src/pages/read_product";
import EditPendingProduct from "/src/pages/edit_pending_product";
import PendingProduct from "/src/pages/pending_product";
import Users from "../pages/users";
import Login from "../pages/Auth/Login";
import SetupPassword from "../pages/Auth/SetupPassword";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import { Policy } from "../pages/policy/policy";
import { MrcIndexView } from "../pages/policy/mrc_extraction";
import { MrcDocumentView } from "../pages/policy/mrc_extraction/document";
import { MrcUploadView } from "../pages/policy/mrc_extraction/upload";
import ProductsOverview from "../pages/ProductsOverview";
import React from "react";
import { EditAggregation } from "../pages/portfolio/edit_aggregation";
import { CreateAggregation } from "../pages/portfolio/create_aggregation";
import { CreateTrigger } from "../pages/portfolio/create_trigger";
import { EditTrigger } from "../pages/portfolio/edit_trigger";
import { TaskList } from "../pages/task/list";
import { TaskRuns } from "../pages/task/runs";

const productIdQueryParam = queryParam("productId", number, "product");

const sortOrderQueryParam = queryParam<"sortOrder", NonNullable<PoliciesSortOrder>>(
  "sortOrder",
  enumED(["asc", "desc"] as const),
  "sort-order",
);

const searchQueryParam = queryParam("searchReferenceQuery", string, "search");

const policiesSortOnED: EncoderDecoder<NonNullable<PoliciesSortOn>> = enumED([
  "insured_name",
  "last_modified",
  "id",
  "custom",
  "created_at",
] as const);

const usersSortOnED: EncoderDecoder<"name" | "email"> = enumED(["name", "email"] as const);
const policyEditModeED: EncoderDecoder<"true"> = enumED(["true"] as const);

const pageQueryParam = queryParam("page", number);

const portfolioTabED: EncoderDecoder<"dashboard" | "aggregates" | "triggers"> = enumED([
  "dashboard",
  "aggregates",
  "triggers",
] as const);
const portfolioParams = [
  "portfolio",
  capture("tab", portfolioTabED),
  queryParam("page", number),
  productIdQueryParam,
] as const;

const aggregationTypeED: EncoderDecoder<"grouped" | "custom"> = enumED([
  "grouped",
  "custom",
] as const);
const aggregationTypeParam = capture("aggregationType", aggregationTypeED);

const taskTagED: EncoderDecoder<TaskId["tag"]> = enumED([
  "ExportPoliciesTaskId",
  "SequelHubTaskId",
  "MrcExtractionTaskId",
  "ArchivePoliciesTaskId",
] as const);

export const policiesParams = [
  productIdQueryParam,
  queryParams("selectedAssigneeIds", string, "assignees[]"),
  queryParams("selectedQuoteFilters", string, "quote-filters[]"),
  queryParams("selectedPolicyFilters", string, "policy-filters[]"),
  queryParams("selectedActionsRequired", string, "actions-required[]"),
  searchQueryParam,
  queryParams("additionalDatapoints", string, "additional-datapoints[]"),
  queryParam("page", number),
  sortOrderQueryParam,
  queryParam("sortOn", policiesSortOnED, "sort-by"),
  queryParam("customSortOn", string, "custom-sort-on"),
] as const;

export const mrcBaseRoute = [
  "product",
  capture("productId", number),
  "policy",
  capture("policyId", number),
  "mrc",
] as const;

export const mrcDocumentImporting = route([
  ...mrcBaseRoute,
  capture("documentId", number),
  "importing",
] as const);

export const mrcDocumentSingleDatapointEdit = route([
  ...mrcBaseRoute,
  capture("documentId", number),
  "datapoint",
  capture("extractionId", number),
] as const);

export const Routes = {
  portfolio: route(["portfolio"] as const),
  portfolioTabs: route(portfolioParams),

  createAggregation: route(["create-aggregation"] as const),
  editAggregation: route([
    "edit-aggregation",
    aggregationTypeParam,
    capture("aggregationId", number),
  ] as const),

  createTrigger: route(["create-trigger"] as const),
  editTrigger: route(["edit-trigger", capture("triggerId", number)] as const),

  product: route(["product", capture("productId", number)] as const),
  createProduct: route(["create-product"] as const),
  editProduct: route(["edit-product", capture("productId", number)] as const),
  readProduct: route([
    "read-product",
    capture("productId", number),
    "version",
    capture("versionId", number),
  ] as const),
  editPendingProduct: route([
    "pending-product",
    "edit",
    capture("pendingProductId", number),
  ] as const),
  pendingProduct: route(["pending-product", capture("pendingProductId", number)] as const),

  users: route([
    "users",
    pageQueryParam,
    sortOrderQueryParam,
    queryParam("sortOn", usersSortOnED, "sort-by"),
    queryParams("roles", string, "roles[]"),
    searchQueryParam,
  ] as const),

  policies: route([...policiesParams] as const),

  policy: route([
    "product",
    capture("productId", number),
    "policy",
    capture("policyId", number),
    queryParam("editing", policyEditModeED),
    ...policiesParams,
  ] as const),

  mrc: route([...mrcBaseRoute] as const),
  mrcUpload: route([...mrcBaseRoute, "upload"] as const),
  mrcDocumentView: route([...mrcBaseRoute, capture("documentId", number)] as const, false),

  products: route(["product"] as const),

  tasks: route([
    "task",
    queryParam("finishedPage", number),
    queryParam("inProgressPage", number),
  ] as const),
  get taskRuns() {
    return route([
      ...this.tasks.route,
      capture("taskTag", taskTagED),
      capture("taskId", number),
    ] as const);
  },
} as const;

export const RouteComponents: Record<keyof typeof Routes, React.FC> = {
  // those are getters to avoid circular dependencies
  get portfolio() {
    return Portfolio;
  },
  get portfolioTabs() {
    return PortfolioTabs;
  },
  get createAggregation() {
    return CreateAggregation;
  },
  get editAggregation() {
    return EditAggregation;
  },
  get createTrigger() {
    return CreateTrigger;
  },
  get editTrigger() {
    return EditTrigger;
  },
  get product() {
    return Product;
  },
  get createProduct() {
    return CreateProduct;
  },
  get editProduct() {
    return EditProduct;
  },
  get readProduct() {
    return ReadProduct;
  },
  get editPendingProduct() {
    return EditPendingProduct;
  },
  get pendingProduct() {
    return PendingProduct;
  },
  get users() {
    return Users;
  },
  get policies() {
    return Policies;
  },
  get policy() {
    return Policy;
  },
  get mrc() {
    return MrcIndexView;
  },
  get mrcUpload() {
    return MrcUploadView;
  },
  get mrcDocumentView() {
    return MrcDocumentView;
  },
  get products() {
    return ProductsOverview;
  },
  get tasks() {
    return TaskList;
  },
  get taskRuns() {
    return TaskRuns;
  },
};

export const PublicRoutes = {
  login: route(["login"] as const),
  setupPassword: route(["setup-password"] as const),
  forgotPassword: route(["forgot-password"] as const),
  resetPassword: route(["reset-password"] as const),
};

export const PublicRouteComponents: Record<keyof typeof PublicRoutes, React.FC> = {
  get login() {
    return Login;
  },
  get setupPassword() {
    return SetupPassword;
  },
  get forgotPassword() {
    return ForgotPassword;
  },
  get resetPassword() {
    return ResetPassword;
  },
};
