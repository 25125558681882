import React from "react";

/**
 * Filter checkbox atom
 */
export const FilterCheckbox = ({
  label,
  name,
  onChange,
  checked,
  disabled,
  className,
}: {
  label: string;
  name: string;
  onChange: () => void;
  checked: boolean;
  disabled?: boolean;
  className?: string;
}) => (
  <div className={`flex items-center p-2 w-full ${className ?? ""}`}>
    <input
      onChange={() => onChange()}
      checked={checked}
      id={`checkbox_${name}`}
      name={name}
      type="checkbox"
      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
      disabled={disabled}
    />
    <label
      htmlFor={`checkbox_${name}`}
      className={`ml-2 block text-sm cursor-pointer ${
        disabled ? "text-gray-400" : "text-gray-900"
      }`}
    >
      {label}
    </label>
  </div>
);
