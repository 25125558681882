import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import Layout from "./Layout";
import { LoginFormValues } from "./Login";
import PasswordForm, { PasswordFormValues } from "./PasswordForm";
import { PublicRoutes } from "../../routing/routes";

const SetupPassword = () => {
  const { push, location } = useHistory();
  const [isLoading, setLoading] = useState(false);
  const signInFormData = location.state as LoginFormValues;

  const handleSubmit = async (formValues: PasswordFormValues) => {
    setLoading(true);

    try {
      const user = await aws_amplify.Auth.signIn(
        signInFormData.email.trim(),
        signInFormData.password,
      );

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        await aws_amplify.Auth.completeNewPassword(user, formValues.newPassword, null);

        setLoading(false);
        push(PublicRoutes.login.generatePath({}));
      }
    } catch (err) {
      setLoading(false);
    }
  };

  if (signInFormData == null) {
    return <Redirect to={PublicRoutes.login.generatePath({})} />;
  }

  return (
    <Layout>
      <PasswordForm handleSubmit={handleSubmit} isLoading={isLoading} />
    </Layout>
  );
};

export default SetupPassword;
