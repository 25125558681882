import clsx from "clsx";
import React from "react";

export type TabsProps<A extends string = string> = {
  tabs: ({ label: string; name: A } | A)[];
  selected: A;
  onSelect: (label: A) => void;
  className?: string;
};

export function Tabs<A extends string = string>(props: TabsProps<A>) {
  return (
    <div className={clsx(["border-b border-gray-200"], props.className)}>
      <nav className="-mb-px flex space-x-8">
        {props.tabs.map((tab) => {
          const label = typeof tab === "object" ? tab.label : tab;
          const name = typeof tab === "object" ? tab.name : tab;
          const selected = props.selected === name;
          return (
            <a
              key={label}
              className={clsx(
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer",
                selected
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              )}
              onClick={() => props.onSelect(name)}
            >
              {label}
            </a>
          );
        })}
      </nav>
    </div>
  );
}
