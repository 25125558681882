import React from "react";
import { generalEditorOrSummaryView, SingleNodeT_, useSingleNodeState } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { toNonEmptyString } from "/src/utils";

export interface TextNodeT_ extends SingleNodeT_<string> {
  type: "text";
  isMultiline: boolean;
}

export interface CaselessTextNodeT_ extends SingleNodeT_<string> {
  type: "caseless_text";
  isMultiline: boolean;
}

export const TextNode = (props: { node: TextNodeT_ | CaselessTextNodeT_ }) => {
  return generalEditorOrSummaryView(props.node, TextNodeEditorView, TextNodeSummaryView);
};

export const TextNodeEditorView = (props: { node: TextNodeT_ | CaselessTextNodeT_ }) => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);

  const handleOnBlur = () => {
    node.commit(toNonEmptyString(localValue));
  };

  return (
    <LeafNode node={node}>
      {node.isMultiline ? (
        <textarea
          className="w-full rounded border border-gray-300 text-sm focus:outline-none focus:ring-blue-400 focus:border-blue-500 disabled:bg-gray-50"
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={handleOnBlur}
          disabled={node.readOnly}
          rows={3}
        />
      ) : (
        <input
          className="w-full rounded border border-gray-300 text-sm focus:outline-none focus:ring-blue-400 focus:border-blue-500 disabled:bg-gray-50"
          type="text"
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={handleOnBlur}
          disabled={node.readOnly}
        />
      )}
    </LeafNode>
  );
};

export const TextNodeSummaryView = (props: { node: TextNodeT_ | CaselessTextNodeT_ }) => {
  return <SummaryLeafStringNode label={props.node.label} contents={[props.node.value ?? ""]} />;
};
