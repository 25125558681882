import React from "react";
import { Routes } from "/src/routing/routes";
import { useParams } from "/src/routing/routing";
import { Page, usePageTitle } from "/src/layout";
import { TriggerForm } from "./aggregations";
import {
  editAggregationTrigger,
  invalidatePath,
  useGetAggregationTriggers,
  useGetProducts,
} from "/src/dal/dal";
import { AggregationTrigger } from "/src/internal_types";
import { throwIfAppError } from "/src/utils/app_error";
import NotFound from "../404";

export function EditTrigger() {
  const params = useParams(Routes.editTrigger);
  const triggerId = params.data.capture.triggerId;

  const triggers = useGetAggregationTriggers();
  throwIfAppError(triggers);
  const trigger = triggers.value.value.find(({ id }) => id === triggerId)?.trigger;

  if (trigger === undefined) {
    return <NotFound />;
  }

  usePageTitle(`Trigger #${triggerId}`);

  const productsResp = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(productsResp);
  const products = productsResp.value.products;

  const onSubmit = async (editedTrigger: AggregationTrigger) => {
    const result = await editAggregationTrigger(triggerId, editedTrigger);
    await invalidatePath("/api/stats/aggregation/triggers");
    return result.status === "error" ? result.value.message : undefined;
  };

  return (
    <Page>
      <TriggerForm
        onSubmit={onSubmit}
        title="Edit trigger"
        products={products}
        initialFields={trigger}
      />
    </Page>
  );
}
