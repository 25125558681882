import React from "react";
import { generalEditorOrSummaryView, SelectableNodeT_ } from "../types";
import { LeafNode, LeafNodeT_, SummaryLeafStringNode } from "./base";
import { TextField, Chip, List, ListItem } from "@mui/material";
import { Autocomplete } from "@mui/lab";

const ChipList = (props: { node: TextSetSelectNodeT_ }) => {
  const { node } = props;
  return (
    <List>
      {node.value.map((v) => (
        <ListItem key={v}>
          <Chip
            onDelete={() => {
              node.remove(v);
            }}
            label={v}
          />
        </ListItem>
      ))}
    </List>
  );
};

export interface TextSetSelectNodeT_ extends LeafNodeT_, SelectableNodeT_<string> {
  type: "text_set_select";
  value: string[];
  add: (value: string) => void;
  remove: (value: string) => void;
  replace: (value: string[]) => void;
  isCommitting: boolean;
  needsCollecting: boolean;
  allowCustomOption: boolean;
  chipListPosition: "before" | "after" | "inline";
  description: string | undefined;
}

export const TextSetSelectNode = (props: { node: TextSetSelectNodeT_ }) => {
  return generalEditorOrSummaryView(
    props.node,
    TextSetSelectNodeEditorView,
    TextSetSelectNodeSummaryView,
  );
};

export const TextSetSelectNodeEditorView = (props: { node: TextSetSelectNodeT_ }) => {
  const { node } = props;

  const chipListPosition = node.chipListPosition;
  const allowCustomOption = node.allowCustomOption;
  return (
    <LeafNode node={node}>
      {chipListPosition === "before" && <ChipList node={node} />}
      <Autocomplete
        multiple={true}
        options={node.options}
        fullWidth={true}
        freeSolo={allowCustomOption}
        renderInput={(params) => <TextField {...params} helperText={node.description} />}
        renderTags={chipListPosition === "inline" ? undefined : () => undefined}
        disableClearable={true}
        disabled={node.readOnly}
        onChange={(_event, newValue) => {
          node.replace(newValue);
        }}
        value={node.value}
      />
      {chipListPosition === "after" && <ChipList node={node} />}
    </LeafNode>
  );
};

export const TextSetSelectNodeSummaryView = (props: { node: TextSetSelectNodeT_ }) => {
  return <SummaryLeafStringNode label={props.node.label} contents={props.node.value} />;
};
