import React, { Dispatch, SetStateAction, useEffect } from "react";
import { generalEditorOrSummaryView, SingleNodeT_ } from "../types";
import { LeafNode, SummaryLeafStringNode } from "./base";
import { UMR } from "/src/internal_types";
import { parseUmr } from "/src/utils";
import { match } from "ts-pattern";

export interface UmrNodeT_ extends SingleNodeT_<UMR> {
  type: "umr";
}

export const UmrNode = (props: { node: UmrNodeT_ }) => {
  return generalEditorOrSummaryView(props.node, UmrNodeEditorView, UmrNodeSummaryView);
};

type LocalValueState =
  | {
      tag: "empty";
    }
  | {
      tag: "invalid";
    }
  | {
      tag: "valid";
      umr: UMR;
    };

export const UmrNodeEditorView = (props: { node: UmrNodeT_ }) => {
  const { node } = props;
  const [localValue, setLocalValue] = useSingleNodeState(node);
  const localValueState: LocalValueState = ((): LocalValueState => {
    if (localValue === "") {
      return { tag: "empty" };
    }
    const umr: UMR | undefined = parseUmr(localValue).getRight();
    if (umr === undefined) {
      return { tag: "invalid" };
    }
    return { tag: "valid", umr };
  })();

  const handleOnBlur = () => {
    match(localValueState)
      .with({ tag: "empty" }, () => {
        // committing null creates a retraction
        node.commit(null);
      })
      .with({ tag: "invalid" }, () => {
        // restore the original node value
        setLocalValue(nullableUmrToString(node));
      })
      .with({ tag: "valid" }, ({ umr }) => {
        node.commit(umr);
      })
      .exhaustive();
  };

  // Here we are repurposing the node.problems mechanism (which usually
  // indicates errors sent from the server for keys annotated with the
  // when...invalid metakeys) for displaying purely FE errors: field values that
  // don't match the UMR pattern but haven't been commited yet.
  const nodeExtraErrors: UmrNodeT_ = match<LocalValueState, UmrNodeT_>(localValueState)
    .with({ tag: "empty" }, () => node)
    .with({ tag: "invalid" }, () => ({
      ...node,
      problems: node.problems.concat("Not a valid UMR"),
    }))
    .with({ tag: "valid" }, () => node)
    .exhaustive();

  return (
    <LeafNode node={nodeExtraErrors}>
      <input
        className="w-full rounded border border-gray-300 text-sm focus:outline-none focus:ring-blue-400 focus:border-blue-500 disabled:bg-gray-50"
        type="text"
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={handleOnBlur}
        disabled={node.readOnly}
      />
    </LeafNode>
  );
};

export const UmrNodeSummaryView = (props: { node: UmrNodeT_ }) => {
  return (
    <SummaryLeafStringNode label={props.node.label} contents={[nullableUmrToString(props.node)]} />
  );
};

export function useSingleNodeState(node: UmrNodeT_): [string, Dispatch<SetStateAction<string>>] {
  const initialValue = nullableUmrToString(node);
  const [localValue, setLocalValue] = React.useState(initialValue);
  useEffect(() => {
    if (!node.isCommitting) {
      setLocalValue(initialValue);
    }
  }, [node.value, node.isCommitting, node.initialRawValue]);
  return [localValue, setLocalValue];
}

const umrToString = (umr: UMR): string => {
  return `B${umr.brokerId}${umr.extraData}`;
};

const nullableUmrToString = (node: UmrNodeT_): string => {
  const fallback = node.initialRawValue === undefined ? "" : node.initialRawValue;
  return node.value === null ? fallback : umrToString(node.value);
};
