import React from "react";
import { Page, usePageTitle } from "/src/layout";
import { TriggerForm } from "./aggregations";
import {
  invalidatePath,
  newAggregationTrigger,
  useGetCurrentUser,
  useGetProducts,
} from "/src/dal/dal";
import { AggregationTrigger } from "/src/internal_types";
import { CanAddTrigger } from "/src/pages/policy/permissions";
import { throwIfAppError } from "/src/utils/app_error";
import NotFound from "../404";

export function CreateTrigger() {
  usePageTitle("Add trigger");

  const currentUser = useGetCurrentUser();
  throwIfAppError(currentUser);
  const canAddTrigger = CanAddTrigger({ currentRole: currentUser.value.role }).check(undefined);

  if (!canAddTrigger) {
    return <NotFound />;
  }

  const products = useGetProducts({ onlyLatestVersion: true });
  throwIfAppError(products);

  const onSubmit = async (newTrigger: AggregationTrigger) => {
    const result = await newAggregationTrigger(newTrigger);
    await invalidatePath("/api/stats/aggregation/triggers");
    return result.status === "error" ? result.value.message : undefined;
  };

  return (
    <Page>
      <TriggerForm
        onSubmit={onSubmit}
        title="Add trigger"
        products={products.value.products}
        initialFields={{}}
      />
    </Page>
  );
}
