import * as apiTypes from "/src/api_types";
import wretch from "wretch";
import { authMiddleware } from "./fetch";

//
// Helpers
// =============================

export type ExtractParams<T> = T extends {
  parameters: { path: infer U };
}
  ? U
  : Record<string, never>;
export type ExtractQuery<T> = T extends {
  parameters: { query: infer U };
}
  ? U
  : Record<string, never>;

export type GetJsonPaths = {
  [P in keyof apiTypes.paths]: apiTypes.paths[P] extends {
    get: {
      responses: {
        200: {
          content: {
            "application/json;charset=utf-8": unknown;
          };
        };
      };
    };
  }
    ? P
    : never;
}[keyof apiTypes.paths];

/* DX could be further improved with the code below but I ran into a few suble intellisene issues
trying it and I've already used my fancy TypeScript quota for the day.

type ParamsOption<T> = {} extends ExtractParams<T> ? { params?: {} } : { params: ExtractParams<T> };
type QueryOption<T> = {} extends ExtractQuery<T> ? { query?: {} } : { query: ExtractQuery<T> };

ParamsOption<apiTypes.paths[T]["get"]> & QueryOption<apiTypes.paths[T]["get"]>
*/

function substParams(path: string, params: Record<string, string | number | undefined>) {
  for (const [p, v] of Object.entries(params)) {
    path = v !== undefined ? path.replace(`{${p}}`, v.toString()) : path;
  }
  return path;
}

function removeUndefinedProps<T extends Record<string, unknown>>(obj: T): T {
  const res: any = {};
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] !== undefined) {
      res[prop] = obj[prop];
    }
  }
  return res;
}

export type AppError = apiTypes.components["schemas"]["AppError"];

export type OrAppError<T> = { status: "success"; value: T } | { status: "error"; value: AppError };

export type GetResponseFor<T extends GetJsonPaths> = OrAppError<
  apiTypes.paths[T]["get"]["responses"][200]["content"]["application/json;charset=utf-8"]
>;

export type PostResponseFor<T extends PostJsonPaths> = OrAppError<
  apiTypes.paths[T]["post"]["responses"][200]["content"]["application/json;charset=utf-8"]
>;

export type PutResponseFor<T extends PutJsonPaths> = OrAppError<
  apiTypes.paths[T]["put"]["responses"][200]["content"]["application/json;charset=utf-8"]
>;

export type PatchResponseFor<T extends PatchJsonPaths> = OrAppError<
  apiTypes.paths[T]["patch"]["responses"][200]["content"]["application/json;charset=utf-8"]
>;

export type DeleteResponseFor<T extends DeleteJsonPaths> = OrAppError<
  apiTypes.paths[T]["delete"]["responses"][200]["content"]["application/json;charset=utf-8"]
>;

function asSuccess<T>(value: T): OrAppError<T> {
  return { status: "success" as const, value: value };
}

function asError<T>(error: any): OrAppError<T> {
  // Is error WretcherError?
  if (error.text !== null) {
    try {
      const errorBody = JSON.parse(error.text);
      if (errorBody.tag !== null) {
        return { status: "error" as const, value: errorBody as AppError };
      }
    } catch (e) {
      // Throw original error instead of JSON parsing error
      throw error;
    }
  }
  throw error;
}

export function apiGet<T extends GetJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["get"]>;
    query: ExtractQuery<apiTypes.paths[T]["get"]>;
    baseUrl?: string;
  },
): Promise<GetResponseFor<T>> {
  const wretcher = path.startsWith("/api")
    ? wretch(options.baseUrl).middlewares([authMiddleware])
    : wretch(options.baseUrl);
  return wretcher
    .url(substParams(path, options.params ?? {}))
    .query(removeUndefinedProps(options.query) ?? {})
    .get()
    .json()
    .then((e) => asSuccess(e as any))
    .catch((e) => asError(e));
}

type PostJsonPaths = {
  [P in keyof apiTypes.paths]: apiTypes.paths[P] extends {
    post: {
      responses: {
        200: {
          content: {
            "application/json;charset=utf-8": unknown;
          };
        };
      };
    };
  }
    ? P
    : never;
}[keyof apiTypes.paths];

type ExtractRequestBody<T> = T extends {
  requestBody: {
    content: {
      "application/json;charset=utf-8": infer U;
    };
  };
}
  ? U
  : Record<string, never>;

export function apiPost<T extends PostJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["post"]>;
    query: ExtractQuery<apiTypes.paths[T]["post"]>;
    body: ExtractRequestBody<apiTypes.paths[T]["post"]>;
    baseUrl?: string;
  },
): Promise<PostResponseFor<T>> {
  const wretcher = path.startsWith("/api")
    ? wretch(options.baseUrl).middlewares([authMiddleware])
    : wretch(options.baseUrl);
  return wretcher
    .url(substParams(path, options.params ?? {}))
    .query(options.query ?? {})
    .post(options.body)
    .json()
    .then((e) => asSuccess(e as any))
    .catch((e) => asError(e));
}

type PutJsonPaths = {
  [P in keyof apiTypes.paths]: apiTypes.paths[P] extends {
    put: {
      responses: {
        200: {
          content: {
            "application/json;charset=utf-8": unknown;
          };
        };
      };
    };
  }
    ? P
    : never;
}[keyof apiTypes.paths];

export function apiPut<T extends PutJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["put"]>;
    query: ExtractQuery<apiTypes.paths[T]["put"]>;
    body: ExtractRequestBody<apiTypes.paths[T]["put"]>;
  },
): Promise<PutResponseFor<T>> {
  const wretcher = path.startsWith("/api") ? wretch().middlewares([authMiddleware]) : wretch();
  return wretcher
    .url(substParams(path, options.params ?? {}))
    .query(options.query ?? {})
    .content("application/json")
    .put(options.body)
    .json()
    .then((e) => asSuccess(e as any))
    .catch((e) => asError(e));
}

type PatchJsonPaths = {
  [P in keyof apiTypes.paths]: apiTypes.paths[P] extends {
    patch: {
      responses: {
        200: {
          content: {
            "application/json;charset=utf-8": unknown;
          };
        };
      };
    };
  }
    ? P
    : never;
}[keyof apiTypes.paths];

export function apiPatch<T extends PatchJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["patch"]>;
    query: ExtractQuery<apiTypes.paths[T]["patch"]>;
    body: ExtractRequestBody<apiTypes.paths[T]["patch"]>;
    baseUrl?: string;
  },
): Promise<PatchResponseFor<T>> {
  const wretcher = path.startsWith("/api")
    ? wretch(options.baseUrl).middlewares([authMiddleware])
    : wretch(options.baseUrl);
  return wretcher
    .url(substParams(path, options.params ?? {}))
    .query(options.query ?? {})
    .patch(options.body)
    .json()
    .then((e) => asSuccess(e as any))
    .catch((e) => asError(e));
}

type DeleteJsonPaths = {
  [P in keyof apiTypes.paths]: apiTypes.paths[P] extends {
    delete: {
      responses: {
        200: {
          content: {
            "application/json;charset=utf-8": unknown;
          };
        };
      };
    };
  }
    ? P
    : never;
}[keyof apiTypes.paths];

export function apiDelete<T extends DeleteJsonPaths>(
  path: T,
  options: {
    params: ExtractParams<apiTypes.paths[T]["delete"]>;
    query: ExtractQuery<apiTypes.paths[T]["delete"]>;
  },
): Promise<DeleteResponseFor<T>> {
  const wretcher = path.startsWith("/api") ? wretch().middlewares([authMiddleware]) : wretch();
  return wretcher
    .url(substParams(path, options.params ?? {}))
    .query(options.query ?? {})
    .delete()
    .json()
    .then((e) => asSuccess(e as any))
    .catch((e) => asError(e));
}
