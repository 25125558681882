import { Asyncs } from "/src/asyncs";
import React, { Suspense } from "react";
import { DataView } from "./data_view";
import { QuoteView, QuoteViewPermissions } from "./quote_view";
import { Dropdown } from "/src/components/Dropdown";
import { FilterCheckbox } from "/src/components/FilterCheckbox";
import { multiDocPreview, newQuote, useCheckMultiDocExists } from "/src/dal/dal";
import { TxStatus } from "/src/data_editor";
import { downloadFile } from "/src/utils";
import { Button as BrossaButton, Button } from "/src/design_system/Button";
import { Loader } from "/src/design_system/Loader";
import { Datapoint, PolicyInfo, QuoteId, QuoteInfo, Quotes } from "/src/internal_types";
import { ItemLoader } from "/src/item_loader";
import {
  determinePartialQuoteState,
  determinePolicyState,
  GlobalPolicyState,
  Permission,
  PolicyState,
} from "/src/pages/policy/permissions";

type QuotesViewProps<p> = {
  policy: PolicyInfo;
  quotes: Quotes;
  permissions: QuoteViewPermissions;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  addQuotePermission: Permission<GlobalPolicyState, PolicyState, p>;
  title: string;
  addButtonCaption: string;
  showChip: boolean;
  quoteOpened?: QuoteId;
  onAddQuoteClick: (quotesRef: React.MutableRefObject<null>) => void;
  onOpenQuote?: (quoteId?: QuoteId) => void;
  notAbleToAddMessage: (canAdd: boolean, policyEditable: boolean) => string | undefined;
  addQuote: () => ReturnType<typeof newQuote>;
  highlightErrorInPolicy: (dp: Datapoint[]) => void;
  asyncs: Asyncs;
};

function QuotesView<P>(props: QuotesViewProps<P>): JSX.Element {
  const quotesRef = React.useRef(null);
  const policyState = determinePolicyState(props.policy);
  const canAddNewQuote = props.addQuotePermission.check(policyState);
  const policyEditable = props.permissions.canArchivePolicy.check(policyState);

  const notAbleToAddMessage = props.notAbleToAddMessage(canAddNewQuote, policyEditable);
  const isActive = (q: Pick<QuoteInfo, "derivedStatus">) => {
    const tag = determinePartialQuoteState(props.policy, q).tag;
    return tag !== "NotTakenUp" && tag !== "Declined";
  };
  const quotes = {
    quotes: props.quotes.quotes.filter(isActive),
  };
  const archivedQuotes = {
    quotes: props.quotes.quotes.filter((q) => !isActive(q)),
  };

  return (
    <>
      <div className="mt-8">
        <DataView
          title={props.title}
          titleActions={
            canAddNewQuote ? (
              <div className="flex space-x-2">
                <GenerateMultiQuoteComponent {...props} />
                <BrossaButton variant="primary" onClick={() => props.onAddQuoteClick(quotesRef)}>
                  {props.addButtonCaption}
                </BrossaButton>
              </div>
            ) : undefined
          }
        >
          <div ref={quotesRef}>
            <BasicQuotesView
              quotes={quotes}
              policy={props.policy}
              permissions={props.permissions}
              isOpen={(id) => id === props.quoteOpened}
              initialIsEditing={(id) => id === props.quoteOpened}
              highlightErrorInPolicy={props.highlightErrorInPolicy}
              asyncs={props.asyncs}
            />
          </div>
          {archivedQuotes.quotes.length > 0 && (
            <div className="">
              <ArchivedQuotes
                quotes={archivedQuotes}
                policy={props.policy}
                asyncs={props.asyncs}
                permissions={props.permissions}
              />
            </div>
          )}
          {!canAddNewQuote && notAbleToAddMessage !== undefined && (
            // <div className="flex items-center justify-start shadow-inner rounded bg-gray-100 px-6 py-8">
            //   <InformationCircleIcon className="w-5 h-5 mr-2 text-gray-400" />
            //   <div className="font-medium text-gray-400">{notAbleToAddMessage}</div>
            //   {/* <Alert severity={"info"}></Alert> */}
            // </div>
            <></>
          )}
        </DataView>
      </div>
    </>
  );
}

type RealQuotesViewProps = {
  policy: PolicyInfo;
  quotes: Quotes;
  permissions: QuoteViewPermissions;
  onTxStatusChange?: (txStatus: TxStatus) => void;
  onAddQuoteClick: (quotesRef: React.MutableRefObject<null>) => void;
  quoteOpened?: QuoteId;
  onOpenQuote?: (quoteId?: QuoteId) => void;
  highlightErrorInPolicy: (dps: Datapoint[]) => void;
  asyncs: Asyncs;
};

export const RealQuotesView = (props: RealQuotesViewProps) => {
  const quotes: Quotes = { quotes: props.quotes.quotes.filter((q) => q.indicative === "Real") };
  const addQuotePermission = props.permissions.canShowAddNewQuote;
  const notAbleToAddMessage = (canAdd: boolean, policyEditable: boolean): string | undefined => {
    if (!canAdd && policyEditable) {
      return "Complete Policy information before proceeding to quotes";
    }
  };

  return (
    <QuotesView
      {...props}
      quotes={quotes}
      addQuotePermission={addQuotePermission}
      title="Quotes"
      notAbleToAddMessage={notAbleToAddMessage}
      addButtonCaption={"New quote"}
      addQuote={() =>
        newQuote(props.policy.productId, props.policy.id, {
          policyStartDate: props.policy.defaultStartDate,
          policyEndDate: props.policy.defaultEndDate,
        })
      }
      showChip={true}
      highlightErrorInPolicy={props.highlightErrorInPolicy}
    />
  );
};

type ArchivedQuotesProps = {
  quotes: Quotes;
  policy: PolicyInfo;
  permissions: QuoteViewPermissions;
  asyncs: Asyncs;
};

const ArchivedQuotes = (props: ArchivedQuotesProps): JSX.Element => {
  return (
    <div className="grid gap-y-6">
      <p className="font-semibold text-lg pt-12 pb-6 py-5">Archived quotes</p>
      <div className="opacity-60">
        <BasicQuotesView
          quotes={props.quotes}
          asyncs={props.asyncs}
          policy={props.policy}
          permissions={props.permissions}
          isOpen={() => false}
          initialIsEditing={() => false}
          highlightErrorInPolicy={() => {
            return;
          }}
        />
      </div>
    </div>
  );
};

type BasicQuotesViewProps = {
  quotes: Quotes;
  policy: PolicyInfo;
  permissions: QuoteViewPermissions;
  isOpen: (id: QuoteId) => boolean;
  initialIsEditing: (id: QuoteId) => boolean;
  highlightErrorInPolicy: (dps: Datapoint[]) => void;
  asyncs: Asyncs;
};

const BasicQuotesView = (props: BasicQuotesViewProps): JSX.Element => {
  return (
    <Suspense fallback={<ItemLoader type={"loader"} size={"lg"} />}>
      <div className="w-full grid gap-y-6">
        {props.quotes.quotes
          .sort((q1, q2) => q1.id - q2.id)
          .map((quote) => {
            return (
              <QuoteView
                key={quote.id}
                quoteId={quote.id}
                quoteNumber={quote.quoteNumber}
                policy={props.policy}
                isOpen={props.isOpen(quote.id)}
                permissions={props.permissions}
                initialIsEditing={props.initialIsEditing(quote.id)}
                highlightErrorInPolicy={props.highlightErrorInPolicy}
                asyncs={props.asyncs}
              />
            );
          })}
      </div>
    </Suspense>
  );
};

type GenerateMultiQuoteComponentProps = {
  quotes: Quotes;
  policy: PolicyInfo;
  permissions: QuoteViewPermissions;
};

const GenerateMultiQuoteComponent = (props: GenerateMultiQuoteComponentProps): JSX.Element => {
  const showComponent = useCheckMultiDocExists(props.policy.productId, props.policy.id);
  const [checkedQuotes, setCheckedQuotes] = React.useState<QuoteId[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const generatableQuotes = props.quotes.quotes.filter((q) => {
    const state = determinePartialQuoteState(props.policy, q);
    return props.permissions.canGenerateMultiQuoteFile.check(state);
  });
  if (!showComponent || generatableQuotes.length === 0) {
    return <></>;
  }
  const maxQuotes = 3;
  const restDisabled = checkedQuotes.length === maxQuotes;
  return (
    <div>
      <Dropdown label="Generate Multi Quote Document" noFixedHeight={true}>
        <p className="p-2"> Choose up to {maxQuotes} quotes </p>
        {generatableQuotes.map((q) => {
          const checked = checkedQuotes.find((v) => v === q.id) !== undefined;
          return (
            <FilterCheckbox
              key={q.id}
              label={`Quote #${q.quoteNumber}`}
              name={q.id.toString()}
              onChange={() => {
                let newQuotes = [];
                if (checked) {
                  newQuotes = checkedQuotes.filter((v) => v !== q.id);
                } else {
                  newQuotes = [...checkedQuotes, q.id];
                }
                setCheckedQuotes(newQuotes);
              }}
              checked={checked}
              disabled={!checked && restDisabled}
            />
          );
        })}
        <div className="m-2 justify-end flex">
          <Button
            onClick={async () => {
              setIsLoading(true);
              const blob = await multiDocPreview(
                props.policy.productId,
                props.policy.id,
                checkedQuotes,
              );
              const qids = checkedQuotes.map((qid) => `#${qid}`).join(", ");
              const name = `Quotes ${qids}`;
              downloadFile(blob, name);
              setIsLoading(false);
            }}
            className="justify-self-end flex space-x-2"
            disabled={checkedQuotes.length === 0}
          >
            <span> Generate </span>
            {isLoading && <Loader size="sm" color="white" />}
          </Button>
        </div>
      </Dropdown>
    </div>
  );
};
