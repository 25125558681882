import React from "react";
import { Modal } from "../design_system/Modal";
import { Button } from "../design_system/Button";

export const ConfirmationModal: React.FC<{
  isOpen: boolean;
  isAwaiting: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  confirmButtonLabel?: string;
}> = (props): JSX.Element => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="p-8 flex flex-col gap-5">
        <div>
          <p className="text-xl font-bold">{props.title}</p>
        </div>
        {props.children}
        <div className="flex flex-row justify-end gap-4">
          <Button variant="secondary" onClick={props.onClose} disabled={props.isAwaiting}>
            Cancel
          </Button>
          <Button onClick={props.onConfirm} disabled={props.isAwaiting}>
            {props.confirmButtonLabel ?? "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
