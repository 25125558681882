import clsx from "clsx";
import React from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { PredefinedColours, COLOUR_SCHEME, BORDER } from "./Colour";

export interface BoxProps {
  colour?: PredefinedColours;
  padding?: "default" | "small" | "none";
  border?: "visible" | "hidden";
  customBorderColour?: PredefinedColours;
  className?: string;
  onClick?: () => void;
}

export const Box: React.FC<React.PropsWithChildren<BoxProps>> = ({
  colour = "white",
  padding = "default",
  border = "visible",
  children,
  className = "",
  customBorderColour,
  onClick,
}) => {
  const borders: string = (() => {
    switch (border) {
      case "hidden":
        return "border-0";
      case "visible":
        return "border";
    }
  })();
  const paddings: string = (() => {
    switch (padding) {
      case "default":
        return "p-4 space-y-3";
      case "small":
        return "p-2 space-y-1";
      case "none":
        return "p-0";
    }
  })();
  return (
    <div
      className={overrideTailwindClasses(
        clsx(
          COLOUR_SCHEME(colour),
          paddings,
          customBorderColour !== undefined && BORDER(customBorderColour),
          borders,
          "rounded-md",
          onClick !== undefined && "cursor-pointer",
          "overflow-hidden",
          className,
        ),
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
