// Pretty print the date/time contained within the input string.
//
// INVARIANT: must be a valid time, otherwise throws an exception.
export const prettyPrintTime = (time: string): { date: string; time: string } => {
  const d = Date.parse(time);
  if (isNaN(d)) throw "Unable to parse time, this is a serious bug: " + time;
  const d2 = new Date(time);
  const d3 = modifiedDateFormatter.format(d2);
  const d4 = modifiedTimeFormatter.format(d2);
  return { date: d3, time: d4 };
};

export const prettyPrintTimeString = (time: string): string => {
  const parts = prettyPrintTime(time);
  return parts.date + " " + parts.time;
};

export const modifiedTimeFormatter = new Intl.DateTimeFormat("en-GB", {
  minute: "numeric",
  hour: "numeric",
  hourCycle: "h12",
});

export const modifiedDateFormatter = new Intl.DateTimeFormat("en-GB", {
  year: "2-digit",
  day: "numeric",
  month: "numeric",
});
